import { DemoParticipantTile } from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/types';
import { getRandomInt } from 'shared/shared/utils';
import { getURLParam } from 'utils/urls';
import { useCallback, useMemo, useState } from 'react';
import { useVirtualVenueDisplay } from 'components/VirtualVenue/contexts/VirtualVenueDisplayContext';
import useTimeout from 'utils/hooks/useTimeout';

const buildGiphyURL = (id: string) => `https://media.giphy.com/media/${id}/giphy.mp4`;

// These are gathered and managed here:
// https://docs.google.com/spreadsheets/d/1ddA_LJz3SudDDtVVfKE4nG1gei9QANvuu9moUBAkMXo/edit?usp=sharing

const DEMO_MEDIA = [
  { name: 'Rock Star', id: 'xThuWhoaNyNBjTGERa' },
  { name: 'Munchy McMunch', id: 'WiXMlla4ZFR8Q' },
  { name: 'Not a Cat', id: 'yTzwdWHe1VxQt4sfiD' },
  { name: 'Señor Legume', id: '4KLv24CPUoZ0I' },
  { name: 'Ready Freddy', id: 'LBjmmt3jF0M0lGfb3c' },
  { name: 'Dr. Awks', id: 'JmD9mkDmzvXE7nxy7j' },
  { name: 'Unresolved Feline', id: 'I5DWjwUKUFlyXZ2haC' },
  { name: 'Mr. Mackey', id: 'My6LerZy9kQLwObqWk' },
];

const useDemoTiles = () => {
  const [demoTiles, setDemoTiles] = useState<DemoParticipantTile[]>([]);
  const { mode } = useVirtualVenueDisplay();
  const demoType = useMemo(() => {
    const demoMode = getURLParam('demoMode');
    const matches = demoMode?.match(/^(fixed)(-(\d+))?/);
    if (matches) {
      if (matches[1] === 'fixed') {
        let count = 6;
        try {
          if (matches.length > 3) {
            count = parseInt(matches[3]);
          }
        } catch {
          console.error('Failed demo count parse');
        }
        return {
          type: 'fixed',
          count: count,
        };
      }
    }
    return {
      type: 'dynamic',
      count: DEMO_MEDIA.length,
    };
  }, []);
  const addDemoTiles = useCallback((n: number) => {
    setDemoTiles((tiles) => {
      const newTiles: DemoParticipantTile[] = [];
      for (let i = 0; i < n; i++) {
        const media = DEMO_MEDIA[(tiles.length + i) % DEMO_MEDIA.length];
        newTiles.push({
          id: `demo-${media.id}-${tiles.length + i / DEMO_MEDIA.length}`,
          type: 'demo',
          mediaUrl: buildGiphyURL(media.id),
          name: media.name,
        });
      }
      return [...tiles, ...newTiles];
    });
  }, []);

  const removeDemoTiles = useCallback((n: number) => {
    setDemoTiles((tiles) => {
      return tiles.slice(0, tiles.length - n);
    });
  }, []);

  const maxDemoTiles = 8;

  const demoTileProc = useCallback(
    (add: boolean, numTiles: number) => {
      if (mode === 'demo') {
        const timeout = getRandomInt(6, 12);
        if (add && numTiles < maxDemoTiles) {
          addDemoTiles(1);
          setTimeout(() => demoTileProc(true, numTiles + 1), timeout * 500);
        } else if (numTiles > 0) {
          removeDemoTiles(1);
          setTimeout(() => demoTileProc(false, numTiles - 1), timeout * 500);
        }
      }
    },
    [addDemoTiles, mode, removeDemoTiles]
  );

  useTimeout(() => {
    if (mode === 'demo') {
      if (demoType.type === 'fixed') {
        addDemoTiles(demoType.count);
      } else {
        demoTileProc(true, demoType.count);
      }
    }
  }, 5000);

  return { demoTiles, addDemoTiles, removeDemoTiles };
};

export default useDemoTiles;
