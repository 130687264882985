import { Link } from 'components/common';
import { ListingEvent } from 'components/pages/ListingEditPage/types';
import { getEventUrl } from 'models/event';
import { getRunUrl } from 'models/run';
import { parseEvent } from 'models';
import AddToCalendar, { ButtonCalendarType } from 'components/pages/EventReadPage/AddToCalendar/AddToCalendar';
import React from 'react';
import When from 'components/When/When';
import Where from 'components/Where/Where';

interface Props {
  listingEvent: ListingEvent;
  listingId?: string;
  newWindow?: boolean;
}

// const GoLink = ({ listingEvent }: Props) => {
//   const { event } = listingEvent;
//   return <AddToCalendar eventId={event.id} forCalendar={event.forCalendar} startTime={event.startTime} />;
// };

const GoLinks = ({ listingEvent, listingId, newWindow }: Props) => {
  const { event } = listingEvent;
  const link = event.run?.id ? getRunUrl(event.run.id) : getEventUrl({ id: event.id });
  const isCompanyVentures = listingId === '1480310347339454623';
  return (
    <div className="flex flex-col">
      {isCompanyVentures}
      <Link href={link} newWindow={newWindow}>
        {event.isTicketed ? 'Get Tickets' : 'RSVP'}
      </Link>
      <div>
        {!isCompanyVentures && (
          <AddToCalendar eventId={event.id} forCalendar={event.forCalendar} type={ButtonCalendarType.LINK} />
        )}
      </div>
    </div>
  );
};

const ListingEventItem = ({ listingEvent, listingId, newWindow }: Props) => {
  const { event } = listingEvent;
  const link = event.run?.id ? getRunUrl(event.run.id) : getEventUrl({ id: event.id });

  return (
    <div className="flex flex-col md:flex-row no-child-link-underline">
      <div className="flex-shrink-0 w-full mb-4 mr-6 md:w-72 md:mb-0">
        <div className="flex flex-col">
          <div>
            {event.themePic && (
              <Link href={link} newWindow={newWindow}>
                <img className="inline-block w-full rounded" src={event.themePic} alt="" />
              </Link>
            )}
          </div>
          <div className="hidden mt-1 text-center md:block">
            <GoLinks listingEvent={listingEvent} newWindow={newWindow} listingId={listingId} />
          </div>
        </div>
      </div>

      <div className="flex-grow">
        <Link className="mb-2 text-2xl text-black" href={link} newWindow={newWindow}>
          <div>
            {event.title}

            {/* <Description
          description={event.description}
          showLabel={false}
          bodyClassName={classNames(styles.description, styles.short)}
        /> */}
            <div className="mt-2 mb-4 vstack-tiny">
              <When event={parseEvent(event)} isRun={!!event.run?.id} />
              <Where event={parseEvent(event)} link={false} />
              {/* <HostedBy event={event} noHostedBy /> */}
            </div>
          </div>
        </Link>
        <div className="md:hidden">
          <GoLinks listingEvent={listingEvent} newWindow={newWindow} listingId={listingId} />
        </div>
      </div>
    </div>
  );
};

export default ListingEventItem;
