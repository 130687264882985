import * as m from 'models';
import { Invite, iconStateMap, isHostInvite, renderName } from 'models/invite';
import { Tooltip } from '@material-ui/core';
import { isEventAtCapacity } from 'shared/shared/utils';
import ChatIcon from '@material-ui/icons/Chat';
import ConfirmDeleteInvite from 'components/EventGuestList/ConfirmDeleteInvite';
import DeleteIcon from '@material-ui/icons/Delete';
import EditInviteResponseModal from 'components/EventGuestList/EditInviteResponseModal';
import Icon from 'components/common/Icon/Icon';
import React, { ReactNode, useState } from 'react';
import classNames from 'classnames';
import styles from 'components/EventGuestList/InviteResponse.module.scss';

interface Props {
  canDelete: boolean;
  event: m.Event;
  extraStyles?: string;
  invite: Invite;
  maxPlusN?: number;
}

const InviteResponse = (props: Props) => {
  const [editDialogIsOpen, setEditDialogIsOpen] = useState<boolean>(false);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState<boolean>(false);

  const { canDelete: viewerIsHost, invite, maxPlusN, event } = props;
  const inviteIsHost = isHostInvite(invite, event);

  const iconName = iconStateMap[invite.state!];
  const name = renderName(invite);
  const plusN = invite.rsvpPlusN || 0;
  const hasMessage = viewerIsHost && !!invite.rsvpAnswer;
  const invitedBy = invite.sentBy?.name;
  const hasInvitedBy = invitedBy && invitedBy !== 'Someone';
  const hasOriginalEmail = name !== invite.originalEmail && invite.originalEmail;
  const hasTooltip = hasOriginalEmail || hasInvitedBy || invite.rsvpAnswer;

  const guestName = (
    <div className={styles.guestName}>
      <span>
        {name}
        {inviteIsHost ? <span className="opacity-50"> (Host)</span> : ''}
        {plusN ? <span className="opacity-50">{` +${plusN}`}</span> : ''}
      </span>
      {hasMessage && <ChatIcon fontSize="inherit" className={styles.commentIcon} />}
    </div>
  );

  const listItem: ReactNode =
    viewerIsHost && hasTooltip ? (
      <Tooltip
        arrow
        placement="bottom-start"
        enterDelay={500}
        disableTouchListener
        // See https://github.com/mui-org/material-ui/issues/14366
        PopperProps={{ disablePortal: true }}
        title={
          <div className={styles.tooltip}>
            {hasOriginalEmail && (
              <div>
                <span className="opacity-50">Invited as:</span> {invite.originalEmail}
              </div>
            )}
            {hasInvitedBy && (
              <div>
                <span className="opacity-50">Invited by:</span> {invitedBy}
              </div>
            )}
            {invite.rsvpAnswer && (
              <div>
                <span className="opacity-50">RSVP Message:</span> {invite.rsvpAnswer}
              </div>
            )}
          </div>
        }
      >
        {guestName}
      </Tooltip>
    ) : (
      guestName
    );

  return (
    <div
      className={classNames(styles.InviteResponse, { [styles.admin]: viewerIsHost })}
      // Check for modals before handling click because modal clicks get propagated up to this element
      onClick={() => viewerIsHost && !editDialogIsOpen && !deleteDialogIsOpen && setEditDialogIsOpen(true)}
    >
      <Icon icon={iconName} className={styles.icon} size="small" />
      <div>{listItem}</div>
      {viewerIsHost && (
        <div className={styles.actions}>
          <div>
            <DeleteIcon
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setDeleteDialogIsOpen(true);
              }}
              fontSize="inherit"
              className={styles.actionIcon}
            />
          </div>
        </div>
      )}
      <EditInviteResponseModal
        canPlusOne={!!maxPlusN && maxPlusN > 0}
        invite={invite}
        isEventAtCapacity={isEventAtCapacity(event.maxCapacity, event.confirmedGuests!)}
        maxPlusN={maxPlusN}
        onClose={() => setEditDialogIsOpen(false)}
        open={editDialogIsOpen}
      />
      <ConfirmDeleteInvite invite={invite} onClose={() => setDeleteDialogIsOpen(false)} open={deleteDialogIsOpen} />
    </div>
  );
};

export default InviteResponse;
