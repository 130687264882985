import { colorWithAlpha } from 'utils/colors';
import { useVirtualVenueTheme } from 'components/VirtualVenue/contexts/VirtualVenueThemeContext';
import Button2 from 'components/common/Button2/Button2';
import React, { ReactNode } from 'react';
import classNames from 'classnames';

interface Props {
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  formId?: string;
  children: Element | ReactNode;
}

export default function StartButton({ disabled, onClick, formId, className, children }: Props) {
  const theme = useVirtualVenueTheme();
  return (
    <Button2
      className={classNames(className ?? 'rounded-md start-button')}
      disabled={disabled}
      onClick={onClick}
      size="14"
      form={formId}
      submit={!!formId}
      color="secondary"
      style={{
        border: 'none',
        backgroundColor: colorWithAlpha(theme.titleTextColor, 0.85),
        color: 'var(--v-theme-bg-color)',
      }}
    >
      {children}
    </Button2>
  );
}
