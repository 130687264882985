import { RedirectToPath } from 'components/Redirect';
import { SignupForm } from 'components/auth-forms';
import { getURLParam } from 'utils/urls';
import { reverse } from 'router';
import AuthPageContext from 'components/context/AuthPageContext/AuthPageContext';
import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import React from 'react';
import useUser from 'utils/hooks/user/useUser';

const AccountSignupPage: React.FC = () => {
  const { isAuthenticated } = useUser();
  const next = getURLParam('next', reverse('home'));
  const name = getURLParam('name', '');
  const email = getURLParam('email', '');

  return (
    <AuthPageContext.Provider value="signup">
      <BaseLayout title="Sign up" hideTitle={true}>
        {isAuthenticated ? (
          <RedirectToPath path={reverse('home')} />
        ) : (
          <SignupForm next={next} name={name} email={email} />
        )}
      </BaseLayout>
    </AuthPageContext.Provider>
  );
};

export default AccountSignupPage;
