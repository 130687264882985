import AuthPageContext from 'components/context/AuthPageContext/AuthPageContext';
import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import React from 'react';
import ResetPasswordForm from 'components/pages/ResetPasswordPage/ResetPasswordForm';

const ResetPasswordPage = () => (
  <AuthPageContext.Provider value="reset">
    <BaseLayout title="Reset Password">
      <ResetPasswordForm />
    </BaseLayout>
  </AuthPageContext.Provider>
);

export default ResetPasswordPage;
