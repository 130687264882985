import { useFormStateContext } from 'components/forms/FormStateContext';
import React from 'react';

function Form<T extends Record<string, any> = any>(props: React.HTMLProps<HTMLFormElement>) {
  const { formContext, onSubmit } = useFormStateContext<T>();
  const wrappedSubmit = formContext.handleSubmit(onSubmit);
  return (
    <form onSubmit={wrappedSubmit} {...props}>
      {props.children}
    </form>
  );
}

export default Form;
