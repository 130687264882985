import { ListingEvent } from 'components/pages/ListingEditPage/types';
import { Listing_listing_listingEvents_event } from 'generated/Listing';
import { WidgetProps } from 'components/widgets';
import ListingEventFormItem from 'components/pages/ListingEditPage/ListingEventFormItem';
import ListingEventSelectorDialog from 'components/pages/ListingEditPage/ListingEventSelectorDialog';
import React, { useState } from 'react';

const ListingEventsWidget = ({ value: listingEvents, onChange }: WidgetProps<ListingEvent[]>) => {
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

  function onDeleteListingEvent(index: number) {
    const newListingEvents = listingEvents.slice(0, index).concat(listingEvents.slice(index + 1));
    onChange(newListingEvents);
  }

  function onAddListingEvent(event: Listing_listing_listingEvents_event) {
    const newListingEvent: Partial<ListingEvent> = {
      event,
      eventId: event.id,
      position: listingEvents.length,
    };
    const newListingEvents = listingEvents.concat(newListingEvent as any);
    onChange(newListingEvents);
  }

  function onMoveUp(index: number) {
    const newListingEvents = listingEvents.slice();
    const temp = newListingEvents[index - 1];
    newListingEvents[index - 1] = newListingEvents[index];
    newListingEvents[index] = temp;
    onChange(newListingEvents.map((listingEvent, idx) => ({ ...listingEvent, position: idx })));
  }

  function onMoveDown(index: number) {
    const newListingEvents = listingEvents.slice();
    const temp = newListingEvents[index + 1];
    newListingEvents[index + 1] = newListingEvents[index];
    newListingEvents[index] = temp;
    onChange(newListingEvents.map((listingEvent, idx) => ({ ...listingEvent, position: idx })));
  }

  return (
    <>
      <div className="mt-3 space-y-3">
        {listingEvents.map((listingEvent: ListingEvent, index: number) => (
          <ListingEventFormItem
            key={listingEvent.id || index}
            value={listingEvent}
            onMoveDown={() => onMoveDown(index)}
            onMoveUp={() => onMoveUp(index)}
            onDelete={() => onDeleteListingEvent(index)}
            hasMany={listingEvents.length > 1}
            isFirst={index === 0}
            isLast={index === listingEvents.length - 1}
          />
        ))}
      </div>
      <ListingEventSelectorDialog
        isOpen={dialogIsOpen}
        setIsOpen={setDialogIsOpen}
        onSelectEvent={onAddListingEvent}
        eventIds={listingEvents.map((listingEvent) => listingEvent.eventId)}
      />
    </>
  );
};

export default ListingEventsWidget;
