import { Button, TextLink } from 'components/common';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { Poll, PollOption, formatPollOptions } from 'models/poll';
import { PollVoteTooltip } from './PollVoteTooltip';
import Dialog from 'components/common/Dialog/Dialog';
import React, { ChangeEvent } from 'react';
import styles from './PollSelectionDialog.module.scss';

interface Props {
  poll: Poll;
  selectedOption: string;
  loading: boolean;
  open: boolean;
  buttonClassName?: string;
  setSelectedOption: (option: string) => void;
  setOpen: (open: boolean) => void;
  handleSave: () => void;
}

export const PollSelectionDialogDisplay = (props: Props) => {
  const { poll, buttonClassName, setSelectedOption, setOpen, handleSave, open, loading, selectedOption } = props;
  const options = formatPollOptions(poll.options);

  return (
    <>
      <TextLink key="button" className={buttonClassName} text="Pick a time" onClick={() => setOpen(true)} />
      <Dialog
        title="Pick a time"
        isOpen={open}
        onClose={() => setOpen(false)}
        actions={[
          <Button
            key="select"
            disabled={loading}
            label={loading ? 'Saving...' : 'Save'}
            className={styles.saveButton}
            onClick={() => handleSave()}
            small
          />,
        ]}
      >
        <div className={styles.label}>Set one of the poll options as your event start time.</div>
        <div className={styles.sublabel}>This will remove the poll from your event.</div>
        <RadioGroup
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setSelectedOption(e.target.value);
          }}
          value={selectedOption}
        >
          {options.map((option: PollOption) => (
            <FormControlLabel
              key={option.id}
              control={<Radio color="primary" />}
              value={option.id}
              label={
                <span>
                  {option.text}
                  <PollVoteTooltip votes={option.votes || []} className={styles.votes} />
                </span>
              }
            />
          ))}
        </RadioGroup>
      </Dialog>
    </>
  );
};
