import * as m from 'models';
import { EventUpdatePageContext } from 'components/pages/EventUpdatePageContext';
import { ThemePicState } from 'components/pages/EventUpdatePage';
import { getURLParam } from 'utils/urls';
import { localStore } from 'utils/localstore';
import EventCreateForm from 'components/EventCreateUpdateForm/EventCreateUpdateForm/EventCreateForm';
import EventLayout from 'components/layout/EventLayout/EventLayout';
import React, { useState } from 'react';
import useUser from 'utils/hooks/user/useUser';

// TODO: DRY with EventUpdatePage

const EventCreatePage = () => {
  const { loading, isAuthenticated } = useUser();
  const [themePic, setThemePic] = useState<ThemePicState>({
    previewUrl: '',
    existingRemovedOnNextSave: false,
    editPosition: false,
  });

  // Make sure we don't load the demo event when user is authenticated
  if (loading) {
    return null;
  }

  const initialTitle = getURLParam('title');
  const eventData = isAuthenticated ? undefined : localStore.demoEvent.get();
  if (eventData && initialTitle) {
    eventData.title = initialTitle;
  }

  return (
    <EventUpdatePageContext.Provider value={{ themePic, setThemePic }}>
      <EventLayout
        title="Create Event"
        themePicUrl={themePic.existingRemovedOnNextSave ? '' : themePic.previewUrl}
        noDefaultPadding={true}
      >
        <EventCreateForm
          initialTitle={initialTitle || undefined}
          event={eventData ? m.parseEvent(eventData) : undefined}
        />
      </EventLayout>
    </EventUpdatePageContext.Provider>
  );
};

export default EventCreatePage;
