import * as F from 'shared/shared/Functional';
import {
  DailyParticipantTile,
  VideoTile,
} from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/types';
import { DailyState } from 'components/VirtualVenue/daily/state';
import { getURLParam } from 'utils/urls';
import { hashCode } from 'shared/shared/utils';
import { isLocal } from 'components/VirtualVenue/daily/utils';

export const getChatTiles = (dailyState: DailyState, showSelf: boolean): DailyParticipantTile[] => {
  const tiles: (DailyParticipantTile | undefined)[] = F.objMap(dailyState.callItems, (id, callItem) => {
    if (isLocal(id) && !showSelf) {
      return;
    }
    if (callItem.isLoading && !isLocal(id)) {
      // Don't show loading participants. This is in respnose to a bug
      // (either on our side or daily's) where some participants got stuck in a loading
      // state. Can remove this case when the bug is fixed.
      // https://www.notion.so/mixily/bug-virtual-venue-participants-stuck-in-Loading-state-65ae9e529a5a4ce7b17ed9b55c5c0275
      return;
    }

    return {
      type: 'daily',
      id,
      callItem,
      isActiveSpeaker: id === dailyState.activeSpeakerId,
    };
  });
  return tiles.filter(Boolean) as DailyParticipantTile[];
};

export const getVideoTiles = (): VideoTile[] => {
  const tiles: VideoTile[] = [];
  const hlsUrl = getURLParam('hls_participant');
  if (hlsUrl) {
    tiles.push({
      id: 'video-' + hashCode(hlsUrl),
      type: 'video',
      url: hlsUrl,
    });
  }
  return tiles;
};
