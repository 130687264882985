import FormStateContext, { FormState } from 'components/forms/FormStateContext';
import React, { ReactNode } from 'react';

interface Props extends FormState {
  children: ReactNode;
}

const FormStateContextProvider = (props: Props) => {
  const { children, ...rest } = props;
  return <FormStateContext.Provider value={rest}>{children}</FormStateContext.Provider>;
};

export default FormStateContextProvider;
