import { Loading } from 'components/common';
import { RichTextType } from 'utils/prismic/types';
import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import React from 'react';
import RichText from './RichText';

interface Props {
  title?: string;
  content?: RichTextType;
}

const RichTextPage = ({ title, content }: Props) => {
  return (
    <BaseLayout title={title || 'Loading...'}>
      <div className="max-w-3xl">{content ? <RichText content={content} /> : <Loading />}</div>
    </BaseLayout>
  );
};

export default RichTextPage;
