import {
  EditorForm,
  MutationForm,
} from 'components/VirtualVenue/VirtualVenueDisplay/layouts/VirtualVenueDisplayLayout/VirtualVenueHeader/VirtualVenueTitle/forms';
import { VenueRole } from 'generated/globalTypes';
import { useVirtualVenue } from 'components/VirtualVenue/contexts/VirtualVenueContext';
import { useVirtualVenueEditContext } from 'components/pages/VirtualVenueEditPage/VirtualVenueEditContext';
import Dialog2 from 'components/common/Dialog2/Dialog2';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import fscreen from 'fscreen';

interface Props {
  title: string;
}

const VirtualVenueTitle = ({ title }: Props) => {
  const venue = useVirtualVenue();
  const [editingTitle, setEditingTitle] = useState(false);

  // If we're inside of an editor, we want to store the title in memory rather than sending to the database,
  // so that the user can save/discard.
  const { title: editContextTitle, setTitle: setEditContextTitle } = useVirtualVenueEditContext() || {};

  const canEditTitle = venue.myAccess.role === VenueRole.OWNER; // TODO: support moderator

  const effectiveTitle = editContextTitle || title;

  useEffect(() => {
    setEditContextTitle?.(title);
  }, [setEditContextTitle, title]);

  const fontSize = title.length <= 50 ? 'text-2xl' : 'text-xl';

  return (
    <>
      <span
        className={classNames(fontSize, 'leading-none', canEditTitle && 'cursor-pointer')}
        style={{ color: 'var(--v-theme-title-text-color)' }}
        onClick={
          canEditTitle
            ? () => {
                setEditingTitle(true);
              }
            : undefined
        }
      >
        {effectiveTitle}
      </span>
      {editingTitle && (
        <Dialog2
          container={fscreen.fullscreenElement}
          isOpen={editingTitle}
          onClose={() => setEditingTitle(false)}
          title="Update Venue Title"
        >
          {setEditContextTitle ? (
            <EditorForm
              title={editContextTitle || ''}
              setTitle={setEditContextTitle}
              onClose={() => setEditingTitle(false)}
            />
          ) : (
            <MutationForm id={venue.id} title={effectiveTitle} onClose={() => setEditingTitle(false)} />
          )}
        </Dialog2>
      )}
    </>
  );
};

export default VirtualVenueTitle;
