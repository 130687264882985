import { GOOGLE_LOGIN_AUTH_MUTATION } from 'utils/gql';
import { GoogleAuthLogin, GoogleAuthLoginVariables } from 'generated/GoogleAuthLogin';
import { useMutation } from '@apollo/client';
import GoogleAuthButton from 'components/auth-forms/GoogleAuthButton';
import React, { useEffect } from 'react';
import useUserChangeContext from 'components/App/useUserChangeContext';

interface Props {
  next?: string;
  setError: any;
  loginHint?: string;
}

const GoogleLogin = (props: Props) => {
  const [doMutation, { data, error }] = useMutation<GoogleAuthLogin, GoogleAuthLoginVariables>(
    GOOGLE_LOGIN_AUTH_MUTATION
  );
  const { login } = useUserChangeContext();
  const next = props.next || window.location.pathname;

  useEffect(() => {
    if (data?.googleAuthLogin.ok) {
      const apitoken = data.googleAuthLogin.apitoken!;
      login(apitoken, true, next);
    }
  });

  if (data?.googleAuthLogin.errors) {
    props.setError(data.googleAuthLogin.errors[0].message);
  }

  if (error) {
    props.setError('Sorry, something went wrong');
  }

  return (
    <GoogleAuthButton
      label="Log in with Google"
      onSuccess={(code) => doMutation({ variables: { code } })}
      loginHint={props.loginHint}
    />
  );
};

export default GoogleLogin;
