import { VIRTUAL_VENUE_UPDATE_MUTATION } from 'utils/gql';
import { VirtualVenueSetTitle, VirtualVenueSetTitleVariables } from 'generated/VirtualVenueSetTitle';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import Button2 from 'components/common/Button2/Button2';
import FieldLayout from 'components/common/FieldLayout/FieldLayout';
import Input2 from 'components/common/Input2/Input2';
import React, { useEffect } from 'react';
import useFormHelpers from 'components/forms/useFormHelpers';
import useMutationFormHelpers from 'components/forms/useMutationFormHelpers';

type FormT = {
  title: string;
};

interface MutationFormProps {
  id: string;
  title: string;
  onClose: () => void;
}

export const MutationForm = ({ id, title, onClose }: MutationFormProps) => {
  const formContext = useForm<FormT>({ defaultValues: { title } });
  const [setVenueTitle] = useMutation<VirtualVenueSetTitle, VirtualVenueSetTitleVariables>(
    VIRTUAL_VENUE_UPDATE_MUTATION
  );
  const formHelpers = useMutationFormHelpers<FormT, VirtualVenueSetTitle, VirtualVenueSetTitleVariables>({
    formContext,
    formToVariables: ({ title }) => ({
      id,
      title,
    }),
    mutation: setVenueTitle,
    resultKey: 'virtualVenueUpdate',
    onSuccess: onClose,
  });
  const { FormStateContextProvider, Form, SubmitButton, FormLevelMessages, isSubmitting, onSubmit } = formHelpers;
  const { register, errors } = formContext;

  useEffect(() => {
    // Need timeout because field is in dialog.
    // See: https://stackoverflow.com/questions/59526726/react-hook-forms-setvalue-method-is-not-working-if-input-is-in-material-ui-dial
    setTimeout(() => formContext.setValue('title', title), 100);
  }, [formContext, title]);

  return (
    <FormStateContextProvider onSubmit={onSubmit} isSubmitting={isSubmitting} formContext={formContext}>
      <Form className="text-black">
        <FieldLayout error={errors.title} label="Title">
          <Input2
            name="title"
            ref={register({ required: true })}
            placeholder="My Venue"
            size="md"
            required={true}
            autoFocus={true}
            maxLength={64}
          />
        </FieldLayout>
        <FormLevelMessages className="mt-4" />
        <div className="flex flex-row justify-between mt-4">
          <Button2 color="secondary" onClick={onClose} size="12">
            Cancel
          </Button2>
          <SubmitButton label="Set Title" submittingLabel="Saving..." size="12" />
        </div>
      </Form>
    </FormStateContextProvider>
  );
};

interface EditorFormProps {
  title: string;
  setTitle: (newTitle: string) => void;
  onClose: () => void;
}

export const EditorForm = ({ title, setTitle, onClose }: EditorFormProps) => {
  const formContext = useForm<FormT>({ defaultValues: { title } });
  const { register, errors } = formContext;

  const { FormStateContextProvider, Form, SubmitButton, FormLevelMessages } = useFormHelpers();

  useEffect(() => {
    // Need timeout because field is in dialog.
    // See: https://stackoverflow.com/questions/59526726/react-hook-forms-setvalue-method-is-not-working-if-input-is-in-material-ui-dial
    setTimeout(() => formContext.setValue('title', title), 100);
  }, [formContext, title]);

  const onSubmit = (data: FormT) => {
    setTitle(data.title);
    onClose();
  };

  return (
    <FormStateContextProvider onSubmit={onSubmit} isSubmitting={false} formContext={formContext}>
      <Form className="text-black">
        <FieldLayout error={errors.title} label="Title">
          <Input2
            name="title"
            ref={register({ required: true })}
            placeholder="My Venue"
            size="md"
            required={true}
            autoFocus={true}
            maxLength={64}
          />
        </FieldLayout>
        <FormLevelMessages className="mt-4" />
        <div className="flex flex-row justify-between mt-4">
          <Button2 color="secondary" onClick={onClose} size="12">
            Cancel
          </Button2>
          <SubmitButton label="Set Title" size="12" />
        </div>
      </Form>
    </FormStateContextProvider>
  );
};
