import Divider from 'components/common/Divider';
import EmailAuthLoginForm from 'components/auth-forms/EmailAuthLoginForm';
import EmailAuthSignupForm from 'components/auth-forms/EmailAuthSignupForm';
import ErrorMessage from 'components/common/ErrorMessage/ErrorMessage';
import GoogleLogin from 'components/auth-forms/GoogleLoginButton';
import GoogleSignup from 'components/auth-forms/GoogleSignupButton';
import React, { useState } from 'react';

export type AuthCallback = (credentials: AuthFormCredentials, cb?: Function) => () => void;

interface Props {
  header: string;
  message?: string;
  hideFooter?: boolean;
  form: 'login' | 'signup';

  next?: string;
  email?: string;
  password?: string;
  name?: string;

  onRequestSignupFormModal?: AuthCallback;
  onRequestLoginFormModal?: AuthCallback;
  onNavigate?: AuthCallback;
}

const BaseAuthForm = (props: Props) => {
  const [socialAuthError, setSocialAuthError] = useState<string>();
  return (
    <div className="max-w-sm m-auto">
      <div className="mb-12 text-center">
        <div className="text-5xl">{props.header}</div>
        {props.message && <p>{props.message}</p>}
      </div>

      {props.form === 'login' ? (
        <GoogleLogin setError={setSocialAuthError} loginHint={props.email} next={props.next} />
      ) : (
        <GoogleSignup setError={setSocialAuthError} loginHint={props.email} next={props.next} />
      )}
      {socialAuthError && (
        <div>
          <br />
          <ErrorMessage key={socialAuthError}>{socialAuthError}</ErrorMessage>
        </div>
      )}
      <Divider>or</Divider>
      {props.form === 'login' ? <EmailAuthLoginForm {...props} /> : <EmailAuthSignupForm {...props} />}
    </div>
  );
};

export default BaseAuthForm;
