import { Tooltip } from '@material-ui/core';
import { canUseVolumeMeter } from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/utils';
import { useDeviceConfig } from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/contexts/DeviceConfigContext';
import IconSvg from 'components/common/IconSvg/IconSvg';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import TrayButton from '../TrayButton/TrayButton';
import styles from './MicTrayButton.module.scss';
import useInterval from 'utils/hooks/useInterval';
import useStreamVolume from 'utils/hooks/useStreamVolume';

interface Props {
  isMicMuted: boolean;
  enabled: boolean;
  onToggle: () => void;
}

const MIC_UPDATE_PERIOD = 50; // in ms
const HISTORY_WINDOW_SIZE = 25; // How many samples to average muted for speaker detection
const MUTE_CHECK_INTERVAL = MIC_UPDATE_PERIOD * HISTORY_WINDOW_SIZE;
const TALKING_WHILE_MUTED_THRESHOLD = 0.2; // 0-1 scale;

const calcRMS = (values: number[]) => {
  let sum = 0;
  let x;

  // Do a root-mean-square on the samples: sum up the squares...
  for (let i = 0; i < values.length; i++) {
    x = values[i];
    sum += x * x;
  }

  // ... then take the square root of the sum.
  return Math.sqrt(sum / values.length);
};

const MicTrayButton = ({ isMicMuted, enabled, onToggle }: Props) => {
  const micRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const [showMutedWarning, setShowMutedWarning] = useState(false);
  const { mediaDevices } = useDeviceConfig();
  const useVolumeMeter = useMemo(() => canUseVolumeMeter() && enabled, [enabled]);
  const volume = useStreamVolume(mediaDevices?.audioinput ?? '', MIC_UPDATE_PERIOD, !useVolumeMeter);
  const [volumeHistory, setVolumeHistory] = useState<number[]>([]);

  useEffect(() => {
    const normalizedVolume = Math.min(volume * 100, 100);

    // This modifies values inside the SVG to render the volume meter on the mic
    micRef.current?.querySelectorAll('.mic-level-boundary').forEach((el) => {
      el.setAttribute('offset', `${normalizedVolume}%`);
    });

    // Store history when we're muted, this is used for moving window average.
    if (isMicMuted) {
      setVolumeHistory((prev) => {
        const previousValues = prev ?? [];
        return [volume, ...previousValues.slice(0, HISTORY_WINDOW_SIZE - 1)];
      });
    }
  }, [isMicMuted, volume]);

  useInterval(() => {
    if (isMicMuted && enabled) {
      // What is the average volume over the time window?
      const rmsOverPeriod = calcRMS(volumeHistory);
      if (rmsOverPeriod > TALKING_WHILE_MUTED_THRESHOLD) {
        setShowMutedWarning(true);
        setTimeout(() => {
          setShowMutedWarning(false);
        }, 3000);
      }
    }
  }, MUTE_CHECK_INTERVAL);

  return (
    <Tooltip
      classes={{ tooltip: styles.MutedTooltip }}
      open={showMutedWarning}
      arrow
      PopperProps={{
        container: ref.current!,
      }}
      placement="top-start"
      title={`Hey, you're on mute. 🤦`}
    >
      <div ref={ref}>
        <TrayButton enabled={enabled} turnedOff={isMicMuted} onClick={onToggle} label="Mic">
          <IconSvg ref={micRef} name={isMicMuted ? 'micOffWithLevel' : 'micWithLevel'} className="w-6 h-6" />
        </TrayButton>
      </div>
    </Tooltip>
  );
};

export default MicTrayButton;
