// Renders its children dependent on getting permission for user's camera and microphone.
// This is useful because Safari returns blank/invalid value for `navigator.mediaDevices.enumerateDevices`
// before access has been granted. So it's helpful to ask permission first before rendering the device form.

import { Loading } from 'components/common';
import React, { ReactElement } from 'react';
import useUserMedia from 'utils/hooks/useUserMedia';

interface Props {
  children: ReactElement;
}

const mediaConfig = {
  audio: true,
  video: true,
} as const;

const WithMediaDevicePermission = ({ children }: Props) => {
  const { loading, error, stream } = useUserMedia(mediaConfig);
  if (stream) {
    return children;
  } else {
    return (
      <div>
        {loading && (
          <div>
            <h3>Waiting for camera and microphone permission</h3>
            <Loading />
          </div>
        )}
        {error && (
          <div>
            <h3>We were unable to access your camera and microphone</h3>
            <div>{error}</div>
          </div>
        )}
      </div>
    );
  }
};

export default WithMediaDevicePermission;
