import { createTheme } from '@material-ui/core/styles';
import colors from 'styles/style-lib';

const theme = createTheme({
  palette: {
    primary: {
      main: colors.orange,
    },
    secondary: {
      main: colors.black,
    },
  },
  breakpoints: {
    // Matches screens.js (2xl not supported)
    values: {
      xs: 480,
      sm: 600,
      md: 768,
      lg: 1024,
      xl: 1224,
    },
  },
  typography: {
    fontFamily: '"Inter", sans-serif',
  },
  overrides: {
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'white',
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: 0,
        paddingTop: 3,
        paddingBottom: 3,
        fontSize: 16,
        fontWeight: 'normal',
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiDialogTitle: {
      root: {
        margin: 0,
        paddingTop: 0,
        paddingRight: 8,
        paddingBottom: 0,
        paddingLeft: 24,
        height: 56,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: `inset 0 -1px 0 0 ${colors.grayd}`,
      },
    },
    MuiDialogContent: {
      root: {
        paddingTop: 24,
        paddingBottom: 24,
      },
    },
    MuiLink: {
      root: {
        cursor: 'pointer',
      },
    },
    MuiDialogActions: {
      root: {
        margin: 0,
        padding: 24,
        '> *:not(:last-child)': {
          marginRight: 8,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: 'rgba(0, 0, 0, 0.23)',
        },
        '&$focused $notchedOutline': {
          borderColor: 'rgba(0, 0, 0, 0.23)',
          borderWidth: 1,
          outline: 'none',
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiCheckbox: {
      disableRipple: true,
    },
  },
});

export default theme;
