import * as F from 'shared/shared/Functional';
import { AuthStateContext } from 'components/App/AuthStateContext';
import { Message } from 'components/common';
import { RESEND_CONFIRMATION_EMAIL_MUTATION } from 'utils/gql';
import { ResendEmailConfirmation, ResendEmailConfirmationVariables } from 'generated/ResendEmailConfirmation';
import { errorCodeHandlers } from 'components/auth-forms/fields';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import FieldLayout from 'components/common/FieldLayout/FieldLayout';
import Input2 from 'components/common/Input2/Input2';
import React, { useContext, useState } from 'react';
import isEmail from 'validator/lib/isEmail';
import useMutationFormHelpers from 'components/forms/useMutationFormHelpers';

type FormT = {
  email: string;
};

const ResendConfirmationEmailForm = () => {
  const [resendConfirmationEmail] = useMutation<ResendEmailConfirmation, ResendEmailConfirmationVariables>(
    RESEND_CONFIRMATION_EMAIL_MUTATION
  );
  const email = useContext(AuthStateContext)?.email;
  const formContext = useForm<FormT>({ defaultValues: { email } });
  const [successEmail, setSuccessEmail] = useState<string>();
  const { errors, watch, register } = formContext;
  const {
    Form,
    SubmitButton,
    FormLevelMessages,
    isSubmitting,
    FormStateContextProvider,
    onSubmit,
  } = useMutationFormHelpers<FormT, ResendEmailConfirmation, ResendEmailConfirmationVariables>({
    formContext,
    formToVariables: F.identity,
    mutation: resendConfirmationEmail,
    resultKey: 'resendConfirmationEmail',
    onSuccess: (val) => {
      setSuccessEmail(watch('email'));
    },
  });

  return (
    <div className="max-w-xl">
      <p className="my-5">Welcome to Mixily! To get started, you need to confirm your email address</p>
      <FormStateContextProvider onSubmit={onSubmit} isSubmitting={isSubmitting} formContext={formContext}>
        <Form>
          <FieldLayout
            label="Email address"
            error={errors.email}
            errorCodeHandlers={errorCodeHandlers.promptForRegistrationWhenEmailAuthNotFound}
          >
            <Input2
              name="email"
              type="email"
              ref={register({
                required: true,
                validate: (value: string) => (!isEmail(value) ? 'Please enter a valid email address' : true),
              })}
              disabled={isSubmitting}
              placeholder="you@example.com"
            />
          </FieldLayout>
          <FormLevelMessages className="mt-4" />
          <SubmitButton className="mt-4" label="Resend Confirmation Email" submittingLabel="Resending..." />
        </Form>
      </FormStateContextProvider>
      {successEmail && (
        <Message type="positive" compact className="mt-6">
          Email sent to {successEmail}. Click the link inside to confirm your password.
        </Message>
      )}
    </div>
  );
};

export default ResendConfirmationEmailForm;
