import { VirtualVenuePubnubProvider } from 'components/VirtualVenue/contexts/VirtualVenuePubnubContext';
import { identity } from 'shared/shared/Functional';
import { initialPubNubState, useVenuePubNub } from 'components/VirtualVenue/pubnub/pubnub';
import { useVirtualVenue } from 'components/VirtualVenue/contexts/VirtualVenueContext';
import {
  useVirtualVenueDispatch,
  useVirtualVenueState,
} from 'components/VirtualVenue/contexts/VirtualVenueStateContext';
import { useVirtualVenueDisplay } from 'components/VirtualVenue/contexts/VirtualVenueDisplayContext';
import React from 'react';

const RealPubnubProvider: React.FC = (props) => {
  const venue = useVirtualVenue();
  const venueState = useVirtualVenueState();
  const venueDispatch = useVirtualVenueDispatch();
  const [pubnubState, pubnubDispatch] = useVenuePubNub(venue.id, venueDispatch, venueState.name);
  return (
    <VirtualVenuePubnubProvider value={{ pubnubState, pubnubDispatch }}>{props.children}</VirtualVenuePubnubProvider>
  );
};

export const MockPubnubProvider: React.FC = (props) => (
  <VirtualVenuePubnubProvider value={{ pubnubState: initialPubNubState, pubnubDispatch: identity }}>
    {props.children}
  </VirtualVenuePubnubProvider>
);

const PubnubProvider: React.FC = (props) => {
  // Doesn't really do anything yet -- the top-level VirtualVenue component is always rendered in 'live' mode for now
  const { isLiveMode } = useVirtualVenueDisplay();
  const Provider = isLiveMode ? RealPubnubProvider : MockPubnubProvider;
  return <Provider>{props.children}</Provider>;
};

export default PubnubProvider;
