import React from 'react';

import Dialog from 'components/common/Dialog/Dialog';
import ImagePicker, { Props as ImagePickerProps } from 'components/EventCreateUpdateForm/ImagePicker/index';

interface Props extends ImagePickerProps {
  open: boolean;
  onClose: () => void;
}

const IPModal: React.FC<Props> = ({ open, onClose, ...rest }) => {
  return (
    <Dialog size="md" isOpen={open} onClose={onClose}>
      <ImagePicker {...rest} />
    </Dialog>
  );
};

export default IPModal;
