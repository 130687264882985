import { DocumentNode } from 'graphql';
import apolloClient from 'api/apolloClient';

interface ApiResult {
  ok: boolean;
}

export interface SuccessResult<T = any> extends ApiResult {
  ok: true;
  data: T;
}

export interface FailureResult extends ApiResult {
  ok: false;
  errorMessage: string;
}

export const GenericError: FailureResult = {
  ok: false,
  errorMessage: 'Sorry, something went wrong.',
};

export const queryToPromise = async (query: DocumentNode, variables?: any) => {
  const result = apolloClient.query({
    query: query,
    fetchPolicy: 'network-only',
    variables,
  });

  return result;
};
