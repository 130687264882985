import Input2 from 'components/common/Input2/Input2';
import React, { ChangeEvent } from 'react';

interface Props {
  value: number;
  adornment: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const ChannelInput = (props: Props) => {
  return (
    <Input2
      inputMode="numeric"
      placeholder="255"
      size="sm"
      value={props.value}
      startAdornment={{
        component: (
          <span className="font-bold text-gray-500" style={{ fontSize: '13px' }}>
            {props.adornment}
          </span>
        ),
        paddingSize: 6.5,
        clickable: false,
      }}
      max={255}
      onChange={props.onChange}
      className="pr-2 text-black rounded-full"
      style={{ fontSize: '13px' }}
      classes={{ root: '' }}
    />
  );
};

export default ChannelInput;
