import * as Sentry from '@sentry/browser';
import React from 'react';
import TextLink from 'components/common/TextLink';
import classNames from 'classnames';
import styles from 'components/common/ErrorBoundary.module.scss';

interface Props {
  children: React.ReactNode;
  root?: boolean;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    Sentry.captureException(error);
    console.error('ErrorBoundary', error, errorInfo);
  }

  render() {
    const { root, children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <div
          className={classNames(styles.Error, {
            [styles.root]: root,
          })}
        >
          <div className={styles.message}>
            Something went wrong and we're on it. Try{' '}
            <TextLink onClick={() => window.location.reload()}>reloading the page</TextLink>
          </div>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
