import * as h from 'utils/helpers';
import { Link } from 'components/common';
import { ReadOrder_order } from 'generated/ReadOrder';
import { RedirectToPath } from 'components/Redirect';
import { reverse } from 'router';
import React, { useState } from 'react';
import useInterval from 'utils/hooks/useInterval';

interface Props {
  order: ReadOrder_order;
}

const OrderCanceled: React.FC<Props> = ({ order }) => {
  const [secondsLeft, setSecondsLeft] = useState<number>(5);

  useInterval(() => setSecondsLeft(secondsLeft ? secondsLeft - 1 : 0), 1000);

  const event = order.tickets[0].ticketType.event;
  const eventUrl = reverse('event_read', { id: event.id });

  if (secondsLeft) {
    return (
      <>
        <p>You left the checkout page without completing your purchase. </p>
        <p>
          Returning to <Link href={eventUrl}>{event.title}</Link> in {secondsLeft} {h.pluralize('second', secondsLeft)}
          ...
        </p>
      </>
    );
  }

  return (
    <div>
      Redirecting...
      <RedirectToPath path={eventUrl} />
    </div>
  );
};

export default OrderCanceled;
