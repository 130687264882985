import './EmojiPicker.module.scss';
import 'emoji-mart/css/emoji-mart.css';
import { EmojiData, Picker } from 'emoji-mart';
import { Popover } from '@material-ui/core';
import React from 'react';
import fscreen from 'fscreen';

interface Props {
  onSelect: (emoji: EmojiData) => void;
  onClose: () => void;
  isOpen: boolean;
  anchorEl: React.RefObject<HTMLElement>;
}

const EmojiPicker = ({ onSelect, isOpen, anchorEl, onClose }: Props) => {
  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl.current}
      keepMounted
      transitionDuration={0}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      className="mt-2 -ml-2"
      // MUI Poppers that can be children of a fullscreened element must conditionally use that element as their container
      // to behave correctly. Otherwise if on body they aren't available in full screen, and if on the fullscreenable element, they can get cut off when not fullscreened.
      container={fscreen.fullscreenElement}
    >
      {isOpen && <Picker emoji="" title="" native={true} onSelect={onSelect}></Picker>}
    </Popover>
  );
};

export default EmojiPicker;
