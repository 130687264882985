import { getLocationIcon } from 'components/widgets/StreamLinkWidget';
import IconWithText from 'components/common/IconWithText/IconWithText';
import LinkifyAddress from 'components/pages/EventReadPage/LinkifyAddress';
import React from 'react';

interface EventWhereFragment {
  location: string | null;
  appendAccessTokenToUrl?: boolean;
  proxyVirtualLocation?: boolean;
}

interface Props {
  event: EventWhereFragment;
  link?: boolean; // defaults to true
  alignTop?: boolean;
}

const Where = ({ event, link = true, alignTop }: Props) => {
  const isVirtual = !!event.appendAccessTokenToUrl || !!event.proxyVirtualLocation;
  const text = event.location || 'TBD';
  const locationIcon = getLocationIcon(text);
  const isKnownStreamingService = locationIcon !== 'generic' && locationIcon !== 'place';
  return (
    <IconWithText
      icon={isKnownStreamingService ? locationIcon : 'pin'}
      alignTop={alignTop}
      streaming={isKnownStreamingService}
    >
      {isVirtual ? 'Virtual Event: Link provided after registration' : link ? <LinkifyAddress text={text} /> : text}
    </IconWithText>
  );
};

export default Where;
