import { Button } from 'components/common';
import { DELETE_INVITE_MUTATION } from 'utils/gql';
import { DeleteInvite, DeleteInviteVariables } from 'generated/DeleteInvite';
import { Invite, renderName } from 'models/invite';
import { useMutation } from '@apollo/client';

import Dialog from 'components/common/Dialog/Dialog';
import React from 'react';

interface Props {
  invite: Invite;
  onClose: () => void;
  open: boolean;
}

export const ConfirmDeleteInvite = (props: Props) => {
  const { onClose, invite, open } = props;
  const [doDeleteInvite] = useMutation<DeleteInvite, DeleteInviteVariables>(DELETE_INVITE_MUTATION);

  const handleConfirm = () => {
    doDeleteInvite({ variables: { ids: [invite.id] } });
    onClose();
  };
  return (
    <Dialog
      title="Are you sure?"
      isOpen={open}
      onClose={onClose}
      actions={[
        <Button key="cancel" color="secondary" onClick={onClose} label="Cancel" small />,
        <Button key="delete" color="red" onClick={handleConfirm} label="Yes, delete invitation" small />,
      ]}
    >
      <div>Are you sure you want to uninvite {renderName(invite)}?</div>
    </Dialog>
  );
};

export default ConfirmDeleteInvite;
