import { Currency } from 'shared/shared/types';
import { WidgetProps } from 'components/widgets';
import QuantityDropdown from 'components/common/QuantityDropdown/QuantityDropdown';
import React from 'react';
import TicketPriceWidget from './TicketPriceWidget/TicketPriceWidget';
import styles from './TicketPriceAndQuantityWidget.module.scss';

export interface TicketPriceAndQuantityValue {
  price: number | null;
  quantity: number;
}

interface Attrs {
  currency: Currency;
  fixedPrice: boolean;
  minQuantity?: number;
  maxQuantity?: number;
}

const TicketPriceAndQuantityWidget: React.FC<WidgetProps<TicketPriceAndQuantityValue, Attrs>> = (props) => {
  const { value, disabled, onChange, attrs } = props;
  const { price, quantity } = value;
  const { currency, fixedPrice, minQuantity, maxQuantity } = attrs!;

  function handleChangeTicketPrice(price: number | null) {
    onChange({ ...value, price });
  }

  function handleChangeQuantity(quantity: number) {
    onChange({ ...value, quantity });
  }

  return (
    <div className={styles.container}>
      <div>
        <label className={styles.label}>{fixedPrice ? 'Ticket Price' : 'Contribution Amount'}</label>
        <TicketPriceWidget
          value={price}
          onChange={handleChangeTicketPrice}
          disabled={disabled}
          attrs={{ readOnly: fixedPrice, className: styles.priceWidget, currency }}
        />
      </div>
      <div className={styles.quantity}>
        <label className={styles.label}>Qty</label>
        <QuantityDropdown
          value={quantity}
          onChange={handleChangeQuantity}
          disabled={disabled}
          attrs={{ min: minQuantity, max: maxQuantity }}
        />
      </div>
    </div>
  );
};

export default TicketPriceAndQuantityWidget;
