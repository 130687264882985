import { VirtualVenueData } from 'components/VirtualVenue/types';
import { VirtualVenueDisplayProvider } from 'components/VirtualVenue/contexts/VirtualVenueDisplayContext';
import { VirtualVenueThemeProvider, defaultTheme } from 'components/VirtualVenue/contexts/VirtualVenueThemeContext';
import { getURLParam } from 'utils/urls';
import { themeToColors } from 'components/VirtualVenue/utils';
import React from 'react';
import VirtualVenueDisplay from 'components/VirtualVenue/VirtualVenueDisplay/VirtualVenueDisplay';
import VirtualVenueOuter from 'components/VirtualVenue/VirtualVenueDisplay/VirtualVenueOuter';

interface Props {
  virtualVenueData: VirtualVenueData;
  isEmbedded?: boolean;
}

const VirtualVenue = ({ virtualVenueData, isEmbedded = false }: Props) => {
  const colors = virtualVenueData.theme ? themeToColors(virtualVenueData.theme) : defaultTheme;
  const isDemo = !!getURLParam('demoMode');

  return (
    <VirtualVenueDisplayProvider initialSetting={{ isEmbedded, mode: isDemo ? 'demo' : 'live' }}>
      <VirtualVenueThemeProvider initialTheme={{ ...colors }}>
        <VirtualVenueOuter virtualVenue={virtualVenueData}>
          <VirtualVenueDisplay />
        </VirtualVenueOuter>
      </VirtualVenueThemeProvider>
    </VirtualVenueDisplayProvider>
  );
};

export default VirtualVenue;
