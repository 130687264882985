import { Button, Confirm } from 'components/common';
import { addSnackbarMessage } from 'utils/eventEmitter';
import { isAuthenticated } from 'models';
import { mixilyAPI } from 'utils/api';
import React, { useState } from 'react';
import queryUser from 'api/query-user';
import useUser from 'utils/hooks/user/useUser';

const StripeDeauthorizeDialog: React.FC = () => {
  const { user } = useUser();
  const [open, setOpen] = useState(false);

  function deauthorize() {
    if (!isAuthenticated(user) || !user.stripeAccountId) {
      return;
    }

    mixilyAPI
      .deauthorizeStripeAccount(user.stripeAccountId)
      .then((response) => {
        if (response.ok) {
          addSnackbarMessage('Deauthorized connection to Stripe', 'success');
          queryUser();
        } else {
          addSnackbarMessage(response.error?.message, 'error');
        }
        setOpen(false);
      })
      .catch(() => {
        addSnackbarMessage('Error connecting to Stripe', 'error');
        setOpen(false);
      });
  }

  return (
    <>
      <Button onClick={() => setOpen(true)} label="Deauthorize" />
      <Confirm
        open={open}
        title="Are you sure?"
        text="Are you sure you want to deauthorize the connection to Stripe? You will no longer be able to sell tickets or ask for donations."
        onConfirm={deauthorize}
        yesLabel="Deauthorize"
        onCancel={() => setOpen(false)}
      />
    </>
  );
};

export default StripeDeauthorizeDialog;
