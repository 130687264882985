import { boolConfig, loadConfig, strConfig } from 'shared/shared/config';

const nodeEnv = loadConfig('NODE_ENV');
const isDev = nodeEnv === 'development';
const isTest = nodeEnv === 'test';
const isProd = nodeEnv === 'production';

const config = {
  nodeEnv: loadConfig('NODE_ENV'),
  isDev,
  isTest,
  isProd,
  release: loadConfig('REACT_APP_RELEASE', strConfig('')),
  sentry: {
    dsn: loadConfig('REACT_APP_SENTRY_DSN', strConfig('')),
  },
  stripePublishableKey: loadConfig('REACT_APP_STRIPE_PUBLISHABLE_KEY'),
  stripeClientId: loadConfig('REACT_APP_STRIPE_CLIENT_ID'),
  stripePriceId: loadConfig('REACT_APP_STRIPE_PRICE_ID'),
  stripeVirtualVenueCommunityPriceId: loadConfig('REACT_APP_STRIPE_VIRTUAL_VENUE_COMMUNITY_PRICE_ID'),
  stripeVirtualVenueIndividualPriceId: loadConfig('REACT_APP_STRIPE_VIRTUAL_VENUE_INDIVIDUAL_PRICE_ID'),
  zoomClientId: loadConfig('REACT_APP_ZOOM_CLIENT_ID'),
  useInsecureHttp: loadConfig('REACT_APP_USE_INSECURE_HTTP', boolConfig(false)),
  hostname: loadConfig(
    'REACT_APP_HOSTNAME',
    strConfig(`${loadConfig('HEROKU_APP_NAME', strConfig('mixily-staging'))}.herokuapp.com`)
  ),
  googleApiMapsKey: loadConfig('REACT_APP_GOOGLE_API_KEY'),
  googleApiClientId: loadConfig('REACT_APP_GOOGLE_API_CLIENT'),
  pusherKey: loadConfig('REACT_APP_PUSHER_KEY'),
  pusherCluster: loadConfig('REACT_APP_PUSHER_CLUSTER', strConfig('us2')),
  manualCinemaOverrides: {
    'Deaf and Hard of Hearing Ticket': loadConfig(
      'REACT_APP_MC_DEAF_CONTENT',
      strConfig(
        '<iframe class="w-full h-full" src="https://www.marquee.tv/embed/player?filmId=a2cf11d8-b526-450f-a7bc-e0a4d9093847" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>'
      )
    ),
    'Blind and Low Vision Ticket': loadConfig(
      'REACT_APP_MC_BLIND_CONTENT',
      strConfig(
        '<iframe class="w-full h-full" src="https://www.marquee.tv/embed/player?filmId=d1c51f00-6c2b-4271-ac90-5207289fd9e2" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>'
      )
    ),
  },
  pubNub: {
    publishKey: loadConfig('REACT_APP_PUBNUB_PUBLISH_KEY', strConfig('')),
    subscribeKey: loadConfig('REACT_APP_PUBNUB_SUBSCRIBE_KEY', strConfig('')),
  },
  // Must be a UTC timestamp in the format ('2021-04-06T13:12:03')
  rtpRecordingLaunchDate: loadConfig('REACT_APP_RTP_LAUNCH_DATE', strConfig('2021-04-06T13:12:03')),
  virtualVenueLaunched: loadConfig('REACT_APP_VIRTUAL_VENUE_LAUNCHED', boolConfig(false)),
};

if (isDev || isTest) {
  console.log(config);
}

export default config;
