import { Loading } from 'components/common';
import { ORDER_WITH_EVENT_BY_LOCATION_ID_QUERY } from 'utils/gql';
import { OrderWithEventByLocationId, OrderWithEventByLocationIdVariables } from 'generated/OrderWithEventByLocationId';
import { analyticsAPI as analytics } from 'utils/api';
import { useQuery } from '@apollo/client';

import React, { ReactNode, useEffect } from 'react';
import ViewerPresenceChecker from 'components/pages/VenuePage/AccessControl/ViewerPresenceChecker';
import moment from 'moment';

interface Props {
  accessToken: string;
  eventId: string;
  children: ReactNode;
  renderNoAccess: Function;
  renderTooManyViewers?: Function;
  skipTokenCheck?: boolean;
}

const AccessControl = ({
  eventId,
  accessToken,
  children,
  renderNoAccess,
  renderTooManyViewers,
  skipTokenCheck,
}: Props) => {
  const { loading, error, data } = useQuery<OrderWithEventByLocationId, OrderWithEventByLocationIdVariables>(
    ORDER_WITH_EVENT_BY_LOCATION_ID_QUERY,
    {
      variables: { locationId: accessToken },
    }
  );
  const accessTokenOrder = !loading && !error && data ? data.orderByLocationId : undefined;
  const accessTokenEvent = accessTokenOrder?.tickets[0].ticketType?.event;
  const eventEndTime = accessTokenEvent?.endTime
    ? moment(accessTokenEvent.endTime!).add(3, 'hours')
    : moment(accessTokenEvent?.startTime ?? undefined).add(12, 'hours');
  const eventIsOver = eventEndTime.isSameOrBefore(moment());
  const hasValidAccess = skipTokenCheck || (accessTokenEvent?.id === eventId && !eventIsOver);
  const errorMessage = !accessToken
    ? 'Missing order ID, please use a valid event link'
    : eventIsOver
    ? 'The event has ended, this link is no longer active'
    : 'Invalid order ID, please double check the event link';

  const errorType = !accessToken ? 'Missing Access Token' : eventIsOver ? 'Event Ended' : 'Invalid Access Token';

  useEffect(() => {
    if (!(hasValidAccess || loading)) {
      analytics.track(`Virtual Venue Error - ${errorType}`, {
        accessToken,
        eventId,
      });
    }
  }, [errorType, accessToken, hasValidAccess, loading, eventId]);

  if (loading) {
    return (
      <div className="mt-6">
        <Loading size="medium" />
      </div>
    );
  }

  if (hasValidAccess) {
    return (
      <ViewerPresenceChecker accessToken={accessToken} renderTooManyViewers={renderTooManyViewers ?? renderNoAccess}>
        {children}
      </ViewerPresenceChecker>
    );
  }
  return renderNoAccess(errorMessage);
};

export default AccessControl;
