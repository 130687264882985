// Custom typed wrappers around local storage. Ensures getter and setter use a matching type `T`.
// (We don't want to use local storage directly, because it returns an `any` type.)
// TODO: fix type for case where `get` is called before `set` (can return undefined)

import { EventWithExistQuery_event } from 'generated/EventWithExistQuery';
import { PreferredView } from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/contexts/CallConfigContext';
import { VirtualVenueCreateVariables } from 'generated/VirtualVenueCreate';

const _getItem = <T = void>(key: string): T | undefined => {
  try {
    const json = localStorage.getItem(key);
    if (typeof json === 'string') {
      return JSON.parse(json);
    }
  } catch (err) {
    return undefined;
  }
};

const _setItem = (key: string, value: any) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (err) {
    return;
  }
};

type SimpleLocalStore<T> = {
  get: () => T | undefined;
  set: (val: T) => void;
};
// `U` represents the argument we need to construct the key
type KeyedLocalStore<T, U> = {
  get: (arg: U) => T | undefined;
  set: (arg: U, val: T) => void;
};

function makeSimpleLocalStore<T>(key: string): SimpleLocalStore<T> {
  return {
    get: () => _getItem(key) as T,
    set: (val: T) => _setItem(key, val),
  };
}

function makeKeyedLocalStore<T, U>(makeKey: (arg: U) => string): KeyedLocalStore<T, U> {
  return {
    get: (arg: U) => _getItem(makeKey(arg)),
    set: (arg: U, val: T) => _setItem(makeKey(arg), val),
  };
}

interface LocalInviteeType {
  email: string;
  name: string;
}

export const localStore = {
  demoEvent: makeSimpleLocalStore<EventWithExistQuery_event | null>('event'),
  creatingDemoEvent: makeSimpleLocalStore<boolean>('creatingDemoEvent'),
  demoVirtualVenue: makeSimpleLocalStore<VirtualVenueCreateVariables | null>('demoVirtualVenue'),
  creatingDemoVirtualVenue: makeSimpleLocalStore<boolean>('creatingDemoVirtualVenue'),
  anonBoughtTickets: makeKeyedLocalStore<string, string>((eventId: string) => `anonEventData:${eventId}:boughtTickets`),
  venueDisplayName: makeSimpleLocalStore<string>('venueDisplayName'),
  venueDefaultDevices: makeSimpleLocalStore<{ audioinput: string; audiooutput: string; videoinput: string }>(
    'VenueDefaultDevices'
  ),
  apitoken: makeSimpleLocalStore<string>('apitoken'),
  pendingInvitees: makeKeyedLocalStore<LocalInviteeType[], string>(
    (eventId: string) => `eventLocalPendingInvites:${eventId}`
  ),
  venueViewPreference: makeSimpleLocalStore<PreferredView>('venueViewPreference'),
};
