import { Event, parseEvent } from 'models';
import { EventCalendarListItem } from 'components/pages/EventCalendarPage/EventCalendarPage';
import EventCalendarPageDropdown from 'components/pages/EventCalendarPage/EventCalendarPageDropdown';
import HostIcon from '@material-ui/icons/VerifiedUser';
import HostedBy from 'components/HostedBy/HostedBy';
import Link from 'components/Link';
import React from 'react';
import When from 'components/When/When';
import Where from 'components/Where/Where';
import moment from 'moment';
import styles from './EventCalendarPageEventList.module.scss';

interface ItemProps {
  event: Event;
  personIsHost?: boolean;
}

const getImageStyle = (imageUrl: string, width: number, height: number) => {
  return {
    backgroundSize: 'cover',
    backgroundImage: `url(${imageUrl.replace('/resize/2400x', '/resize/800x')})`,
  };
};

const EventListItem = ({ event, personIsHost }: ItemProps) => {
  return (
    <Link className={styles.EventListItem} href={event.getUrl()}>
      {!!event.themePic && (
        <div className={styles.image} style={event.themePic ? getImageStyle(event.themePic, 2 * 660, 2 * 472) : {}} />
      )}
      <div className={styles.details}>
        <div className={styles.header}>
          <div className="mb-2 -mt-1 truncate h3-responsive md:leading-normal">{event.title}</div>
        </div>
        <When event={event} />
        <Where event={event} link={false} />
        <div className="flex items-center justify-between">
          <HostedBy hostedByText={event.hostedByText} noHostedBy />
          {personIsHost && (
            <div className="flex items-center">
              <HostIcon className="mr-1 text-link-color" style={{ fontSize: '1.25rem' }} />{' '}
              <span className="text-base sm:text-lg text-link-color">Host</span>
            </div>
          )}
        </div>
        {personIsHost && (
          <div className="absolute top-0 right-0 mt-4 mr-3">
            <EventCalendarPageDropdown event={event} />
          </div>
        )}
      </div>
    </Link>
  );
};

interface Props {
  eventItems: EventCalendarListItem[];
  reverse?: boolean;
  sortByTime?: boolean;
}

const compareEvents = (a: EventCalendarListItem, b: EventCalendarListItem): number => {
  // TBD events are "latest"
  if (!a.startTime) {
    return 1;
  }

  if (!b.startTime) {
    return -1;
  }

  // Most recent events up top
  return moment.utc(a.startTime).diff(moment.utc(b.startTime));
};

const EventCalendarPageEventList = ({ eventItems, reverse, sortByTime = true }: Props) => {
  if (!eventItems.length) {
    return <div>No events.</div>;
  }

  if (sortByTime) {
    eventItems.sort(compareEvents);
    if (reverse) {
      eventItems.reverse();
    }
  }

  return (
    <div className="vstack-small">
      {eventItems.map((event) => (
        <EventListItem key={event.id} event={parseEvent(event)} personIsHost={event.personIsHost} />
      ))}
    </div>
  );
};

export default EventCalendarPageEventList;
