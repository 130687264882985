import './SelectInviteesWidget.scss';
import { CONTACTS_AND_LIST_QUERY, EVENTS_FOR_PERSON_QUERY, EVENT_INVITEES_QUERY } from 'utils/gql';
import { Contact, ContactSelectOption, PersonEvent, SelectOption } from './types';
import { ContactsAndListsQuery } from 'generated/ContactsAndListsQuery';
import { EventInvitees_eventInvitees } from 'generated/EventInvitees';
import { MultiValueProps } from 'react-select';
import { User, isAuthenticated } from 'models';
import { WidgetProps } from 'components/widgets/index';
import {
  contactToSelectOption,
  emailInInviteeList,
  isInvalidEmailInvitee,
  isValidNewOption,
  queryResultToSelectOptions,
} from './helpers';
import { queryToPromise } from 'api/utils';
import AsyncCreatableSelect from 'react-select/async-creatable';
import CustomMultiValueLabel from 'components/pages/EventReadPage/InvitationSender/InviteForm/SelectInviteesWidget/CustomMultiValueLabel';
import CustomOption from 'components/pages/EventReadPage/InvitationSender/InviteForm/SelectInviteesWidget/CustomOption';
import EditDialog from 'components/pages/EventReadPage/InvitationSender/InviteForm/SelectInviteesWidget/EditDialog';
import Fuse from 'fuse.js';
import Mark from 'mark.js';
import React, { Component } from 'react';
import addressparser from 'addressparser';
import colors from 'styles/style-lib';
import validator from 'validator';

const SelectInviteesWidget = (props: WidgetProps<Contact[]>) => (
  <SelectInvitees
    {...props}
    disabledOptions={props.attrs.excludeItems}
    eventId={props.attrs.eventId}
    user={props.attrs.user}
  />
);

interface Props extends WidgetProps<Contact[]> {
  disabledOptions: Set<{}>;
  eventId: string;
  user: User;
}

interface State {
  inputValue: string;
  menuIsOpen: boolean;
  events: PersonEvent[];
  editing?: string; // email address of invitee input being edited; must be present in props.value
}

const styles = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: 0,
    minHeight: '56px', // from style guide. Don't use 'height' directly because it needs to expand.
    boxShadow: 'none !important',
  }),
  indicatorsContainer: (provided: any) => ({
    // Without this style, vertical centering is off in
    // the control element. The placeholder text apppears
    // too high in the input.
    ...provided,
    display: 'none',
  }),
  multiValue: (styles: any, { data }: { data: SelectOption }) => {
    if (isInvalidEmailInvitee(data.data as Contact)) {
      return {
        ...styles,
        backgroundColor: colors.redBg,
      };
    } else {
      return styles;
    }
  },
  multiValueLabel: (styles: any, { data }: { data: SelectOption }) => {
    if (isInvalidEmailInvitee(data.data as Contact)) {
      return {
        ...styles,
        color: 'black',
      };
    } else {
      return styles;
    }
  },
  placeholder: (styles: any) => ({
    paddingLeft: '12px !important',
    position: 'absolute',
  }),
};

class SelectInvitees extends Component<Props, State> {
  state: State = {
    inputValue: '',
    menuIsOpen: false,
    events: [],
  };

  private myRef = React.createRef<any>();

  private parseInput = (): boolean => {
    // Parse the user's typed-input into tokenized email addresses.
    // Returns whether or not any input value was found

    const { value: formValue, onChange } = this.props;
    const inputValue: string = this.myRef.current.inputRef.value;

    // Add a comma after email addresses if missing so that users can
    // copy/paste lists of email addresses from spreadsheets and other sources.
    const preParsed = inputValue.replace(/@[^\s,]* /g, '$&, ');
    const addresses = addressparser(preParsed);

    const newContacts: Contact[] = [];
    for (let { address: email, name } of addresses) {
      if (email && validator.isEmail(email)) {
        if (!emailInInviteeList(email, formValue)) {
          newContacts.push({ email, name });
        }
      }
    }

    this.setState({ inputValue: '', menuIsOpen: false });
    onChange(formValue.concat(newContacts));
    return !!addresses.length;
  };

  private handleInputChange = (newValue: string, action: any) => {
    // On these actions, react-select clears the input by default, which we don't want
    // See https://github.com/JedWatson/react-select/issues/3189#issuecomment-442792535
    const isBlur = action.action === 'input-blur' || action.action === 'menu-close';
    if (!isBlur) {
      this.setState({ inputValue: newValue, menuIsOpen: true });
    } else {
      newValue = this.state.inputValue;
    }
    // If they typed (pasted) text with a comma, parse the input into email addresses.
    // Do the same if they blur the form
    // This lets the user paste a liste of email addresses.
    // Non-goal: supporting commas within display names, e.g.: "John, Esq." <john@example.com>
    if (isBlur || newValue.indexOf(',') !== -1) {
      this.parseInput();
    }
  };

  private handleKeyDown = (ev: React.KeyboardEvent) => {
    // Default behavior at https://github.com/JedWatson/react-select/blob/v2.2.0/src/Select.js#L1186
    // Navigate to innermost Select ref...
    const innerSelect = this.myRef.current;
    const { focusedOption } = innerSelect.state;
    // NB the props/state are different at each level of Select ref nesting
    // const { isComposing, focusedOption } = select.state;

    if (ev.keyCode === 13 && !focusedOption) {
      const hadValue = this.parseInput();
      if (hadValue) {
        // Enter to select --  prevent submit
        ev.preventDefault();
      }
    }
  };

  private selectOptionsToContacts = async (options: SelectOption[]): Promise<Contact[]> => {
    const { user } = this.props;
    const userEmail = isAuthenticated(user) ? user.getPrimaryEmail() : undefined;

    const result: Contact[] = [];
    for (let o of options) {
      if (o.typename === 'ContactType') {
        // recreate object to get rid of `__typename` field from graphql response
        result.push({
          name: o.data.name,
          email: o.data.email,
        });
      } else if (o.typename === 'ContactListType') {
        for (let c of o.data.contacts) {
          // recreate object to get rid of `__typename` field from graphql response
          result.push({
            name: c.contact.name,
            email: c.contact.email,
          });
        }
      } else if (o.typename === 'EventType') {
        const eventId = o.data.id;
        const {
          data: { eventInvitees },
        } = await queryToPromise(EVENT_INVITEES_QUERY, { eventId });
        const inviteesWithoutCurrentUser = eventInvitees.filter(
          (i: EventInvitees_eventInvitees) => i.email !== userEmail
        );

        for (let i of inviteesWithoutCurrentUser) {
          // recreate object to get rid of `__typename` field from graphql response
          result.push({
            name: i.name,
            email: i.email,
          });
        }
      }
    }

    // ensure to only add each contact once
    const added: Record<string, boolean> = {};
    return result.filter((contact) => {
      const email = contact.email;
      if (added[email]) {
        return false;
      } else {
        added[email] = true;
        return true;
      }
    });
  };

  private handleChange = (newValue: SelectOption[] | null) => {
    // As of react-select 3.0, an empty multi-select results in a 'null' value.
    this.selectOptionsToContacts(newValue || []).then((modifiedValue) => this.props.onChange(modifiedValue));
  };

  private loadOptions = async () => {
    const { events, inputValue } = this.state;

    const { data }: { data: ContactsAndListsQuery } = await queryToPromise(CONTACTS_AND_LIST_QUERY, {
      searchQuery: inputValue,
      eventId: this.props.eventId,
    });

    if (!data.contactsAndListsSearch) {
      return [];
    }

    // don't show contacts with score = 0 until user searches
    const contacts = data.contactsAndListsSearch.contacts.filter(
      (contact) => !!inputValue || (!inputValue && contact.score > 0)
    );

    // don't show contact lists until user searches
    const contactLists = data.contactsAndListsSearch.contactLists.filter(() => !!inputValue);

    // max 5 events, sorted by match quality
    const fuse = new Fuse(events, { threshold: 0, keys: ['title'] });
    const filteredEvents: PersonEvent[] = fuse
      .search(inputValue)
      .map((results) => results.item)
      .slice(0, 5) as PersonEvent[];

    return queryResultToSelectOptions({
      contacts,
      contactLists,
      events: filteredEvents,
    });
  };

  private updateHighlight = () => {
    const highlighterContext = this.myRef.current.menuListRef;
    const highlighterInstance = new (Mark as any)(highlighterContext);
    highlighterInstance.mark(this.state.inputValue, {
      acrossElements: true,
      filter(node: any) {
        return node.nodeValue.indexOf('📧') === -1;
      },
    });
  };

  private getValueContact = (email: string): null | [Contact, number] => {
    const { value } = this.props;
    for (let i = 0; i < value.length; i++) {
      const c = value[i];
      if (c.email === email) {
        return [c, i];
      }
    }
    return null;
  };

  componentDidMount() {
    const { eventId, user } = this.props;
    queryToPromise(EVENTS_FOR_PERSON_QUERY).then(({ data }) => {
      const events: PersonEvent[] = []
        .concat(data?.me?.past ?? [])
        .concat(data?.me?.upcoming ?? [])
        .filter(
          (event: PersonEvent) =>
            isAuthenticated(user) &&
            event.owner.id === user.id &&
            event.numInvites &&
            event.numInvites > 1 &&
            event.id !== eventId &&
            !event.isTicketed
        );
      this.setState({ events });
    });
  }

  componentDidUpdate() {
    this.updateHighlight();
  }

  render = () => {
    const { value, disabled, id, disabledOptions } = this.props;
    // const inputValue = 'bay';
    const inputValue = this.state.inputValue;
    const editing = this.state.editing && this.getValueContact(this.state.editing);

    return (
      <>
        <AsyncCreatableSelect
          allowCreateWhileLoading={true}
          blurInputOnSelect={false} // Ensures input gets tokenized on mobile
          cacheOptions={true}
          className={`SelectInviteesWidget`}
          components={{
            // Replace only MultiValueLabel, not whole MultiValueContainer, or else clicking the Remove
            // button puts us into a broken editing state.
            MultiValueLabel: (props: MultiValueProps<ContactSelectOption>) => (
              <CustomMultiValueLabel {...props} setEditing={(email) => this.setState({ editing: email })} />
            ),
            Option: CustomOption,
            DropdownIndicator: null,
            LoadingIndicator: null /** Loading indicator disabled because its not inline and causes bouncing */,
          }}
          createOptionPosition="first" // esp. important with fuzzy search - many results can appear above Create
          formatCreateLabel={(val: string) => `"${val}"`}
          formatOptionLabel={({ label }: SelectOption) => <div dangerouslySetInnerHTML={{ __html: label }} />}
          getNewOptionData={(optionLabel: string): SelectOption => {
            // We handle address parsing logic here so we can properly pass the
            // data to `this.handleChange()` which calls the form's `onChange` handler.
            // Before, an `onChange` call was coupled with other code in `this.parseInput()`
            // and this was causing problems with clicking the dropdown item of an 'addressparsed'
            // input that was typed in. Likely because `this.parseInput()` is triggered in more than
            // just one way. 7/25/19 - PF
            const [{ address: email, name }] = addressparser(optionLabel);
            // addressparser leaves angle brackets in the name, remove them
            const labelString = name ? `${name.replace(/[<>]/g, '')} - ${email}` : email;
            return {
              data: {
                name,
                email,
              },
              typename: 'ContactType',
              label: `"${labelString}"`,
              value: email,
            };
          }}
          id={id}
          inputValue={inputValue}
          isDisabled={disabled || !!this.state.editing}
          isOptionDisabled={(option: any) => disabledOptions.has(option.data.email)}
          isMulti
          isValidNewOption={isValidNewOption}
          loadingMessage={() => null}
          isLoading={false}
          defaultOptions={true}
          loadOptions={this.loadOptions}
          menuIsOpen={this.state.menuIsOpen}
          noOptionsMessage={() => null}
          onChange={this.handleChange}
          onInputChange={this.handleInputChange}
          onKeyDown={this.handleKeyDown}
          openMenuOnClick={true}
          openMenuOnFocus={true}
          onFocus={() => this.setState({ menuIsOpen: true })}
          // Hack: Insert a unicode 'Zero width non-joiner' in the middle of the word 'emails'...
          // This prevents 1Password from picking up the field, which was covering up the dropdown
          // and made it impossible to use
          // placeholder={'Enter emails separated by commas...'}
          placeholder={'Enter e' + String.fromCodePoint(8204) + 'mails separated by commas...'}
          ref={this.myRef}
          styles={styles}
          value={(value || []).map(contactToSelectOption)}
          theme={(theme: any) => {
            return {
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary: colors.black,
              },
            };
          }}
        />
        {editing && (
          <EditDialog
            contactAndIndex={editing}
            onClose={() => this.setState({ editing: undefined })}
            onSubmit={(index: number, updatedContact: Contact) => {
              const { value } = this.props;
              const newValue = value
                .slice(0, index)
                .concat([updatedContact])
                .concat(value.slice(index + 1));
              this.props.onChange(newValue);
              this.setState({ editing: undefined });
            }}
          />
        )}
      </>
    );
  };
}

export default SelectInviteesWidget;
