import './RunReadPage.scss';
import { EventReadViewSkeleton } from 'components/pages/EventReadPage/EventReadViewSkeleton';
import { RUN_READ_QUERY } from 'utils/gql';
import { RunEventId, RunEventIdVariables } from 'generated/RunEventId';
import { localStore } from 'utils/localstore';
import { useQuery } from '@apollo/client';

import Error404Page from 'components/pages/Error404Page';
import Error500Page from 'components/pages/Error500Page';
import EventLayout from 'components/layout/EventLayout/EventLayout';
import EventReadView from 'components/pages/EventReadPage/EventReadView';
import PrivateEventPage from 'components/pages/EventReadPage/PrivateEventPage';
import React from 'react';
import useEventClass from 'utils/hooks/event/useEventClass';
import useSetEventId from 'components/App/globalstate/useSetEventId';

interface Props {
  id: string;
}

const RunReadPage: React.FC<Props> = ({ id }) => {
  const { isDemo, event, eventExists, loading, error } = useEventClass({ required: false });

  const { data, loading: loadingId, error: errorId } = useQuery<RunEventId, RunEventIdVariables>(RUN_READ_QUERY, {
    variables: { id: id! },
  });

  useSetEventId(data?.run?.eventId);

  if (loadingId) {
    return renderLoading();
  }

  if (errorId || !data || !data.run) {
    return <Error500Page />;
  }

  if (loading || (isDemo && error && localStore.creatingDemoEvent.get())) {
    return renderLoading();
  }

  if (error) {
    return <Error500Page />;
  }

  if (!event) {
    return !eventExists ? <Error404Page /> : <PrivateEventPage />;
  }

  return (
    <EventLayout
      title={event.title}
      themePicUrl={event.themePic}
      readPage={true}
      bgColor={event.bgColor}
      bgNoBackdrop={event.bgNoBackdrop}
    >
      <EventReadView event={event} isDemo={isDemo} isRun={true} run={data.run} loading={loading} />
    </EventLayout>
  );
};

const renderLoading = () => {
  return (
    <EventLayout title="Loading...">
      <EventReadViewSkeleton />
    </EventLayout>
  );
};

export default RunReadPage;
