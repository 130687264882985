import { RichText as PrismicRichText } from 'prismic-reactjs';
import { RichTextType } from 'utils/prismic/types';
import { linkResolver } from 'utils/prismic/utils';
import React from 'react';
import classNames from 'classnames';
import styles from './RichText.module.scss';

interface Props {
  className?: string;
  content: RichTextType;
}

const RichText = (props: Props) => {
  const cls = classNames(styles.RichText, props.className);
  return (
    <div className={cls}>
      <PrismicRichText render={props.content} linkResolver={linkResolver} />
    </div>
  );
};

export default RichText;
