import * as m from 'models';
import { EventLayoutContext } from 'components/layout/EventLayout/EventLayout';
import { Link, TextLink } from 'components/common';
import { URLContext } from 'components/RoutingProvider';
import { analyticsAPI as analytics } from 'utils/api';
import { reverse } from 'router';
import AuthedDropdown from 'components/layout/Header/AuthedDropdown';
import React, { useContext } from 'react';
import classNames from 'classnames';
import useUser from 'utils/hooks/user/useUser';

interface NavBarProps {
  user: m.Person;
  withSidebar?: boolean;
}

const AuthenticatedNavBar = (props: NavBarProps) => {
  const { colorClass } = useContext(EventLayoutContext);

  return (
    <>
      <div className={classNames('inline-block', colorClass)}>
        <Link className="only-gte-sm" href={reverse('event_create')}>
          Create an event
        </Link>
      </div>

      {!props.withSidebar && (
        <Link href={reverse('event_calendar')} className={classNames('only-gte-sm', 'text-black', colorClass)}>
          Calendar
        </Link>
      )}

      <AuthedDropdown user={props.user} />
    </>
  );
};

const UnauthenticatedNavBar = () => {
  const { colorClass } = useContext(EventLayoutContext);
  const { authModalStateMutatorSet } = useContext(URLContext);

  return (
    <>
      <div className={classNames('only-gte-sm', colorClass)}>
        <Link href={reverse('event_create')}>Create an event</Link>
      </div>

      <TextLink
        onClick={authModalStateMutatorSet.openLoginModal}
        className={classNames('text-black', colorClass)}
        text="Log in"
      />
      <TextLink
        onClick={authModalStateMutatorSet.openSignupModal}
        className={classNames('text-black', colorClass)}
        text="Sign up"
      />
    </>
  );
};

const LandingPageNavBar = () => {
  const { authModalStateMutatorSet, setPath } = useContext(URLContext);

  const createEvent = () => {
    analytics.track('StartCreateDemoEvent');
    const path = reverse('event_create');
    setPath(path);
  };

  return (
    <>
      <Link className="hidden text-black sm:inline-block" href={reverse('about')}>
        About
      </Link>
      <TextLink onClick={authModalStateMutatorSet.openLoginModal} className="text-black" text="Log in" />
      <TextLink onClick={createEvent} text="Create My Event" />
    </>
  );
};

const NavForUser = (props: { user: m.User; withSidebar?: boolean }) => {
  const { user, withSidebar } = props;
  if (user === m.Unknown) {
    // To avoid flickering, delay rendering until we know who they are.
    return null;
  }
  return m.isAuthenticated(user) ? (
    <AuthenticatedNavBar user={user} withSidebar={withSidebar} />
  ) : (
    <UnauthenticatedNavBar />
  );
};

interface Props {
  landingPage?: boolean;
  withSidebar?: boolean;
}

const HeaderInner = ({ landingPage = false, withSidebar }: Props) => {
  const { user } = useUser();

  return (
    <div className="grid grid-flow-col gap-6">
      {landingPage ? <LandingPageNavBar /> : <NavForUser user={user} withSidebar={withSidebar} />}
    </div>
  );
};

export default HeaderInner;
