import { ErrorCodes } from 'shared/shared/constants';
import { FieldError as RHFFieldError } from 'react-hook-form';
import React, { useEffect, useRef } from 'react';
import styles from './FieldError.module.scss';

type RefField = { value: string; name: string };
type ErrorCode = typeof ErrorCodes[keyof typeof ErrorCodes];
type ErrorFunction = (field: RefField) => JSX.Element;

export type ErrorCodeHandler = Partial<Record<ErrorCode, ErrorFunction>>;

interface Props {
  error?: RHFFieldError;
  errorCodeHandlers?: ErrorCodeHandler;
}

export const getMessage = (error: RHFFieldError, errorCodeHandlers?: ErrorCodeHandler) => {
  if (error.type === 'required') {
    return 'This field is required';
  } else if (error.type === 'server') {
    if (typeof error.message === 'string' && errorCodeHandlers) {
      const Component = errorCodeHandlers[error.message as ErrorCode] as any;
      const value = error.ref?.value ?? '';
      if (Component) {
        return <Component value={value} />;
      }
    }
  }
  return error.message;
};

const FieldError = ({ error, errorCodeHandlers }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const el = ref.current;
    if (error && el && typeof el.scrollIntoView === 'function') {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [error]);
  if (!error) {
    return null;
  }
  const message = getMessage(error, errorCodeHandlers);
  return (
    <div ref={ref} className={styles.FieldError}>
      {message}
    </div>
  );
};

export default FieldError;
