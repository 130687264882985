import { GuestFilter } from 'components/EventGuestList/types';
import { LOADING_INDICATOR_CLASS } from 'utils/constants';
import { MessageGuest, MessageGuestVariables } from 'generated/MessageGuest';
import { Moment } from 'moment';
import { addSnackbarMessage } from 'utils/eventEmitter';
import { getSubmitButtonText } from 'components/MessageCenter/utils';
import { useForm } from 'react-hook-form';
import FieldLayout from 'components/common/FieldLayout/FieldLayout';
import QuillRichTextWidget from 'components/widgets/QuillRichTextWidget/QuillRichTextWidget';
import React, { useEffect } from 'react';
import SendLaterWidget from 'components/widgets/SendLaterWidget/SendLaterWidget';
import styles from './MessageGuestsForm.module.scss';
import useMessageGuestMutation from 'utils/hooks/useMessageGuestMutation';
import useMutationFormHelpers from 'components/forms/useMutationFormHelpers';

interface Props {
  numOfInvites?: number;
  onSuccess?: () => void;
  eventId: string;
  filter: GuestFilter;
  handlePreview(data: { message: string }): void;
}

export type MessageGuestsFormValue = {
  message: string;
  scheduledFor: Moment | null;
};

const MessageGuestsForm = ({ numOfInvites, eventId, filter, handlePreview, onSuccess }: Props) => {
  const [doMessageGuests] = useMessageGuestMutation(eventId);
  const formContext = useForm<MessageGuestsFormValue>({
    defaultValues: { message: '', scheduledFor: null },
  });
  const { setValue, errors, watch, register } = formContext;

  const {
    Form,
    SubmitButton,
    FormLevelMessages,
    FormStateContextProvider,
    isSubmitting,
    onSubmit,
  } = useMutationFormHelpers<MessageGuestsFormValue, MessageGuest, MessageGuestVariables>({
    formContext,
    formToVariables: (value) => {
      return {
        ...value,
        id: eventId,
        filter,
        scheduledFor: value.scheduledFor?.toISOString() ?? null,
      };
    },
    mutation: doMessageGuests,
    resultKey: 'eventMessageGuests',
    onSuccess: (data) => {
      const { isScheduled } = data.eventMessageGuests;
      setValue('message', '');
      const msg = isScheduled ? 'Message scheduled!' : 'Message sent!';
      addSnackbarMessage(msg, 'success');
      if (onSuccess) onSuccess();
    },
  });

  useEffect(() => {
    register(
      { name: 'message' },
      {
        required: true,
        validate: {
          validate: (value) =>
            value.includes(LOADING_INDICATOR_CLASS) ? 'Please wait for all images to finish uploading.' : true,
        },
      }
    );
    register({ name: 'scheduledFor' });
  }, [register]);

  const currentScheduledFor = watch('scheduledFor');
  const disabled = !numOfInvites && !currentScheduledFor;
  const [label, submittingLabel] = getSubmitButtonText(currentScheduledFor, ['Send', 'Sending...']);
  return (
    <FormStateContextProvider onSubmit={onSubmit} isSubmitting={isSubmitting} formContext={formContext}>
      <Form>
        <FieldLayout
          className="mb-4"
          label={<div className="mb-2 label--overline">Schedule</div>}
          error={errors.scheduledFor}
        >
          <SendLaterWidget
            onChange={(val) => setValue('scheduledFor', val)}
            disabled={!!disabled}
            value={currentScheduledFor}
          />
        </FieldLayout>

        <FieldLayout label={<div className="mb-2 label--overline">Message</div>} error={errors.message}>
          <QuillRichTextWidget
            onChange={(val) => setValue('message', val)}
            disabled={!!disabled}
            value={watch('message')}
            attrs={{
              placeholder: 'Email your guests',
              className: styles.MessageWidget,
            }}
          />
        </FieldLayout>

        <div className="mt-4">
          <FormLevelMessages />
        </div>

        <div className="mt-6">
          <SubmitButton
            label={label}
            submittingLabel={submittingLabel}
            disabled={disabled}
            size="14"
            className="w-full"
          />
        </div>
      </Form>
      <span
        className="flex justify-center mx-auto mt-2 opacity-75 cursor-pointer hover:opacity-100 transition-opacity duration-200"
        onClick={() => handlePreview({ message: watch('message') })}
      >
        Preview Email
      </span>
    </FormStateContextProvider>
  );
};

export default MessageGuestsForm;
