import * as F from 'shared/shared/Functional';
import { Dispatch, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { VirtualVenueAction } from 'components/VirtualVenue/contexts/VirtualVenueStateContext';
import { getURLParams } from 'utils/urls';
import PubNub from 'pubnub';
import config from 'config';
import usePrevious from 'utils/hooks/usePrevious';

export const pubnubApi = new PubNub({
  publishKey: config.pubNub.publishKey,
  subscribeKey: config.pubNub.subscribeKey,
} as any);

export const transformPubnubChatMessage = (m: any): ChatMessage => {
  let message = {
    timetoken: m.timetoken.toString(),
    channel: m.channel,
    message: m.message,
  };
  F.objMap(m.actions ?? {}, (actionType: string, reactions: MessageReactions) => {
    if (actionType === 'chat.reaction') {
      message.message.reactions = reactions;
    }
  });
  return message;
};

interface PubNubBaseMessage {
  channel: string;
  timetoken: string;
  message: any;
  actions?: {
    [type: string]: {
      [value: string]: Array<{
        uuid: string;
        actionTimetoken: string | number; // timetoken
      }>;
    };
  };
}

export interface PubnubMessageEvent<T> extends PubNubBaseMessage {
  message: T;
}

interface MessageActionEvent {
  channel: string;
  publisher: string;
  subscription?: string;
  timetoken: string;
  event: string;
  data: PubNub.MessageAction;
}

// Helpful for debugging:
// pubnubApi.addListener(pubnubMessageLogger);

export const getVirtualVenuePubNubChannelName = (venueId: string) => `virtualvenue.${venueId}`;

export const usePubNubSubscribe = (channelName: string, networkStatus: PubNubNetworkStatus) => {
  const lastStatus = usePrevious(networkStatus);
  const resubscribe = lastStatus !== networkStatus && networkStatus === 'PNNetworkUpCategory';
  useEffect(() => {
    pubnubApi.subscribe({ channels: [channelName], withPresence: true });
    return () => pubnubApi.unsubscribe({ channels: [channelName] });
  }, [channelName]);

  useEffect(() => {
    if (resubscribe) {
      pubnubApi.subscribe({ channels: [channelName], withPresence: true });
    }
  }, [channelName, resubscribe]);
};

type RequestState = 'idle' | 'sending' | 'error';

// Our custom-defined state on a PN Presence connection
// See https://www.pubnub.com/docs/platform/presence/presence-state
export type PresenceState = {
  name: null | string; // Null until 1st load
  dailyUserId: null | string; // Null until connected to daily
};

export type EmojiReaction = {
  uuid?: string;
  actionTimetoken?: string | number;
};

export type MessageReactions = {
  [emoji: string]: EmojiReaction[];
};

export type ChatMessage = PubnubMessageEvent<{
  type: 'chat.text';
  text: string;
  name: string;
  reactions: MessageReactions;
}>;

export type RequestUnmuteMessage = PubnubMessageEvent<{
  type: 'request-unmute';
  participant: string;
}>;

export interface VirtualVenueChannelMetadata extends PubNub.ObjectCustom {
  spotlightParticipants: string;
}

type PresenceStateEventOccupant = {
  uuid: string;
  state?: PresenceState;
};

export type Participant = {
  pubnubId: string;
  dailyUserId: string | null;
  name: string | null;
};

export type PubNubVirtualVenueState = {
  presenceState: PresenceState;

  participants: Participant[];

  spotlightParticipants: string[];

  messages: ChatMessage[];

  requestsToUnmute: string[];

  setName: {
    requestState: RequestState;
    name: string;
  };

  setDailyUserId: {
    requestState: RequestState;
    dailyUserId: string | null;
  };

  sendMessage: {
    requestState: RequestState;
    message: string;
  };

  sendRequestToUnmute: {
    requestState: RequestState;
    participant: string;
  };

  sendSpotlightChange: {
    requestState: RequestState;
    participant: string;
    op: 'add' | 'remove';
  };

  sendEmojiReaction: {
    requestState: RequestState;
    emoji: string;
    messageTimetoken: string;
    actionTimetoken?: string | number;
    op: 'add' | 'remove';
  };
};

export const initialPubNubState: PubNubVirtualVenueState = {
  presenceState: {
    name: null,
    dailyUserId: null,
  },

  participants: [] as Participant[],

  spotlightParticipants: [] as string[],

  messages: [] as ChatMessage[],

  requestsToUnmute: [] as string[],

  setDailyUserId: {
    requestState: 'idle',
    dailyUserId: null,
  },

  setName: {
    requestState: 'idle',
    name: '',
  },

  sendMessage: {
    requestState: 'idle',
    message: '',
  },

  sendSpotlightChange: {
    requestState: 'idle',
    participant: '',
    op: 'add',
  },

  sendRequestToUnmute: {
    requestState: 'idle',
    participant: '',
  },

  sendEmojiReaction: {
    requestState: 'idle',
    emoji: '',
    messageTimetoken: '',
    op: 'add',
  },
} as const;

type SetNameAction = { type: 'set-name'; name: string };
type SetNameActionSuccess = { type: 'set-name-success' };
type SetDailyUserIdAction = { type: 'set-daily-user-id'; dailyUserId: string | null };
type SetDailyUserIdActionSuccess = { type: 'set-daily-user-id-success' };
type SendChatMessageAction = { type: 'send-chat-message'; message: string };
type SendChatMessageActionSuccess = { type: 'send-chat-message-success' };
type SendSpotlightChangeAction = { type: 'send-change-spotlight'; participant: string; op: 'add' | 'remove' };
type SendSpotlightChangeActionSuccess = { type: 'send-change-spotlight-success' };
type SendRequestUnmuteAction = { type: 'send-request-unmute'; participant: string };
type SendRequestUnmuteActionSuccess = { type: 'send-request-unmute-success' };
type FetchHistoricalChatMessagesSuccess = { type: 'fetch-historical-messages-success'; messages: ChatMessage[] };
type FetchPresenceStateSuccess = { type: 'fetch-presence-state-success'; occupants: PresenceStateEventOccupant[] };
type SendEmojiReactionMessageAction = {
  type: 'send-emoji-reaction';
  emoji: string;
  messageTimetoken: string;
  actionTimetoken?: string | number;
  operation: 'add' | 'remove';
};
type SendEmojiReactionMessageActionSuccess = { type: 'send-emoji-reaction-success' };
type UpdatePresenceState = { type: 'update-presence-state'; presenceState: PresenceStateEventOccupant };
type ReceiveChatMessage = { type: 'receive-chat-message'; message: ChatMessage };
type ReceiveRequestUnmute = { type: 'receive-request-unmute'; message: RequestUnmuteMessage };
type ReceiveSpotlightChange = { type: 'receive-spotlight-change'; spotlightParticipants: string[] };
type ReceivedChatMessageDeleteAction = { type: 'receive-chat-message-deleted'; message: MessageActionEvent };
type ReceivedEmojiReactionAddedMessageAction = { type: 'receive-chat-reaction-added'; message: MessageActionEvent };
type ReceivedEmojiReactionRemovedMessageAction = { type: 'receive-chat-reaction-removed'; message: MessageActionEvent };
type AcknowledgeRequestUnmute = { type: 'acknowledge-request-unmute'; participant: string };

export type PubNubVirtualVenueAction =
  | SetNameAction
  | SetNameActionSuccess
  | SetDailyUserIdAction
  | SetDailyUserIdActionSuccess
  | SendChatMessageAction
  | SendChatMessageActionSuccess
  | SendSpotlightChangeActionSuccess
  | SendSpotlightChangeAction
  | SendRequestUnmuteAction
  | SendRequestUnmuteActionSuccess
  | FetchHistoricalChatMessagesSuccess
  | FetchPresenceStateSuccess
  | SendEmojiReactionMessageAction
  | SendEmojiReactionMessageActionSuccess
  | UpdatePresenceState
  | ReceiveChatMessage
  | ReceiveRequestUnmute
  | ReceiveSpotlightChange
  | ReceivedChatMessageDeleteAction
  | ReceivedEmojiReactionAddedMessageAction
  | ReceivedEmojiReactionRemovedMessageAction
  | AcknowledgeRequestUnmute;

const addReactionMessageAction = (message: ChatMessage, messageAction: MessageActionEvent) => {
  if (message.timetoken === messageAction?.data?.messageTimetoken) {
    const emojiReceived = messageAction.data.value;
    if (!message.message.reactions) {
      message.message.reactions = {};
    }
    const newReaction = {
      uuid: messageAction.publisher,
      actionTimetoken: messageAction.data.actionTimetoken,
    };

    if (!message.message.reactions[emojiReceived]) {
      message.message.reactions[emojiReceived] = [];
    }

    message.message.reactions[emojiReceived].push(newReaction);
  }
  return message;
};

const removeReactionMessageAction = (message: ChatMessage, messageAction: MessageActionEvent) => {
  if (message.timetoken === messageAction?.data?.messageTimetoken) {
    const emojiReceived = messageAction.data.value;
    message.message.reactions[emojiReceived] = message.message.reactions[emojiReceived].filter((reaction) => {
      return reaction.actionTimetoken !== messageAction.data.actionTimetoken;
    });
    if (message.message.reactions[emojiReceived].length === 0) {
      delete message.message.reactions[emojiReceived];
    }
  }
  return message;
};

const pubnubPresenceStateEventToParticipant = (presenceState: PresenceStateEventOccupant): Participant => {
  const { uuid: pubnubId, state } = presenceState;
  return {
    name: state?.name ?? null,
    dailyUserId: state?.dailyUserId ?? null,
    pubnubId,
  };
};

const reducer = (prevState: PubNubVirtualVenueState, action: PubNubVirtualVenueAction): PubNubVirtualVenueState => {
  switch (action.type) {
    case 'set-name': {
      return {
        ...prevState,
        setName: {
          requestState: 'sending',
          name: action.name,
        },
      };
    }
    case 'set-name-success': {
      return {
        ...prevState,
        setName: {
          requestState: 'idle',
          name: '',
        },
      };
    }
    case 'set-daily-user-id': {
      return {
        ...prevState,
        setDailyUserId: {
          requestState: 'sending',
          dailyUserId: action.dailyUserId,
        },
      };
    }
    case 'set-daily-user-id-success': {
      return {
        ...prevState,
        setDailyUserId: {
          requestState: 'idle',
          dailyUserId: '',
        },
      };
    }
    case 'send-chat-message': {
      return {
        ...prevState,
        sendMessage: {
          requestState: 'sending',
          message: action.message,
        },
      };
    }
    case 'send-chat-message-success': {
      return {
        ...prevState,
        sendMessage: {
          requestState: 'idle',
          message: '',
        },
      };
    }
    case 'fetch-historical-messages-success': {
      return {
        ...prevState,
        messages: action.messages,
      };
    }
    case 'fetch-presence-state-success': {
      const newParticipants = action.occupants.map(pubnubPresenceStateEventToParticipant);
      return {
        ...prevState,
        participants: newParticipants,
      };
    }
    case 'send-change-spotlight': {
      return {
        ...prevState,
        sendSpotlightChange: {
          requestState: 'sending',
          op: action.op,
          participant: action.participant,
        },
      };
    }
    case 'send-change-spotlight-success': {
      return {
        ...prevState,
        sendSpotlightChange: {
          requestState: 'idle',
          op: 'add',
          participant: '',
        },
      };
    }
    case 'send-request-unmute': {
      return {
        ...prevState,
        sendRequestToUnmute: {
          requestState: 'sending',
          participant: action.participant,
        },
      };
    }
    case 'send-request-unmute-success': {
      return {
        ...prevState,
        sendRequestToUnmute: {
          requestState: 'idle',
          participant: '',
        },
      };
    }
    case 'send-emoji-reaction': {
      return {
        ...prevState,
        sendEmojiReaction: {
          requestState: 'sending',
          emoji: action.emoji,
          messageTimetoken: action.messageTimetoken,
          actionTimetoken: action.actionTimetoken,
          op: action.operation,
        },
      };
    }
    case 'send-emoji-reaction-success': {
      return {
        ...prevState,
        sendEmojiReaction: {
          requestState: 'idle',
          emoji: '',
          messageTimetoken: '',
          actionTimetoken: undefined,
          op: 'add',
        },
      };
    }
    case 'update-presence-state': {
      const alreadyPresent = prevState.participants.find((p) => p.pubnubId === action.presenceState.uuid);
      const updatedParticipants = alreadyPresent
        ? prevState.participants.map((p) => {
            if (p.pubnubId === action.presenceState.uuid) {
              return pubnubPresenceStateEventToParticipant(action.presenceState);
            }
            return p;
          })
        : [...prevState.participants, pubnubPresenceStateEventToParticipant(action.presenceState)];
      const newState = {
        ...prevState,
        participants: updatedParticipants,
      };
      const isMe = action.presenceState.uuid === pubnubApi.getUUID();
      if (isMe) {
        newState.presenceState = {
          ...prevState.presenceState,
          ...action.presenceState.state,
        };
      }
      return newState;
    }
    case 'receive-chat-message': {
      return {
        ...prevState,
        messages: [...prevState.messages, action.message],
      };
    }
    case 'receive-chat-reaction-added': {
      return {
        ...prevState,
        messages: prevState.messages.map((m) => {
          return addReactionMessageAction(m, action.message);
        }),
      };
    }
    case 'receive-chat-reaction-removed': {
      return {
        ...prevState,
        messages: prevState.messages.map((m) => {
          return removeReactionMessageAction(m, action.message);
        }),
      };
    }
    case 'receive-chat-message-deleted': {
      return {
        ...prevState,
        messages: prevState.messages.filter((msg) => {
          return msg.timetoken !== action.message.data.messageTimetoken;
        }),
      };
    }
    case 'receive-spotlight-change': {
      return {
        ...prevState,
        spotlightParticipants: action.spotlightParticipants,
      };
    }
    case 'receive-request-unmute': {
      const newRequestParticipantId = action.message.message.participant;
      return {
        ...prevState,
        requestsToUnmute: [
          ...prevState.requestsToUnmute.filter((id) => id !== newRequestParticipantId),
          newRequestParticipantId,
        ],
      };
    }
    case 'acknowledge-request-unmute': {
      return {
        ...prevState,
        requestsToUnmute: prevState.requestsToUnmute.filter((id) => id !== action.participant),
      };
    }
    default: {
      throw new Error('unhandled pubnub action');
    }
  }
};
export type PubNubNetworkStatus =
  | 'PNNetworkUpCategory'
  | 'PNNetworkDownCategory'
  | 'PNNetworkIssuesCategory'
  | undefined;

export const useVenuePubNub = (
  virtualVenueId: string,
  venueDispatch: Dispatch<VirtualVenueAction>,
  localName: string | null
) => {
  const [connected, setConnected] = useState(false);
  const [networkStatus, setNetworkStatus] = useState<PubNubNetworkStatus>();
  const channelName = getVirtualVenuePubNubChannelName(virtualVenueId);

  const handlePubnubChannelMetadata = useCallback((data: PubNub.ChannelMetadataObject<VirtualVenueChannelMetadata>) => {
    let spotlightParticipants = [];
    try {
      const spotlightJSON = (data.custom?.spotlightParticipantsJSON as string) ?? '[]';
      spotlightParticipants = JSON.parse(spotlightJSON);
    } catch (e) {
      console.error(`Error parsing participants JSON: ${e}`);
    }
    dispatch({ type: 'receive-spotlight-change', spotlightParticipants: spotlightParticipants });
  }, []);

  // Listen for pubnub events
  const listeners: PubNub.ListenerParameters = useMemo(() => {
    return {
      message: (m) => {
        if (m.channel !== channelName) {
          return;
        }
        if (m.message.type === 'chat.text') {
          dispatch({ type: 'receive-chat-message', message: m });
          venueDispatch({ type: 'increment-unseen-chat-messages' });
        }
        if (m.message.type === 'update-venue') {
          venueDispatch({ type: 'pn-update-venue', message: m });
        }
        if (m.message.type === 'request-unmute') {
          dispatch({ type: 'receive-request-unmute', message: m });
        }
      },
      presence: (p) => {
        if (p.channel !== channelName) {
          return;
        }
        // Need guard for `undefined` presence state on initial load
        if (p.state) {
          dispatch({ type: 'update-presence-state', presenceState: p });
        }
      },
      signal: (s) => null,
      objects: (o) => {
        if (o.message.event === 'set' && o.message.type === 'channel') {
          handlePubnubChannelMetadata(o.message.data as PubNub.ChannelMetadataObject<VirtualVenueChannelMetadata>);
        }
      },
      messageAction: (m) => {
        // The PubNub.MessageActionEvent type definition is incorrect
        /*
          interface MessageActionEvent {
            channel: string;
            publisher: string;
            subscription?: string;
            timetoken: string;
            message: { <=== should be data: MessageAction;
                event: string;
                data: MessageAction;
            };
          }
        */

        const ma = (m as unknown) as MessageActionEvent;
        if (ma.channel !== channelName) {
          return;
        }
        switch (ma.data.type) {
          case 'chat.delete-msg':
            dispatch({ type: 'receive-chat-message-deleted', message: ma });
            break;
          case 'chat.reaction':
            if (ma.event === 'added') {
              dispatch({ type: 'receive-chat-reaction-added', message: ma });
            } else if (ma.event === 'removed') {
              dispatch({ type: 'receive-chat-reaction-removed', message: ma });
            }
        }
      },
      // file: (f) => null;
      status: (s) => {
        const category = s.category ?? '';
        if (category === 'PNConnectedCategory' && s.subscribedChannels.includes(channelName)) {
          setConnected(true);
        }
        if (category.indexOf('Network') > -1) {
          setNetworkStatus(s.category as PubNubNetworkStatus);
        }
      },
    };
  }, [channelName, handlePubnubChannelMetadata, venueDispatch]);

  // Create pubnub listeners
  useEffect(() => {
    pubnubApi.addListener(listeners);
    return () => pubnubApi.removeListener(listeners);
  }, [channelName, listeners]);

  // Create state and dispatcher
  const [state, dispatch] = useReducer(reducer, { ...initialPubNubState });

  // Handle sending chat messages
  const { requestState: sendMessageState, message } = state.sendMessage;
  useEffect(() => {
    if (sendMessageState === 'sending') {
      pubnubApi.publish(
        {
          channel: channelName,
          message: {
            type: 'chat.text',
            text: message,
            name: localName,
          },
        },
        (status, response) => {
          // todo: handle error case
          dispatch({ type: 'send-chat-message-success' });
        }
      );
    }
  }, [channelName, sendMessageState, message, localName]);

  // Handle sending emoji reaction messages
  const {
    requestState: sendEmojiReactionState,
    emoji,
    messageTimetoken,
    op,
    actionTimetoken,
  } = state.sendEmojiReaction;
  useEffect(() => {
    if (sendEmojiReactionState === 'sending') {
      if (op === 'add') {
        pubnubApi.addMessageAction(
          {
            channel: channelName,
            messageTimetoken: messageTimetoken,
            action: {
              type: 'chat.reaction',
              value: emoji,
            },
          },
          (status, response) => {
            // todo: handle error case
            dispatch({ type: 'send-emoji-reaction-success' });
          }
        );
      } else if (op === 'remove') {
        pubnubApi.removeMessageAction(
          {
            channel: channelName,
            messageTimetoken: messageTimetoken,
            actionTimetoken: actionTimetoken!.toString(),
          },
          (status, response) => {
            // todo: handle error case
            dispatch({ type: 'send-emoji-reaction-success' });
          }
        );
      }
    }
  }, [channelName, sendEmojiReactionState, messageTimetoken, emoji, op, actionTimetoken]);

  // Handle sending spotlight change messages
  const { requestState: sendSpotlightChangeState, participant, op: spotlightOp } = state.sendSpotlightChange;
  const { spotlightParticipants, participants } = state;
  useEffect(() => {
    if (sendSpotlightChangeState === 'sending') {
      let newSpotlightParticipants = spotlightParticipants.filter((p) => {
        // Perform cleanup, remove ids that are no longer in the call
        const isSpotlightParticipantStillInRoom = participants.find(
          (pubnubParticipant) => pubnubParticipant.dailyUserId === p
        );
        return p !== participant && (isSpotlightParticipantStillInRoom || p.startsWith('demo'));
      });
      if (spotlightOp === 'add') {
        newSpotlightParticipants.push(participant);
      }
      pubnubApi.objects.setChannelMetadata(
        {
          channel: channelName,
          data: {
            custom: {
              spotlightParticipantsJSON: JSON.stringify(newSpotlightParticipants),
            },
          },
        },
        (status, response) => {
          // todo: handle error case
          dispatch({ type: 'send-change-spotlight-success' });
        }
      );
    }
  }, [channelName, op, sendSpotlightChangeState, participant, spotlightOp, spotlightParticipants, participants]);

  // Handle sending request unmute messages
  const { requestState: sendRequestUnmuteState, participant: unmuteParticipant } = state.sendRequestToUnmute;
  useEffect(() => {
    if (sendRequestUnmuteState === 'sending') {
      pubnubApi.publish(
        {
          channel: channelName,
          message: {
            type: 'request-unmute',
            participant: unmuteParticipant,
          },
        },
        (status, response) => {
          // todo: handle error case
          dispatch({ type: 'send-request-unmute-success' });
        }
      );
    }
  }, [channelName, sendRequestUnmuteState, unmuteParticipant]);

  // Handle updating name
  useEffect(() => {
    if (!connected || !channelName) {
      return;
    }
    const { requestState, name } = state.setName;
    if (requestState === 'sending') {
      pubnubApi.setState(
        {
          channels: [channelName],
          state: { ...state.presenceState, name },
        },
        (status, response) => {
          // todo: handle error case
          // Note: name gets updated in the presence listener; we don't need to do it here.
          dispatch({ type: 'set-name-success' });
        }
      );
    }
  }, [channelName, connected, state.presenceState, state.setName]);

  // Handle updating daily user id
  useEffect(() => {
    if (!connected || !channelName) {
      return;
    }
    const { requestState, dailyUserId } = state.setDailyUserId;
    if (requestState === 'sending') {
      pubnubApi.setState(
        {
          channels: [channelName],
          state: { ...state.presenceState, dailyUserId },
        },
        (status, response) => {
          dispatch({ type: 'set-daily-user-id-success' });
        }
      );
    }
  }, [channelName, connected, state.presenceState, state.setDailyUserId]);

  // Fetch historical chat messages
  useEffect(() => {
    if (!connected) {
      return;
    }
    const urlParams = getURLParams();
    const numMessages = parseInt(urlParams.msg_count || '0');
    if (!numMessages) return;
    pubnubApi.fetchMessages(
      {
        channels: [channelName],
        count: numMessages,
        start: '', // TODO: set to event start time where present
        includeMessageActions: true,
      },
      (status, response) => {
        if (status.error) {
          console.error('Failed fetching historical messages');
        } else {
          const channelMessages = response['channels'][channelName];
          const messages: ChatMessage[] = channelMessages.map(transformPubnubChatMessage);
          dispatch({ type: 'fetch-historical-messages-success', messages: messages });
        }
      }
    );
  }, [channelName, connected]);

  // Fetch initial presence state
  useEffect(() => {
    if (!connected || !channelName) {
      return;
    }
    pubnubApi.hereNow(
      {
        channels: [channelName],
        includeState: true,
      },
      (status, response) => {
        if (status.error) {
          console.error('Failed fetching presence');
        } else {
          const channelOccupants: { uuid: string; state?: PresenceState }[] =
            response['channels'][channelName].occupants;

          dispatch({ type: 'fetch-presence-state-success', occupants: channelOccupants });
        }
      }
    );
  }, [channelName, connected]);

  // Fetch initial spotlight state
  useEffect(() => {
    if (!connected || !channelName) {
      return;
    }
    pubnubApi.objects.getChannelMetadata(
      {
        channel: channelName,
        include: { customFields: true },
      },
      (status, response) => {
        if (status.error) {
          console.error('Failed fetching channel metadata');
        } else {
          handlePubnubChannelMetadata(response.data as PubNub.ChannelMetadataObject<VirtualVenueChannelMetadata>);
        }
      }
    );
  }, [channelName, connected, handlePubnubChannelMetadata]);

  // Finally, subscribe to pubnub channel
  usePubNubSubscribe(channelName, networkStatus);

  return [state, dispatch] as const;
};
