import { bindListeners } from 'utils/helpers';

export const trackFocusMouse = (el: any, mouseClass = 'focus--mouse') => {
  let usingMouse: any;

  const preFocus = (event: Event) => {
    usingMouse = event.type === 'mousedown';
  };

  const addFocus = () => {
    if (usingMouse) el.classList.add(mouseClass);
  };

  const removeFocus = () => {
    el.classList.remove(mouseClass);
  };

  return bindListeners(el, {
    keydown: preFocus,
    mousedown: preFocus,
    focusin: addFocus,
    focusout: removeFocus,
  });
};
