import * as m from 'models';
import { EVENT_GUEST_LIST_QUERY } from 'utils/gql';
import { EventGuestList, EventGuestListVariables, EventGuestList_event_inviteSet } from 'generated/EventGuestList';
import { EventGuestListSkeleton } from 'components/pages/EventReadPage/EventReadViewSkeleton';
import { Invite } from 'models/invite';
import { RsvpStates } from 'generated/globalTypes';
import { getPusher } from 'utils/api';
import { shallowEqual } from 'utils/helpers';
import { useQuery } from '@apollo/client';

import EventGuestListView from 'components/EventGuestList/EventGuestListView/EventGuestListView';
import React, { useEffect } from 'react';
import TicketedGuestList from 'components/EventGuestList/TicketedGuestList';
import styles from 'components/EventGuestList/EventGuestListView/EventGuestListView.module.scss';
import useEventInviteClass from 'utils/hooks/event/useEventInviteClass';
import usePasswordEvent from 'components/App/globalstate/usePasswordEvent';
import usePrevious from 'utils/hooks/usePrevious';

interface Props {
  event: m.Event;
  isDemo: boolean;
}

const GuestList = ({ event, isDemo }: Props) => {
  if (event.ticketTypes) {
    return <TicketedGuestList />;
  } else {
    return <RSVPGuestList event={event} isDemo={isDemo} />;
  }
};

interface SubscriptionProps {
  eventId?: string | null;
  children: React.FunctionComponent<any>;
  initialInvites?: Invite[];
}

interface SubscriptionState {
  invites: Invite[];
}

class InviteSetSubscription extends React.Component<SubscriptionProps, SubscriptionState> {
  state: SubscriptionState = {
    invites: [],
  };

  channel: any;

  constructor(props: SubscriptionProps) {
    super(props);

    this.state = {
      invites: props.initialInvites || [],
    };
  }

  componentDidMount() {
    this.subscribe();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  componentDidUpdate(prevProps: SubscriptionProps) {
    const { eventId, initialInvites } = this.props;

    if (eventId !== prevProps.eventId) {
      this.subscribe();
    }

    if (initialInvites && (!initialInvites.length || !shallowEqual(initialInvites, prevProps.initialInvites))) {
      this.setState({
        invites: initialInvites,
      });
    }
  }

  unsubscribe() {
    if (this.channel) {
      this.channel.unsubscribe();
      this.channel = null;
    }
  }

  subscribe() {
    const { eventId } = this.props;

    if (!eventId) {
      return;
    }

    this.unsubscribe();

    const channelName = `private-event-${eventId}`;
    const pusher = getPusher();
    const channel = pusher.subscribe(channelName);

    channel.bind('invite-create', (invite: EventGuestList_event_inviteSet) => {
      const { invites } = this.state;
      const hasInvite = invites.find((e) => e.id === invite.id);
      this.setState({
        invites: hasInvite ? invites : invites.concat([invite]),
      });
    });

    channel.bind('invite-update', (invite: EventGuestList_event_inviteSet) => {
      const { invites } = this.state;
      const hasInvite = invites.find((e) => e.id === invite.id);
      this.setState({
        invites: hasInvite ? invites.map((e) => (e.id === invite.id ? invite : e)) : invites.concat([invite]),
      });
    });

    channel.bind('invite-delete', ({ inviteId }: { inviteId: string }) => {
      const { invites } = this.state;
      this.setState({
        invites: invites.filter((e) => e.id !== inviteId),
      });
    });

    this.channel = channel;
  }

  render() {
    const { children } = this.props;
    const { invites } = this.state;

    return children({ invites });
  }
}

const RSVPGuestList: React.FC<Props> = ({ event, isDemo }) => {
  const eventId = event.id;
  const { invite } = useEventInviteClass();
  const password = usePasswordEvent();

  const { data, loading, refetch, error } = useQuery<EventGuestList, EventGuestListVariables>(EVENT_GUEST_LIST_QUERY, {
    skip: isDemo || !eventId,
    variables: { id: eventId!, password },
  });

  const prevInvite = usePrevious(invite);

  useEffect(() => {
    // refetch the guest list once this user has rsvp'd
    if (invite !== prevInvite && invite) {
      refetch();
    }
  }, [invite, prevInvite, refetch]);

  if (loading) {
    return (
      <div className={styles.NoResults}>
        <EventGuestListSkeleton />
      </div>
    );
  }

  if (!isDemo && !data?.event) {
    console.log('Error loading guest list', event, error, data);
    return null;
  }

  const invites = createInvites(isDemo, data?.event?.inviteSet);

  return isDemo ? (
    <EventGuestListView event={event} invites={invites} />
  ) : (
    <InviteSetSubscription eventId={eventId} initialInvites={invites}>
      {({ invites }) => <EventGuestListView event={event} invites={invites} />}
    </InviteSetSubscription>
  );
};

const createInvites = (isDemo: boolean, inviteSet: Invite[] | null | undefined): Invite[] => {
  if (!isDemo) {
    return inviteSet!;
  }

  return [
    {
      id: '1',
      state: RsvpStates.y,
      rsvpName: 'Demo User',
      person: {
        __typename: 'Person',
        id: '1',
        name: 'Demo User',
      },
    },
  ];
};

export default GuestList;
