import { ReadVirtualVenue, ReadVirtualVenueVariables } from 'generated/ReadVirtualVenue';
import { Skeleton } from '@material-ui/lab';
import { VIRTUAL_VENUE_QUERY } from 'utils/gql';
import { VirtualVenueDisplayProvider } from 'components/VirtualVenue/contexts/VirtualVenueDisplayContext';
import { VirtualVenueEditProvider } from 'components/pages/VirtualVenueEditPage/VirtualVenueEditContext';
import { VirtualVenueThemeProvider, defaultTheme } from 'components/VirtualVenue/contexts/VirtualVenueThemeContext';
import { reverse } from 'shared/shared/routing/mixily-routes';
import { themeToColors } from 'components/VirtualVenue/utils';
import { useQuery } from '@apollo/client';
import { useUserId } from 'utils/hooks/user/useUser';
import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import Error404Page from 'components/pages/Error404Page';
import Error500Page from 'components/pages/Error500Page';
import Link from 'components/Link';
import LoginRequired from 'components/LoginRequired';
import MinimalLayout from 'components/layout/MinimalLayout/MinimalLayout';
import React from 'react';
import VirtualVenueEdit from 'components/pages/VirtualVenueEditPage/VirtualVenueEdit';
import VirtualVenueOuter from 'components/VirtualVenue/VirtualVenueDisplay/VirtualVenueOuter';

interface Props {
  id: string;
}
const VirtualVenueEditPage = ({ id }: Props) => {
  const userId = useUserId();

  const { loading, error, data } = useQuery<ReadVirtualVenue, ReadVirtualVenueVariables>(VIRTUAL_VENUE_QUERY, {
    variables: { id, userId },
    skip: !userId,
  });

  if (loading || (!loading && !error && !data)) {
    return (
      <BaseLayout title="Loading...">
        <Skeleton variant="rect" height={360} />
      </BaseLayout>
    );
  }

  if (error) {
    return <Error500Page />;
  }

  if (!data?.virtualVenue) {
    return <Error404Page />;
  }

  const { virtualVenue } = data;

  if (userId && virtualVenue.ownerId !== userId) {
    return (
      <BaseLayout title="Permission denied">
        <p>Sorry, you don't have permission to edit that venue.</p>
      </BaseLayout>
    );
  }

  const colors = virtualVenue.theme ? themeToColors(virtualVenue.theme) : defaultTheme;

  return (
    <LoginRequired>
      <MinimalLayout title={virtualVenue.title}>
        <div>
          <div>
            <Link href={reverse('virtual_venue_list')} className="text-lg leading-none">
              back to my venues
            </Link>
          </div>

          <div className="mt-12 text-5xl leading-none">{virtualVenue.title}</div>

          <div className="mt-12">
            <VirtualVenueDisplayProvider initialSetting={{ mode: 'edit' }}>
              <VirtualVenueThemeProvider initialTheme={{ ...colors }}>
                <VirtualVenueEditProvider
                  initialState={{ title: data.virtualVenue.title, logo: data.virtualVenue.theme?.logo ?? '' }}
                >
                  <VirtualVenueOuter virtualVenue={data.virtualVenue}>
                    <VirtualVenueEdit virtualVenue={data.virtualVenue} />
                  </VirtualVenueOuter>
                </VirtualVenueEditProvider>
              </VirtualVenueThemeProvider>
            </VirtualVenueDisplayProvider>
          </div>
        </div>
      </MinimalLayout>
    </LoginRequired>
  );
};

export default VirtualVenueEditPage;
