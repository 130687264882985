import * as F from 'shared/shared/Functional';
import { ThreadQuery_thread_comments } from 'generated/ThreadQuery';

export type ThreadComment = ThreadQuery_thread_comments;

export interface ThreadCommentWithReplies extends ThreadComment {
  depth?: number;
  parent?: ThreadCommentWithReplies;
  replies?: ThreadCommentWithReplies[];
}

// Helper method for groupCommentsByReplies
const groupComments = (
  comments: ThreadCommentWithReplies[],
  parent: ThreadCommentWithReplies | undefined,
  replyGroups: Record<string, ThreadCommentWithReplies[]>
): ThreadCommentWithReplies[] =>
  comments.map((comment) => {
    const replies = comment.id && replyGroups[comment.id];
    return {
      ...comment,
      parent,
      depth: parent ? parent.depth! + 1 : 0,
      // `createdAt` is an ISO 8601 string, so we can sort lexicographically
      replies: F.sortBy(groupComments(replies || [], comment, replyGroups), F.prop('createdAt')),
    };
  });

/**
 * Given a flat list of comments, returns a tree of comments based on replyTo.
 */
export const groupCommentsByReplies = (comments: ThreadComment[]): ThreadCommentWithReplies[] => {
  const replyGroups = F.groupBy(comments, (comment) => comment.replyTo || '');
  const filteredComments = comments.filter((comment) => !comment.replyTo);
  const sortedComments = F.sortBy(filteredComments, F.prop('createdAt')).reverse();

  return groupComments(sortedComments, undefined, replyGroups);
};
