import { DEFAULT_LOGO_COLOR } from 'utils/constants';
import { EventLayoutContext } from 'components/layout/EventLayout/EventLayout';
import { ReactComponent as LogoImg } from 'components/Logo/mixily-logo.svg';
import { reverse } from 'router';
import Link from 'components/Link';
import React, { useContext } from 'react';
import classNames from 'classnames';

interface Props {
  fill?: string;
  forSidebar?: boolean;
}

const Logo = (props: Props) => {
  const { colorClass } = useContext(EventLayoutContext);
  const logoClass = !props.forSidebar ? colorClass : '';
  return (
    <Link href={reverse('home')} className={classNames('Logo', logoClass)}>
      <LogoImg
        style={{ height: '32px', opacity: 0.85, verticalAlign: 'middle' }}
        fill={props.fill ?? DEFAULT_LOGO_COLOR}
      />
    </Link>
  );
};

export default Logo;
