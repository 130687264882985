import { Button, Loading } from 'components/common';
import { getZoomOauthLink } from 'utils/helpers';
import { isAuthenticated } from 'models';
import Dialog from 'components/common/Dialog/Dialog';
import React, { useState } from 'react';
import styles from './StripeConnectDialog.module.scss';
import useUser from 'utils/hooks/user/useUser';

interface Props {
  isOpen?: boolean;
  setIsOpen?: (b: boolean) => void;
  onClose?: () => void;
}

function openLinkInNewWindow(url: string, onClosed: () => void) {
  const newWindow = window.open(url);
  const int = setInterval(() => {
    if (newWindow?.closed) {
      onClosed();
      clearInterval(int);
    }
  }, 1000);
}

const ZoomConnectDialog = (props: Props) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user, refetchUser } = useUser();

  const isControlled = props.isOpen != null && props.setIsOpen != null;
  const isOpen = isControlled ? props.isOpen! : open;
  const setIsOpen = isControlled ? props.setIsOpen! : setOpen;

  if (!isAuthenticated(user)) {
    return null;
  }

  const oauthLink = getZoomOauthLink();

  function onComplete() {
    refetchUser();
    setIsOpen(false);
  }

  function close() {
    setIsOpen(false);
    props.onClose && props.onClose();
  }

  return (
    <>
      {!isControlled && <Button onClick={() => setIsOpen(true)} label="Connect to Zoom" />}
      <Dialog
        title="Connect to Zoom"
        isOpen={isOpen}
        onClose={close}
        actions={[
          <Button key="cancel" label="Cancel" onClick={close} color="secondary" small />,
          <div key="save" onClick={() => setLoading(true)}>
            <Button
              label={loading ? <Loading size="small" /> : 'Authorize'}
              className={styles.zoomConnectButton}
              onClick={() => openLinkInNewWindow(oauthLink, onComplete)}
              small
            />
          </div>,
        ]}
      >
        <div>Authorize Mixily to access your Zoom account in order to create meetings.</div>
      </Dialog>
    </>
  );
};

export default ZoomConnectDialog;
