import { ReadVirtualVenue, ReadVirtualVenueVariables } from 'generated/ReadVirtualVenue';
import { Skeleton } from '@material-ui/lab';
import { VIRTUAL_VENUE_QUERY } from 'utils/gql';
import { useQuery } from '@apollo/client';
import { useUserId } from 'utils/hooks/user/useUser';
import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import Error404Page from 'components/pages/Error404Page';
import Error500Page from 'components/pages/Error500Page';
import LoginRequired from 'components/LoginRequired';
import React from 'react';
import VirtualVenueDetails from 'components/pages/VirtualVenueDetailsPage/VirtualVenueDetails';

interface Props {
  id: string;
}

const VirtualVenueDetailsPage = ({ id }: Props) => {
  const userId = useUserId();

  const { loading, error, data } = useQuery<ReadVirtualVenue, ReadVirtualVenueVariables>(VIRTUAL_VENUE_QUERY, {
    variables: { id, userId },
    skip: !userId,
  });

  if (loading || (!loading && !error && !data)) {
    return (
      <BaseLayout title="Loading...">
        <Skeleton variant="rect" height={360} />
      </BaseLayout>
    );
  }

  if (error) {
    return <Error500Page />;
  }

  if (!data?.virtualVenue) {
    return <Error404Page />;
  }

  if (userId && data.virtualVenue.ownerId !== userId) {
    return (
      <BaseLayout title="Permission denied">
        <p>Sorry, you don't have permission to view that venue.</p>
      </BaseLayout>
    );
  }
  return (
    <LoginRequired>
      <BaseLayout title="Install Directions" hideTitle={true}>
        <VirtualVenueDetails virtualVenue={data.virtualVenue} />
      </BaseLayout>
    </LoginRequired>
  );
};

export default VirtualVenueDetailsPage;
