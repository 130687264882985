import { FullScreenHandle } from 'utils/hooks/useFullscreen';
import IconSvg from 'components/common/IconSvg/IconSvg';
import React from 'react';
import VirtualVenueLogo from 'components/VirtualVenue/VirtualVenueDisplay/layouts/VirtualVenueDisplayLayout/VirtualVenueHeader/VirtualVenueLogo';
import VirtualVenueTitle from './VirtualVenueTitle/VirtualVenueTitle';

interface Props {
  handle: FullScreenHandle;
  title: string;
  logo?: string;
  logoFilter?: string;
}

const VirtualVenueHeader = ({ handle, logo, title, logoFilter }: Props) => {
  return (
    <div className="flex flex-row items-center justify-between mx-3 my-3 md:h-16 md:my-5">
      <div className="flex flex-col items-start self-stretch justify-between">
        <div
          onClick={handle.active ? handle.exit : handle.enter}
          className="flex justify-center pb-3 cursor-pointer opacity-80"
          style={{ borderColor: 'var(--v-theme-icon-color)' }}
        >
          <IconSvg
            name={handle.active ? 'minimize' : 'expand'}
            className="w-4 h-4"
            style={{ color: 'var(--v-theme-icon-color)' }}
          />
        </div>
        <VirtualVenueTitle title={title} />
      </div>
      <div className="flex items-end justify-end">
        <VirtualVenueLogo logo={logo} logoFilter={logoFilter} />
      </div>
    </div>
  );
};

export default VirtualVenueHeader;
