import { ReadVirtualVenue, ReadVirtualVenueVariables } from 'generated/ReadVirtualVenue';
import { Skeleton } from '@material-ui/lab';
import { VIRTUAL_VENUE_QUERY } from 'utils/gql';
import { useQuery } from '@apollo/client';
import { useUserId } from 'utils/hooks/user/useUser';
import EmbeddedErrorPage from 'components/pages/EmbeddedErrorPage';
import IconSvg from 'components/common/IconSvg/IconSvg';
import Link from 'components/Link';
import React from 'react';
import VirtualVenue from 'components/VirtualVenue/VirtualVenue';
import classNames from 'classnames';
import styles from './EmbeddedVirtualVenuePage.module.scss';

interface Props {
  id: string;
}

const EmbeddedVirtualVenuePage = ({ id }: Props) => {
  const userId = useUserId();

  const { loading, error, data, previousData } = useQuery<ReadVirtualVenue, ReadVirtualVenueVariables>(
    VIRTUAL_VENUE_QUERY,
    {
      variables: { id, userId },
    }
  );

  const venueToRender = data ?? previousData;
  if (loading || (!loading && !error && !venueToRender)) {
    return (
      <div className={classNames(styles.ExpandAndCenter, 'py-16')}>
        <Skeleton variant="rect" height={360} />
      </div>
    );
  }

  if (error) {
    return (
      <EmbeddedErrorPage title="Embed loading error">
        <p>
          Sorry, we couldn't load that venue. Please{' '}
          <a href="mailto:help@mixily.com" style={{ color: 'inherit', textDecoration: 'underline' }}>
            contact support
          </a>{' '}
          for assistance.
        </p>
      </EmbeddedErrorPage>
    );
  }

  if (!venueToRender?.virtualVenue) {
    return (
      <EmbeddedErrorPage title="Embed not found">
        <p>
          Sorry, we can't find that embedded venue. Please{' '}
          <a href="mailto:help@mixily.com" style={{ color: 'inherit', textDecoration: 'underline' }}>
            contact support
          </a>{' '}
          for assistance.
        </p>
      </EmbeddedErrorPage>
    );
  }

  return (
    <div>
      <VirtualVenue virtualVenueData={venueToRender.virtualVenue} isEmbedded={true} />

      <div className="invisible mt-1 text-right">
        <Link
          href="/feature/virtualvenue"
          // className="inline-block px-2 py-1 mt-1 mr-2 text-sm font-semibold text-right rounded-xl bg-cool-gray-500 bg-opacity-30"
          className="inline-block mt-1 mr-2 text-sm font-semibold text-right"
          newWindow={true}
          style={{
            color: venueToRender.virtualVenue.theme?.bgColor || 'black',
          }}
        >
          Powered by <span className="underline">Mixily</span>{' '}
          <IconSvg name="externalLink" className="inline-block w-4 h-4 align-text-bottom" />
        </Link>
      </div>
    </div>
  );
};
export default EmbeddedVirtualVenuePage;
