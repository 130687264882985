import { VirtualVenueThemeColorConfig } from 'components/VirtualVenue/types';
import { isColorLightOrDark } from 'utils/colors';
import { useVirtualVenueTheme } from 'components/VirtualVenue/contexts/VirtualVenueThemeContext';
import Color from 'color';
import React from 'react';

const camelToKebabCase = (str: string): string => str.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);

export function replaceThemeColorVars(container: HTMLDivElement | null, colors: Partial<VirtualVenueThemeColorConfig>) {
  if (container === null) {
    return;
  }

  for (const [key, value] of Object.entries(colors)) {
    const propertyName = `--v-theme-${camelToKebabCase(key)}`;
    container.style.setProperty(propertyName, value || null);
  }

  const color = Color(colors.bgColor);
  const darkerColor = color.darken(0.2).hex();
  const lighterColor = color.lightness(20).hex();
  const isDarkColor = isColorLightOrDark(color) === 'dark';
  const lightCameraColor = '#ebeaea';
  const darkCameraColor = '#212121';

  // (background-color-dark, background-color-light): used for making gradient for non-video participant tile
  container.style.setProperty(`--v-theme-bg-color-dark`, darkerColor);
  container.style.setProperty(`--v-theme-bg-color-light`, darkerColor === color.hex() ? lighterColor : color.hex());

  // background-camera-color: used for camera icon inside non-video participant tile
  container.style.setProperty(`--v-theme-background-camera-color`, isDarkColor ? lightCameraColor : darkCameraColor);
}

export default function useUpdateVirtualVenueTheme(container: HTMLDivElement | null) {
  const theme = useVirtualVenueTheme();

  // Update CSS vars that hold a variety of colors
  React.useLayoutEffect(() => {
    replaceThemeColorVars(container, theme);
  }, [container, theme]);
}
