import { GOOGLE_SCOPES } from 'shared/shared/constants';
import config from 'config';

export interface GoogleAuthProps {
  onSuccess?: (idToken: string) => void;
  scope?: string;
}

const _onSuccess = (onSuccess: Function) => (response: any) => {
  if (!('code' in response)) {
    console.error(response);
    throw new Error('Unexpected Google authentication response lacks code');
  }
  onSuccess(response.code!);
};

const onFailure = (response: any) => {
  console.log('GoogleButton on failure', response);
};

export const GOOGLE_AUTH = (props?: GoogleAuthProps) => {
  return {
    clientId: config.googleApiClientId,
    cookiePolicy: 'single_host_origin',
    scope: props?.scope ? props.scope : GOOGLE_SCOPES,
    // In order to use the back-end's google-auth-library and Google Calendar, we should return a code
    // instead an id token. For this reason, accessType='offline' and 'responseType='code'
    accessType: 'offline',
    responseType: 'code',
    // This is required in order to get the refresh token, which is required to use Google Calendar API offline
    prompt: 'consent',
    // To keep user logged and the auth instance initialized
    isSignedIn: true,
    onSuccess: props?.onSuccess ? _onSuccess(props?.onSuccess) : undefined,
    onFailure,
  };
};
