import { reverse } from 'router';

import { URLContext } from 'components/RoutingProvider';
import { analyticsAPI as analytics } from 'utils/api';
import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import Button2 from 'components/common/Button2/Button2';
import LoginRequired from 'components/LoginRequired';
import React, { useContext } from 'react';
import useCanCreateVenue from 'utils/hooks/subscriptions/useCanCreateVenue';

const VirtualVenueSuccessPage = () => {
  const { setPath } = useContext(URLContext);
  const { refetchVenuesByUser } = useCanCreateVenue(true);
  const handleSubmit = () => {
    const path = reverse('virtual_venue_create');
    analytics.track('Create My First Venue Clicked (Virtual Venue | Virtual Venue Signup Flow)').finally(() => {
      setPath(path);
    });
  };
  refetchVenuesByUser();

  return (
    <LoginRequired>
      <BaseLayout title="Success!" hideTitle={true}>
        <div className="flex flex-col items-center justify-center w-full h-full text-center">
          <div className="text-3xl sm:text-5xl">Ready to get started?</div>
          <div className="mt-8 text-2xl sm:text-3xl">Your own virtual venue is one click away</div>
          <div className="inline-block mx-auto mt-10">
            <Button2 submit={true} color={'primary'} onClick={handleSubmit}>
              <span className="inline-block lg:hidden">Create Virtual Venue</span>
              <span className="hidden px-2 py-3 text-2xl lg:inline-block">Create my first venue</span>
            </Button2>
          </div>
        </div>
      </BaseLayout>
    </LoginRequired>
  );
};

export default VirtualVenueSuccessPage;
