import * as m from 'models';
import { Link, TextLink } from 'components/common';
import { RunEventId_run } from 'generated/RunEventId';
import { URLContext } from 'components/RoutingProvider';
import { getStripeOauthLink } from 'utils/stripe';
import { renderName } from 'models/invite';
import { reverse } from 'router';
import AddToCalendar, { ButtonCalendarType } from 'components/pages/EventReadPage/AddToCalendar/AddToCalendar';
import CommentView from 'components/pages/EventReadPage/comments/CommentView';
import Description from 'components/pages/EventReadPage/Description';
import DisableInPreview from 'components/DisableInPreview/DisableInPreview';
import DuplicateEventButton from './DuplicateEventButton';
import EditEventButton from 'components/pages/EventReadPage/EditEventButton';
import ErrorBoundary from 'components/common/ErrorBoundary';
import EventGuestList from 'components/EventGuestList/EventGuestList';
import EventViewAsDialog from 'components/pages/EventReadPage/EventViewAsDialog';
import HostedBy from 'components/HostedBy/HostedBy';
import InvitationSender from 'components/pages/EventReadPage/InvitationSender/InvitationSender';
import OnlineEventInfo from 'components/pages/EventReadPage/OnlineEventInfo';
import PrivacyIndicators from 'components/pages/EventReadPage/EventPrivacyIndicators';
import React, { useContext, useState } from 'react';
import RolesDialog from 'components/pages/EventReadPage/roles/RolesDialog';
import RsvpForm from 'components/pages/EventReadPage/RsvpForm/RsvpForm';
import StripeConnectDialog from 'components/StripeConnectDialog';
import Title from 'components/pages/EventReadPage/Title/Title';
import When from 'components/When/When';
import Where from 'components/Where/Where';
import classNames from 'classnames';
import styles from './EventReadView.module.scss';
import useEventInviteClass from 'utils/hooks/event/useEventInviteClass';
import useEventPermissions from 'utils/hooks/event/useEventPermissions';
import useUser from 'utils/hooks/user/useUser';

interface Props {
  // Before removing event props, see https://github.com/reverie/mixily/pull/729
  event: m.Event;
  isDemo: boolean;
  isRun: boolean;
  isDialog?: boolean;
  loading: boolean;
  run?: RunEventId_run;
}

const EventReadView = ({ event, isDemo, isDialog, loading, isRun, run }: Props) => {
  const { invite } = useEventInviteClass();
  const { permissions } = useEventPermissions();
  const { user } = useUser();
  const { authModalStateMutatorSet } = useContext(URLContext);
  const [showStripeConnectDialog, setShowStripeConnectDialog] = useState<boolean>(false);

  const userIsAuthenticatedAndOwner = m.isAuthenticated(user) && user.id === event.owner!.id;
  const userWithNotConfirmedEmailEventOwner = m.isAuthenticated(user) && !m.isEmailConfirmed(user.auths) && isDemo;
  const userIsEventOwner = userIsAuthenticatedAndOwner || user === m.DemoUser || userWithNotConfirmedEmailEventOwner;
  const inviteName = !m.isAuthenticated(user) && invite ? renderName(invite) : undefined;
  const viewClass = isDialog ? styles.EventReadDialogView : styles.EventReadView;
  const kind = isRun ? 'run' : 'event';

  return (
    <div className={viewClass} style={{ position: isDemo ? 'relative' : 'static' }}>
      {/* Right-column elements must come first in the document */}
      {isDemo && userIsEventOwner && (
        <div className={classNames(styles.RightColumn, styles.ActionSectionBlock, styles.DemoBlock)}>
          Hey there! This is a demo of Mixily's functionality. Please{' '}
          <TextLink className="color-link-color" onClick={authModalStateMutatorSet.openSignupModal} text="sign up" /> or{' '}
          <Link href={reverse('resend_confirmation')}>confirm your email address</Link>. Already have an account? Log in
          to claim your event. It will be automatically added to your account.
        </div>
      )}

      {event.ticketTypes && userIsEventOwner && m.isAuthenticated(user) && (
        <StripeMessage user={user} kind={kind} setShowStripeConnectDialog={setShowStripeConnectDialog} />
      )}

      {permissions.canUpdate && (
        <div className={classNames(styles.RightColumn, styles.EditEventButtonSection)}>
          {event.isPast && !isRun ? <DuplicateEventButton /> : <EditEventButton isRun={isRun} />}
        </div>
      )}

      {permissions.canUpdate && (
        <div className={classNames(styles.RightColumn, styles.ViewAsGuestButtonSection)}>
          <EventViewAsDialog isRun={isRun} />
        </div>
      )}

      <div className={classNames(styles.RightColumn, styles.ActionSection)}>
        {!loading && !userIsEventOwner && !event.isPast && (
          <div className={classNames(styles.ActionSectionBlock, styles.ActionSectionBlockNoPadding)}>
            <ErrorBoundary>
              <RsvpForm />
            </ErrorBoundary>
          </div>
        )}

        {!loading && !userIsEventOwner && !event.isPast && event.locationReleaseTimeDelta && (
          <div className={classNames(styles.ActionSectionBlock)}>
            <ErrorBoundary>
              <OnlineEventInfo
                startTime={event.startTime!}
                locationReleaseTimeDelta={event.locationReleaseTimeDelta!}
                eventId={event.id}
              />
            </ErrorBoundary>
          </div>
        )}

        {permissions.canInvite && !isRun && (
          <div className={styles.ActionSectionBlock}>
            <ErrorBoundary>
              <InvitationSender />
            </ErrorBoundary>
          </div>
        )}

        {permissions.canViewGuestList && !isRun && (
          <div className={classNames(styles.ActionSectionBlock, styles.ActionSectionBlockNoPadding)}>
            <ErrorBoundary>
              <EventGuestList event={event} isDemo={isDemo} />
            </ErrorBoundary>
          </div>
        )}

        {permissions.canUpdate && !isRun && (
          <div className={classNames(styles.ActionSectionBlock, styles.PrivacyBlock)}>
            <ErrorBoundary>
              <PrivacyIndicators />
            </ErrorBoundary>
          </div>
        )}
      </div>

      <div className={classNames(styles.LeftColumn, styles.EventBlock)}>
        {event.themePic && <img className={styles.HeaderImage} src={event.themePic} alt="" />}
        <div
          className={classNames(styles.MainInfo, {
            [styles.TopBorder]: !event.themePic,
          })}
        >
          <Title title={event.title} />
          <div className="mt-6 vstack-small">
            <div className="flex flex-col xs:flex-row xs:items-center">
              <HostedBy hostedByText={event.hostedByText} alignTop />
              {permissions?.canUpdate && (
                <div className="inline-flex text-base align-middle sm:text-lg">
                  <span className="hidden mx-1 xs:inline">&middot;</span>
                  <span className="ml-8 xs:ml-0">
                    <RolesDialog />
                  </span>
                </div>
              )}
            </div>

            <div className="flex flex-col xs:flex-row xs:items-center">
              <When event={event} inviteName={inviteName} isRun={isRun} run={run} alignTop />
              {event.startTime && event.forCalendar && (
                <div className="inline-flex text-base align-middle sm:text-lg">
                  <span className="hidden mx-1 xs:inline">&middot;</span>
                  <span className="ml-8 xs:ml-0">
                    <AddToCalendar eventId={event.id} forCalendar={event.forCalendar} type={ButtonCalendarType.LINK} />
                  </span>
                </div>
              )}
            </div>

            <Where event={event} alignTop />
          </div>
          <Description description={event.description} className={styles.Description} />
        </div>
      </div>

      {!event.disableComments && event.thread && (
        <div className={classNames(styles.LeftColumn, styles.CommentBlock)}>
          <ErrorBoundary>
            <DisableInPreview>
              <CommentView eventId={event.id} threadId={event.thread.id} user={user} />
            </DisableInPreview>
          </ErrorBoundary>
        </div>
      )}

      <StripeConnectDialog
        isOpen={showStripeConnectDialog}
        setIsOpen={(show: boolean) => setShowStripeConnectDialog(show)}
      />

      <div style={{ clear: 'both' }} />

      {isDemo && userIsEventOwner && (
        <div
          style={{
            position: 'absolute',
            zIndex: 99,
            top: 300,
            left: 0,
            width: '100%',
            height: 'calc(100% - 300px)',
          }}
          onClick={() =>
            authModalStateMutatorSet.toggleSignupModal({
              message: `Please log in or sign up to claim this event.`,
            })
          }
        />
      )}
    </div>
  );
};

const StripeMessage = ({
  user,
  kind,
  setShowStripeConnectDialog,
}: {
  user: m.Person;
  kind: string;
  setShowStripeConnectDialog: Function;
}) => {
  if (!user.stripeAccountId) {
    return (
      <div className={classNames(styles.RightColumn, styles.ActionSectionBlock, styles.DemoBlock)}>
        Hey there! We've noticed that you're hosting a ticketed {kind}. For faster payouts, fancy reporting and other
        great features, we recommend you{' '}
        <TextLink
          className="color-link-color"
          onClick={() => setShowStripeConnectDialog(true)}
          text="setup and connect"
        />{' '}
        your own Stripe account.
      </div>
    );
  }

  // We validate strict false because there are two cases:
  // stripeChargesEnabled = false, users haven't completed the Stripe onboarding process
  // stripeChargesEnable = null, previous users who already setup their Stripe account
  // but we don't know whether they can receive payments
  if (user.stripeChargesEnabled === false) {
    return (
      <div className={classNames(styles.RightColumn, styles.ActionSectionBlock, styles.DemoBlock)}>
        Hey there! We've noticed that you're hosting a ticketed {kind}. Your account is connected to Stripe, but you
        haven't completed the onboarding process. We recommend you{' '}
        <Link href={getStripeOauthLink(user)}>activate your Stripe account</Link> to receive payouts.
      </div>
    );
  }

  return null;
};

export default EventReadView;
