import { Controller, useForm } from 'react-hook-form';
import { Currency, PriceType } from 'shared/shared/types';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { TextLink } from 'components/common';
import { WidgetProps } from 'components/widgets';
import { centsToDollars, dollarsToCents } from 'utils/helpers';
import Input2 from 'components/common/Input2/Input2';
import IntegerInput from 'components/common/IntegerInput/IntegerInput';
import LabelWithInput from 'components/common/LabelWithInput/LabelWithInput';
import MoneyInput from 'components/common/MoneyInput/MoneyInput';
import React, { useEffect } from 'react';
import TextArea2 from 'components/common/TextArea2';
import styles from './TicketTypeWidget.module.scss';

interface TTBase {
  id?: string;
  name?: string;
  description: string;
  capacity?: number;
}

interface FixedTT extends TTBase {
  priceType: PriceType.fixed;
  fixedPriceCents: number | null; // `null` if user hasn't entered a value yet
}

interface SuggestedTT extends TTBase {
  priceType: PriceType.suggested;
  suggestedDonationMinCents: number | null; // `null` if user hasn't entered a value yet
  suggestedDonationDefaultCents: number | null; // `null` if user hasn't entered a value yet
}

export type TicketTypeSettings = FixedTT | SuggestedTT;

export function isFixed(tt: TicketTypeSettings): tt is FixedTT {
  return tt.priceType === PriceType.fixed;
}

interface Attrs {
  currency: Currency;
  hideNameField: boolean;
  showPriceNote: boolean;
  showFeeCalculator: () => void;
}

type FormT = {
  type: PriceType;
  name: string;
  description: string;
  capacity: string;
  minValue: string;
  defaultValue: string;
  fixedPriceValue: string;
};

const getDefaultValues = (ticketType: TicketTypeSettings): FormT => {
  return {
    type: ticketType.priceType,
    name: ticketType.name ?? '',
    description: ticketType.description,
    capacity: `${ticketType.capacity ?? ''}`,
    minValue: 'suggestedDonationMinCents' in ticketType ? centsToDollars(ticketType.suggestedDonationMinCents) : '',
    defaultValue:
      'suggestedDonationDefaultCents' in ticketType ? centsToDollars(ticketType.suggestedDonationDefaultCents) : '0',
    fixedPriceValue: 'fixedPriceCents' in ticketType ? centsToDollars(ticketType.fixedPriceCents) : '0',
  };
};

const TicketTypeWidget = (props: WidgetProps<TicketTypeSettings, Attrs>) => {
  const { value: ticketType, onChange, attrs, disabled } = props;
  const { currency, hideNameField, showPriceNote, showFeeCalculator } = attrs!;
  const defaultValues = getDefaultValues(ticketType);
  const formContext = useForm<FormT>({ defaultValues });
  const { watch, register, control } = formContext;

  const { type, capacity, name, description, minValue, defaultValue, fixedPriceValue } = watch();

  useEffect(() => {
    onChange({
      id: ticketType.id,
      name,
      description,
      capacity: capacity != null ? parseInt(capacity) : undefined,
      ...(type === PriceType.suggested
        ? {
            priceType: PriceType.suggested,
            suggestedDonationMinCents: dollarsToCents(minValue) ?? null,
            suggestedDonationDefaultCents: dollarsToCents(defaultValue) ?? null,
          }
        : {
            priceType: PriceType.fixed,
            fixedPriceCents: dollarsToCents(fixedPriceValue) ?? null,
          }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketType.id, type, capacity, name, description, minValue, defaultValue, fixedPriceValue]);

  return (
    <div className={styles.container}>
      <div className={styles.typeRadio}>
        <Controller as={RadioGroup} name="type" control={control} onChange={([e]: any) => e.target.value}>
          <FormControlLabel
            value={PriceType.suggested}
            control={<Radio color="primary" disabled={disabled} />}
            label="Suggested Donation"
          />
          <FormControlLabel
            value={PriceType.fixed}
            control={<Radio color="primary" disabled={disabled} />}
            label="Fixed Price"
          />
        </Controller>

        {disabled && (
          <div className={styles.changeNote}>Ticket type cannot be changed after tickets have been purchased</div>
        )}
      </div>

      <div className={styles.content}>
        <div className={hideNameField ? 'hidden' : ''}>
          <LabelWithInput label="Name" className="mb-6">
            <Input2 name="name" ref={register} size="lg" placeholder="General Admission" />
          </LabelWithInput>
        </div>

        <LabelWithInput label="Description">
          <TextArea2
            name="description"
            ref={register}
            placeholder={type === PriceType.suggested ? 'Description and suggested range' : ''}
          />
        </LabelWithInput>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4">
          <LabelWithInput label="Minimum" className={type === PriceType.suggested ? 'mt-6' : 'hidden'}>
            <Controller
              as={MoneyInput}
              name="minValue"
              control={control}
              onChangeName="onValueChange"
              placeholder="0"
              onChange={(e: any) => e[0].value}
              layoutProps={{
                size: 'lg',
              }}
              currency={currency}
            />
          </LabelWithInput>

          <LabelWithInput label="Suggested" className={type === PriceType.suggested ? 'mt-6' : 'hidden'}>
            <Controller
              as={MoneyInput}
              name="defaultValue"
              control={control}
              onChangeName="onValueChange"
              placeholder="0"
              onChange={(e: any) => e[0].value}
              layoutProps={{
                size: 'lg',
              }}
              currency={currency}
            />
          </LabelWithInput>

          <LabelWithInput label="Price" className={type === PriceType.fixed ? 'mt-6' : 'hidden'}>
            <Controller
              as={MoneyInput}
              name="fixedPriceValue"
              control={control}
              onChangeName="onValueChange"
              placeholder="0"
              onChange={(e: any) => e[0].value}
              layoutProps={{
                size: 'lg',
              }}
              currency={currency}
            />
          </LabelWithInput>

          <LabelWithInput label="Capacity" className="mt-6">
            <Controller
              as={IntegerInput}
              name="capacity"
              control={control}
              onChangeName="onValueChange"
              placeholder="Unlimited"
              onChange={(e: any) => e[0].value}
              layoutProps={{
                size: 'lg',
              }}
            />
          </LabelWithInput>
        </div>

        {showPriceNote && <TicketInfo showFeeCalculator={showFeeCalculator} />}
      </div>
    </div>
  );
};

interface TicketInfoProps {
  showFeeCalculator: () => void;
}

const TicketInfo = ({ showFeeCalculator }: TicketInfoProps) => (
  <div className="mt-6 text-xs leading-none text-gray-600">
    Mixily takes a fee of 1% + $0.30 on paid ticket purchases.{' '}
    <TextLink onClick={showFeeCalculator}>Learn more</TextLink>
  </div>
);

export default TicketTypeWidget;
