import { Person } from 'models';
import { getStripeOauthLink } from 'utils/stripe';
import CheckIcon from '@material-ui/icons/Check';
import Link from 'components/Link';
import React from 'react';
import StripeConnectDialog from 'components/StripeConnectDialog';
import StripeDeauthorizeDialog from 'components/StripeDeauthorizeDialog';
import styles from './AccountSettingsPage.module.scss';

interface Props {
  user: Person;
}

const StripeSettings = ({ user }: Props) => {
  return (
    <>
      {!user.stripeAccountId && <StripeConnectDialog />}
      {!!user.stripeAccountId && user.stripeChargesEnabled === false && (
        <div className="mb-4">
          You haven't completed the onboarding process. Please return to{' '}
          <Link href={getStripeOauthLink(user)}>Stripe</Link> and submit your account details to receive payouts.
        </div>
      )}
      {!!user.stripeAccountId && (
        <div className={styles.connected}>
          <div className={styles.success}>
            <CheckIcon className={styles.checkIcon} /> <span className={styles.showSm}>Connected</span>{' '}
            <span className={styles.hideSm}>Account connected to Stripe</span>
          </div>
          <StripeDeauthorizeDialog />
        </div>
      )}
      <div className={styles.pricingNote}>
        All ticket sold will be subject to Mixily's fees of 1% + $0.30 per ticket in addition to a credit card
        processing fee of 2.9% + $0.30 per charge. Payouts are made to connected accounts according to Stripe's{' '}
        <a href="https://stripe.com/docs/payouts#standard-payout-timing" target="_blank" rel="noopener noreferrer">
          standard payout schedule
        </a>
        .
      </div>
    </>
  );
};

export default StripeSettings;
