import { FEATURE_FLAGS } from 'shared/shared/json';
import { addSnackbarMessage } from 'utils/eventEmitter';
import { hasReachedAllowedCapacity, isEventAtCapacity } from 'shared/shared/utils';
import ManualRSVPModal from 'components/EventGuestList/ManualRSVPModal';
import PersonAddAltOutlined from '@material-ui/icons/PersonAddOutlined';
import React, { useState } from 'react';
import TextLink from 'components/common/TextLink';
import useEventClass from 'utils/hooks/event/useEventClass';
import useHasFeatureFlags from 'utils/hooks/user/useHasFeatureFlags';

const ManuallyAddRSVPButton = () => {
  const [open, setOpen] = useState<boolean>(false);
  const { event } = useEventClass();
  const { spamProtection } = useHasFeatureFlags(FEATURE_FLAGS.spamProtection);

  const handleSave = ({ name }: any) => {
    setOpen(false);
    addSnackbarMessage(`Manually added ${name}'s RSVP`, 'success');
  };
  const isDisabled =
    isEventAtCapacity(event.maxCapacity, event.confirmedGuests!) ||
    hasReachedAllowedCapacity(event.invitedGuests!, event.id, event.owner?.id);

  const activateSpamAProtection = spamProtection && !event.owner?.enableProEmails;
  if (isDisabled || activateSpamAProtection) {
    return null;
  }

  return (
    <>
      <TextLink onClick={() => setOpen(true)}>
        <PersonAddAltOutlined color="primary" className="opacity-75" />
        <span className="ml-1"> Manually add an RSVP</span>
      </TextLink>
      <ManualRSVPModal isOpen={open} onClose={() => setOpen(false)} onSave={handleSave} />
    </>
  );
};

export default ManuallyAddRSVPButton;
