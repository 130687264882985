import { ColorTile } from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/types';
import { getURLParam } from 'utils/urls';
import { randStr } from 'shared/shared/utils';
import { useMemo } from 'react';

const useTestTiles = (): ColorTile[] => {
  const tiles = useMemo(() => {
    const tiles: ColorTile[] = [];
    const numColors = parseInt(getURLParam('colors', '0')!, 10);
    for (let i = 0; i < numColors; i++) {
      tiles.push({
        id: randStr(),
        type: 'color',
        hsv: {
          h: (i * 157) % 255, // golden ratio trick
          s: 55,
          v: 75,
        },
      });
    }
    return tiles;
  }, []);
  return tiles;
};

export default useTestTiles;
