import React from 'react';

import { reverse } from 'router';
import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import Link from 'components/Link';

const Error500Page = () => (
  <BaseLayout title="Something went wrong">
    <p>
      Sorry about that. Please email <a href="mailto:feedback@mixily.com">feedback@mixily.com</a> to help us improve
      Mixily.
      <br />
      <br />
    </p>
    <p>
      In the meantime, <Link href={reverse('home')}>try returning home</Link>.
    </p>
  </BaseLayout>
);

export default Error500Page;
