import { Skeleton } from '@material-ui/lab';
import { VIRTUAL_VENUE_SESSIONS_WITH_VENUE_QUERY } from 'utils/gql';
import { VenueRole } from 'generated/globalTypes';
import {
  VirtualVenueSessionsWithVenue,
  VirtualVenueSessionsWithVenueVariables,
} from 'generated/VirtualVenueSessionsWithVenue';
import { useQuery } from '@apollo/client';
import { useUserId } from 'utils/hooks/user/useUser';
import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import Error404Page from 'components/pages/Error404Page';
import Error500Page from 'components/pages/Error500Page';
import LoginRequired from 'components/LoginRequired';
import React from 'react';
import VirtualVenueGuestLogDisplay from 'components/pages/VirtualVenueGuestLogPage/VirtualVenueGuestLogDisplay';

interface Props {
  id: string;
}

const VirtualVenueGuestLogPage = ({ id }: Props) => {
  const userId = useUserId();

  const { loading, error, data } = useQuery<VirtualVenueSessionsWithVenue, VirtualVenueSessionsWithVenueVariables>(
    VIRTUAL_VENUE_SESSIONS_WITH_VENUE_QUERY,
    {
      variables: { venueId: id, userId },
      skip: !userId,
    }
  );

  if (loading || (!loading && !error && !data)) {
    return (
      <BaseLayout title="Loading...">
        <Skeleton variant="rect" height={360} />
      </BaseLayout>
    );
  }

  if (error) {
    return <Error500Page />;
  }

  if (!data?.virtualVenue) {
    return <Error404Page />;
  }

  if (userId && data.virtualVenue.myAccess.role !== VenueRole.OWNER) {
    return (
      <BaseLayout title="Permission denied">
        <p>Sorry, you don't have permission to view the guest log.</p>
      </BaseLayout>
    );
  }

  return (
    <LoginRequired>
      <BaseLayout title={`${data.virtualVenue.title ? `${data.virtualVenue.title}: ` : ''} Guest Log`}>
        <VirtualVenueGuestLogDisplay sessions={data.virtualVenueSessions ?? []} />
      </BaseLayout>
    </LoginRequired>
  );
};

export default VirtualVenueGuestLogPage;
