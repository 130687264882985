import * as F from 'shared/shared/Functional';
import { FormControlLabel, FormHelperText, Select } from '@material-ui/core';
import { WidgetProps } from 'components/widgets';
import React, { ReactNode, useMemo } from 'react';

export type SelectOptions = string[] | Record<string, string>;

export interface SelectAttrs {
  // `options` - Either a list of values, or value -> name map
  helpText?: string;
  noFullWidth?: boolean;
  title?: string;
  options: SelectOptions;
  ref?: React.Ref<HTMLSelectElement>;
}

export const makeOptions = (options: SelectOptions): ReactNode => {
  if (Array.isArray(options)) {
    return options.map((option) => (
      <option key={option} value={option}>
        {option}
      </option>
    ));
  } else {
    return F.objMap(options, (value, name) => (
      <option key={value} value={value}>
        {name}
      </option>
    ));
  }
};

function MuiSelectWidget(props: WidgetProps<string, SelectAttrs>) {
  const { value, id, disabled, onChange, attrs } = props;
  if (!attrs?.options) {
    throw new Error('MuiSelectWidget requires attrs.options');
  }
  const options = useMemo(() => makeOptions(attrs.options), [attrs.options]);

  if (attrs.helpText && attrs.title) {
    return (
      <FormControlLabel
        control={
          <Select
            native
            fullWidth={!attrs.noFullWidth}
            variant="standard"
            disabled={disabled}
            value={value}
            inputProps={{ id, className: props.className }}
            onChange={(e: React.ChangeEvent<{ value: any }>) => onChange(e.target.value)}
          >
            {options}
          </Select>
        }
        label={
          <div className="w-full pl-6 ml-6 border-l-2 border-gray-400 border-solid">
            <div className="block">{attrs.title}</div>
            {/* Use blank help text to preserve vertical alignment */}
            <div className="block">{attrs.helpText && <FormHelperText>{attrs.helpText}</FormHelperText>}</div>
          </div>
        }
        labelPlacement="end"
      />
    );
  }
  return (
    <Select
      native
      fullWidth={!attrs.noFullWidth}
      variant="outlined"
      disabled={disabled}
      value={value}
      inputProps={{ id, className: props.className }}
      onChange={(e: React.ChangeEvent<{ value: any }>) => onChange(e.target.value)}
    >
      {options}
    </Select>
  );
}

export default MuiSelectWidget;
