import React from 'react';

import { DocumentNode } from 'graphql';
import { INVITE_EMAIL_PREVIEW_QUERY, MESSAGE_EMAIL_PREVIEW_QUERY, REMINDER_EMAIL_PREVIEW_QUERY } from 'utils/gql';
import { InviteEmailPreviewQuery, InviteEmailPreviewQueryVariables } from 'generated/InviteEmailPreviewQuery';
import { Loading } from 'components/common';
import { MessageEmailPreviewQuery, MessageEmailPreviewQueryVariables } from 'generated/MessageEmailPreviewQuery';
import { ReminderEmailPreviewQuery, ReminderEmailPreviewQueryVariables } from 'generated/ReminderEmailPreviewQuery';
import { useQuery } from '@apollo/client';

import Dialog from 'components/common/Dialog/Dialog';
import styles from 'components/EmailPreviewModal.module.scss';

export enum EmailPreview {
  INVITE = 'invite',
  MESSAGE = 'message',
  REMINDER = 'reminder',
}

interface Props {
  eventId: string;
  isOpen: boolean;
  emailPreviewType: EmailPreview;
  message?: string;
  onClose: () => void;
}

const EmailPrewiewToQuery: Record<EmailPreview, DocumentNode> = {
  [EmailPreview.INVITE]: INVITE_EMAIL_PREVIEW_QUERY,
  [EmailPreview.MESSAGE]: MESSAGE_EMAIL_PREVIEW_QUERY,
  [EmailPreview.REMINDER]: REMINDER_EMAIL_PREVIEW_QUERY,
};

const EmailPreviewModal = (props: Props) => {
  const { eventId, message, emailPreviewType, isOpen, ...modalProps } = props;
  const query = EmailPrewiewToQuery[emailPreviewType];

  const { loading, error, data } = useQuery<
    MessageEmailPreviewQuery | InviteEmailPreviewQuery | ReminderEmailPreviewQuery,
    MessageEmailPreviewQueryVariables | InviteEmailPreviewQueryVariables | ReminderEmailPreviewQueryVariables
  >(query, {
    variables: { eventId, message },
    fetchPolicy: 'network-only',
    skip: !isOpen,
  });

  let preview: any;
  if (data && (data as MessageEmailPreviewQuery).messageEmailPreview) {
    preview = (data as MessageEmailPreviewQuery).messageEmailPreview;
  } else if (data && (data as ReminderEmailPreviewQuery).reminderEmailPreview) {
    preview = (data as ReminderEmailPreviewQuery).reminderEmailPreview;
  } else if (data) {
    preview = (data as InviteEmailPreviewQuery).inviteEmailPreview;
  }

  if (!preview) {
    return null;
  }

  const genEmail = () => {
    if (loading) {
      return (
        <div>
          <Loading size="small" />
        </div>
      );
    }

    if (error || !data) {
      return <div>There was an error loading the preview.</div>;
    }

    return (
      <div className={styles.emailPreview}>
        <div className={styles.subject}>
          <span className={styles.subjectLabel}>Subject:</span> {preview.subject}
        </div>
        <div className={styles.previewBody} dangerouslySetInnerHTML={{ __html: preview.bodyHtml }} />
      </div>
    );
  };

  return (
    <Dialog {...modalProps} isOpen contentClassName={styles.dialogContent} title="Email Preview">
      {genEmail()}
    </Dialog>
  );
};

export default EmailPreviewModal;
