import * as F from 'shared/shared/Functional';
import * as m from 'models';
import { CREATE_ORDER_MUTATION } from 'utils/gql';
import { Controller, useForm } from 'react-hook-form';
import { CreateOrder, CreateOrderVariables } from 'generated/CreateOrder';
import { DEFAULT_LOGO_COLOR } from 'utils/constants';
import { DiscountCodeQuery_discountCode } from 'generated/DiscountCodeQuery';
import { EventWithExistQuery_event_eventsFromRun } from 'generated/EventWithExistQuery';
import { FormTicket } from 'models/ticket';
import { ReactComponent as LogoImg } from 'components/Logo/mixily-logo.svg';
import { OrderMode, TicketOrderInput } from 'generated/globalTypes';
import { PriceType } from 'shared/shared/types';
import { RedirectToPath } from 'components/Redirect';
import { analyticsAPI as analytics } from 'utils/api';
import { calculateTotalDiscount, sortByPosition } from 'utils/helpers';
import { convertRunTicketToEventTicket } from 'models/run';
import { reverse } from 'router';
import { useMutation } from '@apollo/client';
import DisableInPreview from 'components/DisableInPreview/DisableInPreview';
import DiscountCodeForm from './DiscountCodeForm/DiscountCodeForm';
import FieldError from 'components/forms/FieldError';
import FieldLabel from 'components/common/FieldLabel/FieldLabel';
import FieldLayout from 'components/common/FieldLayout/FieldLayout';
import Input2 from 'components/common/Input2/Input2';
import LoadStripeAndRedirect from './LoadStripeAndRedirect/LoadStripeAndRedirect';
import OrderSummary from './OrderSummary/OrderSummaryForEmbedded';
import PhoneInput from 'components/common/PhoneInput/PhoneInput';
import React, { useState } from 'react';
import TicketsWidget, { TicketOrder, TicketsValue } from './TicketsWidget/TicketsWidgetForEmbedded';
import When from 'components/When/When';
import isEmail from 'validator/lib/isEmail';
import useEventClass from 'utils/hooks/event/useEventClass';
import useMutationFormHelpers from 'components/forms/useMutationFormHelpers';
import useUser from 'utils/hooks/user/useUser';

interface FormState {
  discountCode?: DiscountCodeQuery_discountCode;
  mode: OrderMode;
  orderId?: string;
  stripeCheckoutSessionId?: string;
}

function getInitialTicketValues(ticketTypes: m.TicketTypeForPurchase[]) {
  const singleTicketType = ticketTypes.length === 1;
  return ticketTypes.reduce((previous, t, index) => {
    const price =
      t.priceType === PriceType.fixed
        ? t.fixedPriceCents
        : t.suggestedDonationDefaultCents
        ? t.suggestedDonationDefaultCents
        : 0;
    const property = `ticket-${index}`;
    const position = t.position;
    return {
      ...previous,
      [property]: {
        id: t.id,
        quantity: singleTicketType ? 1 : 0,
        price,
        position,
      } as FormTicket,
    };
  }, {});
}

function getTickets(obj: object) {
  const isTicket = (key: any) => key.includes('ticket');
  const filteredTicketObj = F.objFilter(obj, isTicket);
  return F.objMap(filteredTicketObj, (k: any, value: any) => value);
}
const normalizeTickets = (tickets: (TicketOrderInput & { position: number })[]): TicketOrderInput[] =>
  tickets.map((t) => {
    const { position, ...rest } = t;
    return rest;
  });

type FormT = {
  tickets: TicketsValue;
  name: string;
  email: string;
  phone: string;
  eventDay: Date | null;
  selectedEvent: EventWithExistQuery_event_eventsFromRun | null;
};

const validateTicketOrder = (ticket: TicketOrder, ticketType: m.TicketTypeForPurchase) => {
  const minCents = ticketType.suggestedDonationMinCents!;
  const ticketRef = ticketType.name ? `${ticketType.name} Ticket: ` : '';

  if (ticket.quantity > 0 && ticket.price == null) {
    return `${ticketRef}Please enter a contribution amount`;
  }
  if (ticket.quantity > 0 && ticket.price! < minCents) {
    return `${ticketRef}Please enter a contribution higher than the minimum contribution`;
  }
  return true;
};

const InnerTicketingForm = () => {
  const { event } = useEventClass();
  const [state, setState] = useState<FormState>({ mode: OrderMode.EDITING });

  const ticketTypes: m.TicketTypeForPurchase[] = event.ticketTypes!.slice().sort(sortByPosition);
  const formContext = useForm<FormT>({
    defaultValues: {
      ...getInitialTicketValues(ticketTypes),
      name: '',
      email: '',
      phone: '',
      eventDay: null,
      selectedEvent: null,
    },
  });
  const [doOrderCreate] = useMutation<CreateOrder, CreateOrderVariables>(CREATE_ORDER_MUTATION);
  const {
    Form,
    SubmitButton,
    FormLevelMessages,
    isSubmitting,
    FormStateContextProvider,
    onSubmit,
  } = useMutationFormHelpers<FormT, CreateOrder, CreateOrderVariables>({
    formContext,
    formToVariables: (formData) => {
      const formTickets = getTickets(formData);
      const tickets = event.isRunTemplate
        ? convertRunTicketToEventTicket(formTickets, formData.selectedEvent)
        : normalizeTickets(formTickets);
      return {
        ...formData,
        tickets,
        discounts: state.discountCode && [{ id: state.discountCode.id, amount: discount }],
      } as CreateOrderVariables;
    },
    mutation: doOrderCreate,
    resultKey: 'orderCreate',
    onSuccess: ({ orderCreate }) => {
      analytics.track('CreateOrder');
      console.log(orderCreate);
      const mode = orderCreate.mode!;
      const isFree = mode === OrderMode.FREE_CREATED;
      const stripeCheckoutSessionId = isFree ? undefined : orderCreate.stripeCheckoutSessionId!;
      const orderId = isFree ? orderCreate.orderId! : undefined!;
      setState((state) => ({
        ...state,
        mode,
        stripeCheckoutSessionId,
        orderId,
      }));
    },
  });

  const { user } = useUser();
  const isAuthenticated = m.isAuthenticated(user);

  if (state.mode === OrderMode.FREE_CREATED) {
    const path = reverse('ticketing_embed', { id: event.id }) + `?order=${state.orderId}`;
    return <RedirectToPath path={path} />;
  } else if (state.mode === OrderMode.FREE_PAID_CREATED || state.mode === OrderMode.PAID_CREATED) {
    return <LoadStripeAndRedirect stripeCheckoutSessionId={state.stripeCheckoutSessionId ?? ''} />;
  }

  const { watch, register, errors, control } = formContext;

  const tickets = getTickets(watch());
  const totalQuantity = F.sum(tickets.map(F.prop('quantity')));
  const discount = calculateTotalDiscount(tickets, state.discountCode);
  const lineTotals = tickets.map((t) => (t.price || 0) * t.quantity);
  const totalBeforeDiscount = F.sum(lineTotals);
  const paidOrder = totalBeforeDiscount - discount > 0;
  const shouldHideAdditionalFields = totalQuantity === 0 || isAuthenticated || paidOrder;

  const header = (
    <>
      <div className="my-2 text-2xl text-center">{event.title}</div>
      <div className="flex flex-col items-center mb-5 text-lg">
        <When event={event} isRun={false} alignTop />
      </div>
    </>
  );

  return (
    <>
      <div className="grid w-full grid-cols-1 gap-0 mx-auto sm:grid-cols-10">
        <div className="col-span-6 px-8 py-2">
          {header}
          <div className="mt-4">
            <FormStateContextProvider onSubmit={onSubmit} formContext={formContext} isSubmitting={isSubmitting}>
              <Form>
                <TicketsWidget<FormT>
                  attrs={{ ticketTypes, control, watch, validate: validateTicketOrder, errors }}
                  disabled={isSubmitting}
                />
                <FieldLayout
                  label="Name"
                  className={shouldHideAdditionalFields ? 'hidden' : 'mt-8'}
                  error={errors.name}
                >
                  <Input2
                    name="name"
                    size="lg"
                    disabled={isSubmitting}
                    ref={register({
                      validate: (val) => (!shouldHideAdditionalFields && !val ? 'This field is required' : true),
                    })}
                    placeholder="Your Name"
                  />
                </FieldLayout>
                <FieldLayout
                  label="Email address"
                  className={shouldHideAdditionalFields ? 'hidden' : 'mt-8'}
                  error={errors.email}
                >
                  <Input2
                    name="email"
                    type="email"
                    size="lg"
                    disabled={isSubmitting}
                    ref={register({
                      validate: (val) => {
                        if (shouldHideAdditionalFields) {
                          return true;
                        }
                        if (!val) {
                          return 'This field is required';
                        }
                        if (!isEmail(val)) {
                          return 'Please enter a valid email address';
                        }
                        return true;
                      },
                    })}
                    placeholder="you@example.com"
                  />
                </FieldLayout>
                {/* Can't use FieldLayout here -- see FIXME at the top of PhoneInput */}
                {event.captureGuestPhone && totalQuantity && (
                  <div className={event.captureGuestPhone && totalQuantity ? 'mt-8' : 'hidden'}>
                    <FieldLabel>Phone number</FieldLabel>
                    <div className="mt-1">
                      <Controller
                        as={PhoneInput}
                        name="phone"
                        control={control}
                        layoutProps={{
                          size: 'lg',
                        }}
                        disabled={isSubmitting}
                        rules={{
                          validate: (value) => (event.captureGuestPhone && !value ? 'This field is required' : true),
                        }}
                        onChangeName="onValueChange"
                        onChange={([e]) => e.value ?? ''}
                      />
                    </div>
                    <FieldError error={errors.phone} />
                  </div>
                )}
                <FormLevelMessages className="mt-6" />
                {/* Intentionally leaving 'submit' enabled when `!hasAllPrices` because it looks friendlier */}
                <div className="mt-4">
                  <DisableInPreview>
                    <SubmitButton
                      label={event.checkoutButtonLabel || 'Checkout'}
                      submittingLabel="Redirecting..."
                      className="w-full sm:w-full"
                      disabled={isSubmitting || !totalQuantity}
                    />
                  </DisableInPreview>
                </div>
              </Form>
            </FormStateContextProvider>
          </div>
          {event.showDiscountCodeInput && (
            <DiscountCodeForm
              className="pb-1 mt-5"
              discountCode={state.discountCode}
              setDiscountCode={(discountCode: DiscountCodeQuery_discountCode) => setState({ ...state, discountCode })}
            />
          )}
          <div className="mt-9">
            <a href={reverse('event_read', { id: event.id })}>
              <LogoImg style={{ height: '32px', opacity: 0.85, verticalAlign: 'middle' }} fill={DEFAULT_LOGO_COLOR} />
            </a>
          </div>
        </div>
        <div className="hidden col-span-4 px-8 py-2 bg-gray-200 sm:block">
          <div className="invisible">{header}</div>
          <OrderSummary
            tickets={tickets}
            ticketTypes={ticketTypes}
            discountCode={state.discountCode}
            currency={event.currency}
          />
        </div>
      </div>
    </>
  );
};

export default InnerTicketingForm;
