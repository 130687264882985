import * as m from 'models';
import { Button, Loading } from 'components/common';
import { CREATE_LISTING_MUTATION } from 'utils/gql';
import { CreateListing, CreateListingVariables } from 'generated/CreateListing';
import { URLContext } from 'components/RoutingProvider';
import { reverse } from 'shared/shared/routing/mixily-routes';
import { useMutation } from '@apollo/client';
import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import Error500Page from 'components/pages/Error500Page';
import LoginRequired from 'components/LoginRequired';
import React, { useContext } from 'react';
import useUser from 'utils/hooks/user/useUser';

const ListingIndexPage = () => {
  const { user } = useUser();
  const { setPath } = useContext(URLContext);

  const [createListing, { error }] = useMutation<CreateListing, CreateListingVariables>(CREATE_LISTING_MUTATION, {
    onCompleted: (data: CreateListing) => {
      const id = data.createListing.listing?.id;
      if (id) {
        setPath(reverse('listing_edit', { id }));
      }
    },
  });

  if (user === m.Unknown) {
    return (
      <BaseLayout title="Loading">
        <Loading />
      </BaseLayout>
    );
  }

  if (m.isAuthenticated(user) && user.listingId) {
    setPath(reverse('listing_read', { id: user.listingId }));
    return null;
  }

  if (error) {
    return <Error500Page />;
  }

  const userName = m.isAuthenticated(user) && user.name;

  return (
    <LoginRequired>
      <BaseLayout title="Public Listing Page" hideTitle>
        <div className="flex flex-col items-center justify-center">
          <p className="mt-12 mb-6 text-lg text-center ">
            Create a public listing page to display your upcoming events!
          </p>
          <Button
            small
            label="Get Started"
            onClick={() => createListing({ variables: { title: `${userName}'s Events` } })}
          />
        </div>
      </BaseLayout>
    </LoginRequired>
  );
};

export default ListingIndexPage;
