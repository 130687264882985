import { Link, Loading } from 'components/common';
import { ORDER_READ_QUERY } from 'utils/gql';
import { ReadOrder } from 'generated/ReadOrder';
import { useQuery } from '@apollo/client';

import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import OrderReadView from 'components/pages/OrderReadPage/OrderReadView';
import React from 'react';

interface Props {
  id: string;
}

const OrderReadPage: React.FC<Props> = (props: Props) => {
  const { id } = props;
  const { data, error, loading } = useQuery<ReadOrder>(ORDER_READ_QUERY, {
    variables: { id },
  });

  if (loading) {
    return (
      <BaseLayout title="Loading">
        <Loading />
      </BaseLayout>
    );
  }

  if (error || !data || !data.order) {
    return (
      <BaseLayout title="Order not found">
        <p>Sorry, we weren't able to find details on that order.</p>
        <p>
          <Link href="/">Please return home</Link>.
        </p>
      </BaseLayout>
    );
  }

  return <OrderReadView order={data.order} />;
};

export default OrderReadPage;
