import * as F from 'shared/shared/Functional';
import { IntervalName } from 'shared/shared/types';
import { SimpleDuration } from 'components/widgets/time-types';
import { Widget } from 'components/widgets/index';
import MuiSelectWidget from 'components/widgets/MuiSelectWidget/MuiSelectWidget';
import NumberWidget from 'components/widgets/NumberWidget/NumberWidget';
import React from 'react';

const options = F.fromPairs(Object.keys(IntervalName).map((unit) => [unit, unit.toLocaleLowerCase()]));

const DurationPickerWidget: Widget<SimpleDuration> = ({ value, onChange, disabled }) => {
  return (
    <div className="flex flex-col md:flex-row">
      <NumberWidget
        value={value.quantity}
        disabled={disabled}
        attrs={{ className: 'w-full md:w-40 md:mr-4' }}
        onChange={(quantity) => {
          onChange({
            ...value,
            quantity,
          });
        }}
      />
      <div className="w-40 mt-2 md:mt-0">
        <MuiSelectWidget
          className="text-red"
          value={value.unit}
          disabled={disabled}
          onChange={(unit) =>
            onChange({
              ...value,
              unit: unit as IntervalName,
            })
          }
          attrs={{
            options,
            noFullWidth: true,
          }}
        />
      </div>
    </div>
  );
};

export default DurationPickerWidget;
