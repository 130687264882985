/* eslint-disable no-async-promise-executor, @typescript-eslint/no-this-alias, no-prototype-builtins, prefer-spread */
import * as F from 'shared/shared/Functional';
import * as m from 'models';
import { DiscountKind } from 'shared/shared/types';
import {
  STOP_WORDS_MAP,
  THEME_PIC_MAX_HEIGHT,
  THEME_PIC_MAX_WIDTH,
  ZOOM_OAUTH_PARAMS,
  ZOOM_OAUTH_URL,
} from 'utils/constants';
import { TicketOrder } from 'components/pages/EventReadPage/RsvpForm/TicketingForm/InnerTicketingForm/TicketsWidget/TicketsWidget';
import { constructQueryString } from 'shared/shared/utils';
import { getBaseHeaders } from 'utils/api';
import { isUploadCareUrl, uploadCareApplyCrop, uploadCareBaseUrlWithDefaults } from 'utils/upload-care';

/**
 * Gets a value using a getter in a safe way such that, if an exception is thrown, a default value
 * is returned.
 */
export function get<T>(getter: () => T): T | undefined;
export function get<T>(getter: () => T, defaultValue: T): T;
export function get<T>(getter: () => T, defaultValue?: T): T | undefined {
  try {
    return getter();
  } catch (e) {
    return defaultValue;
  }
}

export let splitCamelCase = (s: string): string => {
  // From https://stackoverflow.com/questions/18379254/regex-to-split-camel-case
  return s.replace(/([a-z])([A-Z])/g, '$1 $2');
};

export let toTitleCase = (s: string): string => {
  // From https://stackoverflow.com/questions/196972/convert-string-to-title-case-with-javascript
  return s.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};

export function getImageFilesFromFileList(files: FileList) {
  return Array.from(files).filter((file: File) => file.type.startsWith('image/'));
}

const BASE_UPLOAD_URL = '/api/upload/';

function upload(formData: FormData, path: string, onUploadProgress?: Function) {
  return new Promise<string>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', `${BASE_UPLOAD_URL}${path}`, true);
    Object.entries(getBaseHeaders()).forEach(([header, value]) => {
      xhr.setRequestHeader(header, value);
    });

    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(JSON.parse(xhr.responseText).data);
      } else {
        reject(new Error(xhr.statusText));
      }
    };
    xhr.upload.onprogress = function (e) {
      if (e.lengthComputable) {
        onUploadProgress && onUploadProgress(e.loaded / e.total, e);
      }
    };
    xhr.send(formData);
  });
}

export function uploadImageForm(formData: FormData, onUploadProgress?: Function) {
  return new Promise<string>(async (resolve, reject) => {
    try {
      const src = await upload(formData, 'image/upload-care', onUploadProgress);
      resolve(src);
    } catch (e) {
      reject(e);
    }
  });
}

export function uploadImage(imageFile: File, onUploadProgress?: Function) {
  const formData = new FormData();
  if (!imageFile.type.startsWith('image/')) {
    throw new Error('A file that is not an image was detected');
  }
  formData.append('image', imageFile);
  return uploadImageForm(formData, onUploadProgress);
}

export const clamp = (value: number, min: number, max: number): number => Math.min(Math.max(value, min), max);

export const loadImage = (src: string, props?: object): Promise<HTMLImageElement> =>
  new Promise((res, rej) => {
    const image = new Image();
    props && Object.assign(image, props);
    image.onload = () => res(image);
    image.onerror = () => rej();
    image.src = src;
  });

const parseSpecialUrlData = (url?: string): object => {
  const parts = (url || '').split('#');
  const str = parts.length > 1 ? parts[parts.length - 1] : '';

  return str.split(',').reduce((memo: any, pair) => {
    const [key, val] = pair.split('=');
    if (key) memo[key] = val;
    return memo;
  }, {});
};

const stringifySpecialUrlData = (data: object): string => {
  // TODO: escape special chars (= , and #)
  return Object.entries(data)
    .map(([k, v]) => `${k}=${v}`)
    .join(',');
};

const toNumber = (val: string, defaultNumber = 0) => {
  const x = Number(val);
  return Number.isNaN(x) ? defaultNumber : x;
};

export const getUrlCrop = (url: string) => {
  const data: any = parseSpecialUrlData(url);

  return {
    x: toNumber(data['x'], 0.5),
    y: toNumber(data['y'], 0.5),
    scale: toNumber(data['scale'], 1),
  };
};

export const setUrlCrop = (url: string, crop: any): string => {
  const parts = url.split('#');
  const data: any = parseSpecialUrlData(url);
  data['x'] = crop.x.toFixed(4);
  data['y'] = crop.y.toFixed(4);
  data['scale'] = crop.scale.toFixed(4);
  return `${parts[0]}#${stringifySpecialUrlData(data)}`;
};

export const getImageCropStyle = (imageUrl: string) => {
  const crop = getUrlCrop(imageUrl);
  return {
    backgroundPositionX: crop ? `${crop.x * 100}%` : 'center',
    backgroundPositionY: crop ? `${crop.y * 100}%` : 'center',
    backgroundSize: crop && crop.scale ? `${crop.scale * 100}%` : 'cover',
  };
};

export const absoluteCropToPixelCrop = (crop: any, destWidth: number, destHeight: number) => {
  const { imageWidth, imageHeight, x, y, scale } = crop;

  // perform the crop in image coordinates
  // pixel size of crop rectangle
  const cropWidth = imageWidth / scale;
  const cropHeight = cropWidth * (destHeight / destWidth);

  // compute pixel position based on percent positions
  const cropX = x * (imageWidth - cropWidth);
  const cropY = y * (imageHeight - cropHeight);

  return {
    destWidth,
    destHeight,
    cropWidth,
    cropHeight,
    cropX,
    cropY,
  };
};

export const repositionImageUrl = (
  imageUrl: string,
  crop: string //: ThemePicCrop
): string => {
  if (isUploadCareUrl(imageUrl)) {
    // NOTE(nick): resize image to max display size * 2 (for retina screens)
    const absoluteCrop = absoluteCropToPixelCrop(crop, THEME_PIC_MAX_WIDTH * 2, THEME_PIC_MAX_HEIGHT * 2);

    // NOTE(nick): uploadcare api does not support resizing > 3000 pixels
    const effects = uploadCareApplyCrop(absoluteCrop);
    const baseUrl = uploadCareBaseUrlWithDefaults(imageUrl);
    return setUrlCrop(`${baseUrl}${effects}`, crop);
  }

  return setUrlCrop(imageUrl, crop);
};

export type UnbindListeners = () => void;

export const bindListeners = (el: HTMLElement | Window, listeners: any = {}, options: any = false): UnbindListeners => {
  Object.keys(listeners).forEach((key) => el.addEventListener(key, listeners[key], options));

  return () => {
    Object.keys(listeners).forEach((key) => el.removeEventListener(key, listeners[key], options));
  };
};

export const withFileDrop = (dropZone: HTMLElement, props: any = {}): UnbindListeners => {
  const onDrag = (e: any) => {
    e.stopPropagation();
    e.preventDefault();

    const dragging = props.onDrag ? props.onDrag(e) : true;

    if (dragging !== false) {
      e.dataTransfer.effectAllowed = props.effectAllowed || 'all';
      e.dataTransfer.dropEffect = props.dropEffect || 'copy';
      dropZone.classList.add('dragging');
    }
  };

  const onDragEnd = (e: any) => {
    e.stopPropagation();
    e.preventDefault();

    e.dataTransfer.dropEffect = 'none';
    dropZone.classList.remove('dragging');

    props.onDragEnd && props.onDragEnd(e);
  };

  const onDrop = (e: any) => {
    onDragEnd(e);

    // e.dataTransfer.files;
    props.onDrop && props.onDrop(e);
  };

  return bindListeners(dropZone, {
    dragover: onDrag,
    drop: onDrop,
    dragend: onDragEnd,
    dragleave: onDragEnd,
  });
};

type PromiseFn = (...args: any[]) => Promise<any>;

export function debouncePromise(f: Function, waitMs: number): PromiseFn {
  let timer: NodeJS.Timeout;

  return (...args) => {
    clearTimeout(timer);

    return new Promise((resolve) => {
      timer = setTimeout(() => resolve(f(...args)), waitMs);
    });
  };
}

/** Source: https://github.com/chodorowicz/ts-debounce/blob/master/src/index.ts */
export type Procedure = (...args: any[]) => void;

export type Options = {
  isImmediate: boolean;
};

/* Note: must use function format: function name() { ... } format (not const name = () => { ... }) */
export function debounce<F extends Procedure>(
  func: F,
  waitMilliseconds = 50,
  options: Options = {
    isImmediate: false,
  }
): F {
  let timeoutId: NodeJS.Timeout | undefined;

  return function (this: any, ...args: any[]) {
    const context = this;

    const doLater = function () {
      timeoutId = undefined;
      if (!options.isImmediate) {
        func.apply(context, args);
      }
    };

    const shouldCallNow = options.isImmediate && timeoutId === undefined;

    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(doLater, waitMilliseconds);

    if (shouldCallNow) {
      func.apply(context, args);
    }
  } as any;
}

/* Note: must use function format: function name() { ... } format (not const name = () => { ... }) */
export function memoize(func: any, keyFn = (args: any) => JSON.stringify(args)) {
  const memo: any = {};
  return (...args: any[]) => {
    const key = keyFn(args);
    return key in memo ? memo[key] : (memo[key] = func.apply(null, args));
  };
}

/* Note: must use function format: function name() { ... } format (not const name = () => { ... }) */
export function memoizePromise(func: (...args: any[]) => any, keyFn = (args: any[]): string => JSON.stringify(args)) {
  const memo: Record<string, any> = {};

  return (...args: any[]) => {
    const key = keyFn(args);

    if (key in memo) {
      return Promise.resolve(memo[key]);
    }

    return Promise.resolve(func.apply(null, args)).then((data) => {
      memo[key] = data;
      return data;
    });
  };
}

export const removeStopWords = (str: string) =>
  str
    .split(/\s+/)
    .filter((word) => !(word in STOP_WORDS_MAP))
    .join(' ');

export const prettyEmail = (obj: any): string => (obj.name ? `${obj.name} <${obj.email}>` : obj.email);

// Returns true if the two values are equal (handles Number sign and NaN)
export const isEqual = (x: any, y: any): boolean => {
  // SameValue algorithm
  if (x === y) {
    // Steps 1-5, 7-10
    // Steps 6.b-6.e: +0 != -0
    return x !== 0 || 1 / x === 1 / y;
  } else {
    // Step 6.a: NaN == NaN
    // eslint-disable-next-line no-self-compare
    return x !== x && y !== y;
  }
};

// Returns true if the two objects are shallow equal, from React's shallowEqual method
export const shallowEqual = (objA: any, objB: any): boolean => {
  if (isEqual(objA, objB)) {
    return true;
  }

  if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  // Test for A's keys different from B.
  for (let i = 0; i < keysA.length; i++) {
    if (!objB.hasOwnProperty(keysA[i]) || !isEqual(objA[keysA[i]], objB[keysA[i]])) {
      return false;
    }
  }

  return true;
};

export const getImageColorInfo = (imageSrc: string) => {
  const precision = 0.2;

  return loadImage(imageSrc, { crossOrigin: 'Anonymous' }).then((img) => {
    // create canvas
    const canvas = document.createElement('canvas');
    const width = Math.max(img.width * precision, 100);
    const height = Math.max(img.height * precision, 100);
    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      throw new Error('Could not get drawing context');
    }

    ctx.drawImage(img, 0, 0, width, height);

    const imageData = ctx.getImageData(0, 0, width, height);
    const data: any = imageData.data;

    let sumR = 0;
    let sumG = 0;
    let sumB = 0;
    for (let x = 0, len = data.length; x < len; x += 4) {
      sumR += data[x];
      sumG += data[x + 1];
      sumB += data[x + 2];
    }

    const area = width * height;
    const averageSum = (sumR + sumG + sumB) / 3;

    const brightness = Math.floor(averageSum / area);
    const averageR = Math.round(sumR / area);
    const averageG = Math.round(sumG / area);
    const averageB = Math.round(sumB / area);
    const averageColor = `rgb(${averageR}, ${averageG}, ${averageB})`;

    const info = { brightness, averageColor, averageR, averageG, averageB };
    return info;
  });
};

/**
 * Returns a psuedo-random string of characters.
 * NOTE(nick): this is _not_ cryptographically secure
 */
export const getPsuedoRandomId = (): string =>
  Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

export const setWindowTitleCount = (count: number) => {
  const title = document.title;
  const endIndex = title.indexOf(')');

  const titleWithoutCount = title.startsWith('(') && endIndex > 0 ? title.substring(endIndex + 2) : title;

  document.title = count > 0 ? `(${count}) ${titleWithoutCount}` : titleWithoutCount;
};

export const pluralize = (str: string, count: number, includeCount = false) => {
  return `${includeCount ? `${count} ` : ''}${str}${count === 1 ? '' : 's'}`;
};

/** Modulus with negative numbers. */
export const mod = (a: number, b: number): number => ((a % b) + b) % b;

export function centsToDollars(cents?: number | null): string {
  return cents != null ? `${cents / 100}` : '';
}

export function dollarsToCents(d?: string): number | undefined {
  if (!d) return undefined;
  const parsed = parseFloat(d);
  return Number.isNaN(parsed) ? 0 : Math.floor(parsed * 100);
}

export const noop = () => {};

export function getZoomOauthLink(params: Record<string, string> = {}): string {
  const qs = { ...ZOOM_OAUTH_PARAMS, ...params };
  return `${ZOOM_OAUTH_URL}${constructQueryString(qs)}`;
}

interface HasPosition {
  position: number | null;
}

export function sortByPosition(a: HasPosition, b: HasPosition) {
  if (a.position == null || b.position == null) {
    return 0;
  }
  return a.position - b.position;
}

export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.onload = (e) => res((e.target?.result || '').toString());
    reader.onerror = (e) => rej(e);
    reader.readAsDataURL(file);
  });
};

export function calculateTotalDiscount(tickets: TicketOrder[], discountCode?: m.DiscountCode): number {
  return tickets.reduce((acc, order) => {
    const discount = !discountCode
      ? 0
      : discountCode.type === DiscountKind.dollar
      ? order.quantity * Math.min(discountCode.amount * 100, order.price || 0)
      : (order.price || 0) * order.quantity * (discountCode.amount / 100);
    return acc + discount;
  }, 0);
}

export const getImageStyle = (imageUrl: string, width: number) => {
  return {
    backgroundSize: 'cover',
    backgroundImage: `url(${imageUrl.replace('/resize/2400x', `/resize/${width || 800}x`)})`,
  };
};

export function getCsvFilename(event: m.Event): string {
  return `${event.title ? event.title.replace(/[^\x30-\x7F]/g, '').toLowerCase() : 'mixilyevent'}-summary.csv`;
}

export function formatPhoneNumber(str?: string | null): string {
  const phone = str || '';
  let cleaned = ('' + phone).replace(/\D/g, '');
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  return match ? '(' + match[1] + ') ' + match[2] + '-' + match[3] : phone;
}

export function plusOnesOptionsForHost(max: number): Record<string, string> {
  const list = F.range(1, max + 1) as number[];
  const plusOne = list.reduce((acc, currentValue) => {
    const value = `Up to ${currentValue}`;
    return { ...acc, [currentValue.toString()]: value };
  }, {});
  return plusOne;
}

export function plusOnesOptionsForGuest(max: number): Record<string, string> {
  const list = F.range(1, max + 1) as number[];
  const plusTwo = list.reduce((acc, currentValue) => {
    const value = `+${currentValue}`;
    return { ...acc, [currentValue.toString()]: value };
  }, {});
  return {
    '0': 'Just me',
    ...plusTwo,
  };
}
