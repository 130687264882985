// Example usage:
// const {data, error, status, run} = useAsync()
// React.useEffect(() => {
//   run(fetchPokemon(pokemonName))
// }, [pokemonName, run])

import React from 'react';

type AsyncState<T, E extends {} = {}> = {
  status: 'idle' | 'resolved' | 'rejected' | 'pending';
  error: E | null;
  data: T | null;
};

function useSafeDispatch<Action>(dispatch: React.Dispatch<Action>) {
  const mounted = React.useRef(false);
  React.useLayoutEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return React.useCallback((arg: Action) => (mounted.current ? dispatch(arg) : void 0), [dispatch]);
}

const defaultInitialState = {
  status: 'idle',
  data: null,
  error: null,
} as const;

function useAsync<T, E extends {} = {}>(initialState: AsyncState<T, E> = defaultInitialState) {
  const initialStateRef = React.useRef({
    ...defaultInitialState,
    ...initialState,
  });
  const [{ status, data, error }, setState] = React.useReducer(
    (s: AsyncState<T, E>, a: Partial<AsyncState<T, E>>) => ({ ...s, ...a }),
    initialStateRef.current
  );

  const safeSetState = useSafeDispatch<Partial<AsyncState<T, E>>>(setState);

  const setData = React.useCallback((data: T) => safeSetState({ data, status: 'resolved' }), [safeSetState]);
  const setError = React.useCallback((error: E) => safeSetState({ error, status: 'rejected' }), [safeSetState]);
  const reset = React.useCallback(() => safeSetState(initialStateRef.current), [safeSetState]);

  const run = React.useCallback(
    (promise) => {
      if (!promise || !promise.then) {
        throw new Error(
          `The argument passed to useAsync().run must be a promise. Maybe a function that's passed isn't returning anything?`
        );
      }
      safeSetState({ status: 'pending' });
      return promise.then(
        (data: T) => {
          setData(data);
          return data;
        },
        (error: E) => {
          setError(error);
          return Promise.reject(error);
        }
      );
    },
    [safeSetState, setData, setError]
  );

  return {
    // using the same names that react-query uses for convenience
    isIdle: status === 'idle',
    isLoading: status === 'pending',
    isError: status === 'rejected',
    isSuccess: status === 'resolved',

    setData,
    setError,
    error,
    status,
    data,
    run,
    reset,
  } as const;
}

export { useAsync };
