import 'components/ScheduleTimeDialog.scss';
import { Moment } from 'moment';
import Button2 from 'components/common/Button2/Button2';
import Dialog from 'components/common/Dialog/Dialog';
import React, { useState } from 'react';
import SendLaterWidget from 'components/widgets/SendLaterWidget/SendLaterWidget';

type FormT = {
  scheduledFor: Moment | null;
};

interface Props {
  modalOpen: boolean;
  onClose: () => void;
  handleSubmit: (formData: FormT) => void;
}

const ScheduleTimeDialog = (props: Props) => {
  const { modalOpen, onClose, handleSubmit } = props;
  const [scheduledFor, setScheduledFor] = useState<Moment | null>(null);

  return (
    <Dialog
      dialogClassModifier="ScheduleTimeDialog--picker"
      isOpen={modalOpen}
      onClose={onClose}
      size="sm"
      title="Schedule Invite"
      actions={[
        <Button2
          key="submit"
          submit={true}
          onClick={() => {
            handleSubmit({ scheduledFor });
            onClose();
          }}
        >
          Set Time
        </Button2>,
      ]}
    >
      <SendLaterWidget value={scheduledFor} onChange={setScheduledFor} disabled={false} className="mt-6" />
    </Dialog>
  );
};

export default ScheduleTimeDialog;
