import 'iframe-resizer/js/iframeResizer.contentWindow.js';

export const isIframed = (function () {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
})();

export function initializeIframeResizer() {
  if (!isIframed) {
    return;
  }

  if (window.iFrameResizer) {
    console.error('iFrameResizer resizer already defined.');
  } else {
    window.iFrameResizer = {
      autoResize: true,
      heightCalculationMethod: 'taggedElement',
      onMessage: function (message: string) {
        window.iFrameResizer.parentUrl = message;
      },
    };
  }
}
