import { FormContextValues } from 'react-hook-form';
import React, { useContext } from 'react';

export type FormState<T extends Record<string, any> = any> = {
  onSubmit: (result: T) => void;
  formContext: FormContextValues<T>;
  isSubmitting?: boolean;
};

const FormStateContext = React.createContext<FormState>(null as any);

export const useFormStateContext = <T extends Record<string, any> = any>() => {
  return useContext(FormStateContext as React.Context<FormState<T>>);
};

export default FormStateContext;
