// config - helps you define and validate your project config, parsing process.env
// Problems with having application code read from process.env directly:
// - You have to remember to validate the value everywhere you use it (e.g. check if it exists, parseInt, or make a type assertion)
// - There's no single place for developers to look and see what settings are used
// - There's no clear specification for providing non-string values, especially booleans

export interface ConfigOptions<T = string> {
  default?: T;
  cast?: (val: string) => T;
}

export const castBool = (val: string): boolean => {
  // Based on https://docs.python.org/3/distutils/apiref.html#distutils.util.strtobool,
  // which is what python-decouple uses.
  if (val === '') {
    return false;
  }
  if (['y', 'yes', 't', 'true', 'on', '1'].includes(val.toLowerCase())) {
    return true;
  }
  if (['n', 'no', 'f', 'false', 'off', '0'].includes(val.toLowerCase())) {
    return false;
  }
  throw new Error("Couldn't parse Boolean value from: " + val);
};

export const castNum = (val: string): number => {
  const digits = /^\d+$/;
  if (!digits.test(val)) {
    throw new Error("Couldn't parse integer from config value: " + val);
  }
  return parseInt(val, 10);
};

export const boolConfig = (defaultVal?: boolean): ConfigOptions<boolean> => ({
  default: defaultVal,
  cast: castBool,
});

export const numConfig = (defaultVal?: number): ConfigOptions<number> => ({
  default: defaultVal,
  cast: castNum,
});

export const strConfig = (defaultVal?: string): ConfigOptions<string> => ({
  default: defaultVal,
});

export const loadConfig = <T = string>(envVarName: string, options?: ConfigOptions<T>): T => {
  let valStr: string;
  const { cast, default: defaultVal } = options || {};
  if (process.env[envVarName]) {
    valStr = process.env[envVarName]!;
  } else if (defaultVal === undefined) {
    throw new Error('Missing value for required environment variable: ' + envVarName);
  } else {
    return defaultVal;
  }
  if (cast) {
    return cast(valStr);
  }
  // Assumption: user doesn't pass empty `options` when they want a non-string
  return (valStr as unknown) as T;
};
