import { Cell, Column } from 'react-table';
import { EventSummariesForPerson_me_eventSummaries } from 'generated/EventSummariesForPerson';
import { getDefaultTimezone } from 'utils/datetime';
import { getImageStyle } from 'utils/helpers';
import { renderDateTimeShort } from 'models';
import { renderDollars } from 'shared/shared/utils';
import React from 'react';
import moment from 'moment';

export type EventSummary = EventSummariesForPerson_me_eventSummaries;

export const COLUMNS: Column<EventSummary>[] = [
  {
    Header: 'Event',
    accessor: 'title',
    Cell: ({ row }: Cell<EventSummary>) => {
      const eventSummary = row.original;
      return (
        <div className="flex items-center">
          <div
            className="flex-shrink-0 w-10 h-10 rounded-full"
            style={eventSummary.themePic ? getImageStyle(eventSummary.themePic, 400) : {}}
          />
          <div className="ml-4">
            <div>{eventSummary.title}</div>
          </div>
        </div>
      );
    },
  },
  {
    Header: `Start (${moment.tz(getDefaultTimezone()).zoneAbbr()})`,
    accessor: 'startTime',
    Cell: ({ row }: Cell<EventSummary>) => {
      const eventSummary = row.original;
      return eventSummary.startTime ? (
        <div className="flex items-center leading-5">{renderDateTimeShort(moment(eventSummary.startTime))}</div>
      ) : (
        <div className="flex items-center text-sm leading-5 text-gray-500">TBD</div>
      );
    },
  },
  {
    Header: '# Attendees',
    accessor: 'attendees',
  },
  // {
  //   Header: '# Tickets Sold',
  //   accessor: 'tickets',
  //   Cell: ({ row }: Cell<EventSummary>) => {
  //     const eventSummary = row.original;
  //     return eventSummary.tickets > 0 ? (
  //       <div className="flex items-center leading-5">{eventSummary.tickets}</div>
  //     ) : (
  //       <div className="flex items-center text-sm leading-5 text-gray-500">N/A</div>
  //     );
  //   },
  // },
  {
    Header: 'Sales',
    accessor: 'grossSales',
    Cell: ({ row }: Cell<EventSummary>) => {
      const eventSummary = row.original;
      return eventSummary.tickets > 0 ? (
        <div className="flex items-center leading-5">
          {renderDollars(eventSummary.grossSales, eventSummary.currency)}
        </div>
      ) : (
        <div className="flex items-center text-sm leading-5 text-gray-500"></div>
      );
    },
  },
  {
    Header: 'Net',
    accessor: 'netSales',
    Cell: ({ row }: Cell<EventSummary>) => {
      const eventSummary = row.original;
      return eventSummary.tickets > 0 ? (
        <div className="flex items-center leading-5">{renderDollars(eventSummary.netSales, eventSummary.currency)}</div>
      ) : (
        <div className="flex items-center text-sm leading-5 text-gray-500"></div>
      );
    },
  },
];
