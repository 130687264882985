import Delay from 'components/common/Delay/Delay';
import React from 'react';
import classNames from 'classnames';
import styles from 'components/common/Loading.module.scss';

interface Props {
  delay?: number;
  size?: 'medium' | 'small' | 'tiny';
  style?: object;
}

const Spinner = ({ delay = 0, size = 'medium', ...rest }) => (
  <Delay delay={delay}>
    <div
      className={classNames(styles.Spinner, {
        [styles[`size-${size}`]]: !!size,
      })}
      {...rest}
    />
  </Delay>
);

const Loading = (props: Props) => (
  <div className={styles.Loading}>
    <Spinner {...props} />
  </div>
);

Loading.Spinner = Spinner;

export default Loading;
