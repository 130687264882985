import * as F from 'shared/shared/Functional';
import { Person } from 'models';
import { pollQuery_poll, pollQuery_poll_options, pollQuery_poll_votes } from 'generated/pollQuery';
import { pollVoteVariables } from 'generated/pollVote';
import { prettyDate } from 'utils/datetime';
import moment from 'moment';

type PollWithoutOptionVotes = pollQuery_poll;
type PollOptionWithoutVotes = pollQuery_poll_options;
export type PollVote = pollQuery_poll_votes;

export interface PollOption extends PollOptionWithoutVotes {
  votes: PollVote[];
}

export interface Poll extends PollWithoutOptionVotes {
  options: PollOption[];
}

const getOptionText = (dates: string[]): string => {
  const count = dates.length;
  if (count === 0) {
    return moment().add(1, 'hours').startOf('hour').toISOString();
  }
  if (count === 1) {
    return moment(dates[dates.length - 1])
      .add(1, 'hours')
      .toISOString();
  }

  const d1 = moment(dates[count - 2]);
  const d2 = moment(dates[count - 1]);
  let deltaHours = Math.abs(d2.diff(d1, 'hours', true));

  if (deltaHours >= 24) {
    deltaHours = Math.floor(deltaHours / 24) * 24;
  }

  return d2.add(deltaHours, 'hours').toISOString();
};

const sortOptions = (options: PollOption[]): PollOption[] => {
  return F.sortBy(options, (opt) => new Date(opt.text).getTime());
};

export const groupPollOptionsWithVotes = (poll: PollWithoutOptionVotes): Poll => {
  // Adds votes to each of the options
  const voteOptionGroups = F.groupBy(poll.votes, (vote) => vote.optionId);
  return {
    ...poll,
    options: poll.options.map((option) => ({
      ...option,
      votes: voteOptionGroups[option.id || 'never'] || [],
    })),
  };
};

export const formatPollOptions = (options: PollOption[]): PollOption[] => {
  return sortOptions(options).map((option) => ({
    ...option,
    text: prettyDate(option.text),
  }));
};

export const getVoteWidth = (option: PollOption, totalVotes: number): number | string => {
  return option.votes ? `${100 * (option.votes.length / Math.max(totalVotes, 1))}%` : 0;
};

export const getMaxVotes = (options: PollOption[]): number => {
  return options.reduce((acc: number, option: PollOption) => Math.max(acc, option.votes ? option.votes.length : 0), 0);
};

export const getUserVotes = (votes: PollVote[], user?: Person, name?: string): Record<string, boolean> => {
  const userVotes = votes
    .filter((vote) => (vote.person && user ? vote.person.id === user.id : vote.name === name))
    .reduce((acc: Record<string, boolean>, vote: PollVote) => {
      acc[vote.optionId] = true;
      return acc;
    }, {});

  return userVotes;
};

export const getVoteUpdate = (pollId: string, userVotes: Record<string, boolean>, name: string): pollVoteVariables => {
  return {
    pollId,
    optionIds: Object.keys(userVotes).filter((optionId: string) => userVotes[optionId]),
    name,
  };
};

export function createDefaultPoll(): Poll {
  return {
    __typename: 'PollType',
    id: '',
    options: [],
    type: '',
    votes: [],
  };
}

export function appendNewPollOption(options: PollOption[]): PollOption[] {
  const dates = sortOptions(options).map((option: PollOption) => option.text);
  const text = getOptionText(dates);

  return options.concat([
    {
      __typename: 'PollOptionType',
      id: '',
      votes: [],
      text,
    },
  ]);
}
