import { VirtualVenueCallProvider } from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/contexts/VirtualVenueCallContext';
import { identity } from 'shared/shared/Functional';
import { initialDailyState } from 'components/VirtualVenue/daily/state';
import { useVirtualVenue } from 'components/VirtualVenue/contexts/VirtualVenueContext';
import { useVirtualVenueDisplay } from 'components/VirtualVenue/contexts/VirtualVenueDisplayContext';
import { useVirtualVenueState } from 'components/VirtualVenue/contexts/VirtualVenueStateContext';
import React from 'react';
import useDailyCall from 'components/VirtualVenue/daily/useDailyCall';

const RealCallProvider: React.FC = (props) => {
  const venue = useVirtualVenue();
  const venueState = useVirtualVenueState();
  const [dailyState, dailyDispatch] = useDailyCall(
    venue.dailyCallUrl,
    venueState.name,
    venue.myAccess.dailyToken || undefined
  );
  return <VirtualVenueCallProvider value={{ dailyState, dailyDispatch }}>{props.children}</VirtualVenueCallProvider>;
};

const MockCallProvider: React.FC = (props) => (
  <VirtualVenueCallProvider value={{ dailyState: initialDailyState, dailyDispatch: identity }}>
    {props.children}
  </VirtualVenueCallProvider>
);

const CallProvider: React.FC = (props) => {
  const { isLiveMode } = useVirtualVenueDisplay();
  const Provider = isLiveMode ? RealCallProvider : MockCallProvider;
  return <Provider>{props.children}</Provider>;
};

export default CallProvider;
