import { Skeleton } from '@material-ui/lab';
import { scrollToFragment } from 'utils/urls';
import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import Link from 'components/Link';
import React, { useEffect } from 'react';
import Section from 'components/pages/FaqPage/Section';
import usePrismicFaq from 'components/pages/FaqPage/usePrismicFaq';

const FaqPage = () => {
  const { data } = usePrismicFaq();

  useEffect(() => {
    scrollToFragment(window.location.href);
  }, [data]);

  return (
    <BaseLayout title="Frequently asked questions">
      <div className="max-w-lg">
        {data ? (
          data.map((sectionData) => <Section key={sectionData.title} {...sectionData} />)
        ) : (
          <>
            <Skeleton variant="text" />
            <Skeleton variant="rect" height={120} />
          </>
        )}
        <div className="mt-16">
          <Link href="mailto:hi@mixily.com" newWindow={true}>
            Have another question? Email us!
          </Link>
        </div>
      </div>
    </BaseLayout>
  );
};

export default FaqPage;
