import { mixilyAPI } from 'utils/api';
import { useEffect, useState } from 'react';
import queryUser from 'api/query-user';

interface Response {
  chargesEnabled: boolean;
  ok: boolean;
  error?: { message: string } | null;
}

function useConnectStripeAccount(code?: string) {
  const [loading, setLoading] = useState(true);
  const [chargesEnabled, setChargesEnabled] = useState(false);
  const [stripeError, setStripeError] = useState<{ message: string } | null | undefined>(null);

  useEffect(() => {
    if (code) {
      mixilyAPI
        .connectStripeAccount(code)
        .then((response: Response) => {
          if (response.ok) {
            setStripeError(null);
            setChargesEnabled(response.chargesEnabled);
            queryUser();
          } else {
            setStripeError(response.error);
            setChargesEnabled(false);
          }
          setLoading(false);
        })
        .catch(() => {
          setStripeError({
            message: 'Error connecting to Stripe',
          });
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { stripeError, loading, chargesEnabled };
}

export default useConnectStripeAccount;
