import React, { ReactNode } from 'react';
import styles from './OrderReadPage.module.scss';

interface Props {
  title: string;
  value: ReactNode;
}

const OrderDetail: React.FC<Props> = ({ title, value }) => (
  <div className={styles.Detail}>
    <div className={styles.DetailTitle}>{title}</div>
    <div className={styles.DetailValue}>{value}</div>
  </div>
);

export default OrderDetail;
