import CopyToClipboard from 'react-copy-to-clipboard';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import icon from 'components/common/ClickToCopy/copy-icon.svg';
import styles from 'components/common/ClickToCopy/ClickToCopy.module.scss';

interface Props {
  text: string;
  label?: string; // Optional override for the text that appears in the box
  className?: string;
}

const ClickToCopy: React.FC<Props> = ({ text, label, className }) => {
  const [copied, setCopied] = useState(false);

  let timeout: any;
  const handleCopied = () => {
    // Cleaner workaround
    // https://github.com/nkbt/react-copy-to-clipboard/issues/42#issuecomment-436688282
    navigator.clipboard.writeText(text);
    setCopied(true);
    timeout = setTimeout(() => setCopied(false), 1000);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, [timeout]);

  const cls = classNames(styles.button, copied && styles.copied, className);

  return (
    <CopyToClipboard text={text} onCopy={handleCopied}>
      <button className={cls} tabIndex={0}>
        <div className={styles.content}>
          <div className={styles.text}>{label || text}</div>
          <div className={styles.copyIcon}>
            <img src={icon} width="14" height="16" alt="Copy Button" />
            <div className="uppercase text-xxs color-link-color letter-spacing-s">Copy</div>
          </div>
        </div>
        <div className={classNames(styles.success, { [styles.copied]: copied })}>Copied!</div>
      </button>
    </CopyToClipboard>
  );
};

export default ClickToCopy;
