import './EventReadPage.scss';
import { DemoType } from 'models';
import { EVENT_WITH_PASSWORD_QUERY } from 'utils/gql';
import { EventReadViewSkeleton } from 'components/pages/EventReadPage/EventReadViewSkeleton';
import { EventWithPasswordQuery, EventWithPasswordQueryVariables } from 'generated/EventWithPasswordQuery';
import { useQuery } from '@apollo/client';
import Error404Page from 'components/pages/Error404Page';
import Error500Page from 'components/pages/Error500Page';
import EventLayout from 'components/layout/EventLayout/EventLayout';
import EventReadPage from 'components/pages/EventReadPage/EventReadPage';
import EventReadViewWithPassword from 'components/pages/EventReadPage/EventReadViewWithPassword';
import React from 'react';
import usePasswordEvent from 'components/App/globalstate/usePasswordEvent';
import useSetEventId from 'components/App/globalstate/useSetEventId';

interface Props {
  id: string;
}

const EventCheckPrivacy = ({ id }: Props) => {
  useSetEventId(id);
  const isDemo = id === DemoType.Id;
  const eventPassword = usePasswordEvent();
  const { refetch, loading, data: eventData, error } = useQuery<
    EventWithPasswordQuery,
    EventWithPasswordQueryVariables
  >(EVENT_WITH_PASSWORD_QUERY, {
    variables: { id, password: eventPassword },
    fetchPolicy: 'network-only',
    skip: isDemo,
  });

  if (isDemo) {
    return <EventReadPage id={id} />;
  }

  if (loading) {
    return (
      <EventLayout title="Loading...">
        <EventReadViewSkeleton />
      </EventLayout>
    );
  }

  if (error) {
    return <Error500Page />;
  }

  if (!eventData) {
    return <Error404Page />;
  }

  const event = eventData.eventWithPassword;
  if (event.hasPassword && !event.isAuthorized) {
    return (
      <EventLayout
        title={event.title}
        themePicUrl={event.themePic}
        readPage={true}
        bgColor={event.bgColor}
        bgNoBackdrop={event.bgNoBackdrop as any}
      >
        <EventReadViewWithPassword event={eventData} loading={loading} refetchPassword={refetch} />
      </EventLayout>
    );
  }

  return <EventReadPage id={id} />;
};

export default EventCheckPrivacy;
