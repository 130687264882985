import { Cell, Column } from 'react-table';
import { ContactSummariesForPerson_me_contactSummaries } from 'generated/ContactSummariesForPerson';
import React from 'react';

export type ContactSummary = ContactSummariesForPerson_me_contactSummaries;

export const COLUMNS: Column<ContactSummary>[] = [
  {
    Header: 'Contact',
    accessor: 'email',
    Cell: ({ row }) => (
      <div className="flex items-center leading-5">
        {row.original.name} {row.original.email}
      </div>
    ),
  },
  {
    Header: 'Events',
    accessor: 'numEvents',
    Cell: ({ row }) => <div className="flex items-center leading-5">{row.original.numEvents}</div>,
  },
  {
    Header: 'First Seen',
    accessor: (r) => r.firstSeen.id,
    Cell: ({ row }: Cell<ContactSummary>) => (
      <div className="flex items-center leading-5">
        {row.original.name} {row.original.firstSeen.title}
      </div>
    ),
  },
  {
    Header: 'Last Seen',
    accessor: (r) => r.lastSeen.id,
    Cell: ({ row }: Cell<ContactSummary>) => (
      <div className="flex items-center leading-5">
        {row.original.name} {row.original.lastSeen.title}
      </div>
    ),
  },
  {
    Header: 'Revenue',
    accessor: 'totalRevenue',
    Cell: ({ row }: Cell<ContactSummary>) => (
      <div className="flex items-center leading-5">{row.original.totalRevenue}</div>
    ),
  },
];
