import { CREATE_IMPORTED_GOOGLE_CONTACT_MUTATION } from 'utils/gql';
import {
  CreateImportedGoogleContact,
  CreateImportedGoogleContactVariables,
} from 'generated/CreateImportedGoogleContact';
import { addSnackbarMessage } from 'utils/eventEmitter';
import { useMutation } from '@apollo/client';

import React, { useEffect, useState } from 'react';
import TextLink from 'components/common/TextLink';

const GOOGLE_CLIENT_ID = '343680476539-7plm3tlcoldj85itjb1jdcvs0gdjkprm.apps.googleusercontent.com';
const GOOGLE_API_KEY = 'AIzaSyBeUkH9_nedNLlP4Kp2er9d3AabKlAEzIk';

/* Tell Typescript to expect the Google api object */
declare global {
  interface Window {
    gapi: any;
  }
}

const GoogleContactsImportButton = () => {
  const [isLoading, setIsLoading] = useState(false),
    [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized) {
      const start = () => {
        /* Initialize the google api in order to request required permissions */
        window.gapi.client.init({
          apiKey: GOOGLE_API_KEY,
          clientId: GOOGLE_CLIENT_ID,
          scope: 'email https://www.googleapis.com/auth/contacts.readonly',
        });
      };

      try {
        window.gapi.load('client:auth2', start);
      } catch (e) {
        addSnackbarMessage('Could not load Google API', 'error');
      }
    }
    setInitialized(true);
  }, [initialized]);

  const handleClick = (mutate: any) => {
    setIsLoading(true);

    const options = new window.gapi.auth2.SigninOptionsBuilder({
      scope: 'email https://www.googleapis.com/auth/contacts.readonly',
    });

    const handleSuccess = (googleUser: any) => {
      const authResp = googleUser.getAuthResponse();
      const basicProfile = googleUser.getBasicProfile();

      if (!authResp || !basicProfile) {
        addSnackbarMessage('There was an error importing contacts.', 'error');
        setIsLoading(false);
        return;
      }

      const email = basicProfile.getEmail();
      const authToken = authResp.access_token;

      mutate({ variables: { auth_token: authToken } })
        .then(({ data }: any) => {
          const { importedContactCreate: res } = data;
          if ((!res.errors || res.errors.length === 0) && res.ok) {
            addSnackbarMessage(`Succesfully imported contacts from ${email}.`, 'success');
          } else if (res.errors && res.errors.length > 0 && res.ok) {
            addSnackbarMessage(`Some contacts may have failed to be imported from ${email}.`, 'warning');
          } else if (res.errors && res.errors.length > 0) {
            addSnackbarMessage(`There was an error importing contacts from ${email}.`, 'error');
          }
        })
        .catch(() => {
          addSnackbarMessage(`There was an error importing contacts from ${email}.`, 'error');
        })
        .finally(() => {
          document.dispatchEvent(new CustomEvent('refetch-friends'));
          setIsLoading(false);
        });
    };

    /* Request permissions from user to download their contacts. User can
     * choose which google account to import contacts from. */
    window.gapi.auth2
      .getAuthInstance()
      .signIn()
      .then((googleUser: any) => {
        /* Has the user previously granted permission? */
        if (googleUser.hasGrantedScopes('email https://www.googleapis.com/auth/contacts.readonly')) {
          handleSuccess(googleUser);
        } else {
          googleUser.grant(options).then(
            (success: any) => handleSuccess(success),
            (fail: any) => {
              console.error(fail);
              addSnackbarMessage('There was an error importing contacts.', 'error');
              setIsLoading(false);
            }
          );
        }
      })
      .catch((err: any) => {
        console.error(err);
        addSnackbarMessage('There was an error importing contacts.', 'error');
        setIsLoading(false);
      });
  };

  const msg = isLoading ? 'Loading Contacts...' : '+Import contacts';
  const [createContact] = useMutation<CreateImportedGoogleContact, CreateImportedGoogleContactVariables>(
    CREATE_IMPORTED_GOOGLE_CONTACT_MUTATION
  );

  return (
    <TextLink onClick={() => handleClick(createContact)} text={msg} className="color-link-color label--overline" />
  );
};

export default GoogleContactsImportButton;
