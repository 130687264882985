import { Moment } from 'moment';
import { RouteName } from 'shared/shared/routing/mixily-routes';
import { URLContext } from 'components/RoutingProvider';
import { reverse } from 'router';
import { useContext } from 'react';
import useNowUntil from 'utils/hooks/useNowUntil';

const Redirect = (routeName: RouteName, params?: Record<string, string>) => {
  const C = () => {
    const { setPath } = useContext(URLContext);
    setPath(reverse(routeName, params));
    return null;
  };
  return C;
};

interface RedirectToPathProps {
  path: string;
}

export const RedirectToPath = (props: RedirectToPathProps) => {
  const { setPath } = useContext(URLContext);
  setPath(props.path);
  return null;
};

export const RedirectToExternalPath = (props: RedirectToPathProps) => {
  const { setExternalPath } = useContext(URLContext);
  setExternalPath(props.path);
  return null;
};

interface RedirectToPathAfterProps extends RedirectToPathProps {
  after: Moment;
}

export const RedirectToPathAfter = ({ path, after }: RedirectToPathAfterProps) => {
  const { setExternalPath } = useContext(URLContext);
  useNowUntil(after, () => {
    setExternalPath(path);
  });
  return null;
};

export default Redirect;
