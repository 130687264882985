import { Currency } from 'shared/shared/types';
import { TicketTypeSettings } from 'components/EventCreateUpdateForm/TicketTypesWidget/TicketTypeWidget';
import { WidgetProps } from 'components/widgets';
import AddButton from 'components/common/AddButton/AddButton';
import React from 'react';
import TicketTypeWidgetWrapper from 'components/EventCreateUpdateForm/TicketTypesWidget/TicketTypeWidgetWrapper';

export type TicketTypes = TicketTypeSettings[];

interface Attrs {
  currency: Currency;
  eventIdsWithSoldTickets: string[];
  showFeeCalculator: () => void;
}

const TicketTypesWidget: React.FC<WidgetProps<TicketTypes, Attrs>> = (props) => {
  const { value: ticketTypes, onChange, attrs } = props;
  const { currency, eventIdsWithSoldTickets, showFeeCalculator } = attrs!;

  function onHandleTicketTypeChange(index: number, value: TicketTypeSettings) {
    const newTicketTypes = ticketTypes.slice();
    newTicketTypes[index] = value;
    onChange(newTicketTypes);
  }

  function onDeleteTicketType(index: number) {
    const newTicketTypes = ticketTypes.slice(0, index).concat(ticketTypes.slice(index + 1));
    onChange(newTicketTypes);
  }

  function onAddTicketType() {
    const prevType = ticketTypes[ticketTypes.length - 1];
    const newTicketType: TicketTypeSettings = {
      ...prevType,
      name: '',
      description: '',
    };
    delete newTicketType['id'];
    const newTicketTypes = ticketTypes.concat(newTicketType);
    onChange(newTicketTypes);
  }

  function onMoveUp(index: number) {
    const newTicketTypes = ticketTypes.slice();
    const temp = newTicketTypes[index - 1];
    newTicketTypes[index - 1] = newTicketTypes[index];
    newTicketTypes[index] = temp;
    onChange(newTicketTypes);
  }

  function onMoveDown(index: number) {
    const newTicketTypes = ticketTypes.slice();
    const temp = newTicketTypes[index + 1];
    newTicketTypes[index + 1] = newTicketTypes[index];
    newTicketTypes[index] = temp;
    onChange(newTicketTypes);
  }

  return (
    <div className="vstack-small">
      {ticketTypes.map((ticketType: TicketTypeSettings, index: number) => (
        <TicketTypeWidgetWrapper
          key={ticketType.id || index}
          value={ticketType}
          disabled={!!(ticketType.id && eventIdsWithSoldTickets.includes(ticketType.id))}
          onMoveDown={() => onMoveDown(index)}
          onMoveUp={() => onMoveUp(index)}
          onDelete={() => onDeleteTicketType(index)}
          onChange={(value) => onHandleTicketTypeChange(index, value)}
          hasMany={ticketTypes.length > 1}
          isFirst={!index}
          isLast={index === ticketTypes.length - 1}
          showFeeCalculator={showFeeCalculator}
          currency={currency}
        />
      ))}
      <AddButton onClick={onAddTicketType}>Add another ticket type</AddButton>
    </div>
  );
};

export default TicketTypesWidget;
