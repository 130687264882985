import { Currency } from 'shared/shared/types';
import { EventOrderSummary } from './types';
import React from 'react';
import TicketResponse from './TicketResponse';
import styles from 'components/EventGuestList/GuestList.module.scss';

interface Props {
  currency: Currency;
  ticketsByName: EventOrderSummary[];
}

const TicketList = (props: Props) => {
  const { ticketsByName, currency } = props;
  return (
    <div className="relative">
      <div className={styles.GuestList}>
        {ticketsByName.map((orderSummary) => (
          <TicketResponse currency={currency} key={orderSummary.orderId} orderSummary={orderSummary} />
        ))}
      </div>
      <div className={styles.Fader} />
    </div>
  );
};

export default TicketList;
