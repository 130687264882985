import { useCallConfigDispatch } from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/contexts/CallConfigContext';
import { useVirtualVenue } from 'components/VirtualVenue/contexts/VirtualVenueContext';
import { useVirtualVenueTheme } from 'components/VirtualVenue/contexts/VirtualVenueThemeContext';
import IconSvg from 'components/common/IconSvg/IconSvg';
import Link from 'components/Link';
import React from 'react';
import StartButton from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/screens/VirtualVenueLobby/StartButton/StartButton';
interface Props {
  recordingUrl?: string;
}

const LeftVenue = ({ recordingUrl }: Props) => {
  const venue = useVirtualVenue();
  const theme = useVirtualVenueTheme();
  const callConfigDispatch = useCallConfigDispatch();

  return (
    <>
      <>
        <div
          className="mb-4 text-lg text-center md:text-md lg:text-lg"
          style={{ color: 'var(--v-theme-title-text-color)' }}
        >
          {venue.title}
        </div>
        <div
          className="mb-4 text-2xl text-center md:mb-6 lg:mb-10"
          style={{
            color: 'var(--v-theme-title-text-color)',
          }}
        >
          You've left!
        </div>
        {recordingUrl && (
          <div
            className="mb-4 text-xl text-center underline md:mb-6 lg:mb-10"
            style={{
              color: 'var(--v-theme-title-text-color)',
            }}
          >
            <Link style={{ color: 'inherit' }} href={recordingUrl}>
              <div className="flex items-center">
                <IconSvg name="videoCamera" className="w-8 h-8 mr-2"></IconSvg>
                Click here to download your latest recording
              </div>
            </Link>
          </div>
        )}
      </>

      <StartButton
        className="mb-4 text-lg rounded-full md:mb-6 lg:mb-10 xl:16 sm:h-10 lg:text-xl xl:text-2xl"
        onClick={() => {
          callConfigDispatch({ type: 'set-desired-join-state', joinState: 'idle' });
        }}
      >
        {'Back to lobby'}
      </StartButton>

      <Link
        href="/features/virtualvenue"
        className="inline-block mx-auto text-sm font-semibold text-center opacity-75"
        newWindow={true}
        style={{
          color: theme.titleTextColor,
        }}
      >
        <span className="underline">Get your own custom video chat</span>{' '}
        <IconSvg name="externalLink" className="inline-block w-4 h-4 align-text-bottom" />
      </Link>
    </>
  );
};
export default LeftVenue;
