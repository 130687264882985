import {
  ReadVirtualVenue_virtualVenue,
  ReadVirtualVenue_virtualVenue_events,
  ReadVirtualVenue_virtualVenue_theme,
} from 'generated/ReadVirtualVenue';
import {
  VirtualVenueSessionsWithVenue_virtualVenueSessions,
  VirtualVenueSessionsWithVenue_virtualVenueSessions_participants,
} from 'generated/VirtualVenueSessionsWithVenue';

export type VirtualVirtualVenueTheme = ReadVirtualVenue_virtualVenue_theme;
export type VirtualVenueData = ReadVirtualVenue_virtualVenue;
export type VirtualVenueEvent = ReadVirtualVenue_virtualVenue_events;
export type VirtualVenueSession = VirtualVenueSessionsWithVenue_virtualVenueSessions;
export type VirtualVenueSessionParticipant = VirtualVenueSessionsWithVenue_virtualVenueSessions_participants;

export const ColorOptions = {
  bgColor: 'Background',
  gridLineColor: 'Grid lines',
  titleTextColor: 'Title text',
  iconColor: 'Icons',
  chatBgColor: 'Chat panel background',
  chatTextColor: 'Chat panel text',
  scrollbarColor: 'Scroll bar',
} as const;

export type VirtualVenueThemeColor = keyof typeof ColorOptions;
export const ColorNames = Object.keys(ColorOptions) as VirtualVenueThemeColor[];

export type VirtualVenueThemeOption = VirtualVenueThemeColor;
export type VirtualVenueThemeColorConfig = Record<VirtualVenueThemeColor, string>;

export function isVirtualVenueThemeColor(s: string): s is VirtualVenueThemeColor {
  return Object.keys(ColorOptions).includes(s);
}
