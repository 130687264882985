import { IconName } from 'components/common/Icon/Icon';
import IconWithText from 'components/common/IconWithText/IconWithText';
import React from 'react';
import styles from './EventPrivacyIndicators.module.scss';
import useEventClass from 'utils/hooks/event/useEventClass';

interface Indicator {
  condition: boolean | string;
  icon: IconName;
  text: string;
}

const PrivacyIndicators: React.FC = () => {
  const { event } = useEventClass();
  if (!event) return null;

  const { guestsCanInvite, showGuestList, privacy } = event;

  const indicators: Indicator[] = [
    {
      condition: privacy === 'V',
      icon: 'noEye',
      text: 'Private — Only people who are specifically invited can view the event page and RSVP',
    },
    {
      condition: privacy === 'U',
      icon: 'person',
      text: 'Unlisted — Anyone who is given the event URL can view the event page and RSVP ',
    },
    {
      condition: privacy === 'P',
      icon: 'noEye',
      text: 'Password Protected — Only people with the password ',
    },
    {
      condition: showGuestList,
      icon: 'eye',
      text: 'Guests can see who else is invited',
    },
    {
      condition: privacy === 'V' && guestsCanInvite,
      icon: 'addPerson',
      text: 'Guests can invite other guests',
    },
    {
      condition: privacy === 'V' && !guestsCanInvite,
      icon: 'noPerson',
      text: 'Guests cannot invite other guests',
    },
  ];
  const iconsWithText = indicators.map(
    (item, key) =>
      item.condition && (
        <div key={key} className={styles.PrivacyIndicatorItem}>
          <IconWithText icon={item.icon} size="small" alignTop>
            {item.text}
          </IconWithText>
        </div>
      )
  );

  return (
    <div className={styles.PrivacyIndicators}>
      <div className="label--overline">Privacy Settings</div>
      <div>{iconsWithText}</div>
    </div>
  );
};

export default PrivacyIndicators;
