// Prefer `Dialog2` for new components

import { DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialog, { DialogProps } from '@material-ui/core/Dialog';
import React, { ReactNode } from 'react';
import styles from './Dialog.module.scss';

export interface IDialogProps {
  actions?: ReactNode[];
  children?: ReactNode;
  content?: ReactNode;
  contentClassName?: string;
  dialogClassModifier?: string; // To modify/override the MuiDialog style
  fullScreen?: boolean;
  isOpen: boolean;
  onClose: () => void;
  size?: DialogProps['maxWidth'];
  title?: string;
}

export default function Dialog(props: IDialogProps) {
  const { isOpen, onClose, title, content, children, actions, size, contentClassName, fullScreen } = props;
  const classModifier = props.dialogClassModifier || '';

  return (
    <MuiDialog
      className={classModifier}
      onClose={onClose}
      open={isOpen}
      fullWidth
      fullScreen={fullScreen}
      maxWidth={size || 'sm'}
    >
      {title && (
        <DialogTitle className={styles.title} disableTypography>
          <div className={styles.titleText}>{title}</div>
          {onClose ? (
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon className={styles.closeIcon} />
            </IconButton>
          ) : null}
        </DialogTitle>
      )}
      <DialogContent className={contentClassName}>{content || children}</DialogContent>
      {actions && <DialogActions className={styles.actions}>{actions}</DialogActions>}
    </MuiDialog>
  );
}
