import { GALLERY_QUERY } from 'utils/gql';
import { Gallery } from 'generated/Gallery';
import { Loading } from 'components/common';
import { useQuery } from '@apollo/client';

import IPImageGrid from 'components/EventCreateUpdateForm/ImagePicker/IPImageGrid';
import IPTab from 'components/EventCreateUpdateForm/ImagePicker/IPTab';
import React from 'react';

interface Props {
  onSelect: any;
}

const getGalleryCategories = (data: any) => {
  const gallery = (data || {}).gallery || {};

  return (gallery.categories || [])
    .filter((category: any) => category.images.length > 0)
    .map((category: any) => ({
      ...category,
      title: category.name,
      images: category.images.map((image: any) => ({
        ...image,
        title: image.name,
        themePic: image.url,
      })),
    }));
};

const GalleryTab: React.FC<Props> = ({ onSelect }) => {
  const { data, loading, error } = useQuery<Gallery>(GALLERY_QUERY, {
    pollInterval: 1000,
  });

  if (loading || error) {
    return (
      <IPTab>
        <Loading delay={0} />
      </IPTab>
    );
  }

  return (
    <IPTab>
      {getGalleryCategories(data).map((category: any) => (
        <IPImageGrid key={category.id} title={category.title} images={category.images} onClick={onSelect} />
      ))}
    </IPTab>
  );
};

export default GalleryTab;
