import { useCallConfigDispatch } from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/contexts/CallConfigContext';
import { useDeviceConfigDispatch } from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/contexts/DeviceConfigContext';
import Button2 from 'components/common/Button2/Button2';
import IconSvg from 'components/common/IconSvg/IconSvg';
import React from 'react';

const DevicesError = () => {
  const callConfigDispatch = useCallConfigDispatch();
  const deviceConfigDispatch = useDeviceConfigDispatch();
  return (
    <div style={{ color: 'var(--v-theme-title-text-color)' }}>
      <h3 className="flex items-center mb-4 md:mb-6 lg:mb-10">
        <IconSvg className="w-6 h-6 mr-2" name="exclamation" />
        <span>Device Error</span>
      </h3>
      <div className="mb-4 md:mb-6 lg:mb-10">
        <h3>We were unable to access your camera and microphone. Please change your browser settings and try again.</h3>
      </div>

      <div className="mb-4 md:mb-6 lg:mb-10">
        <Button2
          onClick={() => {
            callConfigDispatch({ type: 'set-desired-join-state', joinState: 'idle' });
          }}
          color="secondary"
        >
          Back
        </Button2>
        <Button2
          onClick={() => {
            deviceConfigDispatch({ type: 'set-access-granted', accessGranted: null });
          }}
          color="secondary"
        >
          Try Again
        </Button2>
      </div>

      <div>
        Still experiencing problems? Try{' '}
        <a href="https://www.mixily.com/site/experiencing-problems">these troubleshooting tips</a>.
      </div>
    </div>
  );
};
export default DevicesError;
