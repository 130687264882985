// Important to use case-matching key/value strings for type-graphql's sake

export enum EventPrivacy {
  // values from models.py
  Private = 'V',
  Unlisted = 'U',
  Password = 'P',
}

export enum PriceType {
  suggested = 'suggested',
  fixed = 'fixed',
}

export enum DiscountKind {
  percent = 'percent',
  dollar = 'dollar',
}

export enum RoleType {
  host = 'h',
}

export enum VirtualVenueRoleType {
  owner = 'o',
  moderator = 'm',
}

export enum OrderMode {
  FREE_CREATED = 'free-created',
  PAID_CREATED = 'paid-created',
  FREE_PAID_CREATED = 'free-and-paid-created',
  EDITING = 'editing',
}

export enum IntervalName {
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
  DAYS = 'DAYS',
}

export enum CurrencyType {
  USD = 'usd',
  CAD = 'cad',
}

export type CurrencyLabel = keyof typeof CurrencyType;
export type Currency = `${CurrencyType}`;
