import React from 'react';
import RichTextPage from './RichTextPage';
import usePrismicPage from 'utils/prismic/usePrismicPage';

interface Props {
  title: string;
  pageName: string;
}

const PrismicPage = ({ title, pageName }: Props) => {
  const { data } = usePrismicPage(pageName);
  return <RichTextPage title={title} content={data} />;
};

export default PrismicPage;
