import { lcm } from 'shared/shared/utils';
import useGridLayout from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/CallDisplay/GridLayout/useGridLayout';
import useSpeakerLayout from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/CallDisplay/SpeakerLayout/useSpeakerLayout';

type SpotlightLayoutConfig = {
  spotlight: {
    containerSize: {
      width: number;
      height: number;
    };
    tileSize: {
      width: number;
      height: number;
    };
    rows: number;
    cols: number;
    colSpan: number;
  };
  others: {
    perPage: number;
    colSpan: number;
  };
  gridColumns: number;
};

const useSpotlightLayout = (
  spotlightTiles: number,
  containerWidth: number,
  containerHeight: number
): SpotlightLayoutConfig => {
  const speakerLayout = useSpeakerLayout(containerWidth, containerHeight, spotlightTiles);
  const {
    speakerSize: { height },
    others: { perPage },
  } = speakerLayout;
  const gridLayout = useGridLayout(spotlightTiles, containerWidth, height);
  const gridCols = lcm(perPage, gridLayout.cols);
  return {
    spotlight: {
      containerSize: speakerLayout.speakerSize,
      tileSize: gridLayout.tileSize,
      rows: gridLayout.rows,
      cols: gridLayout.cols,
      colSpan: gridCols / gridLayout.cols,
    },
    others: {
      perPage: speakerLayout.others.perPage,
      colSpan: gridCols / perPage,
    },
    gridColumns: gridCols,
  };
};

export default useSpotlightLayout;
