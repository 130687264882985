import * as CSS from 'csstype';
import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './Button2.module.scss';

const baseStyles = (disabled: boolean) => classNames(styles.Button2, !disabled && 'hover:shadow-md cursor-pointer');
const sizeStyles = {
  '0': 'p-0',
  '8': 'h-8 text-sm px-3 py-2 rounded-sm',
  '12': 'h-12 px-6 py-2 text-lg rounded-sm',
  '14': 'h-14 px-6 py-2 text-lg',
};

const colorStyles = {
  primary: (disabled: boolean) =>
    classNames(
      'text-white hover:text-gray-100 focus:ring-gray',
      !disabled && 'bg-black hover:bg-gray-900',
      disabled && 'bg-gray-500'
    ),

  secondary: (disabled: boolean) => classNames(styles.secondary, 'focus:border-blue-300 focus:ring-blue'),

  red: (disabled: boolean) => 'bg-red-600 text-white hover:bg-red-500 focus:border-red-700 focus:ring-red',

  'red-outline': (disabled: boolean) =>
    'border-red-500 bg-white text-red-500 hover:tex-red-700 focus:border-red-700 focus:ring-red',
} as const;

export type ButtonColor = keyof typeof colorStyles;
export type ButtonSize = keyof typeof sizeStyles;

export interface ButtonProps {
  children: ReactNode;
  className?: string;
  color?: ButtonColor; // defaults to primary
  size?: ButtonSize; // defaults to 12 (48px)
  disabled?: boolean; // defaults to false
  name?: string;
  onClick?: () => void;
  submit?: boolean; // defaults to false
  style?: CSS.Properties;
  form?: string;
}

const Button2 = (props: ButtonProps) => {
  const { children, form, color = 'primary', size = '12', disabled = false, name, onClick, submit = false } = props;
  const className = classNames(baseStyles(disabled), colorStyles[color](disabled), sizeStyles[size], props.className);

  return (
    <button
      type={submit ? 'submit' : 'button'}
      disabled={disabled}
      onClick={onClick}
      className={className}
      name={name}
      form={form}
      style={props.style as any}
    >
      {children}
    </button>
  );
};

export default Button2;
