import { Link } from 'components/common';
import { reverse } from 'router';
import Container from 'components/layout/Container/Container';
import React from 'react';
import Title from 'components/pages/VirtualVenueLandingPage/LandingPage/IntroSection/Title';
import balancedBeginnings from './balanced-beginnings-3.png';
import classNames from 'classnames';
import diagram from './diagram-3.png';
import styles from './IntroSection3.module.scss';

const IntroSection3 = () => {
  return (
    <div className="relative">
      <Container>
        <div className="mt-4 md:mt-2">
          <Title />
        </div>

        <div className="mt-16 lg:mt-20">
          <div className={styles.IntroSection}>
            <div className={styles.IntroCreateImage}>
              <img
                alt="Styled virtual venue within a website"
                className="max-w-full shadow-sm ring-gray-200 ring-1"
                src={balancedBeginnings}
              />
            </div>
            <div className={classNames(styles.IntroCopy, styles.IntroCreateCopy)}>
              <div className={styles.Headline}>Host custom video gatherings right in your website</div>
              <div className={classNames(styles.Subhead, 'mt-8')}>
                Virtual Venue is a video chat that you can customize and embed in your website at a permanent URL
              </div>
            </div>
          </div>
        </div>

        <div className="mt-12 md:mt-16 lg:mt-24 xl:mt-32">
          <div className={classNames(styles.IntroSection, styles.Reverse)}>
            <div className={styles.IntroGatherImage}>
              <img
                alt="Styled virtual venue within a website"
                className="max-w-full shadow-sm ring-gray-200 ring-1"
                src={diagram}
              />
            </div>
          </div>
        </div>

        <div className="h-0 mt-16 md:mt-20 lg:mt-32">&nbsp;</div>
      </Container>
    </div>
  );
};

export default IntroSection3;
