import './CallMessage.css';
import React from 'react';

interface Props {
  header: string;
  detail: string;
  isError: boolean;
}

export default function CallMessage(props: Props) {
  return (
    <div className={'call-message' + (props.isError ? ' error' : '')}>
      <p className="call-message-header">{props.header}</p>
      <p>{props.detail}</p>
    </div>
  );
}
