import { Tile } from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/types';
import { isLocal } from 'components/VirtualVenue/daily/utils';
import ColorTile from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/CallDisplay/TileDisplay/ColorTile/ColorTile';
import DailyParticipantTile from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/CallDisplay/TileDisplay/DailyParticipantTile/DailyParticipantTile';
import DemoParticipantTile from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/CallDisplay/TileDisplay/DemoParticipantTile/DemoParticipantTile';
import InviteTile from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/CallDisplay/TileDisplay/InviteTile/InviteTile';
import React from 'react';
import VideoPlayerTile from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/CallDisplay/TileDisplay/VideoStreamTile/VideoPlayerTile';

interface Props {
  tile: Tile;
  isOffScreen?: boolean;
  label?: string;
}

const TileDisplay = ({ tile: p, isOffScreen, label }: Props) => {
  if (p.type === 'daily') {
    const { id, callItem, isActiveSpeaker } = p;
    return (
      <DailyParticipantTile
        id={id}
        dailyUserId={callItem.id}
        videoTrack={isOffScreen ? null : callItem.videoTrack}
        audioTrack={callItem.audioTrack}
        isLocalPerson={isLocal(id)}
        isLoading={callItem.isLoading}
        isActiveSpeaker={isActiveSpeaker}
      />
    );
  } else if (p.type === 'invite') {
    return <InviteTile />;
  } else if (p.type === 'video') {
    return <VideoPlayerTile url={p.url} options={p.options} />;
  } else if (p.type === 'demo') {
    return <DemoParticipantTile videoSrc={p.mediaUrl} name={p.name} id={p.id} />;
  } else {
    return <ColorTile hsv={p.hsv} />;
  }
};

export default TileDisplay;
