import IconSvg, { IconSvgName } from 'components/common/IconSvg/IconSvg';
import React, { ReactNode } from 'react';

interface Props {
  title: string;
  iconName: IconSvgName;
  children: ReactNode;
}

const FeatureSectionDetail = (props: Props) => {
  const { title, iconName, children } = props;
  return (
    <div className="flex">
      <div className="flex-shrink-0">
        <div className="flex items-center justify-center w-12 h-12 text-white bg-indigo-500 rounded-md">
          <IconSvg name={iconName} className="w-6 h-6" />
        </div>
      </div>
      <div className="ml-4">
        <h5 className="text-lg font-semibold text-black leading-6">{title}</h5>
        <p className="mt-2 text-base text-gray-700 leading-6">{children}</p>
      </div>
    </div>
  );
};

export default FeatureSectionDetail;
