import { Menu, MenuItem } from '@material-ui/core';
import { ProcessingStates } from 'generated/globalTypes';
import { downloadLink } from 'components/pages/VirtualVenueRecordingPage/VirtualVenueRecordingDisplay';
import ConfirmDeleteRecording from 'components/pages/VirtualVenueRecordingsPage/ConfirmDeleteRecording';
import IconSvg from 'components/common/IconSvg/IconSvg';
import React, { useState } from 'react';
import moment from 'moment';

interface Props {
  recordingId?: string;
  extRecordingId: string;
  compositingStatus: ProcessingStates;
  venueId: string;
  startTime: moment.Moment;
  onDeleteSuccess?: () => unknown;
  fetchAccessLink: () => Promise<{ download_link: string }>;
}

const VirtualVenueRecordingOptions = ({
  recordingId,
  extRecordingId,
  compositingStatus,
  venueId,
  startTime,
  onDeleteSuccess,
  fetchAccessLink,
}: Props) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMixilyRecording = !!recordingId;

  const handleClose = () => {
    setMenuOpen(false);
  };

  const handleDownloadClick = () => {
    handleClose();
    downloadLink(fetchAccessLink, startTime.toString(), isMixilyRecording ? 'mp4' : 'webm');
  };

  const handleDeleteClick = () => {
    handleClose();
    setIsDeleteModalOpen(true);
  };

  return (
    <>
      <button onClick={() => setMenuOpen(!isMenuOpen)} className="align-middle">
        <IconSvg ref={setMenuAnchorEl} name="dotsVertical" className="inline-block w-6 h-6 mt-3 transform rotate-180" />
      </button>
      <ConfirmDeleteRecording
        venueId={venueId}
        isMixilyRecording={isMixilyRecording}
        recordingId={recordingId}
        dailyRecordingId={extRecordingId}
        open={isDeleteModalOpen}
        onCancel={() => {
          setIsDeleteModalOpen(false);
        }}
        onSuccess={() => {
          if (onDeleteSuccess) onDeleteSuccess();
        }}
      />
      <Menu
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorEl={menuAnchorEl}
        keepMounted
        open={isMenuOpen}
        onClose={handleClose}
      >
        {compositingStatus === ProcessingStates.DONE && (
          <MenuItem onClick={handleDownloadClick}>
            <span className="text-sm">Download</span>
          </MenuItem>
        )}
        <MenuItem onClick={handleDeleteClick}>
          <span className="text-sm">Delete</span>
        </MenuItem>
      </Menu>
    </>
  );
};

export default VirtualVenueRecordingOptions;
