import React from 'react';
import classNames from 'classnames';
import styles from './ActionButton.module.scss';

interface Props {
  onClick?: () => void;
  disabled?: boolean;
  children: React.ReactNode;
  className?: string;
}

const ActionButton = (props: Props) => {
  const cls = classNames(styles.ActionButton, props.disabled && styles.disabled, props.className);
  return (
    <div className={cls} onClick={props.disabled ? undefined : props.onClick}>
      {props.children}
    </div>
  );
};

export default ActionButton;
