import { Loading } from 'components/common';
import { VERIFY_EMAIL_MUTATION } from 'utils/gql';
import { VerifyEmailByToken, VerifyEmailByTokenVariables } from 'generated/VerifyEmailByToken';
import { reverse } from 'router';
import { useMutation } from '@apollo/client';

import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import Link from 'components/Link';
import React, { useEffect } from 'react';
import useUser from 'utils/hooks/user/useUser';

interface Props {
  email: string;
  token: string;
}

const AccountVerifyEmailPage: React.FC<Props> = ({ email, token }) => {
  const [verifyEmailMutation, { data, error, loading }] = useMutation<VerifyEmailByToken, VerifyEmailByTokenVariables>(
    VERIFY_EMAIL_MUTATION
  );
  const { refetchUser } = useUser();

  useEffect(() => {
    verifyEmailMutation({ variables: { token } });
    // Disabled until we fix re-rendering problems higher up in the tree
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <BaseLayout title={'Email Verification'}>
        <Loading />
      </BaseLayout>
    );
  }

  if (error || !data || !data.emailAuthVerifyByToken) {
    return (
      <BaseLayout title={'Email Verification'}>
        <div>{error?.message}</div>
      </BaseLayout>
    );
  }

  const { ok, errors } = data.emailAuthVerifyByToken;
  const errorMessage = errors && errors.length > 0 ? errors[0].message : '';

  if (ok) {
    refetchUser();
  }

  return (
    <BaseLayout title={'Email Verification'}>
      <div>
        <div>{ok ? `Successfully verified your email!` : `Error: ${errorMessage}`}</div>
        <div style={{ marginTop: '1em' }}>
          <Link href={reverse('event_calendar')}>Continue to calendar</Link>
        </div>
      </div>
    </BaseLayout>
  );
};

export default AccountVerifyEmailPage;
