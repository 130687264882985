import { DateT, DateTime, Time } from 'components/widgets/time-types';
import { WidgetProps } from 'components/widgets/index';
import DatePickerWidget from 'components/widgets/DatePickerWidget';
import Pill from 'components/common/Pill';
import React, { Component } from 'react';
import TimePickerWidget from 'components/widgets/TimePickerWidget/TimePickerWidget';
import classNames from 'classnames';
import moment from 'moment';
import styles from './DateTimeWidget.module.scss';

interface Attrs {
  showDatePill?: boolean;
  timePill?: {
    content: string;
    onClick?: () => void;
    className?: string;
  };
}

class DateTimeWidget extends Component<WidgetProps<DateTime, Attrs>> {
  handleChange = (newVal: DateT | Time) => {
    this.props.onChange({
      ...this.props.value,
      ...newVal,
    });
  };

  render() {
    const { id, value, disabled, attrs } = this.props;
    const { showDatePill = true, timePill } = attrs || {};
    return (
      <div className={styles.wrapper}>
        <div className={styles.datePickerWidgetWrapper}>
          {showDatePill && <Pill className={styles.DayPill} content={moment(value).format('dddd')} />}
          <DatePickerWidget id={id} value={value} disabled={disabled} onChange={this.handleChange} />
        </div>
        <div className={styles.timePickerWidgetWrapper}>
          <TimePickerWidget value={value} disabled={disabled} onChange={this.handleChange} />
          {timePill && (
            <Pill
              onClick={timePill.onClick}
              className={classNames(styles.TimePill, timePill.onClick && 'cursor-pointer', timePill.className)}
              content={timePill.content}
            />
          )}
        </div>
      </div>
    );
  }
}

export default DateTimeWidget;
