import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const FeatureSectionTitle = (props: Props) => {
  return (
    <h4 className="text-2xl font-extrabold tracking-tight text-gray-900 leading-8 sm:text-3xl sm:leading-9">
      {props.children}
    </h4>
  );
};

export default FeatureSectionTitle;
