import { Timezone } from 'components/widgets/time-types';
import { WidgetProps } from 'components/widgets';
import React from 'react';
import ReactSelect from 'react-select';
import colors from 'styles/style-lib';
import moment from 'moment-timezone';
import styles from './TimeZonePickerWidget.module.scss';

// there is a minor bug in this widget. it renders the timezone short name (e.g. PST)
// based on the current date+time, when it should use the event's start date+time (could be PDT)

// This widget talks to the Form in a raw string `value`,
// but stores timezones in this format internally.
interface TimeZoneInfo {
  text: string; // human-displayed label
  value: string; // official value like "US/Eastern" or "America/New_York"
  time: number; // GMT offset
}

const timeZoneList = ((): TimeZoneInfo[] => {
  // Based on https://www.npmjs.com/package/react-select-timezone
  const offsetTmz: any[] = [];

  for (const tzName of moment.tz.names()) {
    const tz = moment.tz(tzName).format('Z').replace(':00', '').replace(':30', '.5');

    let x = parseInt(tz).toFixed(2);
    //let x = (tz === 0) ? 0 : parseInt(tz).toFixed(2);

    const timeZone = {
      text: `${tzName} (GMT${moment.tz(tzName).format('Z')})`,
      value: tzName,
      time: x,
    };
    offsetTmz.push(timeZone);
  }
  offsetTmz.sort((a, b) => a.time - b.time);
  return offsetTmz;
})();

export const TimeZonePickerWidget = (props: WidgetProps<Timezone>) => {
  const { onChange } = props;

  function handleTimezoneChange(data: any) {
    onChange(data.value);
  }

  const { id, value, disabled } = props;
  const optionValue = timeZoneList.find((option) => option.value === value);

  return (
    <ReactSelect
      id={id}
      options={timeZoneList}
      isDisabled={disabled}
      onChange={handleTimezoneChange}
      getOptionLabel={(option: TimeZoneInfo) => option.text}
      getOptionValue={(option: TimeZoneInfo) => option.value}
      value={optionValue}
      escapeClearsValue={true}
      className={styles.select}
      theme={(theme) => {
        return {
          ...theme,
          borderRadius: 0,
          spacing: {
            ...theme.spacing,
            controlHeight: 44,
          },
          colors: {
            ...theme.colors,
            primary: colors.black,
          },
        };
      }}
      styles={{
        control: (provided) => ({
          ...provided,
          boxShadow: 'none !important',
        }),
      }}
    />
  );
};
