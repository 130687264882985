import { EVENT_TICKET_LIST_QUERY } from 'utils/gql';
import { EventGuestListSkeleton } from 'components/pages/EventReadPage/EventReadViewSkeleton';
import { EventTicketList, EventTicketListVariables } from 'generated/EventTicketList';
import { useQuery } from '@apollo/client';

import EventTicketListView from './EventTicketListView';
import React from 'react';
import styles from './TicketedGuestList.module.scss';
import useAssertEventId from 'components/App/globalstate/useAssertEventId';
import useEventClass from 'utils/hooks/event/useEventClass';
import usePasswordEvent from 'components/App/globalstate/usePasswordEvent';

const TicketedGuestList: React.FC = () => {
  const eventId = useAssertEventId();
  const { isDemo, event } = useEventClass();

  const { data, loading, error } = useQuery<EventTicketList, EventTicketListVariables>(EVENT_TICKET_LIST_QUERY, {
    variables: { id: eventId, password: usePasswordEvent() },
    pollInterval: 10_000,
    skip: isDemo,
  });

  if (loading) {
    return (
      <div className={styles.NoResults}>
        <EventGuestListSkeleton />
      </div>
    );
  }

  if (error || (!isDemo && !data?.event?.ticketSet)) {
    throw new Error('Could not load ticket data');
  }

  return (
    <EventTicketListView eventId={eventId} rawTicketData={data?.event?.ticketSet || []} currency={event.currency} />
  );
};

export default TicketedGuestList;
