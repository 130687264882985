import { useCallConfig } from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/contexts/CallConfigContext';
import { useVirtualVenue } from 'components/VirtualVenue/contexts/VirtualVenueContext';
import {
  useVirtualVenueDispatch,
  useVirtualVenueState,
} from 'components/VirtualVenue/contexts/VirtualVenueStateContext';
import { useVirtualVenuePubnub } from 'components/VirtualVenue/contexts/VirtualVenuePubnubContext';
import IconSvg from 'components/common/IconSvg/IconSvg';
import React, { useEffect, useRef } from 'react';
import TileMenu from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/CallDisplay/TileDisplay/TileMenu/TileMenu';
import classNames from 'classnames';
import styles from './DailyParticipantTile.module.scss';
interface Props {
  id: string;
  dailyUserId: string | null;
  isLocalPerson: boolean;
  isLoading: boolean;
  videoTrack?: MediaStreamTrack | null;
  audioTrack?: MediaStreamTrack | null;
  isActiveSpeaker: boolean;
  onClick?: () => void;
}

const DailyParticipantTile = (props: Props) => {
  const videoEl = useRef<HTMLVideoElement>(null);
  const audioEl = useRef<HTMLAudioElement>(null);
  const { pubnubState } = useVirtualVenuePubnub();
  const venue = useVirtualVenue();
  const dispatch = useVirtualVenueDispatch();
  const { pinnedParticipant } = useVirtualVenueState();
  const bgColor = venue?.theme?.bgColor || 'black';
  const { isLocalPerson, isLoading, videoTrack, dailyUserId, id, audioTrack, onClick, isActiveSpeaker } = props;
  const myPresenceState = pubnubState.participants.find((p) => dailyUserId && p.dailyUserId === dailyUserId);
  const displayName = myPresenceState?.name;
  const { alwaysShowNames } = useCallConfig();
  const isPinned = pinnedParticipant && pinnedParticipant === id;

  /**
   * When video track changes, update video srcObject
   */
  useEffect(() => {
    videoEl.current && (videoEl.current.srcObject = new MediaStream([videoTrack!]));
  }, [videoTrack]);

  /**
   * When audio track changes, update audio srcObject
   */
  useEffect(() => {
    audioEl.current && (audioEl.current.srcObject = new MediaStream([audioTrack!]));
  }, [audioTrack]);

  return (
    <div
      className={classNames(styles.CallTile, 'relative group h-full w-full', {
        'ring-2 ring-yellow-300 ring-opacity-100': isActiveSpeaker && audioTrack,
        [styles.ShowNameOnHover]: !alwaysShowNames,
      })}
      onClick={onClick}
      style={{
        backgroundColor: bgColor,
      }}
    >
      {isLoading && (
        <p className="absolute text-sm leading-none text-white transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
          Loading...
        </p>
      )}
      {props.videoTrack ? (
        <video
          autoPlay
          muted
          playsInline
          ref={videoEl}
          className={classNames('w-full h-full', isLocalPerson && 'horizontal-flip')}
        />
      ) : (
        <div
          className="flex items-center justify-center w-full h-full opacity-50"
          style={{
            background: `linear-gradient(135deg, var(--v-theme-bg-color-dark) 0%, var(--v-theme-bg-color-light) 50%, var(--v-theme-bg-color-dark) 100%)`,
            boxShadow: 'inset 0px 0px 75px 0px rgba(0,0,0,0.2)',
          }}
        >
          {!isLoading && (
            <IconSvg
              name="cameraOff"
              className="h-auto opacity-40"
              style={{ color: 'var(--v-theme-background-camera-color)', width: '20%' }}
            />
          )}
        </div>
      )}
      {!isLocalPerson && audioTrack && <audio autoPlay ref={audioEl} />}
      {!audioTrack && !isLoading && (
        <div className="absolute bottom-0 right-0 w-6 h-6 bg-gray-600 bg-opacity-50">
          <IconSvg name="micOff" className="h-5 w-5 mt-0.5 ml-0.5 text-white opacity-80" />
        </div>
      )}
      {displayName && (
        <div
          className={classNames(
            styles.Name,
            'absolute bottom-0 left-0 bg-transparent transition-opacity duration-150 ease-out'
          )}
          style={{ maxWidth: '85%' }}
        >
          <div className="text-white overflow-ellipsis whitespace-nowrap overflow-hidden bg-gray-600 bg-opacity-60 px-1 py-0.5 text-sm opacity-90">
            {displayName}
          </div>
        </div>
      )}
      <TileMenu
        showOnHoverClassName={'opacity-0 group-hover:opacity-100'}
        participantId={dailyUserId ?? id}
        isMuted={!audioTrack}
        isLocal={isLocalPerson}
      ></TileMenu>
      {isPinned && (
        <div
          onClick={() => {
            dispatch({ type: 'unpin-participant' });
          }}
          className={classNames(
            styles.UnpinContainer,
            'absolute top-0 left-0 bg-gray-600 bg-opacity-50 opacity-50 hover:opacity-80 cursor-pointer group'
          )}
        >
          <div className={classNames('w-6 h-6 flex items-center justify-center', styles.PinnedIcon)}>
            <IconSvg name="lockClosed" className="w-5 h-5 " />
          </div>
          <div className={classNames('w-6 h-6 flex items-center justify-center', styles.UnpinIcon)}>
            <IconSvg name="lockOpen" className="w-5 h-5" />
          </div>
        </div>
      )}
    </div>
  );
};

export default DailyParticipantTile;
