import * as m from 'models';
import { Menu, MenuItem, Tooltip } from '@material-ui/core';
import { WidgetProps } from 'components/widgets';
import React from 'react';
import classNames from 'classnames';
import config from 'config';
import styles from './EventTypeWidget.module.scss';
import useEventClass from 'utils/hooks/event/useEventClass';
import useUser from 'utils/hooks/user/useUser';

export enum EventType {
  FREE = 'free',
  TICKETED = 'ticketed',
}

const EventTypeWidget: React.FC<WidgetProps<EventType>> = ({ value, onChange }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { event } = useEventClass({ required: false });
  const { user } = useUser();
  const disabledTicketPurchased = !!event?.numTickets && event?.numTickets > 0;

  // We validate strict false because there are two cases:
  // stripeChargesEnabled = false, users haven't completed the Stripe onboarding process
  // stripeChargesEnable = null, previous users who already setup their Stripe account
  // but we don't know whether they can receive payments
  const disabledNotStripeAccount = !!(user as m.Person).stripeChargesEnabled === false && config.isProd;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!disabledTicketPurchased && !disabledNotStripeAccount) setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (type: EventType) => {
    return () => {
      onChange(type);
      setAnchorEl(null);
    };
  };

  return (
    <>
      <Tooltip arrow placement="right" title={getTitle(disabledTicketPurchased, disabledNotStripeAccount)}>
        <span
          className={classNames(styles.ticketsWidget, {
            [styles.disabled]: disabledTicketPurchased || disabledNotStripeAccount,
          })}
        >
          This is a{' '}
          <span onClick={handleClick} className={styles.button}>
            {value}
          </span>{' '}
          event.
        </span>
      </Tooltip>
      <Menu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={handleClose}>
        <MenuItem onClick={handleSelect(EventType.FREE)} selected={value === EventType.FREE}>
          Free
        </MenuItem>
        <MenuItem onClick={handleSelect(EventType.TICKETED)} selected={value === EventType.TICKETED}>
          Ticketed
        </MenuItem>
      </Menu>
    </>
  );
};

function getTitle(disabledTicketPurchased: boolean, disabledNotStripeAccount: boolean) {
  if (disabledTicketPurchased) return 'Event type cannot be changed after tickets have been purchased';
  if (disabledNotStripeAccount) return "You haven't completed the Stripe onboarding process";
  return '';
}

export default EventTypeWidget;
