/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum DiscountKind {
  dollar = "dollar",
  percent = "percent",
}

/**
 * Event currency
 */
export enum EventCurrency {
  cad = "cad",
  usd = "usd",
}

/**
 * Event privacy setting
 */
export enum EventPrivacy {
  P = "P",
  U = "U",
  V = "V",
}

export enum IntervalName {
  DAYS = "DAYS",
  HOURS = "HOURS",
  MINUTES = "MINUTES",
}

/**
 * Invite create result type
 */
export enum InviteCreateResultType {
  CREATED = "CREATED",
  DUPLICATE = "DUPLICATE",
  FAILED = "FAILED",
  HOST_SELF_INVITE = "HOST_SELF_INVITE",
}

export enum OrderMode {
  EDITING = "EDITING",
  FREE_CREATED = "FREE_CREATED",
  FREE_PAID_CREATED = "FREE_PAID_CREATED",
  PAID_CREATED = "PAID_CREATED",
}

/**
 * Order payment status
 */
export enum OrderStatus {
  P = "P",
  U = "U",
}

export enum PriceType {
  fixed = "fixed",
  suggested = "suggested",
}

export enum ProcessingStates {
  DONE = "DONE",
  FAILED = "FAILED",
  IN_PROGRESS = "IN_PROGRESS",
  PENDING = "PENDING",
}

export enum RoleType {
  host = "host",
}

/**
 * Rsvp States
 */
export enum RsvpStates {
  i = "i",
  m = "m",
  n = "n",
  s = "s",
  y = "y",
}

/**
 * Subscription order status
 */
export enum SubscriptionOrderStatus {
  A = "A",
  C = "C",
  I = "I",
}

export enum SubscriptionType {
  community = "community",
  communityLegacy = "communityLegacy",
  enterprise = "enterprise",
  free = "free",
  individual = "individual",
  individualLegacy = "individualLegacy",
  ticketing = "ticketing",
}

export enum VenueRole {
  GUEST = "GUEST",
  MODERATOR = "MODERATOR",
  OWNER = "OWNER",
}

export interface ContactInput {
  email: string;
  name?: string | null;
}

export interface DiscountCodeInput {
  id?: string | null;
  type: DiscountKind;
  code: string;
  amount: number;
  isActive: boolean;
}

export interface DiscountOrderInput {
  id: string;
  amount: number;
}

export interface InviteeInput {
  name?: string | null;
  email: string;
}

export interface ListingEventInput {
  id?: string | null;
  eventId: string;
  position: number;
}

export interface SimpleDurationInput {
  quantity: number;
  unit: IntervalName;
}

export interface TicketOrderInput {
  id: string;
  quantity: number;
  price: number;
}

export interface TicketTypeInput {
  id?: string | null;
  name?: string | null;
  description: string;
  priceType?: PriceType | null;
  suggestedDonationMinCents?: number | null;
  suggestedDonationDefaultCents?: number | null;
  fixedPriceCents?: number | null;
  capacity?: number | null;
  position?: number | null;
  quantityClaimed?: number | null;
}

export interface VirtualVenueThemeInput {
  logo?: string | null;
  bgColor?: string | null;
  titleTextColor?: string | null;
  iconColor?: string | null;
  gridLineColor?: string | null;
  scrollbarColor?: string | null;
  chatTextColor?: string | null;
  chatBgColor?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
