import MenuItem from 'components/common/Tab/MenuItem';
import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './Tab.module.scss';

export interface PaneItem {
  title: ReactNode;
  content: () => React.ReactNode;
}

interface Props {
  panes: PaneItem[];
  action?: React.ReactNode;
  className?: string;
  activeIndex?: number;
  menu?: React.HTMLAttributes<HTMLUListElement>;
  onSelect?: (tabIndex: number) => void;
}

interface State {
  activeIndex: number;
}

class Tab extends React.Component<Props, State> {
  state = {
    activeIndex: 0,
  };

  componentDidUpdate(prevProps: Props) {
    const { activeIndex } = this.props;

    if (
      typeof activeIndex === 'number' &&
      prevProps.activeIndex !== activeIndex &&
      this.state.activeIndex !== activeIndex
    ) {
      this.setState({ activeIndex });
    }
  }

  handleTabClick = (tabIndex: number) => {
    const { onSelect } = this.props;
    onSelect && onSelect(tabIndex);
    this.setState({ activeIndex: tabIndex });
  };

  render() {
    const { className, panes, menu, action } = this.props;
    const { activeIndex } = this.state;
    const activePane = panes[activeIndex];

    return (
      <div className={className}>
        <div className={classNames(styles.header)}>
          <ul role="tablist" {...menu} className={classNames(styles.nav, menu && menu.className)}>
            {panes.map((pane: PaneItem, i: number) => (
              <MenuItem
                key={`${pane.title}-${i}`}
                onClick={this.handleTabClick}
                tabIndex={0}
                isActive={i === activeIndex}
                index={i}
              >
                {pane.title}
              </MenuItem>
            ))}
          </ul>
          {action}
        </div>
        {activePane && activePane.content()}
      </div>
    );
  }
}

export default Tab;
