import * as m from 'models';
import Calendar from 'react-calendar';
import React from 'react';
import moment from 'moment';
import styles from './RunCalendar.module.scss';

const Tile = (props: any) => {
  return <span></span>;
};

interface Props {
  eventsFromRun?: (m.Event & m.TicketTypeForPurchase)[] | null;
  onChange: Function;
  value: Date | null;
}

interface DateWithTicket {
  date: Date;
  ticketId: string;
}

const isSame = (availableDates: DateWithTicket[] | undefined, date: Date) =>
  availableDates?.some((d) => moment(date).isSame(d.date, 'day'));

const RunCalendar = ({ eventsFromRun, onChange, value }: Props) => {
  // TODO: Fix any type
  const availableDates = eventsFromRun?.map((t) => {
    return { date: new Date((t as any).startTime), ticketId: t.id };
  });
  const defaultCalendarShow = new Date(2020, 11, 1);
  const calendarShow =
    availableDates && moment(availableDates[0]?.date).isSame(defaultCalendarShow, 'month')
      ? defaultCalendarShow
      : new Date(2020, 10, 1);

  return (
    <Calendar
      className="mx-auto"
      calendarType="US"
      activeStartDate={calendarShow}
      formatShortWeekday={(_, date) => {
        return moment(date).format('dd');
      }}
      minDate={calendarShow}
      maxDate={moment(calendarShow).add(1, 'month').toDate()}
      nextLabel={(null as any) as undefined} // typing error
      prevLabel={(null as any) as undefined} // typing error
      next2Label={null}
      prev2Label={null}
      showNeighboringMonth={false}
      tileContent={Tile}
      tileClassName={({ date }) => {
        if (isSame(availableDates, date)) {
          return styles.highlight;
        }
        return null;
      }}
      tileDisabled={({ date }) => !isSame(availableDates, date)}
      maxDetail="month"
      onChange={(date: Date) => onChange(date)}
      value={value}
    />
  );
};

export default RunCalendar;
