import AuthPageContext from 'components/context/AuthPageContext/AuthPageContext';
import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import React from 'react';
import ResendConfirmationEmailForm from 'components/pages/ResendConfirmationEmailPage/ResendEmailConfirmationForm';

const ResendConfirmationEmailPage = () => (
  <AuthPageContext.Provider value="resend">
    <BaseLayout title="Resend Confirmation Email">
      <ResendConfirmationEmailForm />
    </BaseLayout>
  </AuthPageContext.Provider>
);

export default ResendConfirmationEmailPage;
