import * as m from 'models';
import { Button } from 'components/common';
import { EventFormT } from './types';
import { UPDATE_EVENT_MUTATION } from 'utils/gql';
import { URLContext } from 'components/RoutingProvider';
import { UpdateEvent, UpdateEventVariables, UpdateEvent_eventUpdate_event } from 'generated/UpdateEvent';
import { addSnackbarMessage } from 'utils/eventEmitter';
import { analyticsAPI as analytics } from 'utils/api';
import { eventFormToVariables, getDefaultValues } from './helpers';
import { fakeEventSave } from './demo';
import { getEventUrl } from 'models/event';
import { useMutation } from '@apollo/client';

import Confirm2 from 'components/common/Confirm2/Confirm2';
import DeleteButton from 'components/EventCreateUpdateForm/EventCreateUpdateForm/DeleteButton/DeleteButton';
import EventCreateUpdateFields from 'components/EventCreateUpdateForm/EventCreateUpdateForm/EventCreateUpdateFields';
import React, { useContext, useEffect, useRef, useState } from 'react';
import makeValidators from 'components/EventCreateUpdateForm/EventCreateUpdateForm/validators';
import styles from './EventUpdateForm.module.scss';
import useControlledMutationForm, { ControlledMutationForm } from 'components/forms/useControlledMutationForm';
import useUser from 'utils/hooks/user/useUser';

interface Props {
  event: m.Event;
}

const EventUpdateForm = (props: Props) => {
  const { event } = props;
  const [doUpdate] = useMutation<UpdateEvent, UpdateEventVariables>(UPDATE_EVENT_MUTATION);
  const defaultValues = getDefaultValues(event);
  const { setPath } = useContext(URLContext);
  const { isAuthenticated } = useUser();

  // Confirmation modal to send notifications
  const [whenWhereNotificationDialog, setWhenWhereNotificationDialog] = useState<boolean>(false);

  const getValuesRef = useRef<any>();

  const mxForm = useControlledMutationForm<EventFormT, UpdateEvent, UpdateEventVariables>({
    defaultValues,
    validators: makeValidators(getValuesRef),
    mutation: isAuthenticated ? doUpdate : (fakeEventSave('eventUpdate') as any),
    resultKey: 'eventUpdate',
    formToVariables: (data) => ({ ...eventFormToVariables(data), id: event.id, privacy: 'U' }),
  });

  getValuesRef.current = mxForm.formMethods.getValues;

  const { FormStateContextProvider, Form, SubmitButton, FormLevelMessages, successResult } = mxForm;

  const onSubmit = (mxForm: ControlledMutationForm<EventFormT, UpdateEvent>, values: EventFormT) => {
    if (values.shouldSendConfirmation) {
      mxForm.onChange('shouldSendConfirmation', false);
      setWhenWhereNotificationDialog(true);
    } else {
      mxForm.onSubmit(values);
    }
  };

  useEffect(() => {
    if (!successResult) {
      return;
    }
    const newEvent = successResult.eventUpdate.event as UpdateEvent_eventUpdate_event;
    const msg = 'Event updated!';
    analytics.track('Event-Update');
    addSnackbarMessage(msg, 'success');
    setPath(getEventUrl(newEvent));
  }, [setPath, successResult]);

  if (successResult) {
    return null;
  }

  return (
    <FormStateContextProvider
      onSubmit={(val) => onSubmit(mxForm, val)}
      isSubmitting={mxForm.isSubmitting}
      formContext={mxForm.formMethods}
    >
      <Form className="max-w-3xl mx-auto mt-4 sm:mt-8">
        <EventCreateUpdateFields mxForm={mxForm} event={event} />
        <div className="mt-6">
          <FormLevelMessages />
        </div>
        <div className="mx-4 mt-6 sm:mx-0">
          <div className={styles.ButtonContainer}>
            <SubmitButton label="Save changes" submittingLabel="Saving..." className={styles.SubmitButton} size="14" />
            <Button
              label="Cancel"
              className={styles.CancelButton}
              color="secondary"
              handleClick={() => {
                setPath(event.getUrl());
              }}
            />
            <DeleteButton event={event} className={styles.DeleteButton} />

            <Confirm2
              yesLabel="Yes, send email notification"
              noLabel="No"
              onConfirm={() => {
                mxForm.onChange('shouldSendConfirmation', true);
                setWhenWhereNotificationDialog(false);
                mxForm.onSubmit(mxForm.formMethods.getValues());
              }}
              isOpen={whenWhereNotificationDialog}
              onClose={() => {
                mxForm.onChange('shouldSendConfirmation', false);
                setWhenWhereNotificationDialog(false);
                mxForm.onSubmit(mxForm.formMethods.getValues());
              }}
              title="Send Email Notification"
            >
              Location and/or time have changed. Do you want to send an email notification to your guests?
            </Confirm2>
          </div>
        </div>
      </Form>
    </FormStateContextProvider>
  );
};

export default EventUpdateForm;
