import { Message, Reminder, isReminder } from 'components/MessageCenter/types';
import EmailPreviewModal, { EmailPreview } from 'components/EmailPreviewModal';
import MessageListEntry from 'components/MessageCenter/MessageListEntry';
import React, { useState } from 'react';
import classNames from 'classnames';
import useEventId from 'components/App/globalstate/useEventId';

interface Props {
  messages: Array<Message | Reminder>;
  className?: string;
}

const MessageList = ({ messages, className }: Props) => {
  const [previewMessage, setPreviewMessage] = useState<Message | Reminder>();
  const eventId = useEventId()!;

  return messages.length ? (
    <>
      <ul className={className}>
        {messages.map((message) => (
          <MessageListEntry key={message.id} message={message} setPreviewMessage={setPreviewMessage} />
        ))}
      </ul>
      {previewMessage && (
        <EmailPreviewModal
          eventId={eventId}
          emailPreviewType={isReminder(previewMessage) ? EmailPreview.REMINDER : EmailPreview.MESSAGE}
          message={previewMessage?.message}
          isOpen={!!previewMessage}
          onClose={() => setPreviewMessage(undefined)}
        />
      )}
    </>
  ) : (
    <div className={classNames(className, 'pt-4 flex items-center justify-center')}>No Messages</div>
  );
};

export default MessageList;
