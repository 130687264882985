import { MARK_THREAD_READ_MUTATION } from 'utils/gql';
import { MarkThreadRead, MarkThreadReadVariables } from 'generated/MarkThreadRead';
import { setWindowTitleCount } from 'utils/helpers';
import { useEffect } from 'react';
import { useMutation } from '@apollo/client';

function useThreadHandler(threadId: string) {
  const [markThreadReadMutation] = useMutation<MarkThreadRead, MarkThreadReadVariables>(MARK_THREAD_READ_MUTATION);
  let unreadMessageCount = 0;

  const markThreadRead = () => {
    return markThreadReadMutation({ variables: { threadId } });
  };

  const visibilityChange = () => {
    const isVisible = document.visibilityState === 'visible';

    // went into foreground
    if (isVisible) {
      setWindowTitleCount(0);
      unreadMessageCount = 0;
      markThreadRead();
    }
  };

  const processEvent = (eventName: string) => {
    const isVisible = document.visibilityState === 'visible';

    if (!isVisible) {
      const increment = eventName === 'new-comment' ? 1 : 0;
      const nextCount = unreadMessageCount + increment;

      setWindowTitleCount(nextCount);
      unreadMessageCount = nextCount;
    } else {
      markThreadRead();
    }
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', visibilityChange);

    markThreadRead();

    return () => {
      document.removeEventListener('visibilitychange', visibilityChange);
      setWindowTitleCount(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { processEvent };
}

export default useThreadHandler;
