// FIXME: Can't be used with a <label> wrapper, because clicking the inner react-select refocuses the <input> instead of letting you
// interact with the select

import { getCountryOptions, phoneExamples } from './helpers';
import { getExampleNumber } from 'libphonenumber-js';
import { mergeRefs } from 'utils/react';
import NumberFormatInput, {
  Props as NumberFormatInputProps,
} from 'components/common/NumberFormatInput/NumberFormatInput';
import React, { useEffect, useRef, useState } from 'react';
import ReactSelect from 'react-select';
import classNames from 'classnames';
import getCountryName from 'utils/countries';

export type Props = Omit<NumberFormatInputProps, 'numberFormatProps'>;

const PhoneInput = (props: Props, ref: Props['ref']) => {
  const { layoutProps, className, ...inputProps } = props;
  const [countryCode, setCountryCode] = useState('US');
  const [selectFocused, setSelectFocused] = useState(false);

  // Guess user's country
  useEffect(() => {
    fetch('https://ipinfo.io/json?token=9f9e03062bfb36').then((response) => {
      response.json().then((ipinfo) => {
        const code = ipinfo.country;
        if (code && phoneExamples[code as keyof typeof phoneExamples] && getCountryName(code) && countryCode === 'US') {
          setCountryCode(code);
        }
      });
    });
    // Only run on 1st load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const phoneNumber = getExampleNumber(countryCode, phoneExamples);
  const formattedNumber: string = phoneNumber.formatNational();
  const format = formattedNumber.replace(/[0-9]/g, '#');
  const inputRef = useRef<any>();

  return (
    <div className={classNames('relative', classNames)}>
      <NumberFormatInput
        layoutProps={props.layoutProps}
        numberFormatProps={{
          placeholder: formattedNumber,
          format,
        }}
        {...inputProps}
        ref={mergeRefs(ref, inputRef)}
        className="block w-full pl-3 pr-12"
      />
      <div className="absolute inset-y-0 right-0 flex items-center">
        <ReactSelect
          className={classNames('mr-2 text-gray-500', selectFocused && 'w-40 border-gray-100 border-solid border-2 ')}
          defaultValue={getCountryOptions()[0]}
          isDisabled={props.disabled}
          isClearable={false}
          isSearchable={true}
          options={getCountryOptions()}
          aria-label="Country"
          blurInputOnSelect={true}
          captureMenuScroll={false}
          value={{ label: countryCode, value: countryCode }}
          onChange={(data) => {
            // The `focus()` isn't working without setTimeout -- maybe because react-select manages focus somehow
            setTimeout(() => inputRef.current!.focus(), 0);
            setCountryCode((data as any).value);
          }}
          components={{
            IndicatorSeparator: () => null,
          }}
          onBlur={() => setSelectFocused(false)}
          onFocus={() => setSelectFocused(true)}
          styles={{
            control: (base) => ({
              ...base,
              border: 0,
              boxShadow: 'none',
              '&:hover': {
                border: 0,
              },
            }),
            singleValue: (base) => ({ ...base, color: '#aaa', width: '3em', cursor: 'pointer' }),
            valueContainer: (base) => ({
              ...base,
              width: '100%',
            }),
            input: (base) => ({
              ...base,
              width: '1em',
            }),
            dropdownIndicator: (base) => ({
              ...base,
              paddingLeft: 0,
              opacity: 0.75,
              cursor: 'pointer',
            }),
          }}
        />
      </div>
    </div>
  );
};

export default React.forwardRef(PhoneInput);
