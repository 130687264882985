import * as m from 'models';
import { Collapse, FormControlLabel, Radio, RadioGroup, duration } from '@material-ui/core';
import { DateT, DateTime, IWhen, Time, Timezone } from 'components/widgets/time-types';
import { Poll, PollOption, createDefaultPoll, groupPollOptionsWithVotes } from 'models/poll';
import { TimeZonePickerWidget } from 'components/widgets/TimeZonePickerWidget/TimeZonePickerWidget';
import { WidgetProps } from 'components/widgets';
import { durationToString, getDefaultTimezone } from 'utils/datetime';
import { shallowEqual } from 'utils/helpers';
import DateTimeWidget from 'components/widgets/DateTimeWidget';
import PollEdit from 'components/EventCreateUpdateForm/WhenWidget/PollEdit';
import React, { useEffect, useState } from 'react';
import TimeTitle from 'components/EventCreateUpdateForm/WhenWidget/TimeTitle';
import classNames from 'classnames';
import moment from 'moment';
import styles from './WhenWidget.module.scss';

const getDefaultStart = () => {
  return (
    moment()
      // Start at the next even hour at least one hour in the future.
      // Using 2 hours here instead of 1 avoids an issue where someone
      // testing Mixily at 1:59 won't hit an error when they try to
      // save (at 2:01) an event starting in the past.
      .add(2, 'hour')
      .set({ minutes: 0 })
      .toObject()
  );
};

const getDefaultEnd = (start: DateTime, timezone: Timezone) => {
  return moment.tz(start, timezone).add(1, 'hour').toObject();
};

enum DTKey {
  START = 'start',
  END = 'end',
}

enum WhenType {
  TBD = 'tbd',
  DATE = 'date',
  POLL = 'poll',
}

export const eventToIWhen = (event?: m.Event): IWhen => {
  if (!event) {
    // When creating a new event, default to having a start time rather than TBD for two reasons:
    // - Having a start time is the more common case
    // - For new users quickly testing Mixily, it shows our add-to-calendar features, which people really
    //   value.
    return {
      start: getDefaultStart(),
      timezone: getDefaultTimezone(),
    };
  }
  return {
    start: event.startTime?.toObject() ?? undefined,
    timezone: event.timezone,
    end: event.endTime?.toObject() ?? undefined,
    poll: event.poll,
  };
};

interface Attrs {
  defaultTimezone: Timezone;
}

const WhenWidget = (props: WidgetProps<IWhen, Attrs>) => {
  const { attrs, onChange, value } = props;
  const timezone = value.timezone || attrs!.defaultTimezone;
  const start = value.start || getDefaultStart();
  const end = value.end || getDefaultEnd(start, timezone);
  const defaultType = value.poll ? WhenType.POLL : value.start ? WhenType.DATE : WhenType.TBD;

  const [type, setType] = useState<WhenType>(defaultType);
  const [showEndTime, setShowEndTime] = useState<boolean>(false);
  const [showTimezone, setShowTimezone] = useState<boolean>(false);
  const [pollOptions, setPollOptions] = useState<Poll>();

  const eventDuration = moment.duration(moment(end).diff(moment(start)));
  const eventDurationString = durationToString(eventDuration);

  useEffect(() => {
    if (value.end && !showEndTime) {
      setShowEndTime(true);
    }
  }, [showEndTime, value.end]);

  function handleTypeChange(e: React.ChangeEvent<HTMLInputElement>) {
    const newType = e.target.value as WhenType;

    if (value.poll) setPollOptions(value.poll);

    if (newType === WhenType.TBD && (value.start || value.end || value.poll)) {
      onChange({});
    } else if (newType === WhenType.DATE && !value.start) {
      onChange({ start: getDefaultStart(), timezone });
    } else if (newType === WhenType.POLL && !value.poll) {
      onChange({ poll: pollOptions || createDefaultPoll() });
    }

    setType(newType);
  }

  function toggleShowEndTime() {
    const newShowEndTime = !showEndTime;
    setShowEndTime(newShowEndTime);

    if (newShowEndTime && !value.end) {
      onChange({
        ...value,
        end: getDefaultEnd(value.start || getDefaultStart(), timezone),
      });
    } else if (!newShowEndTime && value.end) {
      onChange({
        ...value,
        end: undefined,
      });
    }
  }

  function handleDTChange(key: DTKey) {
    return (newVal: DateT | Time) => {
      onChange({
        ...value,
        [key]: {
          ...(value[key] as DateTime),
          ...newVal,
        },
      });
    };
  }

  function handleTZChange(newTimezone: Timezone) {
    onChange({
      ...value,
      timezone: newTimezone,
    });
  }

  function resetTimezone() {
    handleTZChange(attrs!.defaultTimezone);
    setShowTimezone(false);
  }

  function setPollOptionAsStartTime(option: PollOption) {
    onChange({
      start: moment(option.text).toObject(),
      timezone,
    });
    setType(WhenType.DATE);
  }

  function onChangePoll(updatedPoll: Poll) {
    onChange({ poll: updatedPoll });
  }

  return (
    <div className={styles.container}>
      <RadioGroup name="type" value={type} onChange={handleTypeChange}>
        <FormControlLabel value="date" control={<Radio color="primary" />} label="Start time" />
        <FormControlLabel value="poll" control={<Radio color="primary" />} label="Poll guests" />
        <FormControlLabel value="tbd" control={<Radio color="primary" />} label="TBD" />
      </RadioGroup>
      {/* keeping this always in the dom to pre-render the timezone react-select for better performance */}
      <div className={classNames(styles.form, styles.timeForm, { block: type === WhenType.DATE })}>
        <div className={styles.section}>
          <TimeTitle label="Start" />
          <div className={styles.start}>
            <DateTimeWidget
              value={{ ...start }}
              disabled={false}
              onChange={handleDTChange(DTKey.START)}
              attrs={{
                timePill: {
                  onClick: () => setShowTimezone(!showTimezone),
                  className: classNames(styles.tzPill, showTimezone && styles.tzPillOpen),
                  content: moment.tz(timezone).format('z'),
                },
              }}
            />
          </div>
          <Collapse in={showTimezone} timeout={duration.short}>
            <div className={styles.timezone}>
              <TimeZonePickerWidget value={timezone} disabled={false} onChange={handleTZChange} />
              {timezone !== attrs!.defaultTimezone && (
                <span className={styles.smallButton} onClick={resetTimezone}>
                  reset
                </span>
              )}
            </div>
          </Collapse>
        </div>

        <div className={styles.section}>
          <TimeTitle label="End" switchChecked={showEndTime} onChange={toggleShowEndTime} />
          <Collapse in={showEndTime} timeout={duration.short}>
            <div className={styles.end}>
              <DateTimeWidget
                value={end}
                disabled={false}
                onChange={handleDTChange(DTKey.END)}
                attrs={{
                  showDatePill: !shallowEqual(start.date, end.date),
                  timePill: {
                    content: eventDurationString,
                  },
                }}
              />
            </div>
          </Collapse>
        </div>
      </div>
      {type === WhenType.POLL && value.poll && (
        <div className={classNames(styles.form, styles.pollForm, 'block')}>
          <PollEdit
            poll={groupPollOptionsWithVotes(value.poll)}
            onSetOption={setPollOptionAsStartTime}
            onChange={onChangePoll}
          />
        </div>
      )}
    </div>
  );
};

export default WhenWidget;
