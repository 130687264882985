// Based on https://usehooks.com/useWindowSize/

import { useEffect, useState } from 'react';

// @ts-ignore
import { screens } from 'styles/config/screens.js';

type WindowSize = {
  width: number;
  height: number;
  isMobileXs: boolean;
  isMobileSm: boolean;
  isMobileMd: boolean;
};

const setSize = (innerWidth: number, innerHeight: number): WindowSize => {
  return {
    width: innerWidth,
    height: innerHeight,
    isMobileXs: innerWidth <= parseInt(screens.xs),
    isMobileSm: innerWidth <= parseInt(screens.sm),
    isMobileMd: innerWidth <= parseInt(screens.md),
  };
};

function useWindowSize(): WindowSize {
  const [windowSize, setWindowSize] = useState<WindowSize>(setSize(window.innerWidth, window.innerHeight));

  useEffect(() => {
    function handleResize() {
      setWindowSize(setSize(window.innerWidth, window.innerHeight));
    }

    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

export default useWindowSize;
