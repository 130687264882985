import React from 'react';

const Title = () => {
  return (
    <div className="mx-auto text-center">
      <div className="mt-1 font-semibold text-black sm:mt-2 text-md md:text-xl">presenting</div>
      <div className="text-3xl font-semibold text-black md:text-5xl">Virtual Venue</div>
    </div>
  );
};

export default Title;
