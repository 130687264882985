// HACK: Use react-modal library instead of MUI Dialog so that 1password works.
// I tried a lot of things to make 1p work with the MUI dialog & failed -ab

import { AuthModalsState, SetAuthModalState } from 'components/RoutingProvider';
import { LoginForm, SignupForm } from 'components/auth-forms';
import Modal from 'react-modal';
import React from 'react';

interface Props {
  authModalState: AuthModalsState;
  setAuthModalState: SetAuthModalState;
}

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0 , 0.5)',
    zIndex: 10000,
  },
  content: {
    zIndex: 10000,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

const AuthModals = ({ authModalState, setAuthModalState }: Props) => {
  const { open, email, message } = authModalState;

  function handleModalClose() {
    setAuthModalState({ email: '', open: null });
  }

  const handleRequestSignupFormModal = ({ email }: AuthFormCredentials) => () => {
    setAuthModalState({ email, open: 'signup' });
  };

  const handleRequestLoginFormModal = ({ email }: AuthFormCredentials) => () => {
    setAuthModalState({ email, open: 'login' });
  };

  const handleRegisterForm = ({ email }: AuthFormCredentials) => () => {
    setAuthModalState({ email, open: null });
  };

  return (
    <Modal isOpen={!!open} onRequestClose={handleModalClose} style={customStyles} shouldCloseOnOverlayClick={true}>
      <div className="w-full mt-6 xs:w-96">
        {open === 'login' && (
          <LoginForm
            email={email}
            message={message}
            onNavigate={handleRegisterForm}
            onRequestLoginFormModal={handleRequestLoginFormModal}
            onRequestSignupFormModal={handleRequestSignupFormModal}
          />
        )}
        {open === 'signup' && (
          <SignupForm
            email={email}
            message={message}
            onRequestLoginFormModal={handleRequestLoginFormModal}
            onRequestSignupFormModal={handleRequestSignupFormModal}
          />
        )}
      </div>
    </Modal>
  );
};

export default AuthModals;
