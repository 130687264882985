import { ViewAsContext } from 'components/context/ViewAsContext/ViewAsContext';
import { useContext } from 'react';

const useViewAsContext = () => {
  const ctx = useContext(ViewAsContext);
  return {
    user: ctx?.user,
    isPreview: ctx?.isPreview || false,
  };
};

export default useViewAsContext;
