import { Button, TextLink } from 'components/common';
import {
  DELETE_ROLE_INVITE_MUTATION,
  DELETE_ROLE_MUTATION,
  RESEND_ROLE_INVITE_MUTATION,
  ROLES_QUERY,
  ROLE_INVITES_QUERY,
} from 'utils/gql';
import { DeleteRole, DeleteRoleVariables } from 'generated/DeleteRole';
import { DeleteRoleInvite, DeleteRoleInviteVariables } from 'generated/DeleteRoleInvite';
import { ResendInvite, ResendInviteVariables } from 'generated/ResendInvite';
import { RoleInvitesQuery, RoleInvitesQueryVariables } from 'generated/RoleInvitesQuery';
import { RolesQuery, RolesQueryVariables } from 'generated/RolesQuery';
import { isAuthenticated } from 'models';
import { useMutation, useQuery } from '@apollo/client';
import Dialog from 'components/common/Dialog/Dialog';
import React, { useState } from 'react';
import RoleInviteForm from 'components/pages/EventReadPage/roles/RoleInviteForm';
import RoleItem from 'components/pages/EventReadPage/roles/RoleItem';
import moment from 'moment';
import styles from './RolesDialog.module.scss';
import useEventClass from 'utils/hooks/event/useEventClass';
import useEventId from 'components/App/globalstate/useEventId';
import useUser from 'utils/hooks/user/useUser';

interface Props {}

const RolesDialog: React.FC<Props> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { user } = useUser();
  const eventId = useEventId()!;
  const { event } = useEventClass();
  const { data: dataRoles, loading: loadingRoles } = useQuery<RolesQuery, RolesQueryVariables>(ROLES_QUERY, {
    variables: { eventId },
    skip: !eventId,
  });
  const { data: dataRoleInvites, loading: loadingRoleInvites } = useQuery<RoleInvitesQuery, RoleInvitesQueryVariables>(
    ROLE_INVITES_QUERY,
    {
      variables: { eventId },
      skip: !eventId,
    }
  );
  const [deleteRole] = useMutation<DeleteRole, DeleteRoleVariables>(DELETE_ROLE_MUTATION, {
    refetchQueries: [{ query: ROLES_QUERY, variables: { eventId } }],
  });
  const [deleteRoleInvite] = useMutation<DeleteRoleInvite, DeleteRoleInviteVariables>(DELETE_ROLE_INVITE_MUTATION, {
    refetchQueries: [{ query: ROLE_INVITES_QUERY, variables: { eventId } }],
  });
  const [resendRoleInvite] = useMutation<ResendInvite, ResendInviteVariables>(RESEND_ROLE_INVITE_MUTATION, {
    refetchQueries: [{ query: ROLE_INVITES_QUERY, variables: { eventId } }],
  });

  const roles = dataRoles?.roles || [];
  const roleInvites = dataRoleInvites?.roleInvites || [];

  if (loadingRoles || loadingRoleInvites) {
    return <TextLink key="button" text="Manage Hosts" onClick={() => null} />;
  }

  return (
    <>
      <TextLink key="button" text="Manage Hosts" onClick={() => setIsOpen(true)} />
      <Dialog
        title="Manage Hosts"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        actions={[<Button key={'done'} label="Done" onClick={() => setIsOpen(false)} small />]}
      >
        <div className={styles.content}>
          <div className={styles.description}>
            Additional event hosts can edit event details, including ticket types and privacy settings.
          </div>
          <RoleInviteForm className={styles.form} />
          <div className={styles.label}>Event Hosts</div>
          <RoleItem label={event.owner?.name} message={`Created event ${moment(event.createdAt).fromNow()}`} />
          {roles.map((role) => (
            <RoleItem
              key={role.id}
              label={role.person.name}
              role={role.role}
              message={`${role.invite ? 'Accepted' : 'Added'} ${moment(role.createdAt).fromNow()}`}
              onRemove={() => deleteRole({ variables: { id: role.id } })}
            />
          ))}
          {roleInvites.map((invite) => (
            <RoleItem
              key={invite.id}
              label={invite.email}
              role={invite.role}
              message={`${
                isAuthenticated(user) && invite.sentBy?.id === user?.id ? 'You' : invite.sentBy?.name
              } invited ${moment(invite.updatedAt || invite.createdAt).fromNow()}`}
              onRemove={() => deleteRoleInvite({ variables: { id: invite.id } })}
              onResend={() => resendRoleInvite({ variables: { inviteId: invite.id } })}
            />
          ))}
        </div>
      </Dialog>
    </>
  );
};

export default RolesDialog;
