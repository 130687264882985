import 'components/layout/Header/Header.scss';
import { isAuthenticated } from 'models';
import Container from 'components/layout/Container/Container';
import DisableInPreview from 'components/DisableInPreview/DisableInPreview';
import HeaderInner from 'components/layout/Header/HeaderInner';
import IconButton from '@material-ui/core/IconButton';
import Logo from 'components/Logo';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
import classNames from 'classnames';
import useIsAuthPage from 'components/context/AuthPageContext/useIsAuthPage';
import useUser from 'utils/hooks/user/useUser';

interface Props {
  className?: string;
  landingPage?: boolean;
  handleDrawerToggle?: () => void;
  withSidebar?: boolean;
  usePermanentSidebar?: boolean;
}

const Header = ({ className, landingPage, handleDrawerToggle, withSidebar, usePermanentSidebar }: Props) => {
  const cls = classNames('transition-bgcolor-theme', 'Header', className);
  const { user } = useUser();
  const classButtonSidebar = usePermanentSidebar ? 'lg:hidden' : '';
  const isAuthPage = useIsAuthPage();

  return (
    <div className={cls}>
      <Container>
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-between">
            {withSidebar && isAuthenticated(user) && (
              <DisableInPreview>
                <span className={`mr-5 ${classButtonSidebar}`}>
                  <IconButton
                    size="small"
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                  >
                    <MenuIcon />
                  </IconButton>
                </span>
              </DisableInPreview>
            )}
            {(!withSidebar || !isAuthenticated(user)) && (
              <DisableInPreview>
                <Logo />
              </DisableInPreview>
            )}
          </div>
          <div className={isAuthPage && isAuthPage !== 'reset' ? 'hidden' : ''}>
            <DisableInPreview>
              <HeaderInner landingPage={landingPage} withSidebar={withSidebar} />
            </DisableInPreview>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Header;
