import * as F from 'shared/shared/Functional';
import { Controller, useForm } from 'react-hook-form';
import { EVENT_SCHEDULED_REMINDER_QUERY, UPDATE_EVENT_SCHEDULED_REMINDER_MUTATION } from 'utils/gql';
import { LOADING_INDICATOR_CLASS } from 'utils/constants';
import { Reminder } from 'components/MessageCenter/types';
import { SimpleDuration } from 'components/widgets/time-types';
import {
  UpdateEventScheduledReminder,
  UpdateEventScheduledReminderVariables,
} from 'generated/UpdateEventScheduledReminder';
import { useMutation } from '@apollo/client';

import Dialog2, { actionStyles } from 'components/common/Dialog2/Dialog2';
import DurationPickerWidget from 'components/widgets/DurationPickerWidget';
import FieldLayout from 'components/common/FieldLayout/FieldLayout';
import QuillRichTextWidget from 'components/widgets/QuillRichTextWidget/QuillRichTextWidget';
import React from 'react';
import useEvent from 'utils/hooks/event/useEvent';
import useMutationFormHelpers from 'components/forms/useMutationFormHelpers';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  message: Reminder;
}

type FormT = {
  message: string;
  interval: SimpleDuration;
};

const ReminderEmailEditDialog = (props: Props) => {
  const { message, isOpen, onClose } = props;
  const { event } = useEvent();
  const [updateEventScheduledReminder] = useMutation<
    UpdateEventScheduledReminder,
    UpdateEventScheduledReminderVariables
  >(UPDATE_EVENT_SCHEDULED_REMINDER_MUTATION, {
    refetchQueries: [{ query: EVENT_SCHEDULED_REMINDER_QUERY, variables: { eventId: event!.id } }],
  });

  const formContext = useForm<FormT>({
    defaultValues: {
      message: message.message,
      interval: F.pick(message.interval, 'quantity', 'unit'),
    },
  });

  const {
    Form,
    SubmitButton,
    FormLevelMessages,
    isSubmitting,
    FormStateContextProvider,
    onSubmit,
  } = useMutationFormHelpers<FormT, UpdateEventScheduledReminder, UpdateEventScheduledReminderVariables>({
    formContext,
    formToVariables: (formData) => {
      return {
        ...formData,
        id: message.id,
      };
    },
    mutation: updateEventScheduledReminder,
    resultKey: 'updateEventScheduledReminder',
    onSuccess: onClose,
  });
  const { errors, control } = formContext;

  return (
    <Dialog2 onClose={onClose} isOpen={isOpen} title="Edit Reminder Message">
      <FormStateContextProvider onSubmit={onSubmit} isSubmitting={isSubmitting} formContext={formContext}>
        <Form>
          <FieldLayout label="Message" error={errors.message}>
            <Controller
              as={QuillRichTextWidget}
              name="message"
              control={control}
              rules={{
                validate: (value) =>
                  value.includes(LOADING_INDICATOR_CLASS) ? 'Please wait for all images to finish uploading.' : true,
              }}
              onChange={([e]) => e}
              disabled={isSubmitting}
              attrs={{
                placeholder: 'Reminder message',
              }}
              value={(undefined as unknown) as any}
            />
          </FieldLayout>

          <FieldLayout label="Time Before Event" error={errors.interval as any} className="mt-10">
            <Controller
              as={DurationPickerWidget}
              name="interval"
              control={control}
              onChange={([e]) => e}
              disabled={isSubmitting}
              value={(undefined as unknown) as any}
            />
          </FieldLayout>

          <FormLevelMessages className="mt-6" />

          <div className={actionStyles}>
            <SubmitButton label="Save" submittingLabel="Saving..." />
          </div>
        </Form>
      </FormStateContextProvider>
    </Dialog2>
  );
};

export default ReminderEmailEditDialog;
