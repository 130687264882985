import { TextLink } from 'components/common';
import { URLContext } from 'components/RoutingProvider';
import React, { useContext } from 'react';

const SignupToComment = () => {
  const { authModalStateMutatorSet } = useContext(URLContext);
  return (
    <div>
      <TextLink onClick={authModalStateMutatorSet.openSignupModal} text="Sign up to comment" />
    </div>
  );
};

export default SignupToComment;
