import FieldLabel from 'components/common/FieldLabel/FieldLabel';
import React, { ReactNode } from 'react';

interface Props {
  alignLeft?: boolean;
  checkbox?: boolean;
  children: ReactNode;
  className?: string;
  label: ReactNode;
}

const LabelWithInput = (props: Props) => {
  const { label, children, className, alignLeft, checkbox = false } = props;
  const style = alignLeft
    ? 'items-center flex-col justify-between space-y-2 justify-start'
    : 'inline-flex flex-row-reverse items-center justify-end';
  return (
    <div className={className}>
      {checkbox ? (
        <label className={style}>
          <FieldLabel className="ml-2">{label}</FieldLabel>
          <div className="leading-none">{children}</div>
        </label>
      ) : (
        <label className="block">
          <FieldLabel>{label}</FieldLabel>
          <div className="mt-1">{children}</div>
        </label>
      )}
    </div>
  );
};

export default LabelWithInput;
