import React from 'react';
import classNames from 'classnames';
import styles from './MenuItem.module.scss';

interface Props {
  children: React.ReactNode;
  isActive: boolean;
  index: number;
  onClick: (index: number) => void;
  className?: string;
  tabIndex?: number;
}

const MenuItem = ({ children, isActive, className, onClick, tabIndex, index }: Props) => (
  <li
    className={classNames(styles.item, className, {
      [styles.active]: isActive,
    })}
    onClick={(event) => {
      event.preventDefault();
      onClick(index);
    }}
    tabIndex={tabIndex}
    role="tab"
    aria-current={isActive ? 'page' : undefined}
  >
    {children}
  </li>
);

export default MenuItem;
