import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import classNames from 'classnames';
import styles from './AddButton.module.scss';

interface Props {
  onClick: () => void;
  children: React.ReactNode;
  className?: string;
}

const AddButton = (props: Props) => {
  const cls = classNames(styles.AddButton, props.className);
  return (
    <div className={cls} onClick={props.onClick}>
      <AddIcon /> {props.children}
    </div>
  );
};

export default AddButton;
