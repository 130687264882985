import { MaybeRsvpResponse } from 'models/invite';
import { RsvpStates } from 'generated/globalTypes';
import { WidgetProps } from 'components/widgets';
import Button from 'components/common/Button/Button';
import GridRow from 'components/common/GridRow';
import React from 'react';
import classNames from 'classnames';
import styles from './RsvpStateWidget.module.scss';

interface Attrs {
  isEventAtCapacity: boolean;
}

type Props = WidgetProps<MaybeRsvpResponse, Attrs>;

const RsvpStateWidget = ({ value, disabled, attrs, onChange }: Props) => {
  return (
    <>
      <GridRow spacing="tiny">
        <Button
          label="Yes"
          color="secondary"
          handleClick={() => onChange(RsvpStates.y)}
          className={classNames({ [styles.active]: value === RsvpStates.y })}
          selected={!disabled && value === RsvpStates.y}
          disabled={disabled || attrs?.isEventAtCapacity}
          thin
        />
        <Button
          label="No"
          color="secondary"
          handleClick={() => onChange(RsvpStates.n)}
          className={classNames({ [styles.active]: value === RsvpStates.n })}
          selected={!disabled && value === RsvpStates.n}
          disabled={disabled}
          thin
        />
        <Button
          label="Maybe"
          color="secondary"
          handleClick={() => onChange(RsvpStates.m)}
          className={classNames({ [styles.active]: value === RsvpStates.m })}
          selected={!disabled && value === RsvpStates.m}
          disabled={disabled}
          thin
        />
      </GridRow>
      {attrs?.isEventAtCapacity ? (
        <div className="my-2">
          <div className="px-4 py-3 text-orange-500 bg-orange-100 border border-orange-500" role="alert">
            <span> This event is at capacity. Please consider adjusting the maximum capacity</span>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default RsvpStateWidget;
