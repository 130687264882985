import { pluralize } from 'utils/helpers';
import React, { useState } from 'react';
import moment, { Moment } from 'moment-timezone';
import useNowUntil from 'utils/hooks/useNowUntil';

interface Props {
  time: Moment;
  onCompletion?: Function;
  shortDurationFormat?: boolean;
  completedMessage?: string;
}

export function durationToString(duration: moment.Duration, shortDurationFormat?: boolean): string | null {
  const months = duration.months();
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  const numMonths = shortDurationFormat ? `${months}mos` : `${months} ${pluralize('month', months)}`;
  const numDays = shortDurationFormat ? `${days}d` : `${days} ${pluralize('day', days)}`;
  const numHours = shortDurationFormat ? `${hours}h` : `${hours} ${pluralize('hour', hours)}`;
  const numMinutes = shortDurationFormat ? `${minutes}m` : `${minutes} ${pluralize('minute', minutes)}`;
  const numSeconds = shortDurationFormat ? `${seconds}s` : `${seconds} ${pluralize('second', seconds)}`;
  const sep = shortDurationFormat ? ':' : ', ';

  if (months && days) {
    return `${numMonths}${sep}${numDays}`;
  } else if (months) {
    return numMonths;
  } else if (days && hours) {
    return `${numDays}${sep}${numHours}`;
  } else if (days) {
    return numDays;
  } else if (hours && minutes) {
    return `${numHours}${sep}${numMinutes}`;
  } else if (hours) {
    return numHours;
  } else if (minutes && seconds) {
    return `${numMinutes}${sep}${numSeconds}`;
  } else if (minutes) {
    return numMinutes;
  } else {
    return numSeconds;
  }
}

const TimeTil = ({ time, onCompletion, shortDurationFormat, completedMessage }: Props) => {
  const [isCounting, setIsCounting] = useState<boolean>(true);
  const now = useNowUntil(time, () => {
    setIsCounting(false);
    onCompletion && onCompletion();
  });
  if (!isCounting) {
    return <span>{completedMessage ?? 'Redirecting...'}</span>;
  }

  const d = moment.duration(time.diff(now));
  const dStr = durationToString(d, shortDurationFormat);
  return <span>{dStr}</span>;
};

export default TimeTil;
