import { ReadVirtualVenueBySlug, ReadVirtualVenueBySlugVariables } from 'generated/ReadVirtualVenueBySlug';
import { Skeleton } from '@material-ui/lab';
import { VIRTUAL_VENUE_BY_SLUG_QUERY } from 'utils/gql';
import { useQuery } from '@apollo/client';
import { useUserId } from 'utils/hooks/user/useUser';
import Error404Page from 'components/pages/Error404Page';
import Error500Page from 'components/pages/Error500Page';
import MinimalLayout from 'components/layout/MinimalLayout/MinimalLayout';
import React from 'react';
import VirtualVenue from 'components/VirtualVenue/VirtualVenue';

interface Props {
  slugOrId: string;
}

const VirtualVenuePage = ({ slugOrId }: Props) => {
  const userId = useUserId();

  const { loading, error, data, previousData } = useQuery<ReadVirtualVenueBySlug, ReadVirtualVenueBySlugVariables>(
    VIRTUAL_VENUE_BY_SLUG_QUERY,
    {
      variables: { slug: slugOrId, userId },
    }
  );

  const venueToRender = data ?? previousData;

  if (loading || (!loading && !error && !venueToRender)) {
    return (
      <MinimalLayout title="Loading...">
        <Skeleton variant="rect" height={360} />
      </MinimalLayout>
    );
  }

  if (error) {
    return <Error500Page />;
  }

  if (!venueToRender?.virtualVenueBySlug) {
    return <Error404Page />;
  }

  return (
    <MinimalLayout title={venueToRender.virtualVenueBySlug.title}>
      <div className="flex items-center justify-center w-full h-full mt-24">
        <VirtualVenue virtualVenueData={venueToRender.virtualVenueBySlug} />
      </div>
    </MinimalLayout>
  );
};

export default VirtualVenuePage;
