import { Button, Loading } from 'components/common';
import IPTab from 'components/EventCreateUpdateForm/ImagePicker/IPTab';
import React from 'react';
import styles from 'components/EventCreateUpdateForm/ImagePicker/ImagePicker.module.scss';

interface Props {
  dragging: boolean;
  isUploading?: boolean;
  handleClick: (ev: any) => void;
  helpText: string;
}

const UploadTab: React.FC<Props> = (props) => {
  const { dragging, isUploading, handleClick, helpText } = props;
  return (
    <IPTab center dragging={dragging}>
      {isUploading ? (
        <Loading />
      ) : dragging ? (
        <div className="text-3xl">Drop file here</div>
      ) : (
        <>
          <Button label="Choose a File" handleClick={handleClick} />
          <small className={styles.info}>{helpText}</small>
        </>
      )}
    </IPTab>
  );
};

export default UploadTab;
