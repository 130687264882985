import { ErrorCodes } from 'shared/shared/constants';
import { Link, TextLink } from 'components/common';
import { URLContext } from 'components/RoutingProvider';
import { reverse } from 'router';
import React, { useContext } from 'react';

const WrongPassword = (): JSX.Element => {
  const { authModalStateMutatorSet } = useContext(URLContext);

  function handleSingleClickSignin() {
    authModalStateMutatorSet.closeModals();
  }

  // Open in new window if we're in an embed frame
  return (
    <span>
      Wrong password.{' '}
      <Link href={reverse('reset_password')} onClick={handleSingleClickSignin} newWindow={window.top !== window.parent}>
        Reset password?
      </Link>
    </span>
  );
};

const AccountAlreadyExists = ({ value }: { value: string }): JSX.Element => {
  const { authModalStateMutatorSet } = useContext(URLContext);

  function handleSingleClickSignin(e: any) {
    e.preventDefault();
    authModalStateMutatorSet.setAuthModalState({
      email: value,
      open: 'login',
    });
  }

  return (
    <span>
      An account already exists with this email address.{' '}
      <a href={reverse('account_login')} onClick={handleSingleClickSignin}>
        Sign in?{' '}
      </a>
    </span>
  );
};

const EmailAuthNotFound = ({ value }: { value: string }): JSX.Element => {
  const { authModalStateMutatorSet } = useContext(URLContext);
  const handleRegisterTextLink = () => {
    authModalStateMutatorSet.setAuthModalState({
      email: value,
      open: 'signup',
    });
  };

  return (
    <div>
      <div>
        No account found with this email address. <TextLink onClick={handleRegisterTextLink}>Sign up?</TextLink>
      </div>
    </div>
  );
};

const EmailInvalid = ({ value }: { value: string }): JSX.Element => {
  return <span>Email is not valid, please use a valid email address. </span>;
};

export const errorCodeHandlers = {
  promptForLoginWhenWrongPassword: {
    [ErrorCodes.EMAIL_AUTH_NO_PASSWORD]: WrongPassword,
  },
  promptForLoginWhenAccountAlreadyExists: {
    [ErrorCodes.EMAIL_AUTH_ALREADY_EXISTS]: AccountAlreadyExists,
  },
  promptForRegistrationWhenEmailAuthNotFound: {
    [ErrorCodes.EMAIL_AUTH_NOT_FOUND]: EmailAuthNotFound,
  },
  promptForRegistrationWhenEmailIsInvalid: {
    [ErrorCodes.EMAIL_INVALID]: EmailInvalid,
  },
};
