import { ChatMessage } from 'components/VirtualVenue/pubnub/pubnub';
import { VenueRole } from 'generated/globalTypes';
import EmojiReactionsDisplay from 'components/VirtualVenue/VirtualVenueDisplay/layouts/VirtualVenueDisplayLayout/Chat/ChatContent/ChatMessageDisplay/EmojiReactionsDisplay/EmojiReactionsDisplay';
import MessageMenu from 'components/VirtualVenue/VirtualVenueDisplay/layouts/VirtualVenueDisplayLayout/Chat/ChatContent/ChatMessageDisplay/MessageMenu/MessageMenu';
import React, { useRef } from 'react';
import classNames from 'classnames';
import linkifyStr from 'linkify-string';
import moment from 'moment';
import styles from './ChatMessageDisplay.module.scss';
interface Props {
  venueRole: VenueRole;
  message: ChatMessage;
  disableInteractions?: boolean;
  ignoreTheme?: boolean;
  showTimestamp?: boolean;
}

const ChatMessageDisplay = ({ venueRole, message, disableInteractions, ignoreTheme, showTimestamp }: Props) => {
  const { reactions = {} } = message.message;
  const canModerate = venueRole === VenueRole.OWNER || venueRole === VenueRole.MODERATOR;
  const ref = useRef<HTMLDivElement>(null);
  const hasReactions = Object.keys(reactions).length > 0;
  const timetokenInMs = parseInt(message.timetoken.slice(0, -4));
  const timestamp = moment(timetokenInMs);

  return (
    <div className={classNames(styles.ChatMessage, 'mt-3.5 relative')} ref={ref}>
      <div
        className="text-xs chat-panel-name"
        style={{
          color: ignoreTheme ? '#555555' : undefined,
        }}
      >
        {message.message.name}
        {showTimestamp && <span className="ml-2 text-xs text-gray-500">{timestamp.format(moment.HTML5_FMT.TIME)}</span>}
      </div>
      <div
        className="pr-4 text-sm"
        style={{ color: ignoreTheme ? '#333333' : 'var(--v-theme-chat-text-color)' }}
        dangerouslySetInnerHTML={{ __html: linkifyStr(message.message.text, { className: 'linkified' }) }}
      />
      {hasReactions && (
        <EmojiReactionsDisplay message={message} anchorEl={ref} disableInteractions={disableInteractions} />
      )}
      {!disableInteractions && (
        <MessageMenu
          message={message}
          anchorEl={ref}
          canModerate={canModerate}
          showOnHoverClassName={styles.ShowOnHover}
        />
      )}
    </div>
  );
};

export default ChatMessageDisplay;
