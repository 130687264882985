import * as React from 'react';

export type VirtualVenueContentType = 'call' | 'broadcast' | 'custom';

type VirtualVenueContentTypeProviderProps = {
  contentType?: VirtualVenueContentType;
  children: React.ReactNode;
};

const VirtualVenueContentTypeContext = React.createContext<VirtualVenueContentType | undefined>(undefined);

export const VirtualVenueContentTypeProvider = ({
  children,
  contentType = 'call',
}: VirtualVenueContentTypeProviderProps) => (
  <VirtualVenueContentTypeContext.Provider value={contentType}>{children}</VirtualVenueContentTypeContext.Provider>
);

export function useVirtualVenueContentType() {
  const context = React.useContext(VirtualVenueContentTypeContext);
  if (context === undefined) {
    throw new Error('useVirtualVenueContentType must be used within a VirtualVenueContentTypeProvider');
  }
  return context;
}
