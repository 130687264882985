import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Landing3 from 'components/pages/VirtualVenueLandingPage/LandingPage/Landing3';
import MinimalVenueLayout from 'components/layout/MinimalVenueLayout/MinimalVenueLayout';
import React from 'react';
import styles from './VirtualVenueLandingPage.module.scss';

const VirtualVenueLandingPage = () => {
  return (
    <MinimalVenueLayout title="Virtual Venue by Mixily">
      <div className={styles.VirtualVenueLandingPage}>
        <Landing3 />
      </div>
    </MinimalVenueLayout>
  );
};
export default VirtualVenueLandingPage;
