import { FormControlLabel, FormHelperText, Switch } from '@material-ui/core';
import React, { ChangeEvent } from 'react';
import classNames from 'classnames';
import styles from './OptionSwitch.module.scss';

interface Props {
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  fieldName?: string;
  title: string;
  helpText?: string;
  hidden?: boolean;
  readOnly?: boolean;
  styleName?: 'expanded';
  disabled?: boolean;
  id?: string;
}

const OptionSwitch = (props: Props) => {
  const { checked, onChange, fieldName, title, helpText, hidden, readOnly, styleName: style } = props;
  const cls = classNames(styles.OptionSwitch, style === 'expanded' && styles.expanded);
  return (
    <div className={hidden ? 'hidden' : ''}>
      <FormControlLabel
        className={cls}
        control={
          <Switch
            inputProps={{ id: props.id }}
            checked={checked}
            onChange={onChange}
            color="primary"
            value={fieldName}
            readOnly={readOnly}
            disabled={props.disabled}
          />
        }
        label={
          <div className={styles.switchLabel}>
            {title}
            {/* Use blank help text to preserve vertical alignment */}
            {helpText && <FormHelperText className={styles.helperText}>{helpText}</FormHelperText>}
          </div>
        }
      />
    </div>
  );
};

export default OptionSwitch;
