import { URLContext } from 'components/RoutingProvider';
import { analyticsAPI as analytics } from 'utils/api';
import { localStore } from 'utils/localstore';
import { useVirtualVenueEditContext } from 'components/pages/VirtualVenueEditPage/VirtualVenueEditContext';
import { useVirtualVenueTheme } from 'components/VirtualVenue/contexts/VirtualVenueThemeContext';
import Button2 from 'components/common/Button2/Button2';
import React, { useContext } from 'react';
import VirtualVenueThemeEditor from 'components/VirtualVenueThemeEditor/VirtualVenueThemeEditor';

const VirtualVenueTryout = () => {
  const { authModalStateMutatorSet } = useContext(URLContext);
  const { title: editedTitle, logo: editedLogo } = useVirtualVenueEditContext()!;
  const theme = useVirtualVenueTheme();

  const handleSubmit = () => {
    analytics.track('Virtual Venue Signup from Try Out');
    const demoVenue = {
      title: editedTitle,
      theme: {
        ...theme,
        logo: editedLogo,
      },
    };

    localStore.demoVirtualVenue.set(demoVenue);
    authModalStateMutatorSet.openSignupModal();
  };
  return (
    <div>
      <VirtualVenueThemeEditor />
      <div className="flex flex-row-reverse items-center justify-between mt-8 no-child-link-underline">
        <Button2 className="no-underline" onClick={handleSubmit}>
          Sign Up to Save
        </Button2>
      </div>
    </div>
  );
};

export default VirtualVenueTryout;
