import { Skeleton } from '@material-ui/lab';
import { VIRTUAL_VENUE_RECORDINGS_WITH_VENUE_QUERY } from 'utils/gql';
import {
  VirtualVenueRecordingsWithVenue,
  VirtualVenueRecordingsWithVenueVariables,
} from 'generated/VirtualVenueRecordingsWithVenue';
import { useQuery } from '@apollo/client';
import { useUserId } from 'utils/hooks/user/useUser';
import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import Error404Page from 'components/pages/Error404Page';
import Error500Page from 'components/pages/Error500Page';
import LoginRequired from 'components/LoginRequired';
import React from 'react';
import VirtualVenueRecordingList from 'components/pages/VirtualVenueRecordingsPage/VirtualVenueRecordingList';

interface Props {
  id: string;
}

const VirtualVenueRecordingsPage = ({ id }: Props) => {
  const userId = useUserId();

  const { loading, error, data } = useQuery<VirtualVenueRecordingsWithVenue, VirtualVenueRecordingsWithVenueVariables>(
    VIRTUAL_VENUE_RECORDINGS_WITH_VENUE_QUERY,
    {
      variables: { venueId: id, userId },
      skip: !userId,
    }
  );

  if (loading || (!loading && !error && !data)) {
    return (
      <BaseLayout title="Loading...">
        <Skeleton variant="rect" height={360} />
      </BaseLayout>
    );
  }

  if (error) {
    return <Error500Page />;
  }

  if (!data?.virtualVenue) {
    return <Error404Page />;
  }

  // Using the same logic used in `VirtualVeneuResolver` (backend)
  const roomName = data.virtualVenue.dailyCallUrl.split('/')[3]; // Hacky

  return (
    <LoginRequired>
      <BaseLayout title={`${data.virtualVenue.title || ''} Recordings`}>
        <VirtualVenueRecordingList roomName={roomName} venueId={id} recordings={data.virtualVenueRecordings} />
      </BaseLayout>
    </LoginRequired>
  );
};

export default VirtualVenueRecordingsPage;
