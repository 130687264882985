import { EventUpdatePageContext } from 'components/pages/EventUpdatePageContext';
import { LISTING_QUERY } from 'utils/gql';
import { Listing, ListingVariables } from 'generated/Listing';
import { Loading } from 'components/common';
import { RedirectToPath } from 'components/Redirect';
import { reverse } from 'shared/shared/routing/mixily-routes';
import { useQuery } from '@apollo/client';
import { useUserId } from 'utils/hooks/user/useUser';
import Error404Page from 'components/pages/Error404Page';
import Error500Page from 'components/pages/Error500Page';
import EventLayout from 'components/layout/EventLayout/EventLayout';
import ListingUpdateForm from 'components/pages/ListingEditPage/ListingUpdateForm';
import LoginRequired from 'components/LoginRequired';
import React, { useState } from 'react';
// import styles from './ListingEditPage.module.scss';

interface Props {
  id: string;
}

export type ThemePicCrop = {
  imageWidth?: number;
  imageHeight?: number;

  x: number;
  y: number;
  scale: number;
};

export type ThemePicState = {
  previewUrl: string;
  existingRemovedOnNextSave: boolean;
  editPosition: boolean;
  crop?: ThemePicCrop;
};

const ListingEditPage = ({ id }: Props) => {
  const [themePic, setThemePic] = useState<ThemePicState>({
    previewUrl: '',
    existingRemovedOnNextSave: false,
    editPosition: false,
  });
  const userId = useUserId();
  const { loading, data, error } = useQuery<Listing, ListingVariables>(LISTING_QUERY, { variables: { id } });
  const listing = data?.listing;

  if (loading) {
    return (
      <EventLayout title="Loading...">
        <Loading />
      </EventLayout>
    );
  }

  if (error) {
    return <Error500Page />;
  }

  if (!listing) {
    return <Error404Page />;
  }

  if (listing!.personId !== userId) {
    return <RedirectToPath path={reverse('listing_read', { id })} />;
  }

  return (
    <LoginRequired>
      <EventUpdatePageContext.Provider value={{ themePic, setThemePic }}>
        <EventLayout
          title={listing.title}
          themePicUrl={themePic.existingRemovedOnNextSave ? '' : themePic.previewUrl || listing.themePic}
          noDefaultPadding={true}
        >
          <ListingUpdateForm listing={listing} />
        </EventLayout>
      </EventUpdatePageContext.Provider>
    </LoginRequired>
  );
};

export default ListingEditPage;
