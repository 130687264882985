import { Person } from 'models';
import { STRIPE_OAUTH_PARAMS, STRIPE_OAUTH_URL } from 'utils/constants';
import { constructQueryString } from 'shared/shared/utils';
import { makeAbsoluteUrl } from 'shared';

export function getStripeOauthLink(user: Person): string {
  return createStripeOauthLink({
    state: `${user.id}`,
    'stripe_user[email]': user.getPrimaryEmail(),
    ...stripeFirstAndLastName(user.name),
  });
}

function createStripeOauthLink(params: Record<string, string> = {}): string {
  const qs = { redirect_uri: makeAbsoluteUrl('connect_account'), ...STRIPE_OAUTH_PARAMS, ...params };
  return `${STRIPE_OAUTH_URL}${constructQueryString(qs)}`;
}

function stripeFirstAndLastName(name?: string): Record<string, string> {
  const parts = name ? name.split(' ') : undefined;

  if (!parts) return {};
  else if (parts.length === 1) return { 'stripe_user[first_name]': parts[0] };
  else if (parts.length === 2) return { 'stripe_user[first_name]': parts[0], 'stripe_user[last_name]': parts[1] };
  else return { 'stripe_user[first_name]': parts[0], 'stripe_user[last_name]': parts.slice(1).join(' ') };
}
