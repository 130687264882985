import { InputSize } from 'components/common/Input/Input';
import { TextWidget, WidgetProps } from 'components/widgets';
import React, { Component } from 'react';
import styles from './MaxLengthTextWidget.module.scss';

interface State {
  changed: boolean;
}

interface Attrs {
  maxLength: number;
  size?: InputSize;
  placeholder?: string;
}

class MaxLengthTextWidget extends Component<WidgetProps<string, Attrs>, State> {
  private remainingCharacterWarnThreshold = 10;

  constructor(props: any) {
    super(props);
    this.state = {
      changed: false,
    };
  }

  handleChange = (value: string) => {
    if (!this.state.changed) {
      this.setState({
        changed: true,
      });
    }
    this.props.onChange(value);
  };

  render() {
    const { attrs, value } = this.props;
    if (!attrs) {
      throw new Error('MaxLengthTextWidget requires attrs.maxLength');
    }
    const { maxLength } = attrs;
    const charactersRemaining = maxLength - value.length;
    return (
      <React.Fragment>
        <TextWidget {...this.props} onChange={this.handleChange} attrs={{ ...attrs }} />
        {this.state.changed && charactersRemaining <= this.remainingCharacterWarnThreshold && (
          <div className={styles.charactersRemainingText}>{charactersRemaining} characters remaining...</div>
        )}
      </React.Fragment>
    );
  }
}

export default MaxLengthTextWidget;
