import * as F from 'shared/shared/Functional';
import * as h from 'utils/helpers';
import * as m from 'models';
import { CREATE_ORDER_MUTATION } from 'utils/gql';
import { Controller, useForm } from 'react-hook-form';
import { CreateOrder, CreateOrderVariables } from 'generated/CreateOrder';
import { DiscountCodeQuery_discountCode } from 'generated/DiscountCodeQuery';
import { EventWithExistQuery_event_eventsFromRun } from 'generated/EventWithExistQuery';
import { FormTicket } from 'models/ticket';
import { OrderMode, TicketOrderInput } from 'generated/globalTypes';
import { PriceType } from 'shared/shared/types';
import { analyticsAPI as analytics } from 'utils/api';
import { convertRunTicketToEventTicket } from 'models/run';
import { reverse } from 'router';
import { useMutation } from '@apollo/client';

import DisableInPreview from 'components/DisableInPreview/DisableInPreview';
import DiscountCodeForm from './DiscountCodeForm/DiscountCodeForm';
import FieldError from 'components/forms/FieldError';
import FieldLabel from 'components/common/FieldLabel/FieldLabel';
import FieldLayout from 'components/common/FieldLayout/FieldLayout';
import Input2 from 'components/common/Input2/Input2';
import LoadStripeAndRedirect from './LoadStripeAndRedirect/LoadStripeAndRedirect';
import OrderSummary from './OrderSummary/OrderSummary';
import PhoneInput from 'components/common/PhoneInput/PhoneInput';
import React, { useState } from 'react';
import RedirectWithMessage from 'components/RedirectWithMessage';
import RunCalendar from 'components/pages/EventReadPage/RsvpForm/TicketingForm/InnerTicketingForm/RunCalendar/RunCalendar';
import RunModal from 'components/pages/EventReadPage/RsvpForm/TicketingForm/InnerTicketingForm/RunModal/RunModal';
import TicketsWidget, { TicketOrder, TicketsValue } from './TicketsWidget/TicketsWidget';
import isEmail from 'validator/lib/isEmail';
import useEventClass from 'utils/hooks/event/useEventClass';
import useMutationFormHelpers from 'components/forms/useMutationFormHelpers';
import useUser from 'utils/hooks/user/useUser';

interface FormState {
  discountCode?: DiscountCodeQuery_discountCode;
  mode: OrderMode;
  orderId?: string;
  stripeCheckoutSessionId?: string;
}

function getInitialTicketValues(ticketTypes: m.TicketTypeForPurchase[]) {
  const singleTicketType = ticketTypes.length === 1;
  return ticketTypes.reduce((previous, t, index) => {
    const price = t.priceType === PriceType.fixed ? t.fixedPriceCents : t.suggestedDonationDefaultCents;
    const property = `ticket-${index}`;
    const position = t.position;
    return {
      ...previous,
      [property]: {
        id: t.id,
        quantity: singleTicketType ? 1 : 0,
        price,
        position,
      } as FormTicket,
    };
  }, {});
}

function getTickets(obj: object) {
  const isTicket = (key: any) => key.includes('ticket');
  const filteredTicketObj = F.objFilter(obj, isTicket);
  return F.objMap(filteredTicketObj, (k: any, value: any) => value);
}
const normalizeTickets = (tickets: (TicketOrderInput & { position: number })[]): TicketOrderInput[] =>
  tickets.map((t) => {
    const { position, ...rest } = t;
    return rest;
  });

type FormT = {
  tickets: TicketsValue;
  name: string;
  email: string;
  phone: string;
  eventDay: Date | null;
  selectedEvent: EventWithExistQuery_event_eventsFromRun | null;
};

const validateTicketOrder = (ticket: TicketOrder, ticketType: m.TicketTypeForPurchase) => {
  const minCents = ticketType.suggestedDonationMinCents!;
  const ticketRef = ticketType.name ? `${ticketType.name} Ticket: ` : '';

  if (ticket.quantity > 0 && ticket.price == null) {
    return `${ticketRef}Please enter a contribution amount`;
  }
  if (ticket.quantity > 0 && ticket.price! < minCents) {
    return `${ticketRef}Please enter a contribution higher than the minimum contribution`;
  }
  return true;
};

const InnerTicketingForm = () => {
  const { event } = useEventClass();
  const [state, setState] = useState<FormState>({ mode: OrderMode.EDITING });
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState<Date | null>(null);

  const ticketTypes: m.TicketTypeForPurchase[] = event.ticketTypes!.slice().sort(h.sortByPosition);
  const formContext = useForm<FormT>({
    defaultValues: {
      ...getInitialTicketValues(ticketTypes),
      name: '',
      email: '',
      phone: '',
      eventDay: null,
      selectedEvent: null,
    },
  });
  const [doOrderCreate] = useMutation<CreateOrder, CreateOrderVariables>(CREATE_ORDER_MUTATION);
  const {
    Form,
    SubmitButton,
    FormLevelMessages,
    isSubmitting,
    FormStateContextProvider,
    onSubmit,
  } = useMutationFormHelpers<FormT, CreateOrder, CreateOrderVariables>({
    formContext,
    formToVariables: (formData) => {
      const formTickets = getTickets(formData);
      const tickets = event.isRunTemplate
        ? convertRunTicketToEventTicket(formTickets, formData.selectedEvent)
        : normalizeTickets(formTickets);
      return {
        ...formData,
        tickets,
        discounts: state.discountCode && [{ id: state.discountCode.id, amount: discount }],
      } as CreateOrderVariables;
    },
    mutation: doOrderCreate,
    resultKey: 'orderCreate',
    onSuccess: ({ orderCreate }) => {
      analytics.track('CreateOrder');
      const mode = orderCreate.mode!;
      const isFree = mode === OrderMode.FREE_CREATED;
      const stripeCheckoutSessionId = isFree ? undefined : orderCreate.stripeCheckoutSessionId!;
      const orderId = isFree ? orderCreate.orderId! : undefined!;
      setState((state) => ({
        ...state,
        mode,
        stripeCheckoutSessionId,
        orderId,
      }));
    },
  });

  const { user } = useUser();
  const isAuthenticated = m.isAuthenticated(user);

  if (state.mode === OrderMode.FREE_CREATED) {
    const path = reverse('order_read', {
      id: state.orderId ?? '',
    });
    return <RedirectWithMessage path={path} message="Order complete!" />;
  } else if (state.mode === OrderMode.FREE_PAID_CREATED || state.mode === OrderMode.PAID_CREATED) {
    return <LoadStripeAndRedirect stripeCheckoutSessionId={state.stripeCheckoutSessionId ?? ''} />;
  }

  const { watch, register, errors, control, setValue } = formContext;

  const tickets = getTickets(watch());
  const totalQuantity = F.sum(tickets.map(F.prop('quantity')));
  const discount = h.calculateTotalDiscount(tickets, state.discountCode);
  const lineTotals = tickets.map((t) => (t.price || 0) * t.quantity);
  const totalBeforeDiscount = F.sum(lineTotals);
  const paidOrder = totalBeforeDiscount - discount > 0;
  const shouldHideAdditionalFields = totalQuantity === 0 || isAuthenticated || paidOrder;
  const purchaseableEventsFromRun = event.eventsFromRun ? event.eventsFromRun.filter((e) => !e.isPast) : [];

  return (
    <div>
      <FormStateContextProvider onSubmit={onSubmit} formContext={formContext} isSubmitting={isSubmitting}>
        <Form>
          {!!event.isRunTemplate && (
            <Controller
              as={RunCalendar}
              name="eventDay"
              control={control}
              eventsFromRun={purchaseableEventsFromRun}
              value={selectedDay}
              rules={{
                validate: (value) => (!value ? 'Select a date' : true),
              }}
              onChange={([e]) => {
                setIsOpen(true);
                setSelectedDay(e);
                return e;
              }}
            />
          )}
          {event.isRunTemplate && <FieldError error={errors.eventDay} />}
          {event.isRunTemplate && (
            <div className="mt-4">
              <Controller
                as={RunModal}
                control={control}
                name="selectedEvent"
                eventsFromRun={purchaseableEventsFromRun}
                templateEvent={event}
                selectedDay={selectedDay}
                setIsOpen={setIsOpen}
                isOpen={isOpen}
                onChange={([event]) => {
                  setIsOpen(false);
                  const eventDay = event?.startTime ? new Date(event.startTime as any) : null;
                  setSelectedDay(eventDay);
                  setValue('eventDay', eventDay);
                  return event;
                }}
                rules={{
                  validate: (value) => (!value ? 'Select a showtime' : true),
                }}
              />
            </div>
          )}
          {event.isRunTemplate && <FieldError error={errors.selectedEvent} />}
          {event.isRunTemplate && <div className="mt-12 text-2xl">Tickets</div>}
          <TicketsWidget<FormT>
            attrs={{ ticketTypes, control, watch, validate: validateTicketOrder, errors }}
            disabled={isSubmitting}
          />
          <FieldLayout label="Name" className={shouldHideAdditionalFields ? 'hidden' : 'mt-8'} error={errors.name}>
            <Input2
              name="name"
              size="lg"
              disabled={isSubmitting}
              ref={register({
                validate: (val) => (!shouldHideAdditionalFields && !val ? 'This field is required' : true),
              })}
              placeholder="Your Name"
            />
          </FieldLayout>
          <FieldLayout
            label="Email address"
            className={shouldHideAdditionalFields ? 'hidden' : 'mt-8'}
            error={errors.email}
          >
            <Input2
              name="email"
              type="email"
              size="lg"
              disabled={isSubmitting}
              ref={register({
                validate: (val) => {
                  if (shouldHideAdditionalFields) {
                    return true;
                  }
                  if (!val) {
                    return 'This field is required';
                  }
                  if (!isEmail(val)) {
                    return 'Please enter a valid email address';
                  }
                  return true;
                },
              })}
              placeholder="you@example.com"
            />
          </FieldLayout>
          {/* Can't use FieldLayout here -- see FIXME at the top of PhoneInput */}
          {event.captureGuestPhone && totalQuantity && (
            <div className={event.captureGuestPhone && totalQuantity ? 'mt-8' : 'hidden'}>
              <FieldLabel>Phone number</FieldLabel>
              <div className="mt-1">
                <Controller
                  as={PhoneInput}
                  name="phone"
                  control={control}
                  layoutProps={{
                    size: 'lg',
                  }}
                  disabled={isSubmitting}
                  rules={{
                    validate: (value) => (event.captureGuestPhone && !value ? 'This field is required' : true),
                  }}
                  onChangeName="onValueChange"
                  onChange={([e]) => e.value ?? ''}
                />
              </div>
              <FieldError error={errors.phone} />
            </div>
          )}
          <FormLevelMessages className="mt-6" />
          {/* Intentionally leaving 'submit' enabled when `!hasAllPrices` because it looks friendlier */}
          <div className="mt-4">
            <DisableInPreview>
              <SubmitButton
                label={event.checkoutButtonLabel || 'Checkout'}
                submittingLabel="Redirecting..."
                className="w-full sm:w-full"
                disabled={isSubmitting || !totalQuantity}
              />
            </DisableInPreview>
          </div>
        </Form>
      </FormStateContextProvider>

      <OrderSummary
        tickets={tickets}
        ticketTypes={ticketTypes}
        discountCode={state.discountCode}
        currency={event.currency}
      />
      {event.showDiscountCodeInput && (
        <DiscountCodeForm
          className="pb-1 mt-5"
          discountCode={state.discountCode}
          setDiscountCode={(discountCode: DiscountCodeQuery_discountCode) => setState({ ...state, discountCode })}
        />
      )}
    </div>
  );
};

export default InnerTicketingForm;
