import React from 'react';

import styles from './EventThemePic.module.scss';

import { ThemePicCrop } from 'components/pages/EventUpdatePage';

interface Props {
  imageUrl: string;
  crop?: ThemePicCrop;
  style?: object;
}

const EventThemePic: React.FC<Props> = ({ imageUrl, crop, style }) => {
  return (
    <div
      className={styles.themePic}
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundPositionX: crop ? `${crop.x * 100}%` : 'center',
        backgroundPositionY: crop ? `${crop.y * 100}%` : 'center',
        backgroundSize: crop && crop.scale ? `${crop.scale * 100}%` : 'cover',
        ...style,
      }}
    />
  );
};

export default EventThemePic;
