import { DateTime } from 'components/widgets/time-types';
import { Poll, PollOption, appendNewPollOption } from 'models/poll';
import { randStr } from 'shared/shared/utils';
import AddIcon from '@material-ui/icons/Add';
import MuiButton from '@material-ui/core/Button';
import PollEditOption from 'components/EventCreateUpdateForm/WhenWidget/PollEditOption';
import React, { useCallback, useEffect } from 'react';
import TimeTitle from 'components/EventCreateUpdateForm/WhenWidget/TimeTitle';
import moment from 'moment';
import styles from './PollEdit.module.scss';

interface Props {
  poll: Poll;
  onChange: (poll: Poll) => void;
  onSetOption: (option: PollOption) => void;
}

const PollEdit = (props: Props) => {
  const { poll, onChange, onSetOption } = props;
  const { options } = poll;

  const addOption = useCallback(() => {
    onChange({ ...poll, options: appendNewPollOption(options) });
  }, [onChange, options, poll]);

  useEffect(() => {
    if (!options.length) {
      addOption();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteOption = (index: number) => {
    return () => {
      onChange({
        ...poll,
        options: options.slice(0, index).concat(options.slice(index + 1)),
      });
    };
  };

  const handleChangeOption = (index: number) => (value: DateTime) => {
    const newOptions = [...options];
    newOptions[index].text = moment(value).toISOString().trim();
    onChange({ ...poll, options: newOptions });
  };

  const canDelete = options.length > 1;

  return (
    <div className={styles.PollEdit}>
      <TimeTitle label="Options" />
      <div>
        {options.map((option: PollOption, index: number) => (
          <PollEditOption
            key={option.id || randStr()}
            option={option}
            canDelete={canDelete}
            onChange={handleChangeOption(index)}
            onDelete={handleDeleteOption(index)}
            onSet={() => onSetOption(option)}
          />
        ))}
      </div>
      <MuiButton
        variant="text"
        color="primary"
        startIcon={<AddIcon />}
        onClick={addOption}
        className={styles.addButton}
      >
        <span className={styles.label}>Add Option</span>
      </MuiButton>
    </div>
  );
};

export default PollEdit;
