import * as F from 'shared/shared/Functional';
import {
  MediaActiveStream,
  MediaDeviceConfig,
} from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/contexts/DeviceConfigContext';
import { deviceKinds } from 'components/VirtualVenue/constants';
import { useMemo } from 'react';
import usePromise from 'utils/hooks/usePromise';

const useDefaultMedia = (
  mediaDevices: MediaDeviceConfig | null,
  activeStream: MediaActiveStream | undefined,
  nonce: number | undefined
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getDevices = useMemo(() => navigator.mediaDevices.enumerateDevices(), [nonce]);
  const { error, data: availableDevices } = usePromise(getDevices);

  const defaultDevices: Partial<MediaDeviceConfig> = useMemo(() => {
    // First selection, no local storage: use active stream
    if (!mediaDevices) {
      const audioinput = availableDevices?.filter((d) => d.label === activeStream?.audioinput)?.[0]?.deviceId;
      const videoinput = availableDevices?.filter((d) => d.label === activeStream?.videoinput)?.[0]?.deviceId;
      return {
        audioinput,
        videoinput,
      };
    }

    let result: Partial<MediaDeviceConfig> = {};
    deviceKinds.forEach((deviceKind) => {
      const desiredDevice = mediaDevices?.[deviceKind];
      const desiredDeviceAvailable =
        (availableDevices?.length ?? 0) === 0 || availableDevices?.find(F.propEq('deviceId', desiredDevice));
      if (desiredDevice && desiredDeviceAvailable) {
        result[deviceKind] = desiredDevice;
      } else {
        const validDevices = availableDevices?.filter(F.propEq('kind', deviceKind));
        if (validDevices?.[0]?.deviceId) {
          result[deviceKind] = validDevices[0].deviceId;
        }
      }
    });
    return result;
  }, [mediaDevices, availableDevices, activeStream?.audioinput, activeStream?.videoinput]);

  return { error, defaultDevices, availableDevices };
};

export default useDefaultMedia;
