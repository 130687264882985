import { Button } from '@material-ui/core';
import { GuestFilter } from 'components/EventGuestList/types';
import {
  Invite,
  InviteCountsByStateT,
  InvitesByStateT,
  sortInvitesHostsFirst,
  sortedInvitesForRsvpState,
} from 'models/invite';
import { RsvpStates } from 'generated/globalTypes';
import { renderInviteListItem } from 'components/EventGuestList/GuestList';
import Dialog from 'components/common/Dialog/Dialog';
import React, { useState } from 'react';
import classNames from 'classnames';
import styles from 'components/EventGuestList/GuestListModal.module.scss';
import useEventClass from 'utils/hooks/event/useEventClass';

interface Props {
  invitesByState: InvitesByStateT; // Object with invites by state (yes, no, invited, etc)
  countsByState: InviteCountsByStateT; // Object with invites by state (yes, no, invited, etc)
  invites: Invite[];
  canDeleteInvites: boolean;
  open: boolean;
  setOpen: Function;
}

const GuestListModal = (props: Props) => {
  const { invitesByState, countsByState, canDeleteInvites, open, setOpen }: any = props;
  const { event } = useEventClass();

  const [filter, setFilter] = useState<string>(GuestFilter.Going);
  const renderInvites = (inviteList: Invite[]) =>
    sortedInvitesForRsvpState(inviteList)
      .sort(sortInvitesHostsFirst(event))
      .map((invite: Invite) => renderInviteListItem(invite, canDeleteInvites, event, styles.Popup));

  const counts: any = {
    [RsvpStates.y]: countsByState[RsvpStates.y],
    [RsvpStates.m]: countsByState[RsvpStates.m],
    [RsvpStates.i]: countsByState[RsvpStates.i] + countsByState[RsvpStates.s],
    [RsvpStates.n]: countsByState[RsvpStates.n],
  };

  interface IFilterOption {
    label: string;
    filter: RsvpStates;
  }

  const options: IFilterOption[] = [
    { label: event && event.isPast ? 'Went' : 'Going', filter: RsvpStates.y },
    { label: 'Maybe', filter: RsvpStates.m },
    { label: 'Invited', filter: RsvpStates.i },
    { label: 'No', filter: RsvpStates.n },
  ];

  const inviteResponses: any = {
    [RsvpStates.y]: renderInvites(invitesByState[RsvpStates.y]),
    [RsvpStates.m]: renderInvites(invitesByState[RsvpStates.m]),
    [RsvpStates.i]: renderInvites(invitesByState[RsvpStates.i].concat(invitesByState[RsvpStates.s])),
    [RsvpStates.n]: renderInvites(invitesByState[RsvpStates.n]),
  };

  return (
    <Dialog title="Guest List" isOpen={open} onClose={() => setOpen(false)}>
      <div className="mt-1">
        <div className={styles.buttons}>
          {options.map((option) => (
            <Button
              key={option.label}
              className={classNames({ [styles.active]: filter === option.filter }, styles.button)}
              variant="text"
              onClick={() => setFilter(option.filter)}
            >
              <span className={styles.label}>
                {option.label} ({counts[option.filter]})
              </span>
            </Button>
          ))}
        </div>
      </div>
      <div className={styles.GuestListContainer}>
        <div className={styles.GuestList}>
          {inviteResponses[filter].length > 0 ? inviteResponses[filter] : <p className={styles.NullState}>No Guests</p>}
        </div>
        <div className={styles.Fader} />
      </div>
    </Dialog>
  );
};

export default GuestListModal;
