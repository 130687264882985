import Button2 from 'components/common/Button2/Button2';
import IconSvg from 'components/common/IconSvg/IconSvg';
import React, { CSSProperties } from 'react';
import classNames from 'classnames';

interface Props {
  onClick?: () => void;
  className?: string;
  style?: CSSProperties;
  buttonStyle?: 'circle' | 'rectangle';
  direction: 'left' | 'right';
}

const PaginationButton = ({ onClick, className, style, direction, buttonStyle = 'rectangle' }: Props) => {
  return (
    <div
      className={classNames(
        'absolute overflow-hidden z-10 flex justify-center',
        className,
        {
          'h-full w-4': buttonStyle === 'rectangle',
          'h-8 w-8': buttonStyle === 'circle',
        },
        `${direction}-0 -m${direction.charAt(0)}-${buttonStyle === 'rectangle' ? 4 : 2} `
      )}
      style={style}
    >
      <Button2
        size="0"
        className={classNames('absolute h-full py-1 opacity-30 hover:opacity-70', {
          'rounded-full px-1 w-full': buttonStyle === 'circle',
        })}
        onClick={onClick}
      >
        <IconSvg
          name={direction === 'right' ? 'chevronRight' : 'chevronLeft'}
          className={buttonStyle === 'rectangle' ? 'w-5' : 'w-6'}
        />
      </Button2>
    </div>
  );
};

export default PaginationButton;
