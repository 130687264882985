import { ApolloError } from '@apollo/client';
import { Event, parseEvent } from 'models';
import { useMemo } from 'react';
import useEvent from 'utils/hooks/event/useEvent';

interface UseEventClassResult {
  event?: Event;
  eventExists?: boolean;
  loading: boolean;
  isDemo: boolean;
  error?: ApolloError;
}
interface UseEventClassResultRequired extends UseEventClassResult {
  event: Event;
}

interface EventClassOptions {
  password?: string;
  required: boolean;
  shouldSkip?: boolean;
}

function useEventClass(options?: { required: true; shouldSkip?: boolean }): UseEventClassResultRequired;
function useEventClass(options?: { required: false; shouldSkip?: boolean }): UseEventClassResult;
function useEventClass(options: EventClassOptions = { required: true, shouldSkip: false }): UseEventClassResult {
  const { event: eventData, loading, error, eventExists, isDemo } = useEvent(!!options.shouldSkip);

  if (options.required && !eventData) throw new Error('Event required but not found');

  const event = useMemo(() => (eventData ? parseEvent(eventData) : undefined), [eventData]);

  return { event, loading, error, eventExists, isDemo };
}

export default useEventClass;
