const colors = {
  bodyBg: '#fdfcf9',
  redBg: '#fff6f6',
  red: '#fb4949',
  black: '#393939',
  orange: '#ff8653',
  grayd: '#d0d0d0',
};

export default colors;
