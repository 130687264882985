// TODO: DRY with SASS somehow
// Also coupled with MuiTheme.ts

module.exports = {
  screens: {
    xs: '480px',
    sm: '600px',
    md: '768px',
    lg: '1024px',
    xl: '1224px',
    '2xl': '1526px',
  },
};
