import { RichTextType } from 'utils/prismic/types';
import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import Error500Page from 'components/pages/Error500Page';
import FeeCalculator from 'components/FeeCalculator/FeeCalculator';
import Prismic from 'prismic-javascript';
import React from 'react';
import RichText from 'utils/prismic/RichText';
import usePrismic from 'utils/prismic/usePrismic';

interface Props {}

interface PricingPageContent {
  content: RichTextType;
  content_below_calculator: any;
  show_calculator_: any;
}

const query = Prismic.Predicates.at('document.type', 'pricing_page');

const SiteFeeCalculator = (props: Props) => {
  const { data, error, loading } = usePrismic<PricingPageContent>(query);
  console.log(data, error, loading);
  if (error) {
    return <Error500Page />;
  }
  const {
    content: contentAbove = null,
    content_below_calculator: contentBelow = null,
    show_calculator_: showCalculator = null,
  } = data?.[0].data || {};
  return (
    <BaseLayout title="Pricing" hideTitle={true}>
      <div className="max-w-3xl">{!!contentAbove && <RichText content={contentAbove} />}</div>
      {showCalculator && (
        <div className="max-w-3xl mx-left">
          <div className="my-12 h4-responsive">Calculator</div>
          <div className="p-4 bg-white border border-gray-300 border-solid shadow-md sm:p-8 md:p-10">
            <FeeCalculator />
          </div>
        </div>
      )}
      <div className="max-w-3xl">{!!contentBelow && <RichText content={contentBelow} />}</div>
    </BaseLayout>
  );
};

export default SiteFeeCalculator;
