import * as F from 'shared/shared/Functional';
import { AllFaqDocuments, FaqData, SectionData } from 'components/pages/FaqPage/types';
import { FaqDocument, FaqQuestionDocument, FaqResult, FaqSectionDocument } from './types';
import Prismic from 'prismic-javascript';
import usePrismic from 'utils/prismic/usePrismic';

const buildFaqSection = (
  sectionId: string,
  sections: FaqSectionDocument[],
  questions: FaqQuestionDocument[]
): SectionData => {
  const section = F.find(F.propEq('id', sectionId), sections);
  const { section_title, questions: questionData } = section.data;
  const questionIds: string[] = questionData.map((q) => q.question.id);

  return {
    title: section_title[0].text,
    questions: questionIds.map((id) => {
      const q = F.find(F.propEq('id', id), questions);
      return {
        question: q.data.question[0].text,
        answer: q.data.answer,
      };
    }),
  };
};

const getPrismicFaqData = (documents: AllFaqDocuments[]): FaqData | null => {
  const byType = F.groupBy(documents, F.prop('type'));
  const faq = byType.faq[0] as FaqDocument;
  const sectionIds: string[] = faq.data.sections.map((s) => s.section.id);
  const sections = byType.faq_section as FaqSectionDocument[];
  const questions = byType.faqquestion as FaqQuestionDocument[];
  return sectionIds.map((id) => buildFaqSection(id, sections, questions));
};

const prismicQuery = Prismic.Predicates.any('document.type', ['faq', 'faq_section', 'faqquestion']);
const options = { pageSize: 100 };

const usePrismicFaq = () => {
  const { data, loading, error } = usePrismic<FaqResult>(prismicQuery, options);
  if (!data) {
    return { data, loading, error };
  }
  return {
    data: getPrismicFaqData(data as AllFaqDocuments[]),
    loading,
    error,
  };
};

export default usePrismicFaq;
