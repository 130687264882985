import { DateTime } from 'components/widgets/time-types';
import { PollOption } from 'models/poll';
import { onChangeT } from 'components/widgets';
import { pluralize } from 'utils/helpers';
import CloseIcon from '@material-ui/icons/Close';
import DateTimeWidget from 'components/widgets/DateTimeWidget';
import Pill from 'components/common/Pill';
import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import styles from './PollEditOption.module.scss';

interface Props {
  option: PollOption;
  canDelete: boolean;
  onChange: onChangeT<DateTime>;
  onDelete: () => void;
  onSet: () => void;
}

const PollEditOption: React.FC<Props> = (props) => {
  const { option, canDelete, onChange, onDelete, onSet } = props;
  const numVotes = option.votes?.length || 0;
  return (
    <div className={styles.PollEditOption}>
      <div className={styles.top}>
        <DateTimeWidget
          value={moment(option.text).toObject()}
          onChange={onChange}
          disabled={!!option.id}
          attrs={
            numVotes
              ? {
                  timePill: {
                    content: pluralize('vote', numVotes, true),
                  },
                }
              : undefined
          }
        />
        {canDelete && <CloseIcon onClick={onDelete} className={classNames(styles.smallButton, styles.icon)} />}
      </div>
      {!!option.votes?.length && (
        <div className={styles.bottom}>
          <Pill content="Set as start time" onClick={onSet} />
        </div>
      )}
    </div>
  );
};

export default PollEditOption;
