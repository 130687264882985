import { DiscountCode } from './types';
import { DiscountKind } from 'generated/globalTypes';
import { WidgetProps } from 'components/widgets';
import AddButton from 'components/common/AddButton/AddButton';
import DiscountCodeWidget from './DiscountCodeWidget/DiscountCodeWidget';
import React, { useCallback } from 'react';

const makeDefaultCode = (): DiscountCode => ({
  code: '',
  type: DiscountKind.percent,
  isActive: true,
  quantityClaimed: 0,
});

const DiscountCodesWidget: React.FC<WidgetProps<DiscountCode[]>> = (props) => {
  const { value, disabled, onChange } = props;

  const addOne = useCallback(() => {
    value.push(makeDefaultCode());
    onChange(value);
  }, [value, onChange]);

  const onChildChange = useCallback(
    (index: number, newCode: DiscountCode) => {
      value[index] = newCode;
      onChange(value);
    },
    [onChange, value]
  );

  const onDelete = useCallback(
    (index: number) => {
      value.splice(index, 1);
      onChange(value);
    },
    [onChange, value]
  );

  return (
    <div className="vstack-small">
      {value.map((discountCode, index) => (
        <DiscountCodeWidget
          key={`${discountCode.id}-${index}`}
          value={discountCode}
          disabled={disabled}
          onChange={(newCode) => onChildChange(index, newCode)}
          attrs={{ onDelete: () => onDelete(index) }}
        />
      ))}
      <AddButton onClick={addOne}>{value.length ? 'Add another promo code' : 'Add a promo code'}</AddButton>
    </div>
  );
};

export default DiscountCodesWidget;
