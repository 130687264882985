import { URLContext } from 'components/RoutingProvider';
import React, { CSSProperties, useContext } from 'react';

// Based on https://hackernoon.com/routing-in-react-the-uncomplicated-way-b2c5ffaee997

export interface LinkProps {
  href: string;
  className?: string;
  onClick?: (e: React.MouseEvent<any>) => void;
  download?: boolean;
  children: React.ReactNode;
  newWindow?: boolean;
  style?: CSSProperties;
}

const newWindowProps = {
  target: '_blank',
  rel: 'noopener noreferrer',
};

const Link = (props: LinkProps) => {
  const context = useContext(URLContext);
  const { href, children, download, className, newWindow = false } = props;

  const onClick = (e: React.MouseEvent<any>) => {
    const aNewTab = e.metaKey || e.ctrlKey;
    const anExternalLink = href.startsWith('http') || href.startsWith('mailto:');
    if (!aNewTab && !anExternalLink && !download && !newWindow) {
      e.preventDefault();
      context.setPath(href);
    }
    props.onClick && props.onClick(e);
  };

  return (
    <a
      href={href}
      {...(newWindow ? newWindowProps : {})}
      onClick={onClick}
      download={download}
      className={className}
      style={props.style}
    >
      {children}
    </a>
  );
};

export default Link;
