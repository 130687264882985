import './SelectInviteesWidget.scss';
import { OptionProps, components } from 'react-select';
import { SelectOption } from './types';
import EventIcon from '@material-ui/icons/Event';
import PeopleIcon from '@material-ui/icons/PeopleAlt';
import React from 'react';
import classNames from 'classnames';

const CustomOption = (props: OptionProps<SelectOption, boolean>) => {
  const { innerProps, innerRef, data } = props;
  return (
    <div className={classNames('mx-custom-option', { 'mx-focused': props.isFocused })} {...innerProps} ref={innerRef}>
      {data.typename === 'ContactListType' && (
        <span className="mx-contact-list-icon">
          <PeopleIcon fontSize="inherit" />
        </span>
      )}
      {data.typename === 'EventType' && (
        <span className="mx-contact-list-icon">
          <EventIcon fontSize="inherit" />
        </span>
      )}
      <components.Option className={'mx-option'} {...props} />
    </div>
  );
};

export default CustomOption;
