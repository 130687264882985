// Based on https://usehooks.com/usePrevious/
// Gets the previous value of a props/state/anything variable
// E.g.   const prevCount = usePrevious(count);

import { useEffect, useRef } from 'react';

const usePrevious = <T>(value: T) => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T>();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

export default usePrevious;
