import { VIRTUAL_VENUES_BY_OWNER_QUERY } from 'utils/gql';
import { VirtualVenuesByOwner, VirtualVenuesByOwnerVariables } from 'generated/VirtualVenuesByOwner';
import { useQuery } from '@apollo/client';
import useSubscriptionFeatures from 'utils/hooks/subscriptions/useSubscriptionFeatures';

interface UseCanCreateVenueResult {
  canCreateVenue: boolean | undefined;
  loading: boolean;
  refetchVenuesByUser: () => void;
}
const useCanCreateVenue = (latest = false): UseCanCreateVenueResult => {
  const { loading: loadingUser, features, userId } = useSubscriptionFeatures(latest);
  const { loading, error, refetch: refetchVenuesByUser, data } = useQuery<
    VirtualVenuesByOwner,
    VirtualVenuesByOwnerVariables
  >(VIRTUAL_VENUES_BY_OWNER_QUERY, {
    variables: { ownerId: userId || '' },
    skip: loadingUser,
    fetchPolicy: latest ? 'network-only' : 'cache-first',
  });
  if (loading || loadingUser) {
    return {
      loading: true,
      canCreateVenue: undefined,
      refetchVenuesByUser,
    };
  }
  if (error) {
    throw new Error(`Unable to load user venues for [${userId}]: ${error}`);
  }
  const maxVenues = features?.maxVenues ?? 1;
  const venueCount = data?.virtualVenuesByOwner?.length ?? 1;
  return {
    loading: false,
    canCreateVenue: venueCount < maxVenues,
    refetchVenuesByUser,
  };
};

export default useCanCreateVenue;
