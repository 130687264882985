import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const HelpText = (props: Props) => {
  return <div className="mt-1 text-xs text-cool-gray-400">{props.children}</div>;
};

export default HelpText;
