import { VERIFY_EVENT_WITH_PASSWORD_MUTATION } from 'utils/gql';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';

import {
  VerifyEventWithPasswordMutation,
  VerifyEventWithPasswordMutationVariables,
} from 'generated/VerifyEventWithPasswordMutation';
import FieldLayout from 'components/common/FieldLayout/FieldLayout';
import Input2 from 'components/common/Input2/Input2';
import React, { useEffect, useState } from 'react';
import useGlobalState from 'components/App/globalstate/useGlobalState';
import useMutationFormHelpers from 'components/forms/useMutationFormHelpers';

type FormT = {
  password: string;
};

const defaultValues = {
  password: '',
};

const EventPasswordForm = ({ eventId, refetch }: { eventId: string; refetch: Function }) => {
  const [doUpdate] = useMutation<VerifyEventWithPasswordMutation, VerifyEventWithPasswordMutationVariables>(
    VERIFY_EVENT_WITH_PASSWORD_MUTATION
  );
  const [finalPassword, setFinalPassword] = useState<string | undefined>(undefined);
  const formContext = useForm<FormT>({ defaultValues });
  const { setPasswordEvent } = useGlobalState();

  useEffect(() => {
    if (finalPassword) {
      setPasswordEvent(finalPassword);
    }
    return () => setFinalPassword(undefined);
  }, [finalPassword, setPasswordEvent]);

  //useSetPasswordEvent(formContext.getValues('password'));
  const formHelpers = useMutationFormHelpers<
    FormT,
    VerifyEventWithPasswordMutation,
    VerifyEventWithPasswordMutationVariables
  >({
    formContext,
    formToVariables: (formData) => ({
      ...formData,
      id: eventId,
    }),
    mutation: doUpdate,
    resultKey: 'verifyEventWithPassword',
    successMessage: 'Password is correct!',
    onSuccess: () => {
      setFinalPassword(formContext.getValues('password'));
      refetch({ id: eventId, password: formContext.getValues('password') });
    },
  });
  const { FormStateContextProvider, Form, SubmitButton, FormLevelMessages, isSubmitting, onSubmit } = formHelpers;
  const { register, errors } = formContext;

  return (
    <FormStateContextProvider onSubmit={onSubmit} isSubmitting={isSubmitting} formContext={formContext}>
      <Form>
        <div className="flex max-w-2xl">
          <FieldLayout label="Password" error={errors.password} className="w-full">
            <Input2
              name="password"
              type="password"
              ref={register({ required: true })}
              disabled={isSubmitting}
              placeholder="Type in password event"
              size="lg"
            />
          </FieldLayout>
          <SubmitButton label="Click To Join" submittingLabel="Joining..." size="14" className="w-auto mt-5 ml-2" />
        </div>
        <FormLevelMessages className="inline-block mt-4" />
      </Form>
    </FormStateContextProvider>
  );
};

export default EventPasswordForm;
