import { Link, Loading } from 'components/common';
import { Unauthenticated, Unknown } from 'models';
import { getURLParams } from 'utils/urls';
import { reverse } from 'router';
import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import React from 'react';
import useConnectZoomAccount from 'utils/hooks/useConnectZoomAccount';
import useUser from 'utils/hooks/user/useUser';

const params = getURLParams(window.location.href);
const { code, error } = params;

export const ZoomResponsePage: React.FC = () => {
  const loading = useConnectZoomAccount(code);
  const { user } = useUser();

  if (user === Unknown) {
    return null;
  }

  if (user === Unauthenticated || !code || error) {
    return (
      <BaseLayout title="">
        code: {code}
        <br />
        Something went wrong connecting to your Zoom account. Please return to{' '}
        <Link href={reverse('account_settings')}>account settings</Link> and try again.
      </BaseLayout>
    );
  }

  if (loading) {
    return (
      <BaseLayout title="">
        <Loading />
      </BaseLayout>
    );
  } else {
    window.close();
    return null;
  }
};

export default ZoomResponsePage;
