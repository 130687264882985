import * as F from 'shared/shared/Functional';
import {
  MediaDeviceConfig,
  useDeviceConfig,
  useDeviceConfigDispatch,
} from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/contexts/DeviceConfigContext';
import { Skeleton } from '@material-ui/lab';
import { localStore } from 'utils/localstore';
import { useForm } from 'react-hook-form';
import Bowser from 'bowser';
import Button2 from 'components/common/Button2/Button2';
import CameraPreview from 'components/VirtualVenue/VirtualVenueDisplay/layouts/VirtualVenueDisplayLayout/Tray/TraySettingsMenu/DevicesForm/CameraPreview';
import FieldLayout from 'components/common/FieldLayout/FieldLayout';
import Link from 'components/Link';
import React, { useEffect, useReducer } from 'react';
import VolumeMeter from 'components/VirtualVenue/VirtualVenueDisplay/layouts/VirtualVenueDisplayLayout/Tray/TraySettingsMenu/DevicesForm/VolumeMeter';
import config from 'config';
import useDefaultMedia from 'utils/hooks/useDefaultMedia';

// TODO: Initialize form from current daily state

type FormT = MediaDeviceConfig;

const isAudioInput: (device: MediaDeviceInfo) => boolean = F.propEq('kind', 'audioinput');
const isVideoInput: (device: MediaDeviceInfo) => boolean = F.propEq('kind', 'videoinput');
const isAudioOutput: (device: MediaDeviceInfo) => boolean = F.propEq('kind', 'audiooutput');

const DevicesForm = () => {
  const [nonce, refreshDevices] = useReducer((i) => i + 1, 0);
  const deviceConfig = useDeviceConfig();
  const { error, defaultDevices, availableDevices } = useDefaultMedia(
    deviceConfig?.mediaDevices,
    deviceConfig?.mediaStream,
    nonce
  );

  if (error) {
    return <h3>Sorry, there was an error loading your device options.</h3>;
  }

  return <DevicesFormInner devices={availableDevices} defaultValues={defaultDevices} refreshDevices={refreshDevices} />;
};

interface InnerProps {
  devices?: MediaDeviceInfo[];
  defaultValues: Partial<FormT>;
  refreshDevices: () => void;
}

// We have to use this inner form or else react-hook-form resets the field values
// to the 1st option field every time we re-render.
const DevicesFormInner = (props: InnerProps) => {
  const result = Bowser.getParser(window.navigator.userAgent);
  const isChrome = result.getBrowserName(true) === 'chrome';
  const { devices, defaultValues, refreshDevices } = props;
  const formContext = useForm<FormT>({ defaultValues });
  const { register, watch } = formContext;

  const deviceConfigDispatch = useDeviceConfigDispatch();

  const audioInputDevices = devices?.filter(isAudioInput);
  const videoInputDevices = devices?.filter(isVideoInput);
  const audioOutputDevices = devices?.filter(isAudioOutput);

  // Update preferred devices when user changes selection
  const { audioinput, videoinput, audiooutput } = watch();

  useEffect(() => {
    if (!audioinput || !videoinput) {
      return;
    }
    deviceConfigDispatch({ type: 'set-media-devices', devices: { audioinput, videoinput, audiooutput } });
    localStore.venueDefaultDevices.set({
      audioinput: audioinput ?? '',
      videoinput: videoinput ?? '',
      audiooutput: audiooutput ?? '',
    });
  }, [audioinput, videoinput, audiooutput, deviceConfigDispatch]);

  return (
    <>
      <FieldLayout label="Audio input">
        {audioInputDevices ? (
          <select name="audioinput" ref={register} defaultValue={defaultValues.audioinput} className="w-full">
            <DeviceOptions devices={audioInputDevices} />
          </select>
        ) : (
          <Skeleton variant="text" />
        )}
        <div className="mt-4">
          <VolumeMeter deviceId={audioinput} />
        </div>
      </FieldLayout>
      <FieldLayout label="Video input" className="mt-8">
        {videoInputDevices ? (
          <select name="videoinput" ref={register} defaultValue={defaultValues.videoinput} className="w-full">
            <DeviceOptions devices={videoInputDevices} />
          </select>
        ) : (
          <Skeleton variant="text" />
        )}
        <div className="mt-4">
          <CameraPreview deviceId={videoinput} />
        </div>
      </FieldLayout>
      <FieldLayout label="Audio output" className="mt-8">
        {/* Browsers (unless is Chrome) won't show the audio output devices, so we show the default value */}
        {audioOutputDevices ? (
          <select name="audiooutput" ref={register} className="w-full" disabled={!isChrome}>
            {isChrome ? (
              <DeviceOptions devices={audioOutputDevices} />
            ) : (
              <DeviceOptions devices={[{ deviceId: '', label: 'Default' } as any]} />
            )}
          </select>
        ) : (
          <Skeleton variant="text" />
        )}
      </FieldLayout>
      <div className="flex flex-row items-end justify-between mt-8">
        {/* todo: add externalLink icon */}
        <Link
          className="text-sm text-gray-700 underline"
          href="https://www.mixily.com/site/experiencing-problems"
          newWindow={true}
        >
          Troubleshooting
        </Link>
        {config.isDev && (
          <Button2 color="secondary" onClick={refreshDevices} className="">
            (DEV) Refresh Devices
          </Button2>
        )}
      </div>
    </>
  );
};

interface DeviceOptionsProps {
  devices: MediaDeviceInfo[];
}

const DeviceOptions = ({ devices }: DeviceOptionsProps) => (
  <>
    {devices.map((deviceInfo) => (
      <option key={deviceInfo.deviceId} value={deviceInfo.deviceId}>
        {deviceInfo.label}
      </option>
    ))}
  </>
);
export default DevicesForm;
