import { Currency } from 'shared/shared/types';
import { EventOrderSummary } from './types';
import { renderDollars } from 'shared/shared/utils';
import React from 'react';
import styles from 'components/EventGuestList/InviteResponse.module.scss';
import useEventPermissions from 'utils/hooks/event/useEventPermissions';

interface Props {
  currency: Currency;
  orderSummary: EventOrderSummary;
}

const TicketResponse = ({ orderSummary, currency }: Props) => {
  const { name, count, amount }: EventOrderSummary = orderSummary;

  // FIXME hack for now. Need to enforce data permissions server-side.
  const { permissions } = useEventPermissions();
  return (
    <div className={styles.InviteResponse}>
      <div className="inline-block text-lg">
        {name}
        {!!count && <span className="text-gray-600"> &times; {count}</span>}
        {permissions.canUpdate && <span className="ml-2">({renderDollars(amount, currency)})</span>}
      </div>
    </div>
  );
};

export default TicketResponse;
