import { ReadOrder_order_lineItems } from 'generated/ReadOrder';
import { renderDollars } from 'shared/shared/utils';
import React from 'react';
import styles from './OrderReadPage.module.scss';

interface Props {
  items: ReadOrder_order_lineItems[];
}

const LineItems: React.FC<Props> = ({ items }) => {
  return (
    <div>
      {items.map((lineItem) => (
        <div className={styles.lineItem} key={`${lineItem.name}${lineItem.quantity}${lineItem.amount}`}>
          <span className={styles.lineItemName}>{lineItem.name}</span>
          <span>
            {lineItem.quantity} &times; <span>{renderDollars(lineItem.amount, lineItem.currency)}</span>
          </span>
        </div>
      ))}
    </div>
  );
};

export default LineItems;
