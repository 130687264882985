import { SidebarContent } from './SidebarContent';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import React from 'react';
import classNames from 'classnames';

interface Props {
  handleDrawerToggle: () => void;
  mobileOpen: boolean;
  usePermanent?: boolean;
}

const drawerWidth = '16.5rem';

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#112539',
  },
}));

const Sidebar = (props: Props) => {
  const classes = useStyles();

  return (
    <div className={classNames('flex-shrink-0', props.usePermanent && 'lg:w-64')}>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={'left'}
          open={props.mobileOpen}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <SidebarContent />
        </Drawer>
      </Hidden>

      {props.usePermanent && (
        <Hidden mdDown implementation="css">
          <Drawer
            variant="permanent"
            open
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <SidebarContent />
          </Drawer>
        </Hidden>
      )}
    </div>
  );
};

export default Sidebar;
