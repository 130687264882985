import React from 'react';
import Slider from 'react-slick';
import attune from './img3/ss-attune-3.png';
import classNames from 'classnames';
import freelance from './img3/ss-freelance-3.png';
import humhum from './img3/ss-humhum-3.png';
import porkys from './img3/ss-porkys-3.png';
import realitybites from './img3/ss-realitybites-3.png';
import roberta from './img3/ss-roberta-3.png';
import styles from './Examples3.module.scss';

const examples = [realitybites, freelance, porkys, humhum, attune, roberta] as const;

const Examples3 = () => {
  return (
    <div className={classNames('mx-auto lg:w-8/12', styles.Container)}>
      <Slider
        infinite={true}
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        initialSlide={0}
        arrows={false}
        dots={true}
        centerMode={true}
        className="mx-auto text-center"
        centerPadding="0"
        autoplay={true}
        autoplaySpeed={3000}
        pauseOnDotsHover={true}
        pauseOnHover={true}
      >
        {examples.map((e, i) => (
          <div key={i} className="max-w-screen-lg max-h-full mx-auto">
            <img
              key={i}
              src={e}
              alt="Example of embedded virtual venue"
              className="w-full h-full max-w-full max-h-full"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Examples3;
