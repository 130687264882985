import Link from 'components/Link';
import Pill from 'components/common/Pill';
import React from 'react';

import { Cell, Column } from 'react-table';
import { Currency } from 'shared/shared/types';
import { EventOrders_event_orderSet, EventOrders_event_orderSet_tickets_ticketType } from 'generated/EventOrders';
import { PriceType } from 'generated/globalTypes';
import { Tooltip } from '@material-ui/core';
import { calculateTotalDiscount, formatPhoneNumber } from 'utils/helpers';
import { prettyDate } from 'utils/datetime';
import { renderDiscountCode, renderDollars } from 'shared/shared/utils';
import { reverse } from 'router';

type TicketType = EventOrders_event_orderSet_tickets_ticketType;
export type Order = EventOrders_event_orderSet;

export const ACCESSORS: Record<string, (order: Order, currency: Currency) => string | null | undefined> = {
  ID: (order: Order) => order.id,
  Created: (order: Order) => (order.createdAt ? prettyDate(order.createdAt) : undefined),
  Name: (order: Order) => order.safeGuestListName,
  Email: (order: Order) => order.safeEmail,
  Phone: (order: Order) => formatPhoneNumber(order.guestListPhone),
  'Discount Code': (order: Order) =>
    order.discounts?.length
      ? order.discounts.map((discount) => renderDiscountCode(discount.discountCode)).join(', ')
      : undefined,
  Tickets: (order: Order) => {
    const types = order.tickets.reduce((acc, ticket) => {
      if (acc[ticket.ticketType.id]) {
        acc[ticket.ticketType.id].quantity++;
      } else {
        acc[ticket.ticketType.id] = {
          name: ticket.ticketType.name || 'Ticket',
          quantity: 1,
        };
      }
      return acc;
    }, {} as Record<string, { name: string; quantity: number }>);

    return Object.keys(types)
      .map((id) => `${types[id].quantity}x ${types[id].name}`)
      .join(', ');
  },
  Subtotal: (order: Order, currency: Currency) => renderDollars(order.subtotal || order.total, currency),
  Discount: (order: Order, currency: Currency) => renderDollars(order.discountSum || 0, currency),
  Total: (order: Order, currency: Currency) => renderDollars(order.total, currency),
  'Stripe Fee': (order: Order, currency: Currency) => renderDollars(order.stripeFee || 0, currency),
  'App Fee': (order: Order, currency: Currency) => renderDollars(order.appFee || 0, currency),
  Net: (order: Order, currency: Currency) =>
    renderDollars(order.total - (order.stripeFee || 0) - (order.appFee || 0), currency),
};

export function COLUMNS(currency: Currency): Column<Order>[] {
  return [
    {
      Header: 'ID',
      accessor: (order) => ACCESSORS['ID'](order, currency),
      Cell: ({ row }: Cell<Order>) => {
        const id = ACCESSORS['ID'](row.original, currency)!;
        const path = reverse('order_read', { id });
        return (
          <div className="flex items-center text-sm leading-5">
            <Link href={path}>{id}</Link>
          </div>
        );
      },
    },
    {
      Header: 'Created',
      accessor: (order) => ACCESSORS['Created'](order, currency),
      Cell: ({ row }: Cell<Order>) => {
        return (
          <div className="flex items-center text-sm leading-5">{ACCESSORS['Created'](row.original, currency)}</div>
        );
      },
    },
    {
      Header: 'Name',
      accessor: (order) => ACCESSORS['Name'](order, currency),
      Cell: ({ row }: Cell<Order>) => {
        return <div className="flex items-center text-sm leading-5">{ACCESSORS['Name'](row.original, currency)}</div>;
      },
    },
    {
      Header: 'Email',
      accessor: (order) => ACCESSORS['Email'](order, currency),
      Cell: ({ row }: Cell<Order>) => {
        return <div className="flex items-center text-sm leading-5">{ACCESSORS['Email'](row.original, currency)}</div>;
      },
    },
    {
      Header: 'Phone',
      accessor: (order) => ACCESSORS['Phone'](order, currency),
      Cell: ({ row }: Cell<Order>) => {
        return <div className="flex items-center text-sm leading-5">{ACCESSORS['Phone'](row.original, currency)}</div>;
      },
    },
    {
      Header: 'Tickets',
      accessor: (order) => ACCESSORS['Tickets'](order, currency),
      Cell: ({ row }: Cell<Order>) => {
        const order = row.original;
        const tickets = order.tickets.reduce((acc, ticket) => {
          const discountCode = order.discounts?.length ? order.discounts[0].discountCode : undefined;

          if (acc[ticket.ticketType.id]) {
            acc[ticket.ticketType.id].quantity++;
          } else {
            const ticketType = {
              id: ticket.ticketType.id,
              quantity: 1,
              cost: ticket.cost,
              price: ticket.cost,
              ticketType: ticket.ticketType,
            };

            const discount = calculateTotalDiscount([ticketType as any], discountCode);
            ticketType.price = ticketType.price - discount;

            acc[ticket.ticketType.id] = ticketType;
          }

          return acc;
        }, {} as Record<string, { id: string; quantity: number; cost: number; price: number; ticketType: TicketType }>);

        return (
          <div className="flex items-center space-x-2 text-sm leading-5">
            {Object.keys(tickets)
              .sort((id1, id2) => tickets[id1].cost - tickets[id2].cost)
              .map((id) => {
                const ticket = tickets[id];
                const ticketType = ticket.ticketType;

                return (
                  <Tooltip
                    arrow
                    key={id}
                    title={
                      <div>
                        <div>{ticketType.name || 'Ticket'}</div>
                        <div>
                          {ticketType.priceType === PriceType.fixed
                            ? `${renderDollars(ticket.cost, currency)}${
                                ticket.cost !== ticket.price ? ` (paid ${renderDollars(ticket.price, currency)})` : ''
                              }`
                            : `donated ${renderDollars(ticket.price, currency)}`}
                        </div>
                      </div>
                    }
                  >
                    <div className="flex items-center">
                      <Pill className="px-2 text-xs cursor-default">
                        {ticket.quantity} &times; {ticket.ticketType.name}
                      </Pill>
                    </div>
                  </Tooltip>
                );
              })}
          </div>
        );
      },
    },
    {
      Header: 'Discount Code',
      accessor: (order) => ACCESSORS['Discount Code'](order, currency),
      Cell: ({ row }: Cell<Order>) => {
        const order = row.original;
        return order.discounts.length ? (
          <div className="flex items-center text-sm leading-5">{ACCESSORS['Discount Code'](order, currency)}</div>
        ) : null;
      },
    },
    {
      Header: 'Subtotal',
      accessor: (order) => ACCESSORS['Subtotal'](order, currency),
      Cell: ({ row }: Cell<Order>) => {
        return (
          <div className="flex items-center text-sm leading-5">{ACCESSORS['Subtotal'](row.original, currency)}</div>
        );
      },
    },
    {
      Header: 'Discount',
      accessor: (order) => ACCESSORS['Discount'](order, currency),
      Cell: ({ row }: Cell<Order>) => {
        const order = row.original;
        return order.discounts.length ? (
          <div className="flex items-center text-sm leading-5">{ACCESSORS['Discount'](order, currency)}</div>
        ) : (
          <div className="flex items-center text-sm leading-5 text-gray-400">N/A</div>
        );
      },
    },
    {
      Header: 'Total',
      accessor: (order) => ACCESSORS['Total'](order, currency),
      Cell: ({ row }: Cell<Order>) => {
        return <div className="flex items-center text-sm leading-5">{ACCESSORS['Total'](row.original, currency)}</div>;
      },
    },
    {
      Header: 'Stripe Fee',
      accessor: (order) => ACCESSORS['Stripe Fee'](order, currency),
      Cell: ({ row }: Cell<Order>) => {
        return (
          <div className="flex items-center text-sm leading-5">{ACCESSORS['Stripe Fee'](row.original, currency)}</div>
        );
      },
    },
    {
      Header: 'App Fee',
      accessor: (order) => ACCESSORS['App Fee'](order, currency),
      Cell: ({ row }: Cell<Order>) => {
        return (
          <div className="flex items-center text-sm leading-5">{ACCESSORS['App Fee'](row.original, currency)}</div>
        );
      },
    },
    {
      Header: 'Net',
      accessor: (order) => ACCESSORS['Net'](order, currency),
      Cell: ({ row }: Cell<Order>) => {
        return <div className="flex items-center text-sm leading-5">{ACCESSORS['Net'](row.original, currency)}</div>;
      },
    },
  ];
}
