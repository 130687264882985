import { Input } from 'components/common';
import { Widget } from 'components/widgets/index';
import { preventDecimals } from 'utils/input';
import React from 'react';

interface Attrs {
  className?: string;
  placeholder?: string;
  isEmptyAllowed?: boolean;
}

const IntegerWidget: Widget<number | null, Attrs> = (props) => {
  return (
    <Input
      id={props.id}
      className={props?.attrs?.className}
      value={(props.value ?? '').toString()}
      type="numeric"
      placeholder={props?.attrs?.placeholder}
      onChange={(e) => {
        if (isNaN(e.target.value) && props?.attrs?.isEmptyAllowed) {
          props.onChange(null);
        } else if (isNaN(e.target.value) && !props?.attrs?.isEmptyAllowed) {
          props.onChange(0);
        } else {
          props.onChange(parseInt(e.target.value, 10));
        }
      }}
      onKeyPress={preventDecimals}
    />
  );
};

export default IntegerWidget;
