// `Raw` because it doesn't parse into a `User` model instance
// `Doesn't check for `viewAs`

import { ME_QUERY } from 'utils/gql';
import { MeQuery, MeQuery_me } from 'generated/MeQuery';
import { useQuery } from '@apollo/client';

type UseAssertRawUserResult =
  | {
      loading: true;
      user: undefined;
    }
  | {
      loading: false;
      user: MeQuery_me;
    };

function useAssertRawUser(): UseAssertRawUserResult {
  const { loading, data } = useQuery<MeQuery>(ME_QUERY);
  if (loading) {
    return {
      loading,
      user: undefined,
    };
  }
  if (!data?.me) {
    throw new Error('useAssertRawUser used with no user authed');
  }
  return {
    loading,
    user: data.me,
  };
}

export default useAssertRawUser;
