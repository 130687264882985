import { EventOrderSummary } from './types';
import ClickToCopy from 'components/common/ClickToCopy/ClickToCopy';
import Dialog, { IDialogProps } from 'components/common/Dialog/Dialog';
import React from 'react';
import styles from './TicketInfoDialog.module.scss';

interface Props extends Omit<IDialogProps, 'title'> {
  orderSummaries: EventOrderSummary[];
}

const TicketInfoDialog: React.FC<Props> = ({ orderSummaries, ...props }) => {
  const emails = orderSummaries.map((o) => o.email);
  const emailsStr = emails.join(', ');
  return (
    <Dialog title="Your guest list" {...props} contentClassName={styles.TicketInfoDialog}>
      <div className={styles.box}>{emailsStr}</div>
      <ClickToCopy text={emailsStr} label="Copy All" className={styles.button} />
    </Dialog>
  );
};

export default TicketInfoDialog;
