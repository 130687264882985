import { ApolloError, useMutation } from '@apollo/client';
import { DELETE_VIRTUAL_VENUE_MUTATION, VIRTUAL_VENUES_BY_OWNER_QUERY } from 'utils/gql';
import { DeleteVirtualVenue, DeleteVirtualVenueVariables } from 'generated/DeleteVirtualVenue';
import { ReadVirtualVenue_virtualVenue } from 'generated/ReadVirtualVenue';
import { URLContext } from 'components/RoutingProvider';
import { addSnackbarMessage } from 'utils/eventEmitter';
import { reverse } from 'shared/shared/routing/mixily-routes';
import { useUserId } from 'utils/hooks/user/useUser';
import Button2 from 'components/common/Button2/Button2';
import Confirm2 from 'components/common/Confirm2/Confirm2';
import ErrorMessage from 'components/common/ErrorMessage/ErrorMessage';
import React, { useContext, useEffect, useState } from 'react';

interface Props {
  virtualVenue: ReadVirtualVenue_virtualVenue;
  className?: string;
}

const getErrorMessage = (data?: DeleteVirtualVenue | null, error?: ApolloError): string => {
  if (error) {
    return 'Sorry, something went wrong';
  }
  if (data?.virtualVenueDelete.errors?.length) {
    return data.virtualVenueDelete.errors[0].message;
  }
  return '';
};

const VirtualVenueDeleteButton = ({ virtualVenue, className }: Props) => {
  const { id } = virtualVenue;
  const userId = useUserId();
  const [open, setOpen] = useState(false);
  const { setPath } = useContext(URLContext);

  const [doDelete, { loading, data, error }] = useMutation<DeleteVirtualVenue, DeleteVirtualVenueVariables>(
    DELETE_VIRTUAL_VENUE_MUTATION,
    {
      refetchQueries: [{ query: VIRTUAL_VENUES_BY_OWNER_QUERY, variables: { ownerId: userId! } }],
    }
  );

  const errorMessage = getErrorMessage(data, error);

  useEffect(() => {
    if (data?.virtualVenueDelete.ok) {
      addSnackbarMessage(`${virtualVenue.title} was deleted`, 'info');
      setPath(reverse('virtual_venue_list'));
    }
  }, [data?.virtualVenueDelete.ok, setPath, virtualVenue.title]);

  return (
    <>
      <Button2 color="secondary" onClick={() => setOpen(!open)} disabled={loading} className={className}>
        {loading ? 'Deleting...' : 'Delete venue...'}
      </Button2>

      <Confirm2
        yesLabel="Delete this venue"
        noLabel="Cancel"
        onConfirm={() => {
          doDelete({ variables: { id } });
        }}
        isOpen={open}
        onClose={() => setOpen(false)}
      >
        Are you sure you want to delete this virtual venue? This can't be undone.
        {errorMessage && <ErrorMessage className="mt-6">{errorMessage}</ErrorMessage>}
      </Confirm2>
    </>
  );
};

export default VirtualVenueDeleteButton;
