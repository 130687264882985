// NB it's up to the user to call this with a persistent constraints object identity, e.g. via `useMemo`

import { useEffect, useMemo } from 'react';
import usePromise from 'utils/hooks/usePromise';

const useUserMedia = (constraints: MediaStreamConstraints, skip?: boolean) => {
  const promise = useMemo(
    () =>
      skip ? new Promise<null>((resolve, reject) => resolve(null)) : navigator.mediaDevices.getUserMedia(constraints),
    [constraints, skip]
  );
  const { loading, error, data: stream } = usePromise(promise);

  useEffect(
    () => () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    },
    [stream]
  );

  return { loading, error, stream };
};

export default useUserMedia;
