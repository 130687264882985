import * as m from 'models';
import { CHANGE_NAME_MUTATION } from 'utils/gql';
import { ChangeName, ChangeNameVariables } from 'generated/ChangeName';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';

import FieldLayout from 'components/common/FieldLayout/FieldLayout';
import Input2 from 'components/common/Input2/Input2';
import React from 'react';
import useMutationFormHelpers from 'components/forms/useMutationFormHelpers';

interface Props {
  me: m.Person;
}

type FormT = {
  name: string;
};

const validate = {
  required: true,
  minLength: {
    value: 3,
    message: 'Please choose a name at least three letters long',
  },
};

const PersonNameForm = ({ me }: Props) => {
  const defaultValues = { name: me.name };
  const [changeName] = useMutation<ChangeName, ChangeNameVariables>(CHANGE_NAME_MUTATION);
  const formContext = useForm<FormT>({ defaultValues });

  const formHelpers = useMutationFormHelpers<FormT, ChangeName, ChangeNameVariables>({
    formContext,
    formToVariables: (formData) => ({ ...formData, id: me.id }),
    mutation: changeName,
    resultKey: 'personUpdate',
    successMessage: 'Name saved!',
  });
  const { FormStateContextProvider, Form, SubmitButton, FormLevelMessages, isSubmitting, onSubmit } = formHelpers;
  const { register, errors } = formContext;

  return (
    <FormStateContextProvider onSubmit={onSubmit} isSubmitting={isSubmitting} formContext={formContext}>
      <Form>
        <div className="flex max-w-2xl">
          <FieldLayout
            label="Display Name"
            error={errors.name}
            helpText="This is how you'll appear to others on Mixily"
            className="w-full"
          >
            {/* TODO: re-implement max-length constraint */}
            <Input2
              name="name"
              ref={register(validate)}
              disabled={isSubmitting}
              placeholder="e.g. Sam Smith"
              size="lg"
            />
          </FieldLayout>
          <SubmitButton label="Update" submittingLabel="Updating..." size="14" className="w-auto mt-5 ml-2" />
        </div>
        <FormLevelMessages className="inline-block mt-1" />
      </Form>
    </FormStateContextProvider>
  );
};

export default PersonNameForm;
