import { PollVote } from 'models/poll';
import { Tooltip } from '@material-ui/core';
import { pluralize } from 'utils/helpers';
import React from 'react';
import classNames from 'classnames';
import styles from './PollVoteTooltip.module.scss';

interface PollVoteTooltipProps {
  votes: PollVote[];
  className?: string;
}

export const PollVoteTooltip = (props: PollVoteTooltipProps) => {
  const { votes, className } = props;

  return (
    <Tooltip
      arrow
      placement="bottom-end"
      title={
        <div className={styles.tooltip}>
          <div>{votes.length ? 'Voted by:' : 'No votes'}</div>
          {votes.map((vote: PollVote) => (
            <div key={vote.id}>{vote.person ? vote.person.name : vote.name || 'User'}</div>
          ))}
        </div>
      }
    >
      <div className={classNames(styles.votes, className)}>
        {votes.length} {pluralize('vote', votes.length)}
      </div>
    </Tooltip>
  );
};
