import { FieldError as RHFFieldError } from 'react-hook-form';
import FieldError, { ErrorCodeHandler } from 'components/forms/FieldError';
import FieldHelpText from 'components/common/FieldHelpText/FieldHelpText';
import LabelWithInput from 'components/common/LabelWithInput/LabelWithInput';
import React, { ReactNode } from 'react';

interface Props {
  checkbox?: boolean;
  children: ReactNode;
  className?: string;
  error?: RHFFieldError;
  errorCodeHandlers?: ErrorCodeHandler;
  helpText?: ReactNode;
  label?: ReactNode;
  alignLeft?: boolean;
}

const getLabel = (
  children: ReactNode,
  label: ReactNode | undefined,
  checkbox: boolean | undefined,
  alignLeft: boolean | undefined
) => {
  if (label) {
    return (
      <LabelWithInput label={label} checkbox={checkbox} alignLeft={alignLeft}>
        {children}
      </LabelWithInput>
    );
  }
  return <div>{children}</div>;
};

const FieldLayout = (props: Props) => {
  return (
    <div className={props.className}>
      {getLabel(props.children, props.label, props.checkbox, props.alignLeft)}
      {props.helpText && <FieldHelpText className="ml-1">{props.helpText}</FieldHelpText>}
      <FieldError error={props.error} errorCodeHandlers={props.errorCodeHandlers} />
    </div>
  );
};

export default FieldLayout;
