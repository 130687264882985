import React from 'react';
import classNames from 'classnames';

import styles from 'components/common/GridRow.module.scss';

interface Props {
  spacing: 'tiny' | 'small' | 'medium' | 'large' | 'xlarge';
}

const GridRow: React.FC<Props> = ({ spacing, children }) => {
  const cls = classNames(styles.GridRow, styles[spacing]);
  return <div className={cls}>{children}</div>;
};

export default GridRow;
