import { EventUpdatePageContext } from 'components/pages/EventUpdatePageContext';
import { ImagePickerModal } from 'components/EventCreateUpdateForm/ImagePicker';
import { Loading, TextLink } from 'components/common';
import { ThemePicCrop } from 'components/pages/EventUpdatePage';
import {
  UnbindListeners,
  getUrlCrop,
  loadImage,
  removeStopWords,
  repositionImageUrl,
  uploadImage,
  withFileDrop,
} from 'utils/helpers';
import { WidgetProps } from 'components/widgets';
import { addSnackbarMessage } from 'utils/eventEmitter';
import { mixilyAPI } from 'utils/api';
import EventThemePicResizer from 'components/EventCreateUpdateForm/EventThemePicResizer/EventThemePicResizer';
import React from 'react';
import classNames from 'classnames';
import styles from './ThemePicWidget.module.scss';

interface Attrs {
  title: string;
  defaultImageUrl?: string;
}

interface State {
  modalOpen: boolean;
  uploading: boolean;
  uploadPercent: number;
  loadingImage: boolean;
  prevCrop?: ThemePicCrop;
  defaultImageUrl?: string;
}

// This widget is tightly coupled and exclusive to `EventUpdatePage`
// `themePic`, and `setThemePic` are contextual to the preview
// image for `EventUpdatePage`
class ThemePicWidget extends React.Component<WidgetProps<string, Attrs>, State> {
  static contextType = EventUpdatePageContext;

  static initialState: State = {
    modalOpen: false,
    uploading: false,
    uploadPercent: 0,
    loadingImage: false,
  };

  public state: State = {
    ...ThemePicWidget.initialState,
  };

  private removeListeners?: UnbindListeners;

  UNSAFE_componentWillMount() {
    // NOTE(nick): prevent redirect on file drop
    this.removeListeners = withFileDrop(document.body, {
      onDrag: () => {
        return false;
      },
    });

    document.addEventListener('mousedown', this.handleMouseDown);
    document.addEventListener('touchstart', this.handleMouseDown);

    const { attrs } = this.props;
    if (attrs!.defaultImageUrl) {
      this.setThemePicUrl(attrs!.defaultImageUrl);
    }
  }

  componentWillUnmount() {
    this.removeListeners && this.removeListeners();

    document.removeEventListener('mousedown', this.handleMouseDown);
    document.removeEventListener('touchstart', this.handleMouseDown);
  }

  handleMouseDown = (e: any) => {
    const {
      themePic: { editPosition },
    } = this.context;

    if (!editPosition) {
      return;
    }

    // @ts-ignore
    const widget = e.target.closest(`.${styles.ThemePicWidget}`);

    if (!widget) {
      this.saveThemePicReposition();
    }
  };

  beginThemePicReposition = () => {
    const { themePic, setThemePic } = this.context;
    this.setState({ prevCrop: themePic.crop });
    setThemePic({ ...themePic, editPosition: true });
  };

  saveThemePicReposition = () => {
    const { themePic } = this.context;
    const { value } = this.props;

    const prevImageUrl = value || themePic.previewUrl;
    const nextImageUrl = repositionImageUrl(prevImageUrl, themePic.crop);
    this.setThemePicUrl(nextImageUrl);
  };

  cancelThemePicReposition = () => {
    const { themePic, setThemePic } = this.context;
    const { prevCrop } = this.state;
    setThemePic({ ...themePic, crop: prevCrop, editPosition: false });
  };

  handleOpenModal = () => {
    this.setState({ modalOpen: true });
  };

  handleCloseModal = () => {
    this.setState({ modalOpen: false });
  };

  handleUploadImage = async (file: any) => {
    if (!file) {
      return;
    }

    this.handleCloseModal();

    try {
      this.setState({ uploading: true, uploadPercent: 0 });
      const uploadedImageUrl = await uploadImage(file, (uploadPercent: any) => this.setState({ uploadPercent }));
      this.handleSetThemePic(uploadedImageUrl);
    } catch (e: any) {
      addSnackbarMessage(e.message, 'error');
    } finally {
      this.setState({ uploading: false });
    }
  };

  handleSetInitialThemePic = () => {
    this.handleOpenModal();
  };

  handleSetThemePic = (imageUrl: string, event?: any) => {
    this.handleCloseModal();

    this.setState({ loadingImage: true });

    loadImage(imageUrl)
      .then((img) => ({
        x: 0.5,
        y: 0.5,
        scale: 1,
        imageWidth: img.width,
        imageHeight: img.height,
      }))
      .then((defaultCrop: any) => {
        return mixilyAPI
          .getUploadCareUrl(imageUrl)
          .then((uploadCareUrl) => {
            const croppedImageUrl = repositionImageUrl(uploadCareUrl, defaultCrop);

            this.setThemePicUrl(croppedImageUrl);
          })
          .catch((err) => {
            addSnackbarMessage('Error uploading image url', 'error');
          });
      })
      .catch((err) => {
        addSnackbarMessage('Failed to load image', 'error');
      })
      .finally(() => {
        this.setState({ loadingImage: false });
      });
  };

  handleRemoveThemePic = () => {
    this.setThemePicUrl('');
    this.handleCloseModal();
  };

  setThemePicUrl = (imageUrl: string) => {
    const { themePic, setThemePic } = this.context;
    const { onChange } = this.props;

    setThemePic({
      ...themePic,
      previewUrl: imageUrl,
      existingRemovedOnNextSave: !imageUrl,
      editPosition: false,
    });

    onChange(imageUrl);
  };

  render() {
    const { themePic } = this.context;
    const { attrs, value } = this.props;
    const { modalOpen, uploading, uploadPercent, loadingImage } = this.state;

    const themePicUrl = themePic.previewUrl || value || '';
    const hasExistingThemePic = !!this.props.value;

    const hasThemePic = !themePic.existingRemovedOnNextSave && (hasExistingThemePic || themePic.previewUrl);

    const isPositioning = themePic.editPosition;

    const crop = getUrlCrop(themePicUrl);

    return (
      <div className={styles.ThemePicWidget}>
        <ImagePickerModal
          open={modalOpen}
          onClose={this.handleCloseModal}
          onSelect={this.handleSetThemePic}
          onUpload={this.handleUploadImage}
          isUploading={uploading}
          onRemove={hasThemePic ? this.handleRemoveThemePic : undefined}
          defaultSearchValue={removeStopWords(attrs!.title.toLowerCase())}
          helpText={{
            upload: 'Images wider than 1500 pixels work best',
          }}
        />

        <div
          className={classNames(styles.ThemePicPlaceholder, {
            [styles.empty]: !hasThemePic,
          })}
          onClick={!hasThemePic ? this.handleSetInitialThemePic : undefined}
        >
          {(uploading || loadingImage) && (
            <div className={styles.uploading}>
              <div className={styles.center}>
                <Loading.Spinner />

                {uploading && (
                  <div className={styles.progressText}>Uploading... {Math.floor(uploadPercent * 100)}%</div>
                )}
              </div>
            </div>
          )}

          {hasThemePic ? (
            <EventThemePicResizer imageUrl={themePicUrl} crop={crop} isPositioning={isPositioning} />
          ) : (
            <div className={styles.Spacer}>
              <span className={styles.Text}>+ Add a cover photo</span>
            </div>
          )}
        </div>

        {hasThemePic && (
          <div className={styles.links}>
            {isPositioning ? (
              <>
                <TextLink text="Cancel" onClick={this.cancelThemePicReposition} />
                <span> &middot; </span>
                <TextLink text="Save Position" onClick={this.saveThemePicReposition} />
              </>
            ) : (
              <>
                <TextLink text="Change Photo" onClick={this.handleOpenModal} />
                <span> &middot; </span>
                <TextLink text="Reposition" onClick={this.beginThemePicReposition} />
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default ThemePicWidget;
