import { IWhen, Timezone } from 'components/widgets/time-types';
import moment, { Moment } from 'moment';

export const getStartAndDuration = (when: IWhen | undefined) => {
  if (!when) {
    return undefined;
  }
  const { start, end, timezone } = when;
  const toDate = (date: any, timezone: string) => (date ? moment.tz(date, timezone) : undefined);
  const startTime = toDate(start, timezone!);
  const endTime = toDate(end, timezone!);
  return {
    startTime: toDate(start, timezone!) ?? undefined,
    duration: startTime && endTime ? moment.duration(endTime?.diff(startTime)).asMinutes() : undefined,
  };
};

// Returns a string based on time ago: e.g. 1 minute ago
export const prettyTimeAgo = (ms: string): string => {
  const date = moment(ms);

  return date.calendar(undefined, {
    sameDay: '[' + date.fromNow() + ']',
    lastDay: '[Yesterday at] LT',
    lastWeek: 'MMMM Do, LT',
    // @ts-ignore
    sameElse: (now) => (now.diff(date, 'years', true) < 1 ? 'MMMM D' : 'LL'),
  });
};

export const prettyTime = (input: Moment) => {
  const m = moment(input);
  return m.format(m.minute() ? 'h:mma' : 'h:mma');
};

export const prettyDate = (input: string): string => {
  const date = moment(input);
  return date.isValid() ? date.format('ddd, MMM Do') + ' at ' + prettyTime(date) : '';
};

/*
  Converts duration to string in the format 1d 1h 01m or 1h01
  Negative durations return ''
*/
export function durationToString(duration: moment.Duration): string {
  const durationDays = duration.get('days'),
    durationHours = duration.get('hours'),
    durationMins = duration.get('minutes');
  const dayString = `${durationDays > 0 ? durationDays : ''}${durationDays ? 'd ' : ''}`,
    hourString = `${durationHours > 0 ? durationHours + 'h' : ''}`,
    minStringPadding = `${durationDays > 0 ? ' ' : ''}${
      !durationDays && durationMins > 0 && durationMins < 10 ? '0' : ''
    }`,
    minString = `${durationMins > 0 ? durationMins : ''}${durationMins > 0 && durationDays ? 'm' : ''}`;
  return dayString + hourString + minStringPadding + minString;
}

export function getLocalTimeZoneName(): string {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export const getDefaultTimezone = (): Timezone => {
  return getLocalTimeZoneName() || 'US/Eastern';
};

export const renderTimezone = (tzStr: string) => {
  return moment.tz(tzStr).format('z');
};

export const renderFullDate = (m: moment.Moment) => {
  return m.format('dddd, MMM. D, YYYY');
};

export const renderFullDateWithoutYear = (m: moment.Moment) => {
  return m.format('dddd, MMM. D');
};

export const renderTime = (m: moment.Moment) => {
  if (!m.minute()) {
    return m.format('h a');
  } else {
    return m.format('h:mm a');
  }
};
