import { ChatMessage } from 'components/VirtualVenue/pubnub/pubnub';
import { DELETE_CHAT_MESSAGE_MUTATION } from 'utils/gql';
import { DeleteChatMessage, DeleteChatMessageVariables } from 'generated/DeleteChatMessage';
import { Menu, MenuItem } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { useVirtualVenue } from 'components/VirtualVenue/contexts/VirtualVenueContext';
import { useVirtualVenueDisplay } from 'components/VirtualVenue/contexts/VirtualVenueDisplayContext';
import { useVirtualVenuePubnubDispatch } from 'components/VirtualVenue/contexts/VirtualVenuePubnubContext';
import Confirm2 from 'components/common/Confirm2/Confirm2';
import EmojiPicker from 'components/VirtualVenue/VirtualVenueDisplay/layouts/VirtualVenueDisplayLayout/Chat/ChatContent/ChatMessageDisplay/MessageMenu/EmojiPicker/EmojiPicker';
import IconSvg from 'components/common/IconSvg/IconSvg';
import React, { RefObject, useState } from 'react';
import classNames from 'classnames';
import fscreen from 'fscreen';

interface Props {
  anchorEl: RefObject<HTMLDivElement>;
  message: ChatMessage;
  showOnHoverClassName: string;
  canModerate?: boolean;
}

const MessageMenu = ({ message, anchorEl, canModerate, showOnHoverClassName }: Props) => {
  const [msgMenuAnchorEl, setMsgMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [msgMenuOpen, setMsgMenuOpen] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);

  const venue = useVirtualVenue();
  const pubnubDispatch = useVirtualVenuePubnubDispatch();
  const { isLiveMode } = useVirtualVenueDisplay();

  const [doDeleteChatMessageMutation] = useMutation<DeleteChatMessage, DeleteChatMessageVariables>(
    DELETE_CHAT_MESSAGE_MUTATION
  );

  const handleDeleteMessage = () => {
    setShowConfirm(false);
    doDeleteChatMessageMutation({ variables: { timeToken: message.timetoken, venueId: venue.id } });
  };
  return (
    <div className={!isLiveMode ? 'pointer-events-none' : ''}>
      <div
        className={classNames(
          showOnHoverClassName,
          'absolute right-0 top-0',
          !(msgMenuOpen || showConfirm) && 'hidden'
        )}
      >
        <div
          className="w-3 h-5 transition duration-150 ease-in-out opacity-50 cursor-pointer hover:opacity-100"
          onClick={() => setMsgMenuOpen(!msgMenuOpen)}
        >
          <IconSvg
            name="dotsVertical"
            className="w-4 h-4"
            ref={setMsgMenuAnchorEl}
            style={{
              color: 'var(--v-theme-chat-text-color)',
            }}
          />
        </div>
        <Menu
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          anchorEl={msgMenuAnchorEl}
          keepMounted
          transitionDuration={0}
          open={msgMenuOpen}
          onClose={() => setMsgMenuOpen(false)}
          // MUI Poppers that can be children of a fullscreened element must conditionally use that element as their container
          // to behave correctly. Otherwise if on body they aren't available in full screen, and if on the fullscreenable element, they can get cut off when not fullscreened.
          container={fscreen.fullscreenElement}
        >
          <MenuItem
            onClick={() => {
              setShowEmojiPicker(!showEmojiPicker);
              setMsgMenuOpen(false);
            }}
          >
            <IconSvg name="emojiHappy" className="w-4 h-4" />
            <span className="ml-1 text-sm">Add reaction</span>
          </MenuItem>
          {canModerate && (
            <MenuItem
              onClick={() => {
                setShowConfirm(true);
                setMsgMenuOpen(false);
              }}
            >
              <IconSvg name="x" className="w-4 h-4" />
              <span className="ml-1 text-sm">Delete message</span>
            </MenuItem>
          )}
        </Menu>
      </div>
      <Confirm2
        title="Are you sure?"
        onClose={() => {
          setShowConfirm(false);
        }}
        onConfirm={handleDeleteMessage}
        isOpen={showConfirm}
        yesLabel="Yes, delete"
        yesConfig={{ color: 'red-outline' }}
        noLabel="Cancel"
      >
        <div>Are you sure you want to delete this message? This can't be undone.</div>
      </Confirm2>
      <EmojiPicker
        isOpen={showEmojiPicker}
        anchorEl={anchorEl}
        onClose={() => setShowEmojiPicker(false)}
        onSelect={(emoji) => {
          pubnubDispatch({
            type: 'send-emoji-reaction',
            emoji: emoji.id!,
            messageTimetoken: message.timetoken,
            operation: 'add',
          });
          setShowEmojiPicker(false);
          setMsgMenuOpen(false);
        }}
      />
    </div>
  );
};

export default MessageMenu;
