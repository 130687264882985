import { CreateEventVariables } from 'generated/CreateEvent';
import { DemoType } from 'models';
import { analyticsAPI as analytics } from 'utils/api';
import { localStore } from 'utils/localstore';
import { randStr } from 'shared/shared/utils';

const fakePoll = (options: string[]) => ({
  id: 'demo-poll',
  type: 'date',
  votes: [],
  options: options.map((o) => ({
    id: 'demo-poll-option-' + randStr(),
    text: o,
  })),
});

interface FakeData {
  variables: CreateEventVariables;
}

export const fakeEventSave = (mutationName: 'eventUpdate' | 'eventCreate') => {
  return (data: FakeData) => {
    analytics.track('CreateDemoEvent');
    const eventData = data.variables;
    const poll = eventData.poll ? fakePoll(eventData.poll) : undefined;
    const id = DemoType.Id;
    const owner = {};
    const myInvite = {};
    const myPermissions = {
      canUpdate: true,
      canInvite: true,
      canViewGuestList: true,
      canMessageGuests: true,
      canScheduleInvites: true,
    };
    const guestPermissions = {
      canUpdate: false,
      canInvite: eventData.guestsCanInvite,
      canViewGuestList: eventData.showGuestList,
      canMessageGuests: false,
      canScheduleInvites: false,
      password: undefined,
    };
    const forCalendar = {
      description: eventData.description,
      end: eventData.endTime,
      location: eventData.location,
      start: eventData.startTime,
      timezone: eventData.timezone,
      title: eventData.title,
    };

    const event = {
      ...eventData,
      forCalendar,
      guestPermissions,
      hostedByText: 'Demo User',
      id,
      myInvite,
      myPermissions,
      owner,
      poll,
    };
    localStore.demoEvent.set(event as any); // FIXME

    return Promise.resolve({
      data: {
        [mutationName]: {
          event,
          ok: true,
        },
      },
    });
  };
};
