import React, { ReactNode } from 'react';
import classNames from 'classnames';

interface Props {
  children: ReactNode;
  className?: string;
}

const FieldHelpText = (props: Props) => {
  const cls = classNames('field-help-text', props.className);
  return <div className={cls}>{props.children}</div>;
};

export default FieldHelpText;
