import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { fromPairs } from 'shared/shared/Functional';
import { useCallback, useEffect, useMemo, useState } from 'react';
import usePaginator from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/CallDisplay/usePaginator';

const useDraggablePaginator = (elementIds: string[], PAGE_SIZE: number, speakerId?: string) => {
  const [order, setOrder] = useState(elementIds);

  const presenceSet = useMemo(() => fromPairs(elementIds.map((id) => [id, true])), [elementIds]);

  // Reconcile new elements list we got in props with our current ordering
  useEffect(() => {
    setOrder((oldOrder) => {
      // Remove tiles from ordering if they're no longer present
      const newOrder = oldOrder.filter((tId) => presenceSet[tId]);

      // Append newly-arrived tiles to the end of the ordering
      // todo: optimize
      elementIds.forEach((tId) => {
        if (newOrder.indexOf(tId) < 0) {
          newOrder.push(tId);
        }
      });
      return newOrder;
    });
  }, [elementIds, presenceSet]);

  const changeSpeaker = order[0] !== speakerId;
  const nonSpeakers = order.filter((t) => t !== speakerId);
  // Move the speaker to the front of the ordered array
  useEffect(() => {
    if (speakerId && changeSpeaker) {
      setOrder((oldOrder) => {
        let oldIndex = oldOrder.indexOf(speakerId!);
        return [speakerId, ...oldOrder.slice(0, oldIndex), ...oldOrder.slice(oldIndex + 1)];
      });
    }
  }, [changeSpeaker, speakerId]);

  const handleDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      setOrder((items: string[]) => {
        let oldIndex = items.indexOf(active.id);
        let newIndex = items.indexOf(over?.id ?? '');
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }, []);

  const { visible, offscreen, prevPage, nextPage } = usePaginator(speakerId ? nonSpeakers : order, PAGE_SIZE);

  return {
    visible,
    offscreen,
    prevPage,
    nextPage,
    order,
    handleDragEnd,
  };
};

export default useDraggablePaginator;
