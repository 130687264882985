import { Message } from 'components/common';
import React from 'react';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const ErrorMessage = (props: Props) => {
  return (
    <Message compact type="negative" className={props.className}>
      {props.children}
    </Message>
  );
};

export default ErrorMessage;
