import React from 'react';

//
// State
//

export type MediaDeviceConfig = {
  // Device IDs
  audioinput: string;
  videoinput: string;
  audiooutput: string;
};

export type MediaActiveStream = {
  // Device label
  audioinput: string;
  videoinput: string;
};

type DeviceConfigContextT = {
  // Whether our window has been granted access to the user's media devices.
  // `null` means we don't know the answer.
  accessGranted: null | boolean;

  // Audio & Video setup
  // The user's prefered media devices.
  // Starts null, then initialized to the user's default devices on load.
  // Storing this state here allows the user to choose a preferred device
  // before Daily has loaded.
  mediaDevices: null | MediaDeviceConfig;

  // Stream from media devices
  // This object contains the active camera and audio
  mediaStream: undefined | MediaActiveStream;
};

const defaultDeviceConfig = {
  accessGranted: null,
  mediaDevices: null,
  mediaStream: undefined,
} as const;

//
// Actions
//

type SetMediaDevices = { type: 'set-media-devices'; devices: MediaDeviceConfig };
type SetMediaStream = { type: 'set-media-stream'; stream: MediaActiveStream };

// We allow setting access granted back to 'null' for the case where permission was
// initially denied, but we prompted the user to change their browser settings. This
// lets us re-check for permission.
type SetAccessGranted = { type: 'set-access-granted'; accessGranted: null | boolean };
export type DeviceConfigAction = SetMediaDevices | SetAccessGranted | SetMediaStream;

//
// Reducer
//

const deviceConfigReducer = (deviceConfig: DeviceConfigContextT, action: DeviceConfigAction): DeviceConfigContextT => {
  switch (action.type) {
    case 'set-media-devices':
      return {
        ...deviceConfig,
        mediaDevices: action.devices,
      };
    case 'set-media-stream':
      return {
        ...deviceConfig,
        mediaStream: action.stream,
      };
    case 'set-access-granted':
      return {
        ...deviceConfig,
        accessGranted: action.accessGranted,
      };
    default:
      throw new Error();
  }
};

//
// Contexts
//

const DeviceConfigContext = React.createContext<DeviceConfigContextT | undefined>(undefined);

function useDeviceConfig(): DeviceConfigContextT;
function useDeviceConfig(optional: true): DeviceConfigContextT | undefined;
function useDeviceConfig(optional?: boolean): DeviceConfigContextT | undefined {
  const context = React.useContext(DeviceConfigContext);
  if (!optional && context === undefined) {
    throw new Error('useDeviceConfig with optional:false must be used within a DeviceConfigProvider');
  }
  return context;
}

type DeviceConfigDispatchContextT = React.Dispatch<DeviceConfigAction>;

const DeviceConfigDispatchContext = React.createContext<DeviceConfigDispatchContextT | undefined>(undefined);

function useDeviceConfigDispatch(): DeviceConfigDispatchContextT;
function useDeviceConfigDispatch(optional: true): DeviceConfigDispatchContextT | undefined;
function useDeviceConfigDispatch(optional?: boolean): DeviceConfigDispatchContextT | undefined {
  const context = React.useContext(DeviceConfigDispatchContext);
  if (!optional && context === undefined) {
    throw new Error('useDeviceConfigDispatch with optional:false must be used within a DeviceConfigProvider');
  }
  return context;
}

//
// Provider
//

type DeviceConfigProviderProps = {
  initialDeviceConfig?: Partial<DeviceConfigContextT>;
  children: React.ReactNode;
};

function DeviceConfigProvider({ children, initialDeviceConfig }: DeviceConfigProviderProps) {
  const combinedDeviceConfig: DeviceConfigContextT = {
    ...defaultDeviceConfig,
    ...initialDeviceConfig,
  };

  const [state, dispatch] = React.useReducer(deviceConfigReducer, combinedDeviceConfig);

  return (
    <DeviceConfigContext.Provider value={state}>
      <DeviceConfigDispatchContext.Provider value={dispatch}>{children}</DeviceConfigDispatchContext.Provider>
    </DeviceConfigContext.Provider>
  );
}

export { useDeviceConfig, useDeviceConfigDispatch, DeviceConfigProvider };
