import ChatContent from 'components/VirtualVenue/VirtualVenueDisplay/layouts/VirtualVenueDisplayLayout/Chat/ChatContent/ChatContent';
import ChatFooter from './ChatFooter/ChatFooter';
import React from 'react';

interface Props {
  chatWidth: number;
}

const Chat = ({ chatWidth }: Props) => {
  return (
    <div
      className="grid h-full text-white"
      // Use a fixed width so we don't reflow when the pane is opening/closing.
      // Width is 1 less than full pane (parent component) width to compensate for border.
      style={{
        gridTemplateRows: '1fr auto',
        background: 'var(--v-theme-chat-bg-color)',
        width: `${chatWidth - 1}px`,
      }}
    >
      <ChatContent />
      <ChatFooter />
    </div>
  );
};

export default Chat;
