import { Quill } from 'react-quill';

const BlockEmbed = Quill.import('blots/block/embed');

class ImageBlot extends BlockEmbed {
  static create(value: any) {
    let node = super.create();
    if (typeof value === 'string') {
      node.setAttribute('src', value);
    } else {
      value.alt && node.setAttribute('alt', value.alt);
      node.setAttribute('src', value.url);
      node.setAttribute('width', value.width);
    }
    return node;
  }

  static value(node: HTMLElement) {
    return {
      alt: node.getAttribute('alt'),
      url: node.getAttribute('src'),
      width: node.getAttribute('width'),
    };
  }
}
ImageBlot.blotName = 'image';
ImageBlot.tagName = 'img';

export default ImageBlot;
