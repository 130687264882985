import { Confirm } from 'components/common';
import { DELETE_COMMENT_MUTATION, THREAD_QUERY } from 'utils/gql';
import { DeleteComment, DeleteCommentVariables } from 'generated/DeleteComment';
import { addSnackbarMessage } from 'utils/eventEmitter';
import { useMutation } from '@apollo/client';

import React from 'react';

interface Props {
  threadId: string;
  deleteId?: string;
  closeModal: () => void;
}

const ConfirmDeleteModal = (props: Props) => {
  const { threadId, deleteId, closeModal } = props;
  const [deleteComment] = useMutation<DeleteComment, DeleteCommentVariables>(DELETE_COMMENT_MUTATION, {
    update: (cache) => {
      const cacheData = cache.readQuery<any>({
        query: THREAD_QUERY,
        variables: { id: threadId },
      });

      const comments = cacheData?.thread?.comments.filter((c: any) => (c ?? {}).id !== deleteId);

      cache.writeQuery({
        query: THREAD_QUERY,
        variables: { id: threadId },
        data: {
          thread: {
            ...cacheData?.thread,
            comments,
          } as any,
        },
      });
    },
  });

  const onConfirmDelete = async () => {
    if (deleteId) {
      await deleteComment({
        variables: {
          commentId: deleteId,
        },
      }).then(({ data }) => {
        if (!data?.commentDelete || !data.commentDelete.ok) {
          addSnackbarMessage('Error deleting comment', 'error');
        }
      });
    }

    closeModal();
  };

  return (
    <Confirm
      open={!!deleteId}
      title="Delete"
      text="Would you like to delete this comment?"
      onConfirm={onConfirmDelete}
      yesLabel="Delete"
      onCancel={() => closeModal()}
    />
  );
};

export default ConfirmDeleteModal;
