import * as m from 'models';
import { ApolloMutation } from 'utils/types';
import { Invite } from 'models/invite';
import { InviteToken } from 'models/invite-token';
import { RsvpStates } from 'generated/globalTypes';
import { addSnackbarMessage } from 'utils/eventEmitter';
import BlockedRsvpForm from 'components/pages/EventReadPage/RsvpForm/BlockedRsvpForm';
import Cookie from 'js-cookie';
import FollowFormInner from 'components/pages/EventReadPage/RsvpForm/FollowFormInner';
import LogoutOrReset from 'components/pages/EventReadPage/RsvpForm/LogoutOrReset';
import React from 'react';
import UserChangeContext from 'components/App/UserChangeContext';

interface Props {
  defaultRsvp?: string;
  doMutation: ApolloMutation;
  event: m.Event;
  invite?: Invite;
  inviteToken?: InviteToken;
  sendMagicLink: (args: any) => void;
  user: m.User;
}

interface State {
  state: 'editing' | 'submitting';
}

const getSuccessMessage = (invite: Invite): string => {
  return invite?.isFollowing ? `🎉 You're now following this event!` : `You unfollowed this event`;
};

const shouldBlockRsvp = (inviteToken: InviteToken | undefined, eventId: string, user: m.User): boolean => {
  const didRsvpWithToken = inviteToken && inviteToken.didRsvp;
  const localInvite = Cookie.get(`event_${eventId}_invite`);
  return !!(!m.isAuthenticated(user) && !localInvite && didRsvpWithToken);
};

class FollowForm extends React.Component<Props, State> {
  static contextType = UserChangeContext;
  context!: React.ContextType<typeof UserChangeContext>;

  constructor(props: Props) {
    super(props);
    this.state = {
      state: 'editing',
    };
  }

  private handleSubmit = (formResult: any) => {
    const { message = '', name = '', email } = formResult;
    const { event, doMutation, invite } = this.props;

    const variables = {
      eventId: event.id,
      rsvpName: name,
      email: email,
      name: name,
      rsvpAnswer: message,
      inviteState: invite?.state || RsvpStates.i,
      isFollowing: !invite?.isFollowing,
      rsvpPlusN: 0,
    };

    this.setState({
      state: 'submitting',
    });

    doMutation({ variables }).then((result: any) => this.onMutationDone(result, variables));
  };

  private onMutationDone = (result: any, variables: any) => {
    this.setState({
      state: 'editing',
    });

    let invite;

    const { eventRsvpCreate, eventRsvpUpdate } = result.data;

    if (eventRsvpCreate) {
      const { ok, errors, signedCookie } = eventRsvpCreate;
      invite = eventRsvpCreate.invite;

      if (!ok && errors) {
        const error = errors[0];

        if (error.fieldName === 'email') {
          const { sendMagicLink, event } = this.props;
          sendMagicLink({
            variables: {
              eventId: event.id,
              email: variables.email,
            },
          });
          addSnackbarMessage(
            "This email has already been used to Follow this event. We're emailing you a link to edit your subscription.",
            'info'
          );
          return;
        }
        addSnackbarMessage(error.message, 'error');
        return;
      }

      if (signedCookie) {
        Cookie.set(signedCookie.name, signedCookie.value);
      }
    } else if (eventRsvpUpdate) {
      const { ok, errors } = eventRsvpUpdate;
      invite = eventRsvpUpdate.invite;

      if (!ok && errors) {
        addSnackbarMessage(errors[0].message, 'error');
        return;
      }
    } else {
      addSnackbarMessage('Something went wrong processing your request.', 'error');
      return;
    }

    const message = getSuccessMessage(invite);
    addSnackbarMessage(message, 'success');
  };

  render() {
    const { event, invite, inviteToken, user } = this.props;
    const shouldBlockForm = shouldBlockRsvp(inviteToken, event.id, user);
    const hasRsvpEmailInForm = invite?.rsvpEmail;

    return (
      <div className="p-6 sm:p-8">
        <div>
          <div className="leading-none event-read-page-responsive-h4-heading">Follow</div>
          <div className="mt-4">Receive event updates and an RSVP email once the host sets a date.</div>
        </div>
        <div className="mt-6">
          {shouldBlockForm && (
            <BlockedRsvpForm
              eventId={event.id}
              invite={invite}
              inviteToken={inviteToken}
              followForm={true}
              sendMagicLink={this.props.sendMagicLink}
            />
          )}
          {!shouldBlockForm && (
            <FollowFormInner
              invite={invite}
              handleSubmit={this.handleSubmit}
              pending={this.state.state === 'submitting'}
            />
          )}
          {hasRsvpEmailInForm && !m.isAuthenticated(user) && (
            <div className="mt-4 text-sm leading-none text-center">
              Not you? <LogoutOrReset eventId={event.id}>Follow as someone else</LogoutOrReset>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default FollowForm;
