import { Currency } from 'shared/shared/types';
import { WidgetProps } from 'components/widgets';
import { centsToDollars, dollarsToCents } from 'utils/helpers';
import MoneyInput from 'components/common/MoneyInput/MoneyInput';
import React, { useEffect, useState } from 'react';

type Value = number | null;
type Attrs = {
  readOnly: boolean;
  className?: string;
  currency: Currency;
};

interface Props extends WidgetProps<Value, Attrs> {}

const TicketPriceWidget = (props: Props) => {
  const { value, disabled, onChange, attrs } = props;
  const [stateVal, setStateVal] = useState<string>(value != null ? centsToDollars(value) : '');
  const { currency, readOnly, className } = attrs!;

  useEffect(() => {
    setStateVal(value != null ? centsToDollars(value) : '');
  }, [value]);

  return (
    <MoneyInput
      layoutProps={{ size: 'lg' }}
      numberFormatProps={{
        onValueChange: ({ value }) => {
          setStateVal(value);
          onChange(value === '' ? null : (dollarsToCents(value) as number));
        },
      }}
      className={className}
      value={stateVal}
      placeholder="Enter a contribution"
      readOnly={readOnly}
      disabled={disabled}
      currency={currency}
    />
  );
};

export default TicketPriceWidget;
