import { FEATURE_FLAGS } from 'shared/shared/json';
import { TextWidget, WidgetProps } from 'components/widgets';
import LabelWithInput from 'components/common/LabelWithInput/LabelWithInput';
import MaxLengthTextWidget from 'components/widgets/MaxLengthTextWidget';
import OptionSwitch from 'components/OptionSwitch/OptionSwitch';
import React from 'react';
import styles from './AdvancedOptionsWidget.module.scss';
import useHasFeatureFlags from 'utils/hooks/user/useHasFeatureFlags';
interface Value {
  hostedByOverride: string;
  disableComments: boolean;
  facebookPixelId: string;
}

const AdvancedOptionsWidget = (props: WidgetProps<Value>) => {
  const { onChange, value, disabled } = props;
  const { hostedByOverride, disableComments, facebookPixelId } = value;

  const { canSetFacebookPixelId, canDisableComments, hostedByOverride: canHostedByOverride } = useHasFeatureFlags(
    FEATURE_FLAGS.canSetFacebookPixelId,
    FEATURE_FLAGS.canDisableComments,
    FEATURE_FLAGS.hostedByOverride
  );

  return (
    <div className={styles.AdvancedOptionsWidget}>
      {canHostedByOverride && (
        <LabelWithInput label="Custom 'Hosted By' text">
          <MaxLengthTextWidget
            value={hostedByOverride || ''}
            disabled={disabled}
            attrs={{ maxLength: 64, size: 'small', placeholder: 'e.g. My Event Company' }}
            onChange={(hostedByOverride) => onChange({ ...value, hostedByOverride })}
          />
        </LabelWithInput>
      )}
      {canSetFacebookPixelId && (
        <LabelWithInput label="Facebook Pixel ID">
          <TextWidget
            value={facebookPixelId || ''}
            disabled={disabled}
            attrs={{ size: 'small', placeholder: 'e.g. 999888777666555' }}
            onChange={(facebookPixelId) => onChange({ ...value, facebookPixelId })}
          />
        </LabelWithInput>
      )}
      {/* For visual clarity, switches should come below text inputs */}
      {canDisableComments && (
        <OptionSwitch
          checked={!disableComments}
          onChange={() => onChange({ ...value, disableComments: !disableComments })}
          fieldName="disableComments"
          title="Guest Commenting"
          helpText="Guests will be able to comment on the event page"
        />
      )}
    </div>
  );
};

export default AdvancedOptionsWidget;
