import { mod } from 'utils/helpers';
import { withClickOutside } from 'utils/hoc';
import Input, { Props as InputProps } from 'components/common/Input/Input';
import React from 'react';
import classNames from 'classnames';
import styles from 'components/common/Search.module.scss';

interface ItemProps {
  item: any;
  index: number;
  isHighlighted: boolean;
  getItemValue: (item: any) => string;
  onClick?: (event: any) => void;
  onMouseMove?: (event: any) => void;
}

export const Item = ({ item, index, isHighlighted, getItemValue, ...rest }: ItemProps) => (
  <div
    key={item.id || index}
    className={classNames(styles.Item, {
      [styles['highlight']]: !!isHighlighted,
    })}
    {...rest}
  >
    {item.before}
    <span className={styles.title}>{getItemValue(item)}</span>
    {item.after}
  </div>
);

interface SearchResultsProps {
  items: any[];
  selectedIndex: number;
  setSelectIndex: (index: number) => void;
  onSelect: (item: any) => void;
  getItemValue: (item: any) => string;
  before?: React.ReactNode;
  after?: React.ReactNode;
  renderItem?: (props: ItemProps) => React.ReactNode;
  loading?: boolean;
}

class SearchResults extends React.Component<SearchResultsProps> {
  static defaultProps = {
    renderItem: (props: ItemProps) => <Item key={props.item.id || props.index} {...props} />,
  };

  private $list?: HTMLDivElement | null;

  componentDidUpdate(prevProps: SearchResultsProps) {
    const { selectedIndex } = this.props;

    if (selectedIndex !== prevProps.selectedIndex) {
      if (this.$list) {
        const child = this.$list.children[selectedIndex];
        child && child.scrollIntoView({ block: 'nearest' });
      }
    }
  }

  render() {
    const { items, selectedIndex, setSelectIndex, onSelect, renderItem, getItemValue, before, after } = this.props;

    const render = renderItem || SearchResults.defaultProps.renderItem;

    return (
      <ul className={styles.SearchResults} onMouseLeave={() => setSelectIndex(-1)}>
        {before}
        <div ref={(el) => (this.$list = el)}>
          {items.map((item, index) =>
            render({
              item,
              index,
              isHighlighted: selectedIndex === index,
              onClick: () => onSelect(item),
              onMouseMove: () => selectedIndex !== index && setSelectIndex(index),
              getItemValue,
            })
          )}
        </div>
        {after}
      </ul>
    );
  }
}

interface SearchInputProps extends InputProps {
  value: string;
  onChange?: (value: string) => void;
  onSubmit?: (event: any) => void;
  moveSelectedIndex: (index: number) => void;
  setSelectIndex: (index: number) => void;
}

export class SearchInput extends React.Component<SearchInputProps> {
  static defaultProps = {};

  handleChange = (e: any) => {
    const { onChange } = this.props;
    onChange && onChange(e.target.value);
  };

  handleKeyDown = (event: any) => {
    const { moveSelectedIndex, setSelectIndex, onSubmit } = this.props;

    // keyboard navigation and submission
    switch (event.key) {
      case 'Enter': {
        onSubmit && onSubmit(event);
        return;
      }
      case 'ArrowUp':
      case 'ArrowDown': {
        event.preventDefault();
        moveSelectedIndex(event.key === 'ArrowDown' ? 1 : -1);
        return;
      }
      case 'Backspace': {
        setSelectIndex(-1);
        return;
      }
    }
  };

  render() {
    const { value, onChange, moveSelectedIndex, setSelectIndex, ...rest } = this.props;

    return (
      <Input
        className={styles.SearchInput}
        value={value}
        onKeyDown={this.handleKeyDown}
        onChange={this.handleChange}
        {...rest}
      />
    );
  }
}

export interface SearchProps {
  items: any[];
  value: string;
  getItemValue?: (item: any) => string;
  className?: string;
  onSelect?: (item: any) => void;
  onSubmit?: (event: any) => void;
  inputProps?: Partial<InputProps>;
  onChange?: (value: string) => void;
  resultProps?: Partial<SearchResultsProps>;
  showResults?: (state: any) => boolean;
  placeholder?: string;
}

interface SearchState {
  selectedIndex: number;
  isOpen: boolean;
  isHover: boolean;
}

class Search extends React.Component<SearchProps, SearchState> {
  static defaultProps = {
    getItemValue: (item: any) => item.value,
    showResults: (state: any) => state.isHover || state.isOpen,
    items: [],
  };

  state = {
    selectedIndex: -1,
    isOpen: false,
    isHover: false,
  };

  moveSelectedIndex = (by: number) => {
    const { items } = this.props;
    const { selectedIndex } = this.state;
    const nextIndex = mod(selectedIndex + by + 1, items.length + 1) - 1;

    this.setState({ selectedIndex: nextIndex, isOpen: true });
  };

  onSubmit = (event: any) => {
    const item = this.getSelectedItem();

    if (item) {
      event.preventDefault();
      const { onSelect } = this.props;
      onSelect && onSelect(item);
      this.setState({ isOpen: true, isHover: false, selectedIndex: -1 });
    } else {
      const { onSubmit } = this.props;
      const value = event.target.value.trim();

      if (value) {
        const data = { value };
        onSubmit && onSubmit(data);
        this.setState({ isOpen: true, isHover: false, selectedIndex: -1 });
      }
    }
  };

  onSelect = (item: any) => {
    const { onSelect } = this.props;
    onSelect && onSelect(item);
    this.setState({ isOpen: false, isHover: false, selectedIndex: -1 });
  };

  getSelectedItem = () => {
    const { items } = this.props;
    const { selectedIndex } = this.state;
    return items[selectedIndex];
  };

  setSelectIndex = (selectedIndex: number) => {
    this.setState({ selectedIndex, isHover: selectedIndex >= 0 });
  };

  handleClickOutside = (e: any) => {
    this.setState({ isOpen: false, isHover: false });
  };

  onFocus = (e: any) => this.setState({ isOpen: true });

  onBlur = (e: any) => this.setState({ isOpen: false });

  render() {
    const {
      className,
      items,
      getItemValue,
      inputProps,
      resultProps,
      showResults,
      onChange,
      value,
      placeholder,
    } = this.props;

    const { isOpen, isHover, selectedIndex } = this.state;

    const _showResults = showResults || Search.defaultProps.showResults;
    const open = _showResults({ isOpen, isHover, value });

    const cn = classNames(styles.Search, className, {
      [styles.open]: open,
    });

    const selectedItem = this.getSelectedItem();
    const _getItemValue = getItemValue || Search.defaultProps.getItemValue;
    const previewValue = selectedItem ? _getItemValue(selectedItem) : '';

    return (
      <div className={cn}>
        <SearchInput
          value={previewValue ? previewValue : value}
          onChange={onChange}
          placeholder={placeholder}
          moveSelectedIndex={this.moveSelectedIndex}
          setSelectIndex={this.setSelectIndex}
          onSubmit={this.onSubmit}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          {...inputProps}
        />
        {open && (
          <SearchResults
            items={items}
            selectedIndex={selectedIndex}
            setSelectIndex={this.setSelectIndex}
            onSelect={this.onSelect}
            getItemValue={_getItemValue}
            {...resultProps}
          />
        )}
      </div>
    );
  }
}

export default withClickOutside(Search);
