import { Button, ThemeProvider, createTheme, useTheme } from '@material-ui/core';
import {
  DELETE_OUTGOING_MESSAGES_MUTATION,
  EVENT_SCHEDULED_REMINDER_QUERY,
  OUTGOING_MESSAGES_QUERY,
  UPDATE_EVENT_SCHEDULED_REMINDER_MUTATION,
} from 'utils/gql';
import { DeleteOutgoingMessages, DeleteOutgoingMessagesVariables } from 'generated/DeleteOutgoingMessages';
import { Message, Reminder, isReminder } from 'components/MessageCenter/types';
import {
  UpdateEventScheduledReminder,
  UpdateEventScheduledReminderVariables,
} from 'generated/UpdateEventScheduledReminder';
import { messageInfo, messagePreview } from 'components/MessageCenter/utils';
import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import ReminderEmailEditDialog from 'components/MessageCenter/ReminderEmailEditDialog/ReminderEmailEditDialog';
import classNames from 'classnames';
import colors from 'styles/style-lib';
import useEventId from 'components/App/globalstate/useEventId';

interface Props {
  message: Message | Reminder;
  setPreviewMessage: (message: Message | Reminder) => void;
}

const MessageListEntry = (props: Props) => {
  const { message, setPreviewMessage } = props;
  const eventId = useEventId();
  const [deleteOutgoingMessages] = useMutation<DeleteOutgoingMessages, DeleteOutgoingMessagesVariables>(
    DELETE_OUTGOING_MESSAGES_MUTATION,
    { refetchQueries: [{ query: OUTGOING_MESSAGES_QUERY, variables: { eventId } }] }
  );
  const [updateEventScheduledReminder] = useMutation<
    UpdateEventScheduledReminder,
    UpdateEventScheduledReminderVariables
  >(UPDATE_EVENT_SCHEDULED_REMINDER_MUTATION, {
    refetchQueries: [{ query: EVENT_SCHEDULED_REMINDER_QUERY, variables: { eventId } }],
  });
  const [editing, setEditing] = useState(false);

  const theme = useTheme();
  const redTheme = createTheme({ ...theme, palette: { primary: { main: colors.red } } });

  return (
    <>
      <li key={message.id} className="p-6 mb-4 border border-gray-400 border-solid rounded-lg">
        <div className="flex flex-col items-center justify-between mb-2 md:mb-0 md:flex-row">
          <div className={classNames('text-lg', { 'text-gray-500': isReminder(message) && message.disabled })}>
            {isReminder(message) ? 'RSVP Reminder' : 'Message'}
            {isReminder(message) && message.disabled ? ' (disabled)' : ''}
          </div>
          <div className="flex items-center mt-2 space-x-3 md:mt-0">
            <Button size="small" variant="outlined" className="w-8" onClick={() => setPreviewMessage(message)}>
              Preview
            </Button>

            {!message.sent && !isReminder(message) && (
              <ThemeProvider theme={redTheme}>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  className="w-8"
                  onClick={() => deleteOutgoingMessages({ variables: { ids: [message.id] } })}
                >
                  Delete
                </Button>
              </ThemeProvider>
            )}

            {!message.sent && isReminder(message) && (
              <Button size="small" variant="outlined" onClick={() => setEditing(true)} className="w-8">
                Edit
              </Button>
            )}

            {!message.sent && isReminder(message) && (
              <Button
                size="small"
                variant="outlined"
                className="w-8"
                onClick={() =>
                  updateEventScheduledReminder({ variables: { id: message.id, disabled: !message.disabled } })
                }
              >
                {message.disabled ? 'Enable' : 'Disable'}
              </Button>
            )}
          </div>
        </div>
        <div
          className="w-full mb-2 text-base text-gray-800 truncate md:w-9/12"
          dangerouslySetInnerHTML={{ __html: messagePreview(message) }}
        />
        <div className="text-sm text-gray-600">{messageInfo(message)}</div>
        {/* There was a bug where the message text in the form was not getting updated after editing.
        Only rendering the modal if 'editing' fixes it. -ab */}
        {editing && isReminder(message) && (
          <ReminderEmailEditDialog
            isOpen={editing}
            onClose={() => {
              setEditing(false);
            }}
            message={message}
          />
        )}
      </li>
    </>
  );
};

export default MessageListEntry;
