import * as F from 'shared/shared/Functional';
import { EVENT_FOR_VENUE_QUERY, ORDER_WITH_EVENT_BY_LOCATION_ID_QUERY } from 'utils/gql';
import {
  EventForVenueQuery,
  EventForVenueQueryVariables,
  EventForVenueQuery_event,
} from 'generated/EventForVenueQuery';
import { Loading } from 'components/common';
import {
  OrderWithEventByLocationId,
  OrderWithEventByLocationIdVariables,
  OrderWithEventByLocationId_orderByLocationId,
} from 'generated/OrderWithEventByLocationId';
import { getURLParams } from 'utils/urls';
import { useQuery } from '@apollo/client';

import Error404Page from 'components/pages/Error404Page';
import Error500Page from 'components/pages/Error500Page';
import React from 'react';
import VenueContent from 'components/pages/VenuePage/VenueContent/VenueContent';
import moment from 'moment';
import styles from './EmbeddedVenuePage.module.scss';
import useClassOnSelector from 'utils/hooks/useClassOnSelector';
import usePasswordEvent from 'components/App/globalstate/usePasswordEvent';

const eventToFragment = (event: EventForVenueQuery_event) => {
  return Object.assign(
    {},
    event,
    {
      startTime: event.startTime ? moment(event.startTime) : null,
      locationReleaseTimeDelta: event.locationReleaseTimeDelta
        ? moment.duration(event.locationReleaseTimeDelta)
        : undefined,
    },
    F.objMapValues(
      F.pick(event, 'themePic', 'bgColor', 'hostedByText', 'bgNoBackdrop', 'virtualVenueContent'),
      (v) => v ?? undefined
    )
  );
};

const orderToFragment = (order: OrderWithEventByLocationId_orderByLocationId) => {
  const event = order.tickets[0].ticketType?.event;
  return Object.assign(
    {},
    event,
    {
      startTime: event.startTime ? moment(event.startTime) : null,
      locationReleaseTimeDelta: event.locationReleaseTimeDelta
        ? moment.duration(event.locationReleaseTimeDelta)
        : undefined,
    },
    F.objMapValues(
      F.pick(event, 'themePic', 'bgColor', 'hostedByText', 'bgNoBackdrop', 'virtualVenueContent', 'partOfRunId'),
      (v) => v ?? undefined
    )
  );
};

const extractTicketTypes = (order: OrderWithEventByLocationId_orderByLocationId) => {
  return order.tickets.map((ticket) => ticket.ticketType.name ?? '');
};

interface Props {
  id: string;
}

const EmbeddedVenuePage = ({ id }: Props): JSX.Element => {
  const urlParams = getURLParams();
  const accessToken = urlParams.order || '';
  useClassOnSelector('w-screen h-screen', '#root,body');
  const resultPassword = usePasswordEvent();

  const { loading: orderLoading, error: orderError, data: orderData } = useQuery<
    OrderWithEventByLocationId,
    OrderWithEventByLocationIdVariables
  >(ORDER_WITH_EVENT_BY_LOCATION_ID_QUERY, {
    variables: { locationId: accessToken },
  });

  const { loading: eventLoading, error: eventError, data: eventData } = useQuery<
    EventForVenueQuery,
    EventForVenueQueryVariables
  >(EVENT_FOR_VENUE_QUERY, {
    variables: { id, password: resultPassword },
  });

  if (eventLoading || orderLoading) {
    return (
      <div className={styles.ExpandAndCenter}>
        <Loading size="medium" />
      </div>
    );
  }

  if (eventError && orderError) {
    return <Error500Page />;
  }
  const accessTokenOrder = orderData?.orderByLocationId;

  if (!accessTokenOrder && !eventData?.event) {
    return <Error404Page />;
  }

  const orderEvent = accessTokenOrder ? orderToFragment(accessTokenOrder) : null;
  const event = eventData?.event ? eventToFragment(eventData?.event) : null;
  const ticketTypes = accessTokenOrder ? extractTicketTypes(accessTokenOrder) : [];

  return (
    <div className={styles.ExpandAndCenter}>
      <VenueContent event={orderEvent ? orderEvent : event!} accessToken={accessToken} ticketTypes={ticketTypes} />
    </div>
  );
};

export default EmbeddedVenuePage;
