import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import React, { CSSProperties, ReactNode } from 'react';
import classNames from 'classnames';
interface Props {
  id: string;
  children: ReactNode;
  className?: string;
  width?: number | string | null;
  height?: number | string | null;
  style?: CSSProperties;
  sortable?: boolean;
}

const TileContainer = (props: Props) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.id,
    disabled: !props.sortable,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: attributes['aria-pressed'] ? 2 : 1,
  };
  const width = Number.isInteger(props.width) ? `${props.width}px` : props.width || undefined;
  const height = Number.isInteger(props.height) ? `${props.height}px` : props.height || undefined;
  return (
    <div
      ref={props.sortable ? setNodeRef : undefined}
      {...listeners}
      {...attributes}
      className={classNames('w-full', props.className)}
      style={
        { ...props.style, ...style, width, maxWidth: width, height, maxHeight: height, touchAction: 'none' } as any
      }
    >
      <div className="relative w-full rounded" style={{ paddingTop: '56.25%' }}>
        <div className="absolute top-0 w-full h-full rounded">{props.children}</div>
      </div>
    </div>
  );
};

export default TileContainer;
