// Returns a new Moment every 50ms until a given timestamp is reached

import { useState } from 'react';
import moment, { Moment } from 'moment';
import useInterval from 'utils/hooks/useInterval';

const useNowUntil = (until: Moment, callback?: Function) => {
  const [now, setNow] = useState<Moment>(moment());
  const [untilReached, setUntilReached] = useState<boolean>(false);
  useInterval(() => {
    if (!untilReached) {
      setNow(moment());
    }
  }, 50);
  if (now.isSameOrAfter(until) && !untilReached) {
    setUntilReached(true);
    callback && callback();
  }
  return now;
};

export default useNowUntil;
