import { VirtualVenueEvent } from 'components/VirtualVenue/types';
import { _makeAbsoluteUrl, isUrl } from 'shared/shared/utils';
import { addSnackbarMessage } from 'utils/eventEmitter';
import React, { useEffect, useState } from 'react';
import VideoPlaybackDisplay from 'components/VirtualVenue/VirtualVenueDisplay/shared/VideoStream/VideoPlaybackDisplay/VideoPlaybackDisplay';
import config from 'config';
import useInterval from 'utils/hooks/useInterval';

interface Props {
  event?: VirtualVenueEvent;
}

/*
 * This component is shown when VirtualVenueContentType is different than 'call' and 'broadcast': it verifies that
 * the streaming URL is correct (event.virtualVenueContent) and automatically reproduces if the streaming starts.
 * Otherwise, it shows an image with a countdown.
 *
 * If you want to add a custom behavior for another organization, you should add a new type in VirtualVenueContentType
 * (frontend/src/components/VirtualVenue/contexts/VirtualVenueContentTypeContext.tsx) and a new case
 * in getContentType (frontend/src/components/VirtualVenue/utils.ts). Or you can set a value in the event's
 * virtualVenueContent field.
 */

const CustomVirtualVenueContent = ({ event }: Props) => {
  const [isValidStreaming, setIsValidStreaming] = useState<boolean>(false);
  const videoStreamUrl = event && isUrl(event?.virtualVenueContent ?? '') ? event.virtualVenueContent! : '';

  // Retry until streaming is available
  useInterval(
    async () => {
      if (!isValidStreaming && isUrl(videoStreamUrl)) {
        await fetch(videoStreamUrl)
          .then((response) => {
            if (response.ok) {
              setIsValidStreaming(true);
            } else {
              setIsValidStreaming(false);
            }
          })
          .catch(() => {
            setIsValidStreaming(false);
          });
      }
    },
    !isValidStreaming ? 5000 : null
  );

  useEffect(() => {
    if (isValidStreaming) {
      setTimeout(() => {
        addSnackbarMessage("Press 'Play ▶' to start the stream", 'info');
      }, 3000);
    }
  }, [isValidStreaming]);

  const imageUrl = _makeAbsoluteUrl(config.hostname, '/tcp-2.jpeg', config.useInsecureHttp);
  return !isValidStreaming ? (
    // Background image for Third Coast Percussion
    <div
      className="relative w-full h-full"
      style={{ backgroundImage: `url('${imageUrl}')`, backgroundSize: '100% 100%' }}
    ></div>
  ) : (
    <VideoPlaybackDisplay videoUrl={videoStreamUrl} />
  );
};

export default CustomVirtualVenueContent;
