// Wrapper around `useAsyncFormHelpers` that understands Mixily's mutation interface ({ok, errors, ...})
// and automatically sets form errors.
//
// Adds `successResult` to return value.
//
//
// Example usage:
// const formHelpers = useMutationFormHelpers<FormT, AuthLogin, AuthLoginVariables>({
//   formContext,
//   formToVariables: F.identity,
//   mutation: accountLogin,
//   resultKey: 'emailAuthLogin',
//   onSuccess: (val) => {
//     const apitoken = val.emailAuthLogin.apitoken!;
//     const setNext = next || window.location.pathname;
//     login(apitoken, true, setNext);
//   },
// });
// const { FormStateContextProvider, Form, SubmitButton, FormLevelMessages, isSubmitting, onSubmit } = formHelpers;
// return (
//   <FormStateContextProvider onSubmit={onSubmit} isSubmitting={isSubmitting} formContext={formContext}>
//     <Form>
//     </Form>
//   </FormStateContextProvider>
// );

import { ExecutionResult } from 'graphql';
import { FormContextValues } from 'react-hook-form';
import { MutationConfig } from 'components/forms/types';
import { useCallback, useEffect, useState } from 'react';
import useAsyncFormHelpers, { AsyncFormHelpers } from './useAsyncFormHelpers';

interface Props<T extends Record<string, any>, MutationT, MutationVariablesT>
  extends MutationConfig<T, MutationT, MutationVariablesT> {
  formContext: FormContextValues<T>;
}

export interface MutationFormHelpers<T, MutationT> extends AsyncFormHelpers<T, ExecutionResult<MutationT>> {
  successResult?: MutationT;
}

interface MutationResult {
  ok: boolean;
  errors: {
    fieldName: string;
    message: string;
  }[];
}

function useMutationFormHelpers<T extends Record<string, any>, MutationT, MutationVariablesT = T>(
  props: Props<T, MutationT, MutationVariablesT>
): MutationFormHelpers<T, MutationT> {
  const { formContext, mutation, resultKey, successMessage, onSuccess, formToVariables } = props;
  const [successResult, setSuccessResult] = useState<MutationT>();

  const handleSubmit = useCallback((value: T) => mutation({ variables: formToVariables(value) }), [
    formToVariables,
    mutation,
  ]);

  const asyncFormHelpers = useAsyncFormHelpers<T, ExecutionResult<MutationT>>({ handleSubmit });

  const { setError } = formContext;
  const { result, error, setFormLevelError, setSuccessMessage, clearMessages } = asyncFormHelpers;

  // HTTP errors
  useEffect(() => {
    error && setFormLevelError(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    clearMessages();

    if (!result) {
      return;
    }

    // GraphQL errors
    if (result.errors) {
      setFormLevelError(true);
      return;
    }

    const data: MutationResult = result.data![resultKey] as any;

    // Application layer errors
    if (!data?.ok) {
      if (!data.errors) {
        // This case *shouldn't* happen, but it can if the client doesn't
        // query for the `errors` field.
        setFormLevelError(true);
      } else {
        for (const fieldName in data.errors) {
          const fieldError = data?.errors[fieldName];
          // TODO: support field maps
          if (fieldError) {
            if (fieldError.fieldName === '__all__') {
              setFormLevelError(fieldError.message);
            } else {
              setError(fieldError.fieldName as any, 'server', fieldError.message);
            }
          }
        }
      }
    } else {
      successMessage && setSuccessMessage(successMessage);
      setSuccessResult(result.data!);
      // eslint-disable-next-line
      onSuccess?.(result.data!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  return {
    ...asyncFormHelpers,
    successResult,
  };
}

export default useMutationFormHelpers;
