import { Button, Loading } from 'components/common';
import { ProcessingStates } from 'generated/globalTypes';
import { durationToString } from 'components/pages/VenuePage/TimeTil/TimeTil';
import { pluralize } from 'utils/helpers';
import { prettyDate } from 'utils/datetime';
import IconSvg, { IconSvgName } from 'components/common/IconSvg/IconSvg';
import React, { ReactNode, useEffect, useState } from 'react';
import moment from 'moment';

export type FetchLinkFnType = () => Promise<{ download_link: string }>;
interface Props {
  isMixilyRecording?: boolean;
  title: string;
  createdAt: string;
  duration: number | null;
  maxParticipants: number | null;
  compositingStatus: ProcessingStates;
  fetchAccessLink: FetchLinkFnType;
}

export const downloadLink = async (fetchAccessLink: FetchLinkFnType, createdAt: string, extension: string) =>
  fetchAccessLink().then(async (data) => {
    const link = document.createElement('a');
    const blob = await fetch(data.download_link).then((r) => r.blob());
    const formattedStartTime = moment(createdAt).format('YYYY-MM-DDTHH:mm:ss');
    link.download = `Mixily recording ${formattedStartTime}.${extension}`;
    link.href = URL.createObjectURL(blob);
    link.click();
  });

const VirtualVenueRecordingDisplay = (props: Props) => {
  const [isDownloading, setDownloading] = useState<boolean>(false);
  const [videoUrl, setVideoUrl] = useState<string | undefined>();
  const { title, createdAt, duration, maxParticipants, compositingStatus, fetchAccessLink, isMixilyRecording } = props;
  const recordingTitle = `Recording ${title ? `from ${title} ` : ''}`;
  const startRecordingDate = prettyDate(createdAt);
  const formattedDuration = duration ? durationToString(moment.duration(duration, 'seconds')) : undefined;
  const participants = maxParticipants ? pluralize('participant', maxParticipants, true) : undefined;
  const recordingIsReady = compositingStatus === ProcessingStates.DONE;

  const recordingState: ReactNode =
    compositingStatus === ProcessingStates.DONE ? (
      'Ready for download!'
    ) : compositingStatus === ProcessingStates.IN_PROGRESS ? (
      'Processing...'
    ) : compositingStatus === ProcessingStates.FAILED ? (
      <span>
        Recording failed, please contact us at <a href="mailto:support@mixily.com">support@mixily.com</a>
      </span>
    ) : (
      'In progress...'
    );

  const InfoItem = ({ iconName, label }: { iconName: IconSvgName; label: ReactNode }) => (
    <div className="flex items-center my-2 text-gray-800">
      <div className="flex justify-center w-6 text-gray-500">
        <IconSvg name={iconName} className="w-6 h-6"></IconSvg>
      </div>
      <div className="ml-4 text-sm md:text-md lg:text-lg">{label}</div>
    </div>
  );

  const handleDownloadClick = () => {
    setDownloading(true);
    downloadLink(fetchAccessLink, createdAt, isMixilyRecording ? 'mp4' : 'webm').then(() => setDownloading(false));
  };

  useEffect(() => {
    if (recordingIsReady && !videoUrl) {
      fetchAccessLink().then((data: any) => {
        if (data && data.download_link) {
          setVideoUrl(data.download_link);
        }
      });
    }
  }, [fetchAccessLink, isMixilyRecording, recordingIsReady, videoUrl]);

  return (
    <div className="flex flex-col items-center lg:items-start">
      <div className="my-4 ">
        <div className="flex items-center">
          <IconSvg name="videoCamera" className="w-10 h-10"></IconSvg>
          <div className="ml-3 text-lg md:text-xl lg:text-2xl">{recordingTitle}</div>
        </div>
      </div>
      <div className="px-8 py-6 bg-white border border-gray-300 border-solid rounded-xl">
        <InfoItem iconName="calendar" label={`Recorded on ${startRecordingDate}`} />
        {formattedDuration && <InfoItem iconName="clock" label={`Lasts ${formattedDuration}`} />}
        {participants && <InfoItem iconName="userGroup" label={participants} />}
        <InfoItem iconName="informationCircle" label={<span>Current status: {recordingState}</span>} />

        {recordingIsReady ? (
          <div className="flex flex-col items-center my-6">
            <div className="relative" style={{ width: 600 }}>
              {!videoUrl && (
                <div className="absolute top-0 z-10 flex items-center justify-center w-full h-full">
                  <Loading></Loading>
                </div>
              )}
              <video controls className="w-full" src={videoUrl} onError={() => setVideoUrl(undefined)}></video>
            </div>
            <Button
              color="custom"
              onClick={handleDownloadClick}
              icon={<IconSvg name="download" className="w-8 h-8"></IconSvg>}
              className="px-6 py-2 mx-auto mt-8 text-lg font-bold text-white rounded-md focus:outline-none md:text-xl lg:text-2xl "
              label={isDownloading ? <Loading></Loading> : 'Click here to download'}
              style={{
                backgroundColor: '#EF9071',
              }}
            />
          </div>
        ) : compositingStatus !== ProcessingStates.FAILED ? (
          <div className="flex mt-6">
            <div className="w-10 mr-3">
              <Loading size="medium"></Loading>
            </div>
            <div className="flex-auto text-gray-800">
              The recording is still processing. <br />A download link will appear as soon as it's ready!
            </div>
          </div>
        ) : undefined}
      </div>
    </div>
  );
};
export default VirtualVenueRecordingDisplay;
