import { ThreadCommentWithReplies } from 'models/comment';
import Comment from './Comment';
import React from 'react';

interface Props {
  comments: ThreadCommentWithReplies[];
  canReply: boolean;
  eventId: string;
  threadId: string;
  onCommentCreate: () => void;
  highlightId?: string;
}

class Comments extends React.Component<Props> {
  render() {
    const { comments, eventId, threadId, canReply, onCommentCreate, highlightId } = this.props;

    return (
      <div>
        {comments.map((comment) => (
          <Comment
            key={comment.id}
            comment={comment}
            onCommentCreate={onCommentCreate}
            highlightId={highlightId}
            eventId={eventId}
            threadId={threadId}
            canReply={canReply}
          />
        ))}
      </div>
    );
  }
}

export default Comments;
