import {
  CallConfigProvider,
  JoinState,
  useCallConfig,
  useCallConfigDispatch,
} from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/contexts/CallConfigContext';
import {
  DeviceConfigProvider,
  useDeviceConfig,
  useDeviceConfigDispatch,
} from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/contexts/DeviceConfigContext';

import { localStore } from 'utils/localstore';
import { useVirtualVenue } from 'components/VirtualVenue/contexts/VirtualVenueContext';
import { useVirtualVenueCall } from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/contexts/VirtualVenueCallContext';
import { useVirtualVenueDisplay } from 'components/VirtualVenue/contexts/VirtualVenueDisplayContext';
import { useVirtualVenuePubnubDispatch } from 'components/VirtualVenue/contexts/VirtualVenuePubnubContext';
import { useVirtualVenueState } from 'components/VirtualVenue/contexts/VirtualVenueStateContext';
import AcquireDevicePermisssions from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/screens/AcquireDevicePermisssions/AcquireDevicePermisssions';
import Call from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/Call';
import CallProvider from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/CallProvider';
import ClosedVenue from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/screens/ClosedVenue/ClosedVenue';

import { DAILY_RECORDING_STATES } from 'components/VirtualVenue/daily/state';
import { ProcessingStates } from 'generated/globalTypes';

import { reverse } from 'shared/shared/routing/mixily-routes';
import CompatibilityError, {
  CompatibilityErrorT,
  checkCompatibilityError,
} from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/screens/CompatibilityError/CompatibilityError';
import DevicesError from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/screens/DevicesError/DevicesError';
import LeftVenue from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/screens/LeftVenue/LeftVenue';
import React, { useEffect, useMemo } from 'react';
import ScreenLayout from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/screens/ScreenLayout';
import VirtualVenueDisplayLayout from 'components/VirtualVenue/VirtualVenueDisplay/layouts/VirtualVenueDisplayLayout/VirtualVenueDisplayLayout';
import VirtualVenueLobby from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/screens/VirtualVenueLobby/VirtualVenueLobby';
import usePrevious from 'utils/hooks/usePrevious';
import useRecordingManager from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/useRecordingManager';

const VirtualVenueCallDisplay = () => {
  const { isLiveMode } = useVirtualVenueDisplay();

  return (
    <CallConfigProvider>
      <DeviceConfigProvider>
        <CallProvider>
          {isLiveMode ? (
            <VirtualVenueCallDisplayInner />
          ) : (
            <VirtualVenueDisplayLayout>{null}</VirtualVenueDisplayLayout>
          )}
        </CallProvider>
      </DeviceConfigProvider>
    </CallConfigProvider>
  );
};

type Screen = 'lobby' | 'get-devices' | 'devices-error' | 'left' | 'closed' | 'compatibility-error';

type CallConfig = {
  showCall: boolean;
  screen: Screen | null;
  errorType?: CompatibilityErrorT;
};

const getDisplayConfig = (
  desiredJoinState: JoinState,
  accessGranted: boolean | null,
  isClosed: boolean,
  isEmbedded: boolean
): CallConfig => {
  const compatibilityError = checkCompatibilityError(isEmbedded);

  if (compatibilityError) {
    return { showCall: false, screen: 'compatibility-error', errorType: compatibilityError };
  }
  if (isClosed) {
    return { showCall: false, screen: 'closed' };
  }
  if (desiredJoinState === 'idle') {
    return { showCall: false, screen: 'lobby' };
  }
  if (desiredJoinState === 'left') {
    return { showCall: false, screen: 'left' };
  }
  if (accessGranted === null) {
    return { showCall: false, screen: 'get-devices' };
  }
  if (accessGranted === false) {
    return { showCall: false, screen: 'devices-error' };
  }
  return {
    showCall: true,
    screen: null,
  };
};

const VirtualVenueCallDisplayInner = () => {
  const venue = useVirtualVenue();
  const venueState = useVirtualVenueState();
  const pubnubDispatch = useVirtualVenuePubnubDispatch();
  const { dailyState, dailyDispatch } = useVirtualVenueCall();
  const { mediaDevices, accessGranted } = useDeviceConfig();
  const { desiredJoinState } = useCallConfig();
  const callConfigDispatch = useCallConfigDispatch();
  const deviceConfigDispatch = useDeviceConfigDispatch();
  const localDevices = localStore.venueDefaultDevices.get();
  const {
    myAccess: { ableToRecord },
    id: venueId,
    dailyCallUrl,
  } = venue;
  const roomId = dailyCallUrl.replace(/^.+\//g, '');
  const { createRecording, updateRecording, virtualVenueRecording } = useRecordingManager();

  const {
    recordingInfo: { recordingState, activeRecordingId },
  } = dailyState;
  const lastRecordingState = usePrevious(recordingState);
  const { isEmbedded } = useVirtualVenueDisplay();

  // Broadcast my Daily user id and set default devices
  useEffect(() => {
    const dailyUserId = dailyState.callItems.local.id;
    if (!dailyUserId) {
      return;
    }
    pubnubDispatch({ type: 'set-daily-user-id', dailyUserId: dailyUserId });
    if (localDevices) {
      deviceConfigDispatch({ type: 'set-media-devices', devices: localDevices });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyState.callItems.local.id, deviceConfigDispatch, pubnubDispatch]);

  // Update Daily devices based on user's preferences
  const dailyCall = dailyState.call?.call;
  const { audioinput, videoinput, audiooutput } = mediaDevices || localDevices || {};

  useEffect(() => {
    if (!dailyCall || !audioinput || !videoinput) {
      return;
    }

    dailyCall.setInputDevicesAsync({
      audioDeviceId: audioinput,
      videoDeviceId: videoinput,
    });

    if (audiooutput) {
      dailyCall.setOutputDevice({ outputDeviceId: audiooutput });
    }
  }, [dailyCall, audioinput, audiooutput, videoinput]);

  useEffect(() => {
    if (!ableToRecord) {
      return;
    }
    if (
      recordingState === DAILY_RECORDING_STATES.RECORDING_STARTED &&
      lastRecordingState !== DAILY_RECORDING_STATES.RECORDING_STARTED
    ) {
      if (activeRecordingId) {
        createRecording(venueId, roomId, activeRecordingId);
      }
    } else if (
      lastRecordingState === DAILY_RECORDING_STATES.RECORDING_STARTED &&
      recordingState !== DAILY_RECORDING_STATES.RECORDING_STARTED
    ) {
      if (activeRecordingId) {
        const newStatus =
          recordingState === DAILY_RECORDING_STATES.RECORDING_ERROR ? ProcessingStates.FAILED : ProcessingStates.DONE;
        updateRecording(activeRecordingId, newStatus);
      }
    }
  }, [
    ableToRecord,
    activeRecordingId,
    createRecording,
    lastRecordingState,
    recordingState,
    roomId,
    updateRecording,
    venueId,
  ]);

  const recordingUrl = useMemo(() => {
    if (virtualVenueRecording?.id) {
      return reverse('virtual_venue_recording', { venueId, recordingId: virtualVenueRecording?.id });
    }
  }, [virtualVenueRecording?.id, venueId]);

  const isClosed = venueState.isClosed ?? venue.isClosed;
  const { showCall, screen, errorType } = getDisplayConfig(desiredJoinState, accessGranted, isClosed, isEmbedded);

  const screenOverlay = (
    <ScreenLayout>
      {screen === 'lobby' ? (
        <VirtualVenueLobby
          title={venueState.newTitle || venue.title}
          logo={venue.theme?.logo}
          logoFilter={venue.theme?.logoFilter}
        />
      ) : screen === 'closed' ? (
        <ClosedVenue
          title={venueState.newTitle || venue.title}
          logo={venue.theme?.logo}
          logoFilter={venue.theme?.logoFilter}
          awayMessage={venueState.awayMessage ?? venue.awayMessage ?? ''}
          virtualVenueId={venue.id}
          ownerId={venue.ownerId ?? ''}
        />
      ) : screen === 'get-devices' ? (
        <AcquireDevicePermisssions mediaDevices={mediaDevices || localDevices} />
      ) : screen === 'devices-error' ? (
        <DevicesError />
      ) : screen === 'left' ? (
        <LeftVenue recordingUrl={recordingUrl} />
      ) : screen === 'compatibility-error' ? (
        <CompatibilityError errorType={errorType!} />
      ) : null}
    </ScreenLayout>
  );

  // Kick the client out of the call if the room has been closed.
  useEffect(() => {
    if (isClosed) {
      callConfigDispatch({ type: 'set-desired-join-state', joinState: 'idle' });
    }
  }, [showCall, isClosed, callConfigDispatch]);

  // Load call when it's time to show, unload when it's time to hide
  const previousShowCall = usePrevious(showCall);
  useEffect(() => {
    if (showCall) {
      dailyDispatch({ type: 'create-call' });
    } else if (previousShowCall === true) {
      dailyDispatch({ type: 'start-leaving-call' });
    }
  }, [dailyDispatch, previousShowCall, showCall]);

  return (
    <VirtualVenueDisplayLayout screen={screenOverlay}>
      <div className="flex items-center justify-center w-full h-full">{showCall && <Call />}</div>
    </VirtualVenueDisplayLayout>
  );
};

export default VirtualVenueCallDisplay;
