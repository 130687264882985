import 'components/layout/Header/AuthedDropdown.scss';
import * as m from 'models';
import { EventLayoutContext } from 'components/layout/EventLayout/EventLayout';
import { reverse } from 'shared/shared/routing/mixily-routes';
import Dropdown from 'components/common/Dropdown/Dropdown';
import React, { useContext } from 'react';
import classNames from 'classnames';
import useUserChangeContext from 'components/App/useUserChangeContext';

interface Props {
  user: m.Person;
  withSidebar?: boolean;
}
const AuthedDropdown = (props: Props) => {
  const { colorClass } = useContext(EventLayoutContext);
  const { logout } = useUserChangeContext();
  const currentUrl = window.location.pathname;

  const dropdownItems = [
    { text: 'Account Settings', href: reverse('account_settings') },
    { text: 'Help', href: reverse('faq') },
    { text: 'Log out', onClick: () => logout(currentUrl) },
  ];
  return (
    <Dropdown align="right" items={dropdownItems}>
      {/*
        Filter is used here instead of color-white/text-gray-600 for consistency for
        both code and appearance with other elements/components that use filter
        to change color.
      */}
      <div className={classNames(colorClass, 'NameIcon')}>{props.user.name[0]}</div>
    </Dropdown>
  );
};

export default AuthedDropdown;
