// import { Checkbox, CheckboxProps } from '@material-ui/core';
// import React from 'react';

// interface Props extends CheckboxProps {}

// const Checkbox2 = (props: Props) => {
//   return <Checkbox color="primary" {...props} />;
// };

import React, { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import classNames from 'classnames';

export type InputBuiltinProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export interface Props extends InputBuiltinProps {
  ref?: React.Ref<HTMLInputElement>;
  className?: string;
}

const Checkbox2 = (props: Props, ref: React.Ref<HTMLInputElement>) => {
  const { size, className, ...rest } = props;
  const cls = classNames('form-checkbox', 'text-link-color', 'focus:shadow-none', className);
  return <input type="checkbox" className={cls} ref={ref} {...rest} />;
};

export default React.forwardRef(Checkbox2);
