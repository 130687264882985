/* eslint-disable @typescript-eslint/triple-slash-reference */
/// <reference path="./index.d.ts" />
import * as Sentry from '@sentry/browser';
import App from 'components/App/App';
import React from 'react';
import ReactDOM from 'react-dom';

// eslint-disable-next-line import/first
import config from 'config';

// Sentry setup https://docs.sentry.io/platforms/javascript/
if (config.sentry.dsn) {
  Sentry.init({
    dsn: config.sentry.dsn,
    release: config.release,
  });
}

ReactDOM.render(<App />, document.getElementById('root'));
