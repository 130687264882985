import * as F from 'shared/shared/Functional';
import {
  ChatMessage,
  getVirtualVenuePubNubChannelName,
  pubnubApi,
  transformPubnubChatMessage,
} from 'components/VirtualVenue/pubnub/pubnub';
import { FetchMessagesResponse } from 'pubnub';
import { Skeleton } from '@material-ui/lab';
import { VenueRole } from 'generated/globalTypes';
import ChatMessageDisplay from 'components/VirtualVenue/VirtualVenueDisplay/layouts/VirtualVenueDisplayLayout/Chat/ChatContent/ChatMessageDisplay/ChatMessageDisplay';
import React, { useMemo } from 'react';
import moment from 'moment';
import usePromise from 'utils/hooks/usePromise';

interface Props {
  venueId: string;
}

type FetchMessagesResponseWithMore = FetchMessagesResponse & {
  more?: {
    start: string;
  };
};

const fetchBatchOfMessages = (channelName: string, fromToken?: string | number | undefined): Promise<any[]> => {
  // Note pagination is performed starting from the "last" page
  // or most recent messages.
  // Within each page, messages are in ascending chronological order
  return pubnubApi
    .fetchMessages({
      channels: [channelName],
      start: fromToken,
      count: 100,
      includeMessageActions: true,
    })
    .then((data: FetchMessagesResponseWithMore) => {
      const messages = data.channels[channelName];
      if (data.more) {
        return fetchBatchOfMessages(channelName, data.more.start).then((nextData) => {
          return nextData.concat(messages);
        });
      } else {
        return messages;
      }
    });
};

const VirtualVenueChatHistoryDisplay = ({ venueId }: Props) => {
  const channelName = getVirtualVenuePubNubChannelName(venueId);
  const fetchMessagesPromise = useMemo(() => {
    return fetchBatchOfMessages(channelName);
  }, [channelName]);
  const { loading, error, data } = usePromise(fetchMessagesPromise);
  const chatMessages = useMemo(
    () => data?.filter((m) => m.message.type === 'chat.text').map(transformPubnubChatMessage) ?? [],
    [data]
  );
  const chatMessagesByDay = useMemo(() => {
    return F.groupBy(chatMessages, (m: ChatMessage) => {
      const timetokenInMs = parseInt(m.timetoken.slice(0, -4));
      const timestamp = moment(timetokenInMs);
      return timestamp.format(moment.HTML5_FMT.DATE);
    });
  }, [chatMessages]);
  if (loading && !error) {
    return <Skeleton variant="rect" height={36} />;
  }

  if (error) {
    return (
      <div className="text-xl">Sorry, something went wrong loading your chat history. Please contact support.</div>
    );
  }

  if (!data) {
    return <div className="mx-auto text-xl italic">Your chat history is empty.</div>;
  }

  return (
    <div className="flex flex-col">
      {Object.keys(chatMessagesByDay).map((date: string) => {
        const displayDate = moment(date).format('dddd, MMMM Do');
        return (
          <div key={displayDate}>
            <div className="flex flex-row items-center justify-center my-2">
              <span className="flex-grow h-0 border border-t-0 border-gray-300 border-solid "></span>
              <span className="px-3 py-1 text-gray-700 border border-gray-300 border-solid rounded-full">
                {displayDate}
              </span>
              <span className="flex-grow h-0 border border-t-0 border-gray-300 border-solid "></span>
            </div>
            <div className="ml-2">
              {chatMessagesByDay[date].map((m: ChatMessage) => {
                return (
                  <ChatMessageDisplay
                    showTimestamp
                    ignoreTheme
                    disableInteractions
                    key={m.timetoken}
                    venueRole={VenueRole.GUEST}
                    message={m}
                  />
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default VirtualVenueChatHistoryDisplay;
