import React from 'react';
import classNames from 'classnames';

interface Props {
  title: string;
}

const Title = ({ title }: Props) => {
  const hasLongCharacterCount = title.length > 28;
  const cls = classNames('text-3xl', hasLongCharacterCount ? 'sm:text-4xl' : 'sm:text-5xl');
  return <div className={cls}>{title}</div>;
};

export default Title;
