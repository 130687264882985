import React, { ReactNode } from 'react';

interface Props {
  title: string;
  children: ReactNode;
}

const EmbeddedErrorPage = (props: Props) => {
  document.title = props.title;

  return (
    <div className="p-4 bg-white bg-opacity-50">
      <div className="mb-12 h2-responsive">{props.title}</div>
      <div>{props.children}</div>
    </div>
  );
};

export default EmbeddedErrorPage;
