import { RgbaColor, RgbaColorPicker } from 'react-colorful';
import { VirtualVenueThemeOption } from 'components/VirtualVenue/types';
import {
  colorNameToHex,
  colorNameToRgba,
  colorToRgba,
  hexToRgb,
  rgbToHex,
  rgbaToColor,
  rgbaToHexa,
} from 'utils/colors';
import { useVirtualVenueThemeDispatch } from 'components/VirtualVenue/contexts/VirtualVenueThemeContext';
import ChannelInput from 'components/VirtualVenueThemeEditor/ThemePanel/ThemeColorWidget/ChannelInput';
import Input2 from 'components/common/Input2/Input2';
import React from 'react';
import styles from './ThemeColorWidget.module.scss';
import useDebounce from 'utils/hooks/useDebounce';

interface Props {
  selectedOption: VirtualVenueThemeOption;
  value: string; // Stored in #RRGGBBAA format
}

// This component uses a few different color formats
//
// Internally, it tracks state as an {r, g, b, a} object  -- `RgbaColor` -- where
// `r`, `g`, `b` are 0-255, and `a` is 0-1 -- the latter because it's the format
// the 'color' lib uses.
//
// It displays `a` as 0-100, which is the most familiar and intuitive format
//
// It saves to the theme object in #RRGGBBAA format.

function ThemeColorWidget({ selectedOption, value }: Props) {
  const [color, setColor] = React.useState<RgbaColor>(colorNameToRgba(value));
  const [hex, setHex] = React.useState<string>(''); // Stored without hash
  const dispatch = useVirtualVenueThemeDispatch();
  const debouncedColor = useDebounce<RgbaColor>(color, 50);

  // Set our internal state from the color prop passed in
  React.useEffect(() => {
    setHex(colorNameToHex(value).slice(1));
    setColor(colorNameToRgba(value));
  }, [value]);

  // Update hex value when internal color changes
  React.useEffect(() => {
    setHex(rgbToHex(color).slice(1));
  }, [color]);

  // Send updates to the theme venue theme context
  React.useEffect(() => {
    const hexa = rgbaToHexa(debouncedColor);
    dispatch({
      type: 'set-color',
      payload: {
        name: selectedOption,
        value: hexa,
      },
    });

    // Exclude `selectedOption` from deps -- otherwise we see it overwriting
    // option values from the old option when you switch to a new one
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedColor, dispatch]);

  const handleInputChange = (
    el: 'r' | 'g' | 'a' | 'b' | 'hex',
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    const numValue = Number(value);
    if (el !== 'hex' && isNaN(numValue)) {
      return;
    }
    const hexRegex = /^[#]?(?<hex>[0-9A-F]{6})$/i;

    const cc = rgbaToColor(color);

    if (el === 'r') setColor(colorToRgba(cc.red(numValue)));
    else if (el === 'g') setColor(colorToRgba(cc.green(numValue)));
    else if (el === 'b') setColor(colorToRgba(cc.blue(numValue)));
    else if (el === 'a') setColor(colorToRgba(cc.alpha(numValue / 100)));
    else if (el === 'hex') {
      setHex(value);
      const match = hexRegex.exec(value);
      if (match) {
        const newColor = {
          ...hexToRgb('#' + match.groups!.hex),
          a: color.a,
        };
        setColor(newColor);
      }
    }
  };

  return (
    <div className="flex flex-col">
      <div className="grid grid-cols-3 mb-4 gap-x-2 gap-y-3">
        <ChannelInput value={color.r} adornment="R" onChange={(e) => handleInputChange('r', e)} />
        <ChannelInput value={color.g} adornment="G" onChange={(e) => handleInputChange('g', e)} />
        <ChannelInput value={color.b} adornment="B" onChange={(e) => handleInputChange('b', e)} />
        <ChannelInput value={Math.floor(color.a * 100)} adornment="A" onChange={(e) => handleInputChange('a', e)} />
        <Input2
          placeholder="FF0000"
          size="sm"
          startAdornment={{
            component: (
              <span className="font-bold text-gray-500" style={{ fontSize: '13px' }}>
                HEX #
              </span>
            ),
            paddingSize: 13,
            clickable: false,
          }}
          className="text-black rounded-full"
          classes={{
            root: 'col-span-2',
          }}
          value={hex}
          maxLength={7}
          onChange={(e) => handleInputChange('hex', e)}
          style={{ fontSize: '13px' }}
        />
      </div>
      <RgbaColorPicker color={color} onChange={setColor} className={styles.ThemeColorWidget} />
    </div>
  );
}

export default React.memo(ThemeColorWidget);
