import { addSnackbarMessage } from 'utils/eventEmitter';
import { isUrl } from 'shared/shared/utils';
import { useVirtualVenue } from 'components/VirtualVenue/contexts/VirtualVenueContext';
import React, { useEffect, useState } from 'react';
import VideoPlaybackDisplay from 'components/VirtualVenue/VirtualVenueDisplay/shared/VideoStream/VideoPlaybackDisplay/VideoPlaybackDisplay';
import classNames from 'classnames';
import useInterval from 'utils/hooks/useInterval';

const TIMER_FADE_OUT_SECONDS_DELAY = 10;

const CustomVirtualVenueContent = () => {
  const [isValidStreaming, setIsValidStreaming] = useState<boolean>(false);
  const venue = useVirtualVenue();
  const videoStreamUrl = venue.broadcastUrl!;

  // Retry until streaming is available
  useInterval(
    async () => {
      if (!isValidStreaming && isUrl(videoStreamUrl)) {
        await fetch(videoStreamUrl)
          .then((response) => {
            if (response.ok) {
              setIsValidStreaming(true);
            } else {
              setIsValidStreaming(false);
            }
          })
          .catch(() => {
            setIsValidStreaming(false);
          });
      }
    },
    !isValidStreaming ? 5000 : null
  );

  const preShow = (textColor?: string | undefined) => (
    <div
      className={classNames(['text-center', 'leading-none absolute', 'transition-opacity ease-in-out', 'block'])}
      style={{
        left: '50%',
        transform: 'translateX(-50%)',
        bottom: textColor ? '10%' : '25%',
        color: textColor ? textColor : 'white',
        fontSize: textColor ? 'max(1.5rem, min(2vw, 1.5rem))' : 'max(.6rem, min(2vw, 1.5rem))',
        fontWeight: 400,
        transitionDuration: `${TIMER_FADE_OUT_SECONDS_DELAY}s`,
        opacity: isValidStreaming ? '0' : '1',
      }}
    >
      <div className="mb-2 tracking-widest md:mb-4">YOUR SHOW BEGINS IN</div>
      <div
        style={{
          fontSize: 'max(0.8rem, min(2vw, 2.5rem))',
        }}
      >
        a few moments...
      </div>
    </div>
  );

  useEffect(() => {
    if (isValidStreaming) {
      setTimeout(() => {
        addSnackbarMessage("Press 'Play ▶' to start the stream", 'info');
      }, 3000);
    }
  }, [isValidStreaming]);

  return !isValidStreaming ? (
    <div className="relative w-full h-full">{preShow()}</div>
  ) : (
    <div className="relative w-full h-full">{<VideoPlaybackDisplay videoUrl={videoStreamUrl} autoplay={true} />}</div>
  );
};

export default CustomVirtualVenueContent;
