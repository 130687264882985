import { URLParams, constructQueryString } from 'shared/shared/utils';

export function getURLParams(url?: string): URLParams {
  url = url || window.location.href;
  let urlparts = url.split('?');
  if (urlparts.length === 1) {
    return {};
  }
  // Split into key=value pairs
  const paramStrings = urlparts[1].split(/[&;]/g);
  const map: any = {};
  let name: string, value: string;
  for (const pair of paramStrings) {
    [name, value] = pair.split('=');
    map[name] = decodeURIComponent(value);
  }
  return map;
}

export function getURLParam(name: string, defaultVal?: string): string | undefined {
  const params = getURLParams();
  return params[name] ? params[name] : defaultVal;
}

export const replaceURLParam = (name: string, value: string | null): [string, string | null] => {
  // Construct a URL from the current URL by replacing GET param `name` with value `value`,
  // where `null` means delete the key. Returns a pair of the newURL, and the old key value.

  // Don't return use the full URL, just the relative path. This makes it easier to handle
  // internal routing, because RoutingProvider doesn't have to check for the full URL case.
  const windowHref = window.location.href; // e.g. https://foo.com:8000/bar?q=URL#search
  const windowOrigin = window.location.origin; // e.g. https://foo.com:8000/bar?q=URL#search
  const url = windowHref.slice(windowOrigin.length);

  const base = url.split('?')[0];
  const paramMap = getURLParams(url);
  const oldValue = paramMap[name];

  if (value === null) {
    delete paramMap[name];
  } else {
    paramMap[name] = value;
  }

  const newURL = base + constructQueryString(paramMap);
  return [newURL, oldValue];
};

export const popUrlParam = (key: string): string | null => {
  // Return the GET parameter value of 'key', if any exists, or else null.
  // Removes the parameter from the browser location without creating new history.
  const [newURL, result] = replaceURLParam(key, null);
  window.history.replaceState({ path: newURL }, '', newURL);
  return result;
};

export const setUrlParams = (params: URLParams) => {
  const path = window.location.pathname;
  const newURL = path + constructQueryString(params);
  window.history.replaceState({ path: newURL }, '', newURL);
};

// todo put host somewhere else - ENV var?
export const fullUrl = (path: string): string => 'https://www.mixily.com' + path;

export const appendQueryString = (path: string, query?: URLParams) => {
  return path + (query ? constructQueryString(query) : '');
};

export const scrollToFragment = (path: string) => {
  // Returns whether we scrolled to a fragment
  // Based on https://stackoverflow.com/a/28748160/
  const fragment = path.match(/#(.*)/);
  if (!fragment) {
    return false;
  }
  const anchor = fragment[1];
  const anchorEl = document.getElementById(anchor);
  if (!anchorEl) {
    return false;
  }
  window.requestAnimationFrame(() => anchorEl.scrollIntoView());
  return true;
};

export function isActiveUrl(url: string) {
  const currentUrl = window.location.href;
  return currentUrl.includes(url);
}
