import { ACCESSORS, COLUMNS, Order } from './config';
import { EVENT_ORDERS_QUERY } from 'utils/gql';
import { EventOrders, EventOrdersVariables } from 'generated/EventOrders';
import { Loading } from 'components/common';
import { getCsvFilename } from 'utils/helpers';
import { objMapValues } from 'shared/shared/Functional';
import { useQuery } from '@apollo/client';
import DashboardTable from 'components/pages/Dashboard/DashboardTable/DashboardTable';
import React from 'react';
import useEventClass from 'utils/hooks/event/useEventClass';

interface Props {
  eventId: string;
  onSetWide: (wide: boolean) => void;
  password?: string;
  wide: boolean;
}

const EventOrdersTable = ({ eventId, wide, onSetWide, password }: Props) => {
  const { data: orders, loading } = useQuery<EventOrders, EventOrdersVariables>(EVENT_ORDERS_QUERY, {
    variables: { id: eventId, password },
    fetchPolicy: 'cache-and-network',
  });
  const { event, loading: loadingEvent } = useEventClass();

  if (loadingEvent || loading || !event || !orders?.event?.orderSet) {
    return <Loading />;
  }

  const data = orders?.event?.orderSet || [];
  const downloadData = data.map((order) => objMapValues(ACCESSORS, (acc) => acc(order, event.currency)));

  return (
    <DashboardTable<Order>
      data={data}
      columns={COLUMNS(event.currency)}
      loading={loading}
      onSetWide={onSetWide}
      wide={wide}
      downloadData={downloadData}
      downloadFilename={getCsvFilename(event)}
    />
  );
};

export default EventOrdersTable;
