import { ApolloError, useQuery } from '@apollo/client';
import { DemoType } from 'models';
import { EVENT_WITH_EXIST_QUERY } from 'utils/gql';
import {
  EventWithExistQuery,
  EventWithExistQueryVariables,
  EventWithExistQuery_event,
} from 'generated/EventWithExistQuery';
import { localStore } from 'utils/localstore';
import { useEffect } from 'react';
import useEventId from 'components/App/globalstate/useEventId';
import usePasswordEvent from 'components/App/globalstate/usePasswordEvent';
import useUser from 'utils/hooks/user/useUser';

type EventState = {
  event: EventWithExistQuery_event | null;
  eventExists: boolean;
  loading: boolean;
  error: ApolloError | undefined;
  isDemo: boolean;
};

function useEvent(skip = false): EventState {
  const eventId = useEventId();
  const isDemo = eventId === DemoType.Id;
  const shouldSkip = isDemo || !eventId || skip;

  const password = usePasswordEvent();
  const { loading: userLoading, user, error: userError } = useUser();
  const { refetch: refetchEvent, loading: eventLoading, data: eventData, error: eventError } = useQuery<
    EventWithExistQuery,
    EventWithExistQueryVariables
  >(EVENT_WITH_EXIST_QUERY, { variables: { id: eventId!, password }, skip: shouldSkip });

  let error = eventError || userError;
  const loading = userLoading || eventLoading;
  const event = eventData?.event;
  const eventExists = eventData?.eventExists || false;
  const eventNotFoundError = new ApolloError({ errorMessage: 'No event found' });

  useEffect(() => {
    if (!shouldSkip) refetchEvent();
  }, [eventId, refetchEvent, shouldSkip, user]);

  if (isDemo) {
    const localEvent = localStore.demoEvent.get() || null;
    return {
      event: localEvent,
      loading: false,
      eventExists: !!localEvent,
      error: localEvent ? undefined : eventNotFoundError,
      isDemo,
    };
  } else {
    error = error || (!loading && !eventData) ? eventNotFoundError : undefined;
    return { event: event && !loading ? event : null, eventExists, loading, error, isDemo: false };
  }
}

export default useEvent;
