import * as m from 'models';
import InnerTicketingForm from './InnerTicketingForm/InnerTicketingForm';
import MyTicketsInfo from './MyTicketsInfo/MyTicketsInfo';
import React from 'react';
import useEventClass from 'utils/hooks/event/useEventClass';

const isSoldOut = (event: m.Event): boolean => {
  return event.ticketTypes!.every((ticketType) => {
    if (ticketType.capacity === null) {
      return false;
    } else {
      return ticketType.quantityClaimed >= ticketType.capacity;
    }
  });
};

interface Props {
  isRun?: boolean;
}

const TicketingForm = ({ isRun }: Props) => {
  const { event } = useEventClass();

  return (
    <div className="p-6">
      {isSoldOut(event) ? (
        <div className="text-center">Sorry, this event is sold out.</div>
      ) : (
        <>
          <div className="text-3xl">Attend</div>
          <div className="mt-4">
            {isRun ? <MyTicketsInfo /> : null}
            <InnerTicketingForm />
          </div>
        </>
      )}
      {!isRun ? <MyTicketsInfo /> : null}
    </div>
  );
};

export default TicketingForm;
