import * as F from 'shared/shared/Functional';
import { Currency } from 'shared/shared/types';
import { DiscountCode, TicketTypeForPurchase } from 'models';
import { TicketsValue } from 'components/pages/EventReadPage/RsvpForm/TicketingForm/InnerTicketingForm/TicketsWidget/TicketsWidget';
import { calculateTotalDiscount } from 'utils/helpers';
import { isNumber } from 'util';
import { renderDiscountCode, renderDollars } from 'shared/shared/utils';
import React from 'react';
import styles from './OrderSummary.module.scss';

interface Props {
  currency: Currency;
  discountCode?: DiscountCode;
  ticketTypes: TicketTypeForPurchase[];
  tickets: TicketsValue;
}

function getTicketType(id: string, ticketTypes: TicketTypeForPurchase[]): TicketTypeForPurchase | undefined {
  return ticketTypes.find((ticketType) => ticketType.id === id);
}

const Container: React.FC<{}> = (props) => {
  return (
    <div className={styles.OrderSummary}>
      <div className={styles.label}>Order summary</div>

      {props.children}
    </div>
  );
};

const OrderSummary: React.FC<Props> = ({ tickets, ticketTypes, discountCode, currency }) => {
  const hasTickets = F.any(tickets.map(F.prop('quantity')));

  if (!hasTickets) {
    return (
      <Container>
        <div className={styles.none}>No tickets selected</div>
      </Container>
    );
  }

  const hasPrices = tickets.every((ticket) => isNumber(ticket.price));
  if (!hasPrices) {
    return (
      <Container>
        <div className={styles.none}>Please enter contribution amount</div>
      </Container>
    );
  }

  const total = tickets.reduce((acc, ticket) => acc + ticket.price! * ticket.quantity, 0);
  const discount = calculateTotalDiscount(tickets, discountCode);
  const effectiveDiscount = Math.min(discount, total);
  const totalAfterDiscount = Math.max(total - effectiveDiscount, 0);

  return (
    <div className={styles.OrderSummary}>
      <div className={styles.label}>Order summary</div>
      {tickets.map((ticket, index) => {
        if (!ticket.quantity) {
          return null;
        }
        const ticketType = getTicketType(ticket.id, ticketTypes);
        return (
          <div className={styles.lineItem} key={`${ticket.id || index}`}>
            <div>
              <span>{ticket.quantity} &times; </span>
              <span>{ticketType?.name || 'Ticket'}</span>
            </div>
            <span>{renderDollars(ticket.price!, currency)}</span>
          </div>
        );
      })}
      <hr />
      {discountCode && (
        <>
          <div className={styles.lineItem}>
            <div>Subtotal</div>
            <div>{renderDollars(total, currency)}</div>
          </div>
          <div className={styles.lineItem}>
            <div>Discount ({renderDiscountCode(discountCode)})</div>
            <div>-{renderDollars(effectiveDiscount, currency)}</div>
          </div>
          <hr />
        </>
      )}
      <div className={styles.lineItem}>
        <div className={styles.total}>Total</div>
        <div>{renderDollars(totalAfterDiscount, currency)}</div>
      </div>
    </div>
  );
};

export default OrderSummary;
