import * as F from '../Functional';

/* eslint-disable */
const Route = require('route-parser');
// Sharing this library doesn't work with `import` for some reason.
// Unfortunately we lose the typing on `Route`.
type Route = typeof Route;

class Reverser<T extends Record<string, string>> {
  private nameToRoute: Record<keyof T, Route>;

  constructor(routes: T) {
    this.nameToRoute = F.objMapValues(routes, (path) => new Route(path)) as any;
  }

  public reverse = (name: keyof T, params?: Record<string, string>): string => {
    const r = this.nameToRoute[name];

    if (!r) {
      throw new Error('Unknown route name:');
    }

    const path = r.reverse(params || {});
    if (!path) {
      console.error('Could not reverse path', name, params);
      throw new Error('Could not reverse path');
    }
    return path;
  };
}

export default Reverser;
