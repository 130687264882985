import React, { useEffect, useMemo, useRef } from 'react';
import useUserMedia from 'utils/hooks/useUserMedia';

interface Props {
  deviceId: string;
}

const CameraPreview = ({ deviceId }: Props) => {
  const mediaConfig = useMemo(() => ({ video: { deviceId } }), [deviceId]);
  const { stream } = useUserMedia(mediaConfig);
  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    if (stream && videoRef.current) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);
  return (
    <div>
      <video width="160" height="120" className="shadow" autoPlay ref={videoRef}></video>
    </div>
  );
};

export default CameraPreview;
