import { ApolloQueryResult } from '@apollo/client';
import { EVENT_WITH_EXIST_QUERY } from 'utils/gql';
import { EventWithExistQuery } from 'generated/EventWithExistQuery';
import apolloClient from 'api/apolloClient';

const refetchEvent = async (id: string): Promise<ApolloQueryResult<EventWithExistQuery>> => {
  return apolloClient.query<EventWithExistQuery>({
    query: EVENT_WITH_EXIST_QUERY,
    variables: { id },
    fetchPolicy: 'network-only',
  });
};

export default refetchEvent;
