import { useEffect } from 'react';

const useClassOnSelector = (className: string, selector: string) => {
  const elements = document.querySelectorAll(selector);
  useEffect(() => {
    const classes = className.split(/\s+/);
    elements.forEach((el) => el.classList.add(...classes));
    return () => elements.forEach((el) => el.classList.remove(...classes));
  }, [className, elements]);
};

export default useClassOnSelector;
