import { ChatMessage, EmojiReaction } from 'components/VirtualVenue/pubnub/pubnub';
import { useVirtualVenuePubnubDispatch } from 'components/VirtualVenue/contexts/VirtualVenuePubnubContext';
import EmojiPicker from 'components/VirtualVenue/VirtualVenueDisplay/layouts/VirtualVenueDisplayLayout/Chat/ChatContent/ChatMessageDisplay/MessageMenu/EmojiPicker/EmojiPicker';
import EmojiReactionsDisplayInner from 'components/VirtualVenue/VirtualVenueDisplay/layouts/VirtualVenueDisplayLayout/Chat/ChatContent/ChatMessageDisplay/EmojiReactionsDisplay/EmojiReactionsDisplayInner';
import React, { RefObject, useState } from 'react';

interface Props {
  message: ChatMessage;
  anchorEl: RefObject<HTMLDivElement>;
}

const EmojiReactionsDisplayInteractive = ({ message, anchorEl }: Props) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);
  const pubnubDispatch = useVirtualVenuePubnubDispatch();
  const onToggleMyReaction = (emoji: string, myReaction?: EmojiReaction) => {
    pubnubDispatch({
      type: 'send-emoji-reaction',
      emoji: emoji,
      messageTimetoken: message.timetoken,
      operation: myReaction ? 'remove' : 'add',
      actionTimetoken: myReaction?.actionTimetoken,
    });
  };
  const onAddReaction = () => setShowEmojiPicker(true);

  const { reactions = {} } = message.message;
  return (
    <>
      <EmojiReactionsDisplayInner
        reactions={reactions}
        onToggleMyReaction={onToggleMyReaction}
        onAddReaction={onAddReaction}
      />
      <EmojiPicker
        isOpen={showEmojiPicker}
        anchorEl={anchorEl}
        onClose={() => setShowEmojiPicker(false)}
        onSelect={(emoji) => {
          pubnubDispatch({
            type: 'send-emoji-reaction',
            emoji: emoji.id!,
            messageTimetoken: message.timetoken,
            operation: 'add',
          });
          setShowEmojiPicker(false);
        }}
      />
    </>
  );
};
export default EmojiReactionsDisplayInteractive;
