import * as m from 'models';
import { isAddress, isUrl } from 'shared/shared/utils';
import { renderFullDate, renderFullDateWithoutYear, renderTime, renderTimezone } from 'utils/datetime';
import { reverse } from 'shared/shared/routing/mixily-routes';
import moment from 'moment';

interface EventForUrl {
  id?: string;
}

export interface RenderTime {
  startTime: moment.Moment | null;
  endTime: moment.Moment | undefined;
  timezone: string;
  location: string;
}

const DISPLAY_YEAR_CUT_OFF_IN_MONTHS = 3;

export const getEventUrl = ({ id }: EventForUrl) => {
  if (!id) {
    return '/event/create/demo';
  }
  return reverse('event_read', { id });
};

// Determine whether and how timezone should be displayed.
// To avoid confusion, always render timezone for remote events.
const getNormalizedTime = (
  startTime: moment.Moment,
  endTime: moment.Moment | undefined,
  eventTimeZone: string,
  location: string,
  viewerTz: string
) => {
  const isLocAddress = !isUrl(location) && isAddress(location);
  const isDifferentTz = viewerTz !== eventTimeZone;
  const shouldRenderTz = isDifferentTz || !isLocAddress;
  const timezone = isDifferentTz ? (isLocAddress ? eventTimeZone : viewerTz) : viewerTz;
  return {
    start: isLocAddress ? startTime : startTime?.clone().tz(viewerTz),
    end: isLocAddress ? endTime : endTime?.clone().tz(viewerTz),
    timezone: shouldRenderTz ? renderTimezone(timezone) : '',
  };
};

export const renderFullTime = (
  { startTime, endTime, timezone: eventTimeZone, location }: RenderTime,
  viewerTz: string = moment.tz.guess()
): [string, string | null] => {
  // Returns [startStr, endStr], such that if endString is not null,
  // the two strings could be displayed split by an en-dash.

  // todo: shorter days for days this week, e.g. "Friday at 8 pm"
  // todo: don't render full endtime date if it's before, say, 5am
  // todo: is there a style guide for how to display datetime ranges?

  if (!startTime) {
    return ['TBD', null];
  }

  // Timezone
  // For remote events, localize start and end to viewer's timezone.
  const { start, end, timezone } = getNormalizedTime(startTime, endTime, eventTimeZone, location, viewerTz);

  // Start time
  // We don't want to show the year for a particular date if its within DISPLAY_YEAR_CUT_OFF_IN_MONTHS
  const includeStartYear = start.diff(moment(), 'months', true) > DISPLAY_YEAR_CUT_OFF_IN_MONTHS;
  const startDatePart = includeStartYear ? renderFullDate(start) : renderFullDateWithoutYear(start);
  const startTimePart = renderTime(start);
  const startStr = `${startDatePart} at ${startTimePart}`;

  if (!end) {
    return [`${startStr} ${timezone}`, null];
  }

  // End time
  // We always want to show the end date year if its different from
  // the start date year. Even when `endDateMonthsFromNow < DISPLAY_YEAR_CUT_OFF_IN_MONTHS`.
  const includeEndYear = includeStartYear || !start.isSame(end, 'year');
  const endDatePart = includeEndYear ? renderFullDate(end) : renderFullDateWithoutYear(end);
  const endTimePart = renderTime(end);
  const endsOnSameDay = end.isSame(start, 'date');
  const endStr = endsOnSameDay ? `${endTimePart} ${timezone}` : `${endDatePart} at ${endTimePart} ${timezone}`;

  return [startStr.trim(), endStr.trim()];
};

export const areThereSoldTickets = (event: m.Event | undefined): boolean => {
  return !!event?.ticketTypes?.some((tt) => !!tt.quantityClaimed);
};
