import { VideoJsPlayerOptions } from 'video.js';
import React from 'react';
import VideoPlayer from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/CallDisplay/TileDisplay/VideoStreamTile/VideoPlayer';

interface Props {
  url: string;
  options?: VideoJsPlayerOptions;
}

const VideoPlayerTile = ({ url, options }: Props) => {
  const videoJsOptions = {
    autoplay: true,
    controls: options?.controls ?? true,
    loop: options?.loop,
    sources: [
      {
        src: url,
        type: options?.sources?.length ? options.sources[0].type : 'application/x-mpegURL',
      },
    ],
  };
  return (
    <div className="flex items-center w-full h-full align-center">
      <VideoPlayer src={url} options={videoJsOptions} />
    </div>
  );
};

export default VideoPlayerTile;
