// Page for the "Virtual Venue" feature. Needs more customization than the generic FeatureDetailPage.
// Based on https://tailwindui.com/components/marketing/sections/feature-sections#component-12712a7925f8fd86e4f8331cf686d092

import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import FeatureSectionDetail from 'components/pages/FeaturesDetailVenuePage/FeatureSectionDetail/FeatureSectionDetail';
import FeatureSectionTitle from 'components/pages/FeaturesDetailVenuePage/FeatureSectionTitle/FeatureSectionTitle';
import Link from 'components/Link';
import React from 'react';
import UseCaseIcon from 'components/pages/FeaturesDetailVenuePage/UseCaseIcon/UseCaseIcon';
import VirtualVenueCTA from 'components/pages/FeaturesDetailVenuePage/VirtualVenueCTA/VirtualVenueCTA';

const FeaturesDetailVenuePage = () => {
  return (
    <BaseLayout title="Virtual Venue - Sell tickets to livestreams embedded on your own website" hideTitle={true}>
      <div className="py-12 overflow-hidden bg-gray-50 xl:py-24">
        <div className="relative max-w-xl px-4 mx-auto sm:px-6 xl:px-8 xl:max-w-screen-xl">
          <svg
            className="absolute hidden xl:block left-full transform -translate-x-1/2 -translate-y-1/4"
            width="404"
            height="784"
            fill="none"
            viewBox="0 0 404 784"
          >
            <defs>
              <pattern
                id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="404" height="784" fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
          </svg>

          <div className="relative">
            <h3 className="text-3xl font-extrabold tracking-tight text-center text-gray-900 leading-8 sm:text-4xl sm:leading-10 lg:text-5xl">
              Introducing: Virtual Venue
            </h3>
            <p className="max-w-3xl mx-auto mt-4 text-xl text-center text-gray-500 leading-7">
              Sell tickets to a secured livestream. Keep your audience on your own website.
            </p>
          </div>

          <div className="mt-8">
            <VirtualVenueCTA />
          </div>

          <div className="relative mt-12 xl:mt-24 xl:grid xl:grid-cols-2 xl:gap-8 xl:items-center">
            <div className="relative">
              <FeatureSectionTitle>Own your venue</FeatureSectionTitle>
              <p className="mt-3 text-lg text-gray-500 leading-7">
                You worked hard for your audience, so don't send them to an app. With Virtual Venue, the performance
                happens on your own website.
              </p>

              <ul className="mt-10">
                <li>
                  <FeatureSectionDetail title="Sell tickets" iconName="ticket">
                    Zero added fees. Support for promo codes, free tickets, and suggested donation ranges.
                  </FeatureSectionDetail>
                </li>
                <li className="mt-10">
                  <FeatureSectionDetail title="It's your website" iconName="globeAlt">
                    Squarespace? Sure! WordPress? Why not! Virtual Venue is compatible with every website. If you can
                    edit it, you can sell tickets to it.
                  </FeatureSectionDetail>
                </li>
                <li className="mt-10">
                  <FeatureSectionDetail title="Major streaming platforms" iconName="videoCamera">
                    We support Marquee, RTMP, OBS, and popular streaming platforms. If you have a compatibility
                    question, we can help:{' '}
                    <Link href="mailto:sales@mixily.com" newWindow={true} className="text-gray-700 underline">
                      say hi!
                    </Link>
                  </FeatureSectionDetail>
                </li>
              </ul>
            </div>

            <div className="relative mt-10 -mx-4 xl:mt-0">
              <svg
                className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 xl:hidden"
                width="784"
                height="404"
                fill="none"
                viewBox="0 0 784 404"
              >
                <defs>
                  <pattern
                    id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width="784" height="404" fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
              </svg>
              <div className="relative max-w-sm mx-auto">
                <div
                  className="relative mx-auto overflow-hidden bg-white rounded-lg shadow-lg "
                  style={{ paddingTop: '110%' }}
                >
                  <div className="absolute top-0 left-0 w-full h-full">
                    <img
                      alt="Screenshot Example of Virtual Venue"
                      src="https://images.prismic.io/mixily/0a65f3be-9df8-41a3-ae26-f01c17fc13c5_Group+18.png?auto=compress,format"
                    />
                    <div className="px-2 mt-1 text-xs text-right text-cool-gray-400">
                      <span>&copy; Manual Cinema, 2020</span>
                      <div className="relative mt-3">
                        <svg
                          className="absolute top-0 left-0 w-12 h-12 text-indigo-400 opacity-50 transform -translate-y-8"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 144 144"
                        >
                          <path
                            strokeWidth="2"
                            d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
                          />
                        </svg>
                        <div className="mx-2 text-base leading-tight text-center text-gray-900">
                          The fact that we can give our livestream a home on our own website, with our branding, is
                          really exciting for us!
                        </div>
                        <div className="mt-2 text-sm text-center text-cool-gray-500">
                          &ndash;Ben Kauffman,{' '}
                          <Link href="http://manualcinema.com/" newWindow={true} className="text-indigo-500">
                            Manual Cinema
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="p-4"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <svg
            className="absolute hidden xl:block right-full transform translate-x-1/2 translate-y-12"
            width="404"
            height="784"
            fill="none"
            viewBox="0 0 404 784"
          >
            <defs>
              <pattern
                id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="404" height="784" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
          </svg>

          <div className="relative mt-12 sm:mt-16 xl:mt-24">
            <div className="xl:grid xl:grid-flow-row-dense xl:grid-cols-2 xl:gap-8 xl:items-center">
              <div className="xl:col-start-2">
                <FeatureSectionTitle>Interactive experience</FeatureSectionTitle>
                <p className="mt-3 text-lg text-gray-500 leading-7">
                  Quality performances happen in designed spaces. Set the vibe with your fully custom colors, branding,
                  and content.
                </p>

                <ul className="mt-10">
                  <li>
                    <FeatureSectionDetail title="No distractions" iconName="eye">
                      YouTube is riddled with recommendations. Zoom looks bad and feels like work. Instead, design a
                      custom venue the way you already edit your website.
                    </FeatureSectionDetail>
                  </li>
                  <li className="mt-10">
                    <FeatureSectionDetail title="The lights come on" iconName="lightBulb">
                      That feeling after a concert—we look around, and what happens next? There's more to do than close
                      an app. Let your guests mingle, shop, and invite their friends to the next one.
                    </FeatureSectionDetail>
                  </li>
                </ul>
              </div>

              <div className="relative mt-10 -mx-4 xl:mt-0 xl:col-start-1">
                <svg
                  className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 xl:hidden"
                  width="784"
                  height="404"
                  fill="none"
                  viewBox="0 0 784 404"
                >
                  <defs>
                    <pattern
                      id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                      patternUnits="userSpaceOnUse"
                    >
                      <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect width="784" height="404" fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
                </svg>
                <div className="relative max-w-sm mx-auto">
                  <div
                    className="relative mx-auto overflow-hidden rounded-lg shadow-lg bg-cool-gray-600 "
                    style={{ paddingTop: '110%' }}
                  >
                    <div className="absolute top-0 left-0 w-full h-full">
                      <div className="h-full p-4">
                        <div className="h-full text-3xl text-center">
                          <div className="w-full h-full grid grid-cols-1 grid-rows-3">
                            <div className="flex flex-row items-start justify-center">
                              <UseCaseIcon iconName="ticket" label="Theater" className="mr-3 bg-teal-400" />
                              <UseCaseIcon iconName="musicNote" label="Music" className="ml-3 bg-teal-500" />
                            </div>
                            <div className="flex flex-row items-center justify-center">
                              <UseCaseIcon iconName="userGroup" label="Performance" className="mr-3 bg-blue-500" />
                              <UseCaseIcon iconName="mail" label="Exclusives" className="ml-3 bg-blue-400" />
                            </div>
                            <div className="flex flex-row items-end justify-center">
                              <UseCaseIcon iconName="bookOpen" label="Readings" className="mr-3 bg-indigo-400" />
                              <UseCaseIcon iconName="pencilAlt" label="Classes" className="ml-3 bg-indigo-500" />
                            </div>
                          </div>

                          {/* <div className="text-pink-500">Music</div>
                          <div className="text-red-500">Theater</div>
                          <div className="text-yellow-500">Member events</div>
                          <div className="text-green-500">Video premieres</div>
                          <div className="text-teal-500">Classes</div>
                          <div className="text-blue-500">Book readings</div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VirtualVenueCTA />
    </BaseLayout>
  );
};

export default FeaturesDetailVenuePage;
