import * as m from 'models';
import { EventGuestList_event_inviteSet } from 'generated/EventGuestList';
import { GuestFilter } from 'components/EventGuestList/types';
import { IconName } from 'components/common/Icon/Icon';
import { RsvpStates } from 'generated/globalTypes';
import { isString } from 'util';

type InviteFromEvent = Omit<EventGuestList_event_inviteSet, '__typename'>;
export type Invite = Partial<InviteFromEvent> & { id: string };

export type InvitesByStateT = { [stateName in RsvpStates]: Invite[] };

export type InvitesByFilterT = { [filterName in GuestFilter]: Invite[] };

export type InviteCountsByStateT = { [stateName in RsvpStates]: number };

export type RsvpResponse = RsvpStates.y | RsvpStates.m | RsvpStates.n;

export type MaybeRsvpResponse = RsvpResponse | undefined;

export const makeInvitesByState = (invites: Invite[]): InvitesByStateT => {
  const map = {} as InvitesByStateT;
  for (const state of Object.values(RsvpStates)) {
    map[state] = [];
  }
  for (const i of invites) {
    map[i.state!].push(i);
  }
  return map;
};

export const getInviteCountsByState = (invites: Invite[]): InviteCountsByStateT => {
  const map = {} as InviteCountsByStateT;
  Object.values(RsvpStates).forEach((state) => (map[state] = 0));
  invites.forEach((i) => (map[i.state!] += 1 + (i.rsvpPlusN || 0)));
  return map;
};

function rsvpStatesForFilter(filter: GuestFilter) {
  return Object.values(RsvpStates).filter((s) => rsvpStateMatchesFilter(s, filter));
}

function rsvpStateMatchesFilter(state: RsvpStates, filter: GuestFilter) {
  if (filter === GuestFilter.All) {
    return state !== RsvpStates.n;
  }
  if (filter === GuestFilter.Invited) {
    return state === RsvpStates.s || state === RsvpStates.i;
  }
  if (filter === GuestFilter.Going) {
    return state === RsvpStates.y;
  }
  if (filter === GuestFilter.Maybe) {
    return state === RsvpStates.m;
  }
}

export const makeInvitesByFilter = (invitesByState: InvitesByStateT): InvitesByFilterT => {
  const result = {} as InvitesByFilterT;
  for (const filter of Object.values(GuestFilter)) {
    result[filter] = [];
    const states = rsvpStatesForFilter(filter);
    for (const s of states) {
      result[filter]!.push(...invitesByState[s]);
    }
  }
  return result;
};

export const renderState = (state: RsvpStates | undefined) => {
  if (!isString(state)) {
    throw new Error('renderState called on invite with missing state');
  }
  return {
    y: 'Going',
    n: "Can't Go",
    m: 'Maybe',
    i: 'Invited',
    s: 'Seen',
  }[state];
};

export const renderName = (invite: Invite) => {
  const { originalName, rsvpName, rsvpEmail, originalEmail, state, person } = invite;
  const isInvitedOrSeen = state === RsvpStates.i || state === RsvpStates.s;

  if (isInvitedOrSeen && !!originalName) {
    return originalName;
  }

  // If there's no email, they must be the host
  return rsvpName || (person && person.name) || originalName || rsvpEmail || originalEmail || 'Host';
};

export const getName = (invite: Invite | undefined) => {
  return invite?.rsvpName || invite?.originalName || '';
};

export const getEmail = (invite: Invite | undefined) => {
  return invite?.rsvpEmail || invite?.originalEmail || '';
};

export function isRsvpResponse(state: RsvpStates): state is RsvpResponse {
  return [RsvpStates.y, RsvpStates.m, RsvpStates.n].includes(state);
}

export const toRsvpResponse = (str?: string): MaybeRsvpResponse => {
  if (str && isRsvpResponse(str as RsvpResponse)) {
    return str as MaybeRsvpResponse;
  }
};

export const iconStateMap: Record<RsvpStates, IconName> = {
  [RsvpStates.y]: 'check',
  [RsvpStates.m]: 'questionMark',
  [RsvpStates.n]: 'no',
  [RsvpStates.i]: 'envelope',
  [RsvpStates.s]: 'envelope', // todo: open envelope
};

export const sortedInvitesForRsvpState = (invites: Invite[]) =>
  // Sorts alphabetically by string returned from `renderName()`
  invites.sort((a, b) => renderName(a).toLowerCase().localeCompare(renderName(b).toLowerCase()));

export function isOwnerInvite(invite: Invite, event?: m.Event): boolean {
  return !!event && !!event.owner && !!invite.person && event.owner!.id === invite.person!.id;
}

export function isHostInvite(invite: Invite, event?: m.Event): boolean {
  return isOwnerInvite(invite, event) || invite.affiliation === 'h' || !!invite.isHost;
}

export function sortInvitesHostsFirst(event?: m.Event) {
  return (inviteA: Invite, inviteB: Invite) => {
    if (isOwnerInvite(inviteA, event)) {
      return -1;
    } else if (isOwnerInvite(inviteB, event)) {
      return 1;
    } else if (isHostInvite(inviteA, event) && !isHostInvite(inviteB, event)) {
      return -1;
    } else if (isHostInvite(inviteB, event) && !isHostInvite(inviteA, event)) {
      return 1;
    } else {
      return 0;
    }
  };
}
