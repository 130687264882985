import { AsyncSubmitButtonProps2 } from 'components/forms/types';
import { useFormStateContext } from 'components/forms/FormStateContext';
import Button2 from 'components/common/Button2/Button2';
import React from 'react';

function AsyncSubmitButton<T extends Record<string, any> = any>(props: AsyncSubmitButtonProps2) {
  const { formContext, onSubmit, isSubmitting } = useFormStateContext<T>();
  const onClick = formContext.handleSubmit(onSubmit);

  return (
    <Button2
      {...props}
      submit={true}
      color={props.color ?? 'primary'}
      disabled={props.disabled ?? isSubmitting}
      onClick={props.onClick ?? onClick}
    >
      {isSubmitting ? props.submittingLabel : props.label}
    </Button2>
  );
}

export default AsyncSubmitButton;
