import BaseAuthForm, { AuthCallback } from 'components/auth-forms/BaseAuthForm';
import React from 'react';

interface Props {
  email?: string;
  password?: string;
  next?: string;
  message?: string;
  name?: string;
  signupHeader?: string;
  hideFooter?: boolean;
  loginHeader?: string;
  onNavigate?: AuthCallback;
  onRequestLoginFormModal?: AuthCallback;
  onRequestSignupFormModal?: AuthCallback;
  onSuccessfulSignup?: () => void;
}

const LoginForm = (props: Props) => <BaseAuthForm header={props.loginHeader ?? 'Log in'} form="login" {...props} />;
const SignupForm = (props: Props) => <BaseAuthForm header={props.signupHeader ?? 'Sign up'} form="signup" {...props} />;

export { LoginForm, SignupForm };
