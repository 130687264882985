import { ColorNames, ColorOptions, VirtualVenueThemeOption } from '../../../VirtualVenue/types';
import { FormControlLabel, RadioGroup } from '@material-ui/core';
import { defaultTheme, useVirtualVenueTheme } from 'components/VirtualVenue/contexts/VirtualVenueThemeContext';
import ColorRadioIcon from 'components/VirtualVenueThemeEditor/ThemePanel/ColorRadioIcon';
import React from 'react';
import classNames from 'classnames';
import styles from './ThemeColorView.module.scss';

interface Props {
  option: VirtualVenueThemeOption;
  onSelect: (v: VirtualVenueThemeOption) => unknown;
}

function ThemeColorView({ onSelect, option }: Props) {
  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    onSelect(value as VirtualVenueThemeOption);
  };

  const theme = useVirtualVenueTheme();

  return (
    <div className="w-full">
      <RadioGroup name="type" value={option} onChange={handleOptionChange}>
        {ColorNames.map((fieldName) => (
          <div key={fieldName} className="w-full ml-2">
            <FormControlLabel
              value={fieldName}
              className={classNames(
                'block box-border pr-2 text-black rounded-full border border-solid transition-border-color ml-2 w-full',
                {
                  'border-gray-900': fieldName === option,
                  'border-white': fieldName !== option,
                }
              )}
              label={<div className={styles.Label}>{ColorOptions[fieldName]}</div>}
              control={<ColorRadioIcon colorValue={theme[fieldName] || defaultTheme[fieldName]} />}
            />
          </div>
        ))}
      </RadioGroup>
    </div>
  );
}

export default React.memo(ThemeColorView);
