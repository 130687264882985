import { localStore } from 'utils/localstore';
import React from 'react';

//
// State
//

export type PreferredView = 'grid' | 'speaker';

export type JoinState = 'idle' | 'joined' | 'left';

type CallConfigContextT = {
  // Whether user sees their own participant window
  showSelf: boolean;

  // Whether participant names are always visible or only on hover
  alwaysShowNames: boolean;

  // Speaker or Grid view
  preferredView: PreferredView;

  // Where the user *wants* to be, even if (e.g.) the call isn't quite ready yet
  desiredJoinState: JoinState;

  // Whether the call view has been set up as spotlight mode
  isSpotlightMode: boolean;
};

const defaultCallConfig = {
  showSelf: true,
  alwaysShowNames: false,
  desiredJoinState: 'idle',
  isSpotlightMode: false,
} as const;

//
// Actions
//

type ToggleShowSelf = { type: 'toggle-show-self' };
type ToggleAlwaysShowNames = { type: 'toggle-always-show-names' };
type SetPreferredView = { type: 'set-preferred-view'; view: PreferredView };
type SetIsSpotlightMode = { type: 'set-is-spotlight-mode'; isSpotightMode: boolean };
type SetDesiredJoinState = { type: 'set-desired-join-state'; joinState: JoinState };
export type CallConfigAction =
  | ToggleShowSelf
  | ToggleAlwaysShowNames
  | SetPreferredView
  | SetDesiredJoinState
  | SetIsSpotlightMode;

//
// Reducer
//

const callConfigReducer = (callConfig: CallConfigContextT, action: CallConfigAction): CallConfigContextT => {
  switch (action.type) {
    case 'toggle-show-self':
      return {
        ...callConfig,
        showSelf: !callConfig.showSelf,
      };
    case 'toggle-always-show-names':
      return {
        ...callConfig,
        alwaysShowNames: !callConfig.alwaysShowNames,
      };
    case 'set-preferred-view':
      return {
        ...callConfig,
        preferredView: action.view,
      };
    case 'set-is-spotlight-mode':
      return {
        ...callConfig,
        isSpotlightMode: action.isSpotightMode,
      };
    case 'set-desired-join-state':
      return {
        ...callConfig,
        desiredJoinState: action.joinState,
      };
    default:
      throw new Error();
  }
};

//
// Contexts
//

const CallConfigContext = React.createContext<CallConfigContextT | undefined>(undefined);

function useCallConfig(): CallConfigContextT;
function useCallConfig(optional: true): CallConfigContextT | undefined;
function useCallConfig(optional?: boolean): CallConfigContextT | undefined {
  const context = React.useContext(CallConfigContext);
  if (!optional && context === undefined) {
    throw new Error('useCallConfig with optional:false must be used within a CallConfigProvider');
  }
  return context;
}

type CallConfigDispatchContextT = React.Dispatch<CallConfigAction>;

const CallConfigDispatchContext = React.createContext<CallConfigDispatchContextT | undefined>(undefined);

function useCallConfigDispatch(): CallConfigDispatchContextT;
function useCallConfigDispatch(optional: true): CallConfigDispatchContextT | undefined;
function useCallConfigDispatch(optional?: boolean): CallConfigDispatchContextT | undefined {
  const context = React.useContext(CallConfigDispatchContext);
  if (!optional && context === undefined) {
    throw new Error('useCallConfigDispatch with optional:false must be used within a CallConfigProvider');
  }
  return context;
}

//
// Provider
//

type CallConfigProviderProps = {
  initialTheme?: Partial<CallConfigContextT>;
  children: React.ReactNode;
};

function CallConfigProvider({ children, initialTheme }: CallConfigProviderProps) {
  const combinedConfig: CallConfigContextT = {
    ...defaultCallConfig,
    preferredView: localStore.venueViewPreference.get() || 'grid',
    ...initialTheme,
  };

  const [state, dispatch] = React.useReducer(callConfigReducer, combinedConfig);

  return (
    <CallConfigContext.Provider value={state}>
      <CallConfigDispatchContext.Provider value={dispatch}>{children}</CallConfigDispatchContext.Provider>
    </CallConfigContext.Provider>
  );
}

export { useCallConfig, useCallConfigDispatch, CallConfigProvider };
