import { Contact, ContactList, PersonEvent, SelectOption } from './types';
import validator from 'validator';

interface QueryResult {
  events: PersonEvent[];
  contacts: Contact[];
  contactLists: ContactList[];
}

export const isInvalidEmailInvitee = (i: Contact): boolean => {
  return !validator.isEmail(i.email, { allow_display_name: true });
};

export const emailInInviteeList = (email: string, invitees: Contact[]): boolean => {
  for (let invitee of invitees) {
    if (invitee.email === email) {
      return true;
    }
  }
  return false;
};

export const isValidNewOption = (inputValue: string) => {
  // Returning `false` to disable the 'Create new' drop down menu item
  // because users found it to be confusing/pointless
  // https://github.com/JedWatson/react-select/issues/1543#issuecomment-358740650
  return false; // validator.isEmail(inputValue, { allow_display_name: true });
};

export const contactToSelectOption = (c: Contact): SelectOption => {
  return {
    data: {
      ...c,
    },
    typename: 'ContactType',
    label: c.name ? c.name + ' - ' + c.email : c.email,
    value: `Contact:${c.email}`,
  };
};

export const contactListToSelectOption = (l: ContactList): SelectOption => {
  return {
    data: {
      ...l,
    },
    typename: 'ContactListType',
    label: `${l.name} (${l.totalContacts} contacts)`,
    value: `ContactList:${l.id}`,
  };
};

export const eventToSelectOption = (event: PersonEvent): SelectOption => {
  return {
    data: {
      ...event,
    },
    typename: 'EventType',
    // subtract 1 to remove the host
    label: `${event.title} (${event.numInvites! - 1} invitees)`,
    value: `Event:${event.id}`,
  };
};

export const queryResultToSelectOptions = (result: QueryResult): SelectOption[] => {
  return [
    ...result.events.map(eventToSelectOption),
    ...result.contactLists.map(contactListToSelectOption),
    ...result.contacts.map(contactToSelectOption),
  ];
};
