import { GridLayoutConfig, getLayoutForCols, getNumCols } from 'shared/shared/utils';
import { useMemo } from 'react';

const MARGIN = 4; // px
const ASPECT_RATIO = 16 / 9;

const useGridLayout = (numTiles: number, containerWidth: number, containerHeight: number): GridLayoutConfig => {
  const layout = useMemo(
    () => getLayoutForCols(numTiles, containerWidth, containerHeight, getNumCols(numTiles), MARGIN, ASPECT_RATIO),
    [containerHeight, containerWidth, numTiles]
  );
  return layout;
};

export default useGridLayout;
