import { Button, Link, Loading } from 'components/common';
import { EVENTS_FOR_PERSON_QUERY } from 'utils/gql';
import { EventsForPerson_me_upcoming as EventHosting, EventsForPerson } from 'generated/EventsForPerson';
import { reverse } from 'router';
import { useQuery } from '@apollo/client';

import AddIcon from '@material-ui/icons/Add';
import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import EventCalendarPageEventList from 'components/pages/EventCalendarPage/EventCalendarPageEventList';
import LoginRequired from 'components/LoginRequired';
import MuiButton from '@material-ui/core/Button';
import React, { useCallback, useEffect } from 'react';
import Tab from 'components/common/Tab/Tab';
import styles from './EventCalendarPage.module.scss';
import useUser from 'utils/hooks/user/useUser';

export interface EventCalendarListItem extends EventHosting {
  personIsHost?: boolean;
}

const EventListFooter = () => (
  <div className={styles.NoEventsFooter}>
    <div className={styles.footerLabel}>No events planned. Go ahead and create a new event.</div>
    <Button color="primary" label="Create an Event" href={reverse('event_create')} />
  </div>
);

const EventCalendarPage = () => {
  const { loading, data, refetch } = useQuery<EventsForPerson>(EVENTS_FOR_PERSON_QUERY);
  const refetchEvent = useCallback(() => refetch(), [refetch]);
  const me = data?.me;
  const { isEmailConfirmed } = useUser();

  useEffect(() => {
    refetchEvent();
  }, [me, refetchEvent]);

  const pastEventList = (me?.past ?? []).map((event) => ({
    ...event,
    personIsHost: event.owner.id === me?.id,
  }));

  const upcomingEventList = (me?.upcoming ?? []).map((event) => ({
    ...event,
    personIsHost: event.owner.id === me?.id,
  }));

  const eventsDidLoad = Boolean(!loading && data);

  return (
    <LoginRequired>
      <BaseLayout title="Calendar" hideTitle={true}>
        {!isEmailConfirmed && (
          <div className="p-4 my-5 text-black bg-orange-200 border-l-4 border-orange-500" role="alert">
            <p className="font-bold">Please confirm your email address</p>
            <p>
              To create events, you should confirm your email address. Click{' '}
              <Link href={reverse('resend_confirmation')}>here</Link> to resend your confirmation email.
            </p>
          </div>
        )}
        <Tab
          panes={[
            {
              title: 'Upcoming',
              content: () => (
                <div>
                  {!eventsDidLoad && <Loading />}
                  {upcomingEventList.length > 0 && <EventCalendarPageEventList eventItems={upcomingEventList} />}
                  {upcomingEventList.length === 0 && eventsDidLoad && <EventListFooter />}
                </div>
              ),
            },
            {
              title: 'Past',
              content: () => (
                <div>
                  {!eventsDidLoad && <Loading />}
                  {pastEventList?.length > 0 && (
                    <EventCalendarPageEventList eventItems={pastEventList} sortByTime={false} />
                  )}
                  {pastEventList?.length === 0 && (
                    <div className={styles.NoEventsFooter}>
                      <div className={styles.footerLabel}>No past events.</div>
                    </div>
                  )}
                </div>
              ),
            },
          ]}
          action={
            <MuiButton variant="contained" color="secondary" startIcon={<AddIcon />} href={reverse('event_create')}>
              <span className={styles.shortLabel}>New</span>
              <span className={styles.longLabel}>Create Event</span>
            </MuiButton>
          }
        />
      </BaseLayout>
    </LoginRequired>
  );
};

export default EventCalendarPage;
