import * as F from 'shared/shared/Functional';
import { CHANGE_PASSWORD_MUTATION } from 'utils/gql';
import { ChangePassword, ChangePasswordVariables } from 'generated/ChangePassword';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';

import FieldLayout from 'components/common/FieldLayout/FieldLayout';
import Input2 from 'components/common/Input2/Input2';
import React from 'react';
import useMutationFormHelpers from 'components/forms/useMutationFormHelpers';

type FormT = {
  password: string;
};

const defaultValues = {
  password: '',
};

const ChangePasswordForm = () => {
  const [doUpdate] = useMutation<ChangePassword, ChangePasswordVariables>(CHANGE_PASSWORD_MUTATION);
  const formContext = useForm<FormT>({ defaultValues });
  const formHelpers = useMutationFormHelpers<FormT, ChangePassword, ChangePasswordVariables>({
    formContext,
    formToVariables: F.identity,
    mutation: doUpdate,
    resultKey: 'emailAuthUpdate',
    successMessage: 'Password changed!',
    onSuccess: () => formContext.reset(),
  });
  const { FormStateContextProvider, Form, SubmitButton, FormLevelMessages, isSubmitting, onSubmit } = formHelpers;
  const { register, errors } = formContext;

  return (
    <FormStateContextProvider onSubmit={onSubmit} isSubmitting={isSubmitting} formContext={formContext}>
      <Form>
        <div className="flex max-w-2xl">
          <FieldLayout label="Password" error={errors.password} className="w-full">
            <Input2
              name="password"
              type="password"
              ref={register({ required: true })}
              disabled={isSubmitting}
              placeholder="Type in new password"
              size="lg"
            />
          </FieldLayout>
          <SubmitButton label="Update" submittingLabel="Updating..." size="14" className="w-auto mt-5 ml-2" />
        </div>
        <FormLevelMessages className="inline-block mt-4" />
      </Form>
    </FormStateContextProvider>
  );
};

export default ChangePasswordForm;
