import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from 'components/common/Pill.module.scss';

interface PillProps {
  content?: string;
  className?: string;
  onClick?: () => any;
  children?: ReactNode;
}

const Pill = (props: PillProps) => {
  // Using `pointer-events-none` to let users click day-of-week to open date picker in DateTimeWidget
  const cls = classNames(styles.Pill, props.onClick ? 'cursor-pointer' : 'pointer-events-none', props.className);
  return (
    <div onClick={props.onClick} className={cls}>
      {props.content || props.children}
    </div>
  );
};

export default Pill;
