import { MESSAGE_GUEST_MUTATION, OUTGOING_MESSAGES_QUERY } from 'utils/gql';
import { MessageGuest, MessageGuestVariables } from 'generated/MessageGuest';
import { useMutation } from '@apollo/client';

function useMessageGuestMutation(eventId: string) {
  const [doMessageGuests] = useMutation<MessageGuest, MessageGuestVariables>(MESSAGE_GUEST_MUTATION, {
    refetchQueries: [{ query: OUTGOING_MESSAGES_QUERY, variables: { eventId } }],
  });

  return [doMessageGuests];
}

export default useMessageGuestMutation;
