import { Button } from 'components/common';
import { Controller, useForm } from 'react-hook-form';
import { DEFAULT_IMAGE_URL, LABEL_CLASS } from 'components/EventCreateUpdateForm/EventCreateUpdateForm/constants';
import { FIELDS } from 'shared/shared/json';
import { LOADING_INDICATOR_CLASS } from 'utils/constants';
import { Listing_listing } from 'generated/Listing';
import { UPDATE_LISTING_MUTATION } from 'utils/gql';
import { URLContext } from 'components/RoutingProvider';
import { UpdateListing, UpdateListingVariables } from 'generated/UpdateListing';
import { pick } from 'shared/shared/Functional';
import { reverse } from 'shared/shared/routing/mixily-routes';
import { useMutation } from '@apollo/client';
import FieldLayout from 'components/common/FieldLayout/FieldLayout';
import ListingEventsWidget from 'components/pages/ListingEditPage/ListingEventsWidget';
import QuillRichTextWidget from 'components/widgets/QuillRichTextWidget/QuillRichTextWidget';
import React, { useContext } from 'react';
import ThemePicWidget from 'components/EventCreateUpdateForm/ThemePicWidget/ThemePicWidget';
import TitleWidget from 'components/EventCreateUpdateForm/TitleWidget/TitleWidget';
import useMutationFormHelpers from 'components/forms/useMutationFormHelpers';

interface Props {
  listing: Listing_listing;
}

const ListingUpdateForm = ({ listing }: Props) => {
  const [doUpdate] = useMutation<UpdateListing, UpdateListingVariables>(UPDATE_LISTING_MUTATION);
  const { setPath } = useContext(URLContext);

  const upcomingEvents = listing.listingEvents.filter((listingEvent) => !listingEvent.event.isPast);

  const defaultValues: Omit<UpdateListingVariables, 'id'> = {
    description: listing.description || '',
    listingEvents: upcomingEvents,
    ...pick(listing, 'title', 'themePic'),
  };

  const formContext = useForm<UpdateListingVariables>({ defaultValues });
  const { control, errors, watch } = formContext;
  const {
    Form,
    SubmitButton,
    FormLevelMessages,
    FormStateContextProvider,
    isSubmitting,
    onSubmit,
  } = useMutationFormHelpers<UpdateListingVariables, UpdateListing, UpdateListingVariables>({
    formContext,
    mutation: doUpdate,
    resultKey: 'updateListing',
    formToVariables: (formData) => ({
      ...formData,
      listingEvents: formData.listingEvents.map((listingEvent: any) => pick(listingEvent, 'id', 'eventId', 'position')),
      id: listing.id,
    }),
    onSuccess: () => {
      setPath(reverse('listing_read', { id: listing.id }));
    },
  });

  return (
    <FormStateContextProvider onSubmit={onSubmit} isSubmitting={isSubmitting} formContext={formContext}>
      <Form className="max-w-3xl mx-auto mt-4 sm:mt-8">
        <FieldLayout error={errors.themePic}>
          <Controller
            as={ThemePicWidget}
            name="themePic"
            control={control}
            onChange={([e]) => e}
            disabled={isSubmitting}
            attrs={{
              title: watch('title') || '',
              defaultImageUrl: watch('themePic') || DEFAULT_IMAGE_URL,
            }}
            value={(undefined as unknown) as any}
          />
        </FieldLayout>

        <FieldLayout error={errors.title} className="mt-6">
          <Controller
            as={TitleWidget}
            name="title"
            control={control}
            rules={{
              validate: (value) => (value.length ? true : 'This field is required'),
            }}
            onChange={([e]) => e}
            disabled={isSubmitting}
            attrs={{
              placeholder: 'Add Event Title',
              maxLength: FIELDS.title.maxLength,
              spellCheck: false,
            }}
            value={(undefined as unknown) as any}
          />
        </FieldLayout>

        <FieldLayout label={<div className={LABEL_CLASS}>Details</div>} error={errors.description} className="mt-6">
          <Controller
            as={QuillRichTextWidget}
            name="description"
            control={control}
            rules={{
              validate: (value) =>
                value.includes(LOADING_INDICATOR_CLASS) ? 'Please wait for all images to finish uploading.' : true,
            }}
            onChange={([e]) => e}
            disabled={isSubmitting}
            attrs={{
              placeholder: 'e.g. organization details',
            }}
            value={(undefined as unknown) as any}
          />
        </FieldLayout>

        <FieldLayout label={<div className={LABEL_CLASS}>Upcoming Events</div>} className="mt-6">
          <Controller
            as={ListingEventsWidget}
            name="listingEvents"
            control={control}
            onChange={([e]) => e}
            disabled={isSubmitting}
            className="mt-10"
            value={(undefined as unknown) as any}
          />
        </FieldLayout>

        <div className="mt-6">
          <FormLevelMessages />
        </div>
        <div className="flex justify-between mx-4 mt-12 sm:mx-0">
          <Button
            label="Cancel"
            color="secondary"
            handleClick={() => {
              setPath(reverse('listing_read', { id: listing.id }));
            }}
          />
          <SubmitButton label="Save changes" submittingLabel="Saving..." size="14" className="w-auto" />
        </div>
      </Form>
    </FormStateContextProvider>
  );
};

export default ListingUpdateForm;
