// N.B. It's important that email login links result in a 200 rather than a redirect.
//
// First of all, some corporate email servers rewrite email links to internal wrapper
// services, which can pre-emptively switch to redirected URLs before rewriting.
//
// Second, some email clients (Chrome on iOS) have bugs around setting cookies
// when redirects are involved -- maybe in particular when there are multiple
// redirects, or between domains. Specifically, the cookies don't get saved.

import { AccountTokenLogin, AccountTokenLoginVariables } from 'generated/AccountTokenLogin';
import { Loading } from 'components/common';
import { TOKEN_LOGIN_MUTATION } from 'utils/gql';
import { reverse } from 'router';
import { useMutation } from '@apollo/client';

import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import React, { useEffect } from 'react';
import useUserChangeContext from 'components/App/useUserChangeContext';

// todo: only show login message when we weren't already logged in as that person
// todo: Confirm with user in the case that they're already signed in as someone else.
// todo: add links for 'refresh' and 'get new token' suggestions

interface Props {
  token: string;
  next?: string;
}

export const AccountTokenLoginView = (props: Props) => {
  const { token } = props;
  const [doTokenLogin, { data, error, loading }] = useMutation<AccountTokenLogin, AccountTokenLoginVariables>(
    TOKEN_LOGIN_MUTATION
  );
  const { login } = useUserChangeContext();

  useEffect(() => {
    doTokenLogin({ variables: { token } });
    // Disabled until we fix re-rendering problems higher up in the tree
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const errorResult = (
    <b>Sorry, something went wrong. This login link may be expired or invalid. Please request a new sign-in link.</b>
  );

  if (loading) {
    return <Loading />;
  }

  if (error || !data || !data.accountTokenLogin) {
    return errorResult;
  }

  const { ok, apitoken } = data.accountTokenLogin;

  if (!ok) {
    return errorResult;
  }

  if (!apitoken) {
    throw new Error('apitoken required');
  }

  const next = props.next || reverse('home');
  login(apitoken, true, next);

  return (
    <div>
      <b>Logging in...</b>
    </div>
  );
};

const AccountTokenLoginPage = (props: Props) => {
  return (
    <BaseLayout title="">
      <AccountTokenLoginView {...props} />
    </BaseLayout>
  );
};

export default AccountTokenLoginPage;
