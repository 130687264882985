import * as F from 'shared/shared/Functional';
import { Currency } from 'shared/shared/types';
import { EventOrderSummary, RawTicket, TicketsSummary } from 'components/EventGuestList/TicketedGuestList/types';
import { Link, TextLink } from 'components/common';
import { makeInvitesByFilter, makeInvitesByState } from 'models/invite';
import { renderDollars } from 'shared/shared/utils';
import { reverse } from 'shared/shared/routing/mixily-routes';
import DashboardIcon from '@material-ui/icons/AssignmentOutlined';
import IconWithText from 'components/common/IconWithText/IconWithText';
import MessageCenter from 'components/MessageCenter/MessageCenter';
import React, { useState } from 'react';
import TicketInfoDialog from './TicketInfoDialog';
import TicketList from './TicketList';
import classNames from 'classnames';
import styles from 'components/EventGuestList/EventGuestListView/EventGuestListView.module.scss';
import useEventPermissions from 'utils/hooks/event/useEventPermissions';

interface Props {
  currency: Currency;
  eventId: string;
  rawTicketData: RawTicket[];
}

const orderCmp = (a: RawTicket[], b: RawTicket[]): number => {
  // Sort by guest list name
  const aName = a[0].order.guestListName.toLowerCase();
  const bName = b[0].order.guestListName.toLowerCase();
  return aName.localeCompare(bName);
};

const summarizeTickets = (rawTicketData: RawTicket[]): TicketsSummary => {
  const ticketsByOrder = F.groupBy<RawTicket>(rawTicketData, (t: RawTicket) => t.order.id);
  const eventOrders = Object.values(ticketsByOrder);
  eventOrders.sort(orderCmp);
  let totalNumTickets = 0;
  let totalAmount = 0;
  const orderSummaries: EventOrderSummary[] = [];
  for (const order of eventOrders) {
    let count = 0;
    let amount = 0;
    for (const ticket of order) {
      count++;
      amount += ticket.cost;
    }
    totalNumTickets += count;
    totalAmount += amount;
    orderSummaries.push({
      orderId: order[0].order.id,
      name: order[0].order.guestListName,
      email: order[0].order.email!,
      phone: order[0].order.guestListPhone ? order[0].order.guestListPhone : undefined,
      count,
      amount,
    });
  }
  return {
    totalNumTickets,
    orderSummaries,
    totalAmount,
  };
};

const allFixedPrice = (rawTickets: RawTicket[]) => {
  return rawTickets.every((rt) => !!rt.ticketType.fixedPriceCents);
};

const EventTicketListView: React.FC<Props> = (props) => {
  const { permissions } = useEventPermissions();
  const [open, setOpen] = useState<boolean>(false);
  const { rawTicketData, currency } = props;
  const { totalNumTickets, orderSummaries, totalAmount } = summarizeTickets(rawTicketData);
  const invitesByState = makeInvitesByState([]);
  const cls = classNames(styles.EventTicketListView);

  const hasTickets = totalNumTickets > 0;

  return (
    <div className={cls}>
      <div className={styles.GuestListHeading}>Guest List</div>

      {!hasTickets && <div className="mt-4 mb-8">No tickets sold yet</div>}

      {hasTickets && (
        <>
          <div className="mt-2 text-gray-800 label--overline">{totalNumTickets} attending</div>
          <div className="mt-6">
            <TicketList ticketsByName={orderSummaries} currency={currency} />
          </div>
        </>
      )}

      {permissions.canUpdate && (
        // {/* FIXME hack for now. Need to enforce data permissions server-side. */}
        <>
          {!hasTickets && (
            <>
              <hr className={'thin-divider'} />
              <div className="mt-4 text-lg color-gray-8">
                Total {allFixedPrice(rawTicketData) ? 'sales' : 'contributions'}: {renderDollars(totalAmount, currency)}
              </div>

              {permissions.canMessageGuests && (
                <div className="mt-4">
                  <MessageCenter invitesByFilter={makeInvitesByFilter(invitesByState)} totalGuests={totalNumTickets} />
                </div>
              )}
            </>
          )}
          {hasTickets && (
            <>
              <hr className={'thin-divider'} />
              <div className="mt-4 text-lg text-gray-600">
                Total {allFixedPrice(rawTicketData) ? 'sales' : 'contributions'}: {renderDollars(totalAmount, currency)}
              </div>
              {permissions.canMessageGuests && (
                <div className="mt-4">
                  <MessageCenter invitesByFilter={makeInvitesByFilter(invitesByState)} totalGuests={totalNumTickets} />
                </div>
              )}
              <div className="mt-4">
                <TextLink onClick={() => setOpen(true)}>
                  <IconWithText icon="emailList" size="small">
                    Guest emails
                  </IconWithText>
                </TextLink>
                <TicketInfoDialog onClose={() => setOpen(false)} isOpen={open} orderSummaries={orderSummaries} />
              </div>
              <div className="mt-4">
                <Link href={reverse('dashboard_event', { id: props.eventId })}>
                  <DashboardIcon color="primary" className="mr-3 -mt-1 -ml-1 opacity-75" />
                  Event Dashboard
                </Link>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default EventTicketListView;
