import { ProcessingStates } from 'generated/globalTypes';
import { Skeleton } from '@material-ui/lab';
import { VirtualVenueRecordingsWithVenue_virtualVenueRecordings } from 'generated/VirtualVenueRecordingsWithVenue';
import { dailyAPI } from 'utils/api';
import { durationToString } from 'components/pages/VenuePage/TimeTil/TimeTil';
import { reverse } from 'shared/shared/routing/mixily-routes';
import { useAsync } from 'utils/hooks/useAsync';
import Link from 'components/Link';
import React from 'react';
import VirtualVenueRecordingOptions from 'components/pages/VirtualVenueRecordingsPage/VirtualVenueRecordingOptions';
import clsx from 'classnames';
import config from 'config';
import moment from 'moment';

export type DailyRecording = {
  id: string;
  room_name: string;
  start_ts: number;
  status: 'finished' | 'in-progress';
  max_participants: number;
  duration: number;
  share_token: string;
  tracks: Array<any>;
};

type DailyGetRecordingsSuccess = {
  totalCount: number;
  data: DailyRecording[];
  status: undefined;
};

type DailyGetRecordingsFailure = {
  status: 403;
};

type DailyGetRecordingsResponse = DailyGetRecordingsSuccess | DailyGetRecordingsFailure;

const isRecordingsSuccess = (data?: DailyGetRecordingsResponse | null): data is DailyGetRecordingsSuccess => {
  return !!(data && !data.status && data.data);
};

interface Props {
  roomName: string;
  venueId: string;
  recordings: VirtualVenueRecordingsWithVenue_virtualVenueRecordings[] | null;
}

const RTP_RECORDING_LAUNCH_DATE = moment.utc(config.rtpRecordingLaunchDate);

const VirtualVenueRecordingList = ({ roomName, venueId, recordings: mixilyRecordings }: Props) => {
  const { isLoading, isError, data, run } = useAsync<DailyGetRecordingsResponse>();

  const loadRecordings = React.useCallback(() => {
    run(
      dailyAPI.listRecordings(venueId, {
        room_name: roomName,
      })
    );
  }, [run, roomName, venueId]);

  React.useEffect(() => {
    loadRecordings();
  }, [loadRecordings]);

  if (isLoading || (!isError && !data)) {
    return <Skeleton variant="rect" height={36} />;
  }

  if (isError || !isRecordingsSuccess(data)) {
    return <div className="text-xl">Sorry, something went wrong loading your recordings. Please contact support.</div>;
  }

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    Started At
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    Duration
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="hidden px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase xl:table-cell"
                  >
                    Max participants
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Download</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {mixilyRecordings
                  ?.filter(({ createdAt }) => moment(createdAt).isAfter(RTP_RECORDING_LAUNCH_DATE))
                  .map(
                    ({
                      id,
                      createdAt,
                      extCompositingId,
                      extRecordingId,
                      duration,
                      maxParticipants,
                      compositingStatus,
                      recordingStatus,
                    }) => (
                      <tr key={id}>
                        <td className="px-6 py-4 align-middle whitespace-nowrap">{moment(createdAt).format('LLL')}</td>
                        <td className="px-6 py-4 align-middle whitespace-nowrap">
                          {duration ? (
                            durationToString(moment.duration(duration, 'seconds'))
                          ) : (
                            <span className="text-gray-500 ">-</span>
                          )}
                        </td>
                        <td className="px-6 py-4 align-middle whitespace-nowrap">
                          <span
                            className={clsx('inline-flex px-2 text-xs font-semibold leading-5   rounded-full', {
                              'bg-green-100 text-green-800': compositingStatus === ProcessingStates.DONE,
                              'bg-red-100 text-red-800':
                                recordingStatus === ProcessingStates.FAILED ||
                                compositingStatus === ProcessingStates.FAILED,
                              'bg-yellow-100 text-yellow-800':
                                recordingStatus === ProcessingStates.IN_PROGRESS ||
                                compositingStatus === ProcessingStates.IN_PROGRESS ||
                                compositingStatus === ProcessingStates.PENDING,
                            })}
                          >
                            {compositingStatus === ProcessingStates.DONE
                              ? 'finished'
                              : compositingStatus === ProcessingStates.IN_PROGRESS
                              ? 'processing'
                              : compositingStatus === ProcessingStates.FAILED
                              ? 'failed'
                              : 'in-progress'}
                          </span>
                        </td>
                        <td className="hidden px-6 py-4 text-sm text-gray-500 align-middle whitespace-nowrap xl:table-cell">
                          {maxParticipants ?? '-'}
                        </td>
                        <td className="px-6 py-4 text-sm font-medium text-right align-middle whitespace-nowrap">
                          <Link
                            className="mr-4 font-medium text-indigo-600 align-middle cursor-pointer hover:text-indigo-500"
                            href={reverse('virtual_venue_recording', { venueId, recordingId: id })}
                          >
                            View
                          </Link>
                          <VirtualVenueRecordingOptions
                            recordingId={id}
                            extRecordingId={extRecordingId!}
                            compositingStatus={compositingStatus}
                            venueId={venueId}
                            startTime={moment(createdAt)}
                            onDeleteSuccess={() => loadRecordings()}
                            fetchAccessLink={() =>
                              dailyAPI.getCompositeRecordingAccessLink(extRecordingId!, extCompositingId!)
                            }
                          />
                        </td>
                      </tr>
                    )
                  )}
                {data.data
                  .filter(({ start_ts }) => {
                    const m = moment.unix(start_ts);
                    return m.isBefore(RTP_RECORDING_LAUNCH_DATE);
                  })
                  .map(({ id, start_ts, duration, max_participants, status, share_token }) => (
                    <tr key={id}>
                      <td className="px-6 py-4 align-middle whitespace-nowrap">
                        {moment.unix(start_ts).format('LLL')}
                      </td>
                      <td className="px-6 py-4 align-middle whitespace-nowrap">
                        {duration ? durationToString(moment.duration(duration, 'seconds')) : '-'}
                      </td>
                      <td className="px-6 py-4 align-middle whitespace-nowrap">
                        <span
                          className={clsx('inline-flex px-2 text-xs font-semibold leading-5   rounded-full', {
                            'bg-green-100 text-green-800': status === 'finished',
                            'bg-red-100 text-red-800': status === 'in-progress',
                          })}
                        >
                          {status}
                        </span>
                      </td>
                      <td className="hidden px-6 py-4 text-sm text-gray-500 align-middle whitespace-nowrap xl:table-cell">
                        {max_participants ?? '-'}
                      </td>
                      <td className="px-6 py-4 text-sm font-medium text-right align-middle whitespace-nowrap">
                        <Link
                          className="mr-4 font-medium text-indigo-600 align-middle cursor-pointer hover:text-indigo-500"
                          href={reverse('virtual_venue_recording', { venueId, recordingId: id })}
                        >
                          View
                        </Link>
                        <VirtualVenueRecordingOptions
                          extRecordingId={id}
                          compositingStatus={
                            status === 'finished' ? ProcessingStates.DONE : ProcessingStates.IN_PROGRESS
                          }
                          venueId={venueId}
                          startTime={moment.unix(start_ts)}
                          onDeleteSuccess={() => loadRecordings()}
                          fetchAccessLink={() => dailyAPI.getRecordingAccessLink(id)}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VirtualVenueRecordingList;
