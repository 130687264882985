import { SubmitButtonProps2 } from 'components/forms/types';
import { useFormStateContext } from 'components/forms/FormStateContext';
import Button2 from 'components/common/Button2/Button2';
import React from 'react';

function SubmitButton<T extends Record<string, any> = any>(props: SubmitButtonProps2) {
  const { onSubmit, formContext } = useFormStateContext<T>();
  const onClick = formContext.handleSubmit(onSubmit);
  return (
    <Button2 {...props} submit={true} color={props.color || 'primary'} onClick={props.onClick || onClick}>
      {props.label}
    </Button2>
  );
}

export default SubmitButton;
