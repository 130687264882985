import * as m from 'models';
import { Message } from 'components/Outbox/types';
import { OutboxList } from 'components/Outbox/OutboxList';
import Button from 'components/common/Button/Button';
import Dialog from 'components/common/Dialog/Dialog';
import React, { useState } from 'react';
import styles from 'components/Outbox/OutboxModal.module.scss';

export interface Props {
  event?: m.Event;
  modalOpen: boolean;
  onClose: () => void;
  messages: Message[];
  loadingMessages: boolean;
  deleteMessages: (selectedMessageIds: Set<string>) => Promise<void>;
  customCloseMessage?: string;
  customHeader?: string;
  sentBy?: m.Person;
}

function determineRelatedToTitle(event: m.Event | undefined, sentBy: m.Person | undefined): string | undefined {
  if (event) {
    return `Re: ${event.title}`;
  }
  if (sentBy) {
    return `From ${sentBy.name}`;
  }
  return undefined;
}

const OutboxModal = (props: Props) => {
  const [selectedMessageIds, setSelectedMessageIds] = useState(new Set<string>());

  const {
    customCloseMessage,
    customHeader,
    event,
    modalOpen,
    onClose,
    sentBy,
    messages,
    loadingMessages,
    deleteMessages,
  } = props;
  const relatedToTitle = determineRelatedToTitle(event, sentBy);

  async function onDeleteClick() {
    await deleteMessages(selectedMessageIds);
    setSelectedMessageIds(new Set<string>());
  }

  function onMessageSelectionChange(selectedMessageIds: Set<string>) {
    setSelectedMessageIds(new Set<string>(selectedMessageIds));
  }

  return (
    <Dialog
      contentClassName={styles.OutgoingMessagesModal}
      isOpen={modalOpen}
      onClose={onClose}
      title={customHeader ? customHeader : 'Pending Messages'}
    >
      <div className={styles.OutgoingMessagesHeader}>{relatedToTitle ? <h2>{relatedToTitle}</h2> : ''}</div>
      <OutboxList
        loading={loadingMessages}
        onClose={onClose}
        onSelect={onMessageSelectionChange}
        selectedMessageIds={selectedMessageIds}
        messages={messages}
      />
      <div className={styles.Toolbar}>
        <Button
          handleClick={onClose}
          color="secondary"
          label={customCloseMessage ? customCloseMessage : 'Back'}
          small={true}
        />
        <Button
          disabled={selectedMessageIds.size <= 0}
          handleClick={onDeleteClick}
          label="Delete"
          small={true}
          className={styles.DeleteButton}
        />
      </div>
    </Dialog>
  );
};

export default OutboxModal;
