import Footer from 'components/layout/Footer/Footer';
import React, { useEffect, useState } from 'react';
import Sidebar from 'components/layout/Sidebar/Sidebar';
import VenueHeader from 'components/layout/Header/VenueHeader';

interface Props {
  title: string;
  children: React.ReactNode;
}

const MinimalVenueLayout = (props: Props) => {
  useEffect(() => {
    // set the title to something different first:
    // this works around a bug in Chrome where the tab title wasn't getting updated
    // repro: go to index while logged in, click back, IndexPage redirects back to Calendar,
    //        but title is now "Mixily"
    document.title = props.title + '.';
    document.title = props.title;
  });

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className="relative min-h-screen mx-auto bg-body-color">
      <Sidebar handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} />
      <VenueHeader handleDrawerToggle={handleDrawerToggle} />
      {props.children}
      <Footer />
    </div>
  );
};

export default MinimalVenueLayout;
