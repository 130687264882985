/* eslint-disable react/no-find-dom-node */

import React from 'react';
import ReactDOM from 'react-dom';

export const getComponentName = (Component: any) => Component.displayName || Component.name || 'Component';

interface ClickOutsideOptions {
  event?: string;
  mobileEvent?: string;
}

export const withClickOutside = <P extends object>(
  Component: React.ComponentType<P>,
  options: ClickOutsideOptions = {}
) => {
  const config = {
    event: 'mousedown',
    mobileEvent: 'touchstart',
    ...options,
  };

  return class WrappedComponent extends React.Component<P> {
    static displayName = `ClickOutside(${getComponentName(Component)})`;

    private $child: any;
    private __wrappedInstance: any;

    UNSAFE_componentWillMount() {
      document.addEventListener(config.event, this.handleClickOutside);
      document.addEventListener(config.mobileEvent, this.handleClickOutside);
    }

    componentWillUnmount() {
      document.removeEventListener(config.event, this.handleClickOutside);
      document.removeEventListener(config.mobileEvent, this.handleClickOutside);
    }

    handleClickOutside = (event: Event) => {
      // call child method if the user has clicked away
      if (
        this.$child &&
        !this.$child.contains(event.target) &&
        this.__wrappedInstance &&
        typeof this.__wrappedInstance.onClickOutside === 'function'
      ) {
        this.__wrappedInstance.onClickOutside(event);
      }
    };

    render() {
      const { ...rest } = this.props;
      return (
        <Component
          {...(rest as P)}
          ref={(child: any) => {
            this.__wrappedInstance = child;
            this.$child = ReactDOM.findDOMNode(child);
          }}
        />
      );
    }
  };
};
