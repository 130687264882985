import { URLContext } from 'components/RoutingProvider';
import { reverse } from 'router';
import React, { useContext } from 'react';
import styles from 'components/pages/EventReadPage/EventActionButton.module.scss';
import useEventId from 'components/App/globalstate/useEventId';

const DuplicateEventButton: React.FC = () => {
  const urlContext = useContext(URLContext);
  const eventId = useEventId();
  if (!eventId) throw new Error('Event id expected');

  const handleClick = () => {
    urlContext.setPath(reverse('event_duplicate', { id: eventId }));
  };

  return (
    <button onClick={handleClick} className={styles.button}>
      {/* Todo: use + icon */}
      <span className={styles.text}>+ Host again</span>
    </button>
  );
};

export default DuplicateEventButton;
