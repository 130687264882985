import { Contact } from './types';
import { useForm } from 'react-hook-form';
import Button2 from 'components/common/Button2/Button2';
import Dialog2, { actionStyles } from 'components/common/Dialog2/Dialog2';
import ErrorMessage from 'components/common/ErrorMessage/ErrorMessage';
import HelpText from 'components/common/HelpText/HelpText';
import Input2 from 'components/common/Input2/Input2';
import LabelWithInput from 'components/common/LabelWithInput/LabelWithInput';
import React from 'react';
import isEmail from 'validator/lib/isEmail';

interface Props {
  contactAndIndex: [Contact, number];
  onClose: () => void;
  onSubmit: (index: number, value: Contact) => void;
}

const EditDialog = (props: Props) => {
  const { contactAndIndex, onClose, onSubmit } = props;
  const [contact, index] = contactAndIndex;
  const { handleSubmit, register, errors } = useForm<Contact>({ defaultValues: contact });
  const wrappedSubmit = handleSubmit((val: Contact) => onSubmit(index, val));

  return (
    <>
      <Dialog2 isOpen={true} onClose={onClose} title="Update Invitation">
        <form onSubmit={wrappedSubmit}>
          <LabelWithInput label="Name">
            <Input2 ref={register()} name="name" placeholder="Enter an optional name for the guest list" />
          </LabelWithInput>

          <LabelWithInput label="Email" className="mt-4">
            <Input2
              ref={register({
                required: 'This field is required',
                validate: (value) => (!isEmail(value) ? 'Please enter a valid email address' : true),
              })}
              placeholder="guest@example.com"
              type="email"
              name="email"
              className={errors.email && 'border-red-300 focus:border-red-300 focus:ring-red'}
            />
          </LabelWithInput>
          <HelpText>Email will only be visible to event hosts.</HelpText>
          {errors.email && <ErrorMessage className="mt-2">{errors.email.message}</ErrorMessage>}

          <div className={actionStyles}>
            <Button2 onClick={wrappedSubmit} className="sm:ml-4" submit={true}>
              Save
            </Button2>
            <Button2 onClick={onClose} color="secondary" className="mt-4 sm:mt-0">
              Cancel
            </Button2>
          </div>
        </form>
      </Dialog2>
    </>
  );
};

export default EditDialog;
