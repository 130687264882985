import React from 'react';

export type UserChangeManager = {
  login(apitoken: string, showLoginMessage: boolean, nextUrl?: string): void;
  logout(nextUrl?: string | boolean): void;
};

const UserChangeContext = React.createContext<UserChangeManager>(null as any);

export default UserChangeContext;
