import { URLContext } from 'components/RoutingProvider';
import { reverse } from 'router';
import LandingPage from 'components/pages/LandingPage/LandingPage';
import React, { useContext } from 'react';
import useUser from 'utils/hooks/user/useUser';

const IndexPage = () => {
  const { replacePath } = useContext(URLContext);
  const { loading, isAuthenticated } = useUser();

  if (loading) {
    // Prevent flash of unauthenticated content
    return null;
  }

  if (isAuthenticated) {
    replacePath(reverse('event_calendar'));
    return null;
  }

  return <LandingPage />;
};

export default IndexPage;
