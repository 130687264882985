import { Tile } from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/types';
import React from 'react';
import TileDisplay from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/CallDisplay/TileDisplay/TileDisplay';

interface Props {
  width?: string;
  tiles: Tile[];
}

const BroadcastLayout = ({ width, tiles }: Props) => {
  const broadcaster = tiles.find((t) => t.type === 'video');
  return <TileDisplay tile={broadcaster!} />;
};

export default BroadcastLayout;
