import * as m from 'models';
import { CREATE_EVENT_MUTATION } from 'utils/gql';
import { CreateEvent, CreateEventVariables, CreateEvent_eventCreate_event } from 'generated/CreateEvent';
import { EventFormT } from './types';
import { URLContext } from 'components/RoutingProvider';
import { addSnackbarMessage } from 'utils/eventEmitter';
import { analyticsAPI as analytics } from 'utils/api';
import { eventFormToVariables, getDefaultValues } from './helpers';
import { fakeEventSave } from './demo';
import { getEventUrl } from 'models/event';
import { useMutation } from '@apollo/client';

import { Link } from '@material-ui/core';
import { reverse } from 'router';
import EventCreateUpdateFields from 'components/EventCreateUpdateForm/EventCreateUpdateForm/EventCreateUpdateFields';
import React, { useContext, useEffect, useRef } from 'react';
import makeValidators from 'components/EventCreateUpdateForm/EventCreateUpdateForm/validators';
import useControlledMutationForm from 'components/forms/useControlledMutationForm';
import useUser from 'utils/hooks/user/useUser';

interface Props {
  event?: m.Event;
  initialTitle?: string;
}

const EventCreateForm = (props: Props) => {
  const { event, initialTitle } = props;
  const [doCreate] = useMutation<CreateEvent, CreateEventVariables>(CREATE_EVENT_MUTATION);
  const defaultValues = getDefaultValues(event, initialTitle);
  const { setPath } = useContext(URLContext);
  const { isAuthenticated, isEmailConfirmed } = useUser();

  const getValuesRef = useRef<any>();

  const mxForm = useControlledMutationForm<EventFormT, CreateEvent, CreateEventVariables>({
    defaultValues,
    validators: makeValidators(getValuesRef),
    mutation: isAuthenticated && isEmailConfirmed ? doCreate : (fakeEventSave('eventCreate') as any),
    resultKey: 'eventCreate',
    formToVariables: eventFormToVariables,
  });

  getValuesRef.current = mxForm.formMethods.getValues;

  const { FormStateContextProvider, Form, SubmitButton, FormLevelMessages, successResult } = mxForm;

  useEffect(() => {
    if (!successResult) {
      return;
    }
    const newEvent = successResult.eventCreate.event as CreateEvent_eventCreate_event;
    const msg = '✨ Event created!';
    analytics.track('Event-Create');
    addSnackbarMessage(msg, 'success');
    setPath(getEventUrl(newEvent));
  }, [setPath, successResult]);

  if (successResult) {
    return null;
  }

  return (
    <FormStateContextProvider
      onSubmit={mxForm.onSubmit}
      isSubmitting={mxForm.isSubmitting}
      formContext={mxForm.formMethods}
    >
      <Form className="max-w-3xl mx-auto mt-4 sm:mt-8">
        {isAuthenticated && !isEmailConfirmed && (
          <div className="p-4 my-5 text-black bg-orange-200 border-l-4 border-orange-500" role="alert">
            <p className="font-bold">Please confirm your email address</p>
            <p>
              To create events, you should confirm your email address. Click{' '}
              <Link href={reverse('resend_confirmation')}>here</Link> to resend your confirmation email.
            </p>
          </div>
        )}
        <EventCreateUpdateFields mxForm={mxForm} />
        <div className="mt-6">
          <FormLevelMessages />
        </div>
        <div className="mx-4 mt-6 sm:mx-0">
          <SubmitButton label={'Create event'} submittingLabel={'Creating...'} className="w-full sm:w-48" />
        </div>
      </Form>
    </FormStateContextProvider>
  );
};

export default EventCreateForm;
