import { useVirtualVenuePubnub } from 'components/VirtualVenue/contexts/VirtualVenuePubnubContext';

import Confirm2 from 'components/common/Confirm2/Confirm2';
import React, { useCallback, useEffect, useState } from 'react';

interface Props {
  isMicMuted: boolean;
  toggleMic: () => void;
}

const RequestUnmuteDialog = ({ isMicMuted, toggleMic }: Props) => {
  const [requestedUnmute, setRequestedUnmute] = useState(false);
  const {
    pubnubState: {
      requestsToUnmute,
      presenceState: { dailyUserId },
    },
    pubnubDispatch,
  } = useVirtualVenuePubnub();

  useEffect(() => {
    if (requestsToUnmute.includes(dailyUserId ?? '')) {
      setRequestedUnmute(true);
    }
  }, [dailyUserId, pubnubDispatch, requestsToUnmute]);

  const handleRequestUnmuteResponse = useCallback(
    (unmute: boolean) => {
      if (unmute && isMicMuted) {
        toggleMic();
      }
      pubnubDispatch({ type: 'acknowledge-request-unmute', participant: dailyUserId ?? '' });
      setRequestedUnmute(false);
    },
    [dailyUserId, isMicMuted, pubnubDispatch, toggleMic]
  );

  return (
    <Confirm2
      title="Request to unmute"
      onClose={() => {
        handleRequestUnmuteResponse(false);
      }}
      onConfirm={() => {
        handleRequestUnmuteResponse(true);
      }}
      isOpen={requestedUnmute}
      yesLabel="Unmute"
      noLabel="Stay muted"
    >
      <div>The host has requested you unmute your mic.</div>
      <div>Would you like to unmute?</div>
    </Confirm2>
  );
};

export default RequestUnmuteDialog;
