import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import ErrorMessage from 'components/common/ErrorMessage/ErrorMessage';
import React, { useState } from 'react';
import config from 'config';
import useScript from 'utils/hooks/useScript';

interface Props {
  stripeCheckoutSessionId: string;
}

const REDIRECT_ERROR = (
  <div>Sorry, we had a problem with our checkout provider. Please refresh the page and try again.</div>
);

const REDIRECT_LOADING = (
  <BaseLayout title="">
    <p>
      <i>Redirecting to checkout...</i>
    </p>
  </BaseLayout>
);

declare global {
  interface Window {
    Stripe: any;
  }
}

const LoadStripeAndRedirect: React.FC<Props> = ({ stripeCheckoutSessionId }) => {
  const [loadedScript, loadScriptError] = useScript('https://js.stripe.com/v3/');
  const [stripeErrorMessage, setStripeErrorMessage] = useState<string>();

  if (loadScriptError) {
    return REDIRECT_ERROR;
  }

  if (!loadedScript) {
    return REDIRECT_LOADING;
  }

  if (stripeErrorMessage) {
    return (
      <div>
        {REDIRECT_ERROR}
        <div>
          <ErrorMessage>{stripeErrorMessage}</ErrorMessage>
        </div>
      </div>
    );
  }

  const stripe = window.Stripe(config.stripePublishableKey);
  stripe
    .redirectToCheckout({
      sessionId: stripeCheckoutSessionId,
    })
    .then(function (result: any) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
      setStripeErrorMessage(result.error.message);
    });
  return REDIRECT_LOADING;
};

export default LoadStripeAndRedirect;
