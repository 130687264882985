import { COLUMNS, ContactSummary } from './config';
import { CONTACT_SUMMARIES_FOR_PERSON_QUERY } from 'utils/gql';
import { ContactSummariesForPerson } from 'generated/ContactSummariesForPerson';
import { useQuery } from '@apollo/client';
import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import DashboardNavigation from 'components/pages/Dashboard/DashboardNavigation';
import DashboardTable from 'components/pages/Dashboard/DashboardTable/DashboardTable';
import LoginRequired from 'components/LoginRequired';
import React from 'react';

const ContactsDashboardPage = () => {
  const { data: summaries, loading } = useQuery<ContactSummariesForPerson>(CONTACT_SUMMARIES_FOR_PERSON_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const data = summaries?.me?.contactSummaries || [];

  return (
    <LoginRequired>
      <BaseLayout title="Dashboard">
        <DashboardNavigation />
        <DashboardTable<ContactSummary>
          data={data}
          columns={COLUMNS}
          loading={loading}
          // onClickRow={(row) => setPath(reverse('dashboard_event', { id: row.original.id }))}
        />
      </BaseLayout>
    </LoginRequired>
  );
};

export default ContactsDashboardPage;
