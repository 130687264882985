import Container from 'components/layout/Container/Container';
import React, { ReactNode } from 'react';
import editorImg from './benefit-design-3.png';
import homeImg from './benefit-home-3.png';
import linkImg from './benefit-cta-3.png';

interface BenefitProps {
  alt: string;
  attribution: ReactNode;
  children: ReactNode;
  src: string;
}

const Benefit = (props: BenefitProps) => (
  <div className="w-full px-4 mx-auto mb-20 transform lg:w-1/3 md:px-8 lg:mb-32">
    <div className="relative text-center">
      <div className="h-24 text-3xl font-semibold leading-normal text-center text-white ">{props.children}</div>
    </div>
    <img src={props.src} className="w-full max-w-xs mx-auto mt-4 h-100 lg:mx-auto" alt={props.alt} />
    <div className="px-8 mt-4 text-lg italic font-semibold text-center text-orange-500 md:mt-8">
      {props.attribution}
    </div>
  </div>
);

const KeyBenefits3 = () => {
  return (
    <div className="w-full pt-12 mx-auto lg:pt-32" style={{ backgroundColor: '#584D47' }}>
      <Container className="px-4 mx-auto">
        <div className="flex flex-wrap justify-center">
          <Benefit src={linkImg} alt="Shop link" attribution="“Just click the Shop link at the top of the page”">
            Easier calls
            <br /> to action
          </Benefit>

          <Benefit
            src={editorImg}
            alt="color editor"
            attribution={
              <span>
                Plus choose a title and
                <br />
                upload a logo
              </span>
            }
          >
            Control the color
            <br />
            of every element
          </Benefit>

          <Benefit
            src={homeImg}
            alt="home of events"
            attribution="Your guests will always know where to find your venue on your website"
          >
            Stop scrambling
            <br />
            for Zoom links
          </Benefit>
        </div>
      </Container>
    </div>
  );
};

export default KeyBenefits3;
