import React from 'react';
import ThemePanel from './ThemePanel/ThemePanel';
import VirtualVenueDisplay from 'components/VirtualVenue/VirtualVenueDisplay/VirtualVenueDisplay';

const VirtualVenueThemeEditor = () => {
  return (
    <div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr auto',
          columnGap: '1rem',
        }}
      >
        <div className="overflow-hidden">
          <VirtualVenueDisplay />
        </div>
        <ThemePanel />
      </div>
    </div>
  );
};

export default VirtualVenueThemeEditor;
