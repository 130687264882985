// Given a Prismic query, returns {loading, data, error}

import { Document } from 'utils/prismic/types';
import { useMemo } from 'react';
import Prismic from 'prismic-javascript';
import usePromise from 'utils/hooks/usePromise';

const apiEndpoint = 'https://mixily.cdn.prismic.io/api/v2';

const Client = Prismic.client(apiEndpoint);

const usePrismic = <T = any>(query: string, options?: any) => {
  const queryPromise = useMemo(() => {
    return Client.query(query, options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);
  const { loading, error, data } = usePromise(queryPromise);
  return { loading, error, data: data ? (data.results as Document<T>[]) : undefined };
};

export default usePrismic;
