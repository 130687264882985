import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import classNames from 'classnames';
import styles from 'components/common/TextArea.module.scss';

interface Props {
  // Builtin
  value?: string;
  disabled?: boolean;
  onChange?: (event: any) => void;
  className?: string;
  placeholder?: string;
  onKeyDown?: (event: any) => void;
  onFocus?: (event: any) => void;
  onBlur?: (event: any) => void;

  // TextareaAutosize
  inputRef?: Function;

  // Custom
  autosize?: boolean;
}

const TextArea: React.FC<Props> = (props) => {
  const { autosize, className, ...builtin }: any = props;
  const cls = classNames(styles.TextArea, className);
  return autosize ? <TextareaAutosize className={cls} {...builtin} /> : <textarea className={cls} {...builtin} />;
};

export default TextArea;
