import { Button, Icon } from 'components/common';
import { Menu, MenuItem } from '@material-ui/core';
import React, { useState } from 'react';
import styles from './InviteMenu.module.scss';

export enum InviteMenuType {
  INVITE = 'invite',
  MESSAGE = 'message',
}

interface Props {
  nameMenu?: string;
  onPreview: () => void;
  onClear?: () => void;
  onSchedule?: () => void;
  type?: InviteMenuType;
}

const InviteMenu = ({ onPreview, onSchedule, onClear, type, nameMenu }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button className={styles.button} name={nameMenu} label="" icon={<Icon icon="gear" />} onClick={handleClick} />
      <Menu
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
        classes={{ list: styles.menu, paper: styles.paper }}
      >
        {onSchedule && (
          <MenuItem
            onClick={() => {
              onSchedule();
              handleClose();
            }}
            classes={{ root: styles.menuItem }}
            id={nameMenu}
          >
            {onClear ? 'Modify schedule' : 'Schedule for later'}
          </MenuItem>
        )}
        {onClear && (
          <MenuItem
            onClick={() => {
              onClear();
              handleClose();
            }}
            classes={{ root: styles.menuItem }}
          >
            Clear schedule
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            onPreview();
            handleClose();
          }}
          classes={{ root: styles.menuItem }}
        >
          Preview {type || InviteMenuType.INVITE} email
        </MenuItem>
      </Menu>
    </>
  );
};

export default InviteMenu;
