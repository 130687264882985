import { Link } from 'components/common';
import { isActiveUrl } from 'utils/urls';
import React, { ReactNode } from 'react';
import classNames from 'classnames';

interface Props {
  href: string;
  children: ReactNode;
}

export const BASE_LINK_CLASS = `group flex items-center px-2 py-2 text-base leading-6 font-medium rounded-md focus:outline-none focus:bg-gray-700 transition ease-in-out duration-150`;
export const ACTIVE_LINK_CLASS = 'text-white bg-gray-900';
export const NORMAL_LINK_CLASS = 'text-gray-300 hover:text-white hover:bg-gray-700 focus:text-white';

export const MenuItem = ({ href, children }: Props) => (
  <Link href={href} className={classNames(BASE_LINK_CLASS, isActiveUrl(href) ? ACTIVE_LINK_CLASS : NORMAL_LINK_CLASS)}>
    {children}
  </Link>
);
