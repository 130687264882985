import { DEFAULT_IMAGE_URL } from 'components/EventCreateUpdateForm/EventCreateUpdateForm/constants';
import { LISTING_QUERY } from 'utils/gql';
import { Listing, ListingVariables } from 'generated/Listing';
import { Loading } from 'components/common';
import { URLContext } from 'components/RoutingProvider';
import { isAuthenticated } from 'models';
import { reverse } from 'shared/shared/routing/mixily-routes';
import { useQuery } from '@apollo/client';
import Button2 from 'components/common/Button2/Button2';
import Description from 'components/pages/EventReadPage/Description';
import Divider from 'components/common/Divider';
import EmbedListingDialog from 'components/pages/ListingPage/EmbedListingDialog';
import Error404Page from 'components/pages/Error404Page';
import Error500Page from 'components/pages/Error500Page';
import EventLayout from 'components/layout/EventLayout/EventLayout';
import ListingEventItem from 'components/pages/ListingPage/ListingEventItem';
import React, { useContext } from 'react';
import Title from 'components/pages/EventReadPage/Title/Title';
import classNames from 'classnames';
import styles from '../EventReadPage/EventReadView.module.scss';
import useUser from 'utils/hooks/user/useUser';

interface Props {
  id: string;
}

const ListingPage = ({ id }: Props) => {
  const { setPath } = useContext(URLContext);
  const { loading, data, error } = useQuery<Listing, ListingVariables>(LISTING_QUERY, { variables: { id } });
  const listing = data?.listing;
  const { user } = useUser();
  const userId = isAuthenticated(user) ? user.id : undefined;

  if (loading) {
    return (
      <EventLayout title="Loading...">
        <Loading />
      </EventLayout>
    );
  }

  if (error) {
    return <Error500Page />;
  }

  if (!listing) {
    return <Error404Page />;
  }

  const upcomingEvents = listing.listingEvents.filter((listingEvent) => !listingEvent.event.isPast);

  return (
    <EventLayout
      title={listing.title}
      themePicUrl={listing.themePic || DEFAULT_IMAGE_URL}
      readPage={true}
      bgColor={listing.bgColor}
      bgNoBackdrop={listing.bgNoBackdrop}
    >
      <div className="relative max-w-3xl mx-auto">
        {userId === listing.personId && (
          <div className="absolute flex items-center space-x-2 right-4 top-4">
            {isAuthenticated(user) && (
              <EmbedListingDialog id={id} buttonClassName="rounded-full opacity-75 cursor-pointer w-44" />
            )}
            <Button2
              size="8"
              onClick={() => setPath(reverse('listing_edit', { id }))}
              className="w-24 rounded-full opacity-75 cursor-pointer"
            >
              Edit page
            </Button2>
          </div>
        )}
        <img className={styles.HeaderImage} src={listing.themePic || DEFAULT_IMAGE_URL} alt="" />
        <div
          className={classNames(styles.MainInfo, 'bg-white', {
            [styles.TopBorder]: !listing.themePic,
          })}
        >
          <Title title={listing.title} />
          <div className="grid grid-cols-4">
            <div className="col-span-4 lg:col-span-3">
              <Description
                description={listing.description}
                className={styles.Description}
                bodyClassName="text-lg"
                showLabel={false}
              />
            </div>
          </div>
          <div className="pb-2 mt-12 mb-6 text-lg font-bold border-b border-gray-300 border-solid">Upcoming events</div>
          <div className="space-y-4">
            {upcomingEvents.map((listingEvent, idx) => (
              <div key={idx} className="space-y-8">
                {!!idx && <Divider />}
                <ListingEventItem key={listingEvent.id} listingEvent={listingEvent} listingId={listing.id} />
              </div>
            ))}
          </div>
          {!upcomingEvents.length && (
            <div className="flex items-center justify-center p-4 opacity-50">No upcoming events.</div>
          )}
        </div>
      </div>
    </EventLayout>
  );
};

export default ListingPage;
