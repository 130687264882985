import { IZoomMeetingOptions, User, isAuthenticated } from 'models';
import { Loading } from 'components/common';
import { OutlinedInput } from '@material-ui/core';
import { WidgetProps } from 'components/widgets';
import { getStartAndDuration } from 'utils/datetime';
import { mixilyAPI } from 'utils/api';
// import AddIcon from '@material-ui/icons/Add';
// import CheckIcon from '@material-ui/icons/Done';
import LocationIcon, { LocationIconName } from 'components/common/LocationIcon';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import ZoomConnectDialog from 'components/ZoomConnectDialog';
import classNames from 'classnames';
import styles from './StreamLinkWidget.module.scss';
import usePrevious from 'utils/hooks/usePrevious';
import useUser from 'utils/hooks/user/useUser';

export function getLocationIcon(url: string, virtual?: boolean): LocationIconName {
  if (/facebook\.com/.test(url)) {
    return 'facebook';
  } else if (/zoom\.us/.test(url)) {
    return 'zoom';
  } else if (/meet\.google\.com/.test(url)) {
    return 'meet';
  } else if (/youtube\.com/.test(url)) {
    return 'youtube';
  } else if (virtual || /^http/.test(url)) {
    return 'generic';
  } else {
    return 'place';
  }
}

interface Props extends WidgetProps<string> {
  onInputChange?: (e: any) => void;
}

const StreamLinkWidget = ({ value, onChange, onInputChange, attrs }: Props) => {
  const { user } = useUser();
  const { eventTitle, when, virtual, inPerson, roundedCorners } = attrs;
  const dataWhen = getStartAndDuration(when);

  const [locationIcon, setLocationIcon] = useState<LocationIconName>('generic');
  const [loading, setLoading] = useState<boolean>(false);
  const zoomMeetingCreated = !loading && getLocationIcon(value) === 'zoom';
  const prevUser: User | undefined = usePrevious(user);
  const [showModal, setShowModal] = useState(false);

  // Don't show Zoom connect during demo
  const addZoomVisible = !inPerson && isAuthenticated(user);

  const placeholder =
    virtual && !inPerson
      ? 'https://yoursite.example.com/venue'
      : inPerson
      ? 'Place name or address'
      : 'Place name, address or streaming link';

  const onClickAddZoom = (): boolean => {
    if (isAuthenticated(user) && !user.zoomUserId) {
      setShowModal(true);
      return false;
    } else {
      return true;
    }
  };

  /**
   * Meeting type: scheduled meetings
   * Scheduled meetings can be started at any time before the scheduled time. The links
   * will not expire or become invalid unless it is past the 30-day period for a
   * non-recurring meeting.
   * https://support.zoom.us/hc/en-us/articles/201362413-Scheduling-meetings
   *
   * API reference:
   * https://marketplace.zoom.us/docs/api-reference/zoom-api/meetings/meetingcreate
   */
  const createZoomMeeting = useCallback(async () => {
    const startTime = dataWhen?.startTime?.toISOString() ?? undefined;
    const options: IZoomMeetingOptions = {
      topic: eventTitle || 'Mixily Event',
      type: 2,
      start_time: startTime, // Zoom API will use the current time if this is undefined
      duration: dataWhen?.duration, // Zoom API will use a default value of 60 minutes if this is undefined
      timezone: startTime ? 'UTC' : undefined,
      settings: { host_video: true, participant_video: true, join_before_host: false },
    };

    if (isAuthenticated(user)) {
      setLoading(true);
      const res = await mixilyAPI.createZoomMeeting(options);
      setLoading(false);
      const meetingUrl = res?.data?.meetingUrl;
      if (meetingUrl) {
        onChange(meetingUrl);
      }
    }
  }, [eventTitle, dataWhen, onChange, user]);

  useEffect(() => {
    if (prevUser && isAuthenticated(user) && isAuthenticated(prevUser) && !prevUser.zoomUserId && user.zoomUserId) {
      createZoomMeeting();
    }
  }, [user, prevUser, createZoomMeeting]);

  useEffect(() => {
    setLocationIcon(getLocationIcon(value, virtual));
  }, [value, virtual]);

  function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    onChange(e.target.value);
  }

  return (
    <>
      <OutlinedInput
        fullWidth
        className={classNames(styles.input, {
          roundedCorners: roundedCorners,
        })}
        value={value}
        onChange={onInputChange || handleInputChange}
        labelWidth={0}
        placeholder={placeholder}
        startAdornment={<LocationIcon icon={locationIcon} className={styles.icon} />}
        endAdornment={
          addZoomVisible ? (
            <div
              className={classNames(styles.addZoom, { [styles.disabled]: zoomMeetingCreated })}
              onClick={
                zoomMeetingCreated
                  ? undefined
                  : () => {
                      const result = onClickAddZoom();
                      if (result) {
                        createZoomMeeting();
                      }
                    }
              }
            >
              {loading && <Loading size="tiny" />}
              {/* Disable Zoom feature until we have virtual venue location type */}
              {/* {!loading && !value && (
                <>
                  <AddIcon fontSize="small" className={styles.addIcon} /> Add Zoom Meeting
                </>
              )}
              {!loading && zoomMeetingCreated && (
                <>
                  <CheckIcon fontSize="small" className={styles.createdIcon} /> Meeting Created
                </>
              )} */}
            </div>
          ) : undefined
        }
      />
      {showModal && <ZoomConnectDialog isOpen={showModal} setIsOpen={setShowModal} />}
    </>
  );
};

export default StreamLinkWidget;
