// State for form-level success and error message to react-hook-form,
// plus a convenience component

import { Message } from 'components/common';
import React, { useCallback, useState } from 'react';

export type MixilyFormState = {
  formLevelError: string;
  setFormLevelError: (err: true | string) => void;
  successMessage: string;
  setSuccessMessage: React.Dispatch<React.SetStateAction<string>>;
  clearMessages: () => void;
  FormLevelMessages: React.ComponentType<{ className?: string }>;
};

const useFormLevelState = () => {
  const [formLevelError, setFormLevelError] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');

  const clearMessages = useCallback(() => {
    setFormLevelError('');
    setSuccessMessage('');
  }, []);

  const wrappedSetError = useCallback(
    (err: true | string) => setFormLevelError(err === true ? 'Sorry, something went wrong' : err),
    []
  );

  const FormLevelMessages = useCallback(
    ({ className }) => (
      <div className={className}>
        {formLevelError && (
          <Message compact type="negative">
            {formLevelError}
          </Message>
        )}
        {successMessage && (
          <Message compact type="positive">
            {successMessage}
          </Message>
        )}
      </div>
    ),
    [formLevelError, successMessage]
  );

  return {
    formLevelError,
    setFormLevelError: wrappedSetError,
    successMessage,
    setSuccessMessage,
    clearMessages,
    FormLevelMessages,
  };
};

export default useFormLevelState;
