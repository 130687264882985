// Use with label="" and pass the 'label' in under attrs

import { WidgetProps } from 'components/widgets';
import OptionSwitch from 'components/OptionSwitch/OptionSwitch';
import React from 'react';

interface Attrs {
  title: string;
}

const OptionSwitchWidget = (props: WidgetProps<boolean, Attrs>) => {
  const { value, disabled, id, onChange, attrs } = props;
  if (!attrs) {
    throw new Error('OptionSwitchWidget requires attrs');
  }
  return (
    <OptionSwitch
      checked={value}
      disabled={disabled}
      id={id}
      title={attrs!.title}
      onChange={(e, checked) => onChange(checked)}
    />
  );
};

export default OptionSwitchWidget;
