// Based on https://github.com/chenjuneking/quill-image-drop-and-paste

import type { Quill } from 'quill';

class ImageDropAndPaste {
  quill: Quill;
  options: any;

  constructor(editor: Quill, options = {}) {
    this.quill = editor;
    this.options = options;
    editor.root.addEventListener('drop', this.handleDrop, false);
    editor.root.addEventListener('paste', this.handlePaste, false);
  }

  private readonly handleDrop = (e: DragEvent) => {
    const { dataTransfer } = e;
    e.preventDefault();

    if (!dataTransfer || !dataTransfer.files) return;

    const files = this.filterFiles(Array.from(dataTransfer.files));

    if (!files.length) return;

    if (document.caretRangeFromPoint) {
      const selection = document.getSelection();
      const range = document.caretRangeFromPoint(e.clientX, e.clientY);
      if (selection && range) {
        selection.setBaseAndExtent(range.startContainer, range.startOffset, range.startContainer, range.startOffset);
      }
    }

    this.options.handler(this.quill, files);
  };

  private readonly handlePaste = (e: ClipboardEvent) => {
    const { clipboardData } = e;

    if (!clipboardData || !clipboardData.items) return;

    const fileItems = Array.from(clipboardData.items || [])
      .map((e) => e.getAsFile())
      .filter((e) => e) as File[];

    const files = this.filterFiles(fileItems);
    if (!files.length) return;

    e.preventDefault();
    this.options.handler(this.quill, files);
  };

  private readonly filterFiles = (files: File[]): File[] => {
    if (typeof this.options.filter === 'function') {
      return this.options.filter(files);
    }

    return files.filter((file) => file.type.match(/^image\/(gif|jpe?g|a?png|svg|webp|bmp)/i));
  };
}

export default ImageDropAndPaste;
