import * as F from 'shared/shared/Functional';
import { ACCOUNT_SIGNUP_MUTATION } from 'utils/gql';
import { AuthSignup, AuthSignupVariables } from 'generated/AuthSignup';
import { TextLink } from 'components/common';
import { errorCodeHandlers } from 'components/auth-forms/fields';
import { reverse } from 'router';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';

import FieldLayout from 'components/common/FieldLayout/FieldLayout';
import Input2 from 'components/common/Input2/Input2';
import Link from 'components/Link';
import React from 'react';
import classNames from 'classnames';
import isEmail from 'validator/lib/isEmail';
import styles from 'components/auth-forms/AuthForm.module.scss';
import useMutationFormHelpers from 'components/forms/useMutationFormHelpers';
import useUserChangeContext from 'components/App/useUserChangeContext';

interface Props {
  email?: string;
  next?: string;
  hideFooter?: boolean;
  onRequestLoginFormModal?: (credentials: AuthFormCredentials) => () => void;
  onRequestSignupFormModal?: (credentials: AuthFormCredentials) => () => void;
  onSuccessfulSignup?: () => void;
}

type FormT = {
  name: string;
  email: string;
  password: string;
};

const EmailAuthSignupForm = (props: Props) => {
  const { email = '', next, hideFooter, onSuccessfulSignup } = props;
  const { login } = useUserChangeContext();
  const [accountSignup, { loading }] = useMutation<AuthSignup, AuthSignupVariables>(ACCOUNT_SIGNUP_MUTATION);
  const formContext = useForm<FormT>({ defaultValues: { email, password: '', name: '' } });

  const {
    Form,
    SubmitButton,
    FormLevelMessages,
    FormStateContextProvider,
    onSubmit,
    isSubmitting,
  } = useMutationFormHelpers<FormT, AuthSignup, AuthSignupVariables>({
    formContext,
    formToVariables: F.identity,
    mutation: accountSignup,
    resultKey: 'emailAuthCreate',
    onSuccess: (val) => {
      const apitoken = val.emailAuthCreate.apitoken!;
      const setNext = next || window.location.pathname;
      onSuccessfulSignup && onSuccessfulSignup();
      // Delay redirect to allow tracking to complete;
      setTimeout(() => {
        login(apitoken, true, setNext);
      }, 500);
    },
  });

  const { errors, getValues, register } = formContext;
  const values = getValues();

  return (
    <div>
      <FormStateContextProvider onSubmit={onSubmit} isSubmitting={isSubmitting} formContext={formContext}>
        <Form>
          <FieldLayout label="Name" error={errors.name}>
            <Input2
              name="name"
              size="lg"
              disabled={loading}
              ref={register({
                required: true,
              })}
              placeholder="Name"
            />
          </FieldLayout>

          <FieldLayout
            label="Email address"
            className="mt-4"
            error={errors.email}
            errorCodeHandlers={{
              ...errorCodeHandlers.promptForLoginWhenAccountAlreadyExists,
              ...errorCodeHandlers.promptForRegistrationWhenEmailIsInvalid,
            }}
          >
            <Input2
              type="email"
              name="email"
              size="lg"
              disabled={loading}
              ref={register({
                required: true,
                validate: (value: string) => (!isEmail(value) ? 'Please enter a valid email address' : true),
              })}
              placeholder="you@example.com"
            />
          </FieldLayout>

          <FieldLayout label="Password" className="mt-4" error={errors.password}>
            <Input2
              type="password"
              name="password"
              size="lg"
              disabled={loading}
              ref={register({
                required: true,
              })}
            />
          </FieldLayout>
          <SubmitButton label="Sign Up" submittingLabel="Signing Up..." size="14" className="w-full mt-9 sm:w-full" />
        </Form>
      </FormStateContextProvider>
      <FormLevelMessages className="mt-4" />
      <div
        className={classNames(styles.AuthFooter, {
          hidden: hideFooter,
        })}
      >
        <span>
          {props.onRequestLoginFormModal ? (
            <TextLink onClick={props.onRequestLoginFormModal(values)} className="text-center">
              Already have an account?
            </TextLink>
          ) : (
            <Link href={reverse('account_login')}>Already have an account?</Link>
          )}
        </span>
      </div>
    </div>
  );
};

export default EmailAuthSignupForm;
