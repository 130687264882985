import { Invite, getEmail } from 'models/invite';
import { InviteToken } from 'models/invite-token';
import { TextLink } from 'components/common';
import { URLContext } from 'components/RoutingProvider';
import { addSnackbarMessage } from 'utils/eventEmitter';
import React, { useContext } from 'react';
import classNames from 'classnames';
import styles from './RsvpForm.module.scss';

interface BlockedFormProps {
  eventId: string;
  invite?: Invite;
  inviteToken?: InviteToken;
  followForm?: boolean;
  sendMagicLink: Function;
}

const sendEmailLink = (invite: Invite | undefined, eventId: string, sendMagicLink: Function) => {
  const { rsvpEmail } = invite || {};

  sendMagicLink({
    variables: {
      eventId,
      email: rsvpEmail,
    },
  });

  addSnackbarMessage('Email Sent! Please check your inbox.', 'info');
};

const BlockedRsvpForm = (props: BlockedFormProps) => {
  const { invite, inviteToken, eventId, sendMagicLink, followForm } = props;
  const currentUser = invite ? getEmail(invite) : 'This user';
  const { authModalStateMutatorSet } = useContext(URLContext);

  return (
    <>
      {/* <hr> is used here because <Divider /> was causing too many issues with style and spacing */}
      <hr className={'thin-divider'} />
      <div className={classNames(styles.RsvpFormInnerWrapper, 'text-center')}>
        {currentUser} has already responded. To update {followForm ? 'your subscription' : 'the RSVP'},{' '}
        {inviteToken?.userHasFullAccount ? (
          <TextLink onClick={authModalStateMutatorSet.openLoginModal}>Sign in</TextLink>
        ) : (
          <TextLink onClick={() => sendEmailLink(invite, eventId, sendMagicLink)}>email yourself a link</TextLink>
        )}
        .
      </div>
    </>
  );
};

export default BlockedRsvpForm;
