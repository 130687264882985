import { POLL_QUERY } from 'utils/gql';
import { PollOption, formatPollOptions, getMaxVotes, getVoteWidth, groupPollOptionsWithVotes } from 'models/poll';
import { pollQuery as PollQuery } from 'generated/pollQuery';
import { PollSelectionDialog } from 'components/pages/EventReadPage/polls/PollSelectionDialog';
import { PollVoteDialog } from 'components/pages/EventReadPage/polls/PollVoteDialog';
import { PollVoteTooltip } from 'components/pages/EventReadPage/polls/PollVoteTooltip';
import { isAuthenticated } from 'models';
import { useQuery } from '@apollo/client';

import React from 'react';
import styles from './PollView.module.scss';
import useEvent from 'utils/hooks/event/useEvent';
import useUser from 'utils/hooks/user/useUser';

interface Props {
  id: string;
  voterName?: string;
}

export const PollView = (props: Props) => {
  const path = window.location.pathname;
  const isDemo = path.includes('demo');

  const { event, loading: eventLoading } = useEvent();
  const { loading, error, data } = useQuery<PollQuery>(POLL_QUERY, {
    variables: { id: props.id },
  });
  const { user } = useUser();
  const isAdmin = event && event.owner && isAuthenticated(user) && event.owner.id === user.id;

  if (loading || eventLoading) return <p>Loading...</p>;
  if (!isDemo && error) return <p>Error :(</p>;
  if (!eventLoading && !event) throw new Error('Event expected');

  const rawPoll = isDemo ? event!.poll : data?.poll;

  if (!rawPoll) throw new Error('Poll expected');

  const poll = groupPollOptionsWithVotes(rawPoll);
  const options = formatPollOptions(poll.options);
  const maxVotes = getMaxVotes(options);

  return (
    <div className={styles.PollView}>
      {options.map((option: PollOption) => (
        <div key={option.id} className={styles.option}>
          <div className={styles.header}>
            <div className={styles.label}>{option.text}</div>
            {option.votes && <PollVoteTooltip votes={option.votes} className={styles.votes} />}
          </div>
          <div className={styles.track}>
            <div className={styles.meter} style={{ width: getVoteWidth(option, maxVotes) }} />
          </div>
        </div>
      ))}
      <div className={styles.actionButtons}>
        <PollVoteDialog poll={poll} voterName={props.voterName} buttonClassName={styles.actionButton} />
        {isAdmin && (
          <>
            <div className={styles.separator} />
            <PollSelectionDialog poll={poll} buttonClassName={styles.actionButton} />
          </>
        )}
      </div>
    </div>
  );
};
