import InputLayout, { Props as InputLayoutProps } from 'components/common/InputLayout/InputLayout';
import React, { DetailedHTMLProps, InputHTMLAttributes } from 'react';

export type InputBuiltinProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export interface Props extends Omit<InputBuiltinProps, 'size'> {
  size?: InputLayoutProps['size'];
  startAdornment?: InputLayoutProps['startAdornment'];
  endAdornment?: InputLayoutProps['endAdornment'];
  ref?: React.Ref<HTMLInputElement>;
  classes?: InputLayoutProps['classes'];
}

const Input2 = (props: Props, ref: React.Ref<HTMLInputElement>) => {
  const { startAdornment, size, endAdornment, classes, ...rest } = props;
  return (
    <InputLayout
      inputComponent="input"
      inputProps={rest}
      size={size}
      classes={classes}
      startAdornment={props.startAdornment}
      endAdornment={endAdornment}
      ref={ref}
    />
  );
};

export default React.forwardRef(Input2);
