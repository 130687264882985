import * as m from 'models';
import { EventWithExistQuery_event_eventsFromRun } from 'generated/EventWithExistQuery';
import { renderFullTime } from 'models/event';
import Button2 from 'components/common/Button2/Button2';
import CalendarFlag from 'components/pages/EventReadPage/RsvpForm/TicketingForm/InnerTicketingForm/RunModal/CalendarFlag';
import Dialog2 from 'components/common/Dialog2/Dialog2';
import React from 'react';
import moment from 'moment';

interface Props {
  eventsFromRun?: EventWithExistQuery_event_eventsFromRun[] | null | undefined;
  isOpen: boolean;
  setIsOpen: Function;
  selectedDay: Date | null;
  templateEvent: m.Event;
  onChange: Function;
}

const RunModal = ({ eventsFromRun, templateEvent, setIsOpen, selectedDay, isOpen, onChange }: Props) => {
  const occurrences = selectedDay
    ? eventsFromRun?.filter((e) => moment(e.startTime!).isSame(selectedDay, 'day'))
    : eventsFromRun;

  const handleClickSelection = () => {
    setIsOpen(true);
  };

  const handleClickCancel = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className="text-center md:mx-6">
        {selectedDay ? (
          <div
            className="max-w-xs py-4 text-gray-100 rounded shadow-lg cursor-pointer bg-cool-gray-600"
            onClick={handleClickSelection}
          >
            <div className="text-sm">Buying tickets for</div>
            <div className="font-bold underline">{moment(selectedDay).format('dddd, MMMM Do [at] h a')}</div>
            {/* <div className="pt-4 text-sm">
              <TextLink onClick={handleClickSelection} className="text-gray-100 underline">
                Change
              </TextLink>
            </div> */}
          </div>
        ) : (
          <Button2 onClick={handleClickSelection} className="lg:w-full">
            Select a Showtime
          </Button2>
        )}
      </div>
      <Dialog2 isOpen={isOpen} onClose={handleClickCancel} size="md" title="Select a Date">
        <div>
          {occurrences?.map((event) => {
            const startTime = moment(event.startTime!);
            const [startTimeRender] = renderFullTime({
              startTime: startTime,
              endTime: event.endTime ? moment(event.endTime) : undefined,
              location: templateEvent.location,
              timezone: templateEvent.timezone,
            });

            // TODO: Dynamic description based on virtual event
            const manualCinemaRun = '4667430828119875605';
            const description =
              manualCinemaRun !== event.partOfRunId ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: event.description,
                  }}
                ></div>
              ) : (
                templateEvent.title
              );
            return (
              <div
                className="px-1 py-2 my-4 rounded-sm cursor-pointer md:px-3 lg:mx-2 hover:bg-gray-100"
                key={event.id}
                onClick={() => onChange(event as any)}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <CalendarFlag top={startTime.format('dddd')} middle={startTime.date().toString()} bottom="Dec" />
                    <div className="ml-6">
                      <div className="text-lg font-semibold leading-tight md:text-2xl text-black-900">
                        {startTimeRender}
                      </div>
                      <div className="pr-10 mt-2 text-sm leading-tight text-gray-800">{description}</div>
                    </div>
                  </div>
                  <div className="hidden pl-2 md:inline-block">
                    <Button2 className="h-10">Tickets</Button2>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Dialog2>
    </>
  );
};

export default RunModal;
