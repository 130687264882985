import * as m from 'models';
import { DELETE_OUTGOING_INVITATIONS_MUTATION, EVENT_GUEST_LIST_QUERY, OUTGOING_INVITATIONS_QUERY } from 'utils/gql';
import { DeleteOutgoingInvitations, DeleteOutgoingInvitationsVariables } from 'generated/DeleteOutgoingInvitations';
import { EventGuestList, EventGuestListVariables } from 'generated/EventGuestList';
import { Message } from 'components/Outbox/types';
import { OutgoingInvitations, OutgoingInvitationsVariables } from 'generated/OutgoingInvitations';
import { get } from 'utils/helpers';
import { renderName } from 'models/invite';
import { useMutation, useQuery } from '@apollo/client';
import OutboxModal from 'components/Outbox/OutboxModal';
import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
interface InvitationsProps {
  event: m.Event;
  modalOpen: boolean;
  onClose: () => void;
}

export const OutboxInvitationsModal = (props: InvitationsProps) => {
  const { event, modalOpen, onClose } = props;

  const [messages, setMessages] = useState<Message[]>([]);

  const variables = useMemo(() => ({ eventId: event.id, includeSent: false }), [event.id]);

  const { refetch } = useQuery<EventGuestList, EventGuestListVariables>(EVENT_GUEST_LIST_QUERY, {
    variables: { id: event.id },
  });

  const { loading: loadingInvitations, data: dataInvitations, refetch: refetchInvitations } = useQuery<
    OutgoingInvitations,
    OutgoingInvitationsVariables
  >(OUTGOING_INVITATIONS_QUERY, {
    variables: {
      eventId: event?.id,
      includeSent: false,
    },
  });

  const [deleteOutgoingInvitations] = useMutation<DeleteOutgoingInvitations, DeleteOutgoingInvitationsVariables>(
    DELETE_OUTGOING_INVITATIONS_MUTATION,
    { refetchQueries: [{ query: OUTGOING_INVITATIONS_QUERY, variables: { eventId: event.id } }] }
  );

  const deleteMessages = async (selectedMessageIds: Set<string>) => {
    refetch();
    await deleteOutgoingInvitations({ variables: { ids: Array.from(selectedMessageIds) } });
    await refetchInvitations(variables);
  };

  useEffect(() => {
    const invitationMessages = dataInvitations?.invitations?.map(
      (invite): Message => {
        return {
          id: get(() => invite.id, ''),
          eventId: get(() => invite.eventId, ''),
          scheduledFor: get(() => moment(invite.scheduledFor || undefined).toDate(), new Date(0)),
          sendingTo: get(() => renderName(invite), ''),
          message: '',
        };
      }
    );
    setMessages(invitationMessages || []);
  }, [dataInvitations]);

  useEffect(() => {
    refetchInvitations(variables);
  }, [refetchInvitations, variables]);

  return (
    <OutboxModal
      event={event}
      modalOpen={modalOpen}
      onClose={onClose}
      messages={messages}
      loadingMessages={loadingInvitations}
      deleteMessages={deleteMessages}
      customHeader="Pending Invitations"
    />
  );
};
