import React from 'react';

export interface Props {
  children: React.FunctionComponent<any>;
  getItems: (value?: string) => Promise<any>;
  debounce?: number;
  defaultValue?: string;
}

interface State {
  data: any[];
  value: string;
  loading: boolean;
}

class AsyncSearch extends React.Component<Props, State> {
  static defaultProps = {
    getItems: () => {},
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      value: props.defaultValue || '',
      data: [],
      loading: false,
    };
  }

  componentDidMount() {
    const { value } = this.state;
    this.onChange(value);
  }

  onChange = (value: string) => {
    const { getItems } = this.props;

    this.setState({ value, loading: true });
    return Promise.resolve(getItems(value)).then((data) => {
      if (this.state.value === value) {
        this.setState({ data, loading: false });
      }
    });
  };

  render() {
    const { children, ...props } = this.props;

    return children({
      ...this.state,
      ...props,
      onChange: this.onChange,
    });
  }
}

export default AsyncSearch;
