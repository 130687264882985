import { FlagName, FlagState } from 'utils/hooks/user/featureFlag';
import { isAuthenticated } from 'models';
import useUser from 'utils/hooks/user/useUser';

const useHasFeatureFlag = (flagName: FlagName): FlagState => {
  const { loading, user } = useUser();
  if (loading) {
    return undefined;
  }
  if (!isAuthenticated(user)) {
    return false;
  }

  return user.features?.includes(flagName);
};
export default useHasFeatureFlag;
