import { URLContext } from 'components/RoutingProvider';
import { VirtualVenueData } from 'components/VirtualVenue/types';
import { VirtualVenueDisplayProvider } from 'components/VirtualVenue/contexts/VirtualVenueDisplayContext';
import { VirtualVenueEditProvider } from 'components/pages/VirtualVenueEditPage/VirtualVenueEditContext';
import { VirtualVenueThemeProvider, defaultTheme } from 'components/VirtualVenue/contexts/VirtualVenueThemeContext';
import { getDefaultVirtualVenueData } from 'components/VirtualVenue/utils';
import { reverse } from 'shared/shared/routing/mixily-routes';
import MinimalLayout from 'components/layout/MinimalLayout/MinimalLayout';
import React, { useContext, useEffect } from 'react';
import VirtualVenueOuter from 'components/VirtualVenue/VirtualVenueDisplay/VirtualVenueOuter';
import VirtualVenueTryout from './VirtualVenueTryout';
import useUser from 'utils/hooks/user/useUser';

const defaultData: VirtualVenueData = getDefaultVirtualVenueData();

const VirtualVenueTryoutPage = () => {
  const { isAuthenticated } = useUser();
  const { setPath } = useContext(URLContext);

  // Only run on initial render
  // A user that signs up to save shouldn't be redirected
  // to the create page
  useEffect(() => {
    // Auth'd users don't need to trial, they can create a real venue
    if (isAuthenticated) {
      setPath(reverse('virtual_venue_create'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MinimalLayout title="Try Virtual Venue">
      <div>
        <div className="mt-12 text-5xl leading-none">Design Your Venue</div>

        <div className="mt-20">
          <VirtualVenueDisplayProvider initialSetting={{ mode: 'edit' }}>
            <VirtualVenueThemeProvider initialTheme={{ ...defaultTheme }}>
              <VirtualVenueEditProvider initialState={{ title: '', logo: '' }}>
                <VirtualVenueOuter virtualVenue={defaultData}>
                  <VirtualVenueTryout />
                </VirtualVenueOuter>
              </VirtualVenueEditProvider>
            </VirtualVenueThemeProvider>
          </VirtualVenueDisplayProvider>
        </div>
      </div>
    </MinimalLayout>
  );
};
export default VirtualVenueTryoutPage;
