import { URLContext } from 'components/RoutingProvider';
import { VIRTUAL_VENUES_BY_OWNER_QUERY, VIRTUAL_VENUE_CREATE_MUTATION } from 'utils/gql';
import { VirtualVenueCreate as VVCreateT, VirtualVenueCreateVariables } from 'generated/VirtualVenueCreate';
import { addSnackbarMessage } from 'utils/eventEmitter';
import { analyticsAPI as analytics } from 'utils/api';
import { reverse } from 'shared/shared/routing/mixily-routes';
import { useMutation } from '@apollo/client';
import { useUserId } from 'utils/hooks/user/useUser';
import { useVirtualVenueEditContext } from 'components/pages/VirtualVenueEditPage/VirtualVenueEditContext';
import { useVirtualVenueTheme } from 'components/VirtualVenue/contexts/VirtualVenueThemeContext';
import Button2 from 'components/common/Button2/Button2';
import React, { useContext } from 'react';
import VirtualVenueThemeEditor from 'components/VirtualVenueThemeEditor/VirtualVenueThemeEditor';

const VirtualVenueCreate = () => {
  const { setPath } = useContext(URLContext);
  const userId = useUserId();
  const theme = useVirtualVenueTheme();
  const { title: editedTitle, logo: editedLogo } = useVirtualVenueEditContext()!;

  const [virtualVenueCreate] = useMutation<VVCreateT, VirtualVenueCreateVariables>(VIRTUAL_VENUE_CREATE_MUTATION, {
    refetchQueries: [{ query: VIRTUAL_VENUES_BY_OWNER_QUERY, variables: { ownerId: userId! } }],
  });

  const onSubmit = () => {
    virtualVenueCreate({
      variables: {
        title: editedTitle || '',
        theme: {
          logo: editedLogo,
          ...theme,
        },
      },
    }).then((r) => {
      if (r.data?.virtualVenueCreate.ok) {
        const venueId = r.data.virtualVenueCreate.virtualVenue!.id;
        addSnackbarMessage('Virtual venue created! ✨', 'success');
        analytics.track('Created a new Virtual Venue (Virtual Venue | Virtual Venue Create Page)').finally(() => {
          setPath(reverse('virtual_venue_detail', { id: venueId }));
        });
      }
    });
  };

  return (
    <div>
      <VirtualVenueThemeEditor />
      <div className="flex flex-row-reverse items-center justify-between mt-8">
        <Button2 onClick={onSubmit}>Create new Venue</Button2>
      </div>
    </div>
  );
};

export default VirtualVenueCreate;
