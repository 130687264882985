import * as F from 'shared/shared/Functional';
import { ACCOUNT_LOGIN_MUTATION } from 'utils/gql';
import { AuthCallback } from 'components/auth-forms/BaseAuthForm';
import { AuthLogin, AuthLoginVariables } from 'generated/AuthLogin';
import { TextLink } from 'components/common';
import { errorCodeHandlers } from 'components/auth-forms/fields';
import { reverse } from 'router';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';

import FieldLayout from 'components/common/FieldLayout/FieldLayout';
import Input2 from 'components/common/Input2/Input2';
import Link from 'components/Link';
import React from 'react';
import isEmail from 'validator/lib/isEmail';
import styles from 'components/auth-forms/AuthForm.module.scss';
import useMutationFormHelpers from 'components/forms/useMutationFormHelpers';
import useUserChangeContext from 'components/App/useUserChangeContext';

interface Props {
  email?: string;
  next?: string;
  onRequestSignupFormModal?: AuthCallback;
  onRequestLoginFormModal?: AuthCallback;
  onNavigate?: AuthCallback;
}

type FormT = {
  email: string;
  password: string;
};

const EmailAuthLoginForm = (props: Props) => {
  const { email = '', next } = props;
  const { login } = useUserChangeContext();
  const [accountLogin, { loading }] = useMutation<AuthLogin, AuthLoginVariables>(ACCOUNT_LOGIN_MUTATION);
  const formContext = useForm<FormT>({ defaultValues: { email, password: '' } });

  const {
    Form,
    SubmitButton,
    FormLevelMessages,
    isSubmitting,
    onSubmit,
    FormStateContextProvider,
  } = useMutationFormHelpers<FormT, AuthLogin, AuthLoginVariables>({
    formContext,
    formToVariables: F.identity,
    mutation: accountLogin,
    resultKey: 'emailAuthLogin',
    onSuccess: (val) => {
      const apitoken = val.emailAuthLogin.apitoken!;
      const setNext = next || window.location.pathname;
      login(apitoken, true, setNext);
    },
  });

  const { errors, getValues, register } = formContext;
  const values = getValues();

  return (
    <div>
      <FormStateContextProvider onSubmit={onSubmit} isSubmitting={isSubmitting} formContext={formContext}>
        <Form>
          <FieldLayout
            label="Email address"
            error={errors.email}
            errorCodeHandlers={errorCodeHandlers.promptForRegistrationWhenEmailAuthNotFound}
          >
            <Input2
              type="email"
              name="email"
              size="lg"
              disabled={loading}
              ref={register({
                required: true,
                validate: (value: string) => (!isEmail(value) ? 'Please enter a valid email address' : true),
              })}
              placeholder="you@example.com"
              autoComplete="email"
            />
          </FieldLayout>

          <FieldLayout
            label="Password"
            className="mt-4"
            error={errors.password}
            errorCodeHandlers={errorCodeHandlers.promptForLoginWhenWrongPassword}
          >
            <Input2
              type="password"
              name="password"
              size="lg"
              disabled={loading}
              ref={register({
                required: true,
              })}
              autoComplete="current-password"
            />
          </FieldLayout>
          <SubmitButton label="Log In" submittingLabel="Logging In..." size="14" className="w-full mt-9 sm:w-full" />
        </Form>
      </FormStateContextProvider>
      <FormLevelMessages className="mt-4" />
      <div className={styles.AuthFooter}>
        {ResetPasswordButton(values, props.onNavigate)}
        <span>Don't have an account? {SignUpButton(values, props.onRequestSignupFormModal)}</span>
      </div>
    </div>
  );
};

const ResetPasswordButton = (credentials: AuthFormCredentials, onNavigate?: AuthCallback) => {
  const onClick = onNavigate ? onNavigate(credentials) : undefined;
  return (
    // Open in new window if we're in an embed frame
    <Link href={reverse('reset_password')} onClick={onClick} newWindow={window.top !== window.parent}>
      Reset password
    </Link>
  );
};

const SignUpButton = (credentials: AuthFormCredentials, onRequestSignupFormModal: AuthCallback | undefined) => {
  if (onRequestSignupFormModal) {
    return <TextLink onClick={onRequestSignupFormModal(credentials)}>Sign up</TextLink>;
  } else {
    return <Link href={reverse('account_signup')}>Sign up</Link>;
  }
};

export default EmailAuthLoginForm;
