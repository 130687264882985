// Hides the sidebar by default

import Container from 'components/layout/Container/Container';
import Footer from 'components/layout/Footer/Footer';
import Header from 'components/layout/Header/Header';
import React, { useEffect, useState } from 'react';
import Sidebar from 'components/layout/Sidebar/Sidebar';
import classNames from 'classnames';

interface Props {
  children: React.ReactNode;
  noDefaultPadding?: boolean;
  title: string;
}

const MinimalLayout = (props: Props) => {
  useEffect(() => {
    // set the title to something different first:
    // this works around a bug in Chrome where the tab title wasn't getting updated
    // repro: go to index while logged in, click back, IndexPage redirects back to Calendar,
    //        but title is now "Mixily"
    document.title = props.title + '.';
    document.title = props.title;
  });

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className="relative min-h-screen bg-body-color">
      <Sidebar handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} />
      <Header handleDrawerToggle={handleDrawerToggle} withSidebar />
      <Container className={classNames({ nopadding: props.noDefaultPadding })}>{props.children}</Container>
      <Footer />
    </div>
  );
};

export default MinimalLayout;
