import { Input, Loading } from 'components/common';
import { debouncePromise, memoizePromise } from 'utils/helpers';
import { giphyAPI } from 'utils/api';
import AsyncSearch from 'components/common/AsyncSearch';
import IPImageGrid from './IPImageGrid';
import IPTab from './IPTab';
import React from 'react';
import giphyImg from './giphy_200px-White_HorizText.png';
import styles from './ImagePicker.module.scss';

interface Props {
  searchValue?: string;
  onSelect: Function;
  setSearchValue: Function;
}

const giphyGetItems = memoizePromise(debouncePromise(giphyAPI.getItems, 300));

const GiphyTab: React.FC<Props> = (props) => {
  const { searchValue = '', onSelect, setSearchValue } = props;
  return (
    <IPTab>
      <AsyncSearch getItems={giphyGetItems} defaultValue={searchValue}>
        {({ value, data, loading, onChange }) => (
          <>
            <Input
              autoFocus
              placeholder="Search for an image..."
              size="small"
              value={value}
              className={styles.input}
              onChange={(e) => {
                const { value } = e.target;
                onChange(value);
                setSearchValue(value);
              }}
            />
            {loading ? <Loading /> : <IPImageGrid size="lg" images={data} onClick={onSelect} />}
            <small className={styles.info}>
              <img src={giphyImg} width="100" alt="" />
            </small>
          </>
        )}
      </AsyncSearch>
    </IPTab>
  );
};

export default GiphyTab;
