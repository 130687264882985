import { Drawer, IconButton } from '@material-ui/core';
import { EVENT_SCHEDULED_REMINDER_QUERY, OUTGOING_MESSAGES_QUERY } from 'utils/gql';
import { EventScheduledReminders, EventScheduledRemindersVariables } from 'generated/EventScheduledReminders';
import { InvitesByFilterT } from 'models/invite';
import { Message, Reminder } from 'components/MessageCenter/types';
import { OutgoingMessages, OutgoingMessagesVariables } from 'generated/OutgoingMessages';
import { useQuery } from '@apollo/client';
import CloseIcon from '@material-ui/icons/Close';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import MessageGuests from 'components/MessageCenter/MessageGuests';
import MessageList from 'components/MessageCenter/MessageList';
import React, { useState } from 'react';
import Tab from 'components/common/Tab/Tab';
import TextLink from 'components/common/TextLink';
import moment from 'moment';
import styles from './MessageCenter.module.scss';
import useEventClass from 'utils/hooks/event/useEventClass';

interface Props {
  invitesByFilter: InvitesByFilterT;
  totalGuests: number;
}

const MessageCenter = ({ invitesByFilter, totalGuests }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const { event, isDemo } = useEventClass();
  const { data: outgoingMessages } = useQuery<OutgoingMessages, OutgoingMessagesVariables>(OUTGOING_MESSAGES_QUERY, {
    variables: { eventId: event.id },
    skip: isDemo,
  });
  const { data: reminders } = useQuery<EventScheduledReminders, EventScheduledRemindersVariables>(
    EVENT_SCHEDULED_REMINDER_QUERY,
    {
      variables: { eventId: event.id },
      skip: isDemo,
    }
  );

  const isSent = (scheduledFor: string) => moment().isAfter(scheduledFor);
  const scheduledMessages = outgoingMessages?.scheduledMessages
    ? outgoingMessages.scheduledMessages.filter((message) => !isSent(message.scheduledFor))
    : [];

  const scheduledReminders = reminders?.eventScheduledReminders
    ? reminders.eventScheduledReminders
        .filter((reminder) => !reminder.sent)
        .sort((r1, r2) =>
          r1.scheduledFor && r2.scheduledFor ? moment(r1.scheduledFor).diff(moment(r2.scheduledFor)) : 0
        )
    : [];
  const combinedScheduledMessages = ([] as Array<Message | Reminder>).concat(scheduledMessages, scheduledReminders);

  const sentMessages = outgoingMessages?.scheduledMessages
    ? outgoingMessages.scheduledMessages.filter((message) => isSent(message.scheduledFor))
    : [];
  const sentReminders = reminders?.eventScheduledReminders
    ? reminders.eventScheduledReminders.filter((reminder) => reminder.sent)
    : [];
  const combinedSentMessages = ([] as Array<Message | Reminder>).concat(sentMessages, sentReminders);

  function onClose() {
    setOpen(false);
  }

  return (
    <>
      <TextLink onClick={isDemo ? undefined : () => setOpen(true)}>
        <EmailOutlinedIcon color="primary" className="opacity-75" />
        <span className="ml-1"> {totalGuests ? 'Message guests' : 'Schedule Messages'}</span>
      </TextLink>
      <Drawer anchor="right" open={open} onClose={onClose}>
        <div className={styles.content}>
          <div className="flex items-center justify-between h-20 pl-6 pr-2 md:pl-8 md:pr-4">
            <div className="text-2xl">Message Guests</div>
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon className="text-gray-600" />
            </IconButton>
          </div>
          <Tab
            className="w-full"
            menu={{ className: 'px-6 md:px-8' }}
            panes={[
              {
                title: 'Compose',
                content: () => (
                  <MessageGuests invitesByFilter={invitesByFilter} className="w-full px-6 pb-6 md:px-8 md:pb-8" />
                ),
              },
              {
                title: `Scheduled${combinedScheduledMessages.length ? ` (${combinedScheduledMessages.length})` : ''}`,
                content: () => (
                  <MessageList messages={combinedScheduledMessages} className="w-full px-6 pb-6 md:px-8 md:pb-8" />
                ),
              },
              {
                title: `Sent${combinedSentMessages.length ? ` (${combinedSentMessages.length})` : ''}`,
                content: () => (
                  <MessageList messages={combinedSentMessages} className="w-full px-6 pb-6 md:px-8 md:pb-8" />
                ),
              },
            ]}
          />
        </div>
      </Drawer>
    </>
  );
};

export default MessageCenter;
