// To add a new route, see https://www.notion.so/mixily/How-to-add-a-new-route-URL-path-3a25de00890045e4be757cdc87079dca

import { ExtraFrontendRoutes, RouteNameToComponent } from 'routes';
import { RouteNameToPath } from 'shared/shared/routing/mixily-routes';
import Router from 'shared/shared/routing/Router';

const router = new Router(RouteNameToPath, RouteNameToComponent, ExtraFrontendRoutes);

function resolve(path?: string) {
  path = path || window.location.pathname;
  return router.resolve(path);
}

export { reverse } from 'shared/shared/routing/mixily-routes';

export default resolve;
