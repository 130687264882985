import { RedirectToPath } from 'components/Redirect';
import { addSnackbarMessage } from 'utils/eventEmitter';
import React from 'react';

interface Props {
  path: string;
  params?: any;
  message: string;
}

const RedirectWithMessage = (props: Props) => {
  const { message, path } = props;
  addSnackbarMessage(message, 'success');
  return <RedirectToPath path={path} />;
};

export default RedirectWithMessage;
