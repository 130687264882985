import { Menu, MenuItem } from '@material-ui/core';
import { VenueRole } from 'generated/globalTypes';
import { useCallConfig } from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/contexts/CallConfigContext';
import { useVirtualVenue } from 'components/VirtualVenue/contexts/VirtualVenueContext';
import { useVirtualVenueCall } from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/contexts/VirtualVenueCallContext';
import {
  useVirtualVenueDispatch,
  useVirtualVenueState,
} from 'components/VirtualVenue/contexts/VirtualVenueStateContext';
import { useVirtualVenueDisplay } from 'components/VirtualVenue/contexts/VirtualVenueDisplayContext';
import { useVirtualVenuePubnub } from 'components/VirtualVenue/contexts/VirtualVenuePubnubContext';
import IconSvg from 'components/common/IconSvg/IconSvg';
import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import fscreen from 'fscreen';

interface Props {
  showOnHoverClassName: string;
  participantId: string;
  isMuted: boolean;
  isLocal: boolean;
}

const TileMenu = ({ showOnHoverClassName, participantId, isMuted, isLocal }: Props) => {
  const [msgMenuAnchorEl, setMsgMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [tileMenuOpen, setTileMenuOpen] = useState<boolean>(false);
  const venue = useVirtualVenue();
  const { pinnedParticipant } = useVirtualVenueState();
  const dispatch = useVirtualVenueDispatch();
  const { pubnubState, pubnubDispatch } = useVirtualVenuePubnub();
  const { spotlightParticipants } = pubnubState;
  const isOwner = venue.myAccess.role === VenueRole.OWNER;
  const { isLiveMode } = useVirtualVenueDisplay();
  const { isSpotlightMode } = useCallConfig();
  const virtualVenueCall = useVirtualVenueCall(true);
  const dailyCall = virtualVenueCall?.dailyState.call;

  const isPinnedParticipant = pinnedParticipant && pinnedParticipant === participantId;
  const isSpotlightParticipant = spotlightParticipants.includes(participantId);
  const menuItems = [];

  const muteParticipant = useCallback(() => {
    dailyCall?.call.updateParticipant(participantId, {
      setAudio: false,
    });
    setTileMenuOpen(false);
  }, [dailyCall?.call, participantId]);

  if (!isSpotlightMode) {
    if (isPinnedParticipant) {
      menuItems.push(
        <MenuItem
          key="unpin"
          onClick={() => {
            dispatch({ type: 'unpin-participant' });
            setTileMenuOpen(false);
          }}
        >
          <IconSvg name="lockOpen" className="w-4 h-4" />
          <span className="ml-1 text-sm">Unpin</span>
        </MenuItem>
      );
    } else {
      menuItems.push(
        <MenuItem
          key="pin"
          onClick={() => {
            dispatch({ type: 'pin-participant', participant: participantId });
            setTileMenuOpen(false);
          }}
        >
          <IconSvg name="lockClosed" className="w-4 h-4" />
          <span className="ml-1 text-sm">Pin</span>
        </MenuItem>
      );
    }
  }
  if (isOwner) {
    if (isSpotlightParticipant) {
      menuItems.push(
        <MenuItem
          key="remove-from-spotlight"
          onClick={() => {
            pubnubDispatch({ type: 'send-change-spotlight', op: 'remove', participant: participantId });
            setTileMenuOpen(false);
          }}
        >
          <IconSvg name="minusCircle" className="w-4 h-4" />
          <span className="ml-1 text-sm">Remove from spotlight</span>
        </MenuItem>
      );
    } else {
      menuItems.push(
        <MenuItem
          key="add-to-spotlight"
          onClick={() => {
            pubnubDispatch({ type: 'send-change-spotlight', op: 'add', participant: participantId });
            setTileMenuOpen(false);
          }}
        >
          <IconSvg name="userCircle" className="w-4 h-4" />
          <span className="ml-1 text-sm">Add to Spotlight</span>
        </MenuItem>
      );
    }
    if (!isMuted) {
      menuItems.push(
        <MenuItem key="mute" onClick={muteParticipant}>
          <IconSvg name="micOff" className="w-4 h-4" />
          <span className="ml-1 text-sm">Mute participant</span>
        </MenuItem>
      );
    } else if (!isLocal) {
      menuItems.push(
        <MenuItem
          key="unmute-request"
          onClick={() => {
            pubnubDispatch({ type: 'send-request-unmute', participant: participantId });
            setTileMenuOpen(false);
          }}
        >
          <IconSvg name="mic" className="w-4 h-4" />
          <span className="ml-1 text-sm">Request to unmute</span>
        </MenuItem>
      );
    }
  }

  return menuItems.length ? (
    <div
      className={classNames(
        showOnHoverClassName,
        ' bg-gray-600 bg-opacity-40 flex items-center justify-center h-5 w-4 rounded-md absolute right-0.5 top-1',
        {
          'pointer-events-none': !isLiveMode,
        }
      )}
    >
      <div
        className="flex items-center justify-center text-white transition duration-150 ease-in-out opacity-50 cursor-pointer hover:opacity-100"
        onClick={() => setTileMenuOpen(!tileMenuOpen)}
      >
        <IconSvg name="dotsVertical" className="w-4 h-4 text-white" ref={setMsgMenuAnchorEl} />
      </div>
      <Menu
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        anchorEl={msgMenuAnchorEl}
        keepMounted
        transitionDuration={0}
        open={tileMenuOpen}
        onClose={() => setTileMenuOpen(false)}
        // MUI Poppers that can be children of a fullscreened element must conditionally use that element as their container
        // to behave correctly. Otherwise if on body they aren't available in full screen, and if on the fullscreenable element, they can get cut off when not fullscreened.
        container={fscreen.fullscreenElement}
      >
        {menuItems}
      </Menu>
    </div>
  ) : (
    <></>
  );
};

export default TileMenu;
