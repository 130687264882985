import { Currency } from 'shared/shared/types';
import ActionButton from 'components/common/ActionButton/ActionButton';
import DeleteIcon from '@material-ui/icons/Close';
import DownIcon from '@material-ui/icons/ArrowDownward';
import React from 'react';
import TicketTypeWidget, {
  TicketTypeSettings,
} from 'components/EventCreateUpdateForm/TicketTypesWidget/TicketTypeWidget';
import UpIcon from '@material-ui/icons/ArrowUpward';

interface Props {
  currency: Currency;
  disabled: boolean;
  hasMany: boolean;
  isFirst: boolean;
  isLast: boolean;
  onChange: (value: TicketTypeSettings) => void;
  onDelete: () => void;
  onMoveDown: () => void;
  onMoveUp: () => void;
  showFeeCalculator: () => void;
  value: TicketTypeSettings;
}

const TicketTypeWidgetWrapper = (props: Props) => {
  const {
    currency,
    disabled,
    hasMany,
    isFirst,
    isLast,
    onChange,
    onDelete,
    onMoveDown,
    onMoveUp,
    showFeeCalculator,
    value,
  } = props;
  return (
    <div className="relative">
      {hasMany && (
        <div className="absolute top-1 right-1 hstack-thin">
          <ActionButton onClick={onMoveUp} disabled={isFirst}>
            <UpIcon />
          </ActionButton>
          <ActionButton onClick={onMoveDown} disabled={isLast}>
            <DownIcon />
          </ActionButton>
          <ActionButton onClick={onDelete} disabled={disabled}>
            <DeleteIcon />
          </ActionButton>
        </div>
      )}
      <TicketTypeWidget
        value={value}
        disabled={disabled}
        onChange={onChange}
        attrs={{
          currency,
          hideNameField: !hasMany,
          showPriceNote: isFirst,
          showFeeCalculator,
        }}
      />
    </div>
  );
};
export default TicketTypeWidgetWrapper;
