import {
  VIRTUAL_VENUE_RECORDINGS_WITH_VENUE_QUERY,
  VIRTUAL_VENUE_RECORDING_CREATE_MUTATION,
  VIRTUAL_VENUE_RECORDING_DELETE_MUTATION,
  VIRTUAL_VENUE_RECORDING_UPDATE_BY_EXT_RECORDING_ID_MUTATION,
  VIRTUAL_VENUE_RECORDING_WITH_VENUE_QUERY,
} from 'utils/gql';
import {
  VirtualVenueRecordingCreate,
  VirtualVenueRecordingCreateVariables,
} from 'generated/VirtualVenueRecordingCreate';
import {
  VirtualVenueRecordingDelete,
  VirtualVenueRecordingDeleteVariables,
} from 'generated/VirtualVenueRecordingDelete';
import {
  VirtualVenueRecordingUpdateByExtRecordingId,
  VirtualVenueRecordingUpdateByExtRecordingIdVariables,
} from 'generated/VirtualVenueRecordingUpdateByExtRecordingId';
import {
  VirtualVenueRecordingWithVenue,
  VirtualVenueRecordingWithVenueVariables,
} from 'generated/VirtualVenueRecordingWithVenue';
import { useCallback } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';

const useRecordingManager = () => {
  const [doCreateRecording, { data: createRecordingData }] = useMutation<
    VirtualVenueRecordingCreate,
    VirtualVenueRecordingCreateVariables
  >(VIRTUAL_VENUE_RECORDING_CREATE_MUTATION);

  const [doUpdateRecording] = useMutation<
    VirtualVenueRecordingUpdateByExtRecordingId,
    VirtualVenueRecordingUpdateByExtRecordingIdVariables
  >(VIRTUAL_VENUE_RECORDING_UPDATE_BY_EXT_RECORDING_ID_MUTATION);

  const [doFetchRecording, { loading, error, data: fetchRecordingData, startPolling, stopPolling }] = useLazyQuery<
    VirtualVenueRecordingWithVenue,
    VirtualVenueRecordingWithVenueVariables
  >(VIRTUAL_VENUE_RECORDING_WITH_VENUE_QUERY);

  const [doDeleteRecording, { loading: deleteLoading, error: deleteError, data: deleteData }] = useMutation<
    VirtualVenueRecordingDelete,
    VirtualVenueRecordingDeleteVariables
  >(VIRTUAL_VENUE_RECORDING_DELETE_MUTATION);

  const createRecording = useCallback(
    (venueId: string, extRoomId: string, extRecordingId: string) => {
      doCreateRecording({
        variables: {
          extRoomId,
          venueId,
          extRecordingId,
        },
      });
    },
    [doCreateRecording]
  );
  const updateRecording = useCallback(
    (extRecordingId, recordingStatus) => {
      doUpdateRecording({
        variables: {
          extRecordingId,
          recordingStatus,
        },
      });
    },
    [doUpdateRecording]
  );

  const fetchRecording = useCallback(
    (venueId: string, recordingId) => {
      doFetchRecording({
        variables: {
          venueId,
          recordingId,
        },
      });
    },
    [doFetchRecording]
  );

  const deleteRecording = useCallback(
    (recordingId, venueId, userId) => {
      doDeleteRecording({
        variables: {
          recordingId,
        },
        refetchQueries: [{ query: VIRTUAL_VENUE_RECORDINGS_WITH_VENUE_QUERY, variables: { venueId, userId } }],
      });
    },
    [doDeleteRecording]
  );

  const virtualVenueRecording =
    createRecordingData?.virtualVenueRecordingCreate.virtualVenueRecording ?? fetchRecordingData?.virtualVenueRecording;
  const virtualVenue = fetchRecordingData?.virtualVenue;

  return {
    createRecording,
    fetchRecording,
    updateRecording,
    deleteRecording,
    virtualVenueRecording,
    virtualVenue,
    loading,
    error,
    deleteData,
    deleteLoading,
    deleteError,
    startPolling,
    stopPolling,
  };
};

export default useRecordingManager;
