import React from 'react';

import { reverse } from 'router';
import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import Link from 'components/Link';

const Error404Page = () => (
  <BaseLayout title="Page not found">
    <p>
      Sorry, we can't find the page you're looking for. <Link href={reverse('home')}>Try returning home</Link>.
    </p>
  </BaseLayout>
);

export default Error404Page;
