import { VIRTUAL_VENUE_QUERY, VIRTUAL_VENUE_SET_CLOSED_MUTATION } from 'utils/gql';
import { VirtualVenueSetClosed, VirtualVenueSetClosedVariables } from 'generated/VirtualVenueSetClosed';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { useVirtualVenue } from 'components/VirtualVenue/contexts/VirtualVenueContext';
import FieldLayout from 'components/common/FieldLayout/FieldLayout';
import React, { useEffect } from 'react';
import TextArea2 from 'components/common/TextArea2';
import useMutationFormHelpers from 'components/forms/useMutationFormHelpers';
import usePrevious from 'utils/hooks/usePrevious';

interface Props {
  onClose: () => void;
}

type FormT = {
  isOpen?: boolean;
  awayMessage?: string;
};

const defaultAwayMessage = "We're closed for now. Come back for our next event!";

const CloseVenueForm = ({ onClose }: Props) => {
  const venue = useVirtualVenue();
  const { awayMessage, id, ownerId } = venue;
  const defaultValues = { awayMessage: awayMessage ?? defaultAwayMessage };
  const [changeClosed] = useMutation<VirtualVenueSetClosed, VirtualVenueSetClosedVariables>(
    VIRTUAL_VENUE_SET_CLOSED_MUTATION,
    {
      refetchQueries: [{ query: VIRTUAL_VENUE_QUERY, variables: { id, userId: ownerId } }],
    }
  );
  const formContext = useForm<FormT>({ defaultValues });

  const formHelpers = useMutationFormHelpers<FormT, VirtualVenueSetClosed, VirtualVenueSetClosedVariables>({
    formContext,
    formToVariables: (formData) => {
      const formAwayMessage = formData.awayMessage;
      const vars = {
        ...formData,
        isClosed: true,
        awayMessage: formAwayMessage && formAwayMessage.length > 0 ? formAwayMessage : defaultAwayMessage,
        id,
      };
      return vars;
    },
    mutation: changeClosed,
    resultKey: 'virtualVenueUpdate',
    successMessage: 'Virtual Venue Closed!!',
  });
  const { FormStateContextProvider, Form, SubmitButton, FormLevelMessages, isSubmitting, onSubmit } = formHelpers;
  const wasSubmitting = usePrevious(isSubmitting);
  const { watch, register, errors } = formContext;
  useEffect(() => {
    if (wasSubmitting && !isSubmitting) {
      onClose && onClose();
    }
  }, [isSubmitting, onClose, wasSubmitting]);
  return (
    <FormStateContextProvider onSubmit={onSubmit} isSubmitting={isSubmitting} formContext={formContext}>
      <Form>
        <div className="flex flex-col max-w-2xl">
          <FieldLayout
            label={'Away Message'}
            error={errors.awayMessage}
            helpText={`Max length: 250 characters (${watch('awayMessage')?.length ?? 0}/250)`}
            className="w-full"
          >
            <TextArea2
              name="awayMessage"
              maxLength={250}
              ref={register}
              disabled={isSubmitting}
              placeholder="We're closed for now. Come back for our next event!"
            />
          </FieldLayout>
          <SubmitButton label="Close My Venue" submittingLabel="Closing..." size="14" className="w-auto mt-5 ml-2" />
        </div>
        <FormLevelMessages className="inline-block mt-1" />
      </Form>
    </FormStateContextProvider>
  );
};

export default CloseVenueForm;
