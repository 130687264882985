import { EVENT_IMAGES_QUERY } from 'utils/gql';
import { EventImages } from 'generated/EventImages';
import { Loading } from 'components/common';
import { useQuery } from '@apollo/client';

import IPImageGrid from 'components/EventCreateUpdateForm/ImagePicker/IPImageGrid';
import IPTab from 'components/EventCreateUpdateForm/ImagePicker/IPTab';
import React from 'react';
import styles from 'components/EventCreateUpdateForm/ImagePicker/ImagePicker.module.scss';

interface Props {
  onSelect: Function;
  helpText: string;
}

const getEventImages = (data: any) => {
  const me = (data || {}).me || {};

  return (me.upcoming || []).concat(me.past || []).filter((event: any) => event.owner.id === me.id && event.themePic);
};

const MyEventsTab: React.FC<Props> = (props) => {
  const { onSelect, helpText } = props;
  const { data, loading, error } = useQuery<EventImages>(EVENT_IMAGES_QUERY);

  if (loading) {
    return (
      <IPTab>
        <Loading delay={0} />
      </IPTab>
    );
  }

  if (error) {
    return (
      <IPTab>
        return <div>There was an error loading the images.</div>
      </IPTab>
    );
  }

  const images = getEventImages(data);

  return (
    <IPTab>
      {images.length ? (
        <IPImageGrid images={images as any} onClick={onSelect} />
      ) : (
        <small className={styles.info}>{helpText}</small>
      )}
    </IPTab>
  );
};

export default MyEventsTab;
