import React from 'react';
import classNames from 'classnames';

import styles from './Icon.module.scss';

import addPerson from 'assets/icons/add-person-icon.svg';
import addPersonOrange from 'assets/icons/add-person-orange-icon.svg';
import calendar from 'assets/icons/calendar-icon.svg';
import calendarAdd from 'assets/icons/calendar-add-icon.svg';
import check from 'assets/icons/check-icon.svg';
import close from 'assets/icons/close.svg';
import comment from 'assets/icons/comment.svg';
import downloadOrange from 'assets/icons/download-orange-icon.svg';
import emailList from 'assets/icons/email-list.svg';
import envelope from 'assets/icons/envelope-icon.svg';
import envelopeOrange from 'assets/icons/envelope-orange-icon.svg';
import eye from 'assets/icons/eye-icon.svg';
import gear from 'assets/icons/gear-icon.svg';
import google from 'assets/icons/google-icon.svg';
import imageOutline from 'assets/icons/image-outline.svg';
import list from 'assets/icons/list.svg';
import no from 'assets/icons/no-icon.svg';
import noEye from 'assets/icons/no-eye-icon.svg';
import noPerson from 'assets/icons/no-person-icon.svg';
import pencil from 'assets/icons/pencil-icon.svg';
import person from 'assets/icons/person-icon.svg';
import pin from 'assets/icons/pin-icon.svg';
import questionMark from 'assets/icons/question-mark-icon.svg';
import threeDots from 'assets/icons/three-dots.svg';
import trash from 'assets/icons/trash.svg';

export type IconName =
  | 'calendar'
  | 'pin'
  | 'person'
  | 'downloadOrange'
  | 'envelope'
  | 'envelopeOrange'
  | 'addPersonOrange'
  | 'gear'
  | 'google'
  | 'eye'
  | 'noEye'
  | 'addPerson'
  | 'noPerson'
  | 'check'
  | 'questionMark'
  | 'no'
  | 'calendarAdd'
  | 'pencil'
  | 'threeDots'
  | 'close'
  | 'emailList'
  | 'list'
  | 'comment'
  | 'trash'
  | 'imageOutline';

interface Props {
  icon: IconName;
  size?: 'small' | 'medium' | 'large';
  className?: string;
}

const iconSrc: Record<IconName, string> = {
  addPerson,
  addPersonOrange,
  calendar,
  calendarAdd,
  check,
  downloadOrange,
  envelope,
  envelopeOrange,
  eye,
  gear,
  google,
  no,
  noEye,
  noPerson,
  person,
  pin,
  questionMark,
  pencil,
  threeDots,
  close,
  list,
  emailList,
  comment,
  trash,
  imageOutline,
};

const Icon = ({ icon, className, size = 'small' }: Props) => {
  const cls = classNames(styles.Icon, className, {
    [styles[`size-${size}`]]: size,
  });

  return (
    <div className={cls}>
      <img src={iconSrc[icon]} alt={`${icon} icon`} />
    </div>
  );
};

export default Icon;
