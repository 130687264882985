import { Switch } from '@material-ui/core';
import React from 'react';
import classNames from 'classnames';
import styles from './TimeTitle.module.scss';

interface Props {
  label: string;
  switchChecked?: boolean;
  onChange?: () => void;
}

const TimeTitle = (props: Props) => {
  const { label, switchChecked, onChange } = props;
  const labelClass = classNames(styles.label, onChange && 'cursor-pointer');
  return (
    <div className={styles.TimeTitle}>
      <div className={labelClass} onClick={onChange}>
        {label}
      </div>
      {onChange && <Switch checked={switchChecked} onChange={onChange} color="primary" />}
    </div>
  );
};

export default TimeTitle;
