// Used for testing layouts. Add `?colors=5` to the virtual venue URL to add 5 color tiles.

import React from 'react';

const ColorTile = ({ hsv }: { hsv: { h: number; s: number; v: number } }) => {
  const { h, s, v } = hsv;
  const colorStr = `hsl(${h},${s}%,${v}%)`;
  return <div className="w-full h-full" style={{ backgroundColor: colorStr }} />;
};

export default ColorTile;
