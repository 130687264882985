import { Button, Textarea } from 'components/common';
import { CREATE_COMMENT_MUTATION, THREAD_QUERY, UPDATE_COMMENT_MUTATION } from 'utils/gql';
import { CreateComment, CreateCommentVariables } from 'generated/CreateComment';
import { ThreadQuery } from 'generated/ThreadQuery';
import { UpdateComment, UpdateCommentVariables } from 'generated/UpdateComment';
import { addSnackbarMessage } from 'utils/eventEmitter';
import { getPsuedoRandomId } from 'utils/helpers';
import { useMutation } from '@apollo/client';

import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import styles from './CommentInput.module.scss';
import useOnClickOutside from 'utils/hooks/useOnClickOutside';

interface Props {
  eventId: string;
  threadId: string;
  onSubmit: (text?: string) => void;
  initialValue?: string;
  placeholder?: string;
  isValid?: (text: string) => boolean;
  submitText?: string;
  onBlur?: (e: Event) => void;
  autoFocus?: boolean;
  commentId?: string;
  replyCommentId?: string;
}

const CommentInput = ({
  submitText = 'Send',
  placeholder = 'Write something...',
  initialValue,
  autoFocus,
  threadId,
  commentId,
  eventId,
  replyCommentId,
  onSubmit,
  isValid,
  onBlur,
}: Props) => {
  const [value, setValue] = useState<string>(initialValue || '');
  const [focused, setFocused] = useState<boolean>(false);

  let textarea: HTMLTextAreaElement | undefined;

  useEffect(() => {
    if (autoFocus && textarea) {
      const { length } = textarea.value;
      textarea.setSelectionRange(length * 2, length * 2);
      textarea.focus();
    }
  }, [autoFocus, textarea]);

  const [createCommentMutation] = useMutation<CreateComment, CreateCommentVariables>(CREATE_COMMENT_MUTATION, {
    update: (cache, { data: createCommentData }) => {
      const comment = createCommentData?.eventCommentCreate.comment;

      const thread = cache.readQuery<ThreadQuery>({ query: THREAD_QUERY, variables: { id: threadId } })?.thread;

      if (comment && thread) {
        const updatedThread = {
          ...thread,
          comments: [...thread.comments, comment],
        };
        cache.writeQuery({
          query: THREAD_QUERY,
          data: { thread: updatedThread },
        });
      }
    },
  });
  const [updateCommentMutation] = useMutation<UpdateComment, UpdateCommentVariables>(UPDATE_COMMENT_MUTATION);

  const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      if (e.ctrlKey || e.metaKey || e.altKey || e.shiftKey) {
        setValue(e.currentTarget.value);
      } else {
        onCommentSubmit();
        e.preventDefault();
      }
    }
  };

  const onCommentSubmit = async () => {
    if (isCommentValid()) {
      await handleSubmitComment(value);
      onSubmit(value);
      setValue('');
    }
  };

  const handleSubmitComment = async (commentText: string) => {
    if (commentId) {
      const variables = { commentId, text: commentText };
      if (commentText.length === 0) {
        return;
      }

      const { data: updateCommentData } = await updateCommentMutation({ variables });

      if (!updateCommentData?.commentUpdate.ok) {
        addSnackbarMessage('Error updating comment', 'error');
      }
    } else {
      const nonce = `${getPsuedoRandomId()}.${Date.now()}`;
      const variables = {
        eventId,
        text: commentText,
        nonce,
        ...(replyCommentId ? { replyTo: replyCommentId } : {}),
      };
      const { data: createCommentData } = await createCommentMutation({ variables });

      if (!createCommentData?.eventCommentCreate.ok) {
        addSnackbarMessage('Error creating comment', 'error');
      }
    }
  };

  const isCommentValid = () => {
    return isValid ? isValid(value) : !!value.length;
  };

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, (e: Event) => {
    onBlur && onBlur(e);
  });

  return (
    <div className={styles.CommentInput} ref={ref}>
      <Textarea
        inputRef={(el: HTMLTextAreaElement) => (textarea = el)}
        className={styles.textarea}
        placeholder={placeholder || ''}
        onKeyDown={onKeyDown}
        onChange={(e) => setValue(e.currentTarget.value)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        value={value}
        autosize
      />
      <Button
        className={classNames(styles.button, {
          [styles.visible]: focused || isCommentValid,
        })}
        small
        handleClick={onCommentSubmit}
        disabled={!isCommentValid}
        label={submitText || ''}
      />
    </div>
  );
};

export default CommentInput;
