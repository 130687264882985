import * as m from 'models';
import { CREATE_EVENT_MUTATION, VIRTUAL_VENUES_BY_OWNER_QUERY, VIRTUAL_VENUE_CREATE_MUTATION } from 'utils/gql';
import { CreateEvent, CreateEventVariables } from 'generated/CreateEvent';
import { EventWithExistQuery_event } from 'generated/EventWithExistQuery';
import { ExecutionResult } from 'graphql';
import { MutationFunctionOptions, useMutation } from '@apollo/client';
import { VirtualVenueCreate, VirtualVenueCreateVariables } from 'generated/VirtualVenueCreate';
import { addSnackbarMessage } from 'utils/eventEmitter';
import { localStore } from 'utils/localstore';
import { reverse } from 'router';
import Cookie from 'js-cookie';
import React, { useCallback, useRef, useState } from 'react';
import RoutingProvider from 'components/RoutingProvider';
import UserChangeContext, { UserChangeManager } from 'components/App/UserChangeContext';
import queryUser, { UserResult } from 'api/query-user';
import useEventId from 'components/App/globalstate/useEventId';

interface Props {
  initialPath: string;
}

type CreateFn = (
  options?: MutationFunctionOptions<CreateEvent, CreateEventVariables> | undefined
) => Promise<ExecutionResult<CreateEvent>>;

type VirtualVenueCreateFn = (
  options?: MutationFunctionOptions<VirtualVenueCreate, VirtualVenueCreateVariables> | undefined
) => Promise<ExecutionResult<VirtualVenueCreate>>;

const UserAndRoutingProvider: React.FC<Props> = (props) => {
  const { initialPath } = props;
  const [user, setUser] = useState<m.User>(m.Unknown);
  const [loginRedirect, setLoginRedirect] = useState<string | undefined>(undefined);
  const eventId = useEventId();

  const routingProviderRef = useRef<RoutingProvider>(null);
  const [doCreateEvent] = useMutation<CreateEvent, CreateEventVariables>(CREATE_EVENT_MUTATION);
  const [doCreateVirtualVenue] = useMutation<VirtualVenueCreate, VirtualVenueCreateVariables>(
    VIRTUAL_VENUE_CREATE_MUTATION
  );
  const receiveUserValue = useCallback(
    (receivedUser: UserResult, showMessage: boolean, redirect?: string | false) => {
      const userChanged = !m.usersEqual(receivedUser, user);

      // Make sure to check for a loginRedirect value. This concerns a special
      // case with InviteReadPage, where the user might have already been logged
      // in as the right user when arriving on the invite read page.
      if (!userChanged && !loginRedirect) {
        return;
      }

      setUser(receivedUser);

      if (receivedUser === m.Unauthenticated) {
        //
        // We just logged out
        //
        addSnackbarMessage("You've been logged out", 'info');
        // TODO - stay on current page if user is allowed to be there (e.g. public event)
        routingProviderRef.current!.closeModals();

        if (redirect !== false) {
          routingProviderRef.current!.setPath(redirect || reverse('home'));
        }
      } else if (m.isAuthenticated(receivedUser)) {
        //
        // We just logged in
        //
        if (showMessage) {
          const msg = receivedUser.name ? `Signed in as ${receivedUser.name}!` : 'Signed in!';
          const verifyMsg = !receivedUser.auths?.isVerified ? ' Please confirm your email address' : '';
          addSnackbarMessage(msg + verifyMsg, 'success');
        }

        const demoVirtualVenue = localStore.demoVirtualVenue.get();
        // Auto-create demo event
        if (demoVirtualVenue) {
          createDemoVirtualVenue(demoVirtualVenue, routingProviderRef, doCreateVirtualVenue, receivedUser.id);
        } else if (eventId === m.DemoType.Id) {
          const demoEvent = localStore.demoEvent.get();
          if (demoEvent) {
            createDemo(demoEvent, routingProviderRef, doCreateEvent);
          }
        } else if (redirect) {
          // See https://www.notion.so/mixily/fix-redirect-next-param-on-signin-with-Google-auth-36da7553fbac402b8c6bb200370c4130
          routingProviderRef.current!.closeModals();
          routingProviderRef.current!.setPath(redirect);
        }
      }
    },
    [doCreateEvent, doCreateVirtualVenue, eventId, loginRedirect, user]
  );

  const login = useCallback(
    (apitoken: string, showLoginMessage: boolean, nextUrl?: string) => {
      if (!apitoken) {
        throw new Error('apitoken value missing in login');
      }
      // todo: remove event-specific RSVP localstorage for anon users
      // todo: remove event-specific ticket localstorage for anon users

      const oldToken = localStore.apitoken.get() || Cookie.get('apitoken');
      const tokenIsNew = apitoken !== oldToken;
      const newUrl = nextUrl && nextUrl !== loginRedirect;

      if (!tokenIsNew && !newUrl) {
        return;
      }

      localStore.apitoken.set(apitoken);
      setLoginRedirect(nextUrl);
      refetchUser(nextUrl, showLoginMessage, receiveUserValue);
    },
    [loginRedirect, receiveUserValue]
  );

  // Log out the user. Pass a `nextUrl` to redirect to, or `false` to skip redirect.
  const logout = useCallback(
    (nextUrl: string | false) => {
      // Log out the user. Pass a `nextUrl` to redirect to, or `false` to skip redirect.
      Cookie.remove('apitoken');
      localStore.apitoken.set('');
      // todo: mutation to delete session
      refetchUser(nextUrl, true, receiveUserValue);
    },
    [receiveUserValue]
  );

  const userState = getUserState(login, logout);
  return (
    <UserChangeContext.Provider value={userState}>
      <RoutingProvider initialPath={initialPath} ref={routingProviderRef} />
    </UserChangeContext.Provider>
  );
};

const refetchUser = async (
  redirect: string | false | undefined,
  showMessage: boolean,
  receiveUserValue: (receivedUser: UserResult, showMessage: boolean, redirect?: string | false) => void
): Promise<void> => {
  const result = await queryUser();
  if (!result.ok) {
    console.error('refetchUser error', result.errorMessage);
  } else {
    receiveUserValue(result.data, showMessage, redirect);
  }
};

const getUserState = (login: UserChangeManager['login'], logout: UserChangeManager['logout']): UserChangeManager => {
  return {
    login,
    logout,
  };
};

const createDemo = (
  demoEvent: EventWithExistQuery_event,
  routingProviderRef: React.RefObject<RoutingProvider>,
  doCreateEvent: CreateFn
) => {
  // Remove the demo event so that doCreateEvent doesn't get called multiple times
  localStore.demoEvent.set(null);
  localStore.creatingDemoEvent.set(true);

  // Transform event
  const event = {
    ...demoEvent,
    poll: demoEvent.poll?.options?.map((o) => o.text),
  };

  doCreateEvent({ variables: event })
    .then((result) => {
      const eventCreate = result.data?.eventCreate;
      if (eventCreate && eventCreate.ok) {
        const event = m.parseEvent(eventCreate.event!);
        routingProviderRef.current!.closeModals();
        routingProviderRef.current!.setPath(event.getUrl());
      }
    })
    .finally(() => localStore.creatingDemoEvent.set(false));
};

const createDemoVirtualVenue = (
  demoVirtualVenue: VirtualVenueCreateVariables,
  routingProviderRef: React.RefObject<RoutingProvider>,
  doCreateVirtualVenue: VirtualVenueCreateFn,
  userId: string
) => {
  localStore.demoVirtualVenue.set(null);
  localStore.creatingDemoVirtualVenue.set(true);

  doCreateVirtualVenue({
    variables: demoVirtualVenue,
    refetchQueries: [{ query: VIRTUAL_VENUES_BY_OWNER_QUERY, variables: { ownerId: userId } }],
  })
    .then((result) => {
      const virtualVenueCreate = result.data?.virtualVenueCreate;
      if (virtualVenueCreate && virtualVenueCreate.ok) {
        const virtualVenue = virtualVenueCreate.virtualVenue;
        if (virtualVenue?.id) {
          const virtualVenueURL = reverse('virtual_venue_list');
          routingProviderRef.current!.setPath(virtualVenueURL);
        }
        routingProviderRef.current!.closeModals();
      }
    })
    .finally(() => localStore.creatingDemoVirtualVenue.set(false));
};

export default UserAndRoutingProvider;
