// Form state and convenience components
//
// const { onSubmit, FormStateContextProvider, Form, SubmitButton, FormLevelMessages } = useFormHelpers<FormT>({formContext, handleSubmit})

import Form from './Form';
import FormStateContextProvider from './FormStateContextProvider';
import React from 'react';
import SubmitButton from './SubmitButton';
import useFormLevelState, { MixilyFormState } from 'components/forms/useFormLevelState';

type FormComponent = React.ComponentType<React.HTMLProps<HTMLFormElement>>;
export interface FormHelpers {
  // Form-level messages
  successMessage: string;
  formLevelError: string;
  setFormLevelError: (message: true | string) => void;
  setSuccessMessage: (message: string) => void;
  clearMessages: () => void;

  // Convenience components
  FormStateContextProvider: typeof FormStateContextProvider;
  Form: FormComponent;
  SubmitButton: typeof SubmitButton;
  FormLevelMessages: MixilyFormState['FormLevelMessages'];
}

function useFormHelpers(): FormHelpers {
  const formState = useFormLevelState();

  return {
    ...formState,
    Form,
    SubmitButton,
    FormStateContextProvider,
  };
}

export default useFormHelpers;
