import * as m from 'models';
import { EventUpdatePageContext } from 'components/pages/EventUpdatePageContext';
import { Loading } from 'components/common';
import { RedirectToPath } from 'components/Redirect';
import Error404Page from 'components/pages/Error404Page';
import EventLayout from 'components/layout/EventLayout/EventLayout';
import EventUpdateForm from 'components/EventCreateUpdateForm/EventCreateUpdateForm/EventUpdateForm';
import LoginRequired from 'components/LoginRequired';
import React, { useState } from 'react';
import useEvent from 'utils/hooks/event/useEvent';
import useSetEventId from 'components/App/globalstate/useSetEventId';

interface Props {
  id: string;
}

export type ThemePicCrop = {
  imageWidth?: number;
  imageHeight?: number;

  x: number;
  y: number;
  scale: number;
};

export type ThemePicState = {
  previewUrl: string;
  existingRemovedOnNextSave: boolean;
  editPosition: boolean;
  crop?: ThemePicCrop;
};

const EventUpdatePage: React.FC<Props> = ({ id }) => {
  useSetEventId(id);

  const { loading, error, event: eventData, eventExists, isDemo } = useEvent();

  const [themePic, setThemePic] = useState<ThemePicState>({
    previewUrl: '',
    existingRemovedOnNextSave: false,
    editPosition: false,
  });

  if (loading) {
    return (
      <EventLayout title="Loading...">
        <Loading />
      </EventLayout>
    );
  }

  if (!eventExists || error || (!loading && !eventData)) {
    return <Error404Page />;
  }

  const event = m.parseEvent(eventData);

  if (!eventData?.myPermissions?.canUpdate) {
    return <RedirectToPath path={event.getUrl()} />;
  }

  return (
    <LoginRequired ignore={isDemo}>
      <EventUpdatePageContext.Provider value={{ themePic, setThemePic }}>
        <EventLayout
          title={event.title}
          themePicUrl={themePic.existingRemovedOnNextSave ? '' : themePic.previewUrl || event.themePic}
          noDefaultPadding={true}
        >
          <EventUpdateForm event={event} />
        </EventLayout>
      </EventUpdatePageContext.Provider>
    </LoginRequired>
  );
};

export default EventUpdatePage;
