import { Button } from 'components/common';
import { makeAbsoluteUrl } from 'shared';
import Button2 from 'components/common/Button2/Button2';
import CopyToClipboard from 'react-copy-to-clipboard';
import Dialog from 'components/common/Dialog/Dialog';
import React, { useState } from 'react';

interface Props {
  id: string;
  buttonClassName?: string;
}

const EmbedListingDialog = ({ id, buttonClassName }: Props) => {
  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);

  function close() {
    setOpen(false);
  }

  const embedScript = makeAbsoluteUrl('script_embed');
  const snippet = `<div class="mixily-container" data-mixily-id="${id}" data-mixily-type="listing"></div><script defer src="${embedScript}"></script>`;

  return (
    <>
      <Button2 size="8" onClick={() => setOpen(true)} className={buttonClassName}>
        Embed in your site
      </Button2>
      <Dialog
        title="Embed in your site"
        isOpen={open}
        onClose={close}
        actions={[<Button key="done" label="Done" onClick={close} small />]}
        contentClassName="pb-0"
      >
        <div>
          <div className="mb-4">Click the snippet below to copy its contents</div>
          <CopyToClipboard
            text={snippet}
            onCopy={() => {
              setCopied(true);
              setTimeout(() => setCopied(false), 1000);
            }}
          >
            <pre className="p-3 text-gray-300 whitespace-pre-wrap bg-black rounded cursor-pointer hover:bg-trueblack transition-colors duration-300">
              {snippet}
            </pre>
          </CopyToClipboard>
          <div className="flex justify-end h-2">{copied ? 'Copied!' : ''}</div>
          <div className="mb-4">Then paste into your site's HTML for an embedded calendar</div>
        </div>
      </Dialog>
    </>
  );
};

export default EmbedListingDialog;
