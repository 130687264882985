import * as m from 'models';
import React from 'react';

interface Props {
  user: m.Person;
}

const AuthInfo = ({ user }: Props) => {
  const { auths } = user;
  const goog = !!auths?.google.length;
  const email = user.getPrimaryEmail();
  return (
    <div>
      Signed in {goog && 'via Google'} as <b>{email}</b>
    </div>
  );
};

export default AuthInfo;
