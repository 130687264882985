import { FEATURE_FLAGS } from 'shared/shared/json';
import { MenuItem } from './MenuItem';
import { reverse } from 'router';
import IconSvg from 'components/common/IconSvg/IconSvg';
import Logo from 'components/Logo';
import React from 'react';
import useHasFeatureFlag from 'utils/hooks/user/useHasFeatureFlag';

const ICON_CLASS =
  'mr-4 h-6 w-6 text-gray-300 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150';

export const SidebarContent = () => {
  const virtualVenue = useHasFeatureFlag(FEATURE_FLAGS.virtualVenue);

  return (
    <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
      <div className="flex items-center flex-shrink-0 px-4">
        <Logo fill="white" forSidebar />
      </div>
      <nav className="px-2 mt-5 space-y-1">
        <MenuItem href={reverse('event_calendar')}>
          <IconSvg name="calendar" className={ICON_CLASS} />
          Calendar
        </MenuItem>
        {virtualVenue && (
          <MenuItem href={reverse('virtual_venue_list')}>
            <IconSvg name="userGroup" className={ICON_CLASS} />
            Virtual Venues
          </MenuItem>
        )}
        <MenuItem href={reverse('lists')}>
          <IconSvg name="users" className={ICON_CLASS} />
          Contact Lists
        </MenuItem>
        <MenuItem href={reverse('dashboard_events')}>
          <IconSvg name="chartBar" className={ICON_CLASS} />
          Dashboard
        </MenuItem>
        <MenuItem href={reverse('listing_index')}>
          <IconSvg name="collection" className={ICON_CLASS} />
          Listing Page
        </MenuItem>
      </nav>
    </div>
  );
};
