import { CSVLink } from 'react-csv';
import { Column, Row, useTable } from 'react-table';
import { IconButton, Tooltip, useMediaQuery } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import DownloadIcon from '@material-ui/icons/GetApp';
import NarrowIcon from '@material-ui/icons/CropPortrait';
import React from 'react';
import WideIcon from '@material-ui/icons/AspectRatio';
import classNames from 'classnames';
import theme from 'components/App/MuiTheme';

interface Props<T extends Record<string, any>> {
  data: T[];
  columns: Column<T>[];
  loading: boolean;
  downloadData?: any[];
  downloadFilename?: string;
  wide?: boolean;
  onSetWide?: (wide: boolean) => void;
  onClickRow?: (row: Row<T>) => void;
}

function DashboardTable<T extends Record<string, any>>({
  data,
  columns,
  loading,
  downloadData,
  downloadFilename,
  wide,
  onSetWide,
  onClickRow,
}: Props<T>) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<T>({
    columns,
    data,
  });
  const smallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const hasActions = (onSetWide && !smallScreen) || downloadData;

  return (
    <div className="min-w-full">
      <div className={classNames('flex items-center justify-end mb-1', { '-mt-12': hasActions })}>
        {downloadData && (
          <CSVLink data={downloadData} filename={downloadFilename}>
            <Tooltip title="Download CSV" arrow={true}>
              <IconButton>
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          </CSVLink>
        )}
        {onSetWide && !smallScreen && (
          <Tooltip title="Toggle Widescreen" arrow={true}>
            <IconButton className="ml-2" onClick={() => onSetWide(!wide)}>
              {wide ? <NarrowIcon /> : <WideIcon />}
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div className="min-w-full overflow-x-scroll overflow-y-hidden border-b border-gray-200 shadow sm:rounded-lg">
        <table className="min-w-full" {...getTableProps()}>
          <thead className="bg-white">
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id || index}>
                {headerGroup.headers.map((column, columnIndex) => (
                  <th
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase truncate border-b border-gray-200 border-solid bg-gray-50 leading-4"
                    {...column.getHeaderProps()}
                    key={column.id || columnIndex}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="bg-white" {...getTableBodyProps()}>
            {loading &&
              [1, 2, 3, 4, 5, 6].map((i) => (
                <tr key={i}>
                  {columns.map((column, columnIndex) => {
                    return (
                      <td
                        key={column.id || columnIndex}
                        className="px-6 py-4 align-middle border-b border-gray-200 border-solid whitespace-nowrap"
                      >
                        <Skeleton variant="text" />
                      </td>
                    );
                  })}
                </tr>
              ))}
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  className={
                    onClickRow
                      ? 'cursor-pointer duration-200 transition-colors ease-linear hover:bg-gray-100'
                      : undefined
                  }
                  onClick={onClickRow ? () => onClickRow(row) : undefined}
                  {...row.getRowProps()}
                  key={row.id}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        className="px-6 py-4 align-middle border-b border-gray-200 border-solid whitespace-nowrap"
                        {...cell.getCellProps()}
                        key={cell.column.id}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default DashboardTable;
