import Bowser from 'bowser';

export const isUnsupportedDesktopSafari = (): boolean => {
  // See https://bugs.webkit.org/show_bug.cgi?id=218762
  // and https://github.com/twilio/twilio-video.js/issues/1296
  const browser = Bowser.getParser(window.navigator.userAgent);
  const isUnsupported = browser.satisfies({
    desktop: {
      safari: '<14.0.2',
    },
  });
  return !!isUnsupported;
};

export const isUnsupportedMobileSafari = (): boolean => {
  const browser = Bowser.getParser(window.navigator.userAgent);
  const isUnsupported = browser.satisfies({
    iOS: {
      safari: '<14.0.2',
    },
  });
  return !!isUnsupported;
};

export const isUnsupportedMobile = (): boolean => {
  // See https://docs.daily.co/docs/browsers
  const browser = Bowser.getParser(window.navigator.userAgent);
  const isUnsupported = browser.satisfies({
    iOS: {
      firefox: '>0',
      chrome: '>0',
    },
  });
  return !!isUnsupported;
};

export const canUseVolumeMeter = (): boolean => {
  // See https://docs.daily.co/docs/browsers
  const browser = Bowser.getParser(window.navigator.userAgent);
  const isUnsupported =
    browser.satisfies({
      desktop: {
        firefox: '>0',
      },
      iOS: {
        safari: '>0',
      },
    }) || iOSDevice();
  return !isUnsupported;
};

// adapted from https://stackoverflow.com/a/9039885
export const iOSDevice = () => {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};
