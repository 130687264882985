import * as F from 'shared/shared/Functional';
import React from 'react';
import Route from 'route-parser';

export class NoRouteFound extends Error {
  constructor(m: string) {
    super(m);
    // Set the prototype explicitly.
    // See https://stackoverflow.com/questions/31626231/custom-error-class-in-typescript
    Object.setPrototypeOf(this, NoRouteFound.prototype);
  }
}

export interface IResolve {
  page: React.ElementType<any>;
  args: Record<string, string>;
}

class Router<RouteName extends string> {
  private nameToRoute: Record<RouteName, Route>;

  constructor(
    routeToPath: Record<RouteName, string>,
    private routeToComponent: Record<RouteName, React.ElementType>,

    // 'extra' paths are stubby (e.g. redirect or test) paths that the server would never need to know about
    private extraFrontendPathToComponent: Record<string, React.ElementType>
  ) {
    this.nameToRoute = F.objMapValues(routeToPath, (path) => new Route(path)) as any;
  }

  public resolve = (path: string): IResolve => {
    for (const name in this.nameToRoute) {
      const route = this.nameToRoute[name];
      const match = route.match(path);
      if (match) {
        return {
          page: this.routeToComponent[name],
          args: match,
        };
      }
    }
    for (const frontendRoute in this.extraFrontendPathToComponent) {
      const route = new Route(frontendRoute);
      const match = route.match(path);
      if (match) {
        return {
          page: this.extraFrontendPathToComponent[frontendRoute],
          args: match,
        };
      }
    }
    throw new NoRouteFound('Unable to resolve path: ' + path);
  };
}

export default Router;
