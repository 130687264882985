// Fees
export const STRIPE_PERCENTAGE_FEE = 0.029;
export const MIXILY_PER_TICKET_FEE = 30;
export const MIXILY_PERCENTAGE_FEE = 0.01;

// STRIPE comment on flat fees and conversion:
// While 0.30 will always be USD and fixed, generally, you can't rely on the users
// destination being accurate and even less so for wallets like Google Pay, Apple
// Pay etc. So, we'd suggest considering a solution like charging a flat sticker
// price for your application fee.
// -*-
// The max conversion rate in the last 5 years has been 1.44, so 0.3 * 1.44 = 0.432
export const STRIPE_FLAT_FEE_IN_CAD = 44;
export const STRIPE_FLAT_FEE_IN_USD = 30;

export const API_TOKEN_HEADER_NAME = 'X-Mixily-API-Token';

export enum ErrorCodes {
  EMAIL_AUTH_ALREADY_EXISTS = 'EMAIL_AUTH_ALREADY_EXISTS',
  EMAIL_AUTH_NOT_FOUND = 'EMAIL_AUTH_NOT_FOUND',
  EMAIL_AUTH_NO_PASSWORD = 'EMAIL_AUTH_NO_PASSWORD',
  EMAIL_INVALID = 'EMAIL_INVALID ',
  GOOGLE_AUTH_ALREADY_EXISTS = 'GOOGLE_AUTH_ALREADY_EXISTS',
  ORDER_COMPLETED = 'ORDER_COMPLETED',
}

export const errorMapper: { [key in ErrorCodes]?: string } = {
  ORDER_COMPLETED:
    'Sorry, guests have already bought tickets to this event. Please email support@mixily.com for assistance',
};

export enum HttpStatus {
  CONTINUE = 100,
  SWITCHING_PROTOCOLS = 101,
  PROCESSING = 102,
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  NON_AUTHORITATIVE_INFORMATION = 203,
  NO_CONTENT = 204,
  RESET_CONTENT = 205,
  PARTIAL_CONTENT = 206,
  AMBIGUOUS = 300,
  MOVED_PERMANENTLY = 301,
  FOUND = 302,
  SEE_OTHER = 303,
  NOT_MODIFIED = 304,
  TEMPORARY_REDIRECT = 307,
  PERMANENT_REDIRECT = 308,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  PAYMENT_REQUIRED = 402,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  NOT_ACCEPTABLE = 406,
  PROXY_AUTHENTICATION_REQUIRED = 407,
  REQUEST_TIMEOUT = 408,
  CONFLICT = 409,
  GONE = 410,
  LENGTH_REQUIRED = 411,
  PRECONDITION_FAILED = 412,
  PAYLOAD_TOO_LARGE = 413,
  URI_TOO_LONG = 414,
  UNSUPPORTED_MEDIA_TYPE = 415,
  REQUESTED_RANGE_NOT_SATISFIABLE = 416,
  EXPECTATION_FAILED = 417,
  I_AM_A_TEAPOT = 418,
  MISDIRECTED = 421,
  UNPROCESSABLE_ENTITY = 422,
  FAILED_DEPENDENCY = 424,
  TOO_MANY_REQUESTS = 429,
  INTERNAL_SERVER_ERROR = 500,
  NOT_IMPLEMENTED = 501,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504,
  HTTP_VERSION_NOT_SUPPORTED = 505,
}

export enum SubscriptionType {
  FREE = 'free',
  TICKETING = 'ticketing',
  INDIVIDUAL = 'individual',
  COMMUNITY = 'community',
  INDIVIDUAL_LEGACY = 'individualLegacy',
  COMMUNITY_LEGACY = 'communityLegacy',
  ENTERPRISE = 'enterprise',
}

export enum Plans {
  FREE = 'Starter',
  INDIVIDUAL = 'Individual',
  SUBSCRIPTION = 'Community',
  INDIVIDUAL_LEGACY = 'Individual Legacy',
  COMMUNITY_LEGACY = 'Community Legacy',
  ENTERPRISE = 'Enterprise',
  TICKETING = 'Ticketing',
}

export type FeatureValue = boolean | number;
export type FeatureName = 'canRecord' | 'maxVenues' | 'maxParticipants' | 'noTicketFees';
export type Features = Record<string, FeatureValue>;
export interface SubscriptionPlan {
  name: Plans;
  type: SubscriptionType;
  features: Features;
  price: number;
}

export const VirtualVenueFreePlan: SubscriptionPlan = {
  name: Plans.FREE,
  type: SubscriptionType.FREE,
  features: {
    canRecord: false,
    maxVenues: 1,
    maxParticipants: 10,
  },
  price: 0,
};

export const VirtualVenueIndividualPlan: SubscriptionPlan = {
  name: Plans.INDIVIDUAL,
  type: SubscriptionType.INDIVIDUAL,
  features: {
    canRecord: true,
    maxVenues: 5,
    maxParticipants: 50,
  },
  price: 30,
};

export const VirtualVenueCommunityPlan: SubscriptionPlan = {
  name: Plans.SUBSCRIPTION,
  type: SubscriptionType.COMMUNITY,
  features: {
    canRecord: true,
    maxVenues: 10,
    maxParticipants: 200,
  },
  price: 100,
};

export const VirtualVenueIndividualPlanLegacy: SubscriptionPlan = {
  name: Plans.INDIVIDUAL_LEGACY,
  type: SubscriptionType.INDIVIDUAL_LEGACY,
  features: {
    canRecord: true,
    maxVenues: 5,
    maxParticipants: 50,
  },
  price: 10,
};

export const VirtualVenueCommunityPlanLegacy: SubscriptionPlan = {
  name: Plans.COMMUNITY_LEGACY,
  type: SubscriptionType.COMMUNITY_LEGACY,
  features: {
    canRecord: true,
    maxVenues: 10,
    maxParticipants: 200,
  },
  price: 30,
};

export const VirtualVenueEnterprisePlan: SubscriptionPlan = {
  name: Plans.ENTERPRISE,
  type: SubscriptionType.ENTERPRISE,
  features: {
    canRecord: true,
    maxVenues: 100,
    maxParticipants: 200,
  },
  price: 100,
};

export const MixilyPremiumTicketingPlan: SubscriptionPlan = {
  name: Plans.TICKETING,
  type: SubscriptionType.TICKETING,
  features: {
    canRecord: false,
    maxVenues: 1,
    maxParticipants: 20,
    noTicketFees: true,
  },
  price: 10,
};

export const SubscriptionPlansByPriceId: { [priceId: string]: SubscriptionPlan } = {
  // ------ TEST MODE ----------

  // Test Mode Virtual Venue Community $100
  price_1JbdzHCJDlOVwnB8jVe5lN7l: VirtualVenueCommunityPlan,

  // Test Mode Virtual Venue Individual $30
  price_1JbdypCJDlOVwnB8HVMAtRMI: VirtualVenueIndividualPlan,

  // Test Mode Virtual Venue Community $30 (LEGACY)
  price_1IB0nyCJDlOVwnB8Q4Gjtjxk: VirtualVenueCommunityPlanLegacy,

  // Test Mode Virtual Venue Individual $10 (LEGACY)
  price_1ImsWTCJDlOVwnB8vfZhkNlH: VirtualVenueIndividualPlanLegacy,

  // Test Mode Mixily Premium
  price_1HkIVVCJDlOVwnB8V4sOIDUn: MixilyPremiumTicketingPlan,

  // ------ LIVE MODE ----------

  // Live Mode Virtual Venue Community $100
  price_1JbeQQCJDlOVwnB8jbT3Q2yL: VirtualVenueCommunityPlan,

  // Live  Mode Virtual Venue Individual $30
  price_1JbePrCJDlOVwnB81GQjDNE5: VirtualVenueIndividualPlan,

  // Live Mode Virtual Venue Community $30 (LEGACY)
  price_1IAzudCJDlOVwnB8uzO3eJGZ: VirtualVenueCommunityPlanLegacy,

  // LIVE Mode Virtual Venue Individual $10 (LEGACY)
  price_1ImtWLCJDlOVwnB8Z8SLcM5F: VirtualVenueIndividualPlanLegacy,

  // Live Mode Mixily Premium $10
  price_1Hjwg1CJDlOVwnB84RGwKxa6: MixilyPremiumTicketingPlan,

  '': VirtualVenueFreePlan,
};

export const SubscriptionPlansByType: { [type: string]: SubscriptionPlan } = {
  [SubscriptionType.FREE]: VirtualVenueFreePlan,
  [SubscriptionType.INDIVIDUAL]: VirtualVenueIndividualPlan,
  [SubscriptionType.COMMUNITY]: VirtualVenueCommunityPlan,
  [SubscriptionType.INDIVIDUAL_LEGACY]: VirtualVenueIndividualPlanLegacy,
  [SubscriptionType.COMMUNITY_LEGACY]: VirtualVenueCommunityPlanLegacy,
  [SubscriptionType.TICKETING]: MixilyPremiumTicketingPlan,
  [SubscriptionType.ENTERPRISE]: VirtualVenueEnterprisePlan,
};

export const GOOGLE_SCOPES = 'openid profile email';

export const SENSITIVE_SCOPE = 'https://www.googleapis.com/auth/calendar.events';

export const MAX_CAPACITY_FREE_EVENTS = 300;
