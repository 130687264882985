import React from 'react';
import useStreamVolume from 'utils/hooks/useStreamVolume';
interface Props {
  deviceId: string;
}

const VolumeMeter = ({ deviceId }: Props) => {
  const volume = useStreamVolume(deviceId, 50);
  const widthPct = Math.min(volume * 10, 100);

  return (
    <div>
      <div className="w-full shadow bg-grey-light">
        <div
          className="py-3 text-xs leading-none text-center text-white bg-blue-400"
          style={{ width: `${widthPct + 10}%` }}
        ></div>
      </div>
    </div>
  );
};

export default VolumeMeter;
