import Icon, { IconName } from 'components/common/Icon/Icon';
import LocationIcon, { LocationIconName } from 'components/common/LocationIcon';
import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './IconWithText.module.scss';

interface Props {
  icon: IconName | LocationIconName;
  children: ReactNode;
  className?: string;
  size?: 'small' | 'xsmall';
  alignTop?: boolean;
  streaming?: boolean;
}

const IconWithText = ({ icon, className, size, children, alignTop, streaming }: Props) => {
  const cls = classNames(
    styles.IconWithText,
    {
      [styles.IconWithTextAlignTop]: alignTop,
      [styles.small]: size === 'small',
      [styles.xsmall]: size === 'xsmall',
      [styles.medium]: !size,
    },
    className
  );
  return (
    <div className={cls}>
      {!streaming && <Icon icon={icon as IconName} className={styles.Icon} size={!size ? 'medium' : 'small'} />}
      {streaming && (
        <div className={styles.LocationIcon}>
          <LocationIcon icon={icon as LocationIconName} className={styles.Icon} size={24} />
        </div>
      )}
      <div className={classNames({ [styles.StreamingText]: streaming }, styles.Text)}>{children}</div>
    </div>
  );
};

export default IconWithText;
