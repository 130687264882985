import { ThemePicCrop } from 'components/pages/EventUpdatePage';
import EventThemePic from 'components/pages/EventReadPage/EventThemePic';
import React from 'react';
import styles from './ThemePicBlurryBackdrop.module.scss';

interface Props {
  imageUrl: string;
  crop?: ThemePicCrop;
  backgroundColor?: string;
  disabled?: boolean;
}

const ThemePicBlurryBackdrop = (props: Props) => {
  return (
    <div className={styles.ThemePicBlurryBackdrop} style={{ backgroundColor: props.backgroundColor }}>
      <div className={styles.inner}>
        {!props.disabled && <EventThemePic imageUrl={props.imageUrl} crop={props.crop} style={{ height: '100vh' }} />}
      </div>
    </div>
  );
};

export default ThemePicBlurryBackdrop;
