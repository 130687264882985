import { CREATE_ROLE_MUTATION, ROLES_QUERY, ROLE_INVITES_QUERY } from 'utils/gql';
import { CreateRole, CreateRoleVariables } from 'generated/CreateRole';
import { RoleType } from 'shared/shared/types';
import { addSnackbarMessage } from 'utils/eventEmitter';
import { useMutation } from '@apollo/client';

import FieldLayout from 'components/common/FieldLayout/FieldLayout';
import React, { useEffect } from 'react';
import RoleInviteWidget from 'components/pages/EventReadPage/roles/RoleInviteWidget';
import useControlledMutationForm from 'components/forms/useControlledMutationForm';
import useEventId from 'components/App/globalstate/useEventId';

interface Props {
  className?: string;
}

type FormT = {
  email: string;
};

const defaultValues = {
  email: '',
};

const validators = {
  email: {
    required: true,
  },
};

const role = RoleType.host;

const RoleInviteForm: React.FC<Props> = ({ className }) => {
  const eventId = useEventId();
  const [createRole] = useMutation<CreateRole, CreateRoleVariables>(CREATE_ROLE_MUTATION, {
    refetchQueries: [
      { query: ROLES_QUERY, variables: { eventId } },
      { query: ROLE_INVITES_QUERY, variables: { eventId } },
    ],
  });
  const mxForm = useControlledMutationForm<FormT, CreateRole, CreateRoleVariables>({
    defaultValues,
    validators,
    mutation: createRole,
    formToVariables: (formData) => {
      return {
        ...formData,
        eventId: eventId || 'foo',
        role,
      };
    },
    resultKey: 'createRole',
  });

  const {
    onChange,
    formMethods,
    isSubmitting,
    successResult,
    Form,
    SubmitButton,
    FormLevelMessages,
    FormStateContextProvider,
    onSubmit,
  } = mxForm;

  useEffect(() => {
    if (successResult) {
      onChange('email', '', false);
      addSnackbarMessage(
        `${successResult.createRole.label} ${
          successResult.createRole.isInvite ? 'invited to' : 'added as'
        } co-host to this event`,
        'success'
      );
    }
  }, [onChange, successResult]);

  return (
    <div className={className}>
      <FormStateContextProvider onSubmit={onSubmit} isSubmitting={isSubmitting} formContext={formMethods}>
        <Form>
          <div className="flex max-w-2xl">
            <FieldLayout
              className="w-full mt-1 mr-2"
              error={formMethods.errors.email}
              label={<div className="label-overline">Invite by email</div>}
            >
              <RoleInviteWidget
                value={formMethods.watch('email')}
                onChange={(val) => onChange('email', val)}
                disabled={isSubmitting}
              />
            </FieldLayout>
            <SubmitButton label="Invite" submittingLabel="Inviting..." className="mt-7" />
          </div>
          <FormLevelMessages className="inline-block mt-1" />
        </Form>
      </FormStateContextProvider>
    </div>
  );
};

export default RoleInviteForm;
