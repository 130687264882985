import { AsyncSearchInput } from 'components/common';
import { CONTACT_SEARCH_QUERY } from 'utils/gql';
import { ContactSearch_contactSearch } from 'generated/ContactSearch';
import { WidgetProps } from 'components/widgets';
import { debouncePromise, prettyEmail } from 'utils/helpers';
import { queryToPromise } from 'api/utils';
import React, { useState } from 'react';

type Props = string;
type Contact = ContactSearch_contactSearch;
interface Attrs {}

const searchContacts = (value?: string) =>
  queryToPromise(CONTACT_SEARCH_QUERY, { query: value }).then((resp) => {
    return resp.data.contactSearch;
  });

const searchContactsDebounced = debouncePromise(searchContacts, 300);

const RoleInviteWidget: React.FC<WidgetProps<Props, Attrs>> = (props) => {
  const { value, onChange } = props;
  const [selectedContact, setSelectedContact] = useState<Contact | undefined>();

  function onSelect(contact: Contact) {
    onChange(contact.email);
    setSelectedContact(contact);
  }

  function onChangeValue(value: string) {
    setSelectedContact(undefined);
    onChange(value);
  }

  return (
    <AsyncSearchInput
      value={value && selectedContact ? prettyEmail(selectedContact) : value}
      showResults={({ isOpen, isHover, value }) => (isOpen || isHover) && value && value.length && !selectedContact}
      getItems={searchContactsDebounced}
      getItemValue={prettyEmail}
      onSelect={onSelect}
      onChangeValue={onChangeValue}
      inputProps={{
        placeholder: 'email@domain.com',
      }}
    />
  );
};

export default RoleInviteWidget;
