import * as React from 'react';

interface Props {
  checked?: boolean;
  colorValue: string;
}

function RadioButtonIcon(props: Props) {
  const { colorValue } = props;

  return (
    <div>
      <svg height="24" width="24" viewBox="0 0 24 24">
        <circle cx="12" cy="12" r="10" stroke="#ADADAD" strokeWidth="1" fill={colorValue} />
      </svg>
    </div>
  );
}

export default React.memo(RadioButtonIcon);
