import { SectionData } from 'components/pages/FaqPage/types';
import Question from 'components/pages/FaqPage/Question';
import React from 'react';

type Props = SectionData;

const sectionTitleToId = (title: string): string => {
  return title.replace(' ', '-').toLowerCase();
};

const Section = ({ title, questions }: Props) => {
  return (
    <div className="max-w-lg mt-12">
      <div className="text-3xl font-extrabold" id={sectionTitleToId(title)}>
        {title}
      </div>
      {questions.map((q) => (
        <Question key={q.question} {...q} />
      ))}
    </div>
  );
};

export default Section;
