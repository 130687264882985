import CustomVirtualVenueContent from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueBroadcastDisplay/VirtualVenueBroadcastContent/VirtualVenueBroadcastContent';
import React from 'react';
import VirtualVenueDisplayLayout from 'components/VirtualVenue/VirtualVenueDisplay/layouts/VirtualVenueDisplayLayout/VirtualVenueDisplayLayout';

const VirtualVenueBroadcastDisplay = () => {
  return (
    <VirtualVenueDisplayLayout>
      <CustomVirtualVenueContent />
    </VirtualVenueDisplayLayout>
  );
};

export default VirtualVenueBroadcastDisplay;
