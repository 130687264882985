import { Quill } from 'react-quill';
import { clamp } from 'utils/helpers';
import ImageBlot from 'components/widgets/QuillRichTextWidget/ImageBlot';
import styles from './ImageDragAndResize.module.scss';
import type { Quill as QuillT } from 'quill';

class ImageDragAndResize {
  quill: QuillT;
  options: any;

  img?: HTMLImageElement | null;
  overlay?: HTMLDivElement | null;

  resizeDirection = 1;
  resizeStartWidth = 0;
  resizeStartX = 0;

  constructor(editor: QuillT, options = {}) {
    this.quill = editor;
    this.options = options;

    editor.root.addEventListener('mousedown', this.handleMouseDown, false);
    editor.root.addEventListener('keydown', this.handleInput, false);
  }

  private readonly handleMouseDown = (e: MouseEvent) => {
    const tagName = (e.target as HTMLElement)?.tagName || '';

    if (tagName.toUpperCase() !== 'IMG') {
      this.close();
      return;
    }

    const img = e.target! as HTMLImageElement;

    if (this.img !== img) {
      this.close();
      this.open(img);
    }
  };

  private readonly open = (img: HTMLImageElement) => {
    if (!this.overlay) {
      const parent = this.quill.root.parentElement!;

      const overlay = document.createElement('div');
      overlay.classList.add(styles.overlay);
      this.updateOverlayPosition(overlay, img);
      parent.appendChild(overlay);
      this.applyOverlayDragHandlers(overlay, img);

      const handles = [
        this.createResizeHandle('nwse-resize', { top: 0, left: 0 }),
        this.createResizeHandle('nesw-resize', { top: 0, left: '100%' }),
        this.createResizeHandle('nesw-resize', { top: '100%', left: 0 }),
        this.createResizeHandle('nwse-resize', { top: '100%', left: '100%' }),
      ];

      handles.forEach((it) => overlay.appendChild(it));

      const blot = Quill.find(img);
      if (blot) {
        const index = this.quill.getIndex(blot);
        this.quill.setSelection(index, 1, 'user');
      }

      this.img = img;
      this.overlay = overlay;
    }
  };

  private readonly close = () => {
    if (this.overlay) {
      this.overlay.parentElement?.removeChild(this.overlay);
      this.overlay = null;
    }

    this.img = null;
  };

  private readonly updateOverlayPosition = (overlay: HTMLDivElement, img: HTMLImageElement) => {
    const parent = this.quill.root.parentElement!;
    const imgRect = img.getBoundingClientRect();
    const containerRect = parent.getBoundingClientRect();

    const style = {
      position: 'absolute',
      left: `${imgRect.left - containerRect.left - 1 + parent.scrollLeft}px`,
      top: `${imgRect.top - containerRect.top - 1 + parent.scrollTop}px`,
      width: `${imgRect.width}px`,
      height: `${imgRect.height}px`,
    };

    Object.assign(overlay.style, style);
  };

  private readonly applyOverlayDragHandlers = (el: HTMLElement, img: HTMLImageElement) => {
    el.setAttribute('draggable', 'true');

    el.addEventListener('dragstart', (e: DragEvent) => {
      if (!e.dataTransfer) return;

      if (e.target instanceof HTMLElement) {
        e.target.style.cursor = 'grab';
      }

      const imgRect = img.getBoundingClientRect();
      const dragOffsetX = clamp(e.clientX - imgRect.left, 0, img.width);
      const dragOffsetY = clamp(e.clientY - imgRect.top, 0, img.height);

      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.dropEffect = 'move';
      e.dataTransfer.setDragImage(img, dragOffsetX, dragOffsetY);
      return true;
    });

    el.addEventListener('drag', (e: DragEvent) => {
      const el = document.elementFromPoint(e.clientX, e.clientY);
      if (!el) return;
      const blot = Quill.find(el);
      if (blot) {
        const index = blot === this.quill ? this.quill.getLength() : this.quill.getIndex(blot);
        this.quill.setSelection(index, 0);
      }
    });

    el.addEventListener('dragend', (e: DragEvent) => {
      this.close();

      const el = document.elementFromPoint(e.clientX, e.clientY);
      if (!el) return;

      const blot = Quill.find(el);
      if (!blot) return;

      // add new image
      const index = blot === this.quill ? this.quill.getLength() : this.quill.getIndex(blot);
      this.quill.insertEmbed(index, 'image', ImageBlot.value(img), 'user');

      // remove existing image
      const imgBlot = Quill.find(img);
      if (imgBlot) {
        const index = this.quill.getIndex(imgBlot);
        this.quill.deleteText(index, 1, 'user');
      }
    });
  };

  private readonly createResizeHandle = (cursor: string, extraStyle: any): HTMLDivElement => {
    const box = document.createElement('div');
    box.classList.add(styles.resizeHandle);
    box.style.cursor = cursor;
    Object.assign(box.style, extraStyle);

    const resizeInit = (e: MouseEvent | TouchEvent) => {
      e.preventDefault();
      this.beginResize(e, extraStyle.left ? 1 : -1);
    };

    box.addEventListener('mousedown', resizeInit);
    box.addEventListener('touchstart', resizeInit);

    return box;
  };

  private readonly beginResize = (e: MouseEvent | TouchEvent, resizeDirection = 1) => {
    if (!this.img) return;

    const clientX = (e as TouchEvent).touches ? (e as TouchEvent).touches[0].clientX : (e as MouseEvent).clientX;

    this.resizeStartX = clientX;
    this.resizeStartWidth = this.img.width || this.img.naturalWidth;
    this.resizeDirection = resizeDirection;

    document.addEventListener('mousemove', this.handleResize);
    document.addEventListener('mouseup', this.endResize);
    document.addEventListener('touchmove', this.handleResize);
    document.addEventListener('touchend', this.endResize);
    document.addEventListener('touchcancel', this.endResize);

    this.quill.root.parentElement!.classList.add('hide-toolbar');
  };

  private readonly handleResize = (e: MouseEvent | TouchEvent) => {
    if (!this.img) return;

    // update image size
    const clientX = (e as TouchEvent).touches ? (e as TouchEvent).touches[0].clientX : (e as MouseEvent).clientX;
    const deltaX = clientX - this.resizeStartX;
    this.img.width = Math.round(this.resizeStartWidth + deltaX * this.resizeDirection);

    if (this.overlay) {
      this.updateOverlayPosition(this.overlay, this.img);
    }
  };

  private readonly endResize = (e: MouseEvent | TouchEvent) => {
    document.removeEventListener('mousemove', this.handleResize);
    document.removeEventListener('mouseup', this.endResize);
    document.removeEventListener('touchmove', this.handleResize);
    document.removeEventListener('touchend', this.endResize);
    document.removeEventListener('touchcancel', this.endResize);

    this.quill.root.parentElement!.classList.remove('hide-toolbar');
  };

  private readonly handleInput = (e: KeyboardEvent) => {
    if (!this.img) return;

    if (e.keyCode === 46 || e.keyCode === 8) {
      const blot = Quill.find(this.img);
      blot && blot.deleteAt(0);
    }

    this.close();
  };
}

export default ImageDragAndResize;
