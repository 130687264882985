import { CREATE_INVITE_MUTATION, OUTGOING_INVITATIONS_QUERY } from 'utils/gql';
import { CreateInvite, CreateInviteVariables } from 'generated/CreateInvite';
import { useMutation } from '@apollo/client';

export const useInviteMutation = (eventId: string) => {
  const [doInvite] = useMutation<CreateInvite, CreateInviteVariables>(CREATE_INVITE_MUTATION, {
    refetchQueries: [
      { query: OUTGOING_INVITATIONS_QUERY, variables: { eventId } },
      { query: OUTGOING_INVITATIONS_QUERY, variables: { eventId, includeSent: false } },
    ],
  });
  return doInvite;
};
