import { Currency, CurrencyType } from 'shared/shared/types';
import IntegerInput, { Props as IntegerInputProps } from 'components/common/IntegerInput/IntegerInput';
import React from 'react';

type Props = IntegerInputProps & { currency: Currency };

const MoneyInput = (props: Props, ref: Props['ref']) => {
  const { layoutProps, currency, ...rest } = props;
  // https://ontariotraining.net/writing-style-currencies/
  const prefix = currency === CurrencyType.CAD ? 'CAD' : 'US$';
  return (
    <IntegerInput
      layoutProps={{
        ...layoutProps,
        startAdornment: {
          component: <span className="text-gray-500 sm:text-sm sm:leading-5">{prefix}</span>,
          paddingSize: 11,
        },
      }}
      {...rest}
      ref={ref}
    />
  );
};

export default React.forwardRef(MoneyInput);
