import { Drawer, IconButton } from '@material-ui/core';
import { Invite, isRsvpResponse, sortedInvitesForRsvpState } from 'models/invite';
import CloseIcon from '@material-ui/icons/Close';
import MessageListEntry from 'components/RSVPMessages/MessageListEntry';
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined';
import React, { useState } from 'react';
import TextLink from 'components/common/TextLink';
import classNames from 'classnames';
import styles from './RSVPMessages.module.scss';
import useEventClass from 'utils/hooks/event/useEventClass';

interface Props {
  invites: Invite[];
}

interface MessageListProps {
  invites: Array<Invite>;
  className?: string;
}

const RSVPMessages = ({ invites }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const { isDemo } = useEventClass();
  const filtered = (invites ?? []).filter((invite) => isRsvpResponse(invite.state!) && !invite.isHost);
  const sorted = sortedInvitesForRsvpState(filtered);

  function onClose() {
    setOpen(false);
  }

  if (sorted.length < 1) {
    return null;
  }

  return (
    <>
      <TextLink onClick={isDemo ? undefined : () => setOpen(true)}>
        <MessageOutlinedIcon color="primary" className="opacity-75" />
        <span className="ml-1"> RSVP Messages</span>
      </TextLink>
      <Drawer anchor="right" open={open} onClose={onClose}>
        <div className={styles.content}>
          <div className="flex items-center justify-between h-20 pl-6 pr-2 md:pl-8 md:pr-4">
            <div className="text-2xl">RSVP Messages</div>
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon className="text-gray-600" />
            </IconButton>
          </div>
          <MessageList invites={sorted} className="w-full px-6 pb-6 md:px-8 md:pb-8" />
        </div>
      </Drawer>
    </>
  );
};

const MessageList = ({ invites, className }: MessageListProps) => {
  return invites.length ? (
    <>
      <ul className={className}>
        {invites.map((invite) => (
          <MessageListEntry key={invite.id} invite={invite} />
        ))}
      </ul>
    </>
  ) : (
    <div className={classNames(className, 'pt-4 flex items-center justify-center')}>No Messages</div>
  );
};

export default RSVPMessages;
