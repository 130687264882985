import * as m from 'models';
import { Invite, getInviteCountsByState, makeInvitesByFilter, makeInvitesByState } from 'models/invite';
import { Link } from 'components/common';
import { reverse } from 'shared/shared/routing/mixily-routes';
import DashboardIcon from '@material-ui/icons/AssignmentOutlined';
import GuestList from 'components/EventGuestList/GuestList';
import GuestListModal from 'components/EventGuestList/GuestListModal';
import ManuallyAddRSVPButton from 'components/pages/EventReadPage/ManuallyAddRSVPButton';
import MessageCenter from 'components/MessageCenter/MessageCenter';
import RSVPMessages from 'components/RSVPMessages/RSVPMessages';
import React, { useState } from 'react';
import SummaryOverline from 'components/EventGuestList/EventGuestListView/SummaryOverline/SummaryOverline';
import styles from './EventGuestListView.module.scss';
import useEventPermissions from 'utils/hooks/event/useEventPermissions';

interface Props {
  event: m.Event;
  invites: Invite[];
}

const EventGuestListView = (props: Props) => {
  const { event, invites } = props;
  const { permissions } = useEventPermissions();
  const { canMessageGuests, canUpdate } = permissions;
  const invitesByState = makeInvitesByState(invites);
  const countsByState = getInviteCountsByState(invites);
  const [open, setOpen] = useState<boolean>(false);
  const totalGuests = invites.length - 1;

  return (
    <div>
      <div className={styles.GuestListDetailSection}>
        <div className={styles.GuestListHeading}>Guest List</div>
        <div className="mt-2">
          <SummaryOverline countsByState={countsByState} setOpen={setOpen} isPast={event.isPast} />
        </div>
        <div className="mt-4">
          <GuestList invitesByState={invitesByState} canDeleteInvites={canUpdate} />
        </div>
        <GuestListModal
          invitesByState={invitesByState}
          countsByState={countsByState}
          invites={invites}
          canDeleteInvites={canUpdate}
          open={open}
          setOpen={setOpen}
        />
      </div>

      {(canUpdate || canMessageGuests) && <hr className={'thin-divider'} />}

      <div className={styles.GuestListActionSection}>
        {canMessageGuests && (
          <div className="mt-4">
            <MessageCenter invitesByFilter={makeInvitesByFilter(invitesByState)} totalGuests={totalGuests} />
          </div>
        )}

        {canMessageGuests && (
          <div className="mt-4">
            <RSVPMessages invites={invites} />
          </div>
        )}

        {canUpdate && (
          <div className="mt-4">
            <ManuallyAddRSVPButton />
          </div>
        )}

        {canMessageGuests && (
          <div className="mt-4">
            <Link href={reverse('dashboard_event', { id: event.id })}>
              <DashboardIcon color="primary" className="mr-3 -mt-1 -ml-1 opacity-75" />
              Event Dashboard
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventGuestListView;
