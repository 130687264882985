import * as m from 'models';
import { ApolloError, useMutation } from '@apollo/client';
import { DELETE_EVENT_MUTATION, EVENTS_FOR_PERSON_QUERY } from 'utils/gql';
import { DeleteEvent, DeleteEventVariables } from 'generated/DeleteEvent';
import { EventsForPerson } from 'generated/EventsForPerson';
import { addSnackbarMessage } from 'utils/eventEmitter';
import { localStore } from 'utils/localstore';

import { ErrorCodes, errorMapper } from 'shared/shared/constants';
import Confirm2 from 'components/common/Confirm2/Confirm2';
import ErrorMessage from 'components/common/ErrorMessage/ErrorMessage';
import React from 'react';

interface Props {
  eventId: string;
  open: boolean;
  onSuccess: () => void;
  onCancel: () => void;
}

const getErrorMessage = (data?: DeleteEvent | null, error?: ApolloError): string => {
  if (error) {
    return 'Sorry, something went wrong';
  }
  if (data?.eventDelete.errors?.length) {
    const message = data.eventDelete.errors[0].message;
    if (message === ErrorCodes.ORDER_COMPLETED) {
      return errorMapper[ErrorCodes.ORDER_COMPLETED]!;
    }
    return message;
  }
  return '';
};

const ConfirmDelete = (props: Props) => {
  const { open, eventId, onSuccess, onCancel } = props;
  const [doDelete, { loading, data, error }] = useMutation<DeleteEvent, DeleteEventVariables>(DELETE_EVENT_MUTATION, {
    errorPolicy: 'all',
    update: (cache, { data: result }) => {
      if (!result?.eventDelete.ok) return;
      const cacheData = cache.readQuery<EventsForPerson>({
        query: EVENTS_FOR_PERSON_QUERY,
      });

      const upcoming = cacheData?.me?.upcoming?.filter((e) => e?.id !== eventId);
      const past = cacheData?.me?.past?.filter((e) => e?.id !== eventId);

      cache.writeQuery<EventsForPerson>({
        query: EVENTS_FOR_PERSON_QUERY,
        data: {
          me: {
            ...cacheData?.me,
            upcoming,
            past,
          } as any,
        },
      });
    },
  });
  const onConfirm = async () => {
    if (eventId === m.DemoType.Id) {
      localStore.demoEvent.set(null);
      addSnackbarMessage('Event deleted', 'info');
      onSuccess();
    } else {
      doDelete({ variables: { id: eventId } }).then((result) => {
        if (result.data?.eventDelete.ok) {
          addSnackbarMessage('Event deleted', 'info');
          onSuccess();
        }
      });
    }
  };
  const errorMessage = getErrorMessage(data, error);
  return (
    <Confirm2
      title={`Are you sure?`}
      onClose={onCancel}
      onConfirm={onConfirm}
      isOpen={open}
      disabled={loading}
      yesLabel={loading ? 'Deleting...' : 'Yes, delete'}
      yesConfig={{ color: 'red-outline' }}
      noLabel={'Cancel'}
    >
      <div>Are you sure you want to delete this event? This can't be undone.</div>
      {errorMessage && <ErrorMessage className="mt-6">{errorMessage}</ErrorMessage>}
    </Confirm2>
  );
};

export default ConfirmDelete;
