import { ReadVirtualVenue_virtualVenue } from 'generated/ReadVirtualVenue';
import { URLContext } from 'components/RoutingProvider';
import { VIRTUAL_VENUES_BY_OWNER_QUERY, VIRTUAL_VENUE_UPDATE_MUTATION } from 'utils/gql';
import { VirtualVenueUpdate, VirtualVenueUpdateVariables } from 'generated/VirtualVenueUpdate';
import { addSnackbarMessage } from 'utils/eventEmitter';
import { reverse } from 'shared/shared/routing/mixily-routes';
import { useMutation } from '@apollo/client';
import { useUserId } from 'utils/hooks/user/useUser';
import { useVirtualVenueEditContext } from 'components/pages/VirtualVenueEditPage/VirtualVenueEditContext';
import { useVirtualVenueTheme } from 'components/VirtualVenue/contexts/VirtualVenueThemeContext';
import Button2 from 'components/common/Button2/Button2';
import React, { useContext } from 'react';
import VirtualVenueDeleteButton from 'components/pages/VirtualVenueEditPage/VirtualVenueDeleteButton';
import VirtualVenueThemeEditor from 'components/VirtualVenueThemeEditor/VirtualVenueThemeEditor';

interface Props {
  virtualVenue: ReadVirtualVenue_virtualVenue;
}

const VirtualVenueEdit = ({ virtualVenue }: Props) => {
  const { setPath } = useContext(URLContext);
  const userId = useUserId();
  const theme = useVirtualVenueTheme();
  const { title: editedTitle, logo: editedLogo } = useVirtualVenueEditContext()!;

  const [virtualVenueUpdate] = useMutation<VirtualVenueUpdate, VirtualVenueUpdateVariables>(
    VIRTUAL_VENUE_UPDATE_MUTATION,
    {
      refetchQueries: [{ query: VIRTUAL_VENUES_BY_OWNER_QUERY, variables: { ownerId: userId! } }],
    }
  );

  const { id, title } = virtualVenue;

  const onSubmit = () => {
    virtualVenueUpdate({
      variables: {
        id,
        title: editedTitle !== title ? editedTitle : undefined,
        theme: {
          logo: editedLogo,
          ...theme,
        },
      },
    }).then((r) => {
      if (r.data?.virtualVenueUpdate.ok) {
        addSnackbarMessage('Virtual theme updated! ✨', 'success');
        setPath(reverse('virtual_venue_detail', { id }));
      }
    });
  };

  return (
    <div>
      <VirtualVenueThemeEditor />
      <div className="flex flex-row-reverse items-center justify-between mt-8">
        <Button2 onClick={onSubmit}>Save Changes</Button2>
        <VirtualVenueDeleteButton virtualVenue={virtualVenue} />
      </div>
    </div>
  );
};

export default VirtualVenueEdit;
