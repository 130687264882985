import * as F from 'shared/shared/Functional';
import { Emoji } from 'emoji-mart';
import { EmojiReaction, pubnubApi } from 'components/VirtualVenue/pubnub/pubnub';
import IconSvg from 'components/common/IconSvg/IconSvg';
import React from 'react';
import classNames from 'classnames';
import styles from './EmojiReactionsDisplay.module.scss';

interface Props {
  reactions: Record<string, EmojiReaction[]>;
  onToggleMyReaction?: (emoji: string, myReaction?: EmojiReaction) => void;
  onAddReaction?: () => void;
}

const EmojiReactionsDisplayInner = ({ reactions, onToggleMyReaction, onAddReaction }: Props) => {
  return (
    <div className={classNames(styles.MessageReactionsContainer, 'flex flex-wrap')}>
      {F.objMap(reactions, (emoji: string, reactions: EmojiReaction[]) => {
        const myReaction = reactions.find((r) => {
          return r.uuid === pubnubApi.getUUID();
        })!;
        const numReactions = reactions.length;
        const numDigits = numReactions.toString().length;
        return (
          <button
            className={classNames(
              'inline-flex items-center px-2 py-0.5 rounded-full',
              'text-xs font-medium bg-gray-200 bg-opacity-10 mr-2 my-1',
              {
                'hover:bg-opacity-50': !myReaction,
                'ring-grey-500 ring-2': !!myReaction,
              }
            )}
            key={`emoji-${emoji}`}
            onClick={() => {
              onToggleMyReaction && onToggleMyReaction(emoji, myReaction);
            }}
          >
            <Emoji emoji={emoji} native={true} size={14} />
            <span
              className={classNames('text-gray-800 pl-1 pr-0.5')}
              style={{ fontSize: '11px', width: `calc(${numDigits} * 10px)` }}
            >
              {numReactions}
            </span>
          </button>
        );
      })}
      {onAddReaction && (
        <button
          className={classNames(
            'inline-flex items-center px-2 py-0.5 rounded-full',
            'text-xs font-medium bg-gray-200 bg-opacity-10 hover:bg-opacity-50 mr-2 my-1'
          )}
          key={`add-emoji`}
          onClick={onAddReaction}
        >
          <IconSvg className="w-3 h-3 text-gray-800" name="plus" />
        </button>
      )}
    </div>
  );
};
export default EmojiReactionsDisplayInner;
