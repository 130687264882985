import { SelectAttrs, makeOptions } from 'components/widgets/MuiSelectWidget/MuiSelectWidget';
import React, { DetailedHTMLProps, SelectHTMLAttributes, useMemo } from 'react';
import Select from '@material-ui/core/Select';

export type SelectBuiltinProps = DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>;

export interface Props extends SelectBuiltinProps {
  ref?: React.Ref<HTMLSelectElement>;
  attrs?: SelectAttrs;
  id?: string;
  value?: number;
}

const SelectInput = (props: Props, ref: React.Ref<HTMLSelectElement>) => {
  const { attrs, value, ...rest } = props;
  if (!attrs?.options) {
    throw new Error('SelectInpu requires attrs.options');
  }
  const options = useMemo(() => makeOptions(attrs.options), [attrs.options]);
  return (
    <Select native fullWidth={!attrs?.noFullWidth} variant="outlined" value={value} inputProps={rest as any} ref={ref}>
      {options}
    </Select>
  );
};

export default React.forwardRef(SelectInput);
