import { Tooltip } from '@material-ui/core';
import React, { ReactNode } from 'react';
import styles from './DisableInPreview.module.scss';
import useViewAsContext from 'components/context/ViewAsContext/useViewAsContext';

interface Props {
  children: ReactNode;
}

const DisableInPreview = (props: Props) => {
  const { isPreview } = useViewAsContext();
  if (!isPreview) {
    return <>{props.children}</>;
  }
  return (
    <Tooltip arrow title="Disabled in preview">
      <div className={styles.DisableInPreview}>{props.children}</div>
    </Tooltip>
  );
};

export default DisableInPreview;
