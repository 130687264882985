import { DialogContent, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialog, { DialogProps } from '@material-ui/core/Dialog';
import React, { ReactElement, ReactNode } from 'react';

export interface Props {
  actions?: ReactElement;
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  size?: DialogProps['maxWidth'];
  title?: string;
  container?: DialogProps['container'];
}

// This is helpful for Dialog2 users that render a form. It allows you to render the form buttons
// within a form element contained in the dialog. (If the form is in `children`, the buttons
// will be outside.)
export const actionStyles = 'mt-8 sm:mt-10 sm:flex sm:flex-row-reverse';

export default function Dialog2(props: Props) {
  const { isOpen, onClose, title, children, actions, container, size = 'sm' } = props;

  return (
    <MuiDialog onClose={onClose} open={isOpen} fullWidth maxWidth={size} container={container}>
      <DialogContent className="px-4 pt-5 pb-4 overflow-hidden text-black transition-all transform bg-white rounded-lg shadow-xl sm:p-6">
        <div className="absolute top-0 right-0 pt-4 pr-3 sm:pr-4">
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon className="text-grey-800" />
          </IconButton>
        </div>
        {title && <div className="mt-2 text-2xl font-bold leading-6 text-gray-900">{title}</div>}
        <div className="mt-8 sm:mt-8 sm:mb-8">{children}</div>
        {actions && <div className={actionStyles}>{actions}</div>}
      </DialogContent>
    </MuiDialog>
  );
}
