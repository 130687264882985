import { isAuthenticated } from 'models';
import Container from 'components/layout/Container/Container';
import Footer from 'components/layout/Footer/Footer';
import Header from 'components/layout/Header/Header';
import React, { useState } from 'react';
import RootLayout from 'components/layout/RootLayout/RootLayout';
import Sidebar from 'components/layout/Sidebar/Sidebar';
import useUser from 'utils/hooks/user/useUser';

interface Props {
  title: string;
  children: React.ReactNode;
  className?: string;
  hideTitle?: boolean;
  wide?: boolean;
}

const BaseLayout = (props: Props) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const { user } = useUser();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <RootLayout title={props.title} className={props.className}>
      <div className="flex">
        {isAuthenticated(user) && (
          <Sidebar handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} usePermanent />
        )}
        <div className="relative z-0 flex-1 overflow-y-auto lg:mx-16">
          {/* Use min-height to avoid high-up footer when the content is short */}
          <div style={{ minHeight: '75vh' }}>
            <Header handleDrawerToggle={handleDrawerToggle} withSidebar usePermanentSidebar />
            <Container className="mt-6" wide={props.wide}>
              {!props.hideTitle && <div className="my-12 h2-responsive">{props.title}</div>}
              {props.children}
            </Container>
          </div>
          <Footer />
        </div>
      </div>
    </RootLayout>
  );
};

export default BaseLayout;
