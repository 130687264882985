type SnackbarMessageType = 'success' | 'error' | 'info' | 'warning';

export const SnackbarSignal = 'snackbar_message';

type SnackbarMessageDetail = {
  message: string;
  type: SnackbarMessageType;
};

export type SnackbarMessage = {
  detail: SnackbarMessageDetail;
};

export const addSnackbarMessage = (message: string, type: SnackbarMessageType) => {
  document.dispatchEvent(
    new CustomEvent(SnackbarSignal, {
      detail: { message, type },
    })
  );
};
