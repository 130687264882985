import * as F from 'shared/shared/Functional';
import { ColorNames, VirtualVenueData, VirtualVenueThemeColorConfig } from 'components/VirtualVenue/types';
import { DAILY_CONNECTION_STATES, DailyConnectionState } from 'components/VirtualVenue/daily/state';
import { ReadVirtualVenue_virtualVenue_theme } from 'generated/ReadVirtualVenue';
import { SubscriptionType, VenueRole } from 'generated/globalTypes';
import { VirtualVenueContentType } from 'components/VirtualVenue/contexts/VirtualVenueContentTypeContext';
import { defaultTheme } from 'components/VirtualVenue/contexts/VirtualVenueThemeContext';
import { getURLParam } from 'utils/urls';
import { isUrl } from 'shared/shared/utils';

type DisplayConfig = {
  enableCallButtons: boolean;
};

const statesForCallButtons: DailyConnectionState[] = [
  DAILY_CONNECTION_STATES.STATE_JOINED,
  DAILY_CONNECTION_STATES.STATE_ERROR,
];

export const getDisplayConfig = (connectionState: DailyConnectionState): DisplayConfig => {
  /**
   * Only enable the call buttons (camera toggle, leave call, etc.) if we're joined
   * or if we've errored out.
   *
   * !!!
   * IMPORTANT: calling callObject.destroy() *before* we get the "joined-meeting"
   * can result in unexpected behavior. Disabling the leave call button
   * until then avoids this scenario.
   * !!!
   */
  const enableCallButtons = statesForCallButtons.includes(connectionState);
  return {
    enableCallButtons,
  };
};

/**
 * Color conversion utils
 */

export const themeToColors = (theme: ReadVirtualVenue_virtualVenue_theme): VirtualVenueThemeColorConfig => {
  return F.pick(theme, ...ColorNames);
};

export const getContentType = (
  virtualVenue: VirtualVenueData,
  streamUrl: string | undefined | null
): VirtualVenueContentType => {
  if (virtualVenue.events[0]?.virtualVenueContent) return 'custom';
  if (streamUrl) return 'broadcast';
  return 'call';
};
export const getStreamUrl = (virtualVenue: VirtualVenueData): string | undefined => {
  const streamUrl = getURLParam('hls_participant');
  if (streamUrl) {
    return streamUrl;
  }
  if (virtualVenue.broadcastUrl && isUrl(virtualVenue.broadcastUrl ?? '')) {
    return virtualVenue.broadcastUrl;
  }
  return virtualVenue.events[0]?.virtualVenueContent || undefined;
};

export const getDefaultVirtualVenueData = (opts?: { title?: string }): VirtualVenueData => {
  return {
    __typename: 'VirtualVenue',
    dailyCallUrl: '',
    broadcastUrl: '',
    events: [],
    id: '',
    myAccess: {
      __typename: 'MyAccess',
      dailyToken: null,
      role: VenueRole.OWNER,
      ableToRecord: null,
    },
    ownerId: '',
    slug: '',
    requireName: false,
    isClosed: false,
    awayMessage: '',
    subscription: {
      __typename: 'VenueSubscription',
      type: SubscriptionType.free,
    },
    theme: {
      __typename: 'VirtualVenueTheme',
      id: '',
      logo: '',
      logoFilter: '',
      owner: null,
      ...defaultTheme,
    },
    title: opts?.title ?? 'My New Venue',
  };
};
