import { Input } from 'components/common';
import { Widget } from 'components/widgets/index';
import { preventDecimals } from 'utils/input';
import React from 'react';

const NumberWidget: Widget<number> = (props) => {
  return (
    <Input
      id={props.id}
      className={props?.attrs?.className}
      value={(props.value ?? '').toString()}
      // current component uses a default text type, to review
      type="text"
      placeholder="0"
      onChange={(e) => {
        if (isNaN(e.target.value)) {
          props.onChange(0);
        } else {
          props.onChange(parseInt(e.target.value, 10));
        }
      }}
      onKeyPress={preventDecimals}
    />
  );
};

export default NumberWidget;
