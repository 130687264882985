import { FullScreen, useFullscreen } from 'utils/hooks/useFullscreen';
import { VV_CHAT_MID_WIDTH, VV_CHAT_WIDTH, VV_EDITOR_HEIGHT } from 'components/VirtualVenue/constants';
import { motion } from 'framer-motion';
import { useVirtualVenue } from 'components/VirtualVenue/contexts/VirtualVenueContext';
import { useVirtualVenueDisplay } from 'components/VirtualVenue/contexts/VirtualVenueDisplayContext';
import { useVirtualVenueState } from 'components/VirtualVenue/contexts/VirtualVenueStateContext';
import Chat from 'components/VirtualVenue/VirtualVenueDisplay/layouts/VirtualVenueDisplayLayout/Chat/Chat';
import React, { ReactNode, useRef } from 'react';
import SampleVideoTiles from 'components/VirtualVenue/VirtualVenueDisplay/layouts/VirtualVenueDisplayLayout/SampleVideoTiles';
import Tray from 'components/VirtualVenue/VirtualVenueDisplay/layouts/VirtualVenueDisplayLayout/Tray/Tray';
import VirtualVenueHeader from 'components/VirtualVenue/VirtualVenueDisplay/layouts/VirtualVenueDisplayLayout/VirtualVenueHeader/VirtualVenueHeader';
import classNames from 'classnames';
import styles from './VirtualVenueDisplayLayout.module.scss';
import useResizeObserver from 'utils/hooks/useResizeObserver';
import useUpdateVirtualVenueTheme from 'components/VirtualVenue/VirtualVenueDisplay/layouts/VirtualVenueDisplayLayout/useUpdateVirtualVenueTheme';
import useWindowSize from 'utils/hooks/useWindowSize';

interface Props {
  children: ReactNode;
  screen?: ReactNode;
}

const VirtualVenueDisplayLayout = (props: Props) => {
  const venue = useVirtualVenue();
  const venueState = useVirtualVenueState();
  const { theme, title } = venue;
  const { mode, isLiveMode } = useVirtualVenueDisplay();
  const handle = useFullscreen();
  const ref = useRef<HTMLDivElement>(null);
  const resizeContext = useResizeObserver(ref);
  const { isMobileXs, isMobileMd } = useWindowSize();

  useUpdateVirtualVenueTheme(ref.current);

  const borderColor = 'var(--v-theme-grid-line-color)';
  const nonEditHeight = Math.floor((resizeContext?.contentRect.width || 1000) * 0.5625);
  const height = mode === 'edit' ? VV_EDITOR_HEIGHT + 'px' : nonEditHeight;

  const chatWidthMidSize = isMobileMd ? VV_CHAT_MID_WIDTH : VV_CHAT_WIDTH;
  const chatWidth = (isMobileXs ? resizeContext?.contentRect.width : chatWidthMidSize) ?? chatWidthMidSize;
  const chatState = venueState.chatOpen ? 'open' : 'closed';
  return (
    <div
      style={{ height: isMobileXs ? undefined : height }}
      className={classNames('relative w-full', styles.VirtualVenueDisplayContainer)}
      ref={ref}
    >
      <FullScreen handle={handle} className="w-full h-full">
        {props.screen ? props.screen : undefined}
        <div
          id="virtual-venue-container"
          className="grid w-full h-full text-white"
          style={{
            backgroundColor: 'var(--v-theme-bg-color)',
            gridTemplateRows: 'auto 1fr',
            gridTemplateColumns: '1fr auto',
            gridTemplateAreas: isMobileXs
              ? `"header header" "${venueState.chatOpen ? 'chat chat' : 'call call'}" "tray tray"`
              : '"header header" "call chat" "tray chat"',
            minHeight: isMobileMd ? (isMobileXs ? '85vh' : '70vh') : undefined,
          }}
        >
          <div className="border-b border-solid" style={{ gridArea: 'header', borderColor }}>
            <VirtualVenueHeader
              handle={handle}
              logo={theme?.logo}
              title={venueState.newTitle || title}
              logoFilter={theme?.logoFilter}
            />
          </div>

          <div
            style={{
              gridArea: 'call',
              borderColor,
            }}
            className={classNames('overflow-hidden', isMobileXs && venueState.chatOpen && 'hidden')}
          >
            {!isLiveMode ? <SampleVideoTiles /> : props.children}
          </div>

          <motion.div
            className={classNames('overflow-hidden border-solid', isMobileXs && !venueState.chatOpen && 'hidden')}
            style={{
              gridArea: 'chat',
              borderColor,
            }}
            variants={{
              open: {
                width: chatWidth + 'px',
                borderLeftWidth: '1px',
              },
              closed: {
                width: 0,
                borderLeftWidth: '0px',
              },
            }}
            initial={chatState}
            animate={chatState}
            transition={{ ease: 'circOut', duration: 0.5 }}
          >
            <Chat chatWidth={chatWidth} />
          </motion.div>

          <div
            className={classNames('border-t border-solid h-14', isMobileXs && 'border-b')}
            style={{ gridArea: 'tray', borderColor }}
          >
            <Tray />
          </div>
        </div>
      </FullScreen>
    </div>
  );
};

export default VirtualVenueDisplayLayout;
