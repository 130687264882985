import { ApolloError, useQuery } from '@apollo/client';
import {
  DemoType,
  DemoUser,
  Person,
  Unauthenticated,
  Unknown,
  User,
  isAuthenticated,
  isEmailConfirmed,
  parsePerson,
} from 'models';
import { ME_QUERY } from 'utils/gql';
import { MeQuery } from 'generated/MeQuery';
import { ViewAsContext } from 'components/context/ViewAsContext/ViewAsContext';
import { useContext, useMemo } from 'react';

import useEventId from 'components/App/globalstate/useEventId';

interface UseUserResult {
  error?: ApolloError;
  isAuthenticated: boolean;
  isEmailConfirmed: boolean;
  loading: boolean;
  refetchUser: () => void;
  user: User;
}

function useUser(): UseUserResult {
  const viewAs = useContext(ViewAsContext);
  const eventId = useEventId();
  const { loading, refetch, data, error } = useQuery<MeQuery>(ME_QUERY, {
    skip: !!viewAs,
  });

  let user: User = useMemo(() => {
    if (data?.me) {
      return parsePerson(data.me);
    } else if (eventId === DemoType.Id) {
      return DemoUser;
    } else {
      return Unauthenticated;
    }
  }, [data, eventId]);
  user = loading || error ? Unknown : user;

  return viewAs
    ? {
        isAuthenticated: isAuthenticated(viewAs.user),
        isEmailConfirmed: true,
        loading: false,
        refetchUser: () => undefined,
        user: viewAs.user,
      }
    : {
        error,
        isAuthenticated: isAuthenticated(user),
        isEmailConfirmed: isEmailConfirmed((user as Person)?.auths),
        loading,
        refetchUser: refetch,
        user,
      };
}

export function useUserId(): string | undefined {
  const { user } = useUser();
  return user && isAuthenticated(user) ? user.id : undefined;
}

export default useUser;
