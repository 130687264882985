import * as m from 'models';
import ChangePasswordForm from './ChangePasswordForm';
import PersonNameForm from './PersonNameForm';
import React from 'react';
import styles from './AccountSettingsPage.module.scss';

interface Props {
  user: m.Person;
}

const hasOneEmailAuth = (person: m.Person) => {
  return person.auths && person.auths.email && person.auths.email.length === 1;
};

const AccountInfo = ({ user }: Props) => {
  return (
    <>
      <div className={styles.sectionForm}>
        <PersonNameForm me={user} />
      </div>
      {hasOneEmailAuth(user) && (
        <div className="mt-4">
          <ChangePasswordForm />
        </div>
      )}
    </>
  );
};

export default AccountInfo;
