import { ContactSelectOption } from './types';
import { MultiValueProps, components } from 'react-select';
import React from 'react';

interface Props extends MultiValueProps<ContactSelectOption> {
  setEditing: (email: string) => void;
}

const CustomMultiValueLabel = (props: Props) => {
  return (
    <div className="inline-block" onClick={() => props.setEditing(props.data.data.email)}>
      <components.MultiValueContainer {...props} />
    </div>
  );
};

export default CustomMultiValueLabel;
