import { SnackbarMessage, SnackbarSignal } from 'utils/eventEmitter';
import { initializeIframeResizer } from 'utils/embedded';
import { trackFocusMouse } from 'utils/dom';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import UserAndRoutingProvider from 'components/App/UserAndRoutingProvider';

const AppInner = () => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const handleSnackbarMessage: any = (msg: SnackbarMessage) => {
      const { detail } = msg;
      enqueueSnackbar(detail.message, {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        variant: detail.type,
      });
    };
    document.addEventListener(SnackbarSignal, handleSnackbarMessage);
    initializeIframeResizer();
    return () => document.removeEventListener(SnackbarSignal, handleSnackbarMessage);
  });

  useEffect(() => trackFocusMouse(document.body));

  const initialPath = window.location.pathname + window.location.search + window.location.hash;
  return <UserAndRoutingProvider initialPath={initialPath} />;
};

export default AppInner;
