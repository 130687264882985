import { Loading } from 'components/common';
import { Unauthenticated } from 'models';
import { ViewAsContext } from 'components/context/ViewAsContext/ViewAsContext';
import Dialog from 'components/common/Dialog/Dialog';
import EventLayout from 'components/layout/EventLayout/EventLayout';
import EventReadView from 'components/pages/EventReadPage/EventReadView';
import React, { useState } from 'react';
import ViewAsIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import eventActionButtonStyles from 'components/pages/EventReadPage/EventActionButton.module.scss';
import styles from './EventViewAsDialog.module.scss';
import useEventClass from 'utils/hooks/event/useEventClass';

interface Props {
  isRun: boolean;
}

const EventViewAsDialog = ({ isRun }: Props) => {
  const { event, isDemo, loading } = useEventClass({ required: true });
  const [isOpen, setIsOpen] = useState(false);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <button onClick={() => setIsOpen(true)} className={eventActionButtonStyles.button}>
        <ViewAsIcon /> <span className={eventActionButtonStyles.text}>Preview as Guest</span>
      </button>
      <Dialog
        title="Viewing as Guest"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        size="lg"
        contentClassName={styles.dialogContent}
      >
        <ViewAsContext.Provider value={{ user: Unauthenticated, isPreview: true }}>
          <EventLayout
            bgColor={event.bgColor}
            bgNoBackdrop={event.bgNoBackdrop}
            readPage={true}
            themePicUrl={event.themePic}
            title={event.title}
          >
            <EventReadView event={event} isRun={isRun} isDemo={isDemo} loading={loading} isDialog={true} />
          </EventLayout>
        </ViewAsContext.Provider>
      </Dialog>
    </>
  );
};

export default EventViewAsDialog;
