import { CREATE_EVENT_MUTATION, EVENT_WITH_EXIST_QUERY } from 'utils/gql';
import { CreateEvent, CreateEventVariables } from 'generated/CreateEvent';
import { EventWithExistQuery, EventWithExistQueryVariables } from 'generated/EventWithExistQuery';
import { Loading } from '../../common';
import { RedirectToPath } from '../../Redirect';
import { eventToMutationInput } from 'components/pages/EventDuplicatePage/helpers';
import { reverse } from 'router';
import { useMutation, useQuery } from '@apollo/client';
import Error404Page from '../Error404Page';
import EventLayout from '../../layout/EventLayout/EventLayout';
import React from 'react';

interface Props {
  id: string;
}

const EventDuplicatePage = (props: Props) => {
  const { loading, data, error } = useQuery<EventWithExistQuery, EventWithExistQueryVariables>(EVENT_WITH_EXIST_QUERY, {
    variables: { id: props.id },
  });
  const [doCreate, { error: mutationError, data: mutationResult, loading: mutationLoading }] = useMutation<
    CreateEvent,
    CreateEventVariables
  >(CREATE_EVENT_MUTATION);

  if (loading) {
    return (
      <EventLayout title="Loading...">
        <Loading />
      </EventLayout>
    );
  }

  if (error || !data || !data.event) {
    return <Error404Page />;
  }

  const { event: eventData } = data;

  if (!eventData.myPermissions?.canUpdate) {
    return (
      <EventLayout title="Mixily">
        <div className="text-3xl">Sorry, you don't have permission to copy that event.</div>
      </EventLayout>
    );
  }

  const params = eventToMutationInput(eventData);

  if (mutationError) {
    return (
      <EventLayout title="Error">
        <div className="text-3xl">Sorry, something went wrong</div>
      </EventLayout>
    );
  }
  if (mutationLoading) {
    return (
      <EventLayout title="Loading...">
        <Loading />
      </EventLayout>
    );
  }

  if (mutationResult) {
    if (!mutationResult.eventCreate?.ok) {
      return (
        <EventLayout title="Error">
          <div className="text-3xl">Sorry, something went wrong</div>
        </EventLayout>
      );
    }
    const newEvent = mutationResult.eventCreate.event;
    return <RedirectToPath path={reverse('event_update', { id: newEvent!.id })} />;
  }

  doCreate({ variables: params });

  return (
    <EventLayout title="Loading...">
      <Loading />
    </EventLayout>
  );
};

export default EventDuplicatePage;
