import * as F from 'shared/shared/Functional';
import { WidgetProps } from 'components/widgets';
import MuiSelectWidget from 'components/widgets/MuiSelectWidget/MuiSelectWidget';
import React from 'react';

type Value = number;

interface Attrs {
  min?: number;
  max?: number;
  noFullWidth?: boolean;
}

const DEFAULT_MAX = 50;

const QuantityDropdown: React.FC<WidgetProps<Value, Attrs>> = (props) => {
  const { value, disabled, onChange, attrs } = props;
  const min = attrs?.min ?? 0;
  const max = Math.min(attrs?.max ?? Infinity, DEFAULT_MAX);
  const options = F.range(min, max + 1).map((n) => n.toString());

  return (
    <MuiSelectWidget
      disabled={disabled}
      value={value.toString()}
      onChange={(v) => onChange(parseInt(v, 9))}
      attrs={{
        options,
        noFullWidth: attrs!.noFullWidth,
      }}
    />
  );
};

export default QuantityDropdown;
