import 'components/layout/Header/Header.scss';
import { isAuthenticated } from 'models';
import Container from 'components/layout/Container/Container';
import IconButton from '@material-ui/core/IconButton';
import Logo from 'components/Logo';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
import VenueHeaderInner from 'components/layout/Header/VenueHeaderInner';
import useUser from 'utils/hooks/user/useUser';

interface Props {
  handleDrawerToggle: () => void;
}

const VenueHeader = ({ handleDrawerToggle }: Props) => {
  const { user } = useUser();

  return (
    <div className="relative z-50 min-h-0 px-0 py-6 mt-0">
      <Container>
        <div className="flex items-center justify-between">
          {isAuthenticated(user) ? (
            <span className="mr-5">
              <IconButton
                size="small"
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </span>
          ) : (
            <Logo />
          )}
          <VenueHeaderInner />
        </div>
      </Container>
    </div>
  );
};

export default VenueHeader;
