import { URLContext } from 'components/RoutingProvider';
import { appendQueryString } from 'utils/urls';
import { isAuthenticated } from 'models';
import { reverse } from 'router';
import React, { useContext } from 'react';
import useUser from 'utils/hooks/user/useUser';

interface Props {
  children: React.ReactNode;
  ignore?: boolean;
}

const LoginRequired = (props: Props) => {
  const { loading, user } = useUser();
  const { setPath } = useContext(URLContext);
  const query = { next: window.location.pathname };
  const loginPath = appendQueryString(reverse('account_login'), query);

  if (loading) {
    return null;
  }

  if (!props.ignore && !isAuthenticated(user)) {
    setPath(loginPath);
    return null;
  }
  return <>{props.children}</>;
};

export default LoginRequired;
