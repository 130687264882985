import * as m from 'models';
import { Message, Reminder, isReminder } from 'components/MessageCenter/types';
import moment, { Moment } from 'moment';

export function messageInfo(message: Message | Reminder): string {
  const verb = !isReminder(message) && message.sent ? 'Sent' : 'Sending';
  const time = message.scheduledFor ? ` ${moment(message.scheduledFor).fromNow()} ` : ' ';
  const guestFilter = isReminder(message) ? 'Invited' : message.guestFilter;
  const recipients = guestFilter ? `to guests RSVP'd as ${guestFilter}` : 'to all guests';

  return `${verb}${time}${recipients}`;
}

export function messagePreview(message: Message | Reminder): string {
  return message.message.replace(/(<img[^>]*>|<br[^>]*>|<div>|<\/div>|<span>|<\/span>)/g, ' ');
}

export function getSubmitButtonText(scheduledFor?: Moment | null, verbs?: [string, string]): [string, string] {
  if (!scheduledFor) {
    return verbs || ['Invite', 'Inviting...'];
  }
  const t = m.renderTime(scheduledFor);
  const scheduledForStr = scheduledFor.calendar(undefined, {
    sameDay: `[today at ${t}]`,
    nextDay: `[tomorrow at ${t}]`,
    nextWeek: `[Next] ddd [at ${t}]`,
    sameElse: 'L',
  });
  return [`Schedule (${scheduledForStr})`, 'Scheduling...'];
}
