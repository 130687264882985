import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { getBaseHeaders } from 'utils/api';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
  uri: '/api/graphql',
  credentials: 'same-origin',
});

// Based on https://www.apollographql.com/docs/react/networking/authentication/#header
const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      ...getBaseHeaders(),
    },
  };
});

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Person: {
        fields: {
          upcoming: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
    },
  }),
});

export default apolloClient;
