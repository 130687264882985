import { ListingEvent } from 'components/pages/ListingEditPage/types';
import { getImageStyle } from 'utils/helpers';
import { parseEvent } from 'models';
import { renderFullTime } from 'models/event';
import ActionButton from 'components/common/ActionButton/ActionButton';
import DeleteIcon from '@material-ui/icons/Close';
import DownIcon from '@material-ui/icons/ArrowDownward';
import React from 'react';
import UpIcon from '@material-ui/icons/ArrowUpward';

interface Props {
  value: ListingEvent;
  onMoveDown: () => void;
  onMoveUp: () => void;
  onDelete: () => void;
  hasMany: boolean;
  isFirst: boolean;
  isLast: boolean;
}

const ListingEventFormItem = (props: Props) => {
  const { value, onMoveDown, onMoveUp, hasMany, onDelete, isFirst, isLast } = props;

  return (
    <div className="flex items-center justify-between p-3 bg-white border-t border-b border-gray-400 border-solid sm:rounded-md sm:border">
      <div className="flex items-center">
        <div
          className="flex-shrink-0 w-10 h-10 rounded-full"
          style={value.event.themePic ? getImageStyle(value.event.themePic, 400) : {}}
        />
        <div className="flex items-baseline ml-4">
          <div>{value.event.title}</div>
          <div className="ml-4 text-sm opacity-50">{renderFullTime(parseEvent(value.event))}</div>
        </div>
      </div>

      <div className="flex items-center">
        {hasMany && (
          <>
            <ActionButton onClick={onMoveUp} disabled={isFirst}>
              <UpIcon />
            </ActionButton>
            <ActionButton onClick={onMoveDown} disabled={isLast}>
              <DownIcon />
            </ActionButton>
          </>
        )}
        <ActionButton onClick={onDelete}>
          <DeleteIcon />
        </ActionButton>
      </div>
    </div>
  );
};
export default ListingEventFormItem;
