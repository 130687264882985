import config from 'config';

export const LOADING_INDICATOR_CLASS = 'loading-indicator';
export const PASSWORD_EVENT_ID = (id: string) => `eventPsw_${id}`;

export const THEME_PIC_MAX_WIDTH = 1200;
export const THEME_PIC_MAX_HEIGHT = 441;

const STOP_WORDS = [
  'about',
  'after',
  'all',
  'also',
  'am',
  'an',
  'and',
  'another',
  'any',
  'are',
  'as',
  'at',
  'be',
  'because',
  'been',
  'before',
  'being',
  'between',
  'both',
  'but',
  'by',
  'came',
  'can',
  'come',
  'could',
  'did',
  'do',
  'each',
  'for',
  'from',
  'get',
  'got',
  'has',
  'had',
  'he',
  'have',
  'her',
  'here',
  'him',
  'himself',
  'his',
  'how',
  'if',
  'in',
  'into',
  'is',
  'it',
  'like',
  'make',
  'many',
  'me',
  'might',
  'more',
  'most',
  'much',
  'must',
  'my',
  'never',
  'now',
  'of',
  'on',
  'only',
  'or',
  'other',
  'our',
  'out',
  'over',
  'said',
  'same',
  'see',
  'should',
  'since',
  'some',
  'still',
  'such',
  'take',
  'than',
  'that',
  'the',
  'their',
  'them',
  'then',
  'there',
  'these',
  'they',
  'this',
  'those',
  'through',
  'to',
  'too',
  'under',
  'up',
  'very',
  'was',
  'way',
  'we',
  'well',
  'were',
  'what',
  'where',
  'which',
  'while',
  'who',
  'with',
  'would',
  'you',
  'your',
  'a',
  'i',
];

export const STOP_WORDS_MAP = STOP_WORDS.reduce((memo: any, word: string) => {
  memo[word] = true;
  return memo;
}, {});

export const STRIPE_OAUTH_URL = 'https://connect.stripe.com/oauth/authorize';
export const STRIPE_OAUTH_PARAMS = {
  response_type: 'code',
  client_id: config.stripeClientId,
  scope: 'read_write',
  'stripe_user[product_category]': 'events_and_ticketing',
};

export const ZOOM_OAUTH_URL = 'https://zoom.us/oauth/authorize';
export const ZOOM_OAUTH_PARAMS = {
  response_type: 'code',
  client_id: config.zoomClientId,
  redirect_uri: `${config.useInsecureHttp ? 'http' : 'https'}://${config.hostname}/connect-zoom`,
};

export const DEFAULT_LOGO_COLOR = '#393939';
