import { OnSubmit } from 'react-hook-form';
import ChatNameForm from 'components/VirtualVenue/VirtualVenueDisplay/layouts/VirtualVenueDisplayLayout/Chat/ChatFooter/ChatNameForm/ChatNameForm';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import usePrevious from 'utils/hooks/usePrevious';

type SettingName = false | 'from-init' | 'from-user';

interface Props {
  onSubmit: OnSubmit<{ name: string }>;
  formId?: string;
  name?: string | null;
  required?: boolean;
}

const VirtualLobbyNameForm = ({ onSubmit, name, required, formId }: Props) => {
  const nameInitialized = name !== null;
  const [settingName, setSettingName] = useState<SettingName>(name !== '' && nameInitialized ? false : 'from-init');
  const previousName = usePrevious(name);

  useEffect(() => {
    // If we're done loading the initial name, and it's blank, prompt user to set name
    if (previousName === null && name === '') {
      setSettingName('from-init');
    }
    if (name) {
      setSettingName(false);
    }
  }, [name, previousName]);

  return (
    <>
      {/* Keep form in DOM but hidden, the join button is the form submit action */}
      <div
        className={classNames({
          hidden: settingName === false,
        })}
      >
        {/*
          When the name is required, this form cannot have an empty name value or validation
          will fail. There is an edge case where the user actually has a name, but the name value
          initially passed in to defaultName is null (first render before the initial name was set on
          venue state).
          nameInitialized makes sure we don't render ChatNameForm before we actually know if the user
          has a name or not. Since it uses a useForm hook internally, it would not be update with the
          'correct' initial name state
         */}
        {nameInitialized && (
          <ChatNameForm
            required={required}
            formId={formId}
            inputStyle={{
              border: '1px solid var(--v-theme-grid-line-color)',
              color: 'var(--v-theme-chat-text-color)',
            }}
            onBlur={() => {
              setSettingName(false);
            }}
            inputPlaceholder={'Insert your name here'}
            inputClassName={'rounded-full bg-transparent md:h-8 lg:h-11 xl:h-12'}
            onSubmit={onSubmit}
            defaultName={name || ''}
            autoFocus={settingName === 'from-user'}
            autocomplete={false}
            className={'text-center'}
          />
        )}
      </div>
      {!settingName && (
        <div style={{ color: 'var(--v-theme-chat-text-color)', textAlign: 'center' }}>
          <label className="block">
            <div
              className="text-sm font-semibold leading-4"
              style={{
                color: 'var(--v-theme-chat-text-color)',
                filter: 'brightness(0.9)',
              }}
            >
              You are joining as
            </div>
          </label>
          <div className="my-1">
            <div className="relative ">
              <div
                onClick={() => setSettingName('from-user')}
                className="flex items-center justify-center text-lg cursor-pointer lg:text-xl lg:h-10 xl:text-2xl xl:h-11"
              >
                {name}
              </div>
            </div>
          </div>
          <div
            className="h-5 text-xs"
            style={{
              color: 'var(--v-theme-chat-text-color)',
              filter: 'brightness(0.9)',
              opacity: '.8',
            }}
          >
            Click your name to change it
          </div>
        </div>
      )}
    </>
  );
};

export default VirtualLobbyNameForm;
