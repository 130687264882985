import { Features, SubscriptionPlansByType, SubscriptionType, VirtualVenueFreePlan } from 'shared/shared/constants';
import { isAuthenticated } from 'models';
import useUser from 'utils/hooks/user/useUser';

interface UseSubscriptionFeaturesResult {
  features: Features | undefined;
  loading: boolean;
  userId: string | undefined;
}

const useSubscriptionFeatures = (latest = false): UseSubscriptionFeaturesResult => {
  const { loading, user, refetchUser } = useUser();
  if (loading) {
    return {
      loading: true,
      features: undefined,
      userId: undefined,
    };
  }
  if (!isAuthenticated(user)) {
    return {
      loading: false,
      features: VirtualVenueFreePlan.features,
      userId: undefined,
    };
  }
  if (latest) {
    refetchUser();
  }

  const subscriptionType = user.mySubscription?.type ?? SubscriptionType.FREE;
  return {
    userId: user.id,
    loading: false,
    features: SubscriptionPlansByType[subscriptionType].features,
  };
};

export default useSubscriptionFeatures;
