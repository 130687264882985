import React, { ReactNode } from 'react';
import classNames from 'classnames';

interface Props {
  children: ReactNode;
  className?: string;
}

const FieldLabel = (props: Props) => {
  const cls = classNames('field-label font-semibold text-sm leading-4', props.className);
  return <div className={cls}>{props.children}</div>;
};

export default FieldLabel;
