import * as m from 'models';
import { RedirectToPathAfter } from 'components/Redirect';
import EventLayout from 'components/layout/EventLayout/EventLayout';
import React from 'react';
import TimeTil, { durationToString } from 'components/pages/VenuePage/TimeTil/TimeTil';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
import styles from 'components/pages/EventReadPage/EventReadView.module.scss';

interface EventLobbyFragment {
  id: string;
  hostedByText?: string | undefined;
  startTime: moment.Moment | null;
  title: string;
  themePic?: string | undefined;
  bgColor?: string;
  bgNoBackdrop?: boolean;
  location: string;
  owner?: m.Person | undefined;
  locationReleaseTimeDelta?: moment.Duration | undefined;
}

interface Props {
  event: EventLobbyFragment;
}

const LobbyPage = ({ event }: Props) => {
  const linkReleaseTime = event.locationReleaseTimeDelta ? moment(event.startTime || undefined) : moment();

  let redirectMessage: string | null = 'when the event starts';
  if (event.locationReleaseTimeDelta) {
    linkReleaseTime.subtract(event.locationReleaseTimeDelta);
    redirectMessage = `${durationToString(event.locationReleaseTimeDelta)} before the show starts`;
  }
  return (
    <EventLayout
      title={event.title}
      themePicUrl={event.themePic}
      readPage={true}
      bgColor={event.bgColor}
      bgNoBackdrop={event.bgNoBackdrop}
    >
      {event.location && <RedirectToPathAfter path={event.location} after={linkReleaseTime} />}
      <div className="max-w-4xl mx-auto text-center text-base: sm:text-lg">
        <div className={styles.EventBlock}>
          {event.themePic && <img className={styles.HeaderImage} src={event.themePic} alt="" />}
          <div
            className={classNames(styles.MainInfo, {
              [styles.TopBorder]: !event.themePic,
            })}
          >
            <div className="mt-4 text-cool-gray-800">
              <div className="text-3xl sm:text-3xl">{event.title}</div>
            </div>
            <div className="mt-1">
              <div className="flex items-center justify-center mt-2 font-semibold text-cool-gray-600">
                <span role="img" aria-label="Person Icon" className="text-sm">
                  👤
                </span>
                <div className="justify-center inline-block ml-2">
                  Hosted by {event.hostedByText === '__Host__' ? 'Demo Host' : event.hostedByText}
                </div>
              </div>
            </div>
            {!event.location && <div className="mt-12 text-3xl text-gray-700">No location yet, check back soon!</div>}
            <div className="mt-16">
              <div className="text-cool-gray-400">Starting in</div>
              <div className="text-5xl leading-none">
                <TimeTil time={event.startTime as Moment} />
              </div>
            </div>
            <div className="mt-2 mb-4 text-cool-gray-400">You will be automatically redirected {redirectMessage}.</div>
          </div>
        </div>
      </div>
    </EventLayout>
  );
};

export default LobbyPage;
