import { Button, Input } from 'components/common';
import IPTab from 'components/EventCreateUpdateForm/ImagePicker/IPTab';
import React, { useState } from 'react';

import styles from 'components/EventCreateUpdateForm/ImagePicker/ImagePicker.module.scss';

interface Props {
  onSelect: Function;
  helpText: string;
}

const LinkTab: React.FC<Props> = (props) => {
  const [value, setValue] = useState<string>('');
  const { onSelect, helpText } = props;
  return (
    <IPTab center>
      <Input
        autoFocus
        placeholder="Paste an image link..."
        size="small"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onSelect(value);
          }
        }}
      />
      <Button label="Submit" handleClick={() => onSelect(value)} />
      <small className={styles.info}>{helpText}</small>
    </IPTab>
  );
};

export default LinkTab;
