import { Link, Loading } from 'components/common';
import { reverse } from 'router';
import AddToCalendar, { ButtonCalendarType } from 'components/pages/EventReadPage/AddToCalendar/AddToCalendar';
import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import Error404Page from 'components/pages/Error404Page';
import Error500Page from 'components/pages/Error500Page';
import HostedBy from 'components/HostedBy/HostedBy';
import React from 'react';
import Title from 'components/pages/EventReadPage/Title/Title';
import When from 'components/When/When';
import Where from 'components/Where/Where';
import useEventClass from 'utils/hooks/event/useEventClass';
import useSetEventId from 'components/App/globalstate/useSetEventId';

interface Props {
  id: string;
}

const AddToCalendarPage = ({ id }: Props) => {
  useSetEventId(id);

  const { loading, error, event, eventExists, isDemo } = useEventClass({ required: false });

  if (loading) {
    return <Loading></Loading>;
  }

  if (error || isDemo) {
    return <Error500Page />;
  }

  if (!event || !eventExists) {
    return <Error404Page />;
  }

  const eventUrl = reverse('event_read', { id: event.id });

  return (
    <BaseLayout title="">
      <div className="">
        <Title title={event.title} />
        <div className="my-6 vstack-small">
          <div className="flex flex-col xs:flex-row xs:items-center">
            <HostedBy hostedByText={event.hostedByText} alignTop />
          </div>
          <div className="flex flex-col xs:flex-row xs:items-center">
            <When event={event} inviteName={undefined} isRun={false} run={undefined} alignTop />
          </div>
          <Where event={event} alignTop />
        </div>
        <AddToCalendar eventId={event.id} forCalendar={event.forCalendar} type={ButtonCalendarType.BUTTON} />
        <div className="my-6">
          Go to <Link href={eventUrl}>{event.title}</Link>
        </div>
      </div>
    </BaseLayout>
  );
};

export default AddToCalendarPage;
