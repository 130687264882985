import React, { ReactNode, useContext, useState } from 'react';

type EditContext = {
  title: string;
  setTitle: (newTitle: string) => void;

  logo: string | null;
  setLogo: (newTitle: string) => void;
};

const VirtualVenueEditContext = React.createContext<EditContext | undefined>(undefined);

export const useVirtualVenueEditContext = () => useContext(VirtualVenueEditContext);

interface VirtualVenueEditProviderProps {
  children: ReactNode;
  initialState: {
    title: string;
    logo: string;
  };
}

export const VirtualVenueEditProvider = ({ children, initialState }: VirtualVenueEditProviderProps) => {
  const [title, setTitle] = useState(initialState.title);
  const [logo, setLogo] = useState<string>(initialState.logo || '');

  return (
    <VirtualVenueEditContext.Provider value={{ title, setTitle, logo, setLogo }}>
      {children}
    </VirtualVenueEditContext.Provider>
  );
};

export default VirtualVenueEditContext;
