import { EventReadViewSkeleton } from 'components/pages/EventReadPage/EventReadViewSkeleton';
import { INVITE_TOKEN_QUERY } from 'utils/gql';
import { InviteTokenQuery, InviteTokenQueryVariables } from 'generated/InviteTokenQuery';
import { Link } from 'components/common';
import { URLContext } from 'components/RoutingProvider';
import { appendQueryString } from 'utils/urls';
import { reverse } from 'router';
import { useQuery } from '@apollo/client';

import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import EventLayout from 'components/layout/EventLayout/EventLayout';
import React, { useContext } from 'react';
import useInviteId from 'components/App/globalstate/useInviteId';

interface Props {
  id: string;
  rsvp: string;
}

const InviteReadPage: React.FC<Props> = ({ id, rsvp }) => {
  const { replacePath } = useContext(URLContext);
  const { setInviteId } = useInviteId();
  setInviteId(id);

  const { loading, error, data } = useQuery<InviteTokenQuery, InviteTokenQueryVariables>(INVITE_TOKEN_QUERY, {
    variables: { token: id },
  });

  if (loading) {
    return (
      <EventLayout title="Loading...">
        <EventReadViewSkeleton />
      </EventLayout>
    );
  }

  if (error || !data || !data.inviteToken) {
    return (
      <BaseLayout title="">
        Sorry, this invitation is expired or invalid. Please <Link href={reverse('home')}>return home</Link>.
      </BaseLayout>
    );
  }

  const path = appendQueryString(reverse('event_read', { id: data.inviteToken.eventId }), { rsvp });
  replacePath(path);
  return null;
};

export default InviteReadPage;
