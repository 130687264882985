import { Loading } from 'components/common';
import { Message } from './types';
import { SelectableList } from 'components/Outbox/SelectableList';
import React from 'react';

export interface Props {
  messages: Message[];
  loading: boolean;
  onClose: () => void;
  onSelect?: (selectedMessageIds: Set<string>) => void;
  selectedMessageIds: Set<string>;
}

export const OutboxList = (props: Props) => {
  const { selectedMessageIds, onClose, onSelect, loading } = props;
  const onSelectionChange = (selectedMessageIds: any) => {
    onSelect && onSelect(selectedMessageIds);
  };

  if (loading) {
    return <Loading.Spinner size="small" style={{ color: 'black' }} />;
  }

  let messages = props.messages ? props.messages : [];

  if (messages.length === 0) {
    onClose();
  }

  return (
    <SelectableList messages={messages} onSelectionChange={onSelectionChange} selectedMessageIds={selectedMessageIds} />
  );
};
