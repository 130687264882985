import { EventReadViewSkeleton } from 'components/pages/EventReadPage/EventReadViewSkeleton';
import { RedirectToPathAfter } from 'components/Redirect';
import { isUrl } from 'shared/shared/utils';
import { localStore } from 'utils/localstore';
import Error404Page from 'components/pages/Error404Page';
import Error500Page from 'components/pages/Error500Page';
import EventLayout from 'components/layout/EventLayout/EventLayout';
import LobbyPage from 'components/pages/LobbyPage/LobbyPage';
import PrivateEventPage from 'components/pages/EventReadPage/PrivateEventPage';
import React from 'react';
import moment from 'moment';
import useEventClass from 'utils/hooks/event/useEventClass';
import useSetEventId from 'components/App/globalstate/useSetEventId';

interface Props {
  id: string;
}

const EventLocationProxy = ({ id }: Props) => {
  useSetEventId(id);
  const { isDemo, event, eventExists, loading, error } = useEventClass({ required: false });

  if (loading || (isDemo && error && localStore.creatingDemoEvent.get())) {
    return (
      <EventLayout title="Loading...">
        <EventReadViewSkeleton />
      </EventLayout>
    );
  }

  if (error) {
    return <Error500Page />;
  }

  if (!event) {
    return !eventExists ? <Error404Page /> : <PrivateEventPage />;
  }
  const { location, locationReleaseTimeDelta, startTime } = event;
  const locationReleaseTime = locationReleaseTimeDelta ? moment(startTime!) : moment();

  if (locationReleaseTimeDelta) {
    locationReleaseTime.subtract(locationReleaseTimeDelta);
  }

  if (location && isUrl(location)) {
    return <RedirectToPathAfter path={location} after={locationReleaseTime} />;
  } else {
    return <LobbyPage event={event} />;
  }
};

export default EventLocationProxy;
