import * as m from 'models';
import { FEATURE_FLAGS } from 'shared/shared/json';
import AccountInfo from 'components/pages/AccountSettingsPage/AccountInfo';
import AuthInfo from 'components/pages/AccountSettingsPage/AuthInfo';
import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import GoogleCalendarSettings from 'components/pages/AccountSettingsPage/GoogleCalendarSettings';
import LoginRequired from 'components/LoginRequired';
import React from 'react';
import Section from './Section';
import StripeSettings from 'components/pages/AccountSettingsPage/StripeSettings';
import StripeSubscriptionSettings from 'components/pages/AccountSettingsPage/StripeSubscriptionSettings';
import UserFeatureSwitch from 'components/pages/AccountSettingsPage/UserFeatureSwitch';
import useHasFeatureFlags from 'utils/hooks/user/useHasFeatureFlags';
import useUser from 'utils/hooks/user/useUser';

const AccountSettingsPage = () => {
  const { user } = useUser();
  const { hasGoogleCalendar } = useHasFeatureFlags(FEATURE_FLAGS.hasGoogleCalendar);

  return (
    <LoginRequired>
      <BaseLayout title="">
        <div className="text-4xl">Account Settings</div>
        {m.isAuthenticated(user) && (
          <div className="mt-2">
            <AuthInfo user={user} />
            <Section title="Account Information">
              <AccountInfo user={user} />
            </Section>
            {user.stripeCustomerId && (
              <Section
                title="Manage Subscription"
                description="Allows you to manage your subscription and payment methods"
              >
                <StripeSubscriptionSettings />
              </Section>
            )}

            {/* Disabled until we stop confusing people about virtual venue */}
            {/* <Section title="Zoom Integration" description="Create Zoom meetings from within the Mixily event editor">
              <ZoomSettings user={user} />
            </Section> */}
            <Section title="Stripe Integration" description="Allows you to sell tickets and accept donations">
              <StripeSettings user={user} />
            </Section>
            {user.auths?.google[0] && hasGoogleCalendar && (
              <Section title="Google Calendar Sync" description="Allows you to sync your events with Google Calendar">
                <GoogleCalendarSettings />
              </Section>
            )}
            <Section title="Advanced Features">
              {/* Features here must be listed in FeatureFlag.ts `UserSettableFlags` */}
              <div className="vstack-small">
                <UserFeatureSwitch
                  title="Custom 'Hosted By' text"
                  description="Lets you customize the 'Hosted By' text for your event. This field will appear in the event editing form."
                  featureName={FEATURE_FLAGS.hostedByOverride}
                />
                <UserFeatureSwitch
                  title="Hide Commenting"
                  description="Lets you disable event page commenting on a per-event basis. This option will appear in the event editing form."
                  featureName={FEATURE_FLAGS.canDisableComments}
                />
                <UserFeatureSwitch
                  title="Order Confirmation Emails Bcc"
                  description={`Automatically bcc you (${user.getPrimaryEmail()}) on every guest ticket confirmation email.`}
                  featureName={FEATURE_FLAGS.bccTicketEmails}
                />
                <UserFeatureSwitch
                  title="Facebook Pixel"
                  description="Lets you enter a Facebook Pixel ID on a per-event basis."
                  featureName={FEATURE_FLAGS.canSetFacebookPixelId}
                />
              </div>
            </Section>
          </div>
        )}
      </BaseLayout>
    </LoginRequired>
  );
};

export default AccountSettingsPage;
