import classNames from 'classnames';

import React from 'react';

import styles from 'components/EventCreateUpdateForm/ImagePicker/ImagePicker.module.scss';

interface Props {
  center?: boolean;
  dragging?: boolean;
}

const IPTab: React.FC<Props> = ({ center, dragging, children }) => {
  const cls = classNames(styles.Tab, {
    [styles.center]: center,
    [styles.dragging]: dragging,
  });
  return (
    <div className={cls}>
      <div className={styles.children}>{children}</div>
    </div>
  );
};

export default IPTab;
