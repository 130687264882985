import * as React from 'react';

type VirtualVenueDisplayMode = 'live' | 'edit' | 'preview' | 'demo';

type VirtualVenueDisplaySettings = {
  mode: VirtualVenueDisplayMode;
  isEmbedded: boolean;
  isLiveMode: boolean;
};

const defaultSetting: VirtualVenueDisplaySettings = {
  mode: 'live',
  isEmbedded: false,
  isLiveMode: true,
};

type VirtualVenueDisplayProviderProps = {
  initialSetting?: Partial<VirtualVenueDisplaySettings>;
  children: React.ReactNode;
};

const VirtualVenueDisplayContext = React.createContext<VirtualVenueDisplaySettings | undefined>(undefined);

function VirtualVenueDisplayProvider({ children, initialSetting }: VirtualVenueDisplayProviderProps) {
  const combinedSetting = {
    ...defaultSetting,
    ...initialSetting,
  };

  const value = React.useMemo(() => {
    return { ...combinedSetting, isLiveMode: combinedSetting.mode === 'live' || combinedSetting.mode === 'demo' };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <VirtualVenueDisplayContext.Provider value={value}>{children}</VirtualVenueDisplayContext.Provider>;
}

function useVirtualVenueDisplay() {
  const context = React.useContext(VirtualVenueDisplayContext);
  if (context === undefined) {
    throw new Error('useVirtualVenueDisplay must be used within a VirtualVenueDisplayProvider');
  }
  return context;
}

export { VirtualVenueDisplayProvider, useVirtualVenueDisplay };
