import { ReadVirtualVenue_virtualVenue } from 'generated/ReadVirtualVenue';
import { addSnackbarMessage } from 'utils/eventEmitter';
import { makeAbsoluteUrl } from 'shared';
import { reverse } from 'shared/shared/routing/mixily-routes';
import CopyToClipboard from 'react-copy-to-clipboard';
import IconSvg from 'components/common/IconSvg/IconSvg';
import Link from 'components/Link';
import React from 'react';
import Tab from 'components/common/Tab/Tab';
import TextArea2 from 'components/common/TextArea2';

interface Props {
  virtualVenue: ReadVirtualVenue_virtualVenue;
}

const getVirtualVenueEmbedCode = ({ id }: { id: string }) => {
  const embedScript = makeAbsoluteUrl('script_embed');
  return `<div class="mixily-container" data-mixily-id="${id}" data-mixily-type="venue"></div>\n<script defer src="${embedScript}"></script>`;
};

const getVirtualVenueEmbedCodeWix = ({ id }: { id: string }) => {
  const embedScriptURL = makeAbsoluteUrl('script_embed');
  return `${embedScriptURL}?mixilyId=${id}&mixilyType=venue`;
};

const VirtualVenueDetails = ({ virtualVenue }: Props) => {
  const embedCode = getVirtualVenueEmbedCode(virtualVenue);
  const wixEmbedCode = getVirtualVenueEmbedCodeWix(virtualVenue);
  return (
    <div>
      <div>
        <Link href={reverse('virtual_venue_list')} className="text-lg leading-none">
          back to my venues
        </Link>
      </div>

      <div className="mt-12 text-5xl leading-none">Install Directions</div>
      <div className="mt-3 text-lg leading-none">{virtualVenue.title}</div>

      <div className="px-5 py-4 mt-16 bg-white border border-black border-solid rounded-sm sm:py-8 sm:px-10 lg:py-16 lg:px-20">
        <Tab
          panes={[
            {
              title: (
                <span>
                  <IconSvg className="inline-block w-6 h-6 mr-2 align-text-bottom" name="code" />
                  HTML
                </span>
              ),
              content: () => (
                <div>
                  <div className="mt-16 text-2xl">1. Click the code below to copy it to your clipboard</div>
                  <div className="mt-8">
                    <CopyToClipboard text={embedCode} onCopy={() => addSnackbarMessage('Code copied!', 'success')}>
                      <TextArea2 readOnly={true} className="max-w-xl font-mono text-sm" value={embedCode} />
                    </CopyToClipboard>
                    <div className="mt-16 text-2xl">2. Paste it into the HTML of your site</div>
                    <div className="mt-16 text-2xl">3. You're live with Virtual Venue!</div>
                  </div>
                </div>
              ),
            },
            {
              title: (
                <span>
                  <IconSvg className="inline-block w-6 h-6 mr-2 align-text-bottom" name="squarespace" />
                  Squarespace
                </span>
              ),
              content: () => (
                <div>
                  <div className="mt-12 text-2xl">1. Click the code below to copy it to your clipboard</div>
                  <div className="mt-8">
                    <CopyToClipboard text={embedCode} onCopy={() => addSnackbarMessage('Code copied!', 'success')}>
                      <TextArea2 readOnly={true} className="max-w-xl font-mono text-sm" value={embedCode} />
                    </CopyToClipboard>
                    <div className="mt-12 text-2xl">2. Edit the page where you want your venue</div>
                    <div className="mt-12 text-2xl">
                      3. Click '+' to add a new element
                      <div className="m-4">
                        <img
                          alt="Click '+' to add a new element"
                          src="https://images.prismic.io/mixily/fa40de19-e8e8-4462-adf8-c2b78a9dee66_Squarespace_click_plus.gif"
                        ></img>
                      </div>
                    </div>
                    <div className="mt-12 text-2xl">
                      4. Select the 'Code' element in the 'MORE' section
                      <div className="m-4">
                        <img
                          alt="Select the 'Code' element in the 'MORE' section"
                          src="https://images.prismic.io/mixily/c11effa9-62ce-48c0-bcb1-74df6f61db66_Squarespace_click_code.gif"
                        ></img>
                      </div>
                    </div>
                    <div className="mt-12 text-2xl">5. Paste in the code and click 'Apply'</div>
                    <div className="mt-12 text-2xl">6. Save the page and you're live with Virtual Venue!</div>
                  </div>
                </div>
              ),
            },
            {
              title: (
                <span>
                  <IconSvg className="relative inline-block w-6 h-6 mr-2 align-text-bottom top-0.5" name="wix" />
                  Wix
                </span>
              ),
              content: () => (
                <div>
                  <div className="mt-12 text-2xl">1. Click the code below to copy it to your clipboard</div>
                  <div className="mt-8">
                    <CopyToClipboard text={wixEmbedCode} onCopy={() => addSnackbarMessage('Code copied!', 'success')}>
                      <TextArea2 readOnly={true} className="max-w-xl font-mono text-sm" value={wixEmbedCode} />
                    </CopyToClipboard>
                    <div className="mt-12 text-2xl">2. Edit the page where you want to install your venue</div>
                    <div className="mt-12 text-2xl">
                      3. Click '+' to add a new element
                      <div className="m-4">
                        <img
                          alt="Click '+' to add a new element"
                          src="https://images.prismic.io/mixily/1ba6b04b-8f81-477a-9f7a-d4a314536640_wix_add.gif"
                        ></img>
                      </div>
                    </div>
                    <div className="mt-12 text-2xl">
                      4. Select 'Custom Element' in the 'Embed' section
                      <div className="m-4">
                        <img
                          alt="Select 'Custom Element' in the 'Embed' section"
                          src="https://images.prismic.io/mixily/75238f2a-7569-47ae-aecf-9a8efd68af7f_wix_custom_element.gif"
                        ></img>
                      </div>
                    </div>
                    <div className="mt-12 text-2xl">
                      5. Click the 'Choose Source' button above the element
                      <div className="m-4">
                        <img
                          alt="Click the 'Choose Source' button above the element"
                          src="https://images.prismic.io/mixily/92f61120-7337-4c40-a8d1-bac56a035456_wix_choose_source.gif"
                        ></img>
                      </div>
                    </div>
                    <div className="mt-12 text-2xl">
                      6. Click under Server URL and paste in your code
                      <div className="m-4">
                        <img
                          alt="Click under Server URL and paste in your code"
                          src="https://images.prismic.io/mixily/16d9c345-2136-4bfd-8b26-883959b54790_wix_server_url.gif"
                        ></img>
                      </div>
                    </div>
                    <div className="mt-12 text-2xl">7. Set the 'Tag Name' to "mixily-video"</div>
                    <div className="mt-12 text-2xl">8. Publish your page and you're live with Virtual Venue!</div>
                  </div>
                </div>
              ),
            },
            // {
            //   menuItem: 'Wordpress',
            //   render: () => (
            //     <div>
            //       <div className="mt-16 text-2xl">1. Click the code below to copy it to your clipboard</div>
            //       <div className="mt-12">
            //         <CopyToClipboard text={embedCode} onCopy={() => addSnackbarMessage('Code copied!', 'success')}>
            //           <TextArea2 readOnly={true} className="font-mono" value={embedCode} />
            //         </CopyToClipboard>
            //         <div className="mt-16 text-2xl">2. Paste it into the HTML of your site</div>
            //         <div className="mt-16 text-2xl">2. You're live with Virtual Venue!</div>
            //       </div>
            //     </div>
            //   ),
            // },
          ]}
        />
      </div>
    </div>
  );
};

export default VirtualVenueDetails;
