import React from 'react';
import classNames from 'classnames';
import styles from 'components/common/Message.module.scss';

interface Props {
  text?: string;
  children?: React.ReactNode;
  className?: string;
  compact?: boolean;
  type: 'positive' | 'negative';
}

const Message = (props: Props) => {
  const { text, children, className, compact, type } = props;
  const cls = classNames(styles.Message, className, {
    [styles.compact]: !!compact,
    [styles[`${type}`]]: !!type,
  });

  return <div className={cls}>{text || children}</div>;
};

export default Message;
