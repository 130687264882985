// Consider using our typed wrapper, localstore.ts, instead of this.

import { useState } from 'react';

const useLocalStorage = <T = void>(key: string, initialValue: T): [T, (value: T) => void] => {
  // Based on https://usehooks.com/useLocalStorage/
  const [storedValue, setStoredValue] = useState(() => {
    const item = window.localStorage.getItem(key);
    return item ? (JSON.parse(item) as T) : initialValue;
  });

  const setValue = (value: T) => {
    setStoredValue(value);
    window.localStorage.setItem(key, JSON.stringify(value));
  };

  return [storedValue, setValue];
};

export default useLocalStorage;
