import IconWithText from 'components/common/IconWithText/IconWithText';
import React from 'react';

interface Props {
  hostedByText?: string;
  alignTop?: boolean;
  noHostedBy?: boolean;
}

const HostedBy = ({ hostedByText, alignTop, noHostedBy }: Props) => {
  const text = (noHostedBy ? '' : 'Hosted by ') + (hostedByText || 'Mixily');
  return (
    <IconWithText icon="person" alignTop={alignTop}>
      {text}
    </IconWithText>
  );
};

export default HostedBy;
