import * as F from 'shared/shared/Functional';
import { EVENT_FOR_VENUE_QUERY } from 'utils/gql';
import {
  EventForVenueQuery,
  EventForVenueQueryVariables,
  EventForVenueQuery_event,
} from 'generated/EventForVenueQuery';
import { getURLParams } from 'utils/urls';
import { useQuery } from '@apollo/client';

import { Skeleton } from '@material-ui/lab';
import Error404Page from 'components/pages/Error404Page';
import Error500Page from 'components/pages/Error500Page';
import EventLayout from 'components/layout/EventLayout/EventLayout';
import React from 'react';
import VenueContent from 'components/pages/VenuePage/VenueContent/VenueContent';
import classNames from 'classnames';
import moment from 'moment';
import styles from 'components/pages/EventReadPage/EventReadView.module.scss';
import usePasswordEvent from 'components/App/globalstate/usePasswordEvent';

const eventToFragment = (event: EventForVenueQuery_event) => {
  return Object.assign(
    {},
    event,
    {
      startTime: event.startTime ? moment(event.startTime) : null,
      locationReleaseTimeDelta: event.locationReleaseTimeDelta
        ? moment.duration(event.locationReleaseTimeDelta)
        : undefined,
    },
    F.objMapValues(
      F.pick(event, 'themePic', 'bgColor', 'hostedByText', 'bgNoBackdrop', 'virtualVenueContent'),
      (v) => v ?? undefined
    )
  );
};

interface Props {
  id: string;
}

const VenuePage = ({ id }: Props) => {
  const urlParams = getURLParams();
  const resultPassword = usePasswordEvent();
  const accessToken = urlParams.order || '';
  const { loading, error, data } = useQuery<EventForVenueQuery, EventForVenueQueryVariables>(EVENT_FOR_VENUE_QUERY, {
    variables: { id, password: resultPassword },
  });

  if (loading) {
    return (
      <EventLayout title="Loading...">
        <Skeleton variant="rect" height={360} />
      </EventLayout>
    );
  }

  if (error) {
    return <Error500Page />;
  }

  if (!data?.event) {
    console.log('event not found venue page');
    return <Error404Page />;
  }
  const event = eventToFragment(data?.event);

  return (
    <EventLayout
      title={event.title}
      themePicUrl={event.themePic}
      readPage={true}
      bgColor={event.bgColor}
      bgNoBackdrop={event.bgNoBackdrop}
    >
      <div className="max-w-4xl mx-auto text-center text-base: sm:text-lg">
        <div className={styles.EventBlock}>
          {event.themePic && <img className={styles.HeaderImage} src={event.themePic} alt="" />}
          <div
            className={classNames(styles.MainInfo, {
              [styles.TopBorder]: !event.themePic,
            })}
          >
            <div className="mt-4 text-cool-gray-800">
              <div className="text-3xl sm:text-3xl">{event.title}</div>
            </div>
            <div className="mt-1 mb-10">
              <div className="flex items-center justify-center mt-2 font-semibold text-cool-gray-600">
                <span role="img" aria-label="Person Icon" className="text-sm">
                  👤
                </span>
                <div className="justify-center inline-block ml-2">
                  Hosted by {event.hostedByText === '__Host__' ? 'Demo Host' : event.hostedByText}
                </div>
              </div>
            </div>
            <VenueContent event={event} accessToken={accessToken} />
          </div>
        </div>
      </div>
    </EventLayout>
  );
};

export default VenuePage;
