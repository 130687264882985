import { Button, Loading } from 'components/common';
import { getStripeOauthLink } from 'utils/stripe';
import { isAuthenticated } from 'models';
import Dialog from 'components/common/Dialog/Dialog';
import React, { useState } from 'react';
import styles from './StripeConnectDialog.module.scss';
import useUser from 'utils/hooks/user/useUser';

interface Props {
  isOpen?: boolean;
  setIsOpen?: (b: boolean) => void;
  onClose?: () => void;
}

function openLinkInNewWindow(url: string, onClosed: () => void) {
  const newWindow = window.open(url);
  const int = setInterval(() => {
    if (newWindow?.closed) {
      onClosed();
      clearInterval(int);
    }
  }, 1000);
}

const StripeConnectDialog: React.FC<Props> = (props) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user, refetchUser } = useUser();

  const isControlled = props.isOpen != null && props.setIsOpen != null;
  const isOpen = isControlled ? props.isOpen! : open;
  const setIsOpen = isControlled ? props.setIsOpen! : setOpen;

  if (!isAuthenticated(user)) {
    return null;
  }

  const oauthLink = getStripeOauthLink(user);

  function onComplete() {
    refetchUser();
    setIsOpen(false);
  }

  function close() {
    setIsOpen(false);
    refetchUser();
    props.onClose && props.onClose();
  }

  return (
    <>
      {!isControlled && <Button onClick={() => setIsOpen(true)} label="Connect Bank" />}
      <Dialog
        title="Connect Bank Account"
        isOpen={isOpen}
        onClose={close}
        actions={[
          <Button key="cancel" label="Cancel" onClick={close} color="secondary" small />,
          <div key="save" onClick={() => setLoading(true)}>
            <Button
              label={loading ? <Loading size="small" /> : 'Connect with Stripe'}
              className={styles.connectButton}
              onClick={() => openLinkInNewWindow(oauthLink, onComplete)}
              small
            />
          </div>,
        ]}
      >
        <div>
          Mixily processes ticket payments with Stripe. To process payments and route funds directly to your bank
          account, we need to connect to your Stripe account. As part of this process, you'll be prompted to create a
          new Stripe account or sign into an existing one.
        </div>
      </Dialog>
    </>
  );
};

export default StripeConnectDialog;
