import { getImageColorInfo } from 'utils/helpers';
import Container from 'components/layout/Container/Container';
import Footer from 'components/layout/Footer/Footer';
import Header from 'components/layout/Header/Header';
import React, { useEffect, useState } from 'react';
import Sidebar from 'components/layout/Sidebar/Sidebar';
import ThemePicBlurryBackdrop from 'components/pages/EventReadPage/ThemePicBlurryBackdrop';
import classNames from 'classnames';
import styles from './EventLayout.module.scss';
import useViewAsContext from 'components/context/ViewAsContext/useViewAsContext';

interface Props {
  bgColor?: string | null;
  bgNoBackdrop?: boolean | null;
  children: React.ReactNode;
  noDefaultPadding?: boolean;
  readPage?: boolean;
  themePicUrl?: string | null;
  title: string;
}

type ColorFilter = 'white' | 'black' | undefined;
type ColorClass = 'filter-white--with-transition' | 'filter-black--with-transition' | undefined;
type ColorContext = {
  colorFilter: ColorFilter;
  colorClass: ColorClass;
};

export const EventLayoutContext = React.createContext<ColorContext>({ colorFilter: undefined, colorClass: undefined });

const EventLayout = (props: Props) => {
  useEffect(() => {
    // set the title to something different first:
    // this works around a bug in Chrome where the tab title wasn't getting updated
    // repro: go to index while logged in, click back, IndexPage redirects back to Calendar,
    //        but title is now "Mixily"
    document.title = props.title + '.';
    document.title = props.title;
  });

  const { themePicUrl, readPage } = props;
  const { isPreview } = useViewAsContext();

  const [colorFilter, setColorFilter] = useState<ColorFilter>();
  const [backgroundColor, setBackgroundColor] = useState<string>();

  useEffect(() => {
    if (readPage && themePicUrl) {
      getImageColorInfo(themePicUrl).then((info) => {
        setColorFilter(info.brightness <= 190 ? 'white' : 'black');
        setBackgroundColor(info.averageColor);
      });
    }
  }, [readPage, themePicUrl]);

  const colorClass =
    colorFilter === 'white'
      ? 'filter-white--with-transition'
      : colorFilter === 'black'
      ? 'filter-black--with-transition'
      : undefined;

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <EventLayoutContext.Provider value={{ colorFilter, colorClass }}>
      <div className={classNames(styles.EventLayout, 'relative', { 'min-h-screen': !isPreview })}>
        {readPage && themePicUrl && (
          <ThemePicBlurryBackdrop
            imageUrl={themePicUrl}
            backgroundColor={props.bgColor || backgroundColor}
            disabled={!!props.bgNoBackdrop}
          />
        )}
        <Sidebar handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} />
        <Header handleDrawerToggle={handleDrawerToggle} withSidebar />

        <Container className={classNames(styles.container, { nopadding: props.noDefaultPadding })}>
          <div className="EventLayout">{props.children}</div>
        </Container>
        <Footer />
      </div>
    </EventLayoutContext.Provider>
  );
};

export default EventLayout;
