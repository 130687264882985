import * as m from 'models';
import { Controller, useForm } from 'react-hook-form';
import { Invite, MaybeRsvpResponse, getEmail, getName, isRsvpResponse } from 'models/invite';
import { RsvpStates } from 'generated/globalTypes';
import { plusOnesOptionsForGuest } from 'utils/helpers';
import DisableInPreview from 'components/DisableInPreview/DisableInPreview';
import FieldLayout from 'components/common/FieldLayout/FieldLayout';
import Input2 from 'components/common/Input2/Input2';
import React, { useEffect } from 'react';
import SelectInput from 'components/common/SelectInput/SelectInput';
import TextArea2 from 'components/common/TextArea2';
import isEmail from 'validator/lib/isEmail';
import useFormHelpers from 'components/forms/useFormHelpers';

const messagePlaceholderForRsvpState = {
  y: `E.g. Can't wait!`,
  n: `E.g. Sorry, I can't go!`,
  m: `E.g. Hope to make it!`,
};

interface Props {
  handleSubmit: (formResult: FormT) => void;
  invite?: Invite;
  maxPlusN?: number;
  pending: boolean;
  selectedRsvp: MaybeRsvpResponse;
  user: m.User;
}

export type FormT = {
  name: string;
  email: string;
  rsvpAnswer: string;
  plusN: number;
};

const getDefaultValues = (user: m.User, invite?: Invite): FormT => {
  const name = getName(invite) ?? (m.isAuthenticated(user) ? user.name : '');
  const email = getEmail(invite) ?? (m.isAuthenticated(user) ? user.getPrimaryEmail() : '');
  const rsvpAnswer = invite?.rsvpAnswer ?? '';
  const plusN = invite?.rsvpPlusN ?? 0;
  return { name, email, rsvpAnswer, plusN };
};

const RsvpFormInner = (props: Props) => {
  const { invite, handleSubmit, pending, user, selectedRsvp, maxPlusN } = props;
  const allowPlusN = !!maxPlusN && maxPlusN > 0;
  const formContext = useForm<FormT>({
    defaultValues: getDefaultValues(user, invite),
  });

  const { setValue, watch, register, errors, control } = formContext;

  useEffect(() => {
    if (invite) {
      setValue('name', getName(invite));
      setValue('email', getEmail(invite));
      setValue('plusN', invite?.rsvpPlusN ?? 0);
    } else if (m.isAuthenticated(user)) {
      setValue('name', user.name);
      setValue('email', user.getPrimaryEmail());
    }
  }, [invite, setValue, user]);

  const rsvpResponseSaved = invite && isRsvpResponse(invite.state!);
  const [label, submittingLabel] = rsvpResponseSaved ? ['Update RSVP', 'Updating...'] : ['Send RSVP', 'Sending...'];
  const { Form, SubmitButton, FormLevelMessages, FormStateContextProvider } = useFormHelpers();
  const showPlusN: boolean = allowPlusN && selectedRsvp !== RsvpStates.n;

  const plusNOptions = plusOnesOptionsForGuest(maxPlusN ?? 0);

  return (
    <FormStateContextProvider onSubmit={(data) => handleSubmit(data)} formContext={formContext}>
      <Form>
        <FieldLayout
          label={<span className="text-base leading-none">Name</span>}
          error={errors.name}
          helpText="How your RSVP will appear to other guests"
        >
          <Input2 name="name" ref={register({ required: true })} placeholder="Your Name" size="lg" />
        </FieldLayout>

        <FieldLayout
          className="mt-6"
          label={<span className="text-base leading-none">Email address</span>}
          error={errors.email}
          helpText="Where the host can send updates"
        >
          <Input2
            name="email"
            ref={register({
              required: true,
              validate: (value: string) => (!isEmail(value) ? 'Please enter a valid email address' : true),
            })}
            type="email"
            placeholder="you@example.com"
            size="lg"
            disabled={rsvpResponseSaved || m.isAuthenticated(user)}
          />
        </FieldLayout>

        <FieldLayout
          className="mt-6"
          label={<span className="text-base leading-none">Include a message with your RSVP?</span>}
          error={errors.rsvpAnswer}
          helpText="Optional. This will only be visible to the host."
        >
          <TextArea2
            name="rsvpAnswer"
            ref={register()}
            placeholder={messagePlaceholderForRsvpState[selectedRsvp ? selectedRsvp : RsvpStates.y]}
          />
        </FieldLayout>

        <FieldLayout
          className={showPlusN ? 'mt-6' : 'hidden'}
          label={<span className="text-base leading-none">Bringing +n?</span>}
          error={errors.plusN}
          alignLeft
        >
          <Controller
            as={SelectInput}
            name="plusN"
            control={control}
            value={watch('plusN')}
            onChange={([e]) => Number(e.target.value) ?? 0}
            attrs={{ options: plusNOptions, noFullWidth: false }}
          />
        </FieldLayout>

        <FormLevelMessages className="mt-4" />

        <DisableInPreview>
          <div className="mt-6">
            <SubmitButton label={pending ? submittingLabel : label} className="w-full sm:w-full" size="14" />
          </div>
        </DisableInPreview>
      </Form>
    </FormStateContextProvider>
  );
};

export default RsvpFormInner;
