/// <reference types="resize-observer-browser" />
// See: https://github.com/Microsoft/TypeScript/issues/28502
//
// Example usage: /*
// const resizeEntry = useResizeObserver(ref);
// const containerWidth = resizeEntry?.contentRect.width;
// const containerHeight = resizeEntry?.contentRect.height;

// Based on https://tobbelindstrom.com/blog/resize-observer-hook/

import { RefObject, useCallback, useLayoutEffect, useRef, useState } from 'react';

const useResizeObserver = (ref: RefObject<HTMLElement | null>) => {
  const [observerEntry, setObserverEntry] = useState<ResizeObserverEntry>();
  const observer = useRef<ResizeObserver>();

  const observe = useCallback(() => {
    observer.current = new window.ResizeObserver((entries) => {
      // Fixes ResizeObserver loop limit exceeded, see https://stackoverflow.com/a/58701523
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        setObserverEntry(entries[0]);
      });
    });
    if (ref.current) observer.current.observe(ref.current);
  }, [ref, observer]);

  const disconnect = useCallback(() => observer.current?.disconnect(), []);

  useLayoutEffect(() => {
    observe();
    return () => disconnect();
  }, [disconnect, observe]);

  return observerEntry;
};

export default useResizeObserver;
