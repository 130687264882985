// Like useHasFeatureFlag, but takes a list of flags
// Example:
// const {foo, bar} = useHasFeatureFlags('foo', 'bar')

import { FlagName, FlagState } from 'utils/hooks/user/featureFlag';
import { User, isAuthenticated } from 'models';
import { fromPairs } from 'shared/shared/Functional';
import useUser from 'utils/hooks/user/useUser';

const hasFeatureFlag = (loading: boolean, user: User, flagName: FlagName): FlagState => {
  if (loading) {
    return undefined;
  }
  if (!isAuthenticated(user)) {
    return false;
  }

  return user.features?.includes(flagName);
};

const useHasFeatureFlags = (...flagNames: FlagName[]): Record<FlagName, FlagState> => {
  const { loading, user } = useUser();
  const flagStates: [FlagName, FlagState][] = flagNames.map((flagName: FlagName) => [
    flagName,
    hasFeatureFlag(loading, user, flagName),
  ]);
  return fromPairs(flagStates);
};
export default useHasFeatureFlags;
