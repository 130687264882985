import * as F from 'shared/shared/Functional';
import * as m from 'models';
import { Currency, CurrencyType } from 'shared/shared/types';
import { WidgetProps } from 'components/widgets';
import { areThereSoldTickets } from 'models/event';
import MuiSelectWidget from 'components/widgets/MuiSelectWidget/MuiSelectWidget';
import React from 'react';

interface Attrs {
  event?: m.Event;
}

const CurrencyWidget: React.FC<WidgetProps<Currency, Attrs>> = (props) => {
  const { onChange, value, attrs, disabled } = props;
  const soldTickets: boolean = areThereSoldTickets(attrs?.event);
  return (
    <div className="input-container vstack-small">
      <div className="my-2 ml-2">
        <div className="vstack-small">
          <MuiSelectWidget
            disabled={disabled || soldTickets}
            value={value}
            onChange={(v) => onChange(v as Currency)}
            attrs={{
              options: F.objectFlip(CurrencyType),
              noFullWidth: true,
              title: 'Payment Currency',
              helpText: soldTickets
                ? 'Event currency cannot be changed after tickets have been purchased'
                : 'Currency attendees will use to buy tickets',
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CurrencyWidget;
