import { Currency, CurrencyType, DiscountKind } from './types';
import { MAX_CAPACITY_FREE_EVENTS } from './constants';

// https://ontariotraining.net/writing-style-currencies/
function withCurrency(value: string, currency: Currency): string {
  if (currency === CurrencyType.CAD) {
    return `CAD ${value}`;
  }
  return `$${value} USD`;
}

export const renderDollars = (cents: number, currency: Currency): string => {
  const dollarsPart = Math.floor(cents / 100).toLocaleString('en-US');
  const centsPart = String(cents % 100).padStart(2, '0');
  return withCurrency(`${dollarsPart}.${centsPart}`, currency);
};

export const renderWholeMoney = (cents: number, currency: Currency): string => {
  const dollarsPart = Math.round(cents / 100).toLocaleString('en-US');
  return withCurrency(dollarsPart, currency);
};

export function renderDiscountCode(discountCode: any): string {
  const amount =
    discountCode.type === DiscountKind.dollar ? `$${discountCode.amount} off / ticket` : `${discountCode.amount}% off`;

  return `${discountCode.code} ${amount}`;
}

// based on https://stackoverflow.com/a/7616484
export const hashCode = (str: string) => {
  let hash = 0;
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
};

export const _makeAbsoluteUrl = (hostname: string, path: string, useInsecureHttp = false): string => {
  const protocol = useInsecureHttp ? 'http' : 'https';
  return `${protocol}://${hostname}${path}`;
};

//
// Based on https://stackoverflow.com/a/61076348/
//
type Impossible<K extends keyof any> = {
  [P in K]: never;
};
export type NoExtraProperties<T, U extends T = T> = U extends Array<infer V>
  ? NoExtraProperties<V>[]
  : U & Impossible<Exclude<keyof U, keyof T>>;

export const oxford = (list: string[]): string => {
  // Joins the items of `list` into a single string, in the Oxford comma style.
  let l = list.length;
  if (l === 0 || l === 1) {
    return list.join('');
  } else if (l === 2) {
    return list[0] + ' and ' + list[1];
  } else {
    return list.slice(0, -1).join(', ') + ', and ' + list[list.length - 1];
  }
};

export const getRandomInt = (min: number, max: number) => {
  return Math.round(Math.random() * (max - min) + min);
};

export const chooseRandom = (values: any[]): any => {
  return values[getRandomInt(0, values.length - 1)];
};

export const randStr = (length = 12): string => {
  // Based on https://stackoverflow.com/a/1349426
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

// https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url
// eslint-disable-next-line no-useless-escape
const urlPattern = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
const urlRegex = new RegExp(urlPattern);
export const isUrl = (str: string): boolean => !!str.match(urlRegex);

export const isAddress = (text: string) => {
  const numCommas = (text.match(/,/g) || []).length;
  const numNumbers = (text.match(/[0-9]+/g) || []).length;
  return numCommas >= 2 && numNumbers >= 1;
}; // decent guess

export type URLParams = Record<string, string>;
export function constructQueryString(paramMap: URLParams): string {
  let parts: string[] = [];

  for (let key in paramMap) {
    let val: string = paramMap[key];
    if (val) {
      parts.push(key + '=' + encodeURIComponent(val));
    }
  }

  let qs: string = parts.join('&');
  if (qs.length) {
    qs = '?' + qs;
  }
  return qs;
}

// Greatest Common Divider
export const gcd = (a: number, b: number): number => {
  return !b ? a : gcd(b, a % b);
};

// Least Common Multiple
export const lcm = (a: number, b: number): number => {
  return (a * b) / gcd(a, b);
};

export type GridLayoutConfig = {
  rows: number;
  cols: number;
  tileSize: {
    width: number;
    height: number;
  };
  gridSize: {
    width: number;
    height: number;
  };
};

export const getLayoutForCols = (
  numTiles: number,
  containerWidth: number,
  containerHeight: number,
  numCols: number,
  margin: number,
  aspectRatio: number
): GridLayoutConfig => {
  // Pigeon-hole principle
  const numRows = Math.ceil(numTiles / numCols);

  // Calculate size of each grid cell that will contain a tile
  const wMarginSpace = margin * (numCols + 1);
  const hMarginSpace = margin * (numRows + 1);
  const availableWidth = containerWidth - wMarginSpace;
  const availableHeight = containerHeight - hMarginSpace;
  const targetWidth = Math.round(availableWidth / numCols);
  const targetHeight = Math.round(availableHeight / numRows);

  // How big can we get inside the grid cell?
  const targetAspectRatio = targetWidth / targetHeight;
  let adjustedWidth = targetWidth,
    adjustedHeight = targetHeight;
  if (aspectRatio <= targetAspectRatio) {
    adjustedWidth = Math.round(targetHeight * aspectRatio);
  } else {
    adjustedHeight = Math.round(targetWidth / aspectRatio);
  }

  return {
    rows: numRows,
    cols: numCols,
    tileSize: {
      width: adjustedWidth,
      height: adjustedHeight,
    },
    gridSize: {
      width: adjustedWidth * numCols + margin * (numCols + 1),
      height: adjustedHeight * numRows + margin * (numRows + 1),
    },
  };
};

export const getNumCols = (numTiles: number): number => {
  return Math.ceil(Math.sqrt(numTiles));
};

export const isEventAtCapacity = (maxCapacity: number | null | undefined, confirmedGuests: number): boolean => {
  if (maxCapacity === null || maxCapacity === undefined) {
    return false;
  } else {
    return confirmedGuests >= maxCapacity;
  }
};

// Note: This should ideally be an environment variable for flexibility,
// but it is currently shared between front-end and back-end as a temporary measure
// while implementing pricing for events.
const EXCEPTION_EVENT_IDS = ['182585344550537473'];
const EXCEPTION_OWNER_ID = ['7199030030257635307'];

export const hasReachedAllowedCapacity = (
  invitedGuests: number,
  eventId: string,
  ownerId: string | undefined
): boolean => {
  const isExceptionEvent = EXCEPTION_EVENT_IDS.includes(eventId);
  const isExceptionOwner = ownerId ? EXCEPTION_OWNER_ID.includes(ownerId) : false;
  const isOverCapacity = invitedGuests >= MAX_CAPACITY_FREE_EVENTS;

  return isOverCapacity && !isExceptionEvent && !isExceptionOwner;
};
