import Container from 'components/layout/Container/Container';
import Link from 'components/Link';
import React from 'react';

const Footer3 = () => {
  return (
    <Container className="text-center">
      <div className="w-full mx-auto lg:max-w-screen-md md:max-w-screen-sm sm:max-w-screen-xs">
        <div className="mt-12 mb-6 text-xl font-semibold leading-tight text-black md:mb-10 lg:text-4xl md:text-3xl sm:text-2xl lg:mt-24 md:mt-20 sm:mt-16">
          Virtual Venue is a product of{' '}
          <Link href="/" className="cursor-pointer text-default">
            Mixily
          </Link>
          . Create event pages, send invitations, collect RSVPs, sell tickets, message your guests, and more.
        </div>
      </div>
    </Container>
  );
};

export default Footer3;
