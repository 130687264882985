// Functional helpers
//
// Recommended usage (frontend):
// import * as F from 'shared/shared/Functional';
//
// Recommended usage (backend):
// import * as F from '@/shared/shared/Functional';
//
// Unfortunately, existing libraries are too academic or have poor typescript support.
// It's easy enough to implement the few practical functions we need.

// Based on https://stackoverflow.com/a/25835337
export function pick<T extends object, K extends keyof T>(o: T, ...fields: K[]): Pick<T, K> {
  return fields.reduce((a: any, x) => {
    // eslint-disable-next-line no-prototype-builtins
    if (o.hasOwnProperty(x)) {
      a[x] = o[x];
    }
    return a;
  }, {});
}

// Based on https://stackoverflow.com/a/53968837
export function omit<T extends object, K extends keyof T>(o: T, ...fields: K[]): Omit<T, K> {
  let ret = {} as {
    [K in keyof typeof o]: typeof o[K];
  };
  let key: keyof typeof o;
  for (key in o) {
    if (!fields.includes(key as any)) {
      ret[key] = o[key];
    }
  }
  return ret;
}

// Map object to list - Record<K,V> => (K, V) -> T => T[]
export function objMap<K extends string, V, T>(obj: Record<K, V>, f: (k: K, v: V) => T) {
  return Object.keys(obj).map((k) => f(k as K, obj[k as K]));
}

// Transforms every key of object
export function objMapKeys<KBefore extends string, KAfter extends string, V>(
  obj: Record<KBefore, V>,
  f: (k: KBefore) => KAfter
): Record<KAfter, V> {
  const result = {} as Record<KAfter, V>;
  for (const key of Object.keys(obj)) {
    result[f(key as KBefore) as KAfter] = obj[key as KBefore];
  }
  return result;
}

// Transforms every value of object
export function objMapValues<VBefore, VAfter, K extends string>(
  obj: Record<K, VBefore>,
  f: (v: VBefore) => VAfter
): Record<K, VAfter> {
  const result = {} as Record<K, VAfter>;
  for (const key of Object.keys(obj)) {
    result[key as K] = f(obj[key as K]);
  }
  return result;
}

export const sum = (arr: number[]): number => arr.reduce((a, b) => a + b, 0);

// Returns a list of numbers from `from` (inclusive) to `to` (exclusive).
export const range = (from: number, to: number): number[] => {
  const result = [];
  for (let i = from; i < to; i++) {
    result.push(i);
  }
  return result;
};

export function prop<T extends {}, K extends keyof T>(key: K): (obj: T) => T[K];
export function prop<K extends string, T extends Record<K, any>>(key: K): (obj: T) => T[K];
export function prop<T>(key: Key): (obj: any) => T | undefined {
  return (obj) => obj[key];
}

// Extract a prop (`key`) from a list of objects with that key
export function pluck<T extends {}, K extends keyof T>(key: K, list: T[]): T[K][] {
  return list.map(prop(key));
}

export const any = (arr: any[]): boolean => {
  for (const el of arr) {
    if (el) return true;
  }
  return false;
};

// The keys of T that have values in U
export type KeysWithType<T, U> = { [K in keyof T]: T[K] extends U ? K : never }[keyof T];

type Key = string | number;
type GroupBy2GetKey<T> = ((el: T) => Key) | KeysWithType<T, Key>;
type GroupBy2Result<T> = Record<Key, T[]>;

export const groupBy = <T>(arr: T[], getKey: GroupBy2GetKey<T>): GroupBy2Result<T> => {
  const result: GroupBy2Result<T> = {};
  for (const el of arr) {
    const key = (typeof getKey === 'function' ? getKey(el) : el[getKey]) as Key;
    if (!result[key]) {
      result[key] = [];
    }
    result[key].push(el);
  }
  return result;
};

export const arrUniq = <T>(arr: T[]): T[] => {
  return Array.from(new Set(arr));
};

export const arrUniqBy = <T = any>(arr: T[], keyFn: (val: T) => Key): T[] => {
  const seen = new Set();
  return arr.filter((e) => {
    const key = keyFn(e);
    if (seen.has(key)) {
      return false;
    }
    seen.add(key);
    return true;
  });
};

// Filters object based on fn. Similar to Array filter
// todo: fix typing
type FilterFn = <T extends Record<string, any>>(key: keyof T, val: any, obj: T) => boolean;

export const objFilter = <T>(obj: T, fn: FilterFn) => {
  const result: Partial<T> = {};
  for (const k in obj) {
    if (fn(k, obj[k], obj as Record<string, any>)) {
      result[k] = obj[k];
    }
  }
  return result;
};

export const identity = <T = any>(x: T): T => x;

export const propEq = (propName: string, propVal: any) => (x: any) => x[propName] === propVal;

// Strict version of `find` that errors if not found
export function find<T = any>(pred: (v: T) => boolean, l: T[]): T {
  for (const v of l) {
    if (pred(v)) return v;
  }
  console.error(pred);
  console.error(l);
  throw new Error('No element found matching predicate');
}

// Create an object from key-value pairs, [[k,v]] -> {k: v}
// Does not allow duplicate keys with the same name
export function fromPairs<K extends string = any, V = any>(pairs: [K, V][]): Record<K, V> {
  const result = {} as Record<K, V>;
  for (const [k, v] of pairs) {
    if (result[k]) {
      throw new Error(`Duplicate key ${k} found in pairs`);
    }
    result[k] = v;
  }
  return result;
}

type Comp<T> = (a: T, b: T) => number;

const comp: Comp<string | number | Date> = (a, b) => (a === b ? 0 : a > b ? 1 : -1);

// Sort an array based on some mapped value
function sortByMap<T, U>(arr: T[], map: (val: T) => U, cmp: Comp<U>): T[] {
  // Recalculates map on each iteration. A smarter version would cache these values.
  return arr.sort((a, b) => cmp(map(a), map(b)));
}

export function sortBy<T>(arr: T[], map: (val: T) => string | number | Date): T[] {
  return sortByMap(arr, map, comp);
}

export const maybeDateCmp: Comp<Date | undefined> = (d1: Date | undefined, d2: Date | undefined) =>
  d1 && d2 ? d2.valueOf() - d1.valueOf() : 0;

export function sortByDate<T>(arr: T[], map: (val: T) => Date | undefined): T[] {
  return sortByMap(arr, map, maybeDateCmp);
}

// https://stackoverflow.com/a/23013726/5924089
export function objectFlip(obj: Record<string, string>) {
  const ret = {};
  Object.keys(obj).forEach((key: string) => {
    // @ts-ignore
    ret[obj[key]] = key;
  });
  return ret;
}

export function countRepeatedElements<K extends string = any, V = any>(arr: V[]): Record<K, V>[] {
  return Object.values(
    arr.reduce((c, v) => {
      // @ts-ignore
      c[v] = c[v] || [v, 0];
      // @ts-ignore
      c[v][1]++;
      return c;
    }, {})
    // @ts-ignore
  ).map((o) => ({ [o[0]]: o[1] })) as any;
}
