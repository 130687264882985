import React, { ReactElement, useState } from 'react';
import useTimeout from 'utils/hooks/useTimeout';

interface Props {
  delay?: number;
  children: ReactElement;
}

const Delay = (props: Props) => {
  const { delay = 0 } = props;
  const [visible, setVisible] = useState(!delay);
  useTimeout(() => setVisible(true), delay);
  return visible ? props.children : <></>;
};

export default Delay;
