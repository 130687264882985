import { Button } from 'components/common';
import { Listing_listing_listingEvents_event_forCalendar } from 'generated/Listing';
import { appendQueryString } from 'utils/urls';
import Dropdown from 'components/common/Dropdown/Dropdown';
import React from 'react';

export enum ButtonCalendarType {
  BUTTON,
  LINK,
}
interface Props {
  eventId: string;
  forCalendar: Listing_listing_listingEvents_event_forCalendar | null | undefined;
  type: ButtonCalendarType;
}

const makeGcalUrl = (forCalendar: Listing_listing_listingEvents_event_forCalendar | null) => {
  // See https://stackoverflow.com/a/21653600 for some help
  // Note: Gcal URLs with a blank 'dates' *do* work. though we aren't
  // showing this button in the UI right now.
  const start = forCalendar?.start ? forCalendar.start : null;
  const end = forCalendar?.end ? forCalendar.end : null;
  const dates = start ? `${start}/${end}` : '';
  const params = {
    action: 'TEMPLATE',
    text: forCalendar?.title ?? '',
    dates: dates,
    details: forCalendar?.description ?? '',
    location: forCalendar?.location ?? '',
    trp: 'false',
    sprop: forCalendar?.eventUrl ?? '', // is this the right param?
    ctz: forCalendar?.timezone ?? '',
  };
  const link = appendQueryString('https://www.google.com/calendar/event', params);
  console.log({ link });
  return link;
};

const handleAddToCalendar = (
  calendar: 'google' | 'ical',
  eventId: string,
  forCalendar: Listing_listing_listingEvents_event_forCalendar | null
) => () => {
  let url: string;
  if (calendar === 'google') {
    url = makeGcalUrl(forCalendar);
  } else {
    url = `/gen/ics/${eventId}`;
  }

  // Test for Chrome iOS and Firefox iOS, which don't support .ics files
  if (/CriOS|FxiOS/.test(navigator.userAgent)) {
    alert('On iOS devices, only Safari supports iCal. Please open this page in Safari and try again.');
  } else {
    window.open(url, '_blank');
  }
};

const AddToCalendar: React.FC<Props> = ({ eventId, forCalendar, type }) => {
  if (!forCalendar?.start) {
    return null;
  }

  const children =
    type === ButtonCalendarType.BUTTON ? (
      <Button label="Add to Calendar" small />
    ) : (
      <span className="text-link-color">Add to Calendar</span>
    );

  return (
    <Dropdown
      items={[
        {
          text: 'Add to Google Calendar',
          onClick: handleAddToCalendar('google', eventId, forCalendar),
        },
        { text: 'Add to iCal', onClick: handleAddToCalendar('ical', eventId, forCalendar) },
      ]}
    >
      {children}
    </Dropdown>
  );
};

export default AddToCalendar;
