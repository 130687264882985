import React from 'react';
import classNames from 'classnames';
import styles from 'components/common/Divider.module.scss';

interface Props {
  className?: string;
  spacing?: 'tiny' | 'small' | 'medium' | 'large' | 'xlarge';
  children?: string;
}

const Divider = (props: Props) => {
  const { spacing = 'medium', children, className } = props;
  const cls = classNames(styles.Divider, styles[spacing], className);
  const hasChildren = classNames(children && styles.hasChildren);
  return (
    <div className={cls}>
      <span className={hasChildren}>{children}</span>
    </div>
  );
};

export default Divider;
