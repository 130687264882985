import { VideoTile } from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/types';
import { hashCode } from 'shared/shared/utils';
import BroadcastLayout from 'components/VirtualVenue/VirtualVenueDisplay/shared/VideoStream/VideoStreamDisplay/BroadcastLayout/BroadcastLayout';
import React from 'react';

interface Props {
  autoplay?: boolean;
  loop?: boolean;
  videoUrl: string;
  width?: string;
}

const VideoPlaybackDisplay = ({ videoUrl, loop, width, autoplay }: Props) => {
  const tile: VideoTile = {
    id: 'video-' + hashCode(videoUrl),
    type: 'video',
    url: videoUrl,
    options: {
      autoplay: autoplay ? 'play' : undefined,
      loop,
    },
  };
  return <BroadcastLayout tiles={[tile]} width={width} />;
};

export default VideoPlaybackDisplay;
