// Sets global event id context, then cleans it up on unload

import { useEffect } from 'react';
import useGlobalState from 'components/App/globalstate/useGlobalState';

const useSetEventId = (id: string | undefined) => {
  const { setEventId } = useGlobalState();
  useEffect(() => {
    setEventId(id);
    return () => setEventId(undefined);
  }, [id, setEventId]);
};

export default useSetEventId;
