import { PubnubMessageEvent } from 'components/VirtualVenue/pubnub/pubnub';
import React from 'react';

//
// State
//

type VirtualVenueState = {
  // This is the user's local version of what their name to be.
  // For now it's sent along with all chat messages.
  // It needs to sync to the user's PubNub presence state before it's visible
  // *outside* of chat messages, e.g. in a list of room participants.
  // This is done within the PubNub state manager by watching *this* value.
  // Only `null` at first load.
  name: string | null;

  // Override venue title with updates received via PubNub
  // This is sort of hacky -- we should probably have a single source of truth
  // for venue data, especially if we start updating more fields through PN --
  // but fastest solution for now.
  newTitle?: string;

  // Whether the chat pane is open or not
  chatOpen: boolean | null;

  // Whether the venue is closed or not
  isClosed: boolean | null;

  awayMessage: string | null;

  unseenChatMessages: number;

  pinnedParticipant?: string;
};

const defaultVirtualVenueState = {
  name: null,
  chatOpen: null,
  isClosed: null,
  awayMessage: null,
  unseenChatMessages: 0,
} as const;

//
// Actions
//

type SetName = { type: 'set-name'; name: string };
type PNUpdateVenue = {
  type: 'pn-update-venue';
  message: PubnubMessageEvent<{
    type: 'update-venue';
    data: {
      title: string;
      isClosed: boolean | null;
      awayMessage: string;
    };
  }>;
};
type ToggleChatOpen = { type: 'toggle-chat-open' };
type IncrementUnseenChatMessages = { type: 'increment-unseen-chat-messages' };
type PinParticipant = { type: 'pin-participant'; participant: string };
type UnpinParticipant = { type: 'unpin-participant' };

export type VirtualVenueAction =
  | SetName
  | PNUpdateVenue
  | ToggleChatOpen
  | IncrementUnseenChatMessages
  | PinParticipant
  | UnpinParticipant;

//
// Reducer
//

const venueReducer = (venueState: VirtualVenueState, action: VirtualVenueAction): VirtualVenueState => {
  switch (action.type) {
    case 'set-name':
      return {
        ...venueState,
        name: action.name,
      };
    case 'pn-update-venue':
      return {
        ...venueState,
        newTitle: action.message.message.data.title,
        isClosed: action.message.message.data.isClosed,
        awayMessage: action.message.message.data.awayMessage,
      };
    case 'toggle-chat-open':
      return {
        ...venueState,
        unseenChatMessages: !venueState.chatOpen ? 0 : venueState.unseenChatMessages,
        chatOpen: !venueState.chatOpen,
      };
    case 'pin-participant':
      return {
        ...venueState,
        pinnedParticipant: action.participant,
      };
    case 'unpin-participant':
      return {
        ...venueState,
        pinnedParticipant: undefined,
      };
    case 'increment-unseen-chat-messages':
      return {
        ...venueState,
        unseenChatMessages: venueState.unseenChatMessages + (venueState.chatOpen ? 0 : 1),
      };
    default:
      throw new Error();
  }
};

//
// Contexts
//

const VirtualVenueStateContext = React.createContext<VirtualVenueState>(null as any);

function useVirtualVenueState(): VirtualVenueState;
function useVirtualVenueState(optional: true): VirtualVenueState | undefined;
function useVirtualVenueState(optional?: boolean): VirtualVenueState | undefined {
  const context = React.useContext(VirtualVenueStateContext);
  if (!optional && context === undefined) {
    throw new Error('useVirtualVenue with optional:false must be used within a VirtualVenueProvider');
  }
  return context;
}

type VirtualVenueDispatchContextT = React.Dispatch<VirtualVenueAction>;

const VirtualVenueDispatchContext = React.createContext<VirtualVenueDispatchContextT | undefined>(undefined);

function useVirtualVenueDispatch(): VirtualVenueDispatchContextT;
function useVirtualVenueDispatch(optional: true): VirtualVenueDispatchContextT | undefined;
function useVirtualVenueDispatch(optional?: boolean): VirtualVenueDispatchContextT | undefined {
  const context = React.useContext(VirtualVenueDispatchContext);
  if (!optional && context === undefined) {
    throw new Error('useVirtualVenueDispatch with optional:false must be used within a VirtualVenueProvider');
  }
  return context;
}

//
// Provider
//

interface Props {
  initialState?: Partial<VirtualVenueState>;
  children: React.ReactNode;
}

function VirtualVenueStateProvider({ children, initialState }: Props) {
  const combinedState: VirtualVenueState = {
    ...defaultVirtualVenueState,
    ...initialState,
  };

  const [state, dispatch] = React.useReducer(venueReducer, combinedState);

  return (
    <VirtualVenueStateContext.Provider value={state}>
      <VirtualVenueDispatchContext.Provider value={dispatch}>{children}</VirtualVenueDispatchContext.Provider>
    </VirtualVenueStateContext.Provider>
  );
}

export { VirtualVenueStateProvider, useVirtualVenueState, useVirtualVenueDispatch };
