export interface Size {
  width: number;
  height: number;
}

export const isUploadCareUrl = (url?: string): boolean => !!url && url.startsWith('https://ucarecdn.com/');

export const uploadCareBaseUrl = (url: string): string => (url || '').split('-/')[0];

export const uploadCareBaseUrlWithDefaults = (url: string, effects = ''): string => {
  if (isUploadCareUrl(url)) {
    return `${uploadCareBaseUrl(url)}-/autorotate/yes/${effects}`;
  }

  return url;
};

export const uploadCareUrlScaleCrop = (url: string, width: number, height: number): string => {
  if (isUploadCareUrl(url)) {
    const preview = `${width}x${height}`;
    return `${uploadCareBaseUrl(url)}-/autorotate/yes/-/scale_crop/${preview}/center/`;
  }

  return url;
};

export const uploadCareApplyCrop = (pixelCrop: any): string => {
  const width = Math.round(pixelCrop.destWidth);
  const cropWidth = Math.round(pixelCrop.cropWidth);
  const cropHeight = Math.round(pixelCrop.cropHeight);
  const x = Math.round(pixelCrop.cropX);
  const y = Math.round(pixelCrop.cropY);

  const cropSize = `${cropWidth}x${cropHeight}`;
  return `-/stretch/off/-/crop/${cropSize}/${x},${y}/-/resize/${width}x/`;
};
