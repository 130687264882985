import { PollView } from '../pages/EventReadPage/polls/PollView';
import { RenderTime, renderFullTime } from 'models/event';
import { RunEventId_run } from 'generated/RunEventId';
import { renderOccurrences } from 'models/run';
import ErrorBoundary from 'components/common/ErrorBoundary';
import IconWithText from 'components/common/IconWithText/IconWithText';
import React from 'react';

interface Props {
  event: EventWhenFragment;
  inviteName?: string;
  isRun?: boolean;
  run?: RunEventId_run;
  alignTop?: boolean;
}
interface EventWhenFragment extends RenderTime {
  poll?: {
    id: string;
  };
}

const When = ({ event, inviteName, alignTop, isRun, run }: Props) => {
  const [startTime, endTime] = isRun ? renderOccurrences(run) : renderFullTime(event);
  const pollId = (event.poll || {}).id;

  // TODO: Dynamic dates based on run occurrences
  const manualCinemaDates = 'Dec. 3rd–Dec. 20th';

  return (
    <IconWithText icon="calendar" alignTop={alignTop}>
      {startTime === 'TBD' && pollId ? (
        <ErrorBoundary>
          <PollView id={pollId} voterName={inviteName} />
        </ErrorBoundary>
      ) : (
        <span>{isRun ? `Multiple Days, ${manualCinemaDates}` : startTime}</span>
      )}
      {!isRun && endTime && <span className="whitespace-nowrap"> – {endTime}</span>}
    </IconWithText>
  );
};

export default When;
