import { Confirm, Loading } from 'components/common';
import { getDisplayConfig } from 'components/VirtualVenue/utils';
import { logDailyEvent } from 'components/VirtualVenue/daily/utils';
import { useCallConfigDispatch } from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/contexts/CallConfigContext';
import { useVirtualVenue } from 'components/VirtualVenue/contexts/VirtualVenueContext';
import { useVirtualVenueCall } from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/contexts/VirtualVenueCallContext';
import { useVirtualVenueContentType } from 'components/VirtualVenue/contexts/VirtualVenueContentTypeContext';
import {
  useVirtualVenueDispatch,
  useVirtualVenueState,
} from 'components/VirtualVenue/contexts/VirtualVenueStateContext';
import { useVirtualVenueDisplay } from 'components/VirtualVenue/contexts/VirtualVenueDisplayContext';
import DailyIframe, { DailyCall, DailyEventObjectParticipant } from '@daily-co/daily-js';
import IconSvg from 'components/common/IconSvg/IconSvg';
import MicTrayButton from 'components/VirtualVenue/VirtualVenueDisplay/layouts/VirtualVenueDisplayLayout/Tray/MicTrayButton/MicTrayButton';
import React, { useEffect, useState } from 'react';
import RequestUnmuteDialog from 'components/VirtualVenue/VirtualVenueDisplay/layouts/VirtualVenueDisplayLayout/Tray/RequestUnmuteDialog/RequestUnmuteDialog';
import TrayButton from './TrayButton/TrayButton';
import TraySettingsMenu from 'components/VirtualVenue/VirtualVenueDisplay/layouts/VirtualVenueDisplayLayout/Tray/TraySettingsMenu/TraySettingsMenu';
import classNames from 'classnames';
import useWindowSize from 'utils/hooks/useWindowSize';

const getStreamStates = (callObject?: DailyCall) => {
  const localParticipant = callObject?.participants().local;
  return {
    isCameraMuted: localParticipant ? !localParticipant.video : false,
    isMicMuted: localParticipant ? !localParticipant.audio : false,
    isSharingScreen: localParticipant ? localParticipant.screen : false,
  };
};

const Tray = () => {
  const [isCameraMuted, setCameraMuted] = useState(false);
  const [isMicMuted, setMicMuted] = useState(false);
  const [isSharingScreen, setSharingScreen] = useState(false);
  const [isStartingRecording, setStartingRecording] = useState(false);
  const [showConfirmLeaveCall, setShowConfirmLeaveCall] = useState(false);

  const venue = useVirtualVenue();
  const { unseenChatMessages } = useVirtualVenueState();
  const venueDispatch = useVirtualVenueDispatch();
  const callConfigDispatch = useCallConfigDispatch(true);
  const { isLiveMode } = useVirtualVenueDisplay();
  const contentType = useVirtualVenueContentType();
  const { isMobileXs } = useWindowSize();

  const {
    myAccess: { ableToRecord },
  } = venue;

  const call = useVirtualVenueCall(true);
  const dailyState = call?.dailyState;
  const enableCallButtons = dailyState ? getDisplayConfig(dailyState.connectionState).enableCallButtons : false;
  const dailyCall = dailyState?.call?.call;
  const recordingInfo = dailyState?.recordingInfo;

  function toggleCamera() {
    dailyCall?.setLocalVideo(isCameraMuted);
  }

  function toggleMic() {
    dailyCall?.setLocalAudio(isMicMuted);
  }

  function toggleSharingScreen() {
    isSharingScreen ? dailyCall?.stopScreenShare() : dailyCall?.startScreenShare();
  }

  function toggleRecording() {
    if (recordingInfo?.isRecording) {
      dailyCall?.stopRecording();
    } else {
      setStartingRecording(true);
      dailyCall?.startRecording();
    }
  }

  useEffect(() => {
    if (isStartingRecording && recordingInfo?.isRecording) {
      setStartingRecording(false);
    }
  }, [isStartingRecording, recordingInfo?.isRecording]);
  /**
   * Start listening for participant changes when callObject is set (i.e. when the component mounts).
   * This event will capture any changes to your audio/video mute state.
   */
  useEffect(() => {
    if (!dailyCall) return;

    function handleNewParticipantsState(event?: DailyEventObjectParticipant) {
      event && logDailyEvent(event);
      const { isCameraMuted, isMicMuted, isSharingScreen } = getStreamStates(dailyCall);
      setCameraMuted(isCameraMuted);
      setMicMuted(isMicMuted);
      setSharingScreen(isSharingScreen);
    }

    // Use initial state
    handleNewParticipantsState();

    // Listen for changes in state
    dailyCall.on('participant-updated', handleNewParticipantsState);

    // Stop listening for changes in state
    return () => {
      dailyCall.off('participant-updated', handleNewParticipantsState);
    };
  }, [dailyCall]);

  return (
    <div
      className={classNames(
        'flex items-center justify-between w-full h-full md:px-4 group',
        !isLiveMode && 'pointer-events-none',
        contentType !== 'call' && 'flex-row-reverse'
      )}
    >
      {/* Left buttons */}
      {contentType === 'call' && (
        <div className="flex h-full">
          {isLiveMode ? (
            <MicTrayButton enabled={enableCallButtons} isMicMuted={isMicMuted} onToggle={toggleMic} />
          ) : (
            <TrayButton enabled={enableCallButtons} turnedOff={isMicMuted} onClick={toggleMic} label="Mic">
              <IconSvg name={isMicMuted ? 'micOff' : 'mic'} className="w-6 h-6" />
            </TrayButton>
          )}
          <TrayButton enabled={enableCallButtons} turnedOff={isCameraMuted} onClick={toggleCamera} label="Camera">
            <IconSvg name={isCameraMuted ? 'cameraOff' : 'camera'} className="w-6 h-6" />
          </TrayButton>

          {DailyIframe.supportedBrowser().supportsScreenShare && (
            <TrayButton
              enabled={isMobileXs ? false : enableCallButtons}
              open={isSharingScreen}
              onClick={toggleSharingScreen}
              label="Share"
            >
              <IconSvg name="share" className="w-6 h-6" />
            </TrayButton>
          )}
          {ableToRecord && (
            <TrayButton
              onClick={toggleRecording}
              enabled={enableCallButtons}
              size="wide"
              label={isStartingRecording ? 'Starting' : recordingInfo?.isRecording ? 'Stop' : 'Record'}
            >
              {isStartingRecording ? (
                <Loading size="small"></Loading>
              ) : recordingInfo?.isRecording ? (
                <IconSvg name="stopRecording" className="flex items-center justify-center w-6 h-6" />
              ) : (
                <IconSvg name="startRecording" className="flex items-center justify-center w-6 h-6" />
              )}
            </TrayButton>
          )}
        </div>
      )}

      {/* Right buttons */}
      <div className="flex h-full">
        <TrayButton enabled={true} onClick={() => venueDispatch({ type: 'toggle-chat-open' })} label="Chat">
          <div className="relative">
            <IconSvg name="chat" className="w-6 h-6" />
            {unseenChatMessages > 0 && (
              <div className=" absolute -left-2 -top-1 inline-flex items-center justify-center px-1.5 py-0.5 rounded-full text-xxs font-bold bg-red-600 text-white">
                {unseenChatMessages}
              </div>
            )}
          </div>
        </TrayButton>

        {call && (
          <TrayButton
            enabled={enableCallButtons}
            onClick={() => {
              if (recordingInfo?.isRecording && ableToRecord) {
                setShowConfirmLeaveCall(true);
              } else {
                callConfigDispatch && callConfigDispatch({ type: 'set-desired-join-state', joinState: 'left' });
              }
            }}
            label="Leave"
          >
            <IconSvg name="leave" className="w-6 h-6" />
          </TrayButton>
        )}

        <TraySettingsMenu />
      </div>
      <Confirm
        open={showConfirmLeaveCall}
        title="Leave Call?"
        text="There is an ongoing recording, leaving the call will end the recording automatically when the last participant leaves the venue. Are you sure?"
        onConfirm={() => {
          callConfigDispatch && callConfigDispatch({ type: 'set-desired-join-state', joinState: 'left' });
          setShowConfirmLeaveCall(false);
        }}
        yesLabel="Leave Call"
        noLabel="Stay in Call"
        onCancel={() => setShowConfirmLeaveCall(false)}
      />
      <RequestUnmuteDialog isMicMuted={isMicMuted} toggleMic={toggleMic} />
    </div>
  );
};

export default Tray;
