import { useVirtualVenue } from 'components/VirtualVenue/contexts/VirtualVenueContext';
import CustomVirtualVenueContent from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCustomDisplay/CustomVirtualVenueContent/CustomVirtualVenueContent';
import React from 'react';
import VirtualVenueDisplayLayout from 'components/VirtualVenue/VirtualVenueDisplay/layouts/VirtualVenueDisplayLayout/VirtualVenueDisplayLayout';

const VirtualVenueCustomDisplay = () => {
  const venue = useVirtualVenue();
  const nextVenueEvent = venue.events.length ? venue.events[0] : undefined;

  return (
    <VirtualVenueDisplayLayout>
      <CustomVirtualVenueContent event={nextVenueEvent} />
    </VirtualVenueDisplayLayout>
  );
};

export default VirtualVenueCustomDisplay;
