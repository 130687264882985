import React from 'react';
import classNames from 'classnames';
import styles from './Input.module.scss';

export type InputSize = 'small' | 'responsive' | 'normal';

export interface Props {
  // Builtin
  id?: string;
  value?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  onChange?: (event: any) => void;
  onKeyDown?: (event: any) => void;
  onFocus?: (event: any) => void;
  onBlur?: (event: any) => void;
  onKeyPress?: (event: any) => void;
  type?: 'text' | 'number' | 'numeric';
  placeholder?: string;
  className?: string;
  inputRef?: React.MutableRefObject<HTMLInputElement>;

  // Custom
  size?: InputSize;
}

const Input: React.FC<Props> = (props) => {
  const { size = 'normal', className, inputRef, type, ...builtin } = props;
  const cls = classNames(styles.Input, className, {
    [styles.small]: size === 'small',
    [styles.responsive]: size === 'responsive',
  });
  return <input className={cls} type={type ?? 'text'} ref={inputRef} {...builtin} />;
};

export default Input;
