import * as m from 'models';
import { FailureResult, GenericError, SuccessResult } from 'api/utils';
import { ME_QUERY } from 'utils/gql';
import { MeQuery } from 'generated/MeQuery';
import { analyticsAPI as analytics } from 'utils/api';
import apolloClient from 'api/apolloClient';

export type UserResult = m.Unauthenticated | m.Person;

interface QueryUserSuccess extends SuccessResult<UserResult> {}

export type QueryUserResult = QueryUserSuccess | FailureResult;

const queryUser = async (): Promise<QueryUserResult> => {
  const { data, errors } = await apolloClient.query<MeQuery>({
    query: ME_QUERY,
    fetchPolicy: 'network-only',
  });
  if (errors) {
    return Promise.resolve(GenericError);
  }
  if (!data.me) {
    return {
      ok: true,
      data: m.Unauthenticated,
    };
  } else {
    const user = m.parsePerson(data.me);
    analytics.identify(user.id, {
      name: user.name,
      email: user.getPrimaryEmail(),
    });
    return {
      ok: true,
      data: user,
    };
  }
};

export default queryUser;
