import useGlobalState from 'components/App/globalstate/useGlobalState';

const useAssertEventId = () => {
  const { eventId } = useGlobalState();
  if (!eventId) {
    throw new Error('useAssertEventId used with no event active');
  }
  return eventId;
};

export default useAssertEventId;
