import React from 'react';
import classNames from 'classnames';

interface Props {
  size?: 'small' | 'medium' | 'large';
  className?: string;
}

const SiteHeader: React.FC<Props> = ({ children, size = 'large', className }) => {
  return (
    <div
      className={classNames(
        {
          'text-3xl mt-12': size === 'large',
          'text-2xl mt-6': size === 'medium',
          'text-lg mt-6': size === 'small',
        },
        className
      )}
    >
      {children}
    </div>
  );
};

export default SiteHeader;
