import AsyncSearch from 'components/common/AsyncSearch';
import React from 'react';
import Search, { SearchProps } from 'components/common/Search';

interface Props extends Partial<SearchProps> {
  getItems: (value?: string) => Promise<any>;
  transformData?: (data: any) => any;
  onChangeValue?: (value: string) => any;
}

const AsyncSearchInput = ({ getItems, onChangeValue, ...rest }: Props) => (
  <AsyncSearch getItems={getItems}>
    {({ data, onChange, value }) => (
      <Search
        items={rest.transformData ? rest.transformData(data) : data}
        onChange={(value: string) => {
          onChange(value);
          if (onChangeValue) {
            onChangeValue(value);
          }
        }}
        value={value}
        showResults={({ isOpen, isHover, value }) => (isOpen || isHover) && !!value.length}
        {...rest}
        resultProps={{
          ...rest.resultProps,
        }}
        onSelect={(data) => {
          if (rest.onSelect && (rest.onSelect(data) as any)) {
            onChange('');
          }
        }}
        onSubmit={(data) => {
          if (rest.onSubmit && (rest.onSubmit(data) as any)) {
            onChange('');
          }
        }}
      />
    )}
  </AsyncSearch>
);

export default AsyncSearchInput;
