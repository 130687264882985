import React from 'react';
import classNames from 'classnames';
import linkifyHtml from 'linkify-html';

import styles from './Description.module.scss';

interface Props {
  description?: string | null;
  showLabel?: boolean;
  className?: string;
  bodyClassName?: string;
}

const renderDescription = (html: string) => (
  <div
    dangerouslySetInnerHTML={{
      __html: linkifyHtml(html, {
        target: (href: string, type: string) => type === 'url' && '_blank',
      }),
    }}
  />
);

const Description: React.FC<Props> = ({ description, showLabel = true, className, bodyClassName }) => {
  if (!description) {
    return null;
  }
  const descriptionStr = renderDescription(description);
  const cls = classNames(styles.Description, className);
  return (
    <div className={cls}>
      {showLabel && <div className={styles.heading}>Details</div>}
      <div className={classNames(styles.body, bodyClassName)}>{descriptionStr}</div>
    </div>
  );
};

export default Description;
