import { Channel } from 'pusher-js';
import { getPusher } from 'utils/api';
import { useEffect } from 'react';

function usePusherSubscribe(channelName: string | undefined, callback: (channel: Channel) => void) {
  useEffect(() => {
    if (!channelName) {
      return;
    }
    const pusher = getPusher();
    const channel = pusher.subscribe(channelName);
    callback(channel);
    return () => channel.pusher.unsubscribe(channel.name);
  }, [channelName, callback]);
}

export default usePusherSubscribe;
