import { UnbindListeners, withFileDrop } from 'utils/helpers';
import { unsplashAPI } from 'utils/api';
import GalleryTab from 'components/EventCreateUpdateForm/ImagePicker/GalleryTab';
import GiphyTab from 'components/EventCreateUpdateForm/ImagePicker/GiphyTab';
import IPModal from 'components/EventCreateUpdateForm/ImagePicker/IPModal';
import LinkTab from 'components/EventCreateUpdateForm/ImagePicker/LinkTab';
import MyEventsTab from 'components/EventCreateUpdateForm/ImagePicker/MyEventsTab';
import React from 'react';
import Tab, { PaneItem } from 'components/common/Tab/Tab';
import UnsplashTab from 'components/EventCreateUpdateForm/ImagePicker/UnsplashTab';
import UploadTab from 'components/EventCreateUpdateForm/ImagePicker/UploadTab';
import styles from 'components/EventCreateUpdateForm/ImagePicker/ImagePicker.module.scss';

type TabName = 'gallery' | 'upload' | 'link' | 'events' | 'unsplash' | 'giphy';
type TabWithNoText = 'gallery' | 'unsplash' | 'giphy';
type Config = Record<TabName, boolean>;
type HelpText = Omit<Record<TabName, string>, TabWithNoText>;
type ConfigPartial = Partial<Config>;
type HelpTextPartial = Partial<HelpText>;

export interface Props {
  onRemove?: () => void;
  onSelect: (imageUrl: string, data?: object) => void;
  onUpload?: (file: File) => void;
  isUploading?: boolean;
  defaultSearchValue?: string;
  config?: ConfigPartial;
  helpText?: HelpTextPartial;
}

interface State {
  dragging: boolean;
  searchValue?: string;
}

const defaultConfig: Config = {
  gallery: true,
  upload: true,
  link: true,
  events: true,
  unsplash: true,
  giphy: false,
};

const defaultHelpText: HelpText = {
  upload: 'Upload an image from your computer',
  link: 'Works with any image from the web',
  events: 'Images from events you create will show up here.',
};

const initialState: State = {
  dragging: false,
  searchValue: '',
};

class ImagePicker extends React.Component<Props, State> {
  private fileInput = React.createRef<HTMLInputElement>();

  private removeListeners?: UnbindListeners;

  constructor(props: Props) {
    super(props);
    this.state = {
      ...initialState,
      searchValue: props.defaultSearchValue,
    };
  }

  UNSAFE_componentWillMount() {
    this.removeListeners = withFileDrop(document.body, {
      onDrag: () => this.setState({ dragging: true }),
      onDragEnd: () => this.setState({ dragging: false }),
      onDrop: (e: any) => this.onUpload(e.dataTransfer.files[0]),
    });
  }

  componentWillUnmount() {
    this.removeListeners && this.removeListeners();
  }

  private readonly openFileDialog = () => {
    this.fileInput.current!.dispatchEvent(new MouseEvent('click'));
  };

  private readonly handleUploadFiles = (e: any) => {
    if (e.target.files) {
      this.onUpload(e.target.files[0]);
    }
  };

  private readonly onUpload = (file: any) => {
    if (file) {
      const { onUpload } = this.props;
      onUpload && onUpload(file);
    }
  };

  private readonly onSelect = (source: string) => (imageUrl: string, data?: any) => {
    if (source === 'unsplash') {
      const image = data || {};
      unsplashAPI.trackDownload(image.downloadLocation);
    }

    const { onSelect } = this.props;
    onSelect(imageUrl, { source, data });
  };

  render() {
    const { onRemove, isUploading } = this.props;
    const { dragging, searchValue } = this.state;
    const config = this.props.config || defaultConfig;
    const helpText = { ...defaultHelpText, ...this.props.helpText };

    const panes: PaneItem[] = [];
    if (config.unsplash) {
      panes.push({
        title: 'Unsplash',
        content: () => (
          <UnsplashTab
            searchValue={searchValue}
            setSearchValue={(val: string) => this.setState({ searchValue: val })}
            onSelect={this.onSelect('unsplash')}
          />
        ),
      });
    }
    if (config.giphy) {
      panes.push({
        title: 'Giphy',
        content: () => (
          <GiphyTab
            searchValue={searchValue}
            setSearchValue={(val: string) => this.setState({ searchValue: val })}
            onSelect={this.onSelect('giphy')}
          />
        ),
      });
    }
    if (config.gallery) {
      panes.push({
        title: 'Gallery',
        content: () => <GalleryTab onSelect={this.onSelect('gallery')} />,
      });
    }
    if (config.upload) {
      panes.push({
        title: 'Upload',
        content: () => (
          <UploadTab
            dragging={dragging}
            handleClick={this.openFileDialog}
            helpText={helpText.upload}
            isUploading={isUploading}
          />
        ),
      });
    }
    if (config.link) {
      panes.push({
        title: 'Link',
        content: () => <LinkTab onSelect={this.onSelect('link')} helpText={helpText.link} />,
      });
    }
    if (config.events) {
      panes.push({
        title: 'My Events',
        content: () => <MyEventsTab onSelect={this.onSelect('events')} helpText={helpText.events} />,
      });
    }
    if (onRemove) {
      panes.push({
        title: 'Remove',
        content: () => <React.Fragment />,
      });
    }
    return (
      <div className={styles.ImagePicker}>
        <input
          type="file"
          accept="image/*"
          ref={this.fileInput}
          style={{ display: 'none' }}
          onChange={this.handleUploadFiles}
        />

        <Tab
          menu={{
            className: onRemove && styles.alignLastTabRight,
          }}
          activeIndex={dragging && config.upload ? panes.findIndex((pane) => pane.title === 'Upload') : undefined}
          onSelect={(tabIndex: number) => {
            // NOTE(nick): the Remove tab acts like a button
            const pane = panes[tabIndex];
            if (pane.title === 'Remove') {
              onRemove && onRemove();
            }
          }}
          panes={panes}
        />
      </div>
    );
  }
}

export default ImagePicker;
export { IPModal as ImagePickerModal };
