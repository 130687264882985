import { GOOGLE_AUTH } from 'components/auth-forms/utils';
import { Icon } from 'components/common';
import React from 'react';
import ReactGoogleLogin from 'react-google-login';
import styles from 'components/auth-forms/GoogleButton.module.scss';
export interface GoogleAuthProps {
  label: string | JSX.Element;
  loginHint?: string;
  onSuccess: (idToken: string) => void;
}

const GoogleAuthButton = (props: GoogleAuthProps) => {
  const config = GOOGLE_AUTH(props);
  return (
    <ReactGoogleLogin
      clientId={config.clientId}
      render={(renderProps) => (
        <button onClick={renderProps && renderProps.onClick} className={styles.GoogleButton}>
          <Icon className={styles.GoogleButtonLogo} icon="google" />
          <span className={styles.GoogleButtonLabel}>{props.label}</span>
        </button>
      )}
      buttonText={props.label as any}
      onSuccess={config.onSuccess}
      onFailure={config.onFailure}
      cookiePolicy={config.cookiePolicy}
      loginHint={props.loginHint}
      accessType={config.accessType}
      responseType={config.responseType}
      prompt={config.prompt}
      scope={config.scope}
    />
  );
};

export default GoogleAuthButton;
