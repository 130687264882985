import { Currency } from 'shared/shared/types';
import { DiscountCode, TicketTypeForPurchase } from 'models';
import { TicketsValue } from 'components/pages/EventReadPage/RsvpForm/TicketingForm/InnerTicketingForm/TicketsWidget/TicketsWidget';
import { calculateTotalDiscount } from 'utils/helpers';
import { isNumber } from 'util';
import { renderDiscountCode, renderDollars } from 'shared/shared/utils';
import React from 'react';

interface Props {
  currency: Currency;
  discountCode?: DiscountCode;
  ticketTypes: TicketTypeForPurchase[];
  tickets: TicketsValue;
}

function getTicketType(id: string, ticketTypes: TicketTypeForPurchase[]): TicketTypeForPurchase | undefined {
  return ticketTypes.find((ticketType) => ticketType.id === id);
}

const Container: React.FC<{}> = (props) => {
  return (
    <>
      <h2>Order summary</h2>
      {props.children}
    </>
  );
};

const OrderSummary: React.FC<Props> = ({ currency, tickets, ticketTypes, discountCode }) => {
  const hasTickets = tickets.some((t) => t.quantity > 0);

  if (!hasTickets) {
    return (
      <Container>
        <p className="text-sm">No tickets selected</p>
      </Container>
    );
  }

  const hasPrices = tickets.every((ticket) => {
    if (!isNumber(ticket.price)) {
      return ticket.quantity === 0;
    }
    return true;
  });
  if (!hasPrices) {
    return (
      <Container>
        <div>Please enter contribution amount</div>
      </Container>
    );
  }

  const total = tickets.reduce((acc, ticket) => acc + ticket.price! * ticket.quantity, 0);
  const discount = calculateTotalDiscount(tickets, discountCode);
  const effectiveDiscount = Math.min(discount, total);
  const totalAfterDiscount = Math.max(total - effectiveDiscount, 0);

  return (
    <>
      <h2>Order summary</h2>

      {tickets.map((ticket, index) => {
        if (!ticket.quantity) {
          return null;
        }
        const ticketType = getTicketType(ticket.id, ticketTypes);
        return (
          <div key={`${ticket.id || index}`} className="flex flex-row mt-3 text-sm">
            <div className="flex-grow mr-3">
              <span>{ticket.quantity} &times; </span>
              <span>{ticketType?.name || 'Ticket'}</span>
            </div>
            <div>{renderDollars(ticket.price!, currency)}</div>
          </div>
        );
      })}
      {discountCode && (
        <>
          <div className="flex flex-row mt-6 mb-2 text-sm">
            <div className="flex-grow mr-3">Subtotal</div>
            <div>{renderDollars(total, currency)}</div>
          </div>
          <div className="flex flex-row my-2 text-sm">
            <div className="flex-grow mr-3">Discount ({renderDiscountCode(discountCode)})</div>
            <div>-{renderDollars(effectiveDiscount, currency)}</div>
          </div>
        </>
      )}
      <div className="flex flex-row mt-6 text-sm">
        <h3 className="flex-grow mr-3">Total</h3>
        <h3>{renderDollars(totalAfterDiscount, currency)}</h3>
      </div>
    </>
  );
};

export default OrderSummary;
