import { addSnackbarMessage } from 'utils/eventEmitter';
import { colorWithAlpha } from 'utils/colors';
import { useVirtualVenueTheme } from 'components/VirtualVenue/contexts/VirtualVenueThemeContext';
import CopyToClipboard from 'react-copy-to-clipboard';
import React, { useState } from 'react';
import classNames from 'classnames';

const InviteTile = () => {
  const theme = useVirtualVenueTheme();
  const [width, setWidth] = useState<number>(400);

  return (
    <CopyToClipboard text={window.location.href} onCopy={() => addSnackbarMessage('Copied!', 'success')}>
      <div
        className="w-full h-full p-3 text-center text-white border border-solid cursor-pointer"
        style={{
          backgroundColor: colorWithAlpha(theme.titleTextColor, 0.25),
          borderColor: colorWithAlpha(theme.titleTextColor, 0.35),
        }}
        ref={(el) => {
          if (!el) {
            return;
          }
          setWidth(el.getBoundingClientRect().width);
        }}
      >
        <div className="flex flex-col justify-center w-full h-full align-middle">
          <div
            className={classNames('text-xxs sm:text-xs md:text-lg lg:text-xl xl:text-2xl', {
              hidden: width < 200,
            })}
          >
            Share this page to invite guests
          </div>
          <div className="text-xs md:text-sm lg:text-base xl:text-lg">
            Click to copy {width < 200 ? 'invite ' : ''}URL
          </div>
        </div>
      </div>
    </CopyToClipboard>
  );
};

export default InviteTile;
