import { Radio, RadioProps } from '@material-ui/core';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import RadioButtonIcon from './RadioButtonIcon';
import React from 'react';

interface Props extends RadioProps {
  outlined?: boolean;
  colorValue: string;
}

const theme = createTheme({
  overrides: {
    MuiRadio: {
      root: {
        paddingLeft: 4,
        paddingRight: 4,
        paddingTop: 3,
        paddingBottom: 3,
      },
    },
  },
});

function ColorRadioIcon({ colorValue, color, ...restProps }: Props) {
  return (
    <ThemeProvider theme={theme}>
      <Radio
        {...restProps}
        disableRipple={true}
        checkedIcon={<RadioButtonIcon checked colorValue={colorValue} />}
        icon={<RadioButtonIcon checked colorValue={colorValue} />}
      />
    </ThemeProvider>
  );
}

export default ColorRadioIcon;
