import * as m from 'models';
import { Invite, getEmail } from 'models/invite';
import { useForm } from 'react-hook-form';
import DisableInPreview from 'components/DisableInPreview/DisableInPreview';
import Input2 from 'components/common/Input2/Input2';
import LabelWithInput from 'components/common/LabelWithInput/LabelWithInput';
import React, { useEffect } from 'react';
import useFormHelpers from 'components/forms/useFormHelpers';
import useUser from 'utils/hooks/user/useUser';

type FormT = {
  email: string;
};

interface Props {
  handleSubmit: (formResult: FormT) => void;
  invite?: Invite;
  pending: boolean;
}

const getDefaultValues = (user: m.User, invite?: Invite): FormT => {
  const usingInvite = !m.isAuthenticated(user) && getEmail(invite);
  const authEmail = m.isAuthenticated(user) && user.getPrimaryEmail();
  return {
    email: usingInvite ? unmaskedEmail(invite) : authEmail || '',
  };
};

// Note: avoid flash of masked email before rendering full email
const unmaskedEmail = (invite: Invite | undefined): string => {
  const email = getEmail(invite);
  return !email || email.includes('...') ? '' : email;
};

const FollowFormInner = (props: Props) => {
  const { invite, handleSubmit, pending } = props;
  const { user } = useUser();
  const formContext = useForm<FormT>({
    defaultValues: getDefaultValues(user),
  });
  const { Form, SubmitButton, FormLevelMessages, FormStateContextProvider } = useFormHelpers();
  const { setValue, register } = formContext;

  const submitButtonText = invite?.isFollowing ? 'Unfollow' : 'Follow';

  useEffect(() => {
    if (invite) {
      setValue('email', unmaskedEmail(invite));
    } else if (m.isAuthenticated(user)) {
      setValue('email', user.getPrimaryEmail());
    }
  }, [invite, setValue, user]);

  return (
    <FormStateContextProvider onSubmit={handleSubmit} formContext={formContext}>
      <Form className="mt-4">
        <LabelWithInput label={invite?.isFollowing ? "You're following this event as" : ''}>
          <Input2
            name="email"
            type="email"
            ref={register({ required: true })}
            disabled={(pending || m.isAuthenticated(user) || invite?.isFollowing) ?? false}
            placeholder="you@example.com"
          />
        </LabelWithInput>
        <FormLevelMessages className="mt-4" />
        <DisableInPreview>
          <div className="mt-4">
            <SubmitButton label={submitButtonText} className="w-full sm:w-full" />
          </div>
        </DisableInPreview>
      </Form>
    </FormStateContextProvider>
  );
};

export default FollowFormInner;
