import { CreateEventRSVP } from 'generated/CreateEventRSVP';
import {
  EVENT_RSVP_CREATE_MUTATION,
  EVENT_RSVP_UPDATE_MUTATION,
  EVENT_WITH_EXIST_QUERY,
  INVITE_SEND_MAGIC_LINK_MUTATION,
} from 'utils/gql';
import { EventWithExistQuery } from 'generated/EventWithExistQuery';
import { SendMagicLink, SendMagicLinkVariables } from 'generated/SendMagicLink';
import { UpdateEventRSVP } from 'generated/UpdateEventRSVP';
import { getURLParams } from 'utils/urls';
import { useMutation } from '@apollo/client';

import { isEventAtCapacity } from 'shared/shared/utils';
import DoRsvpForm from 'components/pages/EventReadPage/RsvpForm/DoRsvpForm';
import FollowForm from 'components/pages/EventReadPage/RsvpForm/FollowForm';
import React from 'react';
import TicketingForm from 'components/pages/EventReadPage/RsvpForm/TicketingForm/TicketingForm';
import useEventClass from 'utils/hooks/event/useEventClass';
import useEventInviteClass from 'utils/hooks/event/useEventInviteClass';
import useInviteTokenClass from 'utils/hooks/useInviteTokenClass';
import useUser from 'utils/hooks/user/useUser';

const RsvpForm = () => {
  const { inviteToken } = useInviteTokenClass();
  const { invite } = useEventInviteClass();
  const { event } = useEventClass();
  const { user } = useUser();
  const eventId = event.id;

  const mutation = invite?.id ? EVENT_RSVP_UPDATE_MUTATION : EVENT_RSVP_CREATE_MUTATION;
  const [doMutation] = useMutation<CreateEventRSVP | UpdateEventRSVP>(mutation, {
    update: (cache, { data }) => {
      const { invite } = ((data as CreateEventRSVP).eventRsvpCreate || (data as UpdateEventRSVP).eventRsvpUpdate)!;
      const event = eventId
        ? cache.readQuery<EventWithExistQuery>({ query: EVENT_WITH_EXIST_QUERY, variables: { id: eventId } })?.event
        : undefined;

      if (invite && event) {
        const updatedEvent = { ...event, myInvite: invite };
        cache.writeQuery({
          query: EVENT_WITH_EXIST_QUERY,
          data: { event: updatedEvent, eventExists: true },
        });
      }
    },
  });

  const [sendMagicLink] = useMutation<SendMagicLink, SendMagicLinkVariables>(INVITE_SEND_MAGIC_LINK_MUTATION);

  const defaultRsvp = getURLParams(window.location.search)?.rsvp;

  if (event.ticketTypes) {
    return <TicketingForm isRun={event.isRunTemplate} />;
  } else if (!event.startTime) {
    return (
      <FollowForm
        sendMagicLink={sendMagicLink}
        doMutation={doMutation}
        event={event}
        defaultRsvp={defaultRsvp}
        inviteToken={inviteToken}
        invite={invite}
        user={user}
      />
    );
  } else if (isEventAtCapacity(event.maxCapacity, event.confirmedGuests!)) {
    return (
      <div className="p-6 sm:p-8">
        <div className="text-center">Sorry, this event is at capacity and is no longer accepting responses</div>
      </div>
    );
  } else {
    return (
      <DoRsvpForm
        sendMagicLink={sendMagicLink}
        doMutation={doMutation}
        event={event}
        defaultRsvp={defaultRsvp}
        inviteToken={inviteToken}
        invite={invite}
        user={user}
      />
    );
  }
};

export default RsvpForm;
