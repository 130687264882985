import { getRandomInt } from 'shared/shared/utils';
import { useCallConfig } from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/contexts/CallConfigContext';
import { useVirtualVenue } from 'components/VirtualVenue/contexts/VirtualVenueContext';
import {
  useVirtualVenueDispatch,
  useVirtualVenueState,
} from 'components/VirtualVenue/contexts/VirtualVenueStateContext';
import IconSvg from 'components/common/IconSvg/IconSvg';
import React, { useState } from 'react';
import TileMenu from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/CallDisplay/TileDisplay/TileMenu/TileMenu';
import classNames from 'classnames';
import styles from '../DailyParticipantTile/DailyParticipantTile.module.scss';
import useTimeout from 'utils/hooks/useTimeout';

interface Props {
  videoSrc: string;
  name?: string;
  onClick?: () => void;
  id: string;
}

const DemoParticipantTile = (props: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const venue = useVirtualVenue();
  const bgColor = venue?.theme?.bgColor || 'black';

  const dispatch = useVirtualVenueDispatch();
  const { pinnedParticipant } = useVirtualVenueState();
  const { videoSrc, onClick, name: displayName, id } = props;
  const { alwaysShowNames } = useCallConfig();

  const isPinned = pinnedParticipant && pinnedParticipant === id;
  useTimeout(() => {
    setIsLoading(false);
  }, getRandomInt(50, 1500));

  return (
    <div
      className={classNames(styles.CallTile, 'group relative h-full w-full', {
        [styles.ShowNameOnHover]: !alwaysShowNames,
      })}
      onClick={onClick}
      style={{
        backgroundColor: bgColor,
      }}
    >
      {!isLoading ? (
        <>
          <video
            autoPlay
            muted
            loop
            src={videoSrc}
            playsInline
            className={classNames('w-full h-full object-cover object-top')}
          />
          <div className="absolute bottom-0 right-0 w-6 h-6 bg-gray-600 bg-opacity-50">
            <IconSvg name="micOff" className="h-5 w-5 mt-0.5 ml-0.5 text-white opacity-80" />
          </div>
        </>
      ) : (
        <div
          className="flex items-center justify-center w-full h-full opacity-50"
          style={{
            background: `linear-gradient(135deg, var(--v-theme-bg-color-dark) 0%, var(--v-theme-bg-color-light) 50%, var(--v-theme-bg-color-dark) 100%)`,
            boxShadow: 'inset 0px 0px 75px 0px rgba(0,0,0,0.2)',
          }}
        >
          <p className="text-sm leading-none text-white">Loading...</p>
        </div>
      )}

      {displayName && (
        <div
          className={classNames(
            styles.Name,
            'absolute bottom-0 left-0 bg-transparent transition-opacity duration-150 ease-out'
          )}
          style={{ maxWidth: '85%' }}
        >
          <div className="text-white overflow-ellipsis whitespace-nowrap overflow-hidden bg-gray-600 bg-opacity-60 px-1 py-0.5 text-sm opacity-90">
            {displayName}
          </div>
        </div>
      )}
      <TileMenu
        isLocal={false}
        showOnHoverClassName={'opacity-0 group-hover:opacity-100'}
        participantId={id}
        isMuted={true}
      ></TileMenu>
      {isPinned && (
        <div
          onClick={() => {
            dispatch({ type: 'unpin-participant' });
          }}
          className={classNames(
            styles.UnpinContainer,
            'absolute top-0 left-0 bg-gray-600 bg-opacity-50 opacity-50 hover:opacity-80 cursor-pointer group'
          )}
        >
          <div className={classNames('w-6 h-6 flex items-center justify-center', styles.PinnedIcon)}>
            <IconSvg name="lockClosed" className="w-5 h-5 " />
          </div>
          <div className={classNames('w-6 h-6 flex items-center justify-center', styles.UnpinIcon)}>
            <IconSvg name="lockOpen" className="w-5 h-5" />
          </div>
        </div>
      )}
    </div>
  );
};

export default DemoParticipantTile;
