import { DailyCall } from '@daily-co/daily-js';

export const DAILY_CONNECTION_STATES = {
  STATE_IDLE: 'STATE_IDLE',
  STATE_CREATING: 'STATE_CREATING',
  STATE_JOINING: 'STATE_JOINING',
  STATE_REJOINING: 'STATE_REJOINING',
  STATE_JOINED: 'STATE_JOINED',
  STATE_LEAVING: 'STATE_LEAVING',
  STATE_ERROR: 'STATE_ERROR',
} as const;

export type DailyConnectionState = typeof DAILY_CONNECTION_STATES[keyof typeof DAILY_CONNECTION_STATES];

export const DAILY_RECORDING_STATES = {
  RECORDING_IDLE: 'RECORDING_IDLE',
  RECORDING_STARTED: 'RECORDING_STARTED',
  RECORDING_STOPPED: 'RECORDING_STOPPED',
  RECORDING_ERROR: 'RECORDING_ERROR',
};

export const RECORDING_ACTION_MESSAGE_MAP: { [action: string]: string } = {
  'recording-started': DAILY_RECORDING_STATES.RECORDING_STARTED,
  'recording-stopped': DAILY_RECORDING_STATES.RECORDING_STOPPED,
  'recording-error': DAILY_RECORDING_STATES.RECORDING_ERROR,
};

export type DailyRecordingState = typeof DAILY_RECORDING_STATES[keyof typeof DAILY_RECORDING_STATES];

type DailyMetaState = {
  connectionState: DailyConnectionState;
  call?: {
    call: DailyCall;
    roomUrl: string;
  };
};

type Track = MediaStreamTrack | null | undefined;

export type DailyCallItem = {
  id: string | null;
  isLoading: boolean;
  audioTrack: Track;
  videoTrack: Track;
};

// N.B. string key format is described in Daily's comment below
export type DailyCallItems = {
  local: DailyCallItem;
  [id: string]: DailyCallItem;
};

export type DailyRecordingInfo = {
  recordingState: DailyRecordingState;
  isRecording: boolean;
  activeRecordingId: string | null;
};

/**
 * Call state is comprised of:
 * - "Call items" (inputs to the call, i.e. participants or shared screens)
 * - UI state that depends on call items (for now, just whether to show "click allow" message)
 *
 * Call items are keyed by id:
 * - "local" for the current participant
 * - A session id for each remote participant
 * - "<id>-screen" for each shared screen
 */
type CallState = {
  callItems: DailyCallItems;
  camOrMicError: string | null;
  fatalError: string | null;
  activeSpeakerId: string | null;
  recordingInfo: DailyRecordingInfo;
};

export type DailyState = DailyMetaState & CallState;

export const initialDailyState = {
  connectionState: DAILY_CONNECTION_STATES.STATE_IDLE,
  recordingInfo: {
    recordingState: DAILY_RECORDING_STATES.RECORDING_IDLE,
    isRecording: false,
    activeRecordingId: null,
  },

  callItems: {
    local: {
      id: null,
      isLoading: true,
      audioTrack: null,
      videoTrack: null,
    },
  },
  camOrMicError: null,
  fatalError: null,
  activeSpeakerId: null,
} as const;
