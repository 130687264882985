import { Loading } from 'components/common';
import {
  MediaDeviceConfig,
  useDeviceConfigDispatch,
} from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/contexts/DeviceConfigContext';
import { useCallConfigDispatch } from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/contexts/CallConfigContext';
import Button2 from 'components/common/Button2/Button2';
import React, { useEffect, useMemo, useReducer } from 'react';
import useTimeout from 'utils/hooks/useTimeout';
import useUserMedia from 'utils/hooks/useUserMedia';

interface Props {
  mediaDevices: MediaDeviceConfig | null | undefined;
}

const AcquireDevicePermisssions = ({ mediaDevices }: Props) => {
  const [timedOut, setTimedOut] = useReducer(() => true, false);
  const deviceConfigDispatch = useDeviceConfigDispatch();
  const callConfigDispatch = useCallConfigDispatch();
  const { audioinput, videoinput } = mediaDevices ?? {};

  const mediaConfig = useMemo(
    () => ({
      audio: audioinput ? { deviceId: audioinput } : true,
      video: videoinput ? { deviceId: videoinput } : true,
    }),
    [audioinput, videoinput]
  );
  const { error, stream } = useUserMedia(mediaConfig);
  useTimeout(setTimedOut, 10_000);

  useEffect(() => {
    // Notify browser state if we got access...
    if (stream) {
      deviceConfigDispatch({ type: 'set-access-granted', accessGranted: true });
      deviceConfigDispatch({
        type: 'set-media-stream',
        stream: {
          audioinput: stream.getAudioTracks()?.[0].label,
          videoinput: stream.getVideoTracks()?.[0].label,
        },
      });
      // ...Or were denied permission
    } else if (error) {
      deviceConfigDispatch({ type: 'set-access-granted', accessGranted: false });
    }
  }, [deviceConfigDispatch, stream, error]);

  return (
    <div>
      {timedOut && !stream ? (
        <div>
          <h3>Please click "Allow" to enable camera and mic access...</h3>
          <Loading />
          <div className="mx-auto mt-4">
            <Button2
              onClick={() => {
                callConfigDispatch({ type: 'set-desired-join-state', joinState: 'idle' });
              }}
              color="secondary"
            >
              Back
            </Button2>
          </div>
        </div>
      ) : (
        <div>
          <h3>&nbsp;</h3>
          <Loading />
        </div>
      )}
    </div>
  );
};
export default AcquireDevicePermisssions;
