import * as m from 'models';
import { Button, Loading } from 'components/common';
import { GOOGLE_AUTH } from 'components/auth-forms/utils';
import { GOOGLE_AUTH_AUTHORIZE } from 'utils/gql';
import { GoogleAuthAuthorize, GoogleAuthAuthorizeVariables } from 'generated/GoogleAuthAuthorize';
import { GoogleLoginResponseOffline, useGoogleLogin } from 'react-google-login';
import { SENSITIVE_SCOPE } from 'shared/shared/constants';
import { addSnackbarMessage } from 'utils/eventEmitter';
import { useMutation } from '@apollo/client/react/hooks/useMutation';
import CheckIcon from '@material-ui/icons/Check';
import React, { useState } from 'react';
import styles from './AccountSettingsPage.module.scss';
import useUser from 'utils/hooks/user/useUser';

const GoogleCalendarSettings = () => {
  const { user, refetchUser } = useUser();
  const [doMutation] = useMutation<GoogleAuthAuthorize, GoogleAuthAuthorizeVariables>(GOOGLE_AUTH_AUTHORIZE);
  const [loading, setLoading] = useState(false);
  const config = GOOGLE_AUTH({ scope: SENSITIVE_SCOPE });

  const handleFailure = (err: any) => {
    config.onFailure(err);
    addSnackbarMessage("You haven't completed the authorization process, please try again", 'error');
    setLoading(false);
  };

  const handleSuccess = (res: GoogleLoginResponseOffline) => {
    return doMutation({ variables: { idToken: res.code } })
      .then(({ data }) => {
        if (!data?.googleAuthAuthorize.ok) {
          const errors = data?.googleAuthAuthorize?.errors;
          return handleFailure(errors ? errors[0]?.message : null);
        }
        refetchUser();
        setLoading(false);
      })
      .catch(handleFailure);
  };

  const { addScopes } = useGoogleLogin({
    clientId: config.clientId,
    onAddScopesSuccess: handleSuccess as any,
    onAddScopesFailure: handleFailure,
    responseType: config.responseType,
    prompt: config.prompt,
  });

  if ((user as m.Person).auths?.google[0]?.isCalendarAuthorized) {
    return (
      <div className={styles.connected}>
        <div className={styles.success}>
          <CheckIcon className={styles.checkIcon} /> <span className={styles.showSm}>Connected</span>{' '}
          <span className={styles.hideSm}>You've already synchronized Google Calendar</span>
        </div>
      </div>
    );
  }

  return (
    <>
      <Button
        className="text-left w-68"
        label={loading ? <Loading size="small" /> : 'Sync Google Calendar'}
        onClick={() => {
          setLoading(true);
          addScopes(config.scope);
        }}
      />
      <div className={styles.pricingNote}>All new events will be automatically added to your default calendar.</div>
    </>
  );
};

export default GoogleCalendarSettings;
