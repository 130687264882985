import Container from 'components/layout/Container/Container';
import React, { ReactNode } from 'react';

import callGrid from './img/newest/call-grid.svg';
import downArrow from './img/newest/downarrow.svg';
import micCam from './img/newest/mic-cam.svg';
import person from './img/newest/person.svg';
import personPerson from './img/newest/person-person.svg';
import phone from './img/newest/phone.svg';
import screenshare from './img/newest/screenshare.svg';
import speech from './img/newest/speech.svg';

interface ItemProps {
  icon: ReactNode;
  children: ReactNode;
}

const Item = (props: ItemProps) => (
  <div>
    <div className="flex items-center justify-center">{props.icon}</div>
    <div className="relative lg:-mt-4">{props.children}</div>
  </div>
);

const Img = ({ src }: { src: string }) => (
  <div className="h-24 w-22 sm:w-32 md:w-44 sm:h-32 md:h-44">
    <img src={src} alt="" className="w-full h-full" />
  </div>
);

const Features3 = () => {
  return (
    <div className="text-center text-white" style={{ backgroundColor: '#EF9071' }}>
      <Container className="py-24 md:py-48">
        <div className="max-w-md mx-auto text-3xl font-bold text-center md:text-5xl lg:max-w-none">
          Virtual Venue features
        </div>
        <div className="mt-8 text-xs font-bold sm:mt-12 sm:text-lg">
          <div className="grid max-w-screen-lg grid-cols-2 mx-auto lg:grid-cols-3 gap-y-3 sm:gap-y-4">
            <Item icon={<Img src={micCam} />}>
              Audio and video chat up
              <br />
              to 200 participants
            </Item>
            <Item icon={<Img src={speech} />}>
              Text chat with images,
              <br />
              reactions, and moderators
            </Item>
            <Item icon={<Img src={screenshare} />}>Screen sharing</Item>
            <Item
              icon={
                <div className="relative lg:top-2">
                  <Img src={downArrow} />
                </div>
              }
            >
              Easily add to Squarespace,
              <br />
              Wix, HTML, and more
            </Item>
            <Item
              icon={
                <div className="relative lg:top-2">
                  <Img src={callGrid} />
                </div>
              }
            >
              Speaker view
              <br />+ grid view
            </Item>
            <Item icon={<Img src={person} />}>
              Guests can join
              <br />
              without accounts
            </Item>
            <Item icon={<Img src={personPerson} />}>
              Guests can join without
              <br />
              plug-ins or apps
            </Item>
            <div className="hidden lg:block">
              <Item icon={<Img src={phone} />}>Stellar customer support</Item>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Features3;
