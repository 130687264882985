import { Input, Loading } from 'components/common';
import { debouncePromise, memoizePromise } from 'utils/helpers';
import { unsplashAPI } from 'utils/api';
import AsyncSearch from 'components/common/AsyncSearch';
import IPImageGrid from 'components/EventCreateUpdateForm/ImagePicker/IPImageGrid';
import IPTab from 'components/EventCreateUpdateForm/ImagePicker/IPTab';
import React from 'react';
import styles from 'components/EventCreateUpdateForm/ImagePicker/ImagePicker.module.scss';

interface Props {
  searchValue: any;
  setSearchValue: any;
  onSelect: any;
}

const unsplashGetItems = memoizePromise(debouncePromise(unsplashAPI.getItems, 300));

const UnsplashTab: React.FC<Props> = (props) => {
  const { searchValue, setSearchValue, onSelect } = props;
  return (
    <IPTab>
      <AsyncSearch getItems={unsplashGetItems} defaultValue={searchValue}>
        {({ value, data, loading, onChange }) => (
          <>
            <Input
              autoFocus
              placeholder="Search for an image..."
              size="small"
              value={value}
              className={styles.input}
              onChange={(e) => {
                const { value } = e.target;
                onChange(value);
                setSearchValue(value);
              }}
            />
            {loading ? <Loading /> : <IPImageGrid images={data} onClick={onSelect} />}
          </>
        )}
      </AsyncSearch>
    </IPTab>
  );
};

export default UnsplashTab;
