import React from 'react';
import facebook from 'assets/icons/streaming/facebook-icon.svg';
import generic from 'assets/icons/streaming/generic-icon.svg';
import meet from 'assets/icons/streaming/meet-icon.svg';
import place from 'assets/icons/streaming/place-icon2.svg';
import youtube from 'assets/icons/streaming/youtube-icon.svg';
import zoom from 'assets/icons/streaming/zoom-icon.svg';

export type LocationIconName = 'facebook' | 'generic' | 'meet' | 'youtube' | 'zoom' | 'place';

interface Props {
  icon: LocationIconName;
  size?: number;
  className?: string;
}

const iconSrc: Record<LocationIconName, string> = {
  facebook,
  generic,
  meet,
  youtube,
  zoom,
  place,
};

const LocationIcon = ({ icon, className, size = 28 }: Props) => {
  return <img className={className} src={iconSrc[icon]} alt={`${icon} icon`} style={{ height: size, width: size }} />;
};

export default LocationIcon;
