import './EventReadPage.scss';
import { EventReadViewSkeleton } from 'components/pages/EventReadPage/EventReadViewSkeleton';
import { URLContext } from 'components/RoutingProvider';
import { localStore } from 'utils/localstore';
import Error404Page from 'components/pages/Error404Page';
import Error500Page from 'components/pages/Error500Page';
import EventLayout from 'components/layout/EventLayout/EventLayout';
import EventReadView from 'components/pages/EventReadPage/EventReadView';
import PrivateEventPage from 'components/pages/EventReadPage/PrivateEventPage';
import React, { useContext, useEffect, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import useEventClass from 'utils/hooks/event/useEventClass';
import useSetEventId from 'components/App/globalstate/useSetEventId';
import useUser from 'utils/hooks/user/useUser';

interface Props {
  id: string;
}

const EventReadPage = ({ id }: Props) => {
  useSetEventId(id);
  const { isDemo, event, eventExists, loading, error } = useEventClass({ required: false });
  const { isAuthenticated } = useUser();
  const [fbPixelEventSent, setFbPixelEventSent] = useState<boolean>(false);
  const { authModalStateMutatorSet } = useContext(URLContext);

  useEffect(() => {
    if (event?.facebookPixelId && !fbPixelEventSent) {
      ReactPixel.init(event.facebookPixelId);
      ReactPixel.pageView();
      setFbPixelEventSent(true);
    }
  }, [event, fbPixelEventSent]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const roleInviteEmail = params.get('roleInvite');
    if (!isAuthenticated && roleInviteEmail) {
      authModalStateMutatorSet.openSignupModal({
        email: roleInviteEmail,
        message: 'You must sign up to co-host this event',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || (isDemo && error && localStore.creatingDemoEvent.get())) {
    return (
      <EventLayout title="Loading...">
        <EventReadViewSkeleton />
      </EventLayout>
    );
  }

  if (error) {
    return <Error500Page />;
  }

  if (!event) {
    return !eventExists ? <Error404Page /> : <PrivateEventPage />;
  }

  return (
    <EventLayout
      title={event.title}
      themePicUrl={event.themePic}
      readPage={true}
      bgColor={event.bgColor}
      bgNoBackdrop={event.bgNoBackdrop}
    >
      <EventReadView event={event} isRun={false} isDemo={isDemo} loading={loading} />
    </EventLayout>
  );
};

export default EventReadPage;
