import { INVITE_TOKEN_QUERY } from 'utils/gql';
import { InviteToken } from 'models/invite-token';
import { InviteTokenQuery } from 'generated/InviteTokenQuery';
import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import useInviteId from 'components/App/globalstate/useInviteId';

function useInviteTokenClass() {
  const { inviteId } = useInviteId();
  const { loading, data: tokenData, error } = useQuery<InviteTokenQuery>(INVITE_TOKEN_QUERY, {
    variables: { token: inviteId },
    skip: !inviteId,
  });

  const inviteToken: InviteToken | undefined = useMemo(
    () => (tokenData?.inviteToken ? tokenData.inviteToken : undefined),
    [tokenData]
  );

  return { inviteToken, loading, error };
}

export default useInviteTokenClass;
