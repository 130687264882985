import { isActiveUrl } from 'utils/urls';
import { reverse } from 'router';
import Link from 'components/Link';
import React from 'react';

interface NavigationItem {
  href: string;
  text: string;
}

const DashboardNavigation = () => {
  const activeLinkClass = 'bg-gray-300 text-orange-500 px-3 py-2 font-semibold text-lg rounded-md';
  const normalLinkClass = 'text-orange-300 hover:text-orange-500 px-3 py-2 font-semibold text-lg rounded-md';
  const navigationItems: NavigationItem[] = [
    {
      text: 'Events',
      href: reverse('dashboard_events'),
    },
    {
      text: 'Contacts',
      href: reverse('dashboard_contacts'),
    },
  ];
  return (
    <>
      <div className="mb-4">
        <nav className="flex space-x-4" aria-label="Tabs">
          {navigationItems.map((item) => {
            const linkClass = isActiveUrl(item.href) ? activeLinkClass : normalLinkClass;
            return (
              <Link key={item.text} href={item.href} className={linkClass}>
                {item.text}
              </Link>
            );
          })}
        </nav>
      </div>
    </>
  );
};

export default DashboardNavigation;
