import { Invite } from 'models/invite';
import { Unauthenticated } from 'models';
import { ViewAsContext } from 'components/context/ViewAsContext/ViewAsContext';
import { parseInviteFromInviteToken } from 'models/invite-token';
import { useContext, useMemo } from 'react';
import useEvent from 'utils/hooks/event/useEvent';
import useInviteTokenClass from 'utils/hooks/useInviteTokenClass';

function useEventInviteClass() {
  const { event, loading: eventLoading, error: eventError } = useEvent();
  const { inviteToken, loading: inviteTokenLoading, error: inviteTokenError } = useInviteTokenClass();

  const loading = inviteTokenLoading || eventLoading;
  const error = inviteTokenError || eventError;

  const invite: Invite | undefined = useMemo(
    () =>
      event && event.myInvite
        ? event.myInvite
        : event && inviteToken
        ? parseInviteFromInviteToken(inviteToken)
        : undefined,
    [event, inviteToken]
  );

  const viewAs = useContext(ViewAsContext);
  if (viewAs?.user === Unauthenticated) return { permissions: {}, loading: false };

  return { invite, loading, error };
}

export default useEventInviteClass;
