import { Control, Controller } from 'react-hook-form';
import { Currency } from 'shared/shared/types';
import { EventWithExistQuery_event_ticketTypes } from 'generated/EventWithExistQuery';
import { TicketTypeForPurchase } from 'models';
import { WidgetProps } from 'components/widgets';
import { renderWholeMoney } from 'shared/shared/utils';
import DownArrowIcon from '@material-ui/icons/KeyboardArrowDown';
import FieldLayout from 'components/common/FieldLayout/FieldLayout';
import React, { useState } from 'react';
import TicketPriceAndQuantityWidget, {
  TicketPriceAndQuantityValue,
} from './TicketPriceAndQuantityWidget/TicketPriceAndQuantityWidget';
import UpArrowIcon from '@material-ui/icons/KeyboardArrowUp';
import classNames from 'classnames';
import styles from './TicketsWidget.module.scss';
import useEventClass from 'utils/hooks/event/useEventClass';

export interface TicketOrder {
  id: string;
  price: number | null;
  quantity: number;
  position: number;
}

export type TicketsValue = TicketOrder[];

interface Attrs<T extends Record<string, any>> {
  control: Control<T>;
  errors: any;
  ticketTypes: TicketTypeForPurchase[];
  validate: Function;
  watch: Function;
}

type Props<T extends Record<string, any>> = Omit<WidgetProps<TicketsValue, Attrs<T>>, 'value' | 'onChange'>;

function TicketsWidget<T extends Record<string, any>>(props: Props<T>) {
  const { disabled, attrs } = props;
  const { ticketTypes, control, validate, watch, errors } = attrs!;
  const singleTicketType = ticketTypes.length === 1 ? ticketTypes[0] : undefined;
  const [indexOpen, setIndexOpen] = useState(ticketTypes.map(() => true));
  const { event } = useEventClass();

  function onExpandCollapse(index: number) {
    return () => {
      const newIndexOpen = indexOpen.slice();
      newIndexOpen[index] = !newIndexOpen[index];
      setIndexOpen(newIndexOpen);
    };
  }

  if (singleTicketType) {
    const isSoldOut = !!singleTicketType.capacity && singleTicketType.quantityClaimed >= singleTicketType.capacity;

    return (
      <div className="mt-3">
        <div className="mb-4 whitespace-pre-wrap">{singleTicketType.description}</div>
        <PriceAndQuantityWidget<T>
          control={control}
          disabled={disabled || isSoldOut}
          errors={errors}
          index={0}
          ticketType={singleTicketType}
          validateFn={validate}
          watch={watch}
          currency={event.currency}
        />
      </div>
    );
  } else {
    return (
      <div className="mt-3">
        {ticketTypes.map((ticketType, index) => {
          const isSoldOut = !!ticketType.capacity && ticketType.quantityClaimed >= ticketType.capacity;
          return (
            <div key={ticketType.id} className={classNames(styles.ticket, { [styles.fade]: isSoldOut })}>
              <div className={styles.summary} onClick={onExpandCollapse(index)}>
                <div className={styles.header}>
                  <div className={styles.name}>
                    <h3 className={styles.nameInner}>
                      {ticketType.name}
                      {isSoldOut && <span className={styles.soldOut}>Sold Out!</span>}
                    </h3>
                    <span
                      className={classNames(styles.price, {
                        [styles.suggested]: ticketType.suggestedDonationDefaultCents,
                      })}
                    >
                      {renderWholeMoney(
                        ticketType.fixedPriceCents || ticketType.suggestedDonationDefaultCents || 0,
                        event.currency
                      )}
                    </span>
                  </div>
                </div>
                <div className={styles.icons} onClick={onExpandCollapse(index)}>
                  {indexOpen[index] && <UpArrowIcon className={styles.arrowIcon} />}
                  {!indexOpen[index] && <DownArrowIcon className={styles.arrowIcon} />}
                </div>
              </div>
              <div
                className="mb-4 text-sm text-opacity-75 whitespace-pre-wrap text-trueblack"
                onClick={() => {
                  if (!indexOpen[index]) onExpandCollapse(index)();
                }}
              >
                {ticketType.description}
              </div>
              {indexOpen[index] && (
                <div className={styles.form}>
                  <PriceAndQuantityWidget<T>
                    control={control}
                    disabled={disabled || isSoldOut}
                    errors={errors}
                    index={index}
                    ticketType={ticketType}
                    validateFn={validate}
                    watch={watch}
                    currency={event.currency}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  }
}

interface PriceAndQuantityProps<T extends Record<string, any>> {
  currency: Currency;
  control: Control<T>;
  disabled: boolean;
  errors: any;
  index: number;
  ticketType: EventWithExistQuery_event_ticketTypes;
  validateFn: Function;
  watch: Function;
}

function handleChangePriceAndQuantity(value: TicketOrder) {
  return (priceAndQuantity: TicketPriceAndQuantityValue[]) => {
    const newTicketsValue = { ...value, ...priceAndQuantity[0] };
    return newTicketsValue;
  };
}

function PriceAndQuantityWidget<T extends Record<string, any>>(props: PriceAndQuantityProps<T>) {
  const { currency, index, disabled, errors, ticketType, control, validateFn, watch } = props;
  const name = `ticket-${index}`;
  const value = watch(name);
  return (
    <FieldLayout error={errors[name] as any}>
      <Controller
        name={name}
        control={control}
        as={TicketPriceAndQuantityWidget}
        rules={{
          required: true,
          validate: (value) => validateFn(value, ticketType),
        }}
        disabled={disabled}
        onChange={handleChangePriceAndQuantity(value)}
        value={value}
        attrs={{
          currency,
          fixedPrice: typeof ticketType.fixedPriceCents === 'number',
          maxQuantity: ticketType.capacity ? ticketType.capacity - ticketType.quantityClaimed : undefined,
        }}
      />
    </FieldLayout>
  );
}

export default TicketsWidget;
