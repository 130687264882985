import { INVITE_USE_MAGIC_LINK_MUTATION } from 'utils/gql';
import { InviteTokenQueryVariables } from 'generated/InviteTokenQuery';
import { InviteUseMagicLink } from 'generated/InviteUseMagicLink';
import { Loading } from 'components/common';
import { RedirectToPath } from 'components/Redirect';
import { reverse } from 'router';
import { useMutation } from '@apollo/client';

import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import Cookie from 'js-cookie';
import React from 'react';
import useUserChangeContext from 'components/App/useUserChangeContext';

interface Props {
  token: string;
}

const Error = () => (
  <b>Sorry, something went wrong. This login link may be expired or invalid. Please request a new sign-in link.</b>
);

const InviteMagicLinkView: React.FC<Props> = (props) => {
  const { login } = useUserChangeContext();
  const { token } = props;

  const [useMagicLinkMutation, { error, loading, data }] = useMutation<InviteUseMagicLink, InviteTokenQueryVariables>(
    INVITE_USE_MAGIC_LINK_MUTATION
  );

  React.useEffect(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useMagicLinkMutation({ variables: { token } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  if (error) {
    return <Error />;
  }

  if (loading || !data || !data.inviteUseMagicLink) {
    return <Loading delay={0} />;
  }

  const { ok, event, apitoken, signedCookie } = data?.inviteUseMagicLink;

  if (!ok) {
    return <Error />;
  }

  const next = reverse('event_read', { id: event?.id ?? '' });

  if (apitoken) {
    login(apitoken, true, next);
    return (
      <div>
        <b>Logging in...</b>
      </div>
    );
  }
  if (signedCookie) {
    Cookie.set(signedCookie.name, signedCookie.value);
    return <RedirectToPath path={next} />;
  }

  return null;
};

const InviteMagicLinkPage = (props: Props) => {
  return (
    <BaseLayout title="">
      <InviteMagicLinkView {...props} />
    </BaseLayout>
  );
};

export default InviteMagicLinkPage;
