import { useVideojs } from 'react-videojs-hook';
import React from 'react';
import videojs from 'video.js';

import 'video.js/dist/video-js.css';

interface Props {
  src: string;
  options: videojs.PlayerOptions;
}

const initialOptions = {
  controls: true,
  fill: true,
  controlBar: {
    volumePanel: {
      inline: false,
    },
  },
};

const VideoPlayer = ({ src, options }: Props) => {
  const { vjsId, vjsRef, vjsClassName } = useVideojs({ src, ...initialOptions, ...options });

  return (
    <div data-vjs-player>
      <video id={vjsId} ref={vjsRef} className={vjsClassName} />
    </div>
  );
};

export default VideoPlayer;
