import examples from 'libphonenumber-js/examples.mobile.json';
import getCountryName from 'utils/countries';

export const phoneExamples = examples as JSON;

interface CountrySelectOption {
  label: string;
  value: string;
}

const countryOptions: CountrySelectOption[] = [];

export const getCountryOptions = (): CountrySelectOption[] => {
  // a little lazy
  if (countryOptions.length) {
    return countryOptions;
  }
  for (const key in phoneExamples) {
    const countryName = getCountryName(key);
    if (!countryName) {
      continue;
      // Missing: AC, BQ, CW, KP, SS, SX, TA, XK
    }
    const label = `${countryName} (${key})`;
    countryOptions.push({ label, value: key });
  }
  countryOptions.sort((option1, option2) => {
    if (option1.value === 'US') {
      return -1;
    } else if (option2.value === 'US') {
      return 1;
    } else if (option1.value === 'GB') {
      return -1;
    } else if (option2.value === 'GB') {
      return 1;
    } else {
      return option1.label.localeCompare(option2.label);
    }
  });
  return countryOptions;
};
