// Styling wrapper for all <input>-like elements, including start-adornment support

import * as CSS from 'csstype';
import React, { ComponentType, ReactElement } from 'react';
import classNames from 'classnames';

type Adornment = {
  // Component to be rendered at the beginning of the input
  component: ReactElement;

  // Roughly, the width of the adornment divided by 4
  paddingSize: number;

  // Whether the element is interactive. Defaults to false.
  clickable?: boolean;
};

export interface InputLayoutExtraProps {
  startAdornment?: Adornment;
  endAdornment?: Adornment;
  size?: 'md' | 'lg' | 'sm';
}

type InputLayoutBlock = 'root';

export interface Props<T = any> extends InputLayoutExtraProps {
  inputComponent: ComponentType<T> | 'input';
  inputProps: T;
  classes?: Partial<Record<InputLayoutBlock, string | CSS.Properties>>;
  ref?: React.Ref<ComponentType<T>>;
}

const baseStyle = 'form-input block w-full text-base focus:shadow-none';
const sizeStyles = {
  sm: 'h-8',
  md: 'h-12',
  lg: 'h-14 rounded-none',
};

const InputLayout = (props: Props, ref: React.Ref<HTMLInputElement>) => {
  const {
    inputComponent,
    inputProps,
    startAdornment,
    endAdornment,
    classes = { root: undefined },
    size = 'md',
  } = props;
  const { className, ...rest } = inputProps;
  const cls = classNames(
    baseStyle,
    sizeStyles[size],
    inputProps.disabled && 'text-gray-700',
    className,
    startAdornment && 'pl-' + startAdornment.paddingSize,
    endAdornment && 'pr-' + endAdornment.paddingSize,
    'focus:ring-2-black'
  );

  return (
    <div className={classNames('relative', size !== 'lg' && (classes.root ?? 'rounded-sm shadow-sm'))}>
      {startAdornment && (
        <div
          className={classNames(
            'absolute inset-y-0 left-0 pl-3 flex items-center',
            !startAdornment.clickable && 'pointer-events-none'
          )}
        >
          {startAdornment.component}
        </div>
      )}
      {React.createElement(inputComponent, { type: 'text', className: cls, ref, ...rest })}
      {endAdornment && (
        <div
          className={classNames(
            'absolute inset-y-0 right-0 pr-3 flex items-center',
            !endAdornment.clickable && 'pointer-events-none'
          )}
        >
          {endAdornment.component}
        </div>
      )}
    </div>
  );
};

export default React.forwardRef(InputLayout);
