import * as React from 'react';
import { VirtualVenueThemeColor, VirtualVenueThemeColorConfig } from '../types';
import { objFilter } from 'shared/shared/Functional';

type VirtualVenueTheme = VirtualVenueThemeColorConfig;

export const defaultTheme: VirtualVenueTheme = {
  bgColor: '#373433FF',
  titleTextColor: '#ffffffff',
  iconColor: '#ffffffff',
  scrollbarColor: '#ffffff80',
  chatTextColor: '#f1f5f9ff',
  chatBgColor: '#37343300',
  gridLineColor: '#ffffffff',
};

type SetColor = { type: 'set-color'; payload: { name: VirtualVenueThemeColor; value: string } };
type VirtualVenueThemeAction = SetColor;

type DispatchVirtualVenueTheme = React.Dispatch<VirtualVenueThemeAction>;
const VirtualVenueThemeContext = React.createContext<VirtualVenueTheme | undefined>(undefined);
const VirtualVenueThemeDispatchContext = React.createContext<DispatchVirtualVenueTheme | undefined>(undefined);

function venueThemeReducer(theme: VirtualVenueTheme, action: VirtualVenueThemeAction) {
  switch (action.type) {
    case 'set-color': {
      const { name, value } = action.payload;
      return {
        ...theme,
        [name]: value,
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

type VirtualVenueThemeProviderProps = {
  initialTheme?: Partial<VirtualVenueTheme>;
  children: React.ReactNode;
};

function VirtualVenueThemeProvider({ children, initialTheme }: VirtualVenueThemeProviderProps) {
  // Remove blank values from when new values were introduced
  const validInitialTheme = objFilter(initialTheme, (k, v) => !!v);

  const combinedTheme = {
    ...defaultTheme,
    ...validInitialTheme,
  };

  const [state, dispatch] = React.useReducer(venueThemeReducer, combinedTheme);

  return (
    <VirtualVenueThemeContext.Provider value={state}>
      <VirtualVenueThemeDispatchContext.Provider value={dispatch}>{children}</VirtualVenueThemeDispatchContext.Provider>
    </VirtualVenueThemeContext.Provider>
  );
}

function useVirtualVenueTheme() {
  const context = React.useContext(VirtualVenueThemeContext);
  if (context === undefined) {
    throw new Error('useVirtualVenueTheme must be used within a VirtualVenueThemeProvider');
  }
  return context;
}

function useVirtualVenueThemeDispatch() {
  const context = React.useContext(VirtualVenueThemeDispatchContext);
  if (context === undefined) {
    throw new Error('useVirtualVenueThemeDispatch must be used within a VirtualVenueThemeProvider');
  }
  return context;
}

export { VirtualVenueThemeProvider, useVirtualVenueTheme, useVirtualVenueThemeDispatch };
