import {
  ADD_CONTACT_TO_LIST_MUTATION,
  CONTACT_LIST_DELETE_MUTATION,
  CONTACT_LIST_QUERY,
  CONTACT_LIST_UPDATE_MUTATION,
  CONTACT_SEARCH_QUERY,
  CREATE_CONTACT_LIST_MUTATION,
  MY_CONTACT_LISTS_QUERY,
  REMOVE_CONTACT_FROM_LIST_MUTATION,
} from 'utils/gql';
import { AddContactToList, AddContactToListVariables } from 'generated/AddContactToList';
import { AsyncSearchInput, Button, Confirm, Icon, Input, Link, Loading } from 'components/common';
import { ContactList, ContactListVariables } from 'generated/ContactList';
import { CreateContactList, CreateContactListVariables } from 'generated/CreateContactList';
import { MyContactLists } from 'generated/MyContactLists';
import { RemoveContactFromList, RemoveContactFromListVariables } from 'generated/RemoveContactFromList';
import { RemoveContactList, RemoveContactListVariables } from 'generated/RemoveContactList';
import { URLContext } from 'components/RoutingProvider';
import { UpdateContactList, UpdateContactListVariables } from 'generated/UpdateContactList';
import { debouncePromise, memoizePromise, prettyEmail } from 'utils/helpers';
import { queryToPromise } from 'api/utils';
import { reverse } from 'router';
import { useMutation, useQuery } from '@apollo/client';
import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import LoginRequired from 'components/LoginRequired';
import React, { useContext, useState } from 'react';
import addressparser from 'addressparser';
import classNames from 'classnames';
import styles from './AccountContactListsPage.module.scss';

const searchContacts = (value: string) =>
  queryToPromise(CONTACT_SEARCH_QUERY, { query: value }).then((resp: any) => {
    return resp.data.contactSearch;
  });

interface NewListProps {
  onSave?: (item: any) => void;
}

const NewList = (props: NewListProps) => {
  const { onSave } = props;
  const [name, setName] = useState('');
  const submit = (createList: Function, onSave: Function, name: string) => {
    return createList({ variables: { name } }).then((result: any) => {
      const {
        data: {
          contactListCreate: { contactList },
        },
      } = result;

      onSave && onSave(contactList);
    });
  };

  const [createContactList] = useMutation<CreateContactList, CreateContactListVariables>(CREATE_CONTACT_LIST_MUTATION);

  return (
    <div className={styles.NewList}>
      <div className="text-3xl">New List</div>
      <Input
        placeholder="e.g. Besties"
        value={name}
        onChange={(e) => setName(e.target.value)}
        onKeyDown={(e) => e.key === 'Enter' && submit(createContactList, onSave as Function, name)}
        autoFocus
      />
      <Button label="Create" onClick={() => submit(createContactList, onSave as Function, name)} />
    </div>
  );
};

interface ContactListFormProps {
  initialName?: string;
  contacts?: any[];
  onAdd?: (item: any) => void;
  onBulkCreate?: (items: any[]) => void;
  onRename?: (name: string) => void;
  onDelete?: (item: any) => void;
  onDeleteList?: () => void;
}

interface ContactListFormState {
  editMode: boolean;
  name: string;
  showDelete: boolean;
}

class ContactListForm extends React.Component<ContactListFormProps, ContactListFormState> {
  private searchContactsMemo: any;

  constructor(props: ContactListFormProps) {
    super(props);

    this.state = {
      name: props.initialName || '',
      editMode: false,
      showDelete: false,
    };

    this.searchContactsMemo = memoizePromise(debouncePromise(searchContacts, 300));
  }

  componentDidUpdate(prevProps: ContactListFormProps, _: ContactListFormState) {
    const { initialName } = this.props;

    if (initialName !== prevProps.initialName) {
      this.setState({ name: initialName || '' });
    }
  }

  componentWillUnmount() {
    this.searchContactsMemo = null;
  }

  private readonly onChangeName = (e: any) => {
    const { value } = e.target;
    this.setState({ name: value });
  };

  private readonly onBlur = () => {
    const { name } = this.state;
    const { onRename, initialName } = this.props;
    if (name !== initialName) {
      onRename && onRename(name);
    }

    this.setState({ editMode: false });
  };

  private readonly onAdd = (item: any) => {
    const { onAdd } = this.props;
    onAdd && onAdd(item);
    return true;
  };

  private readonly onSubmit = (data: any) => {
    const { value } = data;
    const trimmedValue = value.trim();

    if (trimmedValue) {
      const preParsed = trimmedValue.replace(/@[^\s,]* /g, '$&, ').trim();
      const addresses = addressparser(preParsed)
        .map((e: any) => ({ email: e.address, name: e.name }))
        .filter((e: any) => e.email);

      if (addresses.length > 0) {
        const { onBulkCreate } = this.props;
        onBulkCreate && onBulkCreate(addresses);
        return true;
      }
    }
  };

  private readonly showDelete = () => this.setState({ showDelete: true });

  private readonly closeDelete = () => this.setState({ showDelete: false });

  render() {
    const { contacts, onDelete, onDeleteList } = this.props;
    const { name, showDelete, editMode } = this.state;

    return (
      <div className={styles.ContactListForm}>
        <Confirm
          open={showDelete}
          title="Delete"
          text="Would you like to delete this contact list?"
          onConfirm={() => {
            onDeleteList && onDeleteList();
            this.closeDelete();
          }}
          yesLabel="Delete"
          onCancel={this.closeDelete}
        />

        {editMode ? (
          <Input
            className={styles.header}
            placeholder="e.g. Besties"
            size="small"
            value={name}
            onChange={this.onChangeName}
            onBlur={this.onBlur}
            autoFocus
          />
        ) : (
          <div className="text-3xl">
            {name}{' '}
            <span onClick={() => this.setState({ editMode: true })} style={{ cursor: 'pointer' }}>
              <Icon icon="pencil" />
            </span>
          </div>
        )}

        <div className={styles.contacts}>
          {(contacts || [])
            .map((contact) => ({ ...contact.contact, listId: contact.id }))
            .map((contact) => (
              <div key={contact.id} className={styles.contact}>
                <div className={styles.left}>{prettyEmail(contact)}</div>
                <div className={styles.right}>
                  {onDelete && (
                    <span className={styles.action} onClick={() => onDelete(contact)}>
                      <Icon icon="no" />
                    </span>
                  )}
                </div>
              </div>
            ))}
        </div>

        <AsyncSearchInput
          getItems={this.searchContactsMemo}
          transformData={(data: any) => {
            const existingContacts = (contacts || []).map((e) => e.contact);

            const excludeIds = existingContacts.reduce((memo, item) => {
              memo[item.id] = true;
              return memo;
            }, {});
            if (!data) {
              return [];
            }

            return data.filter((e: any) => !excludeIds[e.id]);
          }}
          getItemValue={prettyEmail}
          onSelect={this.onAdd}
          onSubmit={this.onSubmit}
          inputProps={{
            placeholder: 'Add contacts...',
          }}
        />

        <div className={styles.footer}>
          {onDeleteList && <Button label="Delete List" onClick={this.showDelete} color="secondary" />}
        </div>
      </div>
    );
  }
}

const ContactListEdit = (props: any) => {
  const [updateContactList] = useMutation<UpdateContactList, UpdateContactListVariables>(CONTACT_LIST_UPDATE_MUTATION);
  const [addContactToList] = useMutation<AddContactToList, AddContactToListVariables>(ADD_CONTACT_TO_LIST_MUTATION);
  const [removeContactFromList] = useMutation<RemoveContactFromList, RemoveContactFromListVariables>(
    REMOVE_CONTACT_FROM_LIST_MUTATION
  );
  const [removeContactList] = useMutation<RemoveContactList, RemoveContactListVariables>(CONTACT_LIST_DELETE_MUTATION, {
    update: (cache) => {
      const cacheData = cache.readQuery<MyContactLists>({
        query: MY_CONTACT_LISTS_QUERY,
      });

      const contactLists = cacheData?.me?.contactLists?.filter((c) => (c ?? {}).id !== props.id);

      cache.writeQuery<MyContactLists>({
        query: MY_CONTACT_LISTS_QUERY,
        data: {
          me: {
            ...cacheData?.me,
            contactLists,
          } as any,
        },
      });
    },
  });

  return (
    <ContactListForm
      {...props}
      onRename={(name) => {
        if (!name) {
          return;
        }

        if (props.id) {
          updateContactList({ variables: { id: props.id, name } });
        }
      }}
      onAdd={(contact) => {
        if (!props.id) {
          return;
        }

        addContactToList({
          variables: {
            contactListId: props.id,
            contactId: contact.id,
          },
        }).then((result: any) => {
          const {
            data: {
              contactListContactAdd: { ok, contactListContact },
            },
          } = result;

          if (ok) {
            props.onAdd(contactListContact);
          }
        });
      }}
      onBulkCreate={(emails) => {
        if (!props.id) {
          return;
        }

        Promise.all(
          emails.map((email) =>
            addContactToList({
              variables: {
                contactListId: props.id,
                contactInput: email,
              },
            }).then((result: any) => {
              const {
                data: {
                  contactListContactAdd: { ok, contactListContact },
                },
              } = result;

              if (ok) {
                props.onAdd(contactListContact);
              }
            })
          )
        );
      }}
      onDelete={(contact) =>
        removeContactFromList({ variables: { contactListContactId: contact.listId } }).then((result: any) => {
          const {
            data: {
              contactListContactRemove: { ok },
            },
          } = result;

          if (ok) {
            props.onDelete(contact.listId);
          }
        })
      }
      onDeleteList={() =>
        removeContactList({ variables: { id: props.id } }).then((result: any) => {
          const {
            data: {
              contactListDelete: { ok },
            },
          } = result;

          if (ok) {
            props.onDeleteList();
          }
        })
      }
    />
  );
};

const ContactLists = ({ lists, activeListId }: any) => (
  <div className={styles.ContactLists}>
    {lists.map((list: any) => (
      <Link
        key={list.id}
        className={classNames(styles.list, {
          [styles.active]: activeListId === list.id,
        })}
        href={reverse('list', { id: list.id })}
      >
        <div>{list.name}</div>
      </Link>
    ))}

    <div className="mt-6">
      <Button label="+ New List&nbsp;" href={reverse('list', { id: 'new' })} small />
    </div>
  </div>
);

interface PageProps {
  id: string;
}

const AccountContactListsPage = (props: PageProps) => {
  return (
    <LoginRequired>
      <BaseLayout title="Contact Lists">
        <AccountContactListsPageInner {...props} />
      </BaseLayout>
    </LoginRequired>
  );
};

const AccountContactListsPageInner = (props: PageProps) => {
  const { replacePath } = useContext(URLContext);
  const isNewList = props.id === 'new';
  const { loading, data, error, refetch: refetchMyList } = useQuery<MyContactLists>(MY_CONTACT_LISTS_QUERY);
  const {
    loading: loadingContactList,
    data: dataContactList,
    error: errorContactList,
    updateQuery: updateContactListQuery,
    refetch,
  } = useQuery<ContactList, ContactListVariables>(CONTACT_LIST_QUERY, {
    variables: { id: props.id },
    skip: !props.id || isNewList,
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div>Sorry, something went wrong. Please try refreshing the page.</div>;
  }

  const lists = data?.me?.contactLists ?? [];

  const navigateToDefaultList = () => {
    const nextId = lists?.length ? lists[0]?.id : 'new';
    const path = reverse('list', { id: nextId });
    replacePath(path);
    return <div />;
  };

  // NOTE(nick): navigate to first list by default
  if (!props.id) {
    return navigateToDefaultList();
  }

  if (isNewList) {
    return (
      <div className={styles.ContactListPage}>
        {lists.length > 0 && (
          <div className={styles.left}>
            <ContactLists lists={lists} activeListId={props.id} />
          </div>
        )}
        <div className={styles.right}>
          <NewList
            onSave={(contactList: any) => {
              replacePath(reverse('list', { id: contactList.id }));
              refetchMyList();
            }}
          />
        </div>
      </div>
    );
  }

  if (loadingContactList) {
    return <Loading />;
  }

  if (errorContactList) {
    return <div>Sorry, something went wrong. Please try refreshing the page.</div>;
  }

  const list = dataContactList?.contactList;

  if (!list) {
    return navigateToDefaultList();
  }

  return (
    <div className={styles.ContactListPage}>
      {lists.length > 0 && (
        <div className={styles.left}>
          <ContactLists lists={lists} activeListId={props.id} />
        </div>
      )}

      <div className={styles.right}>
        <ContactListEdit
          onDelete={(id: any) => {
            updateContactListQuery(({ contactList }: any) => ({
              contactList: {
                ...contactList,
                contacts: contactList.contacts.filter((contact: any) => contact.id !== id),
              },
            }));
          }}
          onAdd={(contact: any) => {
            updateContactListQuery(({ contactList }: any) => ({
              contactList: {
                ...contactList,
                contacts: contactList.contacts.concat([contact]),
              },
            }));

            if (!list.contacts.length) {
              refetch();
            }
          }}
          onDeleteList={() => {
            refetch();
          }}
          {...list}
          initialName={list.name}
        />
      </div>
    </div>
  );
};

export default AccountContactListsPage;
