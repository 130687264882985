import { Divider } from '@material-ui/core';
import { LISTING_QUERY } from 'utils/gql';
import { Listing, ListingVariables } from 'generated/Listing';
import { Skeleton } from '@material-ui/lab';
import { useQuery } from '@apollo/client';
import Description from 'components/pages/EventReadPage/Description';
import ListingEventItem from 'components/pages/ListingPage/ListingEventItem';
import React from 'react';
import Title from 'components/pages/EventReadPage/Title/Title';

interface Props {
  id: string;
}

const EmbeddedListingPage = ({ id }: Props): JSX.Element => {
  const { loading, data, error } = useQuery<Listing, ListingVariables>(LISTING_QUERY, { variables: { id } });
  const listing = data?.listing;

  if (loading) {
    return (
      <div className="flex items-center justify-center">
        <Skeleton variant="rect" height={360} />
      </div>
    );
  }

  if (error || !listing) {
    return <div className="flex items-center justify-center">Error loading content</div>;
  }

  const upcomingEvents = listing.listingEvents.filter((listingEvent) => !listingEvent.event.isPast);

  return (
    <div className="p-8 bg-transparent">
      <Title title={listing.title} />
      <div className="grid grid-cols-4">
        <div className="col-span-4 lg:col-span-3">
          <Description description={listing.description} bodyClassName="text-lg" showLabel={false} />
        </div>
      </div>
      <div className="pb-2 mt-12 mb-6 text-lg font-bold border-b border-gray-300 border-solid">Upcoming events</div>
      <div className="space-y-12">
        {upcomingEvents.map((listingEvent, idx) => (
          <div key={idx} className="space-y-12">
            {!!idx && <Divider />}
            <ListingEventItem key={listingEvent.id} listingEvent={listingEvent} newWindow />
          </div>
        ))}
      </div>
      {!upcomingEvents.length && (
        <div className="flex items-center justify-center p-4 opacity-50">No upcoming events.</div>
      )}
    </div>
  );
};

// This is how you'll embed -- need to put this code as copy/paste in a dialog from the listing page (owners only)
/**
<iframe title="mixily-listings" src="http://localhost:3000/embed/listing/3096216266080855439" width="100%" height="533" frameBorder="0"></iframe>
 */

export default EmbeddedListingPage;
