import { Invite } from 'models/invite';
import { InviteTokenQuery_inviteToken } from 'generated/InviteTokenQuery';
import { RsvpStates } from 'generated/globalTypes';

export type InviteToken = Omit<InviteTokenQuery_inviteToken, '__typename'>;

export const parseInviteFromInviteToken = (inviteToken: InviteToken): Invite => ({
  id: undefined as any,
  rsvpName: inviteToken.userName,
  rsvpEmail: inviteToken.userEmail,
  isFollowing: inviteToken.isFollowing,
  state: RsvpStates.i,
});
