// Automatically registers all fields, and assumes you will be rendering the form with controlled
// inputs using on the widget onChange format.

// const { onChange } = useControlledForm({
//   formContext,
//   defaultValues,
//   validators,
//   clearMessages,
// });

import { FormContextValues } from 'react-hook-form';
import { OnChange, Validators } from 'components/forms/types';
import React, { useCallback } from 'react';

interface Props<T extends Record<string, any>> {
  formContext: FormContextValues<T>;
  defaultValues: T;
  validators?: Validators<T>;
  clearMessages: () => void;
}

type ControlledForm<T> = {
  onChange: OnChange<T>;
};

function useControlledForm<T extends Record<string, any>>(props: Props<T>): ControlledForm<T> {
  const { clearMessages, defaultValues, validators, formContext } = props;
  const { register, setValue } = formContext;

  // Register fields
  React.useEffect(() => {
    for (const fieldName in defaultValues) {
      register(
        {
          name: fieldName as any,
        },
        validators?.[fieldName]
      );
      setValue(fieldName, defaultValues[fieldName]);
    }
    // Ignores field changes because it's often easier for callers
    // to construct a new object every render. And react-hook-form
    // methods aren't expected to change, so we ignore all deps.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange: OnChange<T> = useCallback(
    (fieldName, value, clearMessage = true) => {
      if (clearMessage) {
        clearMessages();
      }
      setValue(fieldName as any, value);
    },
    // `setValue` isn't expected to change, so we ignore all deps.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    onChange,
  };
}

export default useControlledForm;
