import { Button, Link, Loading } from 'components/common';
import { Unauthenticated, Unknown } from 'models';
import { getURLParam } from 'utils/urls';
import { mixilyAPI } from 'utils/api';
import { reverse } from 'router';
import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import React, { useState } from 'react';
import useUser from 'utils/hooks/user/useUser';

export const OAuthAuthorizePage: React.FC = () => {
  const { user } = useUser();
  const client_id = getURLParam('client_id');
  const state = getURLParam('state');
  const redirect_uri = getURLParam('redirect_uri');
  const [loading, setLoading] = useState(false);

  const authorize = (client_id: string, state: string, redirect_uri: string) => {
    setLoading(true);
    mixilyAPI
      .authorizeZapier(client_id, state, redirect_uri)
      .then((res) => {
        if (res.success) {
          window.location.href = res.redirect_url;
        }
      })
      .finally(() => setLoading(false));
  };

  if (user === Unknown) {
    return null;
  }

  if (user === Unauthenticated || !client_id || !redirect_uri || !state) {
    return (
      <BaseLayout title="Authorize">
        Something went wrong connecting to your account. Please return to{' '}
        <Link href={reverse('account_login')}>login</Link> and try again.
      </BaseLayout>
    );
  }

  return (
    <>
      <BaseLayout title="Authorize">
        <div className="w-full mb-4">Allow Zapier to access your Mixily account?</div>
        <Button
          className="w-32"
          label={loading ? <Loading size="small" /> : 'Yes'}
          onClick={() => authorize(client_id, state, redirect_uri)}
        />
        <Button label="No" href={reverse('event_calendar')} color="secondary" className="w-32" />
      </BaseLayout>
    </>
  );
};

export default OAuthAuthorizePage;
