import * as F from 'shared/shared/Functional';
import { VirtualVenueSession, VirtualVenueSessionParticipant } from 'components/VirtualVenue/types';
import React, { useMemo } from 'react';
import moment from 'moment';

interface Props {
  sessions: VirtualVenueSession[];
}

const VirtualVenueCGuestLogDisplay = ({ sessions }: Props) => {
  const sessionsByDay = useMemo(() => {
    return F.groupBy(sessions, (m: VirtualVenueSession) => {
      const timestamp = moment(m.startTime);
      return timestamp.format(moment.HTML5_FMT.DATE);
    });
  }, [sessions]);

  const Session = (session: VirtualVenueSession) => {
    const displayTime = moment(session.startTime).format('h:mma');
    const activeParticipants = session.participants.filter(
      (participant: VirtualVenueSessionParticipant) => participant.duration > 120
    );
    return activeParticipants.length ? (
      <div key={session.startTime}>
        <div className="-ml-1 text-lg font-medium leading-none">
          <span className="mr-2 text-gray-400">&#8226;</span>
          {displayTime}
        </div>
        <ul className="pt-1 pb-2 pl-4 text-sm border-l border-gray-300 border-solid">
          {activeParticipants.map((participant: VirtualVenueSessionParticipant) => {
            const minutesActive = Math.round(participant.duration / 60);
            return (
              <li key={participant.startTime} className="my-2">
                <span className="font-medium">{participant.userName ?? 'Unknown'}</span>
                <span className="font-light">{` - ${minutesActive} minute${minutesActive !== 1 ? 's' : ''}`}</span>
              </li>
            );
          })}
        </ul>
      </div>
    ) : undefined;
  };

  return (
    <div className="flex flex-col">
      {Object.keys(sessionsByDay).map((date: string) => {
        const displayDate = moment(date).format('dddd, MMMM Do');
        const sessionsInDay = sessionsByDay[date].map(Session).filter(Boolean);
        return sessionsInDay.length ? (
          <div key={displayDate} className="mb-6">
            <div className="mb-4 text-xl">{displayDate}</div>
            <div className="ml-2">{sessionsInDay}</div>
          </div>
        ) : undefined;
      })}
    </div>
  );
};

export default VirtualVenueCGuestLogDisplay;
