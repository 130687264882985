import { constructQueryString, isAddress, isUrl } from 'shared/shared/utils';
import React from 'react';
interface Props {
  text: string;
}

const MAPS_BASE_SEARCH_URL = 'https://www.google.com/maps/search/';

const LinkifyAddress: React.FC<Props> = ({ text }) => {
  let url: string | null = null;

  if (isUrl(text)) {
    url = text;
  } else if (isAddress(text)) {
    // Construct URL based on https://developers.google.com/maps/documentation/urls/guide#search-action
    const queryParams = { api: '1', query: text };
    const qs = constructQueryString(queryParams);
    url = MAPS_BASE_SEARCH_URL + qs;
  }

  if (!url) {
    return <span>{text}</span>;
  }

  return (
    <a href={url} target="_blank" rel="noopener noreferrer" className="text-black underline">
      {text}
    </a>
  );
};

export default LinkifyAddress;
