import * as m from 'models';
import { Currency } from 'shared/shared/types';
import { EventFormContext } from '../../types';
import { LABEL_CLASS } from 'components/EventCreateUpdateForm/EventCreateUpdateForm/constants';
import DiscountCodesWidget from 'components/EventCreateUpdateForm/DiscountCodesWidget/DiscountCodesWidget';
import FeeCalculatorDialog from 'components/EventCreateUpdateForm/FeeCalculatorDialog/FeeCalculatorDialog';
import FieldLayout from 'components/common/FieldLayout/FieldLayout';
import QuillRichTextWidget from 'components/widgets/QuillRichTextWidget/QuillRichTextWidget';
import React, { useState } from 'react';
import TicketTypesWidget from 'components/EventCreateUpdateForm/TicketTypesWidget/TicketTypesWidget';

interface Props {
  className?: string;
  currency: Currency;
  event?: m.Event;
  mxForm: EventFormContext;
}

const TicketFields = ({ currency, mxForm, event, className }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const { formMethods, onChange, isSubmitting } = mxForm;
  const { watch } = formMethods;

  return (
    <div className={className}>
      <div>
        <div className={LABEL_CLASS}>Tickets</div>
        <FieldLayout error={formMethods.errors.ticketTypes as any}>
          <TicketTypesWidget
            onChange={(val: any) => onChange('ticketTypes', val)}
            value={watch('ticketTypes')}
            disabled={isSubmitting}
            attrs={{
              currency,
              eventIdsWithSoldTickets:
                event?.ticketTypes?.filter((tt) => !!tt.quantityClaimed).map((tt) => tt.id) || [],
              showFeeCalculator: () => setShowModal(true),
            }}
          />
        </FieldLayout>
      </div>

      <FeeCalculatorDialog isOpen={showModal} onClose={() => setShowModal(false)} />

      <FieldLayout
        className="mt-6"
        label={<div className={LABEL_CLASS}>Purchase Confirmation Message</div>}
        error={formMethods.errors.confirmedGuestMessage}
        helpText="Optional. For example, a secret event location, or full details of the schedule."
      >
        <QuillRichTextWidget
          onChange={(val) => onChange('confirmedGuestMessage', val)}
          value={watch('confirmedGuestMessage')}
          disabled={isSubmitting}
          attrs={{
            placeholder: 'Details to be shared with guests only after they buy a ticket.',
            imageSupport: false,
          }}
        />
      </FieldLayout>

      <FieldLayout
        className="mt-6"
        label={<div className={LABEL_CLASS}>Promo Codes</div>}
        error={formMethods.errors.discountCodes as any}
      >
        <DiscountCodesWidget
          onChange={(val) => onChange('discountCodes', val)}
          value={watch('discountCodes')}
          disabled={isSubmitting}
        />
      </FieldLayout>
    </div>
  );
};

export default TicketFields;
