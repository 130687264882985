import React, { ReactNode } from 'react';

import Container from 'components/layout/Container/Container';
import photoMarisa from './marisa.png';
import photoSarah from './sarah.png';
import photoTanya from './tanya.png';

interface TestimonialProps {
  alt: string;
  attribution: ReactNode;
  children: ReactNode;
  src: string;
}

const Testimonial = (props: TestimonialProps) => (
  <div className="w-full px-4 md:px-8">
    <img src={props.src} className="mx-auto mb-8 rounded-full w-60 lg:mb-16 " alt={props.alt} />
    <div className="relative lg:left-4">
      <div className="relative text-center lg:text-left">
        <div className={`text-2xl leading-normal italic text-black`}>{props.children}</div>
      </div>
      <div className={`mt-4 text-lg font-semibold text-center lg:mt-10 lg:text-left text-black`}>
        {props.attribution}
      </div>
    </div>
  </div>
);

const Testimonials3 = () => {
  return (
    <div className="w-full mx-auto">
      <Container className="px-4 mx-auto ">
        <div className="flex flex-col lg:flex-row">
          <div className="lg:w-1/3">
            <Testimonial
              src={photoSarah}
              alt="Sarah"
              attribution={
                <span>
                  Sarah
                  <br />
                  Founder of Reality Bites
                </span>
              }
            >
              “The design is important to us as a creative community.
              <br />I wanted something that looked good, and didn't
              <br />
              feel like a work call.”
            </Testimonial>
          </div>

          <div className="mt-24 lg:mt-0 lg:w-1/3">
            <Testimonial
              src={photoTanya}
              alt="Tanya"
              attribution={
                <span>
                  Tanya Hayles
                  <br />
                  Founder of Black Moms Connection
                </span>
              }
            >
              “I’m excited to have a pretty looking option to virtually gather. (I’m totally a judge
              <br />a book by the cover kind
              <br />
              of girl)”
            </Testimonial>
          </div>

          <div className="mt-24 lg:mt-0 lg:w-1/3">
            <Testimonial
              src={photoMarisa}
              alt="Marisa Lavalette"
              attribution={
                <span>
                  Marisa LaValette
                  <br />
                  Founder of Attune + Align
                </span>
              }
            >
              “I started 2021 super stressed out about organizing my events,
              <br />
              and now I'm excited
              <br />
              and relieved!“
            </Testimonial>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Testimonials3;
