import * as h from 'utils/helpers';
import { Link, Message } from 'components/common';
import { localStore } from 'utils/localstore';
import { reverse } from 'shared/shared/routing/mixily-routes';
import React from 'react';
import useAssertEventId from 'components/App/globalstate/useAssertEventId';
import useEvent from 'utils/hooks/event/useEvent';
import useUser from 'utils/hooks/user/useUser';

const MyTicketsInfo: React.FC = () => {
  const eventId = useAssertEventId();
  const { event } = useEvent();
  const { isAuthenticated } = useUser();
  const tickets = event!.myTickets;
  const ticketsFromRunLocal = event?.eventsFromRun?.map((e) => localStore.anonBoughtTickets.get(e.id));
  const boughtAnon = ticketsFromRunLocal
    ? ticketsFromRunLocal.find((o) => o !== undefined)
    : localStore.anonBoughtTickets.get(eventId);

  let orderId = undefined;
  if (typeof boughtAnon === 'string') {
    orderId = boughtAnon;
  } else if (event?.myTickets?.length) {
    orderId = event.myTickets[0].order.id;
  }

  if (boughtAnon || orderId) {
    return (
      <Message type="positive" compact className="mt-6">
        You're going to this event! Check your inbox for an order confirmation.{' '}
        {orderId && <Link href={reverse('order_read', { id: orderId })}>View details</Link>}
        {isAuthenticated && tickets && (
          <div className="mt-2">
            You have {tickets.length} {h.pluralize('ticket', tickets.length)}.
          </div>
        )}
      </Message>
    );
  } else {
    return null;
  }
};

export default MyTicketsInfo;
