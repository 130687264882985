import { ProcessingStates } from 'generated/globalTypes';
import { Skeleton } from '@material-ui/lab';
import { dailyAPI } from 'utils/api';
import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import Error404Page from 'components/pages/Error404Page';
import Error500Page from 'components/pages/Error500Page';
import React, { useCallback, useEffect } from 'react';
import VirtualVenueRecordingDisplay from 'components/pages/VirtualVenueRecordingPage/VirtualVenueRecordingDisplay';
import useRecordingManager from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/useRecordingManager';

import { DailyRecording } from 'components/pages/VirtualVenueRecordingsPage/VirtualVenueRecordingList';
import { ReadVirtualVenue, ReadVirtualVenueVariables } from 'generated/ReadVirtualVenue';
import { VIRTUAL_VENUE_QUERY } from 'utils/gql';
import { validate as isUUID } from 'uuid';
import { useAsync } from 'utils/hooks/useAsync';
import { useQuery } from '@apollo/client';
import moment from 'moment';

interface Props {
  venueId: string;
  recordingId: string;
}

const VirtualVenueRecordingPage = ({ venueId, recordingId }: Props) => {
  const isMixilyRecording = !isUUID(recordingId);

  // For mixily recordings
  const {
    fetchRecording,
    virtualVenueRecording: mixilyVirtualVenueRecording,
    virtualVenue: virtualVenueFromMixilyRecording,
    startPolling,
    stopPolling,
    loading: mixilyRecordingLoading,
    error: mixilyRecordingError,
  } = useRecordingManager();

  // For daily cloud recording
  const {
    isLoading: dailyRecordingLoading,
    isError: dailyRecordingError,
    data: dailyVirtualVenueRecording,
    run: fetchDailyRecording,
  } = useAsync<DailyRecording>();

  const { loading: venueLoading, error: venueError, data: virtualVenueData } = useQuery<
    ReadVirtualVenue,
    ReadVirtualVenueVariables
  >(VIRTUAL_VENUE_QUERY, {
    variables: { id: venueId },
    skip: isMixilyRecording,
  });

  useEffect(() => {
    if (isMixilyRecording) {
      fetchRecording(venueId, recordingId);
    } else {
      fetchDailyRecording(dailyAPI.getRecording(venueId, recordingId));
    }
  }, [fetchDailyRecording, fetchRecording, isMixilyRecording, recordingId, venueId]);

  const virtualVenue = virtualVenueData?.virtualVenue ?? virtualVenueFromMixilyRecording;

  const recordingStatus = isMixilyRecording ? mixilyVirtualVenueRecording?.recordingStatus : undefined;
  const compositingStatus = isMixilyRecording
    ? mixilyVirtualVenueRecording?.compositingStatus
    : dailyVirtualVenueRecording?.status === 'finished'
    ? ProcessingStates.DONE
    : ProcessingStates.IN_PROGRESS;
  const extRecordingId = isMixilyRecording ? mixilyVirtualVenueRecording?.extRecordingId : recordingId;
  const extCompositingId = isMixilyRecording ? mixilyVirtualVenueRecording?.extCompositingId : undefined;

  const fetchAccessLink = useCallback(() => {
    if (isMixilyRecording) {
      return dailyAPI.getCompositeRecordingAccessLink(extRecordingId!, extCompositingId!);
    } else {
      return dailyAPI.getRecordingAccessLink(recordingId);
    }
  }, [extCompositingId, extRecordingId, isMixilyRecording, recordingId]);

  useEffect(() => {
    if (!isMixilyRecording || !recordingStatus || !compositingStatus) {
      return;
    }
    if (
      [ProcessingStates.DONE, ProcessingStates.FAILED].includes(recordingStatus) &&
      [ProcessingStates.DONE, ProcessingStates.FAILED].includes(compositingStatus)
    ) {
      stopPolling && stopPolling();
    } else {
      startPolling && startPolling(1000);
    }
  }, [recordingStatus, compositingStatus, startPolling, stopPolling, isMixilyRecording]);

  const isLoading = mixilyRecordingLoading || venueLoading || dailyRecordingLoading;
  const isError = mixilyRecordingError || venueError || dailyRecordingError;
  const virtualVenueTitle = virtualVenue?.title ?? 'Your Virtual Venue';

  if (isLoading || (!isLoading && !isError && !(mixilyVirtualVenueRecording || dailyVirtualVenueRecording))) {
    return (
      <BaseLayout title="Loading...">
        <Skeleton variant="rect" height={360} />
      </BaseLayout>
    );
  }

  if (isError) {
    return <Error500Page />;
  }

  if (!virtualVenue || !(mixilyVirtualVenueRecording ?? dailyVirtualVenueRecording)) {
    return <Error404Page />;
  }

  const title = `Recording from ${virtualVenueTitle}`;
  const createdAt = isMixilyRecording
    ? mixilyVirtualVenueRecording?.createdAt
    : moment.unix(dailyVirtualVenueRecording?.start_ts ?? 0).format();
  const duration = isMixilyRecording ? mixilyVirtualVenueRecording?.duration : dailyVirtualVenueRecording?.duration;
  const maxParticipants = isMixilyRecording
    ? mixilyVirtualVenueRecording?.maxParticipants
    : dailyVirtualVenueRecording?.max_participants;

  return (
    <BaseLayout title={title} hideTitle>
      <VirtualVenueRecordingDisplay
        isMixilyRecording={isMixilyRecording}
        title={virtualVenueTitle}
        createdAt={createdAt!}
        duration={duration!}
        maxParticipants={maxParticipants!}
        compositingStatus={compositingStatus!}
        fetchAccessLink={fetchAccessLink}
      />
    </BaseLayout>
  );
};

export default VirtualVenueRecordingPage;
