import { colorWithAlpha } from 'utils/colors';
import { getURLParam } from 'utils/urls';
import { useVirtualVenueTheme } from 'components/VirtualVenue/contexts/VirtualVenueThemeContext';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './ScreenLayout.module.scss';

interface Props {
  children: React.ReactNode;
}

const ScreenLayout = (props: Props) => {
  const hasChildren = !!props.children;
  const [fadeOut, setFadeOut] = useState(true);
  const [render, setRender] = useState(hasChildren);
  const theme = useVirtualVenueTheme();
  const useOverlayStyling = !getURLParam('lobbyFullPage');

  useEffect(() => {
    if (!hasChildren) {
      setFadeOut(true);
      setTimeout(() => setRender(false), 500);
    } else {
      setRender(true);
      // setTimeout needed to initially render the element with 0 opacity
      // for the CSS transition to activate
      setTimeout(() => setFadeOut(false), 0);
    }
  }, [hasChildren]);

  if (!render) {
    return null;
  }

  return (
    <div
      className={classNames(
        'absolute w-full h-full top-0 left-0 flex flex-col',
        'transition-opacity duration-500 ease-in-out',
        'justify-center items-center',
        {
          [styles.BackgroundBlur]: useOverlayStyling,
        }
      )}
      style={{
        zIndex: 10,
        background: useOverlayStyling ? 'rgba(0, 0, 0, 0.0)' : 'var(--v-theme-bg-color)',
        opacity: fadeOut ? 0 : 1,
      }}
    >
      <div
        className={classNames('relative m-auto p-6 flex flex-col justify-evenly items-center', {
          'xs:w-9/12 sm:w-8/12 md:w-7/12 shadow-xl sm:min-h-3/4': useOverlayStyling,
          'w-full': !useOverlayStyling,
        })}
        style={{
          opacity: 1,
          zIndex: 1,
          background: useOverlayStyling ? 'var(--v-theme-bg-color)' : 'transparent',
          border: useOverlayStyling ? `1px solid ${colorWithAlpha(theme.titleTextColor, 0.35)}` : 'none',
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

export default ScreenLayout;
