import { CircularProgress, IconButton, InputAdornment, TextField, useTheme } from '@material-ui/core';
import { DISCOUNT_CODE_QUERY } from 'utils/gql';
import { DiscountCode } from 'models';
import { DiscountCodeQuery } from 'generated/DiscountCodeQuery';
import { FIELDS } from 'shared/shared/json';
import { TextLink } from 'components/common';
import { useApolloClient } from '@apollo/client';
import ArrowIcon from '@material-ui/icons/ArrowForward';
import FieldLabel from 'components/common/OldFieldLabel/OldFieldLabel';
import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './DiscountCodeForm.module.scss';
import useEventId from 'components/App/globalstate/useEventId';

interface Props {
  discountCode?: DiscountCode;
  setDiscountCode: (discountCode: DiscountCode) => void;
  className?: string;
}

const DiscountCodeForm = ({ discountCode, setDiscountCode, className }: Props) => {
  const theme = useTheme();
  const eventId = useEventId()!;
  const [open, setOpen] = useState<boolean>(false);
  const [code, setCode] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const client = useApolloClient();

  const appliedMessage = `Promo code ${discountCode?.code.toLocaleUpperCase()} applied!`;

  async function onSubmit() {
    setLoading(true);
    const { data } = await client.query<DiscountCodeQuery>({
      query: DISCOUNT_CODE_QUERY,
      variables: { code, eventId },
    });

    if (data && data.discountCode) {
      setDiscountCode(data.discountCode);
      setOpen(false);
    } else {
      setError('Invalid code, please try again.');
    }

    setLoading(false);
  }

  return (
    <div className={classNames(styles.DiscountCodeForm, { [styles.show]: open }, className)}>
      {!open && (
        <TextLink onClick={() => setOpen(true)} className="text-sm font-semibold leading-none">
          {discountCode ? appliedMessage : 'I have a promo code'}
        </TextLink>
      )}
      {open && (
        <>
          <FieldLabel label="Promo Code" className={styles.label} />
          <TextField
            fullWidth
            variant="outlined"
            placeholder="CODE123"
            value={code}
            error={!!error}
            className={styles.input}
            onChange={(e) => {
              setCode(e.target.value);
              setError('');
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                e.preventDefault();
                e.stopPropagation();
                onSubmit();
                return false;
              }
            }}
            helperText={error || (discountCode ? appliedMessage : undefined)}
            InputProps={{
              autoFocus: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={onSubmit}>
                    {!loading && <ArrowIcon htmlColor={error ? theme.palette.error.main : 'default'} />}
                    {loading && <CircularProgress size={20} color="primary" />}
                  </IconButton>
                </InputAdornment>
              ),
              inputProps: {
                maxlength: FIELDS.promoCode.maxLength,
                style: {
                  textTransform: 'uppercase',
                },
              },
            }}
          />
        </>
      )}
    </div>
  );
};

export default DiscountCodeForm;
