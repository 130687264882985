// Prefer `Button2` for new components

import Link from 'components/Link';
import React, { CSSProperties } from 'react';
import classNames from 'classnames';
import styles from './Button.module.scss';

export type ButtonColor = 'primary' | 'secondary' | 'red' | 'custom';

export interface ButtonProps {
  className?: string;
  color?: ButtonColor; // defaults to primary
  disabled?: boolean; // defaults to false
  handleClick?: (ev: any) => void;
  href?: string; // for link buttons
  icon?: React.ReactNode;
  label: React.ReactNode;
  children?: React.ReactNode;
  name?: string;
  onClick?: (ev: any) => void;
  selected?: boolean; // Can activate special 'selected' style
  small?: boolean;
  submit?: boolean; // defaults to false
  thin?: boolean;
  style?: CSSProperties;
}

const Button = (props: ButtonProps) => {
  const { color = 'primary', disabled = false, handleClick, href, icon, label, name, onClick, submit = false } = props;
  const type = submit ? 'submit' : 'button';
  const className = classNames(
    styles.Button,
    styles[color],
    props.small && styles.small,
    props.thin && styles.thin,
    props.selected && styles.selected,
    props.className
  );

  const isLink = !!href;

  if (isLink) {
    return (
      <Link className={classNames(styles.button, className)} href={href || ''} style={props.style}>
        {icon && <span className={styles.icon}>{icon}</span>}
        {label}
      </Link>
    );
  }

  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick || handleClick}
      className={className}
      name={name}
      style={props.style}
    >
      {icon && <span className={styles.icon}>{icon}</span>}
      {label}
    </button>
  );
};

export default Button;
