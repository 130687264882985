import * as m from 'models';
import { addSnackbarMessage } from 'utils/eventEmitter';
import AccessControl from 'components/pages/VenuePage/AccessControl/AccessControl';
import React, { useEffect, useState } from 'react';
import TimeTil from 'components/pages/VenuePage/TimeTil/TimeTil';
import classNames from 'classnames';
import config from 'config';
import moment from 'moment';

interface EventVenueFragment {
  id: string;
  hostedByText?: string | undefined;
  startTime: moment.Moment | null;
  title: string;
  themePic?: string | undefined;
  bgColor?: string;
  bgNoBackdrop?: boolean;
  location: string;
  partOfRunId?: string | undefined;
  owner?: m.Person | undefined;
  locationReleaseTimeDelta?: moment.Duration | undefined;
  virtualVenueContent?: string | undefined;
  appendAccessTokenToUrl?: boolean;
}

interface Props {
  event: EventVenueFragment;
  accessToken: string;
  ticketTypes?: string[];
}

const VenueContent = ({ event, accessToken, ticketTypes }: Props) => {
  const [eventStarted, setEventStarted] = useState<boolean>(
    moment(event.startTime ?? undefined).isSameOrBefore(moment())
  );
  const [showContent, setShowContent] = useState<boolean>(false);
  const [contentLoaded, setContentLoaded] = useState<boolean>(false);
  const showContentTime = event.startTime ? moment(event.startTime) : null;
  const [showTimer, setShowTimer] = useState<boolean>(!eventStarted);

  const TIMER_FADE_OUT_SECONDS_DELAY = 10;
  const isManualCinema =
    event?.partOfRunId === '4667430828119875605' ||
    event?.id === '6741542647425792531' ||
    event?.locationReleaseTimeDelta?.asSeconds() === 42; // sentinel value for testing

  let preContent = (
    <div>
      <div className="text-cool-gray-400">Starting in</div>
      <div className="text-5xl leading-none">
        <TimeTil
          time={event.startTime!}
          onCompletion={() => {
            setEventStarted(true);
            setShowContent(true);
            setTimeout(() => {
              setShowTimer(false);
            }, TIMER_FADE_OUT_SECONDS_DELAY * 1000);
          }}
        />
      </div>
    </div>
  );
  let noAccessContent = (errorMessage?: string) => (
    <>
      <div className="mt-12 text-3xl text-gray-700">You do not have access to this event.</div>
      <div className="mt-6 text-xl text-gray-700">
        {errorMessage || 'Please use the link provided in the order confirmation email.'}
      </div>
    </>
  );

  let contentOverride;
  const timerOverlay = (
    <div
      className={classNames([
        'bottom-3 left-3 text-left',
        'xs:bottom-3 xs:left-5',
        'sm:bottom-5 sm:left-6',
        'md:bottom-6 md:left-8',
        'leading-none absolute',
        'transition-opacity ease-in-out',
      ])}
      style={{
        color: '#edead1',
        fontSize: 'max(.8rem, min(2.25vw, 1.5rem))',
        transitionDuration: `${TIMER_FADE_OUT_SECONDS_DELAY}s`,
        opacity: eventStarted ? '0' : '1',
      }}
    >
      <div className="mb-4 tracking-widest">{'YOUR SHOW BEGINS IN'}</div>
      <div
        style={{
          fontSize: 'min(3.75vw, 2.2rem)',
        }}
      >
        <TimeTil
          time={event.startTime!}
          completedMessage="a few moments..."
          onCompletion={() => {
            setEventStarted(true);
            setTimeout(() => {
              setShowTimer(false);
            }, TIMER_FADE_OUT_SECONDS_DELAY * 1000);
          }}
        />
      </div>
    </div>
  );

  if (isManualCinema) {
    const contentOverrideMap: {
      [key: string]: string;
    } = config.manualCinemaOverrides;
    const contentOverrides = ticketTypes
      ? ticketTypes.map((ticketType) => contentOverrideMap[ticketType]).filter((override) => !!override)
      : [];

    // We could have different contents in a single order, giving preference to the deaf/blind override.
    // The deaf+blind in a single order cannot work properly
    contentOverride = eventStarted && contentOverrides.length > 0 ? contentOverrides[0] : undefined;

    if (!contentOverride) {
      // Switch to virtual venue content 25 minutes before the official event start time
      showContentTime?.subtract(25, 'minutes');
    }

    preContent = (
      <div className="relative">
        <div>
          <img
            alt="Manual Cinema Pre Show"
            src="https://prismic-io.s3.amazonaws.com/mixily/9623fb0f-707d-4353-a1e6-f457f4f3b3ce_Waiting+Room+v7_WELCOME.svg"
            onLoad={() => setContentLoaded(true)}
          ></img>
          {/* this timer element triggers early content showing for manual cinema */}
          <div className="hidden">
            <TimeTil
              time={showContentTime!}
              onCompletion={() => {
                setShowContent(true);
              }}
            />
          </div>
        </div>
        {contentLoaded && timerOverlay}
      </div>
    );

    noAccessContent = (errorMessage?: string) => (
      <div className="relative">
        <img
          alt="Manual Cinema Access Error"
          src="https://prismic-io.s3.amazonaws.com/mixily/8d326447-b171-4c77-aef8-666a4b625894_Waiting_Room_v6_PROBLEM.svg"
          onLoad={() => setContentLoaded(true)}
        ></img>
        {contentLoaded && (
          <div
            className={classNames([
              'bottom-1 left-3',
              'xs:left-5',
              'sm:bottom-3 sm:left-6',
              'md:left-8',
              'mt-6 flex items-center leading-none absolute',
            ])}
            style={{
              color: '#edead1',
              fontSize: 'max(.8rem, min(1.75vw, 1.40rem))',
            }}
          >
            <svg
              className="w-6 h-6 text-yellow-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
            <span className="ml-1">{errorMessage}</span>
          </div>
        )}
      </div>
    );
  }

  useEffect(() => {
    if (isManualCinema && showContent) {
      setTimeout(() => {
        addSnackbarMessage("Press 'Play ▶' to start the stream", 'info');
      }, 3000);
    }
  }, [isManualCinema, showContent]);

  return (
    <AccessControl
      accessToken={accessToken}
      eventId={event.id}
      skipTokenCheck={!event.appendAccessTokenToUrl}
      renderNoAccess={noAccessContent}
    >
      {event.startTime && !showContent ? (
        preContent
      ) : (
        <div className="relative w-full h-full">
          <div
            className="w-full h-full"
            dangerouslySetInnerHTML={{
              __html:
                contentOverride ??
                (event.virtualVenueContent ||
                  '<div class="m-auto mt-12 text-3xl text-gray-700">Virtual Venue Closed</div>'),
            }}
          ></div>
          {isManualCinema && showTimer && timerOverlay}
        </div>
      )}
    </AccessControl>
  );
};

export default VenueContent;
