// Input for integers. Passes some default formatting options to `NumberFormatInput`

import NumberFormatInput, {
  Props as NumberFormatInputProps,
} from 'components/common/NumberFormatInput/NumberFormatInput';
import React from 'react';

export type Props = NumberFormatInputProps;

const IntegerInput = (props: Props, ref: Props['ref']) => {
  const { layoutProps, numberFormatProps, ...inputProps } = props;

  const integerNumberFormatProps = {
    thousandSeparator: true,
    allowNegative: false,
    decimalScale: 0,
    ...props.numberFormatProps,
  };

  return (
    <NumberFormatInput
      layoutProps={props.layoutProps}
      numberFormatProps={integerNumberFormatProps}
      {...inputProps}
      ref={ref}
    />
  );
};

export default React.forwardRef(IntegerInput);
