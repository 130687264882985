import { getNumCols } from 'shared/shared/utils';
import { useMemo } from 'react';

const MARGIN = 4; // px
const ASPECT_RATIO = 16 / 9;

type SpeakerLayoutConfig = {
  speakerSize: {
    width: number;
    height: number;
  };
  others: {
    perPage: number;
  };
};

const getLayoutForCols = (
  containerWidth: number,
  containerHeight: number,
  numSpeakers?: number
): SpeakerLayoutConfig => {
  const numRows = numSpeakers ? Math.ceil(numSpeakers / getNumCols(numSpeakers)) + 1 : 2;
  const tilesPerPage = containerWidth > 1500 ? 8 : containerWidth > 1200 ? 6 : 5;

  // Calculate the height of non speaker cells
  const wMarginSpace = MARGIN * (tilesPerPage - 1);
  const availableWidth = containerWidth - wMarginSpace;
  const targetOthersWidth = Math.floor(availableWidth / tilesPerPage);
  const targetOthersHeight = Math.floor(targetOthersWidth / ASPECT_RATIO);

  // Calculate the remaining available height
  const hMarginSpace = MARGIN * (numRows - 1);
  const targetSpeakerHeight = containerHeight - targetOthersHeight - hMarginSpace;

  // How big can the speaker cell get?
  const targetSpeakerWidth = targetSpeakerHeight * ASPECT_RATIO;
  return {
    speakerSize: {
      width: targetSpeakerWidth,
      height: targetSpeakerHeight,
    },
    others: {
      perPage: tilesPerPage,
    },
  };
};

const useSpeakerLayout = (
  containerWidth: number,
  containerHeight: number,
  numSpeakers?: number
): SpeakerLayoutConfig => {
  const layout = useMemo(() => getLayoutForCols(containerWidth, containerHeight, numSpeakers), [
    containerHeight,
    containerWidth,
    numSpeakers,
  ]);
  return layout;
};

export default useSpeakerLayout;
