import * as F from 'shared/shared/Functional';
import { ACCOUNT_LOGIN_AS_MUTATION } from 'utils/gql';
import { AccountLogin, AccountLoginVariables } from 'generated/AccountLogin';
import { reverse } from 'router';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';

import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import FieldLayout from 'components/common/FieldLayout/FieldLayout';
import Input2 from 'components/common/Input2/Input2';
import React from 'react';
import useMutationFormHelpers from 'components/forms/useMutationFormHelpers';
import useUserChangeContext from 'components/App/useUserChangeContext';

type FormT = { email: string };

const AccountLoginAsPage = () => {
  const [accountLogin, { loading }] = useMutation<AccountLogin, AccountLoginVariables>(ACCOUNT_LOGIN_AS_MUTATION);
  const { login } = useUserChangeContext();
  const formContext = useForm<FormT>();
  const {
    Form,
    SubmitButton,
    FormLevelMessages,
    FormStateContextProvider,
    onSubmit,
    isSubmitting,
  } = useMutationFormHelpers<FormT, AccountLogin, AccountLoginVariables>({
    formContext,
    formToVariables: F.identity,
    mutation: accountLogin,
    resultKey: 'accountLoginAs',
    onSuccess: (val) => {
      const apitoken = val.accountLoginAs.apitoken;
      login(apitoken, true, reverse('home'));
    },
  });
  const { errors, register } = formContext;

  return (
    <BaseLayout title="Login As">
      <FormStateContextProvider onSubmit={onSubmit} isSubmitting={isSubmitting} formContext={formContext}>
        <Form>
          <FieldLayout label="Email address" error={errors.email}>
            <Input2
              name="email"
              disabled={loading}
              ref={register({
                required: true,
              })}
              placeholder="user@example.com"
            />
          </FieldLayout>
          <SubmitButton label="Log in" submittingLabel="Logging in..." className="mt-9" />
        </Form>
      </FormStateContextProvider>

      <FormLevelMessages className="mt-4" />
    </BaseLayout>
  );
};

export default AccountLoginAsPage;
