import { Link, Loading } from 'components/common';
import { URLContext } from 'components/RoutingProvider';
import { VIRTUAL_VENUE_QUERY, VIRTUAL_VENUE_SET_CLOSED_MUTATION } from 'utils/gql';
import { VenueRole } from 'generated/globalTypes';
import { VirtualVenueSetClosed, VirtualVenueSetClosedVariables } from 'generated/VirtualVenueSetClosed';
import { getURLParam } from 'utils/urls';
import { useMutation } from '@apollo/client';
import { useVirtualVenue } from 'components/VirtualVenue/contexts/VirtualVenueContext';
import React, { useCallback, useContext, useState } from 'react';
import StartButton from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/screens/VirtualVenueLobby/StartButton/StartButton';
import VirtualVenueLogo from 'components/VirtualVenue/VirtualVenueDisplay/layouts/VirtualVenueDisplayLayout/VirtualVenueHeader/VirtualVenueLogo';

interface Props {
  title: string;
  logo?: string;
  logoFilter?: string;
  awayMessage?: string;
  virtualVenueId: string;
  ownerId: string;
}

const VirtualVenueLobby = (props: Props) => {
  const { title, logo, logoFilter, awayMessage, virtualVenueId, ownerId } = props;

  const venue = useVirtualVenue();
  const [setVirtualVenueOpen] = useMutation<VirtualVenueSetClosed, VirtualVenueSetClosedVariables>(
    VIRTUAL_VENUE_SET_CLOSED_MUTATION,
    {
      refetchQueries: [{ query: VIRTUAL_VENUE_QUERY, variables: { id: virtualVenueId, userId: ownerId } }],
    }
  );
  const [isReopening, setIsReopening] = useState(false);
  const openVirtualVenue = useCallback(() => {
    setIsReopening(true);
    setVirtualVenueOpen({ variables: { isClosed: false, awayMessage: '', id: virtualVenueId } }).then((res) => {
      setIsReopening(false);
    });
  }, [setVirtualVenueOpen, virtualVenueId]);
  const { authModalStateMutatorSet } = useContext(URLContext);

  const useOverlayStyling = !getURLParam('lobbyFullPage');
  return (
    <>
      <div className="mx-auto mb-3" style={{ maxWidth: useOverlayStyling ? '33%' : '20%' }}>
        <VirtualVenueLogo logo={logo} logoFilter={logoFilter} />
      </div>
      <div
        className="mb-4 text-center xs:text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl"
        style={{ color: 'var(--v-theme-title-text-color)', maxWidth: '85%' }}
      >
        {title}
      </div>
      <>
        <div
          className="max-w-md mb-4 text-white whitespace-pre-wrap md:mb-6 lg:mb-10 text-xxl"
          style={{ color: 'var(--v-theme-title-text-color)' }}
        >
          {awayMessage}
        </div>
        {venue.myAccess.role === VenueRole.OWNER ? (
          <StartButton
            className="text-lg rounded-full xs:h-8 sm:h-10 lg:text-xl xl:text-2xl"
            disabled={false}
            onClick={() => {
              openVirtualVenue();
            }}
          >
            {isReopening ? <Loading size={'tiny'} /> : 'Click to Reopen'}
          </StartButton>
        ) : (
          <Link
            href="#"
            className="absolute text-xs bottom-4"
            style={{ color: 'var(--v-theme-title-text-color)', opacity: '60%' }}
            onClick={() => {
              authModalStateMutatorSet.openLoginModal();
            }}
          >
            Are you the host?
          </Link>
        )}
      </>
    </>
  );
};
export default VirtualVenueLobby;
