import { addSnackbarMessage } from 'utils/eventEmitter';
import { reverse } from 'shared/shared/routing/mixily-routes';
import ActionButton from 'components/common/ActionButton/ActionButton';
import ConfirmDelete from 'components/ConfirmDelete/ConfirmDelete';
import Dropdown from 'components/common/Dropdown/Dropdown';
import IconSvg from 'components/common/IconSvg/IconSvg';
import React, { useState } from 'react';

interface Props {
  event: {
    id: string;
  };
}

const EventCalendarPageDropdown = ({ event }: Props) => {
  const { id } = event;
  const [deleting, setDeleting] = useState(false);
  const items = [
    { text: 'Edit', href: reverse('event_update', { id }) },
    { text: 'Duplicate', href: reverse('event_duplicate', { id }) },
    { text: 'Delete', onClick: () => setDeleting(true) },
  ];
  return (
    <Dropdown align="right" items={items} className="text-base" noLink>
      <ActionButton className="bg-gray-300 rounded-full">
        <IconSvg name="dotsVertical" className="w-5 h-5 text-gray-600" />
      </ActionButton>
      <ConfirmDelete
        eventId={id}
        open={deleting}
        onCancel={() => {
          setDeleting(false);
        }}
        onSuccess={() => {
          addSnackbarMessage('Event deleted', 'info');
        }}
      />
    </Dropdown>
  );
};

export default EventCalendarPageDropdown;
