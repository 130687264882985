import { RoleType } from 'generated/globalTypes';
import { TextLink } from 'components/common';
import CloseIcon from '@material-ui/icons/Close';
import Pill from 'components/common/Pill';
import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './RoleItem.module.scss';

interface Props {
  label?: string;
  role?: RoleType;
  message?: string;
  onRemove?: () => void;
  onResend?: () => void;
}

const RoleItem = ({ label, role, message, onRemove, onResend }: Props) => {
  const [resent, setResent] = useState<boolean>(false);
  return (
    <div className={styles.container}>
      <div className={classNames(styles.header, { [styles.noMessage]: !message })}>
        <div className={styles.label}>{label}</div>
        <div className={styles.role}>
          <Pill content={role === RoleType.host ? 'Host' : 'Owner'} />
        </div>
        <div className={styles.remove}>
          {onRemove && (
            <div className={styles.removeButton} onClick={onRemove}>
              <CloseIcon fontSize="small" />
            </div>
          )}
        </div>
      </div>
      {message && (
        <div className={styles.footer}>
          <div className={styles.message}>
            {message}{' '}
            {onResend &&
              (resent ? (
                '— resent!'
              ) : (
                <TextLink
                  className={styles.resendLink}
                  onClick={() => {
                    setResent(true);
                    onResend();
                  }}
                >
                  resend invite
                </TextLink>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default RoleItem;
