import { withClickOutside } from 'utils/hoc';
import DropdownItem, { Props as DropdownItemProps } from 'components/common/Dropdown/DropdownItem';
import React from 'react';
import classNames from 'classnames';
import styles from './Dropdown.module.scss';

interface Props {
  align?: string;
  children: React.ReactNode;
  className?: string;
  items: DropdownItemProps[];
  noLink?: boolean;
}

interface State {
  open: boolean;
}

class Dropdown extends React.Component<Props, State> {
  state = {
    open: false,
  };

  private readonly open = () => {
    const { items } = this.props;
    const { open } = this.state;
    if (open || !items.length) return;

    this.setState({ open: true });
  };

  private readonly close = () => {
    const { open } = this.state;
    if (!open) return;

    this.setState({ open: false });
  };

  private readonly onClickOutside = () => {
    this.close();
  };

  private readonly onClick = (e: React.MouseEvent<any>) => {
    e.preventDefault();
    e.stopPropagation();

    const { open } = this.state;

    if (open) {
      this.close();
    } else {
      this.open();
    }
  };

  private readonly onItemClick = (onClick: any) => (e: React.MouseEvent<any>) => {
    e.stopPropagation();

    this.close();

    if (onClick) {
      // Only preventDefault here. This allows us to use Dropdown menus *on top of* bigger
      // link elements.
      e.preventDefault();
      onClick(e);
    }
  };

  private readonly onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      this.onClick(e);
    }
  };

  render() {
    const { className, items, children, align } = this.props;
    const { open } = this.state;

    const cn = classNames(styles.Dropdown, className, {
      [styles[`align-${align}`]]: !!align,
    });

    return (
      <div className={cn}>
        <div onClick={this.onClick} onKeyDown={this.onKeyDown} tabIndex={0}>
          {children}
        </div>
        {open && (
          <div className={styles.modal}>
            {items.map(({ onClick, ...rest }, i) => (
              <DropdownItem onClick={this.onItemClick(onClick)} {...rest} key={i} />
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default withClickOutside(Dropdown);
