import { useVirtualVenueDisplay } from 'components/VirtualVenue/contexts/VirtualVenueDisplayContext';
import React from 'react';
import classNames from 'classnames';
import styles from './TrayButton.module.scss';

interface Props {
  enabled: boolean;
  turnedOff?: boolean;
  open?: boolean;
  children: React.ReactNode;
  size?: 'normal' | 'wide';
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  label: string;
}

export default function TrayButton(props: Props) {
  const { enabled, turnedOff, onClick, open, children, label } = props;
  const { isLiveMode } = useVirtualVenueDisplay();
  const cls = classNames(
    'h-full border-none bg-transparent px-2.5 md:px-4 relative',
    enabled ? 'cursor-pointer' : 'cursor-default',
    // { 'w-11': size === 'normal', 'w-15': size === 'wide' },
    open && styles.open,
    styles.TrayButton
  );
  const iconCls = classNames(
    'transition-opacity duration-300 ease-out',
    isLiveMode && {
      'opacity-20': !enabled,
      'opacity-50': enabled && turnedOff,
    },
    styles.Icon
  );
  return (
    // Disable button in editor mode
    <button className={cls} onClick={onClick} disabled={!isLiveMode || !enabled}>
      <div className={iconCls}>{children}</div>
      <div className={classNames('group-hover-fade-in', styles.ButtonLabel)}>{label}</div>
    </button>
  );
}
