import React from 'react';

import Input from 'components/common/Input/Input';
import TextArea from 'components/common/TextArea';

export type onChangeT<T = any> = (value: T) => void;

export interface WidgetProps<T = any, U = any> {
  value: T;
  disabled: boolean;
  onChange: onChangeT<T>;
  id?: string;
  onOpenOrClose?: () => void;
  className?: string;

  // widget-specific arguments that get passed down
  attrs?: U;
}

export type Widget<T = any, U = any> = React.ComponentType<WidgetProps<T, U>>;

// Wrap native-style input elements to use our onChange(value) format
function makeWidget<T>(InnerInput: string | React.ComponentType, extraProps = {}): Widget<T> {
  const newWidget = (props: WidgetProps<T>) => {
    const { onChange, attrs = {}, ...rest } = props;

    const newOnChange: onChangeT<T> = (event: any) => onChange(event.target.value);

    const newProps = {
      ...rest,
      ...attrs,
      ...extraProps,
      onChange: newOnChange,
    };
    return <InnerInput {...(newProps as any)} />;
  };
  return newWidget;
}

export const TextWidget = makeWidget<string>(Input);
export const TextAreaWidget = makeWidget<string>(TextArea);
