import { EVENT_RSVP_BY_HOST_MUTATION } from 'utils/gql';
import { EventManualRsvpByHost, EventManualRsvpByHostVariables } from 'generated/EventManualRsvpByHost';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';

import Dialog2, { actionStyles } from 'components/common/Dialog2/Dialog2';
import FieldLayout from 'components/common/FieldLayout/FieldLayout';
import Input2 from 'components/common/Input2/Input2';
import React from 'react';
import isEmail from 'validator/lib/isEmail';
import useEventId from 'components/App/globalstate/useEventId';
import useMutationFormHelpers from 'components/forms/useMutationFormHelpers';

interface Props {
  isOpen: boolean;
  onSave: (data: FormT) => void;
  onClose: () => void;
}

type FormT = {
  name: string;
  email: string;
};

const ManualRSVPModal = ({ isOpen, onSave, onClose }: Props) => {
  const [executeMutation] = useMutation<EventManualRsvpByHost, EventManualRsvpByHostVariables>(
    EVENT_RSVP_BY_HOST_MUTATION
  );
  const formContext = useForm<FormT>({ defaultValues: { name: '', email: '' } });
  const { register, errors } = formContext;
  const eventId = useEventId();

  const {
    Form,
    SubmitButton,
    FormLevelMessages,
    isSubmitting,
    FormStateContextProvider,
    onSubmit,
  } = useMutationFormHelpers<FormT, EventManualRsvpByHost, EventManualRsvpByHostVariables>({
    formContext,
    formToVariables: (formData) => ({
      ...formData,
      eventId: eventId!,
    }),
    mutation: executeMutation,
    resultKey: 'eventManualRsvpByHost',
    onSuccess: (result) => {
      const { invite } = result.eventManualRsvpByHost;
      onSave({
        name: invite!.originalName,
        email: invite!.originalEmail,
      });
    },
  });

  return (
    <Dialog2 isOpen={isOpen} onClose={onClose} title="Manually Add RSVP">
      <FormStateContextProvider onSubmit={onSubmit} isSubmitting={isSubmitting} formContext={formContext}>
        <Form>
          <FieldLayout label="Guest name" error={errors.name}>
            <Input2 name="name" disabled={isSubmitting} ref={register({ required: true })} />
          </FieldLayout>

          <FieldLayout label="Email address" error={errors.email} className="mt-6">
            <Input2
              name="email"
              type="email"
              ref={register({
                required: true,
                validate: (value: string) => (!isEmail(value) ? 'Please enter a valid email address' : true),
              })}
              disabled={isSubmitting}
              placeholder="you@example.com"
            />
          </FieldLayout>

          <FormLevelMessages className="mt-6" />

          <div className={actionStyles}>
            <SubmitButton label="Add to guest list" submittingLabel="Adding..." />
          </div>
        </Form>
      </FormStateContextProvider>
    </Dialog2>
  );
};

export default ManualRSVPModal;
