import { EventLayoutContext } from 'components/layout/EventLayout/EventLayout';
import { reverse } from 'shared/shared/routing/mixily-routes';
import Container from 'components/layout/Container/Container';
import DisableInPreview from 'components/DisableInPreview/DisableInPreview';
import FooterEmailSignup from 'components/layout/Footer/FooterEmailSignup';
import FooterLink from 'components/layout/Footer/FooterLink';
import React, { useContext } from 'react';
import classNames from 'classnames';
import useIsAuthPage from 'components/context/AuthPageContext/useIsAuthPage';

// Based on https://tailwindui.com/components/marketing/sections/footers#component-38c15f2c35def7c2c555175450d1448e

interface Props {
  className?: string;
}

const Footer = ({ className }: Props) => {
  const { colorClass } = useContext(EventLayoutContext);
  const isAuthPage = useIsAuthPage();

  return (
    <div>
      <div
        className={classNames(
          'transition-bgcolor-theme',
          'mt-32',
          'pb-12',
          className,
          isAuthPage && isAuthPage !== 'reset' && 'hidden'
        )}
      >
        <Container>
          <DisableInPreview>
            <div className="max-w-screen-xl px-4 pt-12 mx-auto sm:px-6 lg:pt-16 lg:px-8">
              <div className="xl:grid xl:grid-cols-3 xl:gap-8">
                <div className={classNames('grid', 'grid-cols-2', 'gap-8', 'xl:col-span-2', colorClass)}>
                  <div className="md:grid md:grid-cols-2 md:gap-8">
                    <div>
                      <h4 className="text-sm font-semibold leading-5 tracking-wider uppercase">Learn More</h4>
                      <ul className="mt-1">
                        <FooterLink href={reverse('features')}>Features</FooterLink>
                        <FooterLink href={reverse('pricing')}>Ticketing Pricing</FooterLink>
                        <FooterLink href="https://blog.mixily.com/">Blog</FooterLink>
                      </ul>
                    </div>
                    <div className="mt-12 md:mt-0">
                      <h4 className="text-sm font-semibold leading-5 tracking-wider uppercase">About</h4>
                      <ul className="mt-1">
                        <FooterLink href={reverse('about')}>About Us</FooterLink>
                        <FooterLink href={reverse('site_privacy')}> Terms &amp; Privacy</FooterLink>
                        <FooterLink href="mailto:hi@mixily.com">Say Hi</FooterLink>
                      </ul>
                    </div>
                  </div>
                  <div className="md:grid md:grid-cols-2 md:gap-8">
                    <div>
                      <h4 className="text-sm font-semibold leading-5 tracking-wider uppercase">Help</h4>
                      <ul className="mt-1">
                        <FooterLink href={reverse('faq')}>FAQ</FooterLink>
                        <FooterLink href={reverse('site_support')}>Support</FooterLink>
                      </ul>
                    </div>
                  </div>
                </div>
                <FooterEmailSignup />
              </div>
              <div className="pt-6 mt-6 border-t border-gray-200 md:flex md:items-center md:justify-between">
                <p
                  className={classNames(
                    colorClass,
                    'opacity-50',
                    'mt-8',
                    'text-base',
                    'leading-6',
                    'md:mt-0',
                    'md:order-1'
                  )}
                >
                  &copy; 2021 Mixily, Inc. All rights reserved.
                </p>
              </div>
            </div>
          </DisableInPreview>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
