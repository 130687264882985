import * as F from 'shared/shared/Functional';
import { DndContext, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { Tile } from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/types';
import PaginationButton from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/CallDisplay/PaginationButton';
import React, { useMemo, useRef } from 'react';
import TileContainer from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/CallDisplay/TileContainer/TileContainer';
import TileDisplay from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/CallDisplay/TileDisplay/TileDisplay';
import classNames from 'classnames';
import styles from './GridLayout.module.scss';
import useDraggablePaginator from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/CallDisplay/GridLayout/useDraggablePaginator';
import useGridLayout from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/CallDisplay/GridLayout/useGridLayout';
import useResizeObserver from 'utils/hooks/useResizeObserver';

interface Props {
  tiles: Tile[];
}

const PAGE_SIZE = 16;

// See https://www.figma.com/file/JRQWh5haGzqTJwk7FONu7h/Virtual-Venue-Interface-Design?node-id=0%3A1

const GridLayout = ({ tiles }: Props) => {
  const callContainer = useRef(null);
  const resizeEntry = useResizeObserver(callContainer);
  const containerWidth = resizeEntry?.contentRect.width;
  const containerHeight = resizeEntry?.contentRect.height;
  const layout = useGridLayout(Math.min(tiles.length, PAGE_SIZE), containerWidth || 0, containerHeight || 0);
  const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { distance: 5 } }));

  const tileIds = useMemo(() => F.pluck('id', tiles), [tiles]);
  const tileIdMap = useMemo(() => F.fromPairs(tiles.map((t) => [t.id, t])), [tiles]);

  const { visible, offscreen, prevPage, nextPage, order, handleDragEnd } = useDraggablePaginator(tileIds, PAGE_SIZE);

  return (
    <div className="flex items-start justify-center w-full h-full" style={{ padding: '10px 16px' }}>
      <div className="relative flex items-center justify-center w-full h-full" ref={callContainer}>
        {prevPage && <PaginationButton direction="left" onClick={prevPage} buttonStyle="circle" />}
        <div style={{ height: `${layout.gridSize.height}px`, width: `${layout.gridSize.width}px` }}>
          <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={order} strategy={rectSortingStrategy}>
              <div className={classNames('w-full mx-auto', styles.GridContainer, styles[`Has${visible.length}Tiles`])}>
                {visible.map((tileId) =>
                  tileIdMap[tileId] ? (
                    <TileContainer key={tileId} id={tileId} sortable>
                      <TileDisplay tile={tileIdMap[tileId]} />
                    </TileContainer>
                  ) : undefined
                )}
              </div>
            </SortableContext>
          </DndContext>
        </div>
        {nextPage && <PaginationButton direction="right" onClick={nextPage} buttonStyle="circle" />}
        {offscreen.map((tileId) =>
          tileIdMap[tileId] ? (
            <div key={tileId} className="hidden">
              <TileDisplay tile={tileIdMap[tileId]} isOffScreen />
            </div>
          ) : undefined
        )}
      </div>
    </div>
  );
};

export default GridLayout;
