import { Cell, Column } from 'react-table';
import { EventInvites_event_inviteSetWithoutOwner } from 'generated/EventInvites';
import { getEmail, renderName, renderState } from 'models/invite';
import CheckIcon from '@material-ui/icons/Done';
import React from 'react';
import moment from 'moment';

export type Invite = EventInvites_event_inviteSetWithoutOwner;

export const ACCESSORS: Record<string, (invite: Invite) => string | number | null | undefined> = {
  Name: (invite: Invite) => renderName(invite),
  Email: (invite: Invite) => getEmail(invite),
  Following: (invite: Invite) => String(!!invite.isFollowing),
  'Plus n+': (invite: Invite) => {
    const value = Number(invite.rsvpPlusN ?? '');
    return (value ?? '') === 0 ? '' : value;
  },
  Status: (invite: Invite) => renderState(invite.state),
  Updated: (invite: Invite) => moment(invite.updatedAt).fromNow(),
} as const;

export const COLUMNS: Column<Invite>[] = [
  {
    Header: 'Name',
    accessor: 'originalName',
    Cell: ({ row }: Cell<Invite>) => {
      return <div className="flex items-center text-sm leading-5">{ACCESSORS['Name'](row.original)}</div>;
    },
  },
  {
    Header: 'Email',
    accessor: 'originalEmail',
    Cell: ({ row }: Cell<Invite>) => {
      return <div className="flex items-center text-sm leading-5">{ACCESSORS['Email'](row.original)}</div>;
    },
  },
  {
    Header: () => <div className="flex items-center justify-center">Following</div>,
    accessor: 'isFollowing',
    Cell: ({ row }: Cell<Invite>) => {
      const invite = row.original;
      return invite.isFollowing ? (
        <div className="flex items-center justify-center text-sm leading-5">
          <CheckIcon fontSize="small" />
        </div>
      ) : null;
    },
  },
  {
    Header: () => <div className="flex items-center justify-center">Plus n+</div>,
    accessor: 'rsvpPlusN',
    Cell: ({ row }: Cell<Invite>) => {
      return (
        <div className="flex items-center justify-center text-sm leading-5">{ACCESSORS['Plus n+'](row.original)}</div>
      );
    },
  },
  {
    Header: 'Status',
    accessor: 'state',
    Cell: ({ row }: Cell<Invite>) => {
      return <div className="flex items-center text-sm leading-5">{ACCESSORS['Status'](row.original)}</div>;
    },
  },
  {
    Header: 'Updated',
    accessor: 'updatedAt',
    Cell: ({ row }: Cell<Invite>) => {
      return <div className="flex items-center text-sm leading-5">{ACCESSORS['Updated'](row.original)}</div>;
    },
  },
];
