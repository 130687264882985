import React from 'react';

interface Props {
  title: string;
  description?: string;
  children: React.ReactNode;
}

const Section = (props: Props) => {
  return (
    <div className="mt-16">
      <div className="text-3xl">{props.title}</div>
      {props.description && <div className="text-gray-600">{props.description}</div>}
      <div className="mt-6">{props.children}</div>
    </div>
  );
};

export default Section;
