import { EventWithExistQuery_event_eventsFromRun } from 'generated/EventWithExistQuery';
import { FormTicket } from 'models/ticket';
import { RunEventId_run } from 'generated/RunEventId';
import { TicketOrderInput } from 'generated/globalTypes';
import { renderFullTime } from 'models/event';
import { reverse } from 'shared/shared/routing/mixily-routes';
import moment from 'moment';

export function renderOccurrences(run: RunEventId_run | undefined): [string | null, string | null] {
  if (!run) {
    return [null, null];
  }

  const { startOccurrence, endOccurrence, location, timezone } = run;

  return renderFullTime({
    startTime: startOccurrence ? moment(startOccurrence) : null,
    endTime: endOccurrence ? moment(endOccurrence) : undefined,
    location: location ?? '',
    timezone: timezone ?? '',
  });
}

export function convertRunTicketToEventTicket(
  tickets: FormTicket[],
  selectedEvent: EventWithExistQuery_event_eventsFromRun | null
): TicketOrderInput[] {
  return tickets.map((ticket) => {
    const selectedTicket = selectedEvent?.ticketTypes?.find((t) => t.position === ticket.position);
    return { id: selectedTicket?.id ?? '', quantity: ticket.quantity, price: ticket.price };
  });
}

export const getRunUrl = (id: string) => {
  return reverse('run_read', { id });
};
