import * as m from 'models';
import { EventPrivacy } from 'shared/shared/types';
import { FEATURE_FLAGS } from 'shared/shared/json';
import { OUTGOING_INVITATIONS_QUERY } from 'utils/gql';
import { OutboxInvitationsModal } from 'components/Outbox/OutboxInvitationsModal';
import { OutgoingInvitations, OutgoingInvitationsVariables } from 'generated/OutgoingInvitations';
import { fullUrl } from 'utils/urls';
import { hasReachedAllowedCapacity, isEventAtCapacity } from 'shared/shared/utils';
import { pluralize } from 'utils/helpers';
import { useQuery } from '@apollo/client';
import ClickToCopy from 'components/common/ClickToCopy/ClickToCopy';
import GoogleContactsImportButton from 'components/pages/EventReadPage/InvitationSender/GoogleContactsImportButton/GoogleContactsImportButton';
import InviteForm from 'components/pages/EventReadPage/InvitationSender/InviteForm/InviteForm';
import React, { useState } from 'react';
import useEventClass from 'utils/hooks/event/useEventClass';
import useEventPermissions from 'utils/hooks/event/useEventPermissions';
import useHasFeatureFlags from 'utils/hooks/user/useHasFeatureFlags';
import useUser from 'utils/hooks/user/useUser';

const InvitationSender: React.FC = () => {
  const { event } = useEventClass();
  const { permissions } = useEventPermissions();
  const { isAuthenticated } = useUser();
  const { spamProtection } = useHasFeatureFlags(FEATURE_FLAGS.spamProtection);
  const isAtCapacity = isEventAtCapacity(event.maxCapacity, event.confirmedGuests!);
  const hasReachedAllowCap = hasReachedAllowedCapacity(event.invitedGuests!, event.id, event.owner?.id);
  const activateSpamAProtection = spamProtection && !event.owner?.enableProEmails;

  const [showOutgoingInvites, setShowOutgoingInvites] = useState(false);
  const emailLabel = 'Invite by email';

  const { data: outgoingInvites } = useQuery<OutgoingInvitations, OutgoingInvitationsVariables>(
    OUTGOING_INVITATIONS_QUERY,
    { variables: { eventId: event.id, includeSent: false } }
  );
  const outgoingInvitesCount = outgoingInvites?.invitations?.length || 0;

  return (
    <div>
      <div className="event-read-page-responsive-h4-heading">Share event</div>

      {(event.privacy === EventPrivacy.Unlisted || event.privacy === EventPrivacy.Password) && (
        <div className="mt-2">
          <span className="label--overline">Copy Link</span>
          <div className="mt-2">
            <ClickToCopy text={fullUrl(m.parseEvent(event).getUrl())} />
          </div>
        </div>
      )}

      {permissions.canInvite &&
        !event.isPast &&
        isAuthenticated &&
        (hasReachedAllowCap || activateSpamAProtection ? (
          <div className="mt-6">
            <div className="my-2">
              <div className="px-4 py-3 text-orange-500 bg-orange-100 border border-orange-500" role="alert">
                <span>You have reached the maximum capacity. Additional guests cannot be invited</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="mt-6">
            <div className="flex items-center justify-between">
              <span className="label--overline">{emailLabel}</span>
              <GoogleContactsImportButton />
            </div>
            <div className="mt-2">
              <InviteForm />
            </div>
            {!!isAtCapacity && (
              <div className="my-2">
                <div className="px-4 py-3 text-orange-500 bg-orange-100 border border-orange-500" role="alert">
                  <span>
                    {' '}
                    This event is at capacity, and new guests won't be able to confirm their RSVP. Please consider
                    adjusting the maximum capacity
                  </span>
                </div>
              </div>
            )}
            {!!outgoingInvitesCount && (
              <div className="mt-2 text-center transition-opacity duration-300 opacity-75 hover:opacity-100">
                <span className="cursor-pointer" onClick={() => setShowOutgoingInvites(true)}>
                  {outgoingInvitesCount} {pluralize('pending invitation', outgoingInvitesCount)}
                </span>
              </div>
            )}
          </div>
        ))}

      <OutboxInvitationsModal
        event={event}
        modalOpen={showOutgoingInvites}
        onClose={() => setShowOutgoingInvites(false)}
      />
    </div>
  );
};

export default InvitationSender;
