import * as F from 'shared/shared/Functional';
import { AuthStateContext } from 'components/App/AuthStateContext';
import { IResolve, NoRouteFound } from 'shared/shared/routing/Router';
import { analyticsAPI as analytics } from 'utils/api';
import { scrollToFragment } from 'utils/urls';
import AuthModals from 'components/AuthModals';
import Error404Page from 'components/pages/Error404Page';
import React from 'react';
import resolve from 'router';

export type AuthModalsOpenState = 'login' | 'signup' | null;

export interface AuthModalsState {
  open: AuthModalsOpenState;
  email: string;
  message?: string;
}

export type SetAuthModalState = (s: AuthModalsState) => void;

export interface AuthModalStateMutatorSet {
  setAuthModalState: SetAuthModalState;
  openLoginModal(state?: object): void;
  openSignupModal(state?: object): void;
  toggleSignupModal(state?: object): void;
  closeModals(): void;
}

export interface RouteManager {
  setPath(path: string): void;
  setExternalPath(path: string): void;
  replacePath(path: string): void;
  authModalStateMutatorSet: AuthModalStateMutatorSet;
}

export const URLContext = React.createContext<RouteManager>(null as any);

const resolveOr404 = (url: string): IResolve => {
  try {
    return resolve(url);
  } catch (e) {
    if (e instanceof NoRouteFound) {
      return {
        page: Error404Page,
        args: {},
      };
    } else {
      throw e;
    }
  }
};

const stripTrailingSlash = (path: string): string => {
  while (path.endsWith('/')) {
    path = path.substring(0, path.length - 1);
  }

  return path;
};

interface Props {
  initialPath: string;
}

interface State {
  history: { path: string };
  authModalState: AuthModalsState;
}

class RoutingProvider extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { initialPath } = props;
    const path = stripTrailingSlash(initialPath);

    window.history.replaceState({ path }, '', path);

    this.state = {
      history: { path },
      authModalState: {
        open: null,
        email: '',
      },
    };
  }

  private handlePopState = (ev: PopStateEvent) => {
    if (ev.state) {
      // `ev.state` can be null. I don't totally understand this, but it
      // happens when you first navigate to a page (/faq), then type in
      // a fragment part (/faq#foo) and press enter.
      this.setState({ history: ev.state });
    }
  };

  private readonly scrollToFragment = (): boolean => {
    return scrollToFragment(this.state.history.path);
  };

  public componentDidMount() {
    window.addEventListener('popstate', this.handlePopState);
    this.scrollToFragment();
  }

  public componentWillUnmount() {
    window.removeEventListener('popstate', this.handlePopState);
  }

  public componentDidUpdate(_: Props, prevState: State) {
    if (this.state.history.path !== prevState.history.path) {
      // The DOM of the new page has not rendered at this point
      // document.title which is referenced in analytics.page still contains
      // the previous page title.
      setTimeout(analytics.page, 0);
      this.scrollToFragment() || window.scrollTo(0, 0);
    }
  }

  public setPath = (path: string) => {
    window.history.pushState({ path: path }, '', path);
    this.setState({ history: { path } });
  };

  public setExternalPath = (externalPath: string) => {
    window.location.assign(externalPath);
  };

  public replacePath = (path: string) => {
    window.history.replaceState({ path }, '', path);
    this.setState({ history: { path } });
  };

  private setAuthModalState = (s: AuthModalsState) => {
    /* calling g) w 4 known properties here bc for some unknown reason tons of complex data is getting
     * tacked onto the state prop after a user logs in, causing several unnecessary re-renders */
    this.setState({ authModalState: F.pick(s, 'open', 'email', 'message') });
  };

  private openLoginModal = (state = {}) =>
    this.setAuthModalState({
      ...this.state.authModalState,
      ...state,
      open: 'login',
    });

  private openSignupModal = (state = {}) =>
    this.setAuthModalState({
      ...this.state.authModalState,
      ...state,
      open: 'signup',
    });

  private toggleSignupModal = (state = {}) => {
    if (this.state.authModalState.open === 'signup') {
      this.closeModals();
      return;
    }

    this.openSignupModal(state);
  };

  public closeModals = () =>
    this.setAuthModalState({
      ...this.state.authModalState,
      open: null,
      message: undefined,
    });

  render() {
    // Resolve current URL and render the resulting page
    const { authModalState, history } = this.state;

    // remove fragment - it can be present on initial load
    const resolve = resolveOr404(history.path.replace(/#.*/, ''));

    const Page = resolve.page;
    return (
      <URLContext.Provider
        value={{
          setPath: this.setPath,
          setExternalPath: this.setExternalPath,
          replacePath: this.replacePath,
          authModalStateMutatorSet: {
            setAuthModalState: this.setAuthModalState,
            openLoginModal: this.openLoginModal,
            openSignupModal: this.openSignupModal,
            toggleSignupModal: this.toggleSignupModal,
            closeModals: this.closeModals,
          },
        }}
      >
        <AuthStateContext.Provider value={{ email: authModalState.email, open: authModalState.open }}>
          <Page {...resolve.args} />
          <AuthModals {...{ authModalState, setAuthModalState: this.setAuthModalState }} />
        </AuthStateContext.Provider>
      </URLContext.Provider>
    );
  }
}

export default RoutingProvider;
