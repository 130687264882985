import { Link } from 'components/common';
import { reverse } from 'shared/shared/routing/mixily-routes';
import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import Header from './SiteHeader';
import React from 'react';
import classNames from 'classnames';
import styles from './SiteZoomSupportPage.module.scss';

interface Props {}

export const SiteZoomSupportPage: React.FC<Props> = () => {
  return (
    <BaseLayout title="Zoom Integration">
      <div className="max-w-md mb-6">
        <div className="mt-4">
          Mixily integrates with Zoom to assist with the creation of Zoom meetings directly inside the event create and
          edit workflows.
        </div>

        <Header>Installation</Header>

        <div className="mt-4">
          To install the Zoom integration, either visit <Link href={reverse('account_settings')}>Account Settings</Link>{' '}
          or proceed directly to the event create / edit form by creating a new event or editing an existing one.
        </div>

        <Header size="small" className="italic">
          From the account settings page:
        </Header>

        <ol className={styles.list}>
          <li>Click the ‘Connect to Zoom’ button in the Zoom Integration section.</li>
          <li>Click ‘Authorize’ in the dialog window</li>
          <li>Click ‘Authorize’ again to give Mixily permission to access your Zoom account</li>
          <li>If successful, you will see a green box stating that your account is connected to Zoom</li>
        </ol>

        <Header size="small" className="italic">
          From the event create / edit form:
        </Header>

        <ol className={styles.list}>
          <li>Create a new event or edit an existing event</li>
          <li>Click ‘+ Add Zoom Meeting’ above the ‘Where’ field on the event form</li>
          <li>Click ‘Authorize’ in the dialog window</li>
          <li>Click ‘Authorize’ again to give Mixily permission to access your Zoom account</li>
          <li>
            If successful, a new Zoom meeting will be created and added to your event with the link displayed in the
            Where field
          </li>
        </ol>

        <Header>Usage</Header>
        <div className="mt-4">
          The Zoom integration enables the creation of Zoom meetings directly inside the event create and edit
          workflows.
        </div>

        <ol className={classNames(styles.list, 'mt-4')}>
          <li>Create a new event or edit an existing event</li>
          <li>Click ‘+ Add Zoom Meeting’ above the ‘Where’ field on the Event form</li>
          <li>
            If successful, a new Zoom meeting will be created and added to your event with the link displayed in the
            Where field
          </li>
        </ol>

        <Header>Uninstallation / Deauthorization</Header>
        <div className="mt-4">
          Visit the <Link href="https://marketplace.zoom.us/user/installed">marketplace.zoom.us</Link>, find the Mixily
          application in the list and click 'Uninstall' to deauthorize the Mixily application.
        </div>

        <Header>Troubleshooting</Header>
        <div className="mt-4">
          A Zoom account is required in order to enable this integration. For the easiest installation experience,
          please ensure you already have a Zoom account and are logged in with the same browser you are using to visit
          mixily.com.
        </div>

        <Header>Support</Header>
        <div className="mt-4">
          If you’re having trouble signing up for or using any aspect of Mixily (or just want to say hi), don’t hesitate
          to reach out! Just shoot us a note at <Link href="mailto:help@mixily.com">help@mixily.com</Link>.
        </div>

        <div className="mt-4">We respond to most email support requests within 24 hours.</div>
      </div>
    </BaseLayout>
  );
};

export default SiteZoomSupportPage;
