import { RichTextType, TitleType } from 'utils/prismic/types';
import Error404Page from 'components/pages/Error404Page';
import Error500Page from 'components/pages/Error500Page';
import Prismic from 'prismic-javascript';
import React from 'react';
import RichTextPage from 'utils/prismic/RichTextPage';
import usePrismic from 'utils/prismic/usePrismic';

interface Props {
  id: string;
}

interface FeatureDetails {
  title: TitleType;
  content: RichTextType;
}

const FeaturesDetailPage = ({ id }: Props) => {
  const query = Prismic.Predicates.at('my.feature_page.uid', id);
  const { data, error } = usePrismic<FeatureDetails>(query);
  if (error) {
    return <Error500Page />;
  }
  if (data && !data.length) {
    return <Error404Page />;
  }
  return <RichTextPage title={data?.[0].data.title[0].text} content={data?.[0].data.content} />;
};

export default FeaturesDetailPage;
