import { DAILY_CONNECTION_STATES, DAILY_RECORDING_STATES, DailyState } from 'components/VirtualVenue/daily/state';
import { DailyAction } from 'components/VirtualVenue/daily/actions';
import { getCallItems } from 'components/VirtualVenue/daily/utils';

export const dailyReducer = (prevState: DailyState, action: DailyAction): DailyState => {
  const { connectionState, call } = prevState;
  switch (action.type) {
    case 'create-call':
      return { ...prevState, connectionState: DAILY_CONNECTION_STATES.STATE_CREATING };
    case 'call-connection-error':
      return { ...prevState, connectionState: DAILY_CONNECTION_STATES.STATE_IDLE };
    case 'start-joining-call':
      return {
        ...prevState,
        connectionState: DAILY_CONNECTION_STATES.STATE_JOINING,
        call: {
          call: action.call,
          roomUrl: action.url,
        },
      };
    case 'attempt-rejoining-call':
      return {
        ...prevState,
        connectionState: DAILY_CONNECTION_STATES.STATE_REJOINING,
        fatalError: 'Trying to reconnect...',
      };
    case 'set-join-succeeded':
      return {
        ...prevState,
        connectionState: DAILY_CONNECTION_STATES.STATE_JOINED,
        callItems: getCallItems(action.participants, prevState.callItems),
        fatalError: null,
      };
    case 'call-error':
      return {
        ...prevState,
        connectionState: DAILY_CONNECTION_STATES.STATE_ERROR,
      };
    case 'start-leaving-call':
      if (!call) {
        return {
          ...prevState,
          connectionState: DAILY_CONNECTION_STATES.STATE_IDLE,
        };
      } else if (connectionState === DAILY_CONNECTION_STATES.STATE_ERROR) {
        try {
          if (call) {
            call.call.destroy();
          }
        } catch {
          console.log('Error destroying call');
        }
        return {
          ...prevState,
          connectionState: DAILY_CONNECTION_STATES.STATE_IDLE,
          call: undefined,
        };
      } else {
        return {
          ...prevState,
          connectionState: DAILY_CONNECTION_STATES.STATE_LEAVING,
        };
      }
    case 'finish-leaving-call':
      return {
        ...prevState,
        connectionState: DAILY_CONNECTION_STATES.STATE_IDLE,
        call: undefined,
      };
    case 'participants-change':
      return {
        ...prevState,
        callItems: getCallItems(action.participants, prevState.callItems),
      };
    case 'cam-or-mic-error':
      return { ...prevState, camOrMicError: action.message };
    case 'active-speaker-change':
      return { ...prevState, activeSpeakerId: action.activeSpeakerId };

    case 'recording-started':
      return {
        ...prevState,
        recordingInfo: Object.assign({}, prevState.recordingInfo, {
          isRecording: true,
          activeRecordingId: action.recordingId,
          recordingState: DAILY_RECORDING_STATES.RECORDING_STARTED,
        }),
      };
    case 'recording-stopped':
      return {
        ...prevState,
        recordingInfo: Object.assign({}, prevState.recordingInfo, {
          isRecording: false,
          recordingState: DAILY_RECORDING_STATES.RECORDING_STOPPED,
        }),
      };
    case 'recording-error':
      return {
        ...prevState,
        recordingInfo: Object.assign({}, prevState.recordingInfo, {
          isRecording: false,
          recordingState: DAILY_RECORDING_STATES.RECORDING_ERROR,
        }),
      };
    case 'recording-idle':
      return {
        ...prevState,
        recordingInfo: Object.assign({}, prevState.recordingInfo, {
          isRecording: false,
          recordingState: DAILY_RECORDING_STATES.RECORDING_IDLE,
        }),
      };
    case 'fatal-error':
      return { ...prevState, fatalError: action.message };
    default:
      throw new Error();
  }
};
