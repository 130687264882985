import { EVENT_GUEST_LIST_QUERY } from 'utils/gql';
import { EventGuestList, EventGuestListVariables } from 'generated/EventGuestList';
import { useQuery } from '@apollo/client';
import InviteFormInner from 'components/pages/EventReadPage/InvitationSender/InviteForm/InviteFormInner';
import React from 'react';
import useEventClass from 'utils/hooks/event/useEventClass';
import useEventPermissions from 'utils/hooks/event/useEventPermissions';
import useUser from 'utils/hooks/user/useUser';

const InviteForm = () => {
  const { event } = useEventClass();
  const { permissions } = useEventPermissions();

  const { data, loading } = useQuery<EventGuestList, EventGuestListVariables>(EVENT_GUEST_LIST_QUERY, {
    variables: { id: event.id },
  });

  const invites = !loading && data && data.event && data.event.inviteSet;
  const emails = invites && invites.map((invite: any) => invite.originalEmail);
  const excludeItems = new Set<string>(emails || []);
  const { user } = useUser();

  return <InviteFormInner {...{ event, excludeItems, myPermissions: permissions, user }} />;
};

export default InviteForm;
