import { LoginForm } from 'components/auth-forms';
import { RedirectToPath } from 'components/Redirect';
import { constructQueryString } from 'shared/shared/utils';
import { getURLParam } from 'utils/urls';
import { reverse } from 'router';
import AuthPageContext from 'components/context/AuthPageContext/AuthPageContext';
import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import React from 'react';
import useUser from 'utils/hooks/user/useUser';

const AccountLoginPage = () => {
  const { isAuthenticated } = useUser();
  const next = getURLParam('next', reverse('home'));
  const client_id = getURLParam('client_id');
  const state = getURLParam('state');
  const redirect_uri = getURLParam('redirect_uri');
  const email = getURLParam('email', '');
  const path =
    client_id && state && redirect_uri
      ? reverse('account_authorize') + constructQueryString({ client_id, state, redirect_uri })
      : next || reverse('home');

  return (
    <AuthPageContext.Provider value="login">
      <BaseLayout title="Log in" hideTitle={true}>
        {isAuthenticated ? <RedirectToPath path={path} /> : <LoginForm next={path} email={email} />}
      </BaseLayout>
    </AuthPageContext.Provider>
  );
};

export default AccountLoginPage;
