// Wrapper around unique pages, e.g. 'about_page', that by convention
// have a single key `content` pointing to rich text

import { RichTextType } from 'utils/prismic/types';
import { useMemo } from 'react';
import Prismic from 'prismic-javascript';
import usePrismic from 'utils/prismic/usePrismic';

type PageData = {
  content: RichTextType;
};

const usePrismicPage = (pageName: string) => {
  const query = useMemo(() => {
    return Prismic.Predicates.at('document.type', pageName);
  }, [pageName]);
  const { loading, error, data } = usePrismic<PageData>(query);
  return { loading, error, data: data ? data[0].data.content : undefined };
};

export default usePrismicPage;
