import { ReadVirtualVenue_virtualVenue } from 'generated/ReadVirtualVenue';
import { VenueRole } from 'generated/globalTypes';
import { VirtualVenueDisplayProvider } from 'components/VirtualVenue/contexts/VirtualVenueDisplayContext';
import { VirtualVenueThemeProvider, defaultTheme } from 'components/VirtualVenue/contexts/VirtualVenueThemeContext';
import { themeToColors } from 'components/VirtualVenue/utils';
import PreviewAtSize from 'components/common/PreviewAtSize';
import React from 'react';
import VirtualVenueDisplay from 'components/VirtualVenue/VirtualVenueDisplay/VirtualVenueDisplay';
import VirtualVenueOuter from 'components/VirtualVenue/VirtualVenueDisplay/VirtualVenueOuter';

// A width that gets us the style of venue layout we want
const RENDER_AT_WIDTH = 1000;

// max-w-xl
const DEFAULT_DISPLAY_WIDTH = 576;

interface Props {
  virtualVenue: Omit<ReadVirtualVenue_virtualVenue, 'events' | 'myAccess'>;

  // Max pixels wide at which to render the preview.
  displayWidth?: number;
}

const VirtualVenuePreview = ({ virtualVenue, displayWidth = DEFAULT_DISPLAY_WIDTH }: Props) => {
  const mockVirtualVenue = {
    ...virtualVenue,
    events: [],
    myAccess: {
      __typename: 'MyAccess' as const,
      role: VenueRole.GUEST,
      dailyToken: null,
      ableToRecord: null,
    },
  };
  const colors = virtualVenue.theme ? themeToColors(virtualVenue.theme) : defaultTheme;

  return (
    <PreviewAtSize displayWidth={displayWidth} renderSize={RENDER_AT_WIDTH}>
      <VirtualVenueDisplayProvider initialSetting={{ mode: 'preview' }}>
        <VirtualVenueThemeProvider initialTheme={{ ...colors }}>
          <div className="pointer-events-none">
            <VirtualVenueOuter virtualVenue={mockVirtualVenue}>
              <VirtualVenueDisplay />
            </VirtualVenueOuter>
          </div>
        </VirtualVenueThemeProvider>
      </VirtualVenueDisplayProvider>
    </PreviewAtSize>
  );
};

export default VirtualVenuePreview;
