// Returns { loading, error, data } from a promise.

import { useAsync } from 'utils/hooks/useAsync';
import { useEffect } from 'react';

interface State<T> {
  loading: boolean;
  error?: Error;
  data?: T;
}

const usePromise = <T>(promise: Promise<T>): State<T> => {
  const { data, error, status, run } = useAsync<T, Error>();
  useEffect(() => run(promise), [promise, run]);
  return {
    loading: status === 'pending',
    error: error || undefined,
    data: data || undefined,
  };
};

export default usePromise;
