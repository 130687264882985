import { DAILY_CONNECTION_STATES } from 'components/VirtualVenue/daily/state';
import { Loading } from 'components/common';
import { Tile } from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/types';
import { Tooltip } from '@material-ui/core';
import { VenueMessage, isScreenShare } from 'components/VirtualVenue/daily/utils';
import { getURLParam } from 'utils/urls';
import {
  useCallConfig,
  useCallConfigDispatch,
} from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/contexts/CallConfigContext';
import { useVirtualVenueCall } from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/contexts/VirtualVenueCallContext';
import { useVirtualVenuePubnub } from 'components/VirtualVenue/contexts/VirtualVenuePubnubContext';
import { useVirtualVenueState } from 'components/VirtualVenue/contexts/VirtualVenueStateContext';
import CallMessage from '../CallMessage/CallMessage';
import GridLayout from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/CallDisplay/GridLayout/GridLayout';
import React, { useEffect, useMemo, useState } from 'react';
import SpeakerLayout from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/CallDisplay/SpeakerLayout/SpeakerLayout';
import SpotlightLayout from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/CallDisplay/SpotlightLayout/SpotlightLayout';

interface Props {
  message: VenueMessage | null;
  tiles: Tile[];
}

type Layout = 'grid' | 'speaker' | 'spotlight';

const getLayoutOverride = (
  tiles: Tile[],
  fixedSpeaker?: string,
  spotlightParticipants?: string[]
): Layout | undefined => {
  const urlVal = getURLParam('layout');
  if (urlVal && ['grid', 'speaker', 'spotlight'].includes(urlVal)) {
    return urlVal as Layout;
  }

  if (spotlightParticipants && spotlightParticipants.length > 0) {
    return 'spotlight';
  }

  if (fixedSpeaker) {
    return 'speaker';
  }

  if (tiles.some((t) => t.type === 'daily' && isScreenShare(t.id))) {
    return 'speaker';
  }
};

const CallDisplay = (props: Props) => {
  const { message, tiles } = props;
  const { dailyState } = useVirtualVenueCall();
  const { preferredView, isSpotlightMode } = useCallConfig();
  const callConfigDispatch = useCallConfigDispatch();
  const { pinnedParticipant } = useVirtualVenueState();
  const {
    pubnubState: { spotlightParticipants },
  } = useVirtualVenuePubnub();
  const [numberOfUnloadedParticipants, setNumberOfUnloadedParticipants] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const presentSpotlightParticipants = useMemo(
    () =>
      spotlightParticipants.filter((sp) =>
        tiles.find((t) => t.id === sp || (t.type === 'daily' && t.callItem?.id === sp))
      ),
    [spotlightParticipants, tiles]
  );
  useEffect(() => {
    if (dailyState.connectionState === DAILY_CONNECTION_STATES.STATE_JOINED) {
      const participantIds = Object.keys(dailyState.callItems);
      const unloadedParticipants = participantIds.filter((participantId) => {
        const foundTile = tiles.find((tile) => tile.id === participantId);
        return !foundTile;
      });

      setNumberOfUnloadedParticipants(unloadedParticipants.length);
      if (unloadedParticipants.length === 0) {
        setIsLoading(false);
      }
    } else if (
      dailyState.connectionState === DAILY_CONNECTION_STATES.STATE_JOINING ||
      dailyState.connectionState === DAILY_CONNECTION_STATES.STATE_REJOINING
    ) {
      setIsLoading(true);
    }
  }, [dailyState.callItems, dailyState.connectionState, numberOfUnloadedParticipants, tiles]);

  useEffect(() => {
    if (isSpotlightMode && presentSpotlightParticipants.length === 0) {
      callConfigDispatch({ type: 'set-is-spotlight-mode', isSpotightMode: false });
    } else if (!isSpotlightMode && presentSpotlightParticipants.length) {
      callConfigDispatch({ type: 'set-is-spotlight-mode', isSpotightMode: true });
    }
  }, [callConfigDispatch, isSpotlightMode, presentSpotlightParticipants]);

  const layout = getLayoutOverride(tiles, pinnedParticipant, presentSpotlightParticipants) || preferredView;

  return (
    <div className="relative w-full h-full">
      {dailyState.recordingInfo.isRecording ? (
        <Tooltip arrow title="This call is being recorded">
          <div className="absolute z-10 w-3 h-3 top-2 right-2">
            <span className="absolute flex w-3 h-3">
              {/* Ping */}
              <span
                className="absolute inline-flex w-full h-full rounded-full opacity-30 animate-ping-slow"
                style={{
                  backgroundColor: 'var(--v-theme-icon-color)',
                }}
              />
              {/* Circle */}
              <span
                className="relative inline-flex w-3 h-3 rounded-full opacity-60"
                style={{
                  backgroundColor: 'var(--v-theme-icon-color)',
                }}
              />
            </span>
          </div>
        </Tooltip>
      ) : null}
      {layout === 'grid' ? (
        <GridLayout tiles={tiles} />
      ) : layout === 'spotlight' ? (
        <SpotlightLayout tiles={tiles} spotlightParticipants={presentSpotlightParticipants} />
      ) : (
        <SpeakerLayout tiles={tiles} pinnedSpeaker={pinnedParticipant} />
      )}
      {message && <CallMessage header={message.header!} detail={message.detail!} isError={message.isError} />}
      {isLoading && (
        <div className="absolute top-0 left-0 z-10 flex items-end justify-center w-full h-full">
          <div
            className="inline-flex items-center justify-center mb-1 text-sm"
            style={{
              color: 'var(--v-theme-title-text-color)',
              opacity: '80%',
            }}
          >
            <div className="flex-grow-0 mr-2">
              <Loading size="tiny" />
            </div>
            <div>{numberOfUnloadedParticipants > 0 ? `Loading participants...` : 'Joining the call...'}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CallDisplay;
