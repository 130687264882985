import * as h from 'utils/helpers';
import { DiscountCode } from '../types';
import { DiscountKind } from 'generated/globalTypes';
import { TextWidget, Widget, WidgetProps } from 'components/widgets';
import ActionButton from 'components/common/ActionButton/ActionButton';
import DeleteIcon from '@material-ui/icons/Close';
import LabelWithInput from 'components/common/LabelWithInput/LabelWithInput';
import MuiSelectWidget from 'components/widgets/MuiSelectWidget/MuiSelectWidget';
import NumberWidget from 'components/widgets/NumberWidget/NumberWidget';
import OptionSwitchWidget from 'components/widgets/OptionSwitchWidget/OptionSwitchWidget';
import React from 'react';
import classNames from 'classnames';
import styles from './DiscountCodeWidget.module.scss';

interface Attrs {
  onDelete: () => void;
}

const typeOptions = {
  options: {
    [DiscountKind.percent]: 'Percentage',
    [DiscountKind.dollar]: 'Dollar Amount',
  },
};

const nameToLabel = (name: string) => {
  return h.toTitleCase(h.splitCamelCase(name));
};

const DiscountCodeWidget = (props: WidgetProps<DiscountCode, Attrs>) => {
  const { value, onChange, disabled, attrs } = props;
  if (!attrs) {
    throw new Error('DiscountCodeWidget requires attrs');
  }
  const makeField = (
    name: keyof typeof value,
    widget: Widget,
    className: string,
    label: string | undefined,
    attrs?: any
  ) => (
    <LabelWithInput className={className} label={label ?? nameToLabel(name)}>
      {React.createElement(widget, {
        value: value[name],
        disabled,
        attrs,
        onChange: (newVal) => onChange({ ...value, [name]: newVal }),
      })}
    </LabelWithInput>
  );

  return (
    <div className={classNames(styles.container, 'relative')}>
      <div className={styles.actionButtons}>
        <ActionButton
          onClick={() => {
            attrs.onDelete();
          }}
          disabled={!!value.quantityClaimed && value.quantityClaimed > 0}
        >
          <DeleteIcon />
        </ActionButton>
      </div>
      <div className={styles.DiscountCodeWidget}>
        {makeField('code', TextWidget, styles.codeField, undefined, {})}
        {makeField('type', MuiSelectWidget, styles.typeField, undefined, typeOptions)}
        {makeField('amount', NumberWidget, styles.amountField, undefined, { placeholder: 10 })}
        {makeField('isActive', OptionSwitchWidget, styles.activeField, '', { title: 'Active' })}
        {!!value.quantityClaimed && value.quantityClaimed > 0 && (
          <div className={styles.disabledNote}>This discount code has been used and cannot be deleted</div>
        )}
      </div>
    </div>
  );
};

export default DiscountCodeWidget;
