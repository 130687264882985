import { GOOGLE_SIGNUP_AUTH_MUTATION } from 'utils/gql';
import { GoogleAuthSignup, GoogleAuthSignupVariables } from 'generated/GoogleAuthSignup';
import { useMutation } from '@apollo/client';
import GoogleAuthButton from 'components/auth-forms/GoogleAuthButton';
import React, { useEffect } from 'react';
import useUserChangeContext from 'components/App/useUserChangeContext';

interface Props {
  next?: string;
  setError: any;
  loginHint?: string;
}

const GoogleSignup = (props: Props) => {
  const [doMutation, { data, error }] = useMutation<GoogleAuthSignup, GoogleAuthSignupVariables>(
    GOOGLE_SIGNUP_AUTH_MUTATION
  );
  const { login } = useUserChangeContext();
  const next = props.next || window.location.pathname;

  useEffect(() => {
    if (data?.googleAuthCreate.ok) {
      const apitoken = data.googleAuthCreate.apitoken!;
      login(apitoken, true, next);
    }
  });

  if (data?.googleAuthCreate.errors) {
    props.setError(data.googleAuthCreate.errors[0].message);
  }

  if (error) {
    props.setError('Sorry, something went wrong');
  }

  return (
    <GoogleAuthButton
      label="Sign up with Google"
      onSuccess={(code) => doMutation({ variables: { code } })}
      loginHint={props.loginHint}
    />
  );
};

export default GoogleSignup;
