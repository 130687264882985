import { EventLayoutContext } from 'components/layout/EventLayout/EventLayout';
import Link, { LinkProps } from 'components/Link';
import React, { useContext } from 'react';
import classNames from 'classnames';

interface Props extends LinkProps {
  children: React.ReactNode;
}

const FooterLink = (props: Props) => {
  const { colorClass } = useContext(EventLayoutContext);
  const { children, ...rest } = props;

  return (
    <li className="mt-1">
      <Link className={classNames('text-base', 'leading-6', 'text-black', 'opacity-75', colorClass)} {...rest}>
        {children}
      </Link>
    </li>
  );
};

export default FooterLink;
