import { EventWithExistQuery_event_guestPermissions } from 'generated/EventWithExistQuery';
import { Unauthenticated } from 'models';
import { ViewAsContext } from 'components/context/ViewAsContext/ViewAsContext';
import { useContext } from 'react';
import useEvent from 'utils/hooks/event/useEvent';
export type EventPermissions = EventWithExistQuery_event_guestPermissions;

const useEventPermissions = () => {
  const { event, loading, error } = useEvent();
  const viewAs = useContext(ViewAsContext);

  if (loading) {
    return { permissions: null, loading, error };
  }

  if (!event) {
    console.error("Can't call `useEventPermissions` outside of event page");
    return {
      permissions: {
        __typename: 'EventPersonPermissions',
        canInvite: false,
        canMessageGuests: false,
        canUpdate: false,
        canViewGuestList: false,
        canScheduleInvites: false,
      },
      loading: false,
    } as any;
  }

  if (viewAs?.user === Unauthenticated) {
    return { permissions: event.guestPermissions!, loading: false };
  }

  return { permissions: event.myPermissions!, loading, error };
};

export default useEventPermissions;
