import React from 'react';

interface Props {
  top: string;
  middle: string;
  bottom?: string;
}

const CalendarFlag = ({ top, middle, bottom }: Props) => {
  return (
    <div className="font-semibold leading-none text-center bg-white min-w-20">
      <div className="flex-none w-20 rounded-t rounded-b-lg shadow-lg lg:rounded-t-none lg:rounded-l ">
        <div className="block pb-2 overflow-hidden rounded-t">
          <div className="py-1 text-xs text-white bg-blue-500">{top}</div>
          <div className="pt-1 bg-white border-l border-r border-white">
            <span className="text-2xl font-bold">{middle}</span>
          </div>
          {bottom && (
            <div className="-mb-1 bg-white border-b border-l border-r border-white">
              <span className="text-xs">{bottom}</span>
            </div>
          )}
          {/* <div className="text-center bg-white border-b border-l border-r border-white rounded-b-lg">
            <span className="text-xs">8:00 am to 5:00 pm</span>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default CalendarFlag;
