import { isAuthenticated } from 'models';
import { localStore } from 'utils/localstore';
import { useVirtualVenueContentType } from 'components/VirtualVenue/contexts/VirtualVenueContentTypeContext';
import { useVirtualVenueDispatch } from 'components/VirtualVenue/contexts/VirtualVenueStateContext';
import { useVirtualVenuePubnubDispatch } from 'components/VirtualVenue/contexts/VirtualVenuePubnubContext';
import React, { useEffect, useState } from 'react';
import VirtualVenueBroadcastDisplay from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueBroadcastDisplay/VirtualVenueBroadcastDisplay';
import VirtualVenueCallDisplay from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/VirtualVenueCallDisplay';
import VirtualVenueCustomDisplay from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCustomDisplay/VirtualVenueCustomDisplay';
import useUser from 'utils/hooks/user/useUser';

const VirtualVenueDisplay = () => {
  const venueDispatch = useVirtualVenueDispatch();
  const contentType = useVirtualVenueContentType();
  const pubnubDispatch = useVirtualVenuePubnubDispatch();
  const { user } = useUser();
  const [loadedInitialName, setLoadedInitialName] = useState(false);

  // Set initial name
  useEffect(() => {
    if (loadedInitialName || user === 'UNKNOWN') {
      return;
    }
    const initialName = (isAuthenticated(user) && user.name) || localStore.venueDisplayName.get() || '';
    setLoadedInitialName(true);
    venueDispatch({ type: 'set-name', name: initialName });
    pubnubDispatch({ type: 'set-name', name: initialName });
  }, [loadedInitialName, pubnubDispatch, user, venueDispatch]);

  return contentType === 'call' ? (
    <VirtualVenueCallDisplay />
  ) : contentType === 'broadcast' ? (
    <VirtualVenueBroadcastDisplay />
  ) : (
    <VirtualVenueCustomDisplay />
  );
};

export default VirtualVenueDisplay;
