import { QuestionData } from 'components/pages/FaqPage/types';
import React from 'react';
import RichText from 'utils/prismic/RichText';

type Props = QuestionData;

const Question = ({ question, answer }: Props) => {
  return (
    <div className="mt-10">
      <div className="text-lg font-bold">{question}</div>
      <div className="mt-3">
        <RichText content={answer} />
      </div>
    </div>
  );
};

export default Question;
