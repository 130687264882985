import './ChatNameForm.module.scss';
import { OnSubmit, useForm } from 'react-hook-form';
import FieldLayout from 'components/common/FieldLayout/FieldLayout';
import Input2 from 'components/common/Input2/Input2';
import React from 'react';

type FormT = {
  name: string;
};

interface Props {
  onSubmit: OnSubmit<FormT>;
  onBlur?: () => void;
  defaultName?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  inputClassName?: string;
  inputStyle?: any;
  inputPlaceholder?: string;
  label?: string;
  helpText?: string;
  formId?: string;
  required?: boolean;
  autocomplete?: boolean;
  className?: string;
}

const ChatNameForm = ({
  onSubmit,
  defaultName: name = '',
  disabled,
  autoFocus,
  inputClassName,
  inputStyle,
  inputPlaceholder,
  label,
  helpText,
  formId,
  onBlur,
  className,
  required = true,
  autocomplete = true,
}: Props) => {
  const formContext = useForm<FormT>({ defaultValues: { name } });
  const { register, handleSubmit, errors } = formContext;
  return (
    <div className="ChatNameForm">
      <form onSubmit={handleSubmit(onSubmit)} id={formId}>
        <FieldLayout
          label={label ?? 'Your Name'}
          helpText={helpText ?? "How you'll appear in chat"}
          className={className}
          error={errors.name}
        >
          <Input2
            className={inputClassName || 'mt-2 text-black rounded'}
            name="name"
            ref={register}
            required={required}
            autoFocus={autoFocus}
            disabled={disabled}
            style={inputStyle}
            onBlur={onBlur}
            autoComplete={autocomplete ? 'on' : 'off'}
            placeholder={inputPlaceholder}
          />
        </FieldLayout>
      </form>
    </div>
  );
};

export default ChatNameForm;
