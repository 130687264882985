import { Link, Loading } from 'components/common';
import { Person, Unauthenticated, Unknown, isAuthenticated } from 'models';
import { addSnackbarMessage } from 'utils/eventEmitter';
import { getStripeOauthLink } from 'utils/stripe';
import { getURLParams } from 'utils/urls';
import { reverse } from 'router';
import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import React from 'react';
import useConnectStripeAccount from 'utils/hooks/useConnectStripeAccount';
import useUser from 'utils/hooks/user/useUser';

const params = getURLParams(window.location.href);
const { code, state, error } = params;
const userId = state;

export const StripeResponsePage: React.FC = () => {
  const { chargesEnabled, loading, stripeError } = useConnectStripeAccount(code);
  const { user } = useUser();

  if (user === Unknown) {
    return null;
  }

  if (loading) {
    return (
      <BaseLayout title="Loading">
        <Loading />
      </BaseLayout>
    );
  }

  if (user === Unauthenticated || (isAuthenticated(user) && user.id) !== userId || !code || error || stripeError) {
    addSnackbarMessage(stripeError?.message ?? 'Error connecting to Stripe', 'error');
    return (
      <BaseLayout title="Stripe Integration">
        Something went wrong connecting to your Stripe account. Please return to{' '}
        <Link href={reverse('account_settings')}>account settings</Link> and try again.
      </BaseLayout>
    );
  }

  if (!chargesEnabled) {
    return (
      <BaseLayout title="Stripe Integration">
        Your account is connected to Stripe, but you haven't completed the onboarding process. Please return to{' '}
        <Link href={getStripeOauthLink(user as Person)}>Stripe</Link> and submit your account details to receive
        payouts.
      </BaseLayout>
    );
  }

  window.close();
  return null;
};

export default StripeResponsePage;
