import * as F from 'shared/shared/Functional';
import {
  DailyParticipantTile,
  Tile,
} from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/types';
import { DndContext, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import PaginationButton from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/CallDisplay/PaginationButton';
import React, { useCallback, useMemo, useRef } from 'react';
import TileContainer from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/CallDisplay/TileContainer/TileContainer';
import TileDisplay from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/CallDisplay/TileDisplay/TileDisplay';
import classNames from 'classnames';
import styles from './SpotlightLayout.module.scss';
import useDraggablePaginator from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/CallDisplay/GridLayout/useDraggablePaginator';
import useResizeObserver from 'utils/hooks/useResizeObserver';
import useSpotlightLayout from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/CallDisplay/SpotlightLayout/useSpotlightLayout';

interface Props {
  tiles: Tile[];
  spotlightParticipants: string[];
}

const SpotlightLayout = ({ tiles, spotlightParticipants }: Props) => {
  const callContainer = useRef(null);
  const resizeEntry = useResizeObserver(callContainer);
  const containerWidth = resizeEntry?.contentRect.width ?? 0;
  const containerHeight = resizeEntry?.contentRect.height ?? 0;

  const tileIdMap = useMemo(() => {
    const map = F.fromPairs(tiles.map((t) => [t.id, t]));

    // Add an alias for the local tile using its daily id
    const localTile = map['local'];
    if (localTile) {
      const dailyUserId = localTile.type === 'daily' ? localTile.callItem?.id ?? 'local' : 'local';
      map[dailyUserId] = localTile;
    }

    // Add an alias for the local screenshare tile using its daily id
    const localTileScreen = map['local-screen'];
    if (localTileScreen) {
      const dailyUserId = localTileScreen.type === 'daily' ? localTileScreen.callItem?.id ?? 'local' : 'local';
      map[`${dailyUserId}-screen`] = localTileScreen;
    }
    return map;
  }, [tiles]);

  // When a spotlighted participant starts a screenshare, the screenshare
  // becomes the only spotlight
  const displaySpotlights = useMemo(() => {
    const spotlightWithScreenShare = spotlightParticipants.find((s) => tileIdMap[`${s}-screen`]);
    return spotlightWithScreenShare ? [`${spotlightWithScreenShare}-screen`] : spotlightParticipants;
  }, [spotlightParticipants, tileIdMap]);

  const numSpotlights = displaySpotlights.length;
  const spotlightLayout = useSpotlightLayout(numSpotlights, containerWidth, containerHeight);
  const {
    others: { perPage, colSpan: othersColSpan },
    gridColumns,
    spotlight: {
      cols,
      rows: spotlightRows,
      tileSize: { width: spotlightTileWidth },
      colSpan: spotlightColSpan,
      containerSize: { height: spotlightHeight },
    },
  } = spotlightLayout;

  const nonSpotlightTileIds = useMemo(
    () =>
      tiles
        .filter((t) => {
          const absoluteTileId = t.id.startsWith('local')
            ? t.id.replace('local', (t as DailyParticipantTile).callItem?.id ?? 'local')
            : t.id;
          return !displaySpotlights.includes(absoluteTileId);
        })
        .map((t) => t.id),
    [displaySpotlights, tiles]
  );

  const { visible, prevPage, nextPage, order, handleDragEnd } = useDraggablePaginator(nonSpotlightTileIds, perPage);

  const getOthersColOffset = useCallback((i) => othersColSpan * 2 * ((perPage - visible.length) / 2 + i) + 1, [
    othersColSpan,
    perPage,
    visible.length,
  ]);

  const getSpotlightColOffset = useCallback(
    (i) => {
      const tilesInLastRow = numSpotlights % cols;
      const tilesInFullRows = numSpotlights - tilesInLastRow;
      const tilesInRow = i < tilesInFullRows ? cols : tilesInLastRow;
      const nthInRow = i % cols;
      return spotlightColSpan * 2 * ((cols - tilesInRow) / 2 + nthInRow) + 1;
    },
    [numSpotlights, cols, spotlightColSpan]
  );

  // Dynamically define the number of grid rows based on the number of participants
  const gridTemplateRows = useMemo(() => {
    const rowHeight = spotlightHeight / spotlightRows;
    let css = '';
    for (let row = 0; row < spotlightRows; row++) {
      css += `${rowHeight}px `;
    }
    return `${css}auto`;
  }, [spotlightRows, spotlightHeight]);

  const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { distance: 5 } }));
  return (
    <div className="flex items-start justify-center w-full h-full px-4 py-2">
      <div className="relative flex flex-col w-full h-full" ref={callContainer}>
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext items={order} strategy={rectSortingStrategy}>
            <div
              className={classNames('w-full h-full mx-auto', styles.GridContainer)}
              style={{
                // gridColumns * 2 so we can center even number of participants (half column offsets impossible)
                gridTemplateColumns: `repeat(${gridColumns * 2}, 1fr)`,
                gridTemplateRows,
              }}
            >
              {prevPage && (
                <PaginationButton
                  direction="left"
                  onClick={prevPage}
                  style={{
                    gridArea: `${spotlightRows + 1} / 1`,
                  }}
                />
              )}
              {displaySpotlights.map((tileId, spotlightIndex) => {
                return tileIdMap[tileId] ? (
                  <TileContainer
                    id={tileId}
                    style={{
                      margin: 'auto',
                      gridColumn: `${getSpotlightColOffset(spotlightIndex)} / span ${spotlightColSpan * 2}`,
                      gridRow: Math.ceil((spotlightIndex + 1) / cols),
                    }}
                    width={spotlightTileWidth}
                    key={tileId}
                    sortable={false}
                  >
                    <TileDisplay tile={tileIdMap[tileId]} isOffScreen={false} />
                  </TileContainer>
                ) : undefined;
              })}
              {order.map((tileId) => {
                const visibleIndex = visible.indexOf(tileId);
                const hidden = visibleIndex < 0;
                return tileIdMap[tileId] ? (
                  <TileContainer
                    id={tileId}
                    className={classNames(styles.NonSpotlight, 'h-full', {
                      hidden,
                    })}
                    style={{
                      gridColumn: `${getOthersColOffset(visibleIndex)} / span ${othersColSpan * 2}`,
                      gridRow: spotlightRows + 1,
                    }}
                    key={tileId}
                    sortable={!hidden}
                  >
                    <TileDisplay tile={tileIdMap[tileId]} isOffScreen={hidden} />
                  </TileContainer>
                ) : undefined;
              })}
              {nextPage && (
                <PaginationButton
                  direction="right"
                  onClick={nextPage}
                  style={{
                    gridArea: `${spotlightRows + 1} / -1`,
                  }}
                />
              )}
            </div>
          </SortableContext>
        </DndContext>
      </div>
    </div>
  );
};

export default SpotlightLayout;
