import { DailyCallItems, DailyState, initialDailyState } from 'components/VirtualVenue/daily/state';
import { DailyEventObject, DailyParticipantsObject } from '@daily-co/daily-js';

export function getLocalCallItem(callItems: DailyCallItems) {
  return callItems.local;
}

export function getCallItems(participants: DailyParticipantsObject, prevCallItems: DailyCallItems) {
  let callItems: DailyCallItems = { ...initialDailyState.callItems }; // Ensure we *always* have a local participant
  for (const [id, participant] of Object.entries(participants)) {
    // Here we assume that a participant will join with audio/video enabled.
    // This assumption lets us show a "loading" state before we receive audio/video tracks.
    const hasLoaded = prevCallItems[id] && !prevCallItems[id].isLoading;

    // tracks not in daily type definitions
    const tracks = participant.tracks;
    const tracksLoading = tracks.audio.state === 'loading' || tracks.video.state === 'loading';
    callItems[id as 'local'] = {
      id: participant.user_id,
      isLoading: !hasLoaded && tracksLoading,
      audioTrack: participant.audioTrack,
      videoTrack: participant.videoTrack,
    };
    if (participant.screenVideoTrack || participant.screenAudioTrack) {
      callItems[(id + '-screen') as 'local'] = {
        id: participant.user_id,
        isLoading: false,
        videoTrack: participant.screenVideoTrack,
        audioTrack: participant.screenAudioTrack,
      };
    }
  }
  return callItems;
}

// --- Derived data ---

// True if id corresponds to local participant (*not* their screen share)
export function isLocal(id: string) {
  return id === 'local';
}

export function isScreenShare(id: string) {
  return id.endsWith('-screen');
}

export function containsScreenShare(callItems: DailyCallItems) {
  return Object.keys(callItems).some((id) => isScreenShare(id));
}

export interface VenueMessage {
  header: string | null;
  detail: string | null;
  isError: boolean;
}

export function getMessage(callState: DailyState): VenueMessage | null {
  let header = null;
  let detail = null;
  let isError = false;
  if (callState.fatalError) {
    header = `Error: ${callState.fatalError}`;
    isError = true;
  } else if (callState.camOrMicError) {
    header = `Camera or mic access error: ${callState.camOrMicError}`;
    detail = 'See https://help.daily.co/en/articles/2528184-unblock-camera-mic-access-on-a-computer to troubleshoot.';
    isError = true;
  }
  return header || detail ? { header, detail, isError } : null;
}

export function logDailyEvent(e: DailyEventObject) {
  console.log('[daily.co event]', e.action);
}
