import { Button, Loading } from 'components/common';
import { mixilyAPI } from 'utils/api';
import React, { useCallback, useState } from 'react';

const StripeSubscriptionSettings = () => {
  const [loading, setLoading] = useState(false);
  const createBillingPortalSession = useCallback(() => {
    setLoading(true);
    mixilyAPI
      .createBillingPortalSession()
      .then((res) => {
        if (res.success) {
          window.location.href = res.redirect_url;
        }
      })
      .finally(() => setLoading(false));
  }, []);
  return (
    <>
      <div onClick={() => setLoading(true)}>
        <Button
          className="w-60"
          label={loading ? <Loading size="small" /> : 'Manage Subscriptions'}
          onClick={createBillingPortalSession}
        />
      </div>
    </>
  );
};

export default StripeSubscriptionSettings;
