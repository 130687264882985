import { addSnackbarMessage } from 'utils/eventEmitter';
import { uploadImage } from 'utils/helpers';
import { useVirtualVenueDisplay } from 'components/VirtualVenue/contexts/VirtualVenueDisplayContext';
import { useVirtualVenueEditContext } from 'components/pages/VirtualVenueEditPage/VirtualVenueEditContext';
import IconSvg from 'components/common/IconSvg/IconSvg';
import LoopIcon from '@material-ui/icons/Loop';
import React from 'react';
import classNames from 'classnames';

interface Props {
  logo?: string;
  logoFilter?: string;
}

function VirtualVenueLogo(props: Props) {
  const { mode } = useVirtualVenueDisplay();
  if (mode === 'edit') {
    return <VirtualVenueEditLogo {...props} />;
  } else {
    return <VirtualVenueReadLogo {...props} />;
  }
}

function VirtualVenueReadLogo({ logo, logoFilter }: Props) {
  return logo ? (
    <img alt="Venue logo" className="max-h-16 2xl:max-h-24" src={logo} style={{ filter: logoFilter }} />
  ) : null;
}

function VirtualVenueEditLogo({ logoFilter }: Props) {
  const [isUploading, setIsUploading] = React.useState(false);
  const fileInput = React.useRef<HTMLInputElement>(null);

  const { logo, setLogo } = useVirtualVenueEditContext()!;

  const handleFileChange = React.useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        try {
          setIsUploading(true);
          const uploadedImageUrl = await uploadImage(e.target.files[0]);
          setLogo(uploadedImageUrl);
        } catch (e: any) {
          addSnackbarMessage(e.message, 'error');
        } finally {
          setIsUploading(false);
        }
      }
    },
    [setLogo]
  );

  return (
    <div className="relative flex items-center h-full">
      <input
        type="file"
        ref={fileInput}
        className="hidden"
        onChange={handleFileChange}
        accept="image/gif, image/jpeg, image/png"
      />
      {isUploading ? (
        <LoopIcon color="primary" className="animate-spin" fontSize="large" />
      ) : logo ? (
        <>
          <img
            alt="Venue logo"
            className={classNames('max-h-16 h-full')}
            src={logo}
            style={{ filter: logoFilter }}
            // onClick={() => fileInput.current!.click()}
          />

          <button
            className="absolute w-6 h-6 rounded-full -top-3 -right-3"
            style={{
              color: 'var(--v-theme-title-text-color)',
              backgroundColor: 'var(--v-theme-bg-color)',
              opacity: '.5',
            }}
            onClick={() => setLogo('')}
          >
            <IconSvg name="x" />
          </button>
        </>
      ) : (
        <button className="inline-block max-h-full cursor-pointer" onClick={() => fileInput.current!.click()}>
          <ClickToAddLogo />
        </button>
      )}
    </div>
  );
}

const ClickToAddLogo = () => (
  <div
    className="relative inline-block max-h-full text-center bg-transparent cursor-pointer"
    style={{ color: 'var(--v-theme-title-text-color)' }}
  >
    <div className="px-4 py-1.5">
      <div>Click to</div>
      <div>add logo </div>
    </div>
    <div
      className="absolute top-0 left-0 w-2 h-2 border-t border-l border-solid"
      style={{ borderColor: 'var(--v-theme-title-text-color)' }}
    />
    <div
      className="absolute top-0 right-0 w-2 h-2 border-t border-r border-solid"
      style={{ borderColor: 'var(--v-theme-title-text-color)' }}
    />
    <div
      className="absolute bottom-0 left-0 w-2 h-2 border-b border-l border-solid"
      style={{ borderColor: 'var(--v-theme-title-text-color)' }}
    />
    <div
      className="absolute bottom-0 right-0 w-2 h-2 border-b border-r border-solid"
      style={{ borderColor: 'var(--v-theme-title-text-color)' }}
    />
  </div>
);

export default VirtualVenueLogo;
