import { DailyParticipantUpdateOptions } from '@daily-co/daily-js';
import { Menu, MenuItem, Tooltip } from '@material-ui/core';
import { URLContext } from 'components/RoutingProvider';
import { VIRTUAL_VENUE_SET_REQUIRE_NAME_MUTATION } from 'utils/gql';
import { VenueRole } from 'generated/globalTypes';
import { VirtualVenueSetRequireName, VirtualVenueSetRequireNameVariables } from 'generated/VirtualVenueSetRequireName';
import { addSnackbarMessage } from 'utils/eventEmitter';
import { getDisplayConfig } from 'components/VirtualVenue/utils';
import { isAuthenticated } from 'models';
import {
  useCallConfig,
  useCallConfigDispatch,
} from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/contexts/CallConfigContext';
import { useMutation } from '@apollo/client';
import { useVirtualVenue } from 'components/VirtualVenue/contexts/VirtualVenueContext';
import { useVirtualVenueCall } from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/contexts/VirtualVenueCallContext';
import { useVirtualVenueContentType } from 'components/VirtualVenue/contexts/VirtualVenueContentTypeContext';
import { useVirtualVenueDisplay } from 'components/VirtualVenue/contexts/VirtualVenueDisplayContext';
import Checkbox2 from 'components/common/Checkbox2/Checkbox2';
import CloseVenueForm from 'components/VirtualVenue/VirtualVenueDisplay/shared/CloseVenueForm/CloseVenueForm';
import DevicesForm from 'components/VirtualVenue/VirtualVenueDisplay/layouts/VirtualVenueDisplayLayout/Tray/TraySettingsMenu/DevicesForm/DevicesForm';
import Dialog2 from 'components/common/Dialog2/Dialog2';
import IconSvg from 'components/common/IconSvg/IconSvg';
import React, { useCallback, useContext, useState } from 'react';
import TrayButton from '../TrayButton/TrayButton';
import WithMediaDevicePermission from 'components/VirtualVenue/VirtualVenueDisplay/layouts/VirtualVenueDisplayLayout/Tray/TraySettingsMenu/DevicesForm/WithMediaDevicePermission';
import fscreen from 'fscreen';
import useUser from 'utils/hooks/user/useUser';
import useUserChangeContext from 'components/App/useUserChangeContext';

const TraySettingsMenu = () => {
  const [settingsAnchorEl, setSettingsAnchorEl] = useState<null | HTMLElement>(null);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [devicesOpen, setDevicesOpen] = useState(false);
  const [closeDialogOpen, setCloseDialogOpen] = useState(false);

  const venue = useVirtualVenue();
  const call = useVirtualVenueCall(true);

  const [requireName, setRequireName] = useState<boolean>(venue.requireName);
  const enableCallButtons = call ? getDisplayConfig(call.dailyState.connectionState).enableCallButtons : false;
  const { logout } = useUserChangeContext();
  const { user } = useUser();
  const { authModalStateMutatorSet } = useContext(URLContext);

  const [setVenueRequireName] = useMutation<VirtualVenueSetRequireName, VirtualVenueSetRequireNameVariables>(
    VIRTUAL_VENUE_SET_REQUIRE_NAME_MUTATION
  );
  const { isEmbedded } = useVirtualVenueDisplay();
  const isOwner = venue.myAccess.role === VenueRole.OWNER;
  const dailyCall = call?.dailyState.call?.call;
  const displayConfig = useCallConfig(true);
  const displayConfigDispatch = useCallConfigDispatch(true);

  const isCall = useVirtualVenueContentType() === 'call';

  const muteAllGuests = useCallback(() => {
    const updateList: {
      [sessionId: string]: DailyParticipantUpdateOptions;
    } = {};
    Object.keys(dailyCall?.participants() || {}).forEach((participant) => {
      if (participant !== 'local') {
        updateList[participant] = { setAudio: false };
      }
    });
    dailyCall?.updateParticipants(updateList);
    setSettingsOpen(false);
    addSnackbarMessage('Muted all other guests', 'success');
  }, [dailyCall]);

  const toggleRequireName = useCallback(() => {
    // Instant UI feedback
    setRequireName(!requireName);
    setVenueRequireName({ variables: { id: venue.id, requireName: !requireName } }).then((res) => {
      if (!res.data?.virtualVenueUpdate.ok) {
        // revert on fail
        setRequireName(requireName);
      }
    });
  }, [requireName, setVenueRequireName, venue.id]);

  return (
    <>
      <TrayButton enabled={true} open={settingsOpen} onClick={() => setSettingsOpen(!settingsOpen)} label="Settings">
        <IconSvg name="settings" className="w-6 h-6" ref={setSettingsAnchorEl} />
      </TrayButton>
      <Menu
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        anchorEl={settingsAnchorEl}
        keepMounted
        open={settingsOpen}
        onClose={() => setSettingsOpen(false)}
        // MUI Poppers that can be children of a fullscreened element must conditionally use that element as their container
        // to behave correctly. Otherwise if on body they aren't available in full screen, and if on the fullscreenable element, they can get cut off when not fullscreened.
        container={fscreen.fullscreenElement}
      >
        {isOwner && (
          <MenuItem
            onClick={() => {
              setSettingsOpen(false);
              setCloseDialogOpen(true);
            }}
          >
            <span className="text-sm">Close Venue</span>
          </MenuItem>
        )}
        {isOwner && isCall && (
          <MenuItem onClick={enableCallButtons ? muteAllGuests : undefined}>
            {enableCallButtons ? (
              <span className="text-sm">Mute everyone else</span>
            ) : (
              <Tooltip placement="left" arrow title="Available once you've joined the call">
                <span className="text-sm text-gray-500">Mute everyone else</span>
              </Tooltip>
            )}
          </MenuItem>
        )}

        {isOwner && (
          <MenuItem onClick={toggleRequireName}>
            <Checkbox2 checked={requireName} readOnly />
            <span className="ml-2 text-sm">Require name</span>
          </MenuItem>
        )}

        {isCall && (
          <MenuItem onClick={() => displayConfigDispatch!({ type: 'toggle-show-self' })}>
            <Checkbox2 checked={displayConfig!.showSelf} readOnly />
            <span className="ml-2 text-sm">Show self</span>
          </MenuItem>
        )}

        {isCall && (
          <MenuItem onClick={() => displayConfigDispatch!({ type: 'toggle-always-show-names' })}>
            <Checkbox2 checked={displayConfig!.alwaysShowNames} readOnly />
            <span className="ml-2 text-sm">Show names</span>
          </MenuItem>
        )}

        {isCall && !displayConfig!.isSpotlightMode && (
          <MenuItem
            onClick={() =>
              displayConfigDispatch!({
                type: 'set-preferred-view',
                view: displayConfig!.preferredView === 'grid' ? 'speaker' : 'grid',
              })
            }
          >
            <Checkbox2 checked={displayConfig!.preferredView === 'speaker'} readOnly />
            <span className="ml-2 text-sm">Speaker view</span>
          </MenuItem>
        )}

        {isCall && (
          <MenuItem
            onClick={() => {
              setSettingsOpen(false);
              setDevicesOpen(true);
            }}
          >
            <span className="text-sm">Devices...</span>
          </MenuItem>
        )}

        {isEmbedded &&
          (isAuthenticated(user) ? (
            <MenuItem onClick={() => logout(false)}>
              <span className="text-sm">Log out</span>
            </MenuItem>
          ) : (
            <MenuItem
              onClick={() => {
                setSettingsOpen(false);
                authModalStateMutatorSet.openLoginModal();
              }}
            >
              <span className="text-sm">Log in</span>
            </MenuItem>
          ))}
      </Menu>
      <Dialog2
        title="Devices"
        container={fscreen.fullscreenElement}
        onClose={() => setDevicesOpen(false)}
        isOpen={devicesOpen}
        size="xs"
      >
        <WithMediaDevicePermission>
          <DevicesForm />
        </WithMediaDevicePermission>
      </Dialog2>
      <Dialog2
        container={fscreen.fullscreenElement}
        title="Close your venue"
        onClose={() => setCloseDialogOpen(false)}
        isOpen={closeDialogOpen}
        size="xs"
      >
        <CloseVenueForm onClose={() => setCloseDialogOpen(false)} />
      </Dialog2>
    </>
  );
};

export default TraySettingsMenu;
