import * as React from 'react';
import { PubNubVirtualVenueAction, PubNubVirtualVenueState } from 'components/VirtualVenue/pubnub/pubnub';

type VirtualVenuePubnubContextT = {
  pubnubState: PubNubVirtualVenueState;
  pubnubDispatch: React.Dispatch<PubNubVirtualVenueAction>;
};

const VirtualVenuePubnubContext = React.createContext<VirtualVenuePubnubContextT | undefined>(undefined);

function useVirtualVenuePubnub(): VirtualVenuePubnubContextT;
function useVirtualVenuePubnub(optional: true): VirtualVenuePubnubContextT | undefined;
function useVirtualVenuePubnub(optional?: boolean): VirtualVenuePubnubContextT | undefined {
  const context = React.useContext(VirtualVenuePubnubContext);
  if (!optional && context === undefined) {
    throw new Error('useVirtualVenuePubnub with optional:false must be used within a VirtualVenuePubnubProvider');
  }
  return context;
}

type VirtualVenuePubnubDispatchContextT = React.Dispatch<PubNubVirtualVenueAction>;

const VirtualVenuePubnubDispatchContext = React.createContext<VirtualVenuePubnubDispatchContextT | undefined>(
  undefined
);

function useVirtualVenuePubnubDispatch(): VirtualVenuePubnubDispatchContextT;
function useVirtualVenuePubnubDispatch(optional: true): VirtualVenuePubnubDispatchContextT | undefined;
function useVirtualVenuePubnubDispatch(optional?: boolean): VirtualVenuePubnubDispatchContextT | undefined {
  const context = React.useContext(VirtualVenuePubnubDispatchContext);
  if (!optional && context === undefined) {
    throw new Error(
      'useVirtualVenuePubnubDispatch with optional:false must be used within a VirtualVenuePubnubProvider'
    );
  }
  return context;
}

interface Props {
  children: React.ReactNode;
  value: VirtualVenuePubnubContextT;
}

function VirtualVenuePubnubProvider({ children, value }: Props) {
  return (
    <VirtualVenuePubnubContext.Provider value={value}>
      <VirtualVenuePubnubDispatchContext.Provider value={value.pubnubDispatch}>
        {children}
      </VirtualVenuePubnubDispatchContext.Provider>
    </VirtualVenuePubnubContext.Provider>
  );
}

export { VirtualVenuePubnubProvider, useVirtualVenuePubnub, useVirtualVenuePubnubDispatch };
