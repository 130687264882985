import { useMemo, useState } from 'react';

type Paginator<T> = {
  visible: T[];
  offscreen: T[];
  prevPage: (() => void) | undefined;
  nextPage: (() => void) | undefined;
};

function usePaginator<T = any>(elements: T[], pageSize: number): Paginator<T> {
  const [page, setPage] = useState<number>(0);
  const firstVisibleElementIdx = page * pageSize;
  const visible = elements.slice(firstVisibleElementIdx, firstVisibleElementIdx + pageSize);
  const offscreen = elements.slice(0, firstVisibleElementIdx).concat(elements.slice(firstVisibleElementIdx + pageSize));

  const numPages = Math.max(Math.ceil(elements.length / pageSize), 1);

  // Handle case where number of elements reduces and we're on a later page
  if (page > numPages - 1) {
    setPage(numPages - 1);
  }

  const prevPage = useMemo(() => (page > 0 ? () => setPage(page - 1) : undefined), [page]);
  const nextPage = useMemo(() => (page < numPages - 1 ? () => setPage(page + 1) : undefined), [numPages, page]);

  return {
    visible,
    offscreen,
    prevPage,
    nextPage,
  };
}

export default usePaginator;
