import { Loading } from 'components/common';
import { SignupForm } from 'components/auth-forms';
import { URLContext } from 'components/RoutingProvider';
import { analyticsAPI as analytics } from 'utils/api';
import { constructQueryString } from 'shared/shared/utils';
import { getURLParam } from 'utils/urls';
import { reverse } from 'router';
import AuthPageContext from 'components/context/AuthPageContext/AuthPageContext';
import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import React, { useContext } from 'react';
import useUser from 'utils/hooks/user/useUser';

const VirtualVenueSignupPage: React.FC = () => {
  const { isAuthenticated, loading } = useUser();
  const next = getURLParam('next', reverse('virtual_venue_success')) ?? reverse('virtual_venue_success');
  const name = getURLParam('name', '');
  const email = getURLParam('email', '');
  const plan = getURLParam('plan', '') ?? '';

  const { replacePath } = useContext(URLContext);
  const nextWithType = `${next}${constructQueryString({ plan })}`;

  if (loading) {
    return (
      <BaseLayout title="Virtual Venue Signup" hideTitle={true}>
        <Loading />
      </BaseLayout>
    );
  }

  if (isAuthenticated) {
    replacePath(nextWithType);
    return null;
  }

  return (
    <AuthPageContext.Provider value="signup">
      <BaseLayout title="Virtual Venue Signup" hideTitle={true}>
        <SignupForm
          next={next}
          name={name}
          email={email}
          hideFooter
          signupHeader={'Create your own Virtual Venue'}
          onSuccessfulSignup={() => {
            analytics.track('Virtual Venue Signup (Virtual Venue | Virtual Venue Signup Flow');
          }}
        />
      </BaseLayout>
    </AuthPageContext.Provider>
  );
};

export default VirtualVenueSignupPage;
