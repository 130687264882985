import { localStore } from 'utils/localstore';
import {
  useVirtualVenueDispatch,
  useVirtualVenueState,
} from 'components/VirtualVenue/contexts/VirtualVenueStateContext';
import { useVirtualVenueDisplay } from 'components/VirtualVenue/contexts/VirtualVenueDisplayContext';
import { useVirtualVenuePubnubDispatch } from 'components/VirtualVenue/contexts/VirtualVenuePubnubContext';
import ChatInputForm from 'components/VirtualVenue/VirtualVenueDisplay/layouts/VirtualVenueDisplayLayout/Chat/ChatFooter/ChatInputForm/ChatInputForm';
import ChatNameForm from 'components/VirtualVenue/VirtualVenueDisplay/layouts/VirtualVenueDisplayLayout/Chat/ChatFooter/ChatNameForm/ChatNameForm';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import usePrevious from 'utils/hooks/usePrevious';

type SettingName = false | 'from-init' | 'from-user';

const ChatFooter = () => {
  const venueState = useVirtualVenueState();
  const venueDispatch = useVirtualVenueDispatch();
  const pubnubDispatch = useVirtualVenuePubnubDispatch();
  const { name } = venueState;
  const [settingName, setSettingName] = useState<SettingName>(false);
  const { isLiveMode } = useVirtualVenueDisplay();

  const previousName = usePrevious(name);

  useEffect(() => {
    // If we're done loading the initial name, and it's blank, prompt user to set name
    if (previousName === null && name === '') {
      setSettingName('from-init');
    }
    if (name) {
      setSettingName(false);
    }
  }, [name, previousName]);

  return (
    <div className={classNames('px-4 py-4', !isLiveMode && 'pointer-events-none')}>
      {settingName ? (
        <ChatNameForm
          onSubmit={({ name }) => {
            localStore.venueDisplayName.set(name); // Cache anon name for future visitors
            venueDispatch({ type: 'set-name', name });
            pubnubDispatch({ type: 'set-name', name });
          }}
          defaultName={name || ''}
          autoFocus={settingName === 'from-user'}
        />
      ) : (
        <div>
          <div className="mb-1 text-xs hover:text-white">
            <span className="cursor-pointer chat-panel-name" onClick={() => setSettingName('from-user')}>
              {name}
            </span>
          </div>
          <ChatInputForm
            onSubmit={({ message }) => pubnubDispatch({ type: 'send-chat-message', message })}
            onFocus={() => {
              // Safeguard case. For some reason we aren't always receiving our own setState presence
              // event
              if (!name) {
                setSettingName('from-user');
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ChatFooter;
