import { analyticsAPI as analytics, mixilyAPI } from 'utils/api';
import { v4 as uuidv4 } from 'uuid';
import React, { ReactNode, useEffect, useState } from 'react';
import useInterval from 'utils/hooks/useInterval';

interface Props {
  accessToken: string;
  children: ReactNode;
  renderTooManyViewers: Function;
}

const viewerId = uuidv4();

const ViewerPresenceChecker = ({ accessToken, children, renderTooManyViewers }: Props) => {
  const [canView, setCanView] = useState<boolean>(true);
  useInterval(async () => {
    const resp = await mixilyAPI.checkViewerAccess(accessToken, viewerId);
    if (resp.canView !== undefined && resp.canView !== canView) {
      setCanView(resp.canView);
    }
  }, 10000);

  useEffect(() => {
    analytics.track(canView ? 'Virtual Venue Access Granted' : 'Virtual Venue Error - Too many devices', {
      accessToken,
      viewerId,
    });
  }, [canView, accessToken]);

  if (canView) {
    return <> {children} </>;
  }
  return renderTooManyViewers('Too many devices using this ticket, please reload the page');
};

export default ViewerPresenceChecker;
