import { Loading } from 'components/common';
import { ORDER_REFRESH_MUTATION } from 'utils/gql';
import { OrderStatus } from 'generated/globalTypes';
import { ReadOrder_order } from 'generated/ReadOrder';
import { RefreshOrder, RefreshOrderVariables } from 'generated/RefreshOrder';
import { popUrlParam } from 'utils/urls';
import { useMutation } from '@apollo/client';

import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import OrderCanceled from 'components/pages/OrderReadPage/OrderCanceled';
import OrderComplete from 'components/pages/OrderReadPage/OrderComplete';
import React, { useState } from 'react';

interface Props {
  order: ReadOrder_order;
}

const LOADING_RESPONSE = (
  <BaseLayout title="Loading...">
    <Loading />
  </BaseLayout>
);

const OrderReadView: React.FC<Props> = ({ order }) => {
  const [doMutation, { loading }] = useMutation<RefreshOrder, RefreshOrderVariables>(ORDER_REFRESH_MUTATION);
  const [reloading, setReloading] = useState<boolean>(false);

  if (loading || reloading) {
    return LOADING_RESPONSE;
  }

  if (loading || (popUrlParam('success') === '1' && order.status === OrderStatus.U)) {
    // Mismatch between Stripe redirect success and server value.
    // Maybe the server failed to process the webhook.
    doMutation({ variables: { id: order.id } }).then(() => {
      setReloading(true);
      // TODO: instead of reloading, query for order data in RefreshOrder mutation so
      // the Apollo cache updates
      window.location.reload();
    });

    return LOADING_RESPONSE;
  }

  if (order.status === OrderStatus.U) {
    return (
      <BaseLayout title="Payment cancelled">
        <OrderCanceled order={order} />
      </BaseLayout>
    );
  }
  // const success = order.total ? 'contribution' : 'order';
  const success = 'order'; // tmp hack for kristen
  return (
    <BaseLayout title={`Success! Thank you for your ${success}.`}>
      <OrderComplete order={order} />
    </BaseLayout>
  );
};

export default OrderReadView;
