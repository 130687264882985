import React, { useRef } from 'react';
import TileContainer from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/CallDisplay/TileContainer/TileContainer';
import useResizeObserver from 'utils/hooks/useResizeObserver';

// not DRY with GridLayout, useGridLayout

const MARGIN = 4; // px
const ASPECT_RATIO = 16 / 9;

const SampleVideoTiles = () => {
  const ref = useRef<HTMLDivElement>(null);
  const resizeEntry = useResizeObserver(ref);
  const containerWidth = resizeEntry?.contentRect.width;
  const containerHeight = resizeEntry?.contentRect.height;

  const numCols = containerWidth !== undefined ? (containerWidth > 600 ? 3 : containerWidth > 400 ? 2 : 1) : 1;

  const numRows = 3;

  // Calculate size of each grid cell that will contain a tile
  const wMarginSpace = MARGIN * (numCols + 1);
  const hMarginSpace = MARGIN * (numRows + 1);
  const availableWidth = (containerWidth || 100) - wMarginSpace;
  const availableHeight = (containerHeight || 100) - hMarginSpace;
  const targetWidth = Math.floor(availableWidth / numCols);
  const targetHeight = Math.floor(availableHeight / numRows);

  // How big can we get inside the grid cell?
  const targetAspectRatio = targetWidth / targetHeight;
  let adjustedWidth = targetWidth,
    adjustedHeight = targetHeight;
  if (ASPECT_RATIO <= targetAspectRatio) {
    adjustedWidth = Math.floor(targetHeight * ASPECT_RATIO);
  } else {
    adjustedHeight = Math.floor(targetWidth / ASPECT_RATIO);
  }

  const gridSize = {
    width: adjustedWidth * numCols + MARGIN * (numCols + 1),
    height: adjustedHeight * numRows + MARGIN * (numRows + 1),
  };

  return (
    <div className="flex items-start justify-center w-full h-full" style={{ padding: '16px 16px' }}>
      <div className="relative flex items-center justify-center w-full h-full" ref={ref}>
        <div style={{ height: `${gridSize.height}px`, width: `${gridSize.width}px` }}>
          <div className={`w-full mx-auto grid gap-1 grid-cols-${numCols}`}>
            {new Array(numCols * numRows).fill(0).map((_, index) => (
              <TileContainer id={`sample-${index}`} key={index}>
                {/* <img src={VideoTile} className="w-full opacity-75" /> */}
                {/* <div className="w-full h-full bg-gray-500 opacity-75" /> */}
                {/* <div className="w-full h-full bg-gray-500 opacity-90" /> */}
                {/* <div
                  className="w-full h-full bg-gray-500 opacity-75"
                  style={{
                    // background: `linear-gradient(135deg, var(--v-theme-bg-color) 0%, var(--v-theme-bg-color-light) 50%, var(--v-theme-bg-color) 100%)`,
                    boxShadow: 'inset 0px 0px 75px 0px rgba(0,0,0,0.2)',
                  }}
                /> */}
                <div
                  className="w-full h-full bg-gray-300 opacity-50"
                  style={{
                    // background: `linear-gradient(135deg, var(--v-theme-bg-color) 0%, var(--v-theme-bg-color-light) 50%, var(--v-theme-bg-color) 100%)`,
                    boxShadow: 'inset 0px 0px 5px 0px rgba(0,0,0,0.2)',
                  }}
                />
                {/* <div
                  className="w-full h-full bg-gray-500 opacity-90"
                  style={{
                    // background: `linear-gradient(135deg, var(--v-theme-bg-color) 0%, var(--v-theme-bg-color-light) 50%, var(--v-theme-bg-color) 100%)`,
                    boxShadow: 'inset 0px 0px 75px 0px rgba(0,0,0,0.2)',
                  }}
                /> */}
                {/* <div
                  className="w-full h-full opacity-75"
                  style={{
                    background: `linear-gradient(135deg, var(--v-theme-bg-color) 0%, var(--v-theme-bg-color-light) 50%, var(--v-theme-bg-color) 100%)`,
                    boxShadow: 'inset 0px 0px 75px 0px rgba(0,0,0,0.2)',
                  }}
                /> */}
                {/* <div
                  className="flex items-center justify-center w-full h-full opacity-50"
                  style={{
                    background: `linear-gradient(135deg, var(--v-theme-bg-color-dark) 0%, var(--v-theme-bg-color-light) 50%, var(--v-theme-bg-color-dark) 100%)`,
                    boxShadow: 'inset 0px 0px 75px 0px rgba(0,0,0,0.2)',
                  }}
                /> */}
                {/* <div
                  className="flex items-center justify-center w-full h-full opacity-50"
                  style={{
                    background: `linear-gradient(135deg, var(--v-theme-bg-color-dark) 0%, var(--v-theme-bg-color-light) 50%, var(--v-theme-bg-color-dark) 100%)`,
                    boxShadow: 'inset 0px 0px 75px 0px rgba(0,0,0,0.2)',
                  }}
                >
                  <IconSvg
                    name="user"
                    className="h-auto opacity-90"
                    style={{ color: 'var(--v-theme-bg-color-light)', width: '50%' }}
                  />
                </div> */}
                {/* <div
                  className="flex items-center justify-center w-full h-full bg-gray-500 opacity-50"
                  style={{
                    boxShadow: 'inset 0px 0px 75px 0px rgba(0,0,0,0.2)',
                  }}
                >
                  <IconSvg
                    name="user"
                    className="h-auto opacity-40"
                    style={{ color: 'var(--v-theme-bg-color-dark)', width: '50%' }}
                  />
                </div> */}
              </TileContainer>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SampleVideoTiles;
