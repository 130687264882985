enum GuestFilter {
  // These correspond to values in Python: models.Invite.GUEST_FILTERS,
  // and are valid arguments to the eventMessageGuests mutations' `filter` param
  All = 'a',
  Going = 'y',
  Maybe = 'm',
  Invited = 'i',
}

export { GuestFilter };
