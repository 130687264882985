import React from 'react';
import classNames from 'classnames';

interface Props {
  label: string;
  forId?: string;
  className?: string;
}

const FieldLabel: React.FC<Props> = (props) => {
  // string "FieldLabel" is used by outside consumers
  // uses inline-block so the click target isn't full-width
  const cls = classNames('inline-block', props.className);
  return (
    <div className={cls}>
      <label htmlFor={props.forId}>{props.label}</label>
    </div>
  );
};

export default FieldLabel;
