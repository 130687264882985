// Try to avoid using this in favor of uncontrolled-by-default forms, i.e. plain react-hook-form forms
//
// Wrapper around `useMixilyAsyncForm` that understands Mixily's mutation interface ({ok, errors, ...})
// and automatically sets form errors.
//
// Adds `isSubmitting`, and `successResult` to return value.
// The returned `SubmitButton` requires a `submittingLabel` prop

import { AsyncSubmitButtonProps2, MutationConfig, OnChange, Validators } from 'components/forms/types';
import { FormContextValues, useForm } from 'react-hook-form';
import React from 'react';
import useControlledForm from 'components/forms/useControlledForm';
import useMutationFormHelpers, { MutationFormHelpers } from 'components/forms/useMutationFormHelpers';

interface Props<T extends Record<string, any>, MutationT, MutationVariablesT>
  extends MutationConfig<T, MutationT, MutationVariablesT> {
  defaultValues: T;
  validators?: Validators<T>;
}

export interface ControlledMutationForm<T extends Record<string, any>, MutationT>
  extends MutationFormHelpers<T, MutationT> {
  isSubmitting: boolean;
  SubmitButton: React.ComponentType<AsyncSubmitButtonProps2>;
  successResult?: MutationT;
  onSubmit: (value: T) => void;
  onChange: OnChange<T>;
  formMethods: FormContextValues<T>;
}

function useControlledMutationForm<T extends Record<string, any>, MutationT, MutationVariablesT = T>(
  props: Props<T, MutationT, MutationVariablesT>
): ControlledMutationForm<T, MutationT> {
  const { defaultValues, validators } = props;
  const formContext = useForm<T>({ defaultValues });
  const mutationHelpers = useMutationFormHelpers({ ...props, formContext });
  const { clearMessages } = mutationHelpers;
  const { onChange } = useControlledForm({
    formContext,
    defaultValues,
    validators,
    clearMessages,
  });
  return {
    onChange,
    formMethods: formContext,
    ...mutationHelpers,
  };
}

export default useControlledMutationForm;
