import './Container.scss';
import React from 'react';
import classNames from 'classnames';

interface Props {
  id?: string;
  className?: string;
  wide?: boolean;
}

const Container: React.FC<Props> = ({ id, className, wide, children }) => {
  return (
    <div id={id} className={classNames('Container', { wide }, className)}>
      {children}
    </div>
  );
};

export default Container;
