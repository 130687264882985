// Provides four of the (curently) six contexts we need for virtual venue.
//
// Provides: VirtualVenueContext, VirtualVenueStateContext, VirtualVenueContentTypeContext, VirtualVenuePubnubContext
// Does not provide: VirtualVenueDisplayContext (required), VirtualVenueThemeContext (required), VirtualVenueEditContext (optional)

import { VirtualVenueContentTypeProvider } from 'components/VirtualVenue/contexts/VirtualVenueContentTypeContext';
import { VirtualVenueData } from 'components/VirtualVenue/types';
import { VirtualVenueProvider } from 'components/VirtualVenue/contexts/VirtualVenueContext';
import { VirtualVenueStateProvider } from 'components/VirtualVenue/contexts/VirtualVenueStateContext';
import { getContentType, getStreamUrl } from 'components/VirtualVenue/utils';
import PubnubProvider from 'components/VirtualVenue/PubnubProvider';
import React from 'react';
import useWindowSize from 'utils/hooks/useWindowSize';

interface Props {
  virtualVenue: VirtualVenueData;
  children: React.ReactNode;
}

const VirtualVenueOuter = (props: Props) => {
  const { virtualVenue } = props;
  const { isMobileXs } = useWindowSize();
  return (
    <VirtualVenueProvider value={virtualVenue}>
      <VirtualVenueStateProvider initialState={{ chatOpen: !isMobileXs }}>
        <VirtualVenueContentTypeProvider contentType={getContentType(virtualVenue, getStreamUrl(virtualVenue))}>
          <PubnubProvider>{props.children}</PubnubProvider>
        </VirtualVenueContentTypeProvider>
      </VirtualVenueStateProvider>
    </VirtualVenueProvider>
  );
};

export default VirtualVenueOuter;
