import * as React from 'react';
import { DailyAction } from 'components/VirtualVenue/daily/actions';
import { DailyState } from 'components/VirtualVenue/daily/state';

type VirtualVenueCallContextT = {
  dailyState: DailyState;
  dailyDispatch: React.Dispatch<DailyAction>;
};

const VirtualVenueCallContext = React.createContext<VirtualVenueCallContextT | undefined>(undefined);

function useVirtualVenueCall(): VirtualVenueCallContextT;
function useVirtualVenueCall(optional: true): VirtualVenueCallContextT | undefined;
function useVirtualVenueCall(optional?: boolean): VirtualVenueCallContextT | undefined {
  const context = React.useContext(VirtualVenueCallContext);
  if (!optional && context === undefined) {
    throw new Error('useVirtualVenueCall with optional:false must be used within a VirtualVenueCallProvider');
  }
  return context;
}

type VirtualVenueCallDispatchContextT = React.Dispatch<DailyAction>;

const VirtualVenueCallDispatchContext = React.createContext<VirtualVenueCallDispatchContextT | undefined>(undefined);

function useVirtualVenueCallDispatch(): VirtualVenueCallDispatchContextT;
function useVirtualVenueCallDispatch(optional: true): VirtualVenueCallDispatchContextT | undefined;
function useVirtualVenueCallDispatch(optional?: boolean): VirtualVenueCallDispatchContextT | undefined {
  const context = React.useContext(VirtualVenueCallDispatchContext);
  if (!optional && context === undefined) {
    throw new Error('useVirtualVenueCallDispatch with optional:false must be used within a VirtualVenueCallProvider');
  }
  return context;
}

interface Props {
  children: React.ReactNode;
  value: VirtualVenueCallContextT;
}

function VirtualVenueCallProvider({ children, value }: Props) {
  return (
    <VirtualVenueCallContext.Provider value={value}>
      <VirtualVenueCallDispatchContext.Provider value={value.dailyDispatch}>
        {children}
      </VirtualVenueCallDispatchContext.Provider>
    </VirtualVenueCallContext.Provider>
  );
}

export { VirtualVenueCallProvider, useVirtualVenueCall, useVirtualVenueCallDispatch };
