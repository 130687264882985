import 'whatwg-fetch';
import { API_TOKEN_HEADER_NAME } from 'shared/shared/constants';
import { IZoomMeetingOptions } from 'models';
import { constructQueryString } from 'shared/shared/utils';
import { isIframed } from 'utils/embedded';
import { isUploadCareUrl } from 'utils/upload-care';
import { localStore } from 'utils/localstore';
import { memoize, removeStopWords } from 'utils/helpers';
import Cookie from 'js-cookie';
import Pusher from 'pusher-js';
import config from 'config';

interface FetchData {
  params?: object;
  body?: object | string;
  headers?: Headers | string[][] | Record<string, string> | undefined;
}

const fetchJSON = (method: string) => (url: string, data: FetchData = {}): Promise<any> => {
  let queryString = '';

  if (data.params) {
    const params = new URLSearchParams();
    Object.entries(data.params).forEach(([k, v]) => params.set(k, v.toString()));
    queryString = `?${params.toString()}`;
  }

  const body = typeof data.body === 'object' ? JSON.stringify(data.body) : data.body;

  return fetch(`${url}${queryString}`, { ...data, body, method } as RequestInit).then((resp) => resp.json());
};

const json = {
  get: fetchJSON('get'),
  post: fetchJSON('post'),
  delete: fetchJSON('delete'),
};

export const getBaseHeaders = () => {
  const csrfToken =
    (isIframed ? document.getElementById('_csrf')?.getAttribute('data-token') : Cookie.get('csrftoken')) ?? '1';

  const headers: Record<string, string> = {
    'X-Path-Origin': window.location.pathname,
    'X-Csrf-Token': csrfToken,
  };
  const token = localStore.apitoken.get();
  if (token) {
    headers[API_TOKEN_HEADER_NAME] = token;
  }
  return headers;
};

const getJsonHeaders = () => ({
  ...getBaseHeaders(),
  'Content-Type': 'application/json',
});

export const mixilyAPI = {
  uploadImageUrl: (imageUrl: string) =>
    json.post('/api/upload/image-url/upload-care', {
      body: { image_url: imageUrl },
      headers: getJsonHeaders(),
    }),

  getUploadCareUrl: (imageUrl: string) => {
    if (isUploadCareUrl(imageUrl)) {
      return Promise.resolve(imageUrl);
    }

    return mixilyAPI.uploadImageUrl(imageUrl).then((json) => json.data);
  },

  connectStripeAccount: (code: string) => {
    return json.post('/api/stripe/connect', {
      body: { code },
      headers: getJsonHeaders(),
    });
  },

  deauthorizeStripeAccount: (accountId: string) => {
    return json.post('/api/stripe/deauthorize', {
      body: { accountId },
      headers: getJsonHeaders(),
    });
  },

  connectZoomAccount: (code: string) => {
    return json.post('/api/zoom/connect', {
      body: { code },
      headers: getJsonHeaders(),
    });
  },

  createZoomMeeting: (options: IZoomMeetingOptions) => {
    return json.post('/api/zoom/create', {
      body: { options },
      headers: getJsonHeaders(),
    });
  },

  checkViewerAccess: (accessCode: string, id: string) => {
    return json.post(`/viewer/presence/${accessCode}`, {
      body: {
        id,
      },
      headers: {
        ...getJsonHeaders(),
      },
    });
  },

  createBillingPortalSession: () => {
    return json.post(`/api/stripe/billing-portal-session`, {
      headers: {
        ...getJsonHeaders(),
      },
    });
  },

  authorizeZapier: (client_id: string, state: string, redirect_uri: string) => {
    return json.get(
      '/api/zapier/authorize' + constructQueryString({ client_id, state, redirect_uri, response_type: 'code' }),
      {
        headers: {
          ...getJsonHeaders(),
        },
      }
    );
  },
};

export const analyticsAPI = {
  track: (eventName: string, properties?: object) =>
    json.post('/analytics/track', {
      body: {
        eventName,
        ...properties,
      },
      headers: getJsonHeaders(),
    }),
  page: () =>
    json.post('/analytics/page', {
      body: {
        query: window.location.search,
        referrer: document.referrer,
        path: window.location.pathname,
        host: window.location.host,
        url: window.location.href,
        title: document.title,
      },
      headers: getJsonHeaders(),
    }),
  identify: (userId: string, traits: object) =>
    json.post('/analytics/identify', {
      body: {
        userId,
        ...traits,
      },
      headers: getJsonHeaders(),
    }),
};

const transformUnsplashResult = (result: any) => ({
  id: result.id,
  themePic: result.urls.full,
  themePicThumb: result.urls.thumb,
  title: result.description || result.alt_description,
  authorName: result.user.name,
  authorUrl: `${result.links.html}?utm_source=mixily&utm_medium=referral`,
  downloadLocation: result.links.download_location,
});

// NOTE(nick): https://help.unsplash.com/en/articles/2511245-unsplash-api-guidelines
export const unsplashAPI = {
  baseURL: '/api/unsplash',

  search: (text: string) =>
    json.get(`${unsplashAPI.baseURL}/search/photos`, {
      params: { query: text, per_page: 20 },
    }),

  photos: () =>
    json.get(`${unsplashAPI.baseURL}/photos`, {
      params: {
        per_page: 20,
        order_by: 'popular',
      },
    }),

  trackDownload: (downloadLocation: any) => {
    // NOTE(nick): we need to replace the url unsplash gives us with our proxy
    const proxyDownloadUrl = downloadLocation.replace('https://api.unsplash.com', unsplashAPI.baseURL);
    return json.get(proxyDownloadUrl);
  },

  getRandom: (query: string, params = {}) =>
    json.get(`${unsplashAPI.baseURL}/photos/random`, {
      params: { ...params, query },
    }),

  getRandomSourceImageUrl: (query: string): Promise<string> => {
    if (!query) {
      return Promise.resolve('');
    }

    const keywords = removeStopWords(query).split(' ').join(',');
    return fetch(`https://source.unsplash.com/random/?${keywords}`).then((resp) => resp.url);
  },

  getRandomCoverArt: (query: string) => {
    if (!query) {
      return Promise.resolve();
    }

    return unsplashAPI
      .getRandom(query, {
        featured: 1,
        orientation: 'landscape',
      })
      .then((result) => !result.errors && transformUnsplashResult(result));
  },

  getItems: memoize((value: any) => {
    return (value ? unsplashAPI.search(value).then((data) => data.results) : unsplashAPI.photos()).then((data) =>
      data.map(transformUnsplashResult)
    );
  }),
};

type ListRecordingsParam = {
  room_name: string;
  limit?: number;
  starting_after?: string;
  ending_before?: string;
};

export const dailyAPI = {
  baseURL: '/api/daily',

  listRecordings: (venueId: string, params: ListRecordingsParam) => {
    const filteredParams: { [x in string]: any } = {};
    for (const [key, value] of Object.entries(params)) {
      if (value) filteredParams[key] = value;
    }
    return json.get(`${dailyAPI.baseURL}/recordings/${venueId}`, {
      params: filteredParams,
      headers: getJsonHeaders(),
    });
  },

  getRecording: (venueId: string, recordingId: string) =>
    json.get(`${dailyAPI.baseURL}/recordings/${venueId}/${recordingId}`, {
      headers: getJsonHeaders(),
    }),

  getRecordingAccessLink: (recordingId: string) =>
    json.get(`${dailyAPI.baseURL}/recordings/${recordingId}/access-link`, {
      headers: getJsonHeaders(),
    }),

  getCompositeRecordingAccessLink: (recordingId: string, compositeId: string) =>
    json.get(`${dailyAPI.baseURL}/recordings/${recordingId}/${compositeId}/composite-access-link`, {
      headers: getJsonHeaders(),
    }),

  deleteRecording: (venueId: string, recordingId: string) =>
    json.delete(`${dailyAPI.baseURL}/recordings/${venueId}/${recordingId}`, {
      headers: getJsonHeaders(),
    }),
};

const GIPHY_API_KEY = 'T3uKIdCFgjYWVajwVVH9i9YN3jEghCCT';

export const giphyAPI = {
  baseURL: 'https://api.giphy.com/v1/gifs',

  search: (q: string, params = {}) =>
    json.get(`${giphyAPI.baseURL}/search`, {
      params: {
        limit: 24,
        offset: 0,
        rating: 'G',
        lang: 'en',
        ...params,
        api_key: GIPHY_API_KEY,
        q,
      },
    }),

  getItems: memoize((query: string) => {
    return giphyAPI.search(query).then((resp) => {
      return resp.data.map((item: any) => ({
        id: item.id,
        type: item.type,
        themePic: item.images.downsized.url,
        themePicThumb: item.images.downsized.url,
        title: '',
      }));
    });
  }),
};

export const getPusher = () =>
  new Pusher(config.pusherKey, {
    cluster: config.pusherCluster,
    authEndpoint: '/pusher/auth',
    auth: {
      headers: getBaseHeaders(),
      params: undefined,
    },
  });
