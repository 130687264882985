// A span that acts like a link, with cursor:pointer;

import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from 'components/common/TextLink.module.scss';

interface Props {
  children?: ReactNode;
  onClick?: (ev: any) => void;
  text?: string; // deprecated - prefer to use props.children
  className?: string;
}

const TextLink = (props: Props) => {
  const cls = classNames(styles.TextLink, props.className);
  return (
    <div className={cls} onClick={props.onClick} tabIndex={0}>
      {props.text || props.children}
    </div>
  );
};

export default TextLink;
