import { EVENTS_FOR_PERSON_QUERY } from 'utils/gql';
import { EventsForPerson } from 'generated/EventsForPerson';
import { List, ListItem } from '@material-ui/core';
import { Listing_listing_listingEvents_event } from 'generated/Listing';
import { getImageStyle } from 'utils/helpers';
import { parseEvent } from 'models';
import { renderFullTime } from 'models/event';
import { useQuery } from '@apollo/client';
import AddButton from 'components/common/AddButton/AddButton';
import Dialog from 'components/common/Dialog/Dialog';
import React from 'react';
import styles from './ListingEventSelectorDialog.module.scss';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSelectEvent: (event: Listing_listing_listingEvents_event) => void;
  eventIds: string[];
}

const ListingEventSelectorDialog = ({ isOpen, setIsOpen, onSelectEvent, eventIds }: Props) => {
  const { data } = useQuery<EventsForPerson>(EVENTS_FOR_PERSON_QUERY);
  const upcomingEvents = (data?.me?.upcoming ?? []).filter((event) => !eventIds.includes(event.id));

  function close() {
    setIsOpen(false);
  }

  return (
    <>
      <AddButton onClick={() => setIsOpen(true)} className="mt-4 ml-3">
        Add {eventIds.length > 0 ? 'another' : 'an'} event
      </AddButton>
      <Dialog title="Select an event" isOpen={isOpen} onClose={close} contentClassName={styles.dialogContent}>
        <List>
          {upcomingEvents.map((event) => (
            <ListItem
              button
              onClick={() => {
                onSelectEvent(event);
                close();
              }}
              key={event.id}
              className="flex items-center"
            >
              <div
                className="flex-shrink-0 w-10 h-10 rounded-full"
                style={event.themePic ? getImageStyle(event.themePic, 400) : {}}
              />
              <div className="flex items-baseline ml-4">
                <div>{event.title}</div>
                <div className="ml-4 text-sm opacity-50">{renderFullTime(parseEvent(event))}</div>
              </div>
            </ListItem>
          ))}
        </List>
      </Dialog>
    </>
  );
};

export default ListingEventSelectorDialog;
