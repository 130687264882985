import * as m from 'models';
import { Contact } from 'components/pages/EventReadPage/InvitationSender/InviteForm/SelectInviteesWidget/types';
import { Controller, useForm } from 'react-hook-form';
import { CreateInvite, CreateInviteVariables } from 'generated/CreateInvite';
import { EVENT_GUEST_LIST_QUERY } from 'utils/gql';
import { EventGuestList, EventGuestListVariables } from 'generated/EventGuestList';
import { EventPermissions } from 'utils/hooks/event/useEventPermissions';
import { Moment } from 'moment';
import { addSnackbarMessage } from 'utils/eventEmitter';
import { analyticsAPI as analytics } from 'utils/api';
import { getSubmitButtonText } from 'components/MessageCenter/utils';
import { localStore } from 'utils/localstore';
import { oxford } from 'shared/shared/utils';
import { useInviteMutation } from './helpers';
import { useQuery } from '@apollo/client';
import EmailPreviewModal, { EmailPreview } from 'components/EmailPreviewModal';
import FieldLayout from 'components/common/FieldLayout/FieldLayout';
import InviteMenu from 'components/pages/EventReadPage/InvitationSender/InviteForm/InviteMenu';
import React, { useEffect, useState } from 'react';
import ScheduleTimeDialog from 'components/ScheduleTimeDialog';
import SelectInviteesWidget from 'components/pages/EventReadPage/InvitationSender/InviteForm/SelectInviteesWidget/SelectInviteesWidget';
import classNames from 'classnames';
import styles from './InviteFormInner.module.scss';
import useMutationFormHelpers from 'components/forms/useMutationFormHelpers';

interface Props {
  event: m.Event;
  excludeItems: Set<{}>;
  myPermissions: EventPermissions;
  user: m.User;
}

type FormT = {
  emails: Contact[];
};

const onSuccess = (data: CreateInvite) => {
  const createdNames: string[] = [];
  const duplicatedNames: string[] = [];
  const hostNames: string[] = [];
  const failedNames: string[] = [];

  document.dispatchEvent(new CustomEvent('refetch-guests'));

  for (let x of data.inviteCreate.inviteResults!) {
    const { result, invite } = x;
    const name = invite.invitedByEmail;
    if (result === 'CREATED') {
      createdNames.push(name);
    } else if (result === 'DUPLICATE') {
      duplicatedNames.push(name);
    } else if (result === 'HOST_SELF_INVITE') {
      hostNames.push(name);
    } else if (result === 'FAILED') {
      failedNames.push(name);
    } else {
      throw new Error('Unknown invitation result type: ' + result);
    }
  }
  analytics.track('InviteGuests');
  // Order matters - last one appears on top
  if (failedNames.length) {
    addSnackbarMessage(`We couldn't email: ${oxford(failedNames)}.`, 'error');
  }
  if (duplicatedNames.length) {
    addSnackbarMessage(`Already invited: ${oxford(duplicatedNames)}.`, 'info');
  }
  if (hostNames.length) {
    addSnackbarMessage(`You're already invited!`, 'info');
  }
  if (createdNames.length) {
    addSnackbarMessage(`You invited: ${oxford(createdNames)}.`, 'success');
  }
};

const InviteFormInner = (props: Props) => {
  const formContext = useForm<FormT>({ defaultValues: { emails: [] } });
  const [showScheduler, setShowSchedule] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [scheduledFor, setScheduledFor] = useState<Moment | null>(null);

  const { event, excludeItems, myPermissions, user } = props;
  const eventId = event.id;
  const { watch, errors, control } = formContext;

  const { refetch } = useQuery<EventGuestList, EventGuestListVariables>(EVENT_GUEST_LIST_QUERY, {
    variables: { id: event.id },
  });

  useEffect(() => {
    // On initial load, recover any pending invitees from local cache
    const invitees = localStore.pendingInvitees.get(eventId) || [];
    formContext.setValue('emails', invitees);

    // Cache pending invitees before unloading page. This is helpful when someone goes to make
    // a last-minute edit to their event before sending.
    return () => localStore.pendingInvitees.set(eventId, watch('emails'));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doInvite = useInviteMutation(eventId);

  const formHelpers = useMutationFormHelpers<FormT, CreateInvite, CreateInviteVariables>({
    formContext,
    formToVariables: (formData) => ({ eventId, invitees: formData.emails, scheduledFor: scheduledFor?.toISOString() }),
    mutation: doInvite,
    resultKey: 'inviteCreate',
    onSuccess: (data) => {
      localStore.pendingInvitees.set(eventId, []);
      refetch();
      formContext.reset();
      onSuccess(data);
    },
  });
  const { Form, SubmitButton, FormLevelMessages, isSubmitting, FormStateContextProvider, onSubmit } = formHelpers;

  const labels = getSubmitButtonText(scheduledFor);

  return (
    <FormStateContextProvider onSubmit={onSubmit} isSubmitting={isSubmitting} formContext={formContext}>
      <Form>
        <FieldLayout error={errors.emails as any}>
          <Controller
            as={SelectInviteesWidget}
            name="emails"
            control={control}
            rules={{
              validate: (value) => (value.length ? true : 'This field is required'),
            }}
            disabled={isSubmitting}
            value={(undefined as unknown) as any}
            attrs={{
              excludeItems: excludeItems,
              eventId: event.id,
              user: user,
            }}
          />
        </FieldLayout>

        <FormLevelMessages className="mt-4" />
        <div className={classNames(styles.SubmitButtonWrapper, 'mt-2')}>
          <SubmitButton className="truncate h-14" label={labels[0]} submittingLabel={labels[1]} size="14" />
          <InviteMenu
            nameMenu="invite-menu"
            onSchedule={myPermissions.canScheduleInvites ? () => setShowSchedule(true) : undefined}
            onClear={scheduledFor ? () => setScheduledFor(null) : undefined}
            onPreview={() => setShowPreview(true)}
          />
        </div>
      </Form>
      <ScheduleTimeDialog
        modalOpen={showScheduler}
        onClose={() => setShowSchedule(false)}
        handleSubmit={(formData) => setScheduledFor(formData.scheduledFor)}
      />
      <EmailPreviewModal
        emailPreviewType={EmailPreview.INVITE}
        eventId={event.id}
        isOpen={showPreview}
        onClose={() => setShowPreview(false)}
      />
    </FormStateContextProvider>
  );
};

export default InviteFormInner;
