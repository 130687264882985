import * as F from 'shared/shared/Functional';
import { DiscountCode } from 'models';
import { EventType } from 'components/EventCreateUpdateForm/EventTypeWidget/EventTypeWidget';
import { LOADING_INDICATOR_CLASS } from 'utils/constants';
import { TicketTypes } from 'components/EventCreateUpdateForm/TicketTypesWidget/TicketTypesWidget';
import { isFixed } from 'components/EventCreateUpdateForm/TicketTypesWidget/TicketTypeWidget';

const eventCreateUpdateValidators = {
  title: {
    required: true,
  },
  eventType: {
    required: true,
  },
  description: {
    validate: (value: string) =>
      value.includes(LOADING_INDICATOR_CLASS) ? 'Please wait for all images to finish uploading.' : null,
  },
  options: {
    required: true,
  },
};

const makeTicketsValidator = (getValuesRef: React.MutableRefObject<any>) => {
  return {
    ticketTypes: {
      validate: (ticketTypes: TicketTypes) => {
        if (!getValuesRef.current) {
          return;
        }
        const formValues = getValuesRef.current();
        if (!formValues) {
          return;
        }
        if (formValues.eventType === EventType.FREE) {
          return {};
        }
        const len = ticketTypes.length;
        let num = 1;

        function ticketRef(len: number, num: number): string {
          return len > 1 ? `Ticket Type ${num}: ` : '';
        }

        for (const ticketType of ticketTypes) {
          if (len > 1 && !ticketType.name) return `${ticketRef(len, num)}Name is required`;
          if (!ticketType.description) return `${ticketRef(len, num)}Description is required`;
          if (ticketType.capacity && ticketType.capacity < 0)
            return `${ticketRef(len, num)}Capacity cannot be negative`;
          if (isFixed(ticketType)) {
            const fixedCents = ticketType.fixedPriceCents;
            if (fixedCents && fixedCents < 0) return `${ticketRef(len, num)}Ticket price cannot be negative`;
          } else {
            const minCents = ticketType.suggestedDonationMinCents;
            const defaultCents = ticketType.suggestedDonationDefaultCents;
            if (minCents && minCents < 0) return `${ticketRef(len, num)}Minimum contribution can't be negative`;
            if (defaultCents && defaultCents < 0)
              return `${ticketRef(len, num)}Suggested contribution cannot be negative`;
            if (defaultCents && minCents && defaultCents < minCents) {
              return `${ticketRef(len, num)}Suggested contribution can't be less than the minimum`;
            }
          }
          num++;
        }
      },
    },
  };
};

const makeDiscountCodesValidator = () => {
  return {
    discountCodes: {
      validate: (discountCodes: DiscountCode[]) => {
        // Validate uniqueness
        const seen = new Set<string>();
        for (const c of discountCodes.map(F.prop('code'))) {
          const normedCode = c.toUpperCase();
          if (seen.has(normedCode)) {
            return `You can't have more than one code for '${c}'`;
          }
          seen.add(normedCode);
        }

        // Per-code validations
        const len = discountCodes.length;
        let num = 1;
        function discountCodeRef(len: number, num: number): string {
          return len > 1 ? `Discount Code ${num}: ` : '';
        }

        for (const discountCode of discountCodes) {
          if (len > 1 && !discountCode.code) {
            return `${discountCodeRef(len, num)}Code is required`;
          }
          if (discountCode.amount == null) {
            return `${discountCodeRef(len, num)}Amount is required`;
          }
          if (discountCode.amount && discountCode.amount <= 0) {
            return `${discountCodeRef(len, num)}Amount must be positive`;
          }
          num++;
        }

        return null;
      },
    },
  };
};

const makeValidators = (getValuesRef: React.MutableRefObject<any>) => {
  return {
    ...eventCreateUpdateValidators,
    ...makeTicketsValidator(getValuesRef),
    ...makeDiscountCodesValidator(),
  };
};

export default makeValidators;
