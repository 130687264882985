import * as m from 'models';
import { Channel } from 'pusher-js';
import { EventCommentsSkeleton } from 'components/pages/EventReadPage/EventReadViewSkeleton';
import { THREAD_QUERY } from 'utils/gql';
import { ThreadComment, groupCommentsByReplies } from 'models/comment';
import { ThreadQuery, ThreadQueryVariables } from 'generated/ThreadQuery';
import { useQuery } from '@apollo/client';

import CommentInput from 'components/pages/EventReadPage/comments/CommentInput';
import Comments from 'components/pages/EventReadPage/comments/Comments';
import React, { useEffect, useState } from 'react';
import SignupToComment from 'components/pages/EventReadPage/comments/SignupToComment';
import styles from './CommentView.module.scss';
import usePusherSubscribe from 'utils/hooks/usePusherSubscribe';
import useThreadHandler from 'utils/hooks/useThreadHandler';

interface Props {
  comments: ThreadComment[];
  eventId: string;
  threadId: string;
  updateThread: () => void;
  user: m.User;
}

const CommentViewInner = ({ comments, eventId, threadId, updateThread, user }: Props) => {
  const [highlightId, setHighlightId] = useState<string>('');

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const commentId = params.get('comment_id');
    if (commentId) {
      const el = document.getElementById(`comment-${commentId}`);
      el && el.scrollIntoView();
      setHighlightId(commentId);
    }
  }, []);

  const groupedComments = groupCommentsByReplies(comments);
  const canReply = m.isAuthenticated(user);

  return (
    <div className={styles.CommentView}>
      {canReply ? <CommentInput onSubmit={updateThread} eventId={eventId} threadId={threadId} /> : <SignupToComment />}

      {!!groupedComments.length && (
        <Comments
          comments={groupedComments}
          eventId={eventId}
          threadId={threadId}
          canReply={canReply}
          highlightId={highlightId}
          onCommentCreate={updateThread}
        />
      )}
    </div>
  );
};

interface OuterProps {
  threadId: string;
  eventId: string;
  user: m.User;
}

const CommentView = ({ threadId, eventId, user }: OuterProps) => {
  const { loading, error, data, refetch } = useQuery<ThreadQuery, ThreadQueryVariables>(THREAD_QUERY, {
    variables: {
      id: threadId,
    },
  });

  useEffect(() => {
    refetch();
  }, [refetch, user]);

  const { processEvent } = useThreadHandler(threadId);
  const threadSubscribe = (channel: Channel) => {
    const events = ['new-comment', 'edit-comment', 'delete-comment'];

    for (const eventName of events) {
      channel.bind(eventName, () => {
        processEvent(eventName);
        refetch();
      });
    }
  };
  const thread = data?.thread;
  const comments = thread?.comments || [];
  const channel = thread?.channel || '';

  usePusherSubscribe(channel, threadSubscribe);

  if (loading) {
    return <EventCommentsSkeleton />;
  }
  if (error) {
    return <div>Failed to fetch comments.</div>;
  }

  return (
    <CommentViewInner comments={comments} eventId={eventId} threadId={threadId} updateThread={refetch} user={user} />
  );
};

export default CommentView;
