import {
  isUnsupportedDesktopSafari,
  isUnsupportedMobile,
  isUnsupportedMobileSafari,
} from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/utils';
import DailyIframe from '@daily-co/daily-js';
import IconSvg from 'components/common/IconSvg/IconSvg';
import React, { ReactNode } from 'react';
import config from 'config';

export type CompatibilityErrorT =
  | 'unsupported-mobile-safari'
  | 'unsupported-desktop-safari'
  | 'unsupported-mobile'
  | 'unsupported-browser'
  | 'insecure-http'
  | undefined;

const isHTTPS = /^https:/i;

export const checkCompatibilityError = (isEmbedded: boolean): CompatibilityErrorT => {
  if (isUnsupportedDesktopSafari()) {
    return 'unsupported-desktop-safari';
  }

  if (isUnsupportedMobileSafari()) {
    return 'unsupported-mobile-safari';
  }

  if (isUnsupportedMobile()) {
    return 'unsupported-mobile';
  }

  if (!DailyIframe.supportedBrowser().supported) {
    return 'unsupported-browser';
  }

  const url = isEmbedded ? document.referrer || window?.iFrameResizer?.parentUrl : document.location.href;
  if (url && !isHTTPS.test(url) && !config.isDev) {
    return 'insecure-http';
  }

  return;
};

interface Props {
  errorType: CompatibilityErrorT;
}

const CompatibilityError = ({ errorType }: Props) => {
  let errorMessage: ReactNode | undefined;

  switch (errorType) {
    case 'unsupported-desktop-safari':
      errorMessage = `Sorry, your version of Safari does not support video calls. Please join from Chrome or Firefox, or upgrade to the latest version of Safari.`;
      break;
    case 'unsupported-mobile-safari':
      errorMessage = `Sorry, your version of Safari does not support video calls. Please upgrade to the latest version of Safari.`;
      break;
    case 'unsupported-mobile':
      errorMessage = 'Looks like you need Safari to make video calls.';
      break;
    case 'unsupported-browser':
      errorMessage = 'Looks like you need to upgrade your browser to make video calls.';
      break;
    case 'insecure-http':
      errorMessage = (
        <>
          <div>Looks like you're visiting this virtual venue on an insecure HTTP website.</div>
          <div>
            <a href={`https:${window.location.href.substring(window.location.protocol.length)}`}>Click here</a> to
            redirect to the secure HTTPS website.
          </div>
        </>
      );
      break;
  }

  return (
    <div className="flex flex-col justify-center h-full mx-auto" style={{ color: 'var(--v-theme-title-text-color)' }}>
      <h3 className="flex items-center mb-4 md:mb-6 lg:mb-10">
        <IconSvg className="w-6 h-6 mr-2" name="exclamation" />
        <span>Compatibility Error</span>
      </h3>
      <div className="mb-4 md:mb-6 lg:mb-10">{errorMessage}</div>
      <div>
        Still experiencing problems? Try{' '}
        <a href="https://www.mixily.com/site/experiencing-problems">these troubleshooting tips</a>.
      </div>
    </div>
  );
};

export default CompatibilityError;
