import React from 'react';
import classNames from 'classnames';

import { uploadCareUrlScaleCrop } from 'utils/upload-care';

import styles from 'components/EventCreateUpdateForm/ImagePicker/ImagePicker.module.scss';

export interface Image {
  id: any;
  title: any;
  themePic: any;
  themePicThumb: any;
  authorName: any;
  authorUrl: any;
}

interface Props {
  title?: string;
  onClick: Function;
  size?: string;
  images: Image[];
}

const IPImageGrid: React.FC<Props> = ({ title = '', images, onClick, size = '' }) => {
  return (
    <div
      className={classNames(styles.ImageGrid, {
        [styles[`size-${size}`]]: true,
      })}
    >
      {title && <div className={styles.title}>{title}</div>}
      <div className={styles.inner}>
        {images.map((item, i: number) => (
          <div className={styles.Image} title={item.title} key={item.id || i}>
            <div
              className={styles.img}
              onClick={() => onClick(item.themePic, item)}
              style={{
                backgroundImage:
                  'url(' +
                  (item.themePicThumb ? item.themePicThumb : uploadCareUrlScaleCrop(item.themePic, 240, 144)) +
                  ')',
                backgroundSize: 'cover',
              }}
            />
            {item.authorName && (
              <div className={styles.attrib}>
                by{' '}
                <a target="_blank" rel="noopener noreferrer" href={item.authorUrl}>
                  {item.authorName}
                </a>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default IPImageGrid;
