import { BaseEmoji } from 'emoji-mart';
import { OnSubmit, useForm } from 'react-hook-form';
import { useVirtualVenueDisplay } from 'components/VirtualVenue/contexts/VirtualVenueDisplayContext';
import EmojiPicker from 'components/VirtualVenue/VirtualVenueDisplay/layouts/VirtualVenueDisplayLayout/Chat/ChatContent/ChatMessageDisplay/MessageMenu/EmojiPicker/EmojiPicker';
import FieldLayout from 'components/common/FieldLayout/FieldLayout';
import IconSvg from 'components/common/IconSvg/IconSvg';
import Input2 from 'components/common/Input2/Input2';
import React, { useRef, useState } from 'react';
import useWindowSize from 'utils/hooks/useWindowSize';

type FormT = {
  message: string;
};

interface Props {
  onSubmit: OnSubmit<FormT>;
  onFocus?: () => void;
}

const ChatInputForm = ({ onSubmit, onFocus }: Props) => {
  const formContext = useForm<FormT>({ defaultValues: { message: '' } });
  const { register, handleSubmit, errors, reset } = formContext;
  const { isLiveMode } = useVirtualVenueDisplay();

  const { width } = useWindowSize();
  const showEmoji = width >= 480; // todo: DRY Somehow?

  const inputRef = useRef<HTMLElement | null>(null);
  const emojiRef = useRef(null);

  const [emojiOpen, setEmojiOpen] = useState(false);

  return (
    <div>
      <form
        onSubmit={(result) => {
          handleSubmit(onSubmit)(result);
          reset();
        }}
      >
        <FieldLayout error={errors.message}>
          <Input2
            className="text-black rounded"
            name="message"
            ref={(el) => {
              register(el!);
              inputRef.current = el;
            }}
            required={true}
            disabled={!isLiveMode}
            onFocus={onFocus}
            autoComplete="off"
            endAdornment={
              showEmoji
                ? {
                    component: (
                      <div className="inline-block" ref={emojiRef} onClick={() => setEmojiOpen(!emojiOpen)}>
                        <IconSvg name="emojiHappy" className="w-6 h-6 text-gray-500 cursor-pointer" />
                      </div>
                    ),
                    paddingSize: 10,
                    clickable: true,
                  }
                : undefined
            }
            classes={{ root: '' }}
          />
        </FieldLayout>
      </form>
      <EmojiPicker
        isOpen={emojiOpen}
        anchorEl={emojiRef}
        onClose={() => setEmojiOpen(false)}
        onSelect={(emoji) => {
          setEmojiOpen(false);
          const oldMessage = formContext.watch('message');
          // Seeing a weird issue where emoji are overlapping with neighboring characters.
          // Hacky fix for now: add spaces on both sides.
          // Only on MacOS non-retina? https://bugs.chromium.org/p/chromium/issues/detail?id=1083965&q=emoji&can=2
          // and https://bugs.chromium.org/p/chromium/issues/detail?id=596223 - Fixed in next release?
          const newMessage = oldMessage + ` ${(emoji as BaseEmoji).native} `;
          formContext.setValue('message', newMessage);
          setTimeout(() => inputRef.current?.focus(), 50);
        }}
      />
    </div>
  );
};

export default ChatInputForm;
