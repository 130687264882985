import { getURLParam } from 'utils/urls';
import { localStore } from 'utils/localstore';
import { useCallConfigDispatch } from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/contexts/CallConfigContext';
import { useVirtualVenue } from 'components/VirtualVenue/contexts/VirtualVenueContext';
import {
  useVirtualVenueDispatch,
  useVirtualVenueState,
} from 'components/VirtualVenue/contexts/VirtualVenueStateContext';
import { useVirtualVenuePubnubDispatch } from 'components/VirtualVenue/contexts/VirtualVenuePubnubContext';
import React, { useState } from 'react';
import StartButton from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/screens/VirtualVenueLobby/StartButton/StartButton';
import VirtualLobbyNameForm from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/screens/VirtualVenueLobby/VirtualVenueLobbyNameForm/VirtualVenueLobbyNameForm';
import VirtualVenueLogo from 'components/VirtualVenue/VirtualVenueDisplay/layouts/VirtualVenueDisplayLayout/VirtualVenueHeader/VirtualVenueLogo';

interface Props {
  title: string;
  logo?: string;
  logoFilter?: string;
}

const VirtualVenueLobby = (props: Props) => {
  const { title, logo, logoFilter } = props;

  const venue = useVirtualVenue();
  const venueState = useVirtualVenueState();
  const venueDispatch = useVirtualVenueDispatch();
  const callConfigDispatch = useCallConfigDispatch();
  const pubnubDispatch = useVirtualVenuePubnubDispatch();

  const [joined, setJoined] = useState(false);

  const { name } = venueState;
  const nameFormId = 'virtal-lobby-name-form';
  const useOverlayStyling = !getURLParam('lobbyFullPage');

  return (
    <>
      <div className="mx-auto mb-3" style={{ maxWidth: useOverlayStyling ? '33%' : '20%' }}>
        <VirtualVenueLogo logo={logo} logoFilter={logoFilter} />
      </div>
      <div
        className="mb-4 text-center xs:text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl"
        style={{ color: 'var(--v-theme-title-text-color)', maxWidth: '85%' }}
      >
        {title}
      </div>
      <div className="flex flex-col items-center name-input-form justify-evenly">
        <div className="mb-4 md:mb-6 lg:mb-10">
          <VirtualLobbyNameForm
            name={name}
            formId={nameFormId}
            required={venue.requireName}
            onSubmit={({ name }) => {
              setJoined(true);
              if (name) {
                localStore.venueDisplayName.set(name); // Cache anon name for future visitors
                venueDispatch({ type: 'set-name', name });
                pubnubDispatch({ type: 'set-name', name });
              }

              callConfigDispatch({ type: 'set-desired-join-state', joinState: 'joined' });
            }}
          />
        </div>
        {/* This button is the form submit for the VirtualLobbyNameForm */}
        <StartButton
          className="h-8 text-lg rounded-full sm:h-10 lg:text-xl lg:py-6 xl:text-2xl xl:py-8"
          disabled={joined}
          formId={nameFormId}
        >
          {joined ? 'Joining...' : 'Click to Join'}
        </StartButton>
      </div>
    </>
  );
};
export default VirtualVenueLobby;
