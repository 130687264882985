import React from 'react';

import { AccountTokenLoginView } from 'components/pages/AccountTokenLoginPage';
import { reverse } from 'router';
import BaseLayout from 'components/layout/BaseLayout/BaseLayout';

interface Props {
  token: string;
}

const ResetPasswordTokenPage = (props: Props) => {
  const next = reverse('account_settings');
  return (
    <BaseLayout title="">
      <AccountTokenLoginView next={next} {...props} />
    </BaseLayout>
  );
};

export default ResetPasswordTokenPage;
