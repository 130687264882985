import {
  DAILY_CONNECTION_STATES,
  DAILY_RECORDING_STATES,
  RECORDING_ACTION_MESSAGE_MAP,
  initialDailyState,
} from 'components/VirtualVenue/daily/state';
import { dailyReducer } from 'components/VirtualVenue/daily/reducer';
import { logDailyEvent } from './utils';
import { useCallback, useEffect, useReducer } from 'react';
import DailyIframe, { DailyEventErrorObject, DailyEventObject, DailyEventObjectParticipant } from '@daily-co/daily-js';
import useDailyAppMessage from 'components/VirtualVenue/daily/useDailyAppMessage';

const useDailyCall = (dailyCallUrl: string, localName: string | null, dailyToken?: string) => {
  const [state, dispatch] = useReducer(dailyReducer, initialDailyState);
  const { connectionState, call: callData } = state;
  const { call, roomUrl } = callData || {};

  const beforeUnloadHandler = useCallback(
    (e: Event) => {
      if (call?.meetingState() === 'joined-meeting') {
        dispatch({ type: 'start-leaving-call' });
      }
    },
    [call]
  );

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnloadHandler);
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, [beforeUnloadHandler]);

  useEffect(() => {
    async function stateEffect() {
      switch (connectionState) {
        case DAILY_CONNECTION_STATES.STATE_CREATING:
          try {
            const call = DailyIframe.createCallObject();
            // (window as any).dailyCall = call; // Debug
            dispatch({
              type: 'start-joining-call',
              call,
              url: dailyCallUrl,
            });
          } catch (error) {
            dispatch({ type: 'call-connection-error' });
          }
          break;
        case DAILY_CONNECTION_STATES.STATE_JOINING:
        case DAILY_CONNECTION_STATES.STATE_REJOINING:
          // This triggers a change in call.meetingState(), which we watch for below
          call!.join({ url: roomUrl!, ...(dailyToken ? { token: dailyToken } : undefined) });
          break;
        case DAILY_CONNECTION_STATES.STATE_LEAVING:
          /**
           * NOTE (Daily): In this demo we show how to completely clean up a call with destroy(),
           * which requires creating a new call object before you can join() again.
           * This isn't strictly necessary, but is good practice when you know you'll
           * be done with the call object for a while and you're no longer listening to its
           * events.
           */
          await call?.leave();
          call?.destroy().then(() => {
            dispatch({ type: 'finish-leaving-call' });
          });
      }
    }
    stateEffect();
  }, [call, roomUrl, dailyCallUrl, dailyToken, connectionState]);

  // Update connection state based on meeting state changes reported by Daily client
  useEffect(() => {
    if (!call) return;

    const events = ['joined-meeting', 'left-meeting', 'error'] as const;

    function handleNewMeetingState(event?: DailyEventObject) {
      event && logDailyEvent(event);
      switch (call!.meetingState()) {
        case 'joined-meeting':
          dispatch({ type: 'set-join-succeeded', participants: event?.participants });
          break;
        case 'left-meeting':
          call?.destroy().then(() => {
            dispatch({ type: 'finish-leaving-call' });
          });
          break;
        case 'error':
          dispatch({ type: 'call-error' });
          break;
        default:
          break;
      }
    }

    // Use initial state
    handleNewMeetingState();

    // Listen for changes in state
    for (const event of events) {
      call.on(event, handleNewMeetingState);
    }

    // Stop listening for changes in state
    return () => {
      for (const event of events) {
        call.off(event, handleNewMeetingState);
      }
    };
  }, [call]);

  useEffect(() => {
    if (!call) return;

    const events = ['participant-joined', 'participant-updated', 'participant-left'] as const;

    function handleNewParticipantsState(event?: DailyEventObjectParticipant) {
      event && logDailyEvent(event);
      dispatch({
        type: 'participants-change',
        participants: call!.participants(),
      });
    }

    // Use initial state
    handleNewParticipantsState();

    // Listen for changes in state
    for (const event of events) {
      call.on(event, handleNewParticipantsState);
    }

    // Stop listening for changes in state
    return function cleanup() {
      for (const event of events) {
        call.off(event, handleNewParticipantsState);
      }
    };
  }, [call, dispatch]);

  /**
   * Start listening for recording events, when the callobject is set.
   */
  useEffect(() => {
    if (!call) return;
    const events: RecordingEvent[] = ['recording-started', 'recording-stopped', 'recording-error'];
    function handleRecordingEvent(event: DailyEventObject) {
      if (event.action.startsWith('recording') && RECORDING_ACTION_MESSAGE_MAP[event.action]) {
        dispatch({ type: event.action, recordingId: event.recordingId });
        call?.sendAppMessage({ message: RECORDING_ACTION_MESSAGE_MAP[event.action] });
      }
    }

    // Listen for changes in state
    for (const event of events) {
      call.on(event, handleRecordingEvent);
    }

    // Stop listening for changes in state
    return function cleanup() {
      for (const event of events) {
        call.off(event, handleRecordingEvent);
      }
    };
  }, [call, dispatch]);

  /**
   * Start listening for call errors, when the callObject is set.
   */
  useEffect(() => {
    if (!call) return;
    const events = ['camera-error', 'active-speaker-change'] as const;
    function handleMeetingEvent(event: DailyEventObject) {
      logDailyEvent(event);
      switch (event.action) {
        case 'camera-error':
          dispatch({
            type: 'cam-or-mic-error',
            message: (event && event.errorMsg && event.errorMsg.errorMsg) || 'Unknown',
          });
          break;
        case 'active-speaker-change':
          dispatch({
            type: 'active-speaker-change',
            activeSpeakerId: event.activeSpeaker.peerId,
          });
          break;
        default:
          break;
      }
    }

    // We're making an assumption here: there is no camera error when callObject
    // is first assigned.

    // Listen for changes in state
    for (const event of events) {
      call.on(event, handleMeetingEvent);
    }

    // Stop listening for changes in state
    return function cleanup() {
      for (const event of events) {
        call.off(event, handleMeetingEvent);
      }
    };
  }, [call, dispatch]);

  /**
   * Start listening for fatal errors, when the callObject is set.
   */
  useEffect(() => {
    if (!call) return;

    function handleErrorEvent(e?: DailyEventErrorObject) {
      e && logDailyEvent(e);
      dispatch({
        type: 'fatal-error',
        message: (e && e.errorMsg) || 'Unknown',
      });
      setTimeout(() => {
        dispatch({
          type: 'attempt-rejoining-call',
        });
      }, 2000);
    }

    // We're making an assumption here: there is no error when callObject is
    // first assigned.

    call.on('error', handleErrorEvent);

    return function cleanup() {
      call.off('error', handleErrorEvent);
    };
  }, [call, dailyCallUrl, dispatch]);

  // Clean up on unload
  useEffect(
    () => () => {
      if (call?.meetingState() === 'joined-meeting') {
        dispatch({ type: 'start-leaving-call' });
      }
    },
    [call]
  );
  type RecordingEvent = 'recording-started' | 'recording-stopped' | 'recording-error';
  useDailyAppMessage(call, (event?) => {
    if (event) {
      logDailyEvent(event);
      // If app message is about recording related events
      if (event.data.message in DAILY_RECORDING_STATES) {
        const recordingEventType = Object.keys(RECORDING_ACTION_MESSAGE_MAP).find(
          (action) => event.data.message === RECORDING_ACTION_MESSAGE_MAP[action]
        );
        // recordingId is only needed for the user that initiates the recording
        // Not needed for other daily app users who are the recipients of the DailyAppMessage
        dispatch({ type: recordingEventType as RecordingEvent, recordingId: null });
      }
    }
  });

  const meetingState = call?.meetingState();

  useEffect(() => {
    const currentName = call?.participants()?.local?.user_name;
    if (localName && localName !== currentName && meetingState === 'joined-meeting') {
      call?.setUserName(localName);
    }
  }, [call, localName, meetingState]);

  return [state, dispatch] as const;
};

export default useDailyCall;
