import React from 'react';
import classNames from 'classnames';
import styles from 'components/common/MCheckbox.module.scss';

interface CheckboxProps {
  value: boolean;
  onChange?: (value: boolean) => void;
  className?: string;
  text?: string;
}

class Checkbox extends React.Component<CheckboxProps> {
  onChange = () => {
    const { value, onChange } = this.props;

    if (onChange) onChange(!value);
  };

  render() {
    const { className, value, text } = this.props;

    const cn = classNames(styles.Checkbox, className, {
      [styles.checked]: value,
    });

    return (
      <div className={cn} onClick={this.onChange}>
        <input type="checkbox" checked={value} readOnly />
        {text && <label className={styles.label}>{text}</label>}
      </div>
    );
  }
}

export default Checkbox;
