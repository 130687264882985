import { TextLink } from 'components/common';
import Cookie from 'js-cookie';
import React, { ReactNode, useCallback } from 'react';
import useUser from 'utils/hooks/user/useUser';
import useUserChangeContext from 'components/App/useUserChangeContext';

interface Props {
  eventId: string;
  children: ReactNode;
}

const LogoutOrReset = (props: Props) => {
  const { eventId, children } = props;
  const { logout } = useUserChangeContext();
  const { isAuthenticated } = useUser();

  const logoutOrReset = useCallback(() => {
    Cookie.remove(`event_${eventId}_invite`);
    if (isAuthenticated) {
      logout(false);
    } else {
      window.location.replace(window.location.pathname);
    }
  }, [eventId, isAuthenticated, logout]);

  return <TextLink onClick={logoutOrReset}>{children}</TextLink>;
};

export default LogoutOrReset;
