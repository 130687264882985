import { Icon, TextLink } from 'components/common';
import { ThreadCommentWithReplies } from 'models/comment';
import { prettyTimeAgo } from 'utils/datetime';
import CommentInput from 'components/pages/EventReadPage/comments/CommentInput';
import Comments from 'components/pages/EventReadPage/comments/Comments';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import Dropdown from 'components/common/Dropdown/Dropdown';
import React, { useState } from 'react';
import classNames from 'classnames';
import linkifyHtml from 'linkifyjs/html';
import styles from './Comment.module.scss';

interface Props {
  comment: ThreadCommentWithReplies;
  onCommentCreate: (text?: string) => void;
  highlightId?: string;
  canReply: boolean;
  eventId: string;
  threadId: string;
}

const Comment = ({ eventId, threadId, comment, canReply, onCommentCreate, highlightId }: Props) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isReplying, setIsReplying] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const replyCommentId = ((comment) => {
    // enforce max-depth of 1
    while (comment!.depth && comment!.parent && comment!.depth >= 1) {
      comment = comment.parent;
    }
    return comment.id;
  })(comment);

  return (
    <>
      {isDeleting && (
        <ConfirmDeleteModal threadId={threadId} deleteId={comment.id} closeModal={() => setIsDeleting(false)} />
      )}
      <div
        id={`comment-${comment.id}`}
        className={classNames(styles.Comment, {
          [styles.loading]: !comment.id,
          [styles.highlight]: highlightId && highlightId === comment.id,
        })}
      >
        <div className={styles.body}>
          <div className={styles.content}>
            <div className={styles.left}>
              <div className={styles.meta}>
                <span>{comment.author ? comment.author.name : ''}</span>
                <span>&middot;</span>
                <span>
                  {comment.edited && <span>edited </span>}
                  {comment.updatedAt ? prettyTimeAgo(comment.updatedAt) : ''}
                </span>
              </div>

              {isEditing ? (
                <CommentInput
                  initialValue={comment.text}
                  isValid={(_: string) => true}
                  submitText="Save"
                  onSubmit={(text) => {
                    setIsEditing(false);
                    if (text) {
                      onCommentCreate();
                    } else {
                      setIsDeleting(true);
                    }
                  }}
                  onBlur={() => {
                    setIsEditing(false);
                  }}
                  commentId={comment.id}
                  eventId={eventId}
                  threadId={threadId}
                  autoFocus
                />
              ) : (
                <div>
                  <div className={styles.text}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: linkifyHtml(comment.text.replace(/(?:\r\n|\r|\n)/g, '<br>'), {
                          target: (href: string, type: string) => type === 'url' && '_blank',
                        }),
                      }}
                    />
                  </div>

                  <div className={styles.actions}>
                    {canReply && <TextLink onClick={() => setIsReplying(true)}>Reply</TextLink>}
                  </div>
                </div>
              )}
            </div>

            {comment.id && (comment.canEdit || comment.canDelete) && (
              <Dropdown
                className={styles.right}
                items={
                  [
                    comment.canEdit && {
                      text: 'Edit',
                      onClick: () => setIsEditing(true),
                    },
                    comment.canDelete && {
                      text: 'Delete',
                      onClick: () => setIsDeleting(true),
                    },
                  ].filter(Boolean) as any
                }
              >
                <Icon icon="threeDots" className={styles.icon} />
              </Dropdown>
            )}
          </div>
        </div>

        <div className={styles.replies}>
          {!!comment.replies?.length && (
            <Comments
              comments={comment.replies}
              eventId={eventId}
              threadId={threadId}
              canReply={canReply}
              highlightId={highlightId}
              onCommentCreate={onCommentCreate}
            />
          )}

          {isReplying && (
            <CommentInput
              submitText="Reply"
              onSubmit={() => {
                onCommentCreate();
                setIsReplying(false);
              }}
              onBlur={() => setIsReplying(false)}
              eventId={eventId}
              threadId={threadId}
              replyCommentId={replyCommentId}
              autoFocus
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Comment;
