import React from 'react';

type GlobalState = {
  eventId?: string;
  inviteId?: string;
  passwordEvent?: string;
  setEventId: (eventId: string | undefined) => void;
  setInviteId: (eventId: string | undefined) => void;
  setPasswordEvent: (password: string | undefined) => void;
};

const GlobalStateContext = React.createContext<GlobalState>({} as GlobalState);

export default GlobalStateContext;
