import { Link } from 'components/common';
import { reverse } from 'router';
import Icon from 'components/common/Icon/Icon';
import React from 'react';
import styles from './EventActionButton.module.scss';
import useEventId from 'components/App/globalstate/useEventId';

interface Props {
  isRun: boolean;
}

const EditEventButton = ({ isRun }: Props) => {
  const eventId = useEventId();
  const kind = isRun ? 'run' : 'event';

  if (!eventId) throw new Error('Event id expected');

  return (
    <Link href={reverse('event_update', { id: eventId })} className={styles.button}>
      <Icon icon={'pencil'} /> <span className={styles.text}>Edit {kind}</span>
    </Link>
  );
};

export default EditEventButton;
