import { Button } from 'components/common';
import { DemoType } from 'models';
import { URLContext } from 'components/RoutingProvider';
import { reverse } from 'router';
import ConfirmDelete from 'components/ConfirmDelete/ConfirmDelete';
import React, { useContext, useState } from 'react';

interface Event {
  id: string;
}

interface Props {
  event: Event;
  className: string;
}

const DeleteButton = (props: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { id } = props.event;
  const { setPath } = useContext(URLContext);

  return (
    <>
      <Button color="red" label="Delete" handleClick={() => setModalOpen(true)} className={props.className} />
      <ConfirmDelete
        eventId={id}
        open={modalOpen}
        onCancel={() => {
          setModalOpen(false);
        }}
        onSuccess={() => {
          if (id === DemoType.Id) {
            setPath(reverse('event_create'));
          } else {
            setPath(reverse('event_calendar'));
          }
        }}
      />
    </>
  );
};

export default DeleteButton;
