import { Skeleton } from '@material-ui/lab';
import { getRandomInt } from 'shared/shared/utils';
import React from 'react';
import classNames from 'classnames';
import layoutStyles from './EventReadView.module.scss';
import styles from './EventReadViewSkeleton.module.scss';
import useEventClass from 'utils/hooks/event/useEventClass';

const widths = [`${getRandomInt(45, 60)}%`, `${getRandomInt(45, 60)}%`, `${getRandomInt(45, 60)}%`];
const widths2 = [`${getRandomInt(30, 50)}%`, `${getRandomInt(30, 50)}%`, `${getRandomInt(30, 55)}%`];

export const EventReadViewSkeleton = () => {
  const { event } = useEventClass({ required: false });
  return (
    <div className={layoutStyles.EventReadView}>
      <div className={classNames(layoutStyles.RightColumn, layoutStyles.ActionSection)}>
        <div
          className={classNames(
            layoutStyles.ActionSectionBlock,
            styles.section,
            styles.rsvpSection,
            styles.sectionContent
          )}
        >
          <EventRsvpSkeleton />
        </div>
        <div
          className={classNames(
            layoutStyles.ActionSectionBlock,
            styles.section,
            styles.guestListSection,
            styles.sectionContent
          )}
        >
          <EventGuestListSkeleton />
        </div>
      </div>
      <div className={classNames(layoutStyles.LeftColumn, layoutStyles.EventBlock, styles.section, styles.mainSection)}>
        <EventInfoSkeleton />
      </div>
      {!event?.disableComments && (
        <div
          className={classNames(
            layoutStyles.LeftColumn,
            layoutStyles.CommentBlock,
            styles.section,
            styles.commentSection,
            styles.sectionContent
          )}
        >
          <EventCommentsSkeleton />
        </div>
      )}
    </div>
  );
};

export const EventInfoSkeleton = () => {
  return (
    <>
      <Skeleton variant="rect" className={styles.titleImage} />
      <div className={styles.sectionContent}>
        <Skeleton variant="rect" className={styles.title} />
        {[0, 1, 2].map((i) => (
          <div key={i} className={styles.item}>
            <Skeleton variant="circle" className={styles.itemIcon} />
            <Skeleton variant="rect" className={styles.itemLabel} style={{ width: widths[i] }} />
          </div>
        ))}
      </div>
    </>
  );
};

export const EventRsvpSkeleton = () => {
  return (
    <>
      <Skeleton variant="rect" className={styles.sectionTitle} width={220} />
      <div className={styles.rsvpButtons}>
        <Skeleton variant="rect" className={styles.rsvpButton} />
        <Skeleton variant="rect" className={styles.rsvpButton} />
        <Skeleton variant="rect" className={styles.rsvpButton} />
      </div>
    </>
  );
};

export const EventGuestListSkeleton = () => {
  return (
    <>
      <Skeleton variant="rect" className={styles.sectionTitle} width={130} />
      <Skeleton variant="rect" className={styles.guestListControls} width={80} />
      {[0, 1, 2].map((i) => (
        <div key={i} className={classNames(styles.item, styles.small)}>
          <Skeleton variant="circle" className={styles.itemIcon} />
          <Skeleton variant="rect" className={styles.itemLabel} style={{ width: widths2[i] }} />
        </div>
      ))}
    </>
  );
};

export const EventCommentsSkeleton = () => {
  return <Skeleton variant="rect" className={styles.commentsLabel} />;
};
