import { Button, TextLink } from 'components/common';
import { Checkbox, CircularProgress, FormControlLabel, FormGroup, TextField } from '@material-ui/core';
import { POLL_QUERY, POLL_VOTE_MUTATION } from 'utils/gql';
import { Poll, PollOption, formatPollOptions, getUserVotes, getVoteUpdate } from 'models/poll';
import { pollQuery as PollQuery } from 'generated/pollQuery';
import { pollVote as PollVote, pollVoteVariables as PollVoteVars } from 'generated/pollVote';
import { PollVoteTooltip } from './PollVoteTooltip';
import { isAuthenticated } from 'models';
import { useMutation } from '@apollo/client';

import useUser from 'utils/hooks/user/useUser';

import { getDefaultTimezone, renderTimezone } from 'utils/datetime';
import Dialog from 'components/common/Dialog/Dialog';
import React, { ChangeEvent, useState } from 'react';
import styles from './PollVoteDialog.module.scss';
import useLocalStorage from 'utils/hooks/useLocalStorage';

interface Props {
  poll: Poll;
  voterName?: string;
  buttonClassName?: string;
}

export const PollVoteDialog = (props: Props) => {
  const { poll, buttonClassName } = props;
  const { user } = useUser();

  const [pollVote, { loading, called }] = useMutation<PollVote, PollVoteVars>(POLL_VOTE_MUTATION, {
    update: (cache, resp) => {
      // todo: fix typing
      const {
        data: {
          pollVoteBulkUpdate: { votes = [], deletedVoteIds = [] },
        },
      }: any = resp;

      const cacheData: any = cache.readQuery<PollQuery>({ query: POLL_QUERY, variables: { id: poll.id } });
      const cachePoll = cacheData && cacheData.poll ? cacheData.poll : {};
      const voteIds = votes.map((vote: any) => vote.id);

      cache.writeQuery<PollQuery>({
        query: POLL_QUERY,
        data: {
          poll: {
            ...cachePoll,
            votes: cachePoll.votes
              .filter((vote: any) => !deletedVoteIds.includes(vote.id) && !voteIds.includes(vote.id))
              .concat(votes),
          },
        },
      });
    },
    onCompleted: () => setVoteDialogIsOpen(false),
  });

  const existingVotes =
    poll &&
    !called &&
    getUserVotes(poll.votes, isAuthenticated(user) ? user : undefined, props.voterName || '' || undefined);
  const [userVotes, setUserVotes] = useState<Record<string, boolean>>(existingVotes || {});
  const [voteDialogIsOpen, setVoteDialogIsOpen] = useState<boolean>(false);
  const [voterName, setVoterName] = useLocalStorage<string>('voterName', props.voterName || '');
  const options = formatPollOptions(poll.options);

  return (
    <>
      <TextLink
        key="button"
        className={buttonClassName}
        text={Object.keys(userVotes).length ? 'Change Vote' : 'Vote'}
        onClick={() => setVoteDialogIsOpen(true)}
      />
      <Dialog
        title="Poll Vote"
        isOpen={voteDialogIsOpen}
        onClose={() => setVoteDialogIsOpen(false)}
        actions={[
          <Button
            key={'save'}
            label={loading ? <CircularProgress size={18} /> : 'Save'}
            className={styles.saveButton}
            onClick={() => pollVote({ variables: getVoteUpdate(poll.id, userVotes, voterName) })}
            disabled={!isAuthenticated(user) && !voterName}
            small
          />,
        ]}
      >
        <FormGroup className={styles.voteForm}>
          {!isAuthenticated(user) && (
            <div className={styles.nameField}>
              <div className={styles.label}>Your Name</div>
              <TextField
                fullWidth
                className={styles.voterName}
                placeholder="First Last"
                margin="normal"
                variant="outlined"
                value={voterName}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setVoterName(e.target.value)}
              />
            </div>
          )}
          <div className={styles.label}>Vote for one or more times below:</div>
          {options.map((option: PollOption) => (
            <FormControlLabel
              key={option.id}
              control={
                <Checkbox
                  checked={!!userVotes[option.id!]}
                  color="primary"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setUserVotes({ ...userVotes, [option.id!]: e.target.checked });
                  }}
                  value={option.id}
                />
              }
              label={
                <span>
                  {option.text}
                  <PollVoteTooltip votes={option.votes || []} className={styles.votes} />
                </span>
              }
            />
          ))}
          <div className="mt-6 text-gray-600">All times are shown in {renderTimezone(getDefaultTimezone())}</div>
        </FormGroup>
      </Dialog>
    </>
  );
};
