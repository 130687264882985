import { Loading } from 'components/common';
import { WidgetProps } from 'components/widgets';
import PlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete';
import React, { Component } from 'react';
import StreamLinkWidget from 'components/widgets/StreamLinkWidget';
import config from 'config';
import styles from './LocationWidget.module.scss';

interface State {
  googleMapsLibraryDidLoad: boolean;
  isLoadingLocationAddress: boolean;
  location?: string;
  inputValue: string;
}
interface LocationWidgetSuggestion extends google.maps.places.AutocompletePrediction {
  id: any;
  active: boolean;
}

const GOOGLE_MAPS_API_KEY = config.googleApiMapsKey;
const GOOGLE_MAPS_API_LIBRARIES = 'places,geocoder';

class LocationWidget extends Component<WidgetProps, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      googleMapsLibraryDidLoad: false,
      isLoadingLocationAddress: false,
      location: props.value || '',
      inputValue: props.value || '',
    };
  }

  componentDidMount() {
    if (!window.google) {
      this.retrieveGoogleMapsLibrary();
    } else {
      this.handleGoogleMapsLibraryLoaded();
    }
  }

  retrieveGoogleMapsLibrary = () => {
    window.handleGoogleMapsLibraryLoaded = this.handleGoogleMapsLibraryLoaded;
    const googleMapsScriptElement = document.createElement('script');
    googleMapsScriptElement.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=${GOOGLE_MAPS_API_LIBRARIES}&callback=handleGoogleMapsLibraryLoaded`;
    document.body.insertAdjacentElement('beforeend', googleMapsScriptElement);
  };

  handleGoogleMapsLibraryLoaded = () => {
    this.setState({
      googleMapsLibraryDidLoad: true,
    });
  };

  handleChange = (val: any) => {
    this.setState({ location: val });
  };

  handleInputChange = (e: any) => {
    const inputValue = e.target.value;
    this.setState({ inputValue, location: inputValue });
    this.props.onChange(inputValue);
  };

  handleSelect = async (location: any, placeId: string) => {
    if (!placeId) {
      // If there's no placeId then the user's 'plaintext' input was selected
      this.setState({ location, inputValue: location });
      this.props.onChange(this.state.location);
      return;
    }

    this.setState({ isLoadingLocationAddress: true });

    const [geocoderResult]: [google.maps.GeocoderResult] = (await geocodeByPlaceId(placeId)) as any;
    const { formatted_address, types } = geocoderResult;

    // TODO: Either make another API call to retrieve structured_formatting so we can pull main_text

    // v-- super dirty and unsafe way of handling this for now.. any location with a comma in it's name will break this
    const UNSAFE_formattedLocation = `${
      types.includes('establishment') ? location.split(',')[0] + ', ' : ''
    }${formatted_address}`;

    this.setState(
      {
        location: UNSAFE_formattedLocation,
        inputValue: UNSAFE_formattedLocation,
        isLoadingLocationAddress: false,
      },
      () => {
        this.props.onChange(this.state.location);
      }
    );
  };

  render() {
    const { googleMapsLibraryDidLoad, isLoadingLocationAddress, inputValue, location } = this.state;
    return (
      <div>
        {googleMapsLibraryDidLoad && (
          <PlacesAutocomplete value={location} onChange={this.handleChange} onSelect={this.handleSelect}>
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }: any) => (
              <div>
                <StreamLinkWidget
                  value={inputValue}
                  onChange={(v) => this.handleInputChange({ target: { value: v } })}
                  attrs={this.props.attrs || {}}
                  onInputChange={(e: any) => {
                    this.handleInputChange(e);
                    getInputProps().onChange(e);
                  }}
                  disabled={isLoadingLocationAddress}
                />
                <div className={styles.autocompleteDropdownContainer}>
                  {suggestions.length > 0 &&
                    [
                      /* Insert the user's input into the suggestion list as the first option */
                      {
                        description: inputValue,
                        id: -1,
                        active: suggestions.every((suggestion: any) => !suggestion.active),
                      },
                      ...suggestions,
                    ].map((suggestion: LocationWidgetSuggestion) => {
                      const className = suggestion.active
                        ? [styles.suggestionItem, styles.suggestionItemActive].join(' ')
                        : styles.suggestionItem;
                      return (
                        <div
                          key={suggestion.id}
                          {...getSuggestionItemProps(suggestion, {
                            className,
                          })}
                        >
                          <span key={suggestion.id}>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  {loading && (
                    <div className={styles.suggestionItem}>
                      <Loading size="tiny" />
                    </div>
                  )}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        )}
      </div>
    );
  }
}

export default LocationWidget;
