import Button2 from 'components/common/Button2/Button2';
import Dialog2 from 'components/common/Dialog2/Dialog2';
import FeeCalculator from 'components/FeeCalculator/FeeCalculator';
import Link from 'components/Link';
import React from 'react';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}
const FeeCalculatorDialog: React.FC<Props> = ({ isOpen, onClose }) => {
  return (
    <Dialog2
      title="Ticket Fee Calculator"
      isOpen={isOpen}
      onClose={onClose}
      actions={<Button2 onClick={onClose}>Done</Button2>}
    >
      <FeeCalculator />
      <div className="mt-8 text-gray-800">
        Still have questions? Check out our{' '}
        <Link href="/faq#sell-tickets" newWindow={true}>
          FAQ
        </Link>
        .
      </div>
    </Dialog2>
  );
};

export default FeeCalculatorDialog;
