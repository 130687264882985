import { Link, TextLink } from 'components/common';
import { constructQueryString } from 'shared/shared/utils';
import { reverse } from 'router';
import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import React from 'react';
import useUser from 'utils/hooks/user/useUser';
import useUserChangeContext from 'components/App/useUserChangeContext';

const PrivateEventPage = () => {
  const { logout } = useUserChangeContext();
  const { isAuthenticated } = useUser();
  const loginUrl = reverse('account_login') + constructQueryString({ next: window.location.pathname });

  return (
    <BaseLayout title="Private Event">
      <div className="text-3xl">This Event is Private</div>
      {isAuthenticated ? (
        <div>
          You are not invited to this event. Please contact the host or{' '}
          <TextLink onClick={() => logout(loginUrl)}>log in</TextLink> to another account.
        </div>
      ) : (
        <div>
          Please click the link from your invitation email or <Link href={loginUrl}>log in</Link> to an invited account.
        </div>
      )}
    </BaseLayout>
  );
};

export default PrivateEventPage;
