import React, { ReactNode } from 'react';

interface Props {
  title: React.ReactNode;
  imgSrc: string;
  subText: ReactNode;
}

const MiniFeature = (props: Props) => {
  return (
    <div className="flex flex-col justify-between text-center bg-white border border-gray-500 border-solid p-7">
      <div className="mx-4 mt-6 text-xl font-bold md:text-2xl">{props.title}</div>
      <img src={props.imgSrc} alt="" className="mx-auto mt-8" />
      <div className="mt-8 font-semibold">{props.subText}</div>
    </div>
  );
};

export default MiniFeature;
