import { Link } from 'components/common';
import { reverse } from 'router';
import Button2 from 'components/common/Button2/Button2';
import React, { useState } from 'react';
import TimeTil, { durationToString as durationLongFormString } from 'components/pages/VenuePage/TimeTil/TimeTil';
import moment, { Duration, Moment } from 'moment';

interface Props {
  startTime: Moment;
  locationReleaseTimeDelta: Duration;
  eventId: string;
}

const OnlineEventInfo = ({ startTime, locationReleaseTimeDelta, eventId }: Props) => {
  const deltaString = durationLongFormString(locationReleaseTimeDelta);
  const linkReleaseTime = moment(startTime);
  linkReleaseTime.subtract(locationReleaseTimeDelta);
  const initLinkReleased = moment().isSameOrAfter(linkReleaseTime);
  const [linkReleased, setLinkReleased] = useState<boolean>(initLinkReleased);

  return (
    <div>
      <div className="event-read-page-responsive-h4-heading">Online Event</div>
      <p className="py-5 font-medium text-gray-700 ">Event link will be available {deltaString} before the event.</p>
      <Link className="hover:no-underline" href={linkReleased ? reverse('event_proxy', { id: eventId }) : '#'}>
        <Button2 className="w-full">
          {linkReleased ? (
            'Online Event Link'
          ) : (
            <>
              <span>Link will be available in&nbsp;</span>
              <TimeTil
                time={linkReleaseTime}
                onCompletion={() => {
                  setLinkReleased(true);
                }}
                shortDurationFormat
              ></TimeTil>
            </>
          )}
        </Button2>
      </Link>
    </div>
  );
};

export default OnlineEventInfo;
