import IconSvg, { IconSvgName } from 'components/common/IconSvg/IconSvg';
import React from 'react';

interface Props {
  iconName: IconSvgName;
  label: string;
  className: string;
}

const UseCaseIcon = ({ iconName, label, className }: Props) => {
  return (
    <div className={`w-28 h-28 py-4 ${className}`}>
      <div className="mt-2">
        <IconSvg name={iconName} className="w-12 h-12 mx-auto text-white" />
      </div>
      <div className="text-sm text-center text-white">{label}</div>
    </div>
  );
};

export default UseCaseIcon;
