// Wrapper around react-hook-form and useFormHelper that adds helpers related to async form submissions:
// `isSubmitting`, `result`, and `error`
//
// The returned `SubmitButton` requires a `submittingLabel` prop

import { AsyncSubmitButtonProps2 } from 'components/forms/types';
import AsyncSubmitButton from 'components/forms/AsyncSubmitButton';
import React, { useCallback, useState } from 'react';
import useFormHelpers, { FormHelpers } from 'components/forms/useFormHelpers';

interface Props<T, Result = any> {
  handleSubmit: (values: T) => Promise<Result>;
}

export interface AsyncFormHelpers<T, Result> extends Omit<FormHelpers, 'SubmitButton'> {
  SubmitButton: React.ComponentType<AsyncSubmitButtonProps2>;
  isSubmitting: boolean;
  result?: Result;
  error?: any;
  onSubmit: (value: T) => void;
}

function useAsyncFormHelpers<T, Result = any>(props: Props<T, Result>): AsyncFormHelpers<T, Result> {
  const { handleSubmit } = props;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [result, setResult] = useState<Result>();
  const [error, setError] = useState<any>();

  const wrappedHandleSubmit = useCallback(
    (value: T) => {
      setIsSubmitting(true);
      handleSubmit(value)
        .then(setResult)
        .catch(setError)
        .finally(() => setIsSubmitting(false));
    },
    [handleSubmit]
  );
  const formHelpers = useFormHelpers();

  return {
    ...formHelpers,
    isSubmitting,
    result,
    error,
    SubmitButton: AsyncSubmitButton,
    onSubmit: wrappedHandleSubmit,
  };
}

export default useAsyncFormHelpers;
