import 'react-quill/dist/quill.bubble.css';
import { ImagePickerModal } from 'components/EventCreateUpdateForm/ImagePicker';
import { WidgetProps } from '..';
import { addSnackbarMessage } from 'utils/eventEmitter';
import { fileToBase64, uploadImage } from 'utils/helpers';
import Button2 from 'components/common/Button2/Button2';
import ImageBlot from 'components/widgets/QuillRichTextWidget/ImageBlot';
import ImageDragAndResize from 'components/widgets/QuillRichTextWidget/ImageDragAndResize';
import ImageDropAndPaste from 'components/widgets/QuillRichTextWidget/ImageDropAndPaste';
import ImageIcon from '@material-ui/icons/AddPhotoAlternateOutlined';
import React from 'react';
import ReactQuill, { Quill } from 'react-quill';
import type { Quill as QuillT } from 'quill';

import styles from './QuillRichTextWidget.module.scss';

//
// Setup
//

Quill.register('modules/imageDropAndPaste', ImageDropAndPaste);

Quill.register('modules/imageDragAndResize', ImageDragAndResize);

const Link = Quill.import('formats/link');
Link.sanitize = (url: string) => {
  return url.startsWith('http://') || url.startsWith('https://') ? url : `http://${url}`;
};
const Block = Quill.import('blots/block');
Block.tagName = 'div';
Quill.register(Block);

Quill.register(ImageBlot);

//
// Editor
//

interface Props extends WidgetProps<string, Attrs> {}

interface State {
  imagePickerOpen: boolean;
}

interface Attrs {
  imageSupport?: boolean;
  className?: string;
  placeholder?: string;
}

class QuillRichTextWidget extends React.Component<Props, State> {
  quill = React.createRef<ReactQuill>();

  public state: State = {
    imagePickerOpen: false,
  };

  private readonly openImagePicker = () => this.setState({ imagePickerOpen: true });

  private readonly closeImagePicker = () => this.setState({ imagePickerOpen: false });

  private readonly uploadImage = async (file: File) => {
    try {
      return await uploadImage(file);
    } catch (err) {
      addSnackbarMessage('There was an error while uploading your image', 'error');
      return;
    }
  };

  private readonly onImageUpload = async (file: File) => {
    const url = await this.uploadImage(file);
    if (url) {
      this.onImageSelect(url, { source: 'upload', file });
    }
  };

  private getEditor(): QuillT | undefined {
    // Not defined during first render, before ref has been set
    return this.quill.current?.getEditor() as any;
  }

  private isEditorEmpty() {
    const editor = this.getEditor();
    if (!editor) {
      return false;
    }
    const textLength = editor.getText().trim().length;
    // Inexact check-- finds the string "img" even if not in a tag-- but that's ok
    const hasImage = editor.root.innerHTML.includes('img');
    return !textLength && !hasImage;
  }

  private readonly onImageSelect = (url: string, data?: object) => {
    const editor = this.getEditor() as QuillT;
    editor.focus();
    this.insertImage(url);
    this.closeImagePicker();
  };

  private readonly insertImage = (url: string, source?: 'api' | 'user' | 'silent') => {
    const editor = this.getEditor() as QuillT;
    const index = (editor.getSelection() || {}).index || editor.getLength();
    editor.insertEmbed(index, 'image', url, source);
    editor.setSelection(index + 1, 0);
  };

  private readonly imageDropAndPaste = (editor: QuillT, files: File[]) => {
    files.forEach((file) => {
      fileToBase64(file).then((base64) => {
        this.insertImage(base64, 'user');

        this.uploadImage(file).then((url) => {
          const images = Array.from(editor.root.querySelectorAll('img'));
          const image = images.find((image) => image.src === base64);
          if (image && url) {
            image.src = url;
          }
        });
      });
    });
  };

  render() {
    const { attrs, value, disabled } = this.props;
    const { imagePickerOpen } = this.state;
    const { imageSupport = true, className, placeholder } = attrs || {};

    return (
      <div className="relative">
        <div className={styles.QuillRichTextWidget}>
          <ReactQuill
            ref={this.quill}
            // The `|| ''` is to fix this error on initial load. Was mysterious only appearing in certain environments.
            // https://stackoverflow.com/a/47762708/65385
            // "You are passing the `delta` object from the `onChange` event back as `value`. You most probably want `editor.getContents()` instead."
            value={value || ''}
            readOnly={disabled}
            placeholder={placeholder}
            onChange={(value) => {
              const { onChange } = this.props;
              if (this.isEditorEmpty()) {
                onChange('');
              } else {
                onChange(value);
              }
            }}
            theme="bubble"
            className={className}
            formats={['link', 'image', 'width', 'bold', 'italic']}
            modules={{
              toolbar: ['bold', 'italic', 'link'],
              imageDropAndPaste: {
                handler: this.imageDropAndPaste,
              },
              imageDragAndResize: {},
            }}
            bounds={`.${styles.QuillRichTextWidget}`}
          />
        </div>
        {imageSupport && (
          <div className="flex items-center justify-between mx-1 mt-3 xs:mx-0">
            <Button2
              color="secondary"
              size="8"
              onClick={this.openImagePicker}
              disabled={disabled}
              className="w-auto border-gray-400 rounded-md sm:w-auto"
            >
              <div className="flex items-center">
                <ImageIcon fontSize="small" />
                <span className="ml-2">Add Image</span>
              </div>
            </Button2>
            <div className="hidden text-sm leading-none text-gray-600 sm:inline">
              Highlight text for formatting options
            </div>
          </div>
        )}
        <ImagePickerModal
          open={imagePickerOpen}
          onClose={this.closeImagePicker}
          onSelect={this.onImageSelect}
          onUpload={this.onImageUpload}
          config={{
            upload: true,
            link: true,
            events: true,
            giphy: true,
          }}
        />
      </div>
    );
  }
}

export default QuillRichTextWidget;
