import { addSnackbarMessage } from 'utils/eventEmitter';
import { mixilyAPI } from 'utils/api';
import { useEffect, useState } from 'react';
import queryUser from 'api/query-user';

function useConnectZoomAccount(code?: string) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (code) {
      mixilyAPI
        .connectZoomAccount(code)
        .then((response) => {
          if (response.ok) {
            addSnackbarMessage('Connected to Zoom', 'success');
            queryUser();
          } else {
            addSnackbarMessage(response.error?.message, 'error');
          }
          setLoading(false);
        })
        .catch(() => {
          addSnackbarMessage('Error connecting to Zoom', 'error');
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading;
}

export default useConnectZoomAccount;
