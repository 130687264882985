import React, { DetailedHTMLProps, TextareaHTMLAttributes } from 'react';
import classNames from 'classnames';

export type TextareaBuiltinProps = DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>;

interface Props extends TextareaBuiltinProps {
  ref?: React.Ref<HTMLTextAreaElement>;
}

const TextArea2 = (props: Props, ref: React.Ref<HTMLTextAreaElement>) => {
  const { className, ...rest } = props;
  const cls = classNames('form-textarea', 'textarea', className);
  return <textarea className={cls} ref={ref} {...rest} />;
};

export default React.forwardRef(TextArea2);
