import Button2, { ButtonProps as Button2Props } from 'components/common/Button2/Button2';
import Dialog2, { Props as Dialog2Props } from 'components/common/Dialog2/Dialog2';
import React from 'react';

type ButtonConfig = Partial<Pick<Button2Props, 'color' | 'size'>>;

interface Props extends Omit<Dialog2Props, 'actions'> {
  onConfirm: () => void;
  yesLabel: string;
  yesConfig?: ButtonConfig;
  noLabel: string;
  noConfig?: ButtonConfig;
  disabled?: boolean;
  size?: Dialog2Props['size'];
}

const Confirm2 = (props: Props) => {
  const {
    onConfirm,
    onClose,
    yesLabel,
    noLabel,
    children,
    disabled,
    yesConfig,
    noConfig,
    size = 'xs',
    ...rest
  } = props;

  const actions = (
    <>
      <Button2 key="yes" onClick={onConfirm} disabled={disabled} className="sm:ml-4" {...yesConfig}>
        {yesLabel}
      </Button2>
      <Button2 key="no" onClick={onClose} disabled={disabled} color="secondary" className="mt-4 sm:mt-0" {...noConfig}>
        {noLabel}
      </Button2>
    </>
  );

  return (
    <Dialog2 onClose={onClose} actions={actions} size={size} {...rest}>
      {children}
    </Dialog2>
  );
};

export default Confirm2;
