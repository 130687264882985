import { Controller, useForm } from 'react-hook-form';
import { CurrencyType } from 'shared/shared/types';
import {
  MIXILY_PERCENTAGE_FEE,
  MIXILY_PER_TICKET_FEE,
  STRIPE_FLAT_FEE_IN_USD,
  STRIPE_PERCENTAGE_FEE,
} from 'shared/shared/constants';
import { renderDollars } from 'shared/shared/utils';
import IntegerInput from 'components/common/IntegerInput/IntegerInput';
import LabelWithInput from 'components/common/LabelWithInput/LabelWithInput';
import MoneyInput from 'components/common/MoneyInput/MoneyInput';
import React from 'react';

type FormT = {
  ticketPrice: string | null;
  ticketsPerOrder: string | null;
};

const defaultValues = {
  ticketPrice: '18',
  ticketsPerOrder: '2',
};

function calculate(ticketPrice: string | null, ticketsPerOrder: string | null) {
  const ticketPriceN = ticketPrice ? parseInt(ticketPrice) * 100 : 0;
  const ticketsPerOrderN = ticketsPerOrder ? parseInt(ticketsPerOrder) : 1;
  const totalPrice = ticketPriceN * ticketsPerOrderN;
  if (!totalPrice) {
    return {
      totalPrice,
      mixilyFees: 0,
      paymentProcessing: 0,
    };
  }
  const mixilyFees = Math.round(MIXILY_PER_TICKET_FEE * ticketsPerOrderN + MIXILY_PERCENTAGE_FEE * totalPrice);
  const paymentProcessing = Math.round(STRIPE_FLAT_FEE_IN_USD + STRIPE_PERCENTAGE_FEE * totalPrice);
  return { mixilyFees, paymentProcessing, totalPrice };
}

const FeeCalculator = () => {
  const { watch, control } = useForm<FormT>({ defaultValues });
  const { mixilyFees, paymentProcessing, totalPrice } = calculate(watch('ticketPrice'), watch('ticketsPerOrder'));

  return (
    <div>
      <div className="flex mb-8 sm:mb-10">
        <div className="flex-grow">
          <div className="font-bold text-gray-800">Mixily Fee</div>
          <div>1% + $0.30 </div>
          <div className="text-gray-800">per ticket</div>
        </div>
        <div className="flex-grow">
          <div className="font-bold text-gray-800">Payment Processor</div>
          <div>2.9% + $0.30 (or 0.44 CAD)</div>
          <div className="text-gray-800">per order</div>
        </div>
      </div>
      <div className="flex flex-col my-8 sm:flex-row sm:my-10">
        <LabelWithInput label="Ticket Price" className="items-start flex-1 sm:mr-4">
          <Controller
            as={MoneyInput}
            name="ticketPrice"
            control={control}
            onChangeName="onValueChange"
            onChange={(e) => e[0].floatValue ?? null}
            currency={CurrencyType.USD}
          />
        </LabelWithInput>
        <LabelWithInput label="Number of Tickets" className="items-start flex-1 mt-4 sm:mt-0">
          <Controller
            as={IntegerInput}
            name="ticketsPerOrder"
            control={control}
            onChangeName="onValueChange"
            onChange={(e) => e[0].floatValue ?? null}
          />
        </LabelWithInput>
      </div>
      <div className="mt-8 sm:mt-10">
        <div className="flex justify-between text-gray-900">
          <div>Order Price</div>
          <div>{renderDollars(totalPrice, CurrencyType.USD)}</div>
        </div>
        <div className="flex justify-between text-gray-900">
          <div>Mixily Fees</div>
          <div>{renderDollars(mixilyFees, CurrencyType.USD)}</div>
        </div>
        <div className="flex justify-between text-gray-900">
          <div>Payment Processor Fees</div>
          <div>{renderDollars(paymentProcessing, CurrencyType.USD)}</div>
        </div>
        <div className="flex justify-between pt-2 mt-2 font-semibold border-t border-gray-500 border-solid">
          <div>Your Payout</div>
          <div>{renderDollars(totalPrice - mixilyFees - paymentProcessing, CurrencyType.USD)}</div>
        </div>
      </div>
    </div>
  );
};

export default FeeCalculator;
