/**
 * Listen for app messages from other call participants.
 */

import { DailyCall, DailyEventObjectAppMessage } from '@daily-co/daily-js';
import { useEffect } from 'react';

const useDailyAppMessage = (
  callObject: DailyCall | null | undefined,
  handler: (event?: DailyEventObjectAppMessage) => void
) => {
  useEffect(() => {
    if (!callObject) {
      return;
    }

    callObject.on('app-message', handler);

    return () => {
      callObject.off('app-message', handler);
    };
  }, [callObject, handler]);
};

export default useDailyAppMessage;
