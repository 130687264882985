import { RgbColor, RgbaColor } from 'react-colorful';
import Color from 'color';

// const hexToInt = (hh: string) => parseInt(hh, 16);

export const hexToRgb = (hex: string) => {
  const c = Color(hex);
  const { r, g, b } = c.object();
  return { r, g, b };
};

export const rgbaToHexa = ({ r, g, b, a }: RgbaColor): string => {
  let alpha16 = Math.ceil((a ?? 1) * 255).toString(16);
  if (alpha16.length === 1) alpha16 = `0${alpha16}`;
  return Color({ r, g, b }).hex() + alpha16.toUpperCase();
};

export const rgbToHex = ({ r, g, b }: RgbColor): string => {
  return Color({ r, g, b }).hex();
};

export const rgbaToColor = ({ r, g, b, a }: RgbaColor): Color => {
  return Color({ r, g, b }).alpha(a);
};

export const colorToRgba = (color: Color): RgbaColor => {
  const { r, g, b, alpha } = color.object();

  return {
    r,
    g,
    b,
    a: alpha ?? 1,
  };
};

export const colorNameToRgba = (colorName: string): RgbaColor => colorToRgba(Color(colorName));
export const colorToHex = (color: Color): string => rgbToHex(colorToRgba(color));
export const colorToHexa = (color: Color): string => rgbaToHexa(colorToRgba(color));
export const colorNameToHex = (colorName: string): string => colorToHex(Color(colorName));
export const colorNameToHexa = (colorName: string): string => colorToHexa(Color(colorName));
export const colorWithAlpha = (colorName: string, alpha: number): string => {
  const rgba = colorNameToRgba(colorName);
  rgba.a = alpha;
  return rgbaToHexa(rgba);
};

// https://awik.io/determine-color-bright-dark-using-javascript/
export const isColorLightOrDark = (color: Color): 'light' | 'dark' => {
  // Variables for red, green, blue values
  const r = color.red(),
    g = color.green(),
    b = color.blue();
  let hsp;

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return 'light';
  }
  return 'dark';
};
