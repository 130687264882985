import './DatePickerWidget.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { DateT } from 'components/widgets/time-types';
import { WidgetProps } from 'components/widgets/index';
import DatePicker from 'react-datepicker';
import React, { Component } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import styles from './DatePickerWidget.module.scss';

class DatePickerWidget extends Component<WidgetProps<DateT, void>> {
  handleChange = (date: Date | null) => {
    const m = date ? moment(date) : moment();
    const obj = m.toObject();

    this.props.onChange({
      years: obj.years,
      months: obj.months,
      date: obj.date,
    });
  };

  render() {
    const { id, value, disabled } = this.props;

    // The value can be '' in the special case that no initial or default value
    // was provided to the field.
    const selected = value ? moment(value).toDate() : moment().toDate();

    return (
      <DatePicker
        id={id}
        dateFormat={'MMMM d, yyyy'}
        minDate={moment().toDate()}
        selected={selected}
        onChange={this.handleChange}
        readOnly={disabled}
        disabled={disabled}
        className={classNames({ [styles.disabled]: disabled })}
        {...(this.props.attrs as any)}
      />
    );
  }
}

export default DatePickerWidget;
