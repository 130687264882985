import { Tile } from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/types';
import {
  getChatTiles,
  getVideoTiles,
} from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/utils';
import { getMessage, isLocal } from 'components/VirtualVenue/daily/utils';
import { useCallConfig } from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/contexts/CallConfigContext';
import { useVirtualVenueCall } from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/contexts/VirtualVenueCallContext';
import CallDisplay from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/CallDisplay/CallDisplay';
import React from 'react';
import useDemoTiles from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/useDemoTiles';

import { useVirtualVenue } from 'components/VirtualVenue/contexts/VirtualVenueContext';
import useTestTiles from './useTestTiles';

const Call = () => {
  const { dailyState } = useVirtualVenueCall();
  const { showSelf } = useCallConfig();
  const venue = useVirtualVenue();

  const chatTiles = getChatTiles(dailyState, showSelf);
  const videoTiles = getVideoTiles();
  const testTiles = useTestTiles();
  const { demoTiles } = useDemoTiles();
  const tiles: Tile[] = [...chatTiles, ...videoTiles, ...testTiles, ...demoTiles];

  const isCustomUser = venue.ownerId === '7021740614534326313';

  // If we're the only participant, show a nice invite window.
  // Note: tiles.length == 0 if we're the only participant and "Show self" is off.
  // Similarly, tiles.length can be 1 in the case that we're in a 1-1 chat and show-self
  // is off. In this case, we don't want to show the invite tile -- just the other person.
  if (!isCustomUser && (!tiles.length || (tiles.length === 1 && isLocal(tiles[0].id)))) {
    tiles.push({ type: 'invite', id: 'invite' });
  }

  return <CallDisplay message={getMessage(dailyState)} tiles={tiles} />;
};

export default Call;
