import { EventWithPasswordQuery } from 'generated/EventWithPasswordQuery';
import ErrorBoundary from 'components/common/ErrorBoundary';
import EventPasswordForm from 'components/pages/EventPasswordForm';
import HostedBy from 'components/HostedBy/HostedBy';
import React from 'react';
import Title from 'components/pages/EventReadPage/Title/Title';
import classNames from 'classnames';
import styles from './EventReadViewWithPassword.module.scss';

interface Props {
  // Before removing event props, see https://github.com/reverie/mixily/pull/729
  event: EventWithPasswordQuery;
  isDialog?: boolean;
  loading: boolean;
  refetchPassword: Function;
}

const EventReadViewWithPassword = ({ refetchPassword, event, isDialog }: Props) => {
  const viewClass = isDialog ? styles.EventReadDialogView : styles.EventReadView;

  return (
    <div className={viewClass} style={{ position: 'static' }}>
      <div className={classNames(styles.LeftColumn, styles.EventBlock)}>
        {event.eventWithPassword.themePic && (
          <img className={styles.HeaderImage} src={event.eventWithPassword.themePic} alt="" />
        )}
        <div
          className={classNames(styles.MainInfo, {
            [styles.TopBorder]: !event.eventWithPassword.themePic,
          })}
        >
          <Title title={event.eventWithPassword.title} />
          <div className="mt-4 vstack-small">
            <div className="flex flex-col xs:flex-row xs:items-center">
              <HostedBy hostedByText={event.eventWithPassword.hostedByText} alignTop />
            </div>
          </div>
        </div>
      </div>

      <div className={classNames(styles.LeftColumn, styles.CommentBlock)}>
        <ErrorBoundary>
          <div className="my-3 sm:text-3xl">Enter Password</div>
          <div className="mt-5">
            <EventPasswordForm eventId={event.eventWithPassword.id} refetch={refetchPassword} />
          </div>
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default EventReadViewWithPassword;
