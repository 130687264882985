import { Loading } from 'components/common';
import { ORDER_READ_QUERY } from 'utils/gql';
import { ReadOrder } from 'generated/ReadOrder';
import { getURLParams } from 'utils/urls';
import { useQuery } from '@apollo/client';
import InnerTicketingForm from 'components/pages/EventReadPage/RsvpForm/TicketingForm/InnerTicketingForm/InnerTicketingFormForEmbedded';
import OrderComplete from 'components/pages/OrderReadPage/OrderComplete';
import React, { useEffect, useState } from 'react';
import useEventClass from 'utils/hooks/event/useEventClass';
import useSetEventId from 'components/App/globalstate/useSetEventId';
interface Props {
  id: string;
}

const EmbeddedTicketPage = ({ id }: Props): JSX.Element => {
  useSetEventId(id);
  const [parentUrl, setParentUrl] = useState(undefined);
  const urlParentParams = getURLParams(parentUrl);

  useEffect(() => {
    const handler = () => {
      setParentUrl(window.iFrameResizer.parentUrl);
    };
    window.addEventListener('message', handler);

    return () => window.removeEventListener('message', handler);
  }, []);

  const orderId =
    urlParentParams.order ?? (window.location.search ? getURLParams(window.location.href)?.order : undefined);

  const { event, loading, error } = useEventClass({
    required: false,
    shouldSkip: !!orderId,
  });
  const { data: orderData, error: orderError, loading: orderLoading } = useQuery<ReadOrder>(ORDER_READ_QUERY, {
    variables: { id: orderId },
    skip: !orderId,
  });

  if (!orderId) {
    if (loading) return showLoading;
    if (error || !event) return showError;

    return (
      <div className="bg-transparent">
        <InnerTicketingForm />
      </div>
    );
  }

  if (orderLoading) return showLoading;
  if (orderError || !orderData) return showError;

  return <OrderComplete order={orderData.order!} />;
};

const showLoading: JSX.Element = (
  <div className="flex items-center justify-center">
    <Loading />
  </div>
);

const showError: JSX.Element = <div className="flex items-center justify-center">Error loading content</div>;

export default EmbeddedTicketPage;
