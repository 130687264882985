import { Loading } from 'components/common';
import { VIRTUAL_VENUES_BY_OWNER_QUERY } from 'utils/gql';
import { VirtualVenuesByOwner, VirtualVenuesByOwnerVariables } from 'generated/VirtualVenuesByOwner';
import { useQuery } from '@apollo/client';
import { useUserId } from 'utils/hooks/user/useUser';
import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import LoginRequired from 'components/LoginRequired';
import React from 'react';
import VirtualVenueList from 'components/pages/VirtualVenueListPage/VirtualVenueList';

const VirtualVenueListPage = () => {
  const userId = useUserId();

  const { loading, error, data } = useQuery<VirtualVenuesByOwner, VirtualVenuesByOwnerVariables>(
    VIRTUAL_VENUES_BY_OWNER_QUERY,
    { variables: { ownerId: userId || '' }, skip: !userId }
  );

  const title =
    loading || (!error && data?.virtualVenuesByOwner !== undefined)
      ? 'My Virtual Venues'
      : 'Sorry, something went wrong';

  return (
    <LoginRequired>
      <BaseLayout title={title}>
        {loading ? (
          <Loading />
        ) : error || data?.virtualVenuesByOwner === undefined || data?.virtualVenuesByOwner === null ? (
          <p>We were unable to load your venues. Please refresh and try again.</p>
        ) : (
          <VirtualVenueList virtualVenues={data.virtualVenuesByOwner} />
        )}
      </BaseLayout>
    </LoginRequired>
  );
};

export default VirtualVenueListPage;
