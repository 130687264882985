import Button2 from 'components/common/Button2/Button2';
import Link from 'components/Link';
import React from 'react';

const VirtualVenueCTA = () => {
  return (
    <div className="text-center">
      <Link href="https://mxpresents.typeform.com/to/cL3K5CrO" newWindow={true} className="text-white">
        <Button2 size="14" className="text-white bg-blue-600 rounded-md hover:bg-blue-500">
          Get Early Access
        </Button2>
      </Link>
    </div>
  );
};

export default VirtualVenueCTA;
