import { ChatMessage } from 'components/VirtualVenue/pubnub/pubnub';
import EmojiReactionsDisplayInner from 'components/VirtualVenue/VirtualVenueDisplay/layouts/VirtualVenueDisplayLayout/Chat/ChatContent/ChatMessageDisplay/EmojiReactionsDisplay/EmojiReactionsDisplayInner';
import EmojiReactionsDisplayInteractive from 'components/VirtualVenue/VirtualVenueDisplay/layouts/VirtualVenueDisplayLayout/Chat/ChatContent/ChatMessageDisplay/EmojiReactionsDisplay/EmojiReactionDisplayInteractive';
import React, { RefObject } from 'react';

interface Props {
  message: ChatMessage;
  disableInteractions?: boolean;
  anchorEl: RefObject<HTMLDivElement>;
}

const EmojiReactionsDisplay = ({ message, anchorEl, disableInteractions }: Props) => {
  const { reactions = {} } = message.message;
  return disableInteractions ? (
    <EmojiReactionsDisplayInner reactions={reactions} />
  ) : (
    <EmojiReactionsDisplayInteractive message={message} anchorEl={anchorEl} />
  );
};
export default EmojiReactionsDisplay;
