import './TimePickerWidget.scss';
import { Time } from 'components/widgets/time-types';
import { WidgetProps } from 'components/widgets/index';
import React from 'react';
import TimePicker from 'react-time-picker';
import classNames from 'classnames';
import styles from './TimePickerWidget.module.scss';

class TimePickerWidget extends React.Component<WidgetProps<Time>> {
  private useNativeTimePicker: boolean;

  constructor(props: any) {
    super(props);
    this.useNativeTimePicker = this.browserSupportsTimeInput();
  }

  handleChange = (type: string) => (data: any) => {
    const value = type === 'native' ? data.currentTarget.value : data;
    if (value) {
      const [hours, minutes] = value.split(':');
      this.props.onChange({
        hours: parseInt(hours, 10),
        minutes: parseInt(minutes, 10),
      });
    }
  };

  browserSupportsTimeInput = () => {
    // https://stackoverflow.com/questions/21840574/how-to-check-if-a-browser-supports-input-type-time
    let timeInput = document.createElement('input');
    timeInput.type = 'time';
    return timeInput.type === 'time';
  };

  render() {
    const { value, disabled, id } = this.props;
    const hrs = value.hours.toString().padStart(2, '0');
    const mns = value.minutes.toString().padStart(2, '0');
    return (
      <div className={classNames('TimePickerWidget', { [styles.disabled]: disabled })}>
        {this.useNativeTimePicker ? (
          <input
            id={id}
            type="time"
            className={styles.timeInput}
            onChange={this.handleChange('native')}
            disabled={disabled}
            value={`${hrs}:${mns}`}
            required // hide clear icon just like TimePicker
          />
        ) : (
          <TimePicker
            id={id}
            // Widget can return null for empty values. Fixing this would be complicated,
            // see https://github.com/wojtekmaj/react-time-picker/issues/12
            onChange={this.handleChange('time-picker')}
            value={`${hrs}:${mns}`}
            disabled={disabled}
            disableClock={true}
            clearIcon={null} // hide clear icon until form handles empty values correctly
          />
        )}
      </div>
    );
  }
}

export default TimePickerWidget;
