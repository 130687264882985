import * as F from 'shared/shared/Functional';
import { DndContext, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { Tile } from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/types';
import { isLocal, isScreenShare } from 'components/VirtualVenue/daily/utils';
import PaginationButton from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/CallDisplay/PaginationButton';
import React, { useCallback, useMemo, useRef } from 'react';
import TileContainer from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/CallDisplay/TileContainer/TileContainer';
import TileDisplay from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/CallDisplay/TileDisplay/TileDisplay';
import classNames from 'classnames';
import styles from './SpeakerLayout.module.scss';
import useDraggablePaginator from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/CallDisplay/GridLayout/useDraggablePaginator';
import useResizeObserver from 'utils/hooks/useResizeObserver';
import useSpeakerLayout from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/CallDisplay/SpeakerLayout/useSpeakerLayout';

interface Props {
  tiles: Tile[];
  pinnedSpeaker?: string;
}

const getSpeaker = (tiles: Tile[], fixedSpeakerId?: string) => {
  let speaker: Tile =
    (fixedSpeakerId &&
      tiles.find((t) => t.id === fixedSpeakerId || (t.type === 'daily' && t.callItem.id === fixedSpeakerId))) ||
    tiles.find((t) => isScreenShare(t.id)) || // Speaker is first screenshare...
    tiles.find((t) => t.type === 'daily' && t.isActiveSpeaker) || // ... or active speaker
    (tiles.find((t) => !(t.type === 'daily' && isLocal(t.id))) as Tile); // ... or whoever's left.
  if (!speaker) {
    console.error("Couldn't find speaker among", tiles);
    speaker = tiles[0];
  }
  return speaker;
};

const SpeakerLayout = ({ tiles, pinnedSpeaker }: Props) => {
  const callContainer = useRef(null);
  const resizeEntry = useResizeObserver(callContainer);
  const containerWidth = resizeEntry?.contentRect.width ?? 0;
  const containerHeight = resizeEntry?.contentRect.height ?? 0;
  const layout = useSpeakerLayout(containerWidth, containerHeight);
  const {
    others: { perPage },
  } = layout;

  const tileIds = useMemo(() => tiles.map((t) => t.id), [tiles]);
  const tileIdMap = useMemo(() => F.fromPairs(tiles.map((t) => [t.id, t])), [tiles]);
  const fixedSpeaker = pinnedSpeaker;
  const speakerId = useMemo(() => getSpeaker(tiles, fixedSpeaker).id, [tiles, fixedSpeaker]);
  const { visible, prevPage, nextPage, order, handleDragEnd } = useDraggablePaginator(tileIds, perPage, speakerId);

  // Formula explained:
  // • ( + 2 * i ) = Each tile spans 2 columns
  // • ( + 1 ) = Grid uses 1-based indexing
  // • ( perPage - visible.length ) = Available space around tiles
  // • The above is divided by 2 to get the leading offset,
  // • but then multiplied by two because each tile spans two columns.
  const getColOffset = useCallback((i) => perPage - visible.length + 1 + 2 * i, [perPage, visible.length]);

  const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { distance: 5 } }));
  return (
    <div className="flex items-start justify-center w-full h-full px-4 py-2">
      <div className="relative flex flex-col w-full h-full" ref={callContainer}>
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext items={order} strategy={rectSortingStrategy}>
            <div
              className={classNames('w-full h-full mx-auto', styles.GridContainer)}
              style={{
                // perPage * 2 so we can center even number of participants (half column offsets impossible)
                gridTemplateColumns: `repeat(${perPage * 2}, 1fr)`,
              }}
            >
              {prevPage && <PaginationButton direction="left" onClick={prevPage} />}
              {order.map((tileId) => {
                const isSpeaker = tileId === speakerId;
                const visibleIndex = visible.indexOf(tileId);
                const hidden = visibleIndex < 0 && !isSpeaker;
                return tileIdMap[tileId] ? (
                  <TileContainer
                    id={tileId}
                    className={classNames({
                      [styles.Speaker]: isSpeaker,
                      [styles.NonSpeaker]: !isSpeaker,
                      hidden,
                    })}
                    style={{
                      gridColumn: isSpeaker ? '1 / -1' : `${getColOffset(visibleIndex)} / span 2`,
                    }}
                    key={tileId}
                    width={isSpeaker ? layout.speakerSize.width : undefined}
                    sortable={!hidden && !isSpeaker}
                  >
                    <TileDisplay tile={tileIdMap[tileId]} isOffScreen={hidden} />
                  </TileContainer>
                ) : undefined;
              })}
              {nextPage && <PaginationButton direction="right" onClick={nextPage} />}
            </div>
          </SortableContext>
        </DndContext>
      </div>
    </div>
  );
};

export default SpeakerLayout;
