import { Checkbox, TextLink } from 'components/common';
import { Message } from 'components/Outbox/types';
import { randStr } from 'shared/shared/utils';
import Pill from 'components/common/Pill';
import React from 'react';
import moment from 'moment';
import styles from 'components/Outbox/SelectableList.module.scss';

export interface Props {
  messages: Message[];
  onSelectionChange?: (selectedMessageIds: Set<string>) => void;
  selectedMessageIds: Set<string>;
}

export const SelectableList = (props: Props) => {
  const { selectedMessageIds, messages, onSelectionChange } = props;

  const onCheckboxChange = (data: any) => {
    setMessageSelectedState(data.id as string, data.checked as boolean, selectedMessageIds, onSelectionChange);
  };

  return (
    <div className={styles.SelectableList}>
      {messages.length > 0 ? (
        <div role="list" style={{ cursor: '' }}>
          {messages.map((message) => (
            <div
              role="listitem"
              className={styles.SelectableListItem}
              style={message.disableCheckbox ? { cursor: 'auto' } : {}}
              key={message.id}
              title={message.id}
              onClick={() =>
                message.disableCheckbox
                  ? () => undefined
                  : onCheckboxChange({ id: message.id, checked: !selectedMessageIds.has(message.id) })
              }
            >
              <div className={styles.SelectableListItemContent}>
                {!message.disableCheckbox ? (
                  <Checkbox className={styles.Checkbox} value={selectedMessageIds.has(message.id)} />
                ) : (
                  ''
                )}
                <div className={styles.RealContent}>
                  <span className={styles.RealContentTimestamp}>
                    {`Sending ${moment(message.scheduledFor).fromNow()} to ${message.sendingTo}`}
                  </span>
                  {message.message && (
                    <span className={styles.RealContentText} dangerouslySetInnerHTML={{ __html: message.message }} />
                  )}
                  {message.actions && (
                    <div className={styles.ReminderContent}>
                      <div className={styles.links}>
                        {message.actions?.map((action) => (
                          <TextLink key={randStr()} onClick={action.action}>
                            {action.title}
                          </TextLink>
                        ))}
                      </div>
                      <Pill content="Reminder" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className={styles.Empty}>No messages found</p>
      )}
    </div>
  );
};

function setMessageSelectedState(
  messageId: string,
  selected: boolean,
  selectedMessageIds: Set<string>,
  onSelectionChange: Function | undefined
) {
  if (selected) {
    selectedMessageIds.add(messageId);
  } else {
    selectedMessageIds.delete(messageId);
  }
  if (onSelectionChange) {
    onSelectionChange(selectedMessageIds);
  }
}
