import { VV_EDITOR_HEIGHT, VV_EDITOR_WIDTH } from 'components/VirtualVenue/constants';
import { VirtualVenueThemeOption } from '../../VirtualVenue/types';
import { defaultTheme, useVirtualVenueTheme } from 'components/VirtualVenue/contexts/VirtualVenueThemeContext';
import React from 'react';
import ThemeColorView from 'components/VirtualVenueThemeEditor/ThemePanel/ThemeColorView/ThemeColorView';
import ThemeColorWidget from 'components/VirtualVenueThemeEditor/ThemePanel/ThemeColorWidget/ThemeColorWidget';

const ThemePanel = () => {
  const [currentOption, setCurrentOption] = React.useState<VirtualVenueThemeOption>('bgColor');

  const theme = useVirtualVenueTheme();

  const currentOptionColor = theme[currentOption] || defaultTheme[currentOption];

  return (
    <div
      className="flex flex-col bg-white border border-black border-solid rounded-lg"
      style={{
        height: VV_EDITOR_HEIGHT + 'px',
        width: VV_EDITOR_WIDTH + 'px',
      }}
    >
      <div className="flex flex-col justify-between h-full">
        <div className="px-4 py-3">
          <ThemeColorView onSelect={setCurrentOption} option={currentOption} />
        </div>

        <div className="block w-full">
          <hr className="border-gray-900 border-solid " />
        </div>

        <div className="px-4 py-4">
          <ThemeColorWidget selectedOption={currentOption} value={currentOptionColor} />
        </div>
      </div>
    </div>
  );
};

export default React.memo(ThemePanel);
