import { EventUpdatePageContext } from 'components/pages/EventUpdatePageContext';
import { ThemePicCrop } from 'components/pages/EventUpdatePage';
import { clamp, loadImage } from 'utils/helpers';
import { uploadCareBaseUrlWithDefaults } from 'utils/upload-care';
import EventThemePic from 'components/pages/EventReadPage/EventThemePic';
import React, { useContext } from 'react';
import classNames from 'classnames';
import styles from './EventThemePicResizer.module.scss';

interface Props {
  imageUrl: string;
  isPositioning: boolean;
  allowResize?: boolean;
  crop?: ThemePicCrop;
  onEndDrag?: (crop: ThemePicCrop) => void;
}

interface State {
  loading: boolean;
  imageWidth: number;
  imageHeight: number;
  crop: ThemePicCrop;

  isDragging: boolean;
  initialDragX: number;
  initialDragY: number;
  initialCrop: ThemePicCrop;
}

class EventThemePicResizerInner extends React.Component<Props, State> {
  static initialState: State = {
    loading: false,
    imageWidth: 0,
    imageHeight: 0,
    crop: { x: 0.5, y: 0.5, scale: 1 },

    isDragging: false,
    initialDragX: 0,
    initialDragY: 0,
    initialCrop: { x: 0.5, y: 0.5, scale: 1 },
  };

  static zoom = {
    min: 1,
    max: 4,
  };

  public state: State = {
    ...EventThemePicResizerInner.initialState,
  };

  private $root = React.createRef<HTMLDivElement>();

  private removeListeners() {
    window.removeEventListener('mousemove', this.handleDrag);
    window.removeEventListener('touchmove', this.handleDrag);
    window.removeEventListener('mouseup', this.endDrag);
    window.removeEventListener('touchend', this.endDrag);
    this.$root.current!.removeEventListener('wheel', this.handleWheel);
  }

  private addListeners() {
    window.addEventListener('mousemove', this.handleDrag, { passive: false });
    window.addEventListener('touchmove', this.handleDrag, { passive: false });
    window.addEventListener('mouseup', this.endDrag, { passive: false });
    window.addEventListener('touchend', this.endDrag, { passive: false });
    this.$root.current!.addEventListener('wheel', this.handleWheel, {
      passive: false,
    });
  }

  componentDidMount() {
    const { crop } = this.props;

    if (crop) {
      this.setState({ crop });
    }
    this.getImageDimensions();
  }

  componentWillUnmount() {
    this.removeListeners();
  }

  componentDidUpdate(prevProps: Props) {
    const { imageUrl, allowResize, crop, isPositioning } = this.props;

    if (prevProps.imageUrl !== imageUrl) {
      this.getImageDimensions();
      this.setState({ crop: EventThemePicResizerInner.initialState.crop });
    }

    if (crop && prevProps.allowResize !== allowResize) {
      this.setState({ crop });
    }

    if (isPositioning !== prevProps.isPositioning) {
      if (isPositioning) {
        this.addListeners();
      } else {
        this.removeListeners();
      }
    }
  }

  // NOTE(nick): use this to prevent the beginDrag event from consuming pointer events
  captureEvents = () => ({
    onMouseDown: (e: any) => e.stopPropagation(),
    onTouchStart: (e: any) => e.stopPropagation(),
  });

  beginDrag = (event: any) => {
    const { crop } = this.state;

    const dragX = event.touches ? event.touches[0].pageX : event.screenX;
    const dragY = event.touches ? event.touches[0].pageY : event.screenY;

    this.setState({
      isDragging: true,
      initialDragX: dragX,
      initialDragY: dragY,
      initialCrop: crop,
    });

    event.preventDefault();
  };

  handleDrag = (event: any) => {
    const { imageHeight, imageWidth, isDragging, initialDragX, initialDragY, initialCrop, crop } = this.state;

    if (!isDragging || !imageHeight) {
      return;
    }

    const dragX = event.touches ? event.touches[0].pageX : event.screenX;
    const dragY = event.touches ? event.touches[0].pageY : event.screenY;

    // NOTE(nick): to get pixel-perfect movement, we need to convert from
    // pixel space to percent space:
    const $root = this.$root.current!;
    const containerWidth = $root.clientWidth;
    const containerHeight = $root.clientHeight;

    const { scale } = crop;

    const percentToPixels = scale * imageHeight * (containerWidth / imageWidth) - containerHeight;

    const dx = initialDragX - dragX;
    const dy = initialDragY - dragY;
    const percentDx = dx / percentToPixels;
    const percentDy = dy / percentToPixels;
    const nextX = clamp(initialCrop.x + percentDx, 0, 1);
    const nextY = clamp(initialCrop.y + percentDy, 0, 1);

    this.setState({ crop: { ...crop, x: nextX, y: nextY } });

    event.preventDefault();
    event.stopPropagation();
  };

  endDrag = () => {
    const { onEndDrag } = this.props;
    const { imageWidth, imageHeight, crop } = this.state;

    this.setState({ isDragging: false });

    onEndDrag && onEndDrag({ ...crop, imageWidth, imageHeight });
  };

  handleZoom = (event: any) => {
    const { crop } = this.state;
    const { value } = event.target;

    if (value) {
      const scale = Number(value);
      this.setState({ crop: { ...crop, scale } });
    }
  };

  handleWheel = (event: any) => {
    const { allowResize } = this.props;

    if (!event.ctrlKey || !allowResize) {
      return;
    }

    this.zoomBy(event.deltaY / 40)();

    event.preventDefault();
  };

  zoomBy = (amount: any) => () => {
    const zoom = EventThemePicResizerInner.zoom;
    const { crop } = this.state;

    const scale = clamp(crop.scale + amount, zoom.min, zoom.max);
    this.setState({ crop: { ...crop, scale } });
  };

  getImageDimensions = () => {
    const { imageUrl } = this.props;

    if (!imageUrl) {
      return;
    }

    this.setState({ imageHeight: 0, loading: true });

    loadImage(imageUrl)
      .then((img) => {
        this.setState({
          imageWidth: img.width,
          imageHeight: img.height,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { imageUrl, allowResize } = this.props;
    const { imageHeight, crop } = this.state;

    const canResize = !!imageHeight && allowResize;

    const zoom = EventThemePicResizerInner.zoom;

    return (
      <div
        className={classNames(styles.EventThemePicResizer, {
          [styles['can-resize']]: canResize,
          [styles['can-move-x']]: crop.scale > zoom.min,
        })}
        onMouseDown={canResize ? this.beginDrag : undefined}
        onTouchStart={canResize ? this.beginDrag : undefined}
        ref={this.$root}
      >
        <div className={styles.center}>
          <div className={styles.ui}>Drag Image to Reposition</div>
        </div>
        <div className={styles.centerBottom}>
          <div className={classNames(styles.ui, styles.Zoom)} {...this.captureEvents()}>
            <div
              className={classNames(styles.zoomButton, styles.left, {
                [styles.disabled]: crop.scale === zoom.min,
              })}
              onClick={this.zoomBy(-0.5)}
            >
              -
            </div>
            <input
              value={crop.scale}
              type="range"
              min={zoom.min}
              max={zoom.max}
              step="0.001"
              onChange={this.handleZoom}
            />
            <div
              className={classNames(styles.zoomButton, styles.right, {
                [styles.disabled]: crop.scale === zoom.max,
              })}
              onClick={this.zoomBy(0.5)}
            >
              +
            </div>
          </div>
        </div>
        <EventThemePic imageUrl={imageUrl} crop={crop} />
      </div>
    );
  }
}

// NOTE(nick): pass context as props so the component can listen for changes
const EventThemePicResizer = (props: any) => {
  const { themePic, setThemePic } = useContext(EventUpdatePageContext);

  const allowResize = themePic.editPosition;
  const crop = allowResize && themePic.crop ? themePic.crop : props.crop;

  return (
    <EventThemePicResizerInner
      {...props}
      imageUrl={uploadCareBaseUrlWithDefaults(props.imageUrl)}
      allowResize={allowResize}
      crop={crop}
      onEndDrag={(crop) => setThemePic({ ...themePic, crop })}
    />
  );
};

export default EventThemePicResizer;
