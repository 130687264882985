import { TextAreaWidget, WidgetProps } from 'components/widgets';
import React from 'react';
import classNames from 'classnames';
import styles from './TitleWidget.module.scss';

interface Attrs {
  maxLength: number;
  placeholder?: string;
  spellCheck?: boolean;
  autoFocus?: boolean;
}

type Props = WidgetProps<string, Attrs>;

// Make the text grey when there are no chars left
const remainingCharacterWarnThreshold = 0;

class TitleWidget extends React.Component<Props> {
  private handleChange = (value: string) => {
    value = value.trimLeft();
    value = value.replace(/(\r\n|\n|\r)/gm, ''); // Don't allow newlines
    document.title = value;
    this.props.onChange(value);
  };

  render() {
    const { attrs, value, onChange, ...rest } = this.props;

    const charactersRemaining = attrs!.maxLength - value.length;
    const cls = classNames(styles.TitleWidget, {
      [styles.limitReached]: charactersRemaining <= remainingCharacterWarnThreshold,
    });

    const innerAttrs = {
      className: cls,
      autosize: true,
      ...attrs,
    };

    return <TextAreaWidget {...rest} value={value} onChange={this.handleChange} attrs={innerAttrs} />;
  }
}

export default TitleWidget;
