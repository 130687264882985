import { Link } from 'components/common';
import React from 'react';
import TextLink from 'components/common/TextLink';

export interface Props {
  onClick?: (e: React.MouseEvent<any>) => void;
  href?: string;
  text: string;
  [key: string]: any;
}

const DropdownItem = ({ onClick, href, text, ...rest }: Props) => {
  // noLink to avoid nesting <a></a> in calendar page
  const LinkComponent = href || rest.noLink ? Link : TextLink;

  return (
    <LinkComponent
      className="flex items-center px-4 py-2 text-sm text-black h-9 whitespace-nowrap hover:bg-gray-100 hover:no-underline"
      onClick={onClick}
      href={href as any}
      {...rest}
    >
      {text}
    </LinkComponent>
  );
};

export default DropdownItem;
