import * as m from 'models';
import { Link, TextLink } from 'components/common';
import { URLContext } from 'components/RoutingProvider';
import { reverse } from 'router';
import AuthedDropdown from 'components/layout/Header/AuthedDropdown';
import React, { useContext } from 'react';
import useUser from 'utils/hooks/user/useUser';

const UnauthenticatedNavBar = () => {
  const { authModalStateMutatorSet } = useContext(URLContext);

  return (
    <div className="inline-block">
      <Link href={reverse('virtual_venue_signup')}>Create a venue</Link>
      <TextLink
        onClick={authModalStateMutatorSet.openLoginModal}
        className="ml-6 text-black only-gte-sm"
        text="Log in"
      />
      <TextLink onClick={authModalStateMutatorSet.openSignupModal} className="ml-6 text-black" text="Sign up" />
    </div>
  );
};

const VenueHeaderInner = () => {
  const { user } = useUser();

  return (
    // To avoid flickering, delay rendering until we know who they are.
    user === m.Unknown ? null : m.isAuthenticated(user) ? <AuthedDropdown user={user} /> : <UnauthenticatedNavBar />
  );
};

export default VenueHeaderInner;
