import 'moment-timezone';
import { DateTime } from 'components/widgets/time-types';
import { MenuItem, Select } from '@material-ui/core';
import { WidgetProps } from 'components/widgets/index';
import { getDefaultTimezone, renderTimezone } from 'utils/datetime';
import DateTimeWidget from 'components/widgets/DateTimeWidget';
import React, { useEffect, useMemo, useState } from 'react';
import moment, { Moment } from 'moment';

const getNextMonday = (): Moment => {
  const MONDAY = 1;
  const today = moment().isoWeekday();
  return today <= MONDAY ? moment().isoWeekday(MONDAY) : moment().add(1, 'weeks').isoWeekday(MONDAY);
};

function getDateTimeByHour(day: Moment, hour: number) {
  return day.hours(hour).minutes(0).tz(getDefaultTimezone());
}

const dateTimeToMoment = (value: DateTime): Moment => {
  const dateStringNoTZ = moment(value).format('YYYY-MM-DDTHH:mm:ss');
  return moment.tz(dateStringNoTZ, getDefaultTimezone());
};

interface ScheduleOption {
  label: string;
  value: Moment | 'immediately' | 'custom';
}

const options: ScheduleOption[] = [
  {
    label: 'Immediately',
    value: 'immediately',
  },
  {
    label: 'Tomorrow at 8am',
    value: getDateTimeByHour(moment().add(1, 'days'), 8),
  },
  {
    label: 'Tomorrow at 1pm',
    value: getDateTimeByHour(moment().add(1, 'days'), 13),
  },
  {
    label: 'Next Monday at 8am',
    value: getDateTimeByHour(getNextMonday(), 8),
  },
  {
    label: 'Custom time',
    value: 'custom',
  },
];

const SendLaterWidget: React.FC<WidgetProps> = ({ id, value, disabled, onChange }) => {
  const [customOpen, setCustomOpen] = useState<boolean>(false);
  const [customValue, setCustomValue] = useState<DateTime>(moment().add(1, 'hour').startOf('hour').toObject());

  function onChangeSelect(e: React.ChangeEvent<any>) {
    if (e.target.value === 'immediately') {
      onChange(null);
      setCustomOpen(false);
    } else if (e.target.value === 'custom') {
      onChange(dateTimeToMoment(customValue));
      setCustomOpen(true);
    } else {
      onChange(e.target.value);
      setCustomOpen(false);
    }
  }

  const selectValue = useMemo(() => {
    if (
      customOpen ||
      (value &&
        options.findIndex(
          (option) => moment.isMoment(option.value) && option.value.toISOString() === value.toISOString()
        ) === -1)
    ) {
      return 'custom';
    } else if (value) {
      return value;
    } else {
      return 'immediately';
    }
  }, [value, customOpen]);

  useEffect(() => {
    if (value && selectValue === 'custom' && !customOpen) {
      setCustomOpen(true);
      setCustomValue(value.toObject());
    }
  }, [customOpen, selectValue, value]);

  return (
    <div>
      <Select className="mb-2" fullWidth variant="outlined" value={selectValue} onChange={onChangeSelect}>
        {options.map((option: any) => (
          <MenuItem key={option.label} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {customOpen && (
        <DateTimeWidget
          id={id}
          className="mb-2"
          disabled={disabled}
          value={customValue}
          onChange={(value) => {
            setCustomValue(value);
            onChange(dateTimeToMoment(value));
          }}
          attrs={{
            timePill: {
              content: renderTimezone(getDefaultTimezone()),
            },
          }}
        />
      )}
    </div>
  );
};

export default SendLaterWidget;
