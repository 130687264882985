import { ChatMessage } from 'components/VirtualVenue/pubnub/pubnub';
import { useVirtualVenue } from 'components/VirtualVenue/contexts/VirtualVenueContext';
import { useVirtualVenueDisplay } from 'components/VirtualVenue/contexts/VirtualVenueDisplayContext';
import { useVirtualVenuePubnub } from 'components/VirtualVenue/contexts/VirtualVenuePubnubContext';
import ChatMessageDisplay from 'components/VirtualVenue/VirtualVenueDisplay/layouts/VirtualVenueDisplayLayout/Chat/ChatContent/ChatMessageDisplay/ChatMessageDisplay';
import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';

const texts = [
  'Welcome to the Virtual Venue Theme Editor!',
  'Customize your Virtual Venue to your brand using the editor on the right. Select element names to change their color.',
  "When you're ready, save your venue and copy the provided embed code into your website.",
  // 'If you use a background image, we recommend a low-contrast textural image so your event content shines.',
  // "Here in the sample venue, you can upload an event or series logo. If you don't have an event logo, and don't want to see a repeat of the logo on your web page, leave the sample logo there and it will appear blank.",
  'You can upload a logo to represent your venue or event series.',
  'Enjoy Virtual Venue!',
];

// These demo messages will be pre-populated in editor mode
const demoMessage: ChatMessage = {
  timetoken: '16106621922387900',
  channel: 'virtualvenue.771723649091774786',
  message: {
    type: 'chat.text',
    name: 'Andrew',
    text: '',
    reactions: {},
  },
};

const demoMessages = texts.map((text) => {
  const msg = Object.assign({}, demoMessage);
  msg.message = {
    ...demoMessage.message,
    text,
  };
  return msg;
});

const ChatContent = () => {
  const container = useRef<HTMLDivElement>(null);

  const venue = useVirtualVenue();
  const { pubnubState } = useVirtualVenuePubnub();
  const { messages } = pubnubState;
  const { mode } = useVirtualVenueDisplay();

  useEffect(() => {
    if (container.current) {
      container.current.scrollTop = container.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className={classNames('mt-4 pb-4 pl-4 pr-2 overflow-y-auto overscroll-contain')} ref={container}>
      {(mode === 'edit' ? demoMessages : messages).map((message, i) => (
        <ChatMessageDisplay key={i} message={message} venueRole={venue.myAccess.role} />
      ))}
    </div>
  );
};

export default ChatContent;
