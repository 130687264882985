import { DialogProps } from '@material-ui/core';
import Button, { ButtonColor } from 'components/common/Button/Button';
import Dialog from 'components/common/Dialog/Dialog';
import ErrorMessage from 'components/common/ErrorMessage/ErrorMessage';
import React from 'react';
import styles from './Confirm.module.scss';

interface Props {
  title: string;
  text?: string;
  open: boolean;
  onConfirm(): void;
  yesLabel?: string;
  yesColor?: ButtonColor;
  onCancel?: () => void;
  noLabel?: string;
  preventClose?: boolean;
  size?: DialogProps['maxWidth'];
  disabled?: boolean;
  errorMessage?: string;
}

const Confirm = (props: Props) => {
  const {
    title,
    text,
    open,
    preventClose,
    onCancel,
    onConfirm,
    disabled,
    errorMessage,
    size = 'xs',
    yesLabel = 'OK',
    yesColor = 'primary',
    noLabel = 'Cancel',
  } = props;

  const onClose = !preventClose && onCancel ? onCancel : () => {};

  return (
    <Dialog isOpen={open} onClose={onClose} title={title} size={size}>
      {text && <p className={styles.text}>{text}</p>}
      <div className={styles.buttons}>
        {onCancel && (
          <Button label={noLabel} color="secondary" handleClick={onCancel} className="mt-6" disabled={disabled} />
        )}
        <Button label={yesLabel} color={yesColor} handleClick={onConfirm} className="mt-6" disabled={disabled} />
      </div>
      {errorMessage && <ErrorMessage className="mt-6">{errorMessage}</ErrorMessage>}
    </Dialog>
  );
};

export default Confirm;
