import { FlagName } from 'utils/hooks/user/featureFlag';
import { PERSON_SET_FEATURE_MUTATION } from 'utils/gql';
import { SetFeatureFlag, SetFeatureFlagVariables } from 'generated/SetFeatureFlag';
import { addSnackbarMessage } from 'utils/eventEmitter';
import { useMutation } from '@apollo/client';
import OptionSwitch from 'components/OptionSwitch/OptionSwitch';
import React from 'react';
import useAssertRawUser from 'utils/hooks/user/useAssertRawUser';

interface Props {
  featureName: FlagName;
  title: string;
  description: string;
}

const UserFeatureSwitch = (props: Props) => {
  const { featureName, title, description } = props;
  const userState = useAssertRawUser();
  const [doMutate, { loading }] = useMutation<SetFeatureFlag, SetFeatureFlagVariables>(PERSON_SET_FEATURE_MUTATION);
  if (userState.loading) {
    return null;
  }
  const user = userState.user;
  return (
    <div>
      <OptionSwitch
        fieldName={'feature-switch-' + featureName}
        checked={user.features.includes(featureName)}
        onChange={(e, checked) => {
          doMutate({
            variables: {
              id: user.id,
              featureName,
              value: checked,
            },
          })
            .then(() => {
              if (checked) {
                const msg = `${title} enabled ✨`;
                addSnackbarMessage(msg, 'success');
              }
            })
            .catch(() => {
              addSnackbarMessage("Sorry, something went wrong. We're looking into it.", 'error');
            });
        }}
        title={title}
        helpText={description}
        readOnly={loading}
        styleName={'expanded'}
      />
    </div>
  );
};

export default UserFeatureSwitch;
