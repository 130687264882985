// Renders children at a certain width/aspect ratio
// Doesn't set pointer-events-none -- children remain interactive by default

import React, { useRef } from 'react';
import useResizeObserver from 'utils/hooks/useResizeObserver';

interface Props {
  // Max pixels wide at which to render the preview, otherwise max is container size
  displayWidth?: number;

  // "Screen" size at which to render the component. Doesn't actually affect responsive styles.
  renderSize?: number;

  // Component to shrink/grow
  children: React.ReactNode;
}

const PreviewAtSize = ({ displayWidth, renderSize = 1000, children }: Props) => {
  const outerRef = useRef(null);
  const componentRef = useRef<HTMLDivElement>(null);

  const resizeEntry = useResizeObserver(outerRef);
  const containerWidth = resizeEntry?.contentRect.width;
  const targetWidth = Math.min(containerWidth || 0, displayWidth || 9999);
  const scale = targetWidth / renderSize;

  const aspectRatio = componentRef.current ? componentRef.current.clientWidth / componentRef.current.clientHeight : 1;

  const targetHeight = targetWidth / aspectRatio;

  return (
    <div ref={outerRef}>
      <div className="relative inline-block" style={{ height: `${targetHeight}px`, width: `${targetWidth}px` }}>
        <div className="absolute w-0 h-0">
          <div
            style={{ width: `${renderSize}px`, transform: `scale(${scale})`, transformOrigin: 'top left' }}
            ref={componentRef}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewAtSize;
