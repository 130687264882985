import { InviteCountsByStateT } from 'models/invite';
import { RsvpStates } from 'generated/globalTypes';
import { TextLink } from 'components/common';
import React from 'react';
import classNames from 'classnames';
import styles from './SummaryOverline.module.scss';

interface Props {
  countsByState: InviteCountsByStateT;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isPast?: boolean;
}
const SummaryOverline = (props: Props) => {
  const { countsByState, setOpen, isPast } = props;
  return (
    <TextLink className={classNames(styles.SummaryOverline, 'label--overline')} onClick={() => setOpen(true)}>
      {countsByState[RsvpStates.y]} {isPast ? 'Went' : 'Going'}
      {!!countsByState[RsvpStates.m] && (
        <>
          <span style={{ margin: '0 0.5em' }}> &bull; </span>
          {countsByState[RsvpStates.m]} Maybe
        </>
      )}
      {!!countsByState[RsvpStates.i] && (
        <>
          <span style={{ margin: '0 0.5em' }}> &bull; </span>
          {countsByState[RsvpStates.i]} Invited
        </>
      )}
    </TextLink>
  );
};

export default SummaryOverline;
