import { VirtualVenueData } from 'components/VirtualVenue/types';
import React from 'react';

type ContextT = VirtualVenueData;

const VirtualVenueContext = React.createContext<ContextT>(null as any);

function useVirtualVenue(): ContextT;
function useVirtualVenue(optional: true): ContextT | undefined;
function useVirtualVenue(optional?: boolean): ContextT | undefined {
  const context = React.useContext(VirtualVenueContext);
  if (!optional && context === undefined) {
    throw new Error('useVirtualVenue with optional:false must be used within a VirtualVenueProvider');
  }
  return context;
}
interface Props {
  children: React.ReactNode;
  value: ContextT;
}

function VirtualVenueProvider({ children, value }: Props) {
  return <VirtualVenueContext.Provider value={value}>{children}</VirtualVenueContext.Provider>;
}

export { VirtualVenueProvider, useVirtualVenue };
