import React, { useEffect } from 'react';
import classNames from 'classnames';
import styles from './RootLayout.module.scss';

interface Props {
  title: string;
  children: React.ReactNode;
  className?: string;
}

const RootLayout = (props: Props) => {
  useEffect(() => {
    // set the title to something different first:
    // this works around a bug in Chrome where the tab title wasn't getting updated
    // repro: go to index while logged in, click back, IndexPage redirects back to Calendar,
    //        but title is now "Mixily"
    document.title = props.title + '.';
    document.title = props.title || 'Mixily';
  });

  const cls = classNames(styles.RootLayout, props.className);

  // Header & Container are in their own div because BaseLayout has `justify-content: space-between`.
  // (Without the div, Container would be in the middle of the page instead of at the top.)
  return (
    <div className={cls}>
      <div>{props.children}</div>
    </div>
  );
};

export default RootLayout;
