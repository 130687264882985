import { ApolloProvider } from '@apollo/client';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@material-ui/core/styles';
import { localStore } from 'utils/localstore';
import AppInner from 'components/App/AppInner';
import Cookie from 'js-cookie';
import ErrorBoundary from 'components/common/ErrorBoundary';
import GlobalStateContext from './globalstate/GlobalStateContext';
import React, { useState } from 'react';
import apolloClient from 'api/apolloClient';
import theme from './MuiTheme';

// Must come last or else our styles override tailwind
import 'styles/main.scss';

// Update notistack when this is issue is solved
// https://github.com/iamhosseindhv/notistack/issues/305
const snackbarStyles = {
  variantSuccess: 'snackbar-success',
  variantInfo: 'snackbar-info',
  variantWarning: 'snackbar-warning',
  variantError: 'snackbar-error',
};

const App = () => {
  const [eventId, setEventId] = useState<string>();
  const [inviteId, setInviteId] = useState<string>();
  const [passwordEvent, setPasswordEvent] = useState<string>();

  window.history.scrollRestoration = 'manual';

  // Remove this block Feb 01 2021
  // See https://github.com/reverie/mixily/pull/1124
  const cookieToken = Cookie.get('apitoken');
  const storageToken = localStore.apitoken.get();
  if (cookieToken && !storageToken) {
    localStore.apitoken.set(cookieToken);
  }

  return (
    <ErrorBoundary root>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            autoHideDuration={3000}
            classes={snackbarStyles}
            dense={false}
            hideIconVariant={true}
            maxSnack={3}
            preventDuplicate={true}
            transitionDuration={{ enter: 300, exit: 500 }}
            disableWindowBlurListener={true}
          >
            <GlobalStateContext.Provider
              value={{ eventId, setEventId, inviteId, setInviteId, passwordEvent, setPasswordEvent }}
            >
              <AppInner />
            </GlobalStateContext.Provider>
          </SnackbarProvider>
        </ThemeProvider>
      </ApolloProvider>
      {/* Preload bold and italic font variants to avoid jitter on first usage */}
      <div
        style={{
          visibility: 'hidden',
          height: 0,
          width: 0,
          position: 'absolute',
          top: '-1000px',
        }}
      >
        <b>Bold</b>
        <i>Italic</i>
        <b>
          <i>Bold Italic</i>
        </b>
      </div>
    </ErrorBoundary>
  );
};

export default App;
