import { addSnackbarMessage } from 'utils/eventEmitter';
import { dailyAPI } from 'utils/api';
import { useAsync } from 'utils/hooks/useAsync';
import { useUserId } from 'utils/hooks/user/useUser';
import Confirm2 from 'components/common/Confirm2/Confirm2';
import ErrorMessage from 'components/common/ErrorMessage/ErrorMessage';
import React, { useEffect } from 'react';
import useRecordingManager from 'components/VirtualVenue/VirtualVenueDisplay/content-types/VirtualVenueCallDisplay/Call/useRecordingManager';

interface Props {
  venueId: string;
  recordingId?: string;
  dailyRecordingId: string;
  isMixilyRecording?: boolean;
  open: boolean;
  onSuccess: () => void;
  onCancel: () => void;
}

const ConfirmDeleteRecording = (props: Props) => {
  const userId = useUserId();
  const { open, venueId, dailyRecordingId, recordingId, onSuccess, onCancel, isMixilyRecording } = props;
  const { isLoading: dailyLoading, isError: dailyError, isSuccess: dailySuccess, run, reset } = useAsync<any>();
  const { deleteRecording, deleteData, deleteError, deleteLoading } = useRecordingManager();

  const isSuccess = dailySuccess && (!isMixilyRecording || deleteData?.virtualVenueRecordingDelete.ok);
  const isLoading = dailyLoading || deleteLoading;
  const isError = dailyError || deleteError;

  const onConfirm = async () => {
    if (isMixilyRecording) {
      await deleteRecording(recordingId, venueId, userId);
    }
    run(dailyAPI.deleteRecording(venueId, dailyRecordingId));
  };

  useEffect(() => {
    if (isSuccess) {
      addSnackbarMessage('Recording deleted', 'info');
      onSuccess();

      reset();
    }
  }, [isSuccess, onSuccess, reset]);

  return (
    <Confirm2
      title={`Are you sure?`}
      onClose={onCancel}
      onConfirm={onConfirm}
      isOpen={open}
      disabled={isLoading}
      yesLabel={isLoading ? 'Deleting...' : 'Yes, delete'}
      yesConfig={{ color: 'red-outline' }}
      noLabel={'Cancel'}
    >
      <div>Are you sure you want to delete this recording? This can't be undone.</div>
      {isError && <ErrorMessage className="mt-6">Sorry, something went wrong</ErrorMessage>}
    </Confirm2>
  );
};

export default ConfirmDeleteRecording;
