// Our input wrapper for react-number-format.
// `NumberFormat` is a controlled input, so if you're using react-hook-form directly, you need to use
// a `Controller` component. See https://react-hook-form.com/api#Controller.
//
// Example using `IntegerInput`:
// <Controller
//   as={IntegerInput}
//   name="ticketsPerOrder"
//   control={control}
//   placeholder="1"
//   onChangeName="onValueChange"
//   onChange={(e) => e[0].floatValue}
// />;

import { InputBuiltinProps } from 'components/common/Input2/Input2';
import { NumberFormatProps } from './types';
import InputLayout, {
  InputLayoutExtraProps,
  Props as InputLayoutProps,
} from 'components/common/InputLayout/InputLayout';
import NumberFormat from 'react-number-format';
import React from 'react';

export interface Props extends Omit<InputBuiltinProps, 'ref'> {
  layoutProps?: InputLayoutExtraProps;
  numberFormatProps?: NumberFormatProps;
  ref?: InputLayoutProps['ref'];
}

const NumberFormatInput = (props: Props, ref: InputLayoutProps['ref']) => {
  const { layoutProps, numberFormatProps, ...inputProps } = props;
  const combinedNumberFormatProps = {
    getInputRef: ref,
    ...inputProps,
    ...props.numberFormatProps,
  };
  return <InputLayout inputComponent={NumberFormat} inputProps={combinedNumberFormatProps} {...props.layoutProps} />;
};

export default React.forwardRef(NumberFormatInput);
